import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { IcSearch } from 'assets/icons'
import { Table, TabsActive } from 'components'

const BookingMobilComponent = ({
  columns,
  data,
  tableState,
  setTableState,
  isLoading,
  units,
  tabs,
  onChangeTab
}) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value,
      offset: 0
    }))
  }, [])

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex flex-col w-full space-y-5">
          <TabsActive data={tabs} onPress={(index) => onChangeTab(index)} />
          <Typography.Text className="font-semibold text-2xl">
            {`Daftar User Booking ${
              tabs.find((item) => item.active === true).name === 'ex-Taxi'
                ? 'ex-Taxi'
                : 'Mobil Bekas'
            }`}
          </Typography.Text>
          <div className="flex justify-between">
            <Select
              showSearch
              allowClear
              placeholder="Semua Unit"
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
              style={{ width: '250px' }}
              value={tableState?.unit_name === '' ? null : tableState?.unit_name}
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, unit_name: e ?? '', offset: 0 }))
              }
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}>
              {units?.map((unit, idx) => (
                <Select.Option key={idx} value={unit}>
                  {unit}
                </Select.Option>
              ))}
            </Select>
            <Input
              className="w-1/4 rounded-md py-1.5"
              placeholder="Pencarian"
              prefix={<IcSearch className="text-gray-400" />}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Table
            columns={columns}
            data={data}
            pending={isLoading}
            totalRows={tableState?.totalRows}
            handlePerRowsChange={handlePerRowsChange}
            pageSize={tableState?.limit}
            page={tableState?.offset}
            onChangePrevPage={() => {
              if (tableState?.offset > 0) {
                onChangePage(tableState?.offset - tableState?.limit)
              }
            }}
            onChangeNextPage={() => {
              if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                onChangePage(tableState?.offset + tableState?.limit)
              }
            }}
          />
        </div>
      </Card>
    </Content>
  )
}

BookingMobilComponent.propTypes = {
  columns: PropTypes.array,
  tableState: PropTypes.object,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  setTableState: PropTypes.func,
  isLoading: PropTypes.bool,
  tabs: PropTypes.array,
  onChangeTab: PropTypes.func
}

BookingMobilComponent.defaultProps = {
  columns: PropTypes.array,
  tableState: {},
  data: [],
  onAdd: () => {},
  setTableState: () => {},
  isLoading: false,
  tabs: [],
  onChangeTab: () => {}
}

export default BookingMobilComponent
