import React from 'react'
import { Input } from 'antd'

const { TextArea } = Input
function FormTextArea({ data, state, onChange }) {
  const { label, ...inputProps } = data
  return (
    <div className="grid grid-cols-1 gap-1">
      <p className="text-sm-medium text-gray-700">{label}</p>
      <TextArea {...inputProps} value={state} onChange={(e) => onChange(e)} />
    </div>
  )
}

export default FormTextArea
