import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Table } from 'components'
import { IcPlus, IcSearch } from 'assets/icons'

const InternalUnitComponent = ({
  columns,
  data,
  tableState,
  setTableState,
  isLoading,
  dropDownWorkshop
}) => {
  const router = useHistory()
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleFilterByWorkshop = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      branch_id: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }
  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">Internal Unit</Typography.Text>
          <button
            className="flex flex-row items-center justify-around rounded-md border-solid border-primary-500 focus:outline-none space-x-1 px-2 py text-md text-white bg-primary-500 hover:cursor-pointer"
            onClick={() => router.push('/internal-unit/add')}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Unit</span>
          </button>
        </div>
        <div className="mb-5 flex justify-between">
          <Select
            showSearch
            allowClear
            placeholder="Semua Cabang"
            className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
            style={{ minWidth: '200px' }}
            onSearch={(e) => {}}
            value={tableState?.branch_id === '' ? null : tableState?.branch_id}
            onChange={(e) => handleFilterByWorkshop(e ?? '')}
            filterOption={(input, option) => {
              return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }}>
            {dropDownWorkshop?.map((item, idx) => (
              <Select.Option key={idx} value={item?.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          <Input
            className="w-1/4 rounded-md"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

export default InternalUnitComponent
