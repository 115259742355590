import {
  IcBarChart,
  IcBox,
  IcCar,
  IcCarCustom,
  IcChrome,
  IcDatabase,
  IcFlag,
  IcInbox,
  IcLock,
  IcSearch,
  IcSettings,
  IcShoppingBag,
  IcTaxi,
  IcTool,
  IcUsers
} from 'assets/icons'
import IcLayout from 'assets/icons/IcLayout'

export const menuData = [
  {
    id: 'menu-1',
    name: 'Analytics Dashboard',
    icons: IcBarChart,
    key: 'analytics-dashboard',
    label: 'Analytics Dashboard',
    href: '/analytics-dashboard',
    icon: <IcBarChart className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    children: [
      {
        key: 'penjualan',
        label: 'Penjualan',
        href: '/analytics-dashboard/penjualan'
      },
      {
        key: 'cabang',
        label: 'Cabang',
        href: '/analytics-dashboard/cabang'
      },
      {
        key: 'unit',
        label: 'Unit',
        href: '/analytics-dashboard/unit'
      }
    ]
  },
  {
    id: 'menu-2',
    name: 'Test Drive',
    icons: IcChrome,
    key: 'test-drive',
    label: 'Test Drive',
    href: '/test-drive',
    icon: <IcChrome className="text-gray-500 hover:text-primary-500 ic-menu-custom" />
  },
  {
    id: 'menu-3',
    name: 'Booking Mobil',
    icons: IcLock,
    key: 'booking-mobil',
    label: 'Booking Mobil',
    href: '/booking-mobil',
    icon: <IcLock className="text-gray-500 hover:text-primary-500 ic-menu-custom" />
  },
  {
    id: 'menu-4',
    name: 'Jual Unit',
    icons: IcShoppingBag,
    key: 'jual-unit',
    label: 'Jual Unit',
    href: '/jual-unit',
    icon: <IcShoppingBag className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    children: [
      {
        key: 'stock',
        label: 'Stock',
        href: '/jual-unit/stock'
      },
      {
        key: 'katalog',
        label: 'Katalog',
        href: '/jual-unit/katalog'
      },
      {
        key: 'riwayat-penjualan',
        label: 'Riwayat Penjualan',
        href: '/jual-unit/riwayat-penjualan'
      }
    ]
  },
  {
    id: 'menu-5',
    name: 'Inspeksi',
    icons: IcSearch,
    key: 'inspeksi',
    label: 'Inspeksi',
    icon: <IcSearch className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    children: [
      {
        key: 'jadwal-inspeksi',
        label: 'Jadwal Inspeksi',
        href: '/inspeksi/jadwal-inspeksi'
      },
      {
        key: 'hasil-inspeksi',
        label: 'Hasil Inspeksi',
        href: '/inspeksi/hasil-inspeksi'
      }
    ]
  },
  {
    id: 'menu-6',
    name: 'Servis',
    icons: IcTool,
    key: 'services',
    label: 'Servis',
    icon: <IcTool className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    children: [
      {
        key: 'pickup',
        label: 'Pickup Servis',
        href: '/services/pickup'
      },
      {
        key: 'jadwal',
        label: 'Jadwal Servis',
        href: '/services/jadwal'
      },
      {
        key: 'management',
        label: 'Management Servis',
        href: '/services/management'
      },
      {
        key: 'harga',
        label: 'Harga Servis',
        href: '/services/harga'
      }
    ]
  },
  {
    id: 'menu-61',
    name: 'Rilis Kendaraan',
    icons: IcCar,
    key: 'rilis-kendaraan',
    label: 'Rilis Kendaraan',
    href: '/rilis-kendaraan',
    icon: <IcCar className="text-gray-500 hover:text-primary-500 ic-menu-custom" />
  },
  {
    id: 'menu-7',
    name: 'Workshop Management',
    icons: IcFlag,
    key: 'workshop-management',
    label: 'Workshop Management',
    icon: <IcFlag className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    href: '/workshop-management'
  },
  {
    id: 'menu-8',
    name: 'Internal Unit',
    icons: IcInbox,
    key: 'internal-unit',
    label: 'Internal Unit',
    icon: <IcInbox className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    href: '/internal-unit'
  },
  {
    id: 'menu-9',
    name: 'Ex-Taxi',
    icons: IcTaxi,
    key: 'ex-taxi',
    label: 'Ex-Taxi',
    icon: <IcTaxi className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    children: [
      {
        key: 'ex-taxi-unit',
        label: 'Unit',
        href: '/ex-taxi/unit'
      }
    ]
  },
  {
    id: 'menu-10',
    name: 'Create Your Own Car',
    icons: IcCarCustom,
    key: 'create-your-own-car',
    label: 'Create Your Own Car',
    icon: <IcCarCustom className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    children: [
      {
        key: 'cyoc-sparepart',
        label: 'Sparepart',
        href: '/create-your-own-car/cyoc-sparepart'
      },
      {
        key: 'cyoc-daftar-unit',
        label: 'Daftar Unit',
        href: '/create-your-own-car/cyoc-daftar-unit'
      },
      {
        key: 'cyoc-kategori',
        label: 'Kategori',
        href: '/create-your-own-car/cyoc-kategori'
      }
    ]
  },
  {
    id: 'menu-11',
    name: 'Sparepart',
    icons: IcBox,
    key: 'sparepart',
    label: 'Sparepart',
    icon: (
      <IcBox
        className="text-gray-500 hover:text-primary-500 ic-menu-custom"
        currentColor="currentColor"
      />
    ),
    children: [
      {
        key: 'sparepart-inventory',
        label: 'Sparepart Inventory',
        href: '/sparepart/sparepart-inventory'
      },
      {
        key: 'sparepart-category',
        label: 'Sparepart Category',
        href: '/sparepart/category'
      },
      {
        key: 'sparepart-unit',
        label: 'Sparepart Unit',
        href: '/sparepart/unit'
      }
    ]
  },
  {
    id: 'menu-12',
    name: 'Master Data',
    icons: IcDatabase,
    key: 'master',
    label: 'Master Data',
    icon: <IcDatabase className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    href: '/master',
    children: [
      {
        key: 'user',
        label: 'User',
        href: '/master/user'
      },
      {
        key: 'perusahaan',
        label: 'Perusahaan',
        href: '/master/perusahaan'
      },
      {
        key: 'admin',
        label: 'Admin',
        href: '/master/admin'
      },
      {
        key: 'voucher',
        label: 'Voucher',
        href: '/master/voucher'
      },
      {
        key: 'kelas-unit',
        label: 'Kelas Unit',
        href: '/master/kelasunit'
      },
      {
        key: 'harga-rekomendasi',
        label: 'Harga Rekomendasi',
        href: '/master/hargarekomendasi'
      },
      {
        key: 'lokasi',
        label: 'Lokasi',
        href: '/master/lokasi',
        children: [
          {
            key: 'provinsi',
            label: 'Provinsi',
            href: '/master/lokasi/provinsi'
          },
          {
            key: 'kota',
            label: 'Kota/Kabupaten',
            href: '/master/lokasi/kota'
          },
          {
            key: 'kecamatan',
            label: 'Kecamatan',
            href: '/master/lokasi/kecamatan'
          },
          {
            key: 'all',
            label: 'Lokasi Lengkap',
            href: '/master/lokasi/all'
          },
          {
            key: 'harga',
            label: 'Harga Jarak',
            href: '/master/lokasi/harga'
          }
        ]
      },
      {
        key: 'products',
        label: 'Produk',
        href: '/master/products',
        children: [
          {
            key: 'brand',
            label: 'Brand',
            href: '/master/products/brand'
          },
          {
            key: 'model',
            label: 'Model',
            href: '/master/products/model'
          },
          {
            key: 'tipe',
            label: 'Tipe',
            href: '/master/products/tipe'
          },
          {
            key: 'transmisi',
            label: 'Transmisi',
            href: '/master/products/transmisi'
          },
          {
            key: 'bahanbakar',
            label: 'Bahan Bakar',
            href: '/master/products/bahanbakar'
          },
          {
            key: 'warna',
            label: 'Warna',
            href: '/master/products/warna'
          },
          {
            key: 'kategori',
            label: 'Kategori',
            href: '/master/products/kategori'
          }
        ]
      },
      {
        label: 'Inspeksi',
        children: [
          {
            key: 'kategori-inspeksi',
            label: 'Kategori Inspeksi',
            href: '/master/inspeksi/kategori-inspeksi'
          },
          {
            key: 'bagian-inspeksi',
            label: 'Bagian Inspeksi',
            href: '/master/inspeksi/bagian-inspeksi'
          },
          {
            key: 'keterangan-inspeksi',
            label: 'Keterangan Inspeksi',
            href: '/master/inspeksi/keterangan-inspeksi'
          },
          {
            key: 'grade-inspeksi',
            label: 'Grade Inspeksi',
            href: '/master/inspeksi/grade-inspeksi'
          }
        ]
      }
    ]
  },
  {
    id: 'menu-13',
    name: 'Content Management',
    icons: IcLayout,
    key: 'content-management',
    label: 'Content Management',
    icon: <IcLayout className="text-gray-500 hover:text-primary-500 ic-menu-custom" />,
    href: '/content-management',
    children: [
      {
        key: 'landing-page',
        label: 'Landing Page',
        href: '/content-management/landing-page'
      }]
    },
  {
    id: 'menu-14',
    name: 'Konfigurasi',
    icons: IcSettings,
    key: 'configuration',
    label: 'Konfigurasi',
    href: '/configuration',
    icon: <IcSettings className="text-gray-500 hover:text-primary-500 ic-menu-custom" />
  },
  {
    id: 'menu-15',
    name: 'Role Management',
    icons: IcUsers,
    key: 'role-management',
    label: 'Role Management',
    href: '/role-management',
    icon: <IcUsers className="text-gray-500 hover:text-primary-500 ic-menu-custom" />
  }
]
