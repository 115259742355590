import React from 'react'
import { Field } from 'formik'

const FormRadio = ({
  data,
  title,
  check,
  isRequired = true,
  flex = 'flex-row',
  onChange = () => {}
}) => (
  <div className={`flex ${flex} items-start justify-between w-full h-full`}>
    <label htmlFor={title} className="font-medium block mb-2 text-md text-gray-900">
      {title}
      {isRequired && <span className="text-red-600">{' *'}</span>}
    </label>
    <div
      role="group"
      aria-labelledby="typePotongan-radio-group"
      className="flex flex-row space-x-10">
      {data.map((item, index) => (
        <label
          key={index}
          role="group"
          className="flex space-x-5 font-medium text-gray-700 text-sm items-center justify-center">
          <Field>
            {({ field, form: { touched, errors } }) => (
              <input
                {...field}
                className="mr-2 accent-primary-500"
                type="radio"
                name={item.name}
                value={item.value}
                checked={check === item.value}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          </Field>
          {item.label}
        </label>
      ))}
    </div>
  </div>
)

export default FormRadio
