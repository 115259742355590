import React, { useEffect, useState } from 'react'
import { Breadcrumb, DatePicker, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import {
  CardContainer,
  CustomButton,
  FormDropDown,
  FormInput,
  ModalDelete,
  Table,
  TabsActive
} from 'components'
import { useHistory, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import {
  InformasiPerusahaanCard,
  ModalDeleteUnit,
  ModalTambahUserPerusahaan,
  ModalLoadMoreBills,
  ModalConfirmationBills,
  ModalPaymentProof,
  ModalUpdatePerusahaan
} from '../../components'
import { IcEdit, IcEye, IcLoadMore, IcPlus, IcTrash } from 'assets/icons'
import { metaInitialData, perPageInitialData } from 'constant'
import { useDispatch, useSelector } from 'react-redux'
import {
  formatCurrency,
  regexPassword,
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage
} from 'utils'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPut, urls } from 'api'
import { logout } from 'store/actions'
import moment from 'moment'
import _ from 'lodash'

const initialTabsActive = [
  { name: 'Unit', active: true },
  { name: 'User', active: false },
  { name: 'Tagihan', active: false }
]

const PerusahaanDetailPage = () => {
  const { id, tabActive } = useParams()
  const router = useHistory()
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  //* initial data state *//
  const [tabs, setTabs] = useState(initialTabsActive)
  const [dropDownUser, setDropDownUser] = useState([{ name: 'Semua User', value: -1 }])
  const [month, setMonth] = useState({
    value: moment(new Date(), 'MM'),
    parse: ''
  })
  const [year, setYear] = useState({
    value: moment(new Date(), 'YYYY'),
    parse: ''
  })

  const [perPageArr, setPerPageArr] = useState(perPageInitialData)
  const [currentPage, setCurrentPage] = useState(0)
  const [meta, setMeta] = useState(metaInitialData)
  const [dataTable, setDataTable] = useState([])
  const [detailPerusahaan, setDetailPerusahaan] = useState()

  //* modal state *//
  const [modalUpdatPerusahaan, setModalUpdatePerusahaan] = useState({
    show: false,
    type: 'edit',
    data: null
  })
  const [modalDelete, setModalDelete] = useState({
    show: false,
    type: 'user',
    id: 0,
    title: '',
    desc: ''
  })
  const [modalTambahUser, setModalTambahUser] = useState({ show: false })
  const [modalDeleteUnit, setModalDeleteUnit] = useState({
    show: false,
    id: 0,
    unit_name: '',
    unit_police_number: ''
  })
  const [modalConfirmationBills, setModalConfirmationBills] = useState({
    show: false,
    id: 0
  })
  const [modalPaymentProof, setModalPaymentProof] = useState({
    show: false,
    proof_of_payment_photo: undefined
  })
  const onLogout = () => dispatch(logout())

  //* search & filter state
  const [userSelector, setUserSelector] = useState(-1)
  const [search, setSearch] = useState('')
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  useEffect(() => {
    const temp = [...tabs]
    if (tabActive) {
      temp.map((item) => {
        if (item.name.toLowerCase() === tabActive) {
          item.active = true
        } else {
          item.active = false
        }
      })
      setTabs(temp)
    }
  }, [tabActive])

  const {
    isLoading: isLoadingDetailPerusahaan,
    isFetching: isFetchingDetailPerusahaan,
    refetch: refetchDetailPerusahaan
  } = useQuery({
    queryKey: ['perusahaan-detail', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token: user.token,
        url: 'user-service/admin/companies/' + id,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      let data = {
        id: id,
        namaPerusahaan: res?.resData?.data?.name,
        nomorTelepon: res.resData.data.phone_number,
        email:
          res.resData.data.email && res.resData.data.email !== '' ? res.resData.data.email : '-',
        npwp: res.resData.data.npwp,
        alamatPerusahaan: res?.resData?.data?.address,
        users: res?.resData?.data?.name,
        potonganHarga: res?.resData?.data?.discount,
        maksimalPotongan: res?.resData?.data?.max_discount,
        jatuhTempo: res?.resData?.data?.due_date,
        discountType: res?.resData?.data?.discount_type,
        provinsi: res?.resData?.data?.province_name,
        kabupaten: res?.resData?.data?.city_name,
        kecamatan: res?.resData?.data?.sub_district_name,
        kodePos: res?.resData?.data?.postal_code
      }
      setDetailPerusahaan(data)
    }
  })

  const { isLoading: isLoadingAllUser } = useQuery({
    queryKey: ['detail-perusahaan-all-user'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.perusahaan.detail.user.prefix}/${urls.master.perusahaan.detail.user.all}/${id}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownUser([{ name: 'Semua User', value: -1 }])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            if (item.name !== '') {
              setDropDownUser((prev) => [...prev, { name: item.name, value: item.id }])
            }
          })
        }
      }
    }
  })

  const { isLoading: isLoadingUnit, refetch: refetchUnit } = useQuery({
    queryKey: ['detail-perusahaan-unit', userSelector, search],
    refetchOnWindowFocus: false,
    enabled: tabs.find((item) => item.active === true).name === 'Unit',
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.perusahaan.detail.unit.list}/${id}?user_id=${
          userSelector === -1 ? '' : userSelector
        }&keywords=${search}&limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${meta.offset ?? 0}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDataTable(data.resData.data)
      setMeta(data.resData.meta)
    }
  })

  const { isLoading: isLoadingUser, refetch: refetchUser } = useQuery({
    queryKey: ['detail-perusahaan-user', search],
    refetchOnWindowFocus: false,
    enabled: tabs.find((item) => item.active === true).name === 'User',
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.perusahaan.detail.user.prefix}/${
          urls.master.perusahaan.detail.user.list
        }/${id}?keywords=${search}&limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${meta.offset ?? 0}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDataTable(data.resData.data)
      setMeta(data.resData.meta)
    }
  })

  const { isLoading: isLoadingTagihan, refetch: refetchTagihan } = useQuery({
    queryKey: ['detail-perusahaan-tagihan', search, month.parse, year.parse],
    refetchOnWindowFocus: false,
    enabled: tabs.find((item) => item.active === true).name === 'Tagihan',
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.perusahaan.detail.tagihan.prefix}/${
          urls.master.perusahaan.detail.tagihan.list
        }/${id}?keywords=${search}&month=${month.parse}&year=${year.parse}&limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${meta.offset ?? 0}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDataTable(data.resData.data)
      setMeta(data.resData.meta)
    }
  })

  //* table state *//
  const onHandleExtraUrl = () => {
    let extraUrl = ''
    if (search && search !== '') {
      extraUrl += `&keywords=${search}`
    }
    if (userSelector && userSelector !== -1) {
      extraUrl += `&user_id=${userSelector}`
    }
    if (month.parse && month.parse !== '') {
      extraUrl += `&month=${month.parse}`
    }
    if (year.parse && year.parse !== '') {
      extraUrl += `&year=${year.parse}`
    }
    return extraUrl
  }

  const onChangePrevNextPage = (offset) => {
    let urlTemp = ''
    setMeta({ ...meta, offset })
    const tabActive = tabs.find((item) => item.active === true).name
    if (tabActive === 'Unit') {
      urlTemp = `${urls.master.prefix}/${urls.master.perusahaan.detail.unit.list}/${id}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    if (tabActive === 'User') {
      urlTemp = `${urls.master.perusahaan.detail.user.prefix}/${
        urls.master.perusahaan.detail.user.list
      }/${id}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    if (tabActive === 'Tagihan') {
      urlTemp = `${urls.master.perusahaan.detail.tagihan.prefix}/${
        urls.master.perusahaan.detail.tagihan.list
      }/${id}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    setIsLoadingHelper(true)
    const mount = setTimeout(async () => {
      const res = await apiGet({
        url: urlTemp,
        token: user.token,
        onLogout
      })
      if (res.resStatus === 200) {
        setDataTable(res.resData.data)
      } else {
        onRefetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    let urlTemp = ''
    const tabActive = tabs.find((item) => item.active === true).name
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    if (tabActive === 'Unit') {
      urlTemp = `${urls.master.prefix}/${
        urls.master.perusahaan.detail.unit.list
      }/${id}?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`
    }
    if (tabActive === 'User') {
      urlTemp = `${urls.master.perusahaan.detail.user.prefix}/${
        urls.master.perusahaan.detail.user.list
      }/${id}?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`
    }
    if (tabActive === 'Tagihan') {
      urlTemp = `${urls.master.perusahaan.detail.tagihan.prefix}/${
        urls.master.perusahaan.detail.tagihan.list
      }/${id}?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`
    }
    setIsLoadingHelper(true)
    const mount = setTimeout(async () => {
      const res = await apiGet({
        url: urlTemp,
        token: user.token,
        onLogout
      })
      if (res.resStatus === 200) {
        setDataTable(res.resData.data)
        setMeta(res.resData.meta)
        setPerPageArr(newArr)
      } else {
        onRefetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangeDataTable = (index) => {
    switch (index) {
      case 0:
        return refetchUnit()
      case 1:
        return refetchUser()
      case 2:
        return refetchTagihan()
      default:
        break
    }
  }

  const onRefetch = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Unit':
        return refetchUnit()
      case 'User':
        return refetchUser()
      case 'Tagihan':
        return refetchTagihan()
    }
  }

  const columnsUnit = [
    {
      name: 'Unit',
      selector: (row) => row.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <span className="font-medium text-gray-700">{row.unit_name}</span>
          <span className="font-normal text-gray-500">{row.police_number}</span>
        </div>
      )
    },
    {
      name: 'Users',
      selector: (row) => row.owner_name,
      cell: (row) => (
        <span className="font-medium text-gray-700">
          {row.owner_name && row.owner_name !== '' ? row.owner_name : '-'}
        </span>
      )
    },
    {
      name: 'Riwayat Service',
      selector: (row) => row.latest_service_date,
      cell: (row) => (
        <span className="font-medium text-gray-700">
          {row.latest_service_date && row.latest_service_date !== ''
            ? row.latest_service_date
            : '-'}
        </span>
      )
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              router.push(`/master/perusahaan/perusahaandetail/${id}/unitdetail/${row.id}`)
            }>
            <IcEye className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setModalDeleteUnit({
                show: true,
                id: row.id,
                unit_name: row.unit_name,
                unit_police_number: row.police_number
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  const columnsUser = [
    {
      name: 'User',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      cell: (row) => <span className="font-medium text-gray-700">{row.email}</span>
    },
    {
      name: 'Nomor Telepon',
      selector: (row) => row.phone_number,
      cell: (row) => (
        <span className="font-medium text-gray-700">
          {row.phone_number && row.phone_number !== '' ? row.phone_number : '-'}
        </span>
      )
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() => {
              router.push(`/master/perusahaan/edituserperusahaan/${row.id}`)
            }}>
            <IcEdit className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() => {
              setModalDelete({
                show: true,
                id: row.id,
                type: 'user',
                title: 'Hapus Data User Perusahaan',
                desc: `Apakah Anda yakin ingin menghapus user [${row.name}] ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`
              })
            }}>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  const onReturnStatusPayment = (status, payment_status) => {
    let containerStyle = 'bg-gray-50'
    let textStyle = 'text-gray-700'
    let statusTemp = '-'
    if (payment_status.toLowerCase() === 'paid') {
      containerStyle = 'bg-success-50'
      textStyle = 'text-success-700'
      statusTemp = _.startCase(payment_status)
    } else {
      if (status.toLowerCase() === 'waiting_payment') {
        containerStyle = 'bg-danger-50'
        textStyle = 'text-danger-700'
        statusTemp = 'Unpaid'
      }
      if (status.toLowerCase() !== 'waiting_payment') {
        containerStyle = 'bg-warning-50'
        textStyle = 'text-warning-700'
        statusTemp = 'In Progress'
      }
    }

    return (
      <div className={`flex rounded-lg px-2 py-1 ${containerStyle}`}>
        <span className={`font-medium ${textStyle}`}>{statusTemp}</span>
      </div>
    )
  }

  const columnsBill = [
    {
      name: 'Tanggal',
      selector: (row) => row.created_at,
      cell: (row) => (
        <span className="font-medium text-gray-700">
          {moment(row.created_at).format('DD/MM/YYYY')}
        </span>
      )
    },
    {
      name: 'Nama Unit',
      selector: (row) => row.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-2 py-2">
          <div className={`flex flex-col bg-transparent space-y-1`}>
            <span className="font-medium text-gray-700">{row.unit_name}</span>
            <span className="font-normal text-gray-500">{row.unit_police_number}</span>
          </div>
        </div>
      )
    },
    {
      name: 'User',
      selector: (row) => row.user_fullname,
      cell: (row) => (
        <div className="grid grid-flow-row gap-y-8 justify-center items-center">
          <span className="font-medium text-gray-700">
            {row.user_fullname && row.user_fullname !== '' ? row.user_fullname : '-'}
          </span>
        </div>
      )
    },
    {
      name: 'Layanan',
      selector: (row) => row.transaction_type,
      cell: (row) => (
        <div className="grid grid-flow-row gap-y-8 justify-center items-center">
          <span className="font-medium text-gray-700">{row.transaction_type}</span>
        </div>
      )
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) =>
        row.status && row.payment_status
          ? onReturnStatusPayment(row.status, row.payment_status)
          : '-'
    },
    {
      name: 'Biaya',
      selector: (row) => row.transaction_profit,
      cell: (row) => (
        <span className="font-medium text-gray-700">{formatCurrency(row.transaction_profit)}</span>
      )
    },
    {
      name: 'Action',
      width: '100px',
      ignoreRowClick: true,
      sortable: false,
      right: true,
      cell: (row) => (
        <ModalLoadMoreBills
          status={row.status}
          payment_status={row.payment_status}
          proof_of_payment={row.proof_of_payment}
          onPressInvoiceBills={() => {}}
          onPressConfirmastionBills={() => setModalConfirmationBills({ show: true, id: row.id })}
          onPressPaymentProof={() => {
            if (row.proof_of_payment && row.proof_of_payment !== null) {
              setModalPaymentProof({
                show: true,
                proof_of_payment_photo: `${process.env.REACT_APP_IMAGE_URL_GCS}/${row.proof_of_payment}`
              })
            }
          }}
          onPressDeleteBills={() =>
            setModalDelete({
              id: row.id,
              show: true,
              type: 'bill',
              title: 'Hapus Tagihan',
              desc: `Apakah Anda yakin ingin menghapus Tagihan [${row.transaction_code}] ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`
            })
          }>
          <button
            type="button"
            className="flex items-center justify-center self-center border-0 bg-transparent hover:cursor-pointer">
            <IcLoadMore className="text-gray-500" size={18} />
          </button>
        </ModalLoadMoreBills>
      )
    }
  ]

  const onReturnColumnsTable = () => {
    if (tabs[0].active) {
      return columnsUnit
    }
    if (tabs[1].active) {
      return columnsUser
    }
    if (tabs[2].active) {
      return columnsBill
    }
  }

  const onReturnTitleTableCard = () => {
    if (tabs[0].active) {
      return 'Unit'
    }
    if (tabs[1].active) {
      return 'User Perusahaan'
    }
    if (tabs[2].active) {
      return 'Tagihan'
    }
  }

  //* form filter *//
  const onReturnFormFilter = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Unit':
        return (
          <div className="w-1/6">
            <Formik
              enableReinitialize
              initialValues={{
                userSelector: userSelector
              }}>
              <FormDropDown
                name="userSelector"
                data={dropDownUser}
                onChange={(value) => {
                  setUserSelector(parseInt(value))
                }}
              />
            </Formik>
          </div>
        )
      case 'Tagihan':
        return (
          <div className="flex flex-row items-center space-x-2">
            <DatePicker
              placeholder="Bulan"
              picker="month"
              value={month.parse === '' ? '' : month.value}
              onChange={(value) => {
                setMonth({
                  value: value,
                  parse: value && value !== '' ? moment(value, 'MM').format('MM') : ''
                })
              }}
              className="custom-date-picker py-3 w-full"
              popupClassName="custom-date-picker-dropdown"
              format={['MM']}
            />
            <DatePicker
              placeholder="Tahun"
              picker="year"
              value={year.parse === '' ? '' : year.value}
              onChange={(value) => {
                setYear({
                  value: value,
                  parse: value && value !== '' ? moment(value, 'YYYY').format('YYYY') : ''
                })
              }}
              className="custom-date-picker py-3 w-full"
              popupClassName="custom-date-picker-dropdown"
              format={['YYYY']}
            />
          </div>
        )
      default:
        return null
    }
  }

  const onCheckFieldEmpty = (data) => {
    return Object.entries(data).every((item) => {
      if (item[1] !== undefined && item[1] !== '' && item[1] !== null && item[1].length !== 0) {
        if (item[0] === 'password') {
          if (regexPassword(item[1])) {
            return true
          } else {
            showErrorMessage({
              content:
                'Password minimal 8 karakter, maksimal 13 karakter, berupa kombinasi huruf & angka.',
              duration: 4000
            })
            return false
          }
        } else {
          return true
        }
      } else {
        if (
          item[0] !== 'id' &&
          item[0] !== 'province_name' &&
          item[0] !== 'city_name' &&
          item[0] !== 'sub_district_name'
        ) {
          if (item[0] === 'province_id') {
            showErrorMessage({ content: `Silahkan Pilih Provinsi` })
          } else if (item[0] === 'city_id') {
            showErrorMessage({ content: `Silahkan Pilih Kota/Kabupaten` })
          } else if (item[0] === 'sub_district_id') {
            showErrorMessage({ content: `Silahkan Pilih Kecamatan` })
          } else {
            showErrorMessage({ content: `Silahkan Lengkapi Field ${_.startCase(item[0])}` })
          }
          return false
        } else {
          return true
        }
      }
    })
  }

  const updateDataPerusahaan = (data) => {
    if (onCheckFieldEmpty(data)) {
      setIsLoadingHelper(true)
      const mount = setTimeout(async () => {
        const res = await apiPut({
          url: `user-service/admin/companies/${id}`,
          token: user.token,
          data,
          onLogout
        })
        if (res.resStatus === 200) {
          setModalUpdatePerusahaan({
            ...modalUpdatPerusahaan,
            show: false
          })
          showSuccessMessage({ content: 'Berhasil Mengupdate Data Perusahaan' })
        } else {
          showErrorMessage({
            content: `${
              res.resData.message ??
              res.resData.errors[0].message ??
              'Gagal Mengupdate Data Perusahaan.'
            }`
          })
        }
        refetchDetailPerusahaan()
        setIsLoadingHelper(false)
      })
      return () => clearTimeout(mount)
    }
  }

  const deleteDataPerusahaan = async () => {
    try {
      let res
      res = await apiDelete({
        url: `user-service/admin/companies/${id}`,
        token: user.token,
        onLogout
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        router.goBack()
        showSuccessMessage({ content: 'Berhasil Menghapus Data Perusahaan' })
      } else {
        showErrorMessage({
          content: `${
            res.resData.message ??
            res.resData.errors[0].message ??
            'Gagal Menghapus Data Perusahaan.'
          }`
        })
      }
      setModalUpdatePerusahaan({
        ...modalUpdatPerusahaan,
        show: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout
      style={{
        padding: 24,
        background: '#F5F5F5',
        width: '100%',
        overflowY: 'hidden'
      }}
      className="overflow-y-hidden">
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Master Data
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Perusahaan
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Perusahaan
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {/* modal update perusahaan */}
        <ModalUpdatePerusahaan
          isOpen={modalUpdatPerusahaan.show}
          data={modalUpdatPerusahaan.data}
          type={modalUpdatPerusahaan.type}
          onCancel={() => setModalUpdatePerusahaan({ ...modalUpdatPerusahaan, show: false })}
          onSubmit={updateDataPerusahaan}
          onDelete={deleteDataPerusahaan}
        />
        {/* modal delete perushaan/bills */}
        {modalDelete.show ? (
          <ModalDelete
            minHeight="min-h-[20%]"
            maxWidth="max-w-[22%]"
            minWidth="min-w-[22%["
            title={modalDelete.title}
            desc={modalDelete.desc}
            onClose={() => setModalDelete({ ...modalDelete, show: false })}
            onSubmit={() => {
              let urlTemp = ''
              if (modalDelete.type === 'user') {
                urlTemp = `${urls.master.perusahaan.detail.user.prefix}/${urls.master.perusahaan.detail.user.root}/${modalDelete.id}`
              }
              if (modalDelete.type === 'bill') {
                urlTemp = `${urls.master.perusahaan.detail.user.prefix}/${urls.master.perusahaan.detail.tagihan.root}/${modalDelete.id}`
              }
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                const res = await apiDelete({
                  url: urlTemp,
                  token: user.token,
                  onLogout
                })
                if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                  if (modalDelete.type === 'user') {
                    showSuccessMessage({ content: 'Berhasil Menghapus User Perusahaan' })
                  }
                  if (modalDelete.type === 'bill') {
                    showSuccessMessage({ content: 'Berhasil Menghapus Tagihan' })
                  }
                } else {
                  showErrorMessage({
                    content: `${
                      res.resData.errors[0].message ?? res.resData.message ?? 'Terjadi Kesalahan'
                    }`
                  })
                }
                setModalDelete({ ...modalDelete, show: false })
                setIsLoadingHelper(false)
                onRefetch()
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {/* modal delete unit */}
        {modalDeleteUnit.show ? (
          <ModalDeleteUnit
            minHeight="min-h-[23%]"
            maxWidth="max-w-[25%]"
            state={modalDeleteUnit}
            onClose={() => setModalDeleteUnit({ ...modalDeleteUnit, show: false })}
            onSubmit={() => {
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                const res = await apiDelete({
                  url: `${urls.master.prefix}/${urls.master.perusahaan.detail.unit.root}/${modalDeleteUnit.id}`,
                  token: user.token,
                  onLogout
                })
                if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                  showSuccessMessage({ content: 'Berhasil Menghapus Unit' })
                } else {
                  showErrorMessage({
                    content: `${
                      res.resData.errors[0].message ?? res.resData.message ?? 'Gagal Menghapus Unit'
                    }`
                  })
                }
                onRefetch()
                setIsLoadingHelper(false)
                setModalDeleteUnit({ ...modalDeleteUnit, show: false })
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {/* modal tambah user */}
        {modalTambahUser.show ? (
          <ModalTambahUserPerusahaan
            openModal={modalTambahUser.show}
            onCancel={() => setModalTambahUser({ show: false })}
            onClick={(value) => {
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                const res = await apiGet({
                  url: `${urls.master.perusahaan.detail.user.prefix}/${urls.master.perusahaan.detail.user.search}?keyword=${value}`,
                  token: user.token,
                  onLogout
                })
                if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                  const resAdd = await apiPut({
                    url: `${urls.master.perusahaan.detail.user.prefix}/${urls.master.perusahaan.detail.user.addExistingUser}/${res.resData.data.id}/${id}`,
                    token: user.token,
                    onLogout
                  })
                  if (resAdd.resStatus === 200 && resAdd.resData.meta.http_code === 200) {
                    showSuccessMessage({ content: 'Berhasil Menambah User Perusahaan' })
                  } else {
                    showErrorMessage({
                      content: `${
                        res.resData.message ?? res.resData.errors[0].message ?? 'Terjadi Kesalahan'
                      }`
                    })
                  }
                } else {
                  router.push(`/master/perusahaan/tambahuserperusahaan/${id}`)
                  showWarningMessage({
                    content: 'User belum terdaftar, Silahkan lengkapi form untuk menambah'
                  })
                }
                setModalTambahUser({ show: false })
                onRefetch()
                setIsLoadingHelper(false)
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {/* modal payment proof */}
        {modalPaymentProof.show ? (
          <ModalPaymentProof
            minHeight="min-h-[25%]"
            maxWidth="max-w-[22%]"
            minWidth="min-w-[22%]"
            proof_of_payment_photo={modalPaymentProof.proof_of_payment_photo}
            onClose={() => setModalPaymentProof({ ...modalPaymentProof, show: false })}
          />
        ) : null}
        {/* modal confirmation bills */}
        {modalConfirmationBills.show ? (
          <ModalConfirmationBills
            minHeight="min-h-[25%]"
            maxWidth="max-w-[22%]"
            minWidth="min-w-[22%]"
            onClose={() => setModalConfirmationBills({ ...modalConfirmationBills, show: false })}
            onSubmit={(file) => {
              setIsLoadingHelper(true)
              const formData = new FormData()
              const mount = setTimeout(async () => {
                formData.append('proof_of_payment', file)
                const res = await apiPut({
                  url: `${urls.master.perusahaan.detail.tagihan.prefix}/${urls.master.perusahaan.detail.tagihan.finishManual}/${modalConfirmationBills.id}/finish-manual`,
                  token: user.token,
                  data: formData,
                  isFormData: true,
                  onLogout
                })
                if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                  showSuccessMessage({ content: 'Berhasil Upload Bukti Pembayaran' })
                } else {
                  showErrorMessage({
                    content: `${
                      res.resData.message ?? res.resData.errors[0].message ?? 'Terjadi Kesalahan'
                    }`
                  })
                }
                setModalConfirmationBills({ ...modalConfirmationBills, show: false })
                setIsLoadingHelper(false)
                onRefetch()
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {detailPerusahaan && (
          <InformasiPerusahaanCard
            data={detailPerusahaan}
            tabs={tabs}
            onEditPerusahaan={() =>
              setModalUpdatePerusahaan({
                show: true,
                type: 'edit',
                data: { id: id }
              })
            }
            onDeletePerusahaan={() =>
              setModalUpdatePerusahaan({
                show: true,
                type: 'delete',
                data: { id: id, name: detailPerusahaan?.namaPerusahaan }
              })
            }
          />
        )}
        <div className="flex-1 w-full my-6">
          <TabsActive
            data={tabs}
            onPress={(index) => {
              setSearch('')
              setUserSelector(-1)
              onChangeDataTable(index)
              const tempArr = [...tabs]
              tempArr.map((itemCh, indexCh) => {
                if (index === indexCh) {
                  if (itemCh.active) {
                    return false
                  } else {
                    itemCh.active = true
                  }
                } else {
                  itemCh.active = false
                }
              })
              router.replace(
                `/master/perusahaan/perusahaandetail/${id}/${tabs
                  .find((item, indexCh) => indexCh === index)
                  .name.toLowerCase()}`
              )
              setTabs(tempArr)
            }}
          />
        </div>
        <CardContainer minHeight="60%">
          <div className="flex flex-col w-full space-y-5">
            <div className="flex justify-between items-center">
              <span className="font-semibold text-2xl text-gray-900">
                {onReturnTitleTableCard()}
              </span>
              {tabs[1].active ? (
                <CustomButton
                  onClick={() => {
                    setModalTambahUser({ show: true })
                  }}>
                  <div className="flex gap-2 items-center justify-center">
                    <IcPlus className={'stroke-white'} />
                    <span>Tambah User</span>
                  </div>
                </CustomButton>
              ) : null}
            </div>
            <div className="flex flex-row w-full items-center justify-between">
              {onReturnFormFilter()}
              <div className="w-1/4">
                <Formik
                  initialValues={{
                    searchAll: search
                  }}>
                  <FormInput
                    name="searchAll"
                    placeholder="Pencarian"
                    type="text"
                    isIconLeft
                    iconName="search"
                    onValidate={(value) => setSearch(value)}
                  />
                </Formik>
              </div>
            </div>
            <Table
              pending={
                isFetchingDetailPerusahaan ??
                isLoadingDetailPerusahaan ??
                isLoadingAllUser ??
                isLoadingUnit ??
                isLoadingUser ??
                isLoadingTagihan ??
                isLoadingHelper
              }
              currentPage={meta.offset ? meta.offset + 1 : 1}
              totalPerPage={
                perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
                meta.total
                  ? meta.total
                  : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
              }
              dropDownDataFilter={perPageArr}
              lengthAllData={meta.total}
              columns={onReturnColumnsTable()}
              data={dataTable}
              onChangePrevPage={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onChangePrevNextPage(
                    (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeNextPage={() => {
                if (
                  perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
                  meta.total
                ) {
                  setCurrentPage(currentPage + 1)
                  onChangePrevNextPage(
                    (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeDropDown={(value) => onChangePerPage(value)}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default PerusahaanDetailPage
