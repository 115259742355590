import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Button } from 'components'
import { IcEdit, IcTrash } from 'assets/icons'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import BrandPageComponent from './components/BrandPageComponent'
import ModalBrand from './components/ModalBrand'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  loading: false,
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const BrandPage = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [tableState, setTableState] = useState(initialTableState)
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: null
  })
  const columns = [
    {
      name: 'Brand',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>
    },
    {
      name: 'Asal',
      selector: (row) => row.origin,
      cell: (row) => <span className="font-medium text-gray-700">{row.origin}</span>
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => setModal({ show: true, type: 'edit', data: row })}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ show: true, type: 'delete', data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'brand-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.brand.root}`,
        data: params,
        token: token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const saveData = async (data) => {
    try {
      let res
      let body = {
        logo: data?.logo,
        name: data?.name,
        origin: data?.origin
      }
      if (data?.id) {
        const id = data?.id
        res = await apiPut({
          url: `${urls.master.prefix}/${urls.master.products.brand.root}/${id}`,
          data: body,
          token: token,
          onLogout: () => dispatch(logout())
        })
      } else {
        res = await apiPost({
          url: `${urls.master.prefix}/${urls.master.products.brand.root}`,
          data: body,
          token: token,
          onLogout: () => dispatch(logout())
        })
      }
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({ content: `Successfully ${data?.id ? 'Edit' : 'Create a New'} Brand.` })
        setModal({
          ...modal,
          show: false
        })
      } else {
        showErrorMessage({
          content: `${res.resData.message ?? res.resData.errors[0].message ?? `Failed to ${data?.id ? 'Edit' : 'Create'} Brand.`
            }`
        })
        setTableState((prevState) => ({
          ...prevState,
          loading: false
        }))
      }
      refetch()
    } catch (error) {
      setTableState((prevState) => ({
        ...prevState,
        loading: false
      }))
    }
  }

  const deleteData = async (data) => {
    try {
      setTableState((prevState) => ({
        ...prevState,
        loading: true
      }))
      let res
      res = await apiDelete({
        url: `${urls.master.prefix}/${urls.master.products.brand.root}/${data?.id}`,
        token
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({ content: 'Successfully Delete Brand.' })
        setModal({
          ...modal,
          show: false
        })
        refetch()
      } else {
        showErrorMessage({
          content: `${res.resData.message ?? res.resData.errors[0].message ?? 'Failed to Delete Brand.'
            }`
        })
      }
    } catch (error) {
      setTableState((prevState) => ({
        ...prevState,
        loading: false
      }))
    }
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Master Data
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Produk</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Brand</Breadcrumb.Item>
      </Breadcrumb>
      <ModalBrand
        isOpen={modal.show}
        type={modal.type}
        data={modal.data}
        onSubmit={saveData}
        onDelete={deleteData}
        onCancel={() => setModal({ ...modal, show: false })}
      />
      <BrandPageComponent
        isLoading={isLoading}
        columns={columns}
        data={tableState?.listData}
        onAdd={() => setModal({ show: true, type: 'add', data: null })}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}
export default BrandPage
