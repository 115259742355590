export const invoiceKatalogDataDummies = {
  invoiceNumber: '20220929',
  account: 'Jude Bellingham',
  unit: '2019 Toyota Kijang Innova 2.4G',
  layanan: 'Jual Beli',
  payments: {
    items: [
      {
        name: '2019 Toyota Kijang Innova 2.4G',
        price: 300000000,
        qty: 1
      }
    ],
    discount: 0
  }
}

export const invoiceJadwalServiceDataDummies = {
  invoiceNumber: '20220929',
  account: 'Jude Bellingham',
  unit: '2019 Toyota Kijang Innova 2.4G',
  layanan: 'Servis',
  payments: {
    items: [
      {
        name: 'Shell Helix 5L',
        price: 200000,
        qty: 3
      },
      {
        name: 'Tune Up Mesin',
        price: 500000,
        qty: 1
      }
    ],
    discount: 50000
  }
}

export const invoiceInspeksiDataDummies = {
  invoiceNumber: '20220929',
  account: 'Jude Bellingham',
  unit: '2019 Toyota Kijang Innova 2.4G',
  layanan: 'Inspeksi',
  payments: {
    items: [
      {
        name: 'Biaya Inspeksi',
        price: 500000,
        qty: 1
      }
    ],
    discount: 50000
  }
}

export const invoiceBookingMobilDataDummies = {
  invoiceNumber: '20220929',
  account: 'Jude Bellingham',
  unit: '2019 Toyota Kijang Innova 2.4G',
  layanan: 'Booking Mobil',
  payments: {
    items: [
      {
        name: '2019 Toyota Kijang Innova 2.4G',
        price: 20000000,
        qty: 1
      }
    ],
    discount: 0
  }
}
