import { Card, Image, Typography } from 'antd'
import { CarouselMultipleLayout } from 'layouts/CarouselMultipleLayout'
import React from 'react'

const PhotoCarousel = ({ title, data }) => {
  return (
    <Card className="rounded-lg mb-4">
      {title && (
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-2xl">{title}</Typography.Text>
        </div>
      )}
      <CarouselMultipleLayout slideToShow={4} totalItem={data?.length} className={'cursor-grab'}>
        {data?.map((item, key) => (
          <div key={key}>
            <Image
              className="object-center object-cover pr-4 w-72 h-52"
              src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${item}`}
              alt={`pict${key}`}
              preview
            />
          </div>
        ))}
      </CarouselMultipleLayout>
    </Card>
  )
}

export default PhotoCarousel
