import React, { useEffect, useRef } from 'react'
import { Card, DatePicker, Radio, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button, CalendarCard, FormInput, Table, TabsActive } from 'components'
import { dayPropGetter, eventPropGetterTestDrive } from 'utils'
import { Formik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'

const statusList = [
  { value: 'scheduled', label: 'Terjadwal' },
  { value: 'ongoing', label: 'Sedang berlangsung' },
  { value: 'done', label: 'Selesai' },
  { value: 'not_confirmed', label: 'Belum dikonfirmasi' }
]

const TestDriveDetailComponent = ({
  owners,
  testers,
  calendars,
  detailWorkshop,
  selectedFilter,
  columns,
  filterCalendar,
  data,
  tab,
  handleChangeTab,
  tabExTaxi,
  setTabExTaxi,
  handleChange,
  onChangeDate,
  handleSearch,
  currentPage,
  totalPerPage,
  dropDownDataFilter,
  lengthAllData,
  onChangePrevPage,
  onChangeNextPage,
  onChangeDropDown,
  onPrevWeek,
  onNextWeek,
  onChangeMonth,
  idWorkshop,
  isLoading
}) => {
  const router = useHistory()
  const location = useLocation()
  const bigCalendarRef = useRef(null)

  useEffect(() => {
    if (location?.hash === '' || location?.hash === null || location?.hash === undefined) {
      const mount = setTimeout(
        () =>
          window.requestAnimationFrame(() =>
            bigCalendarRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          ),
        500
      )
      return () => clearTimeout(mount)
    }
  }, [location?.hash])

  return (
    <Content style={{ margin: 8 }}>
      <Card className="rounded-lg mb-4">
        <div className="flex flex-row w-full h-44 justify-between">
          <div className="flex flex-col min-w-[35%] max-w-[35%] space-y-1">
            <Typography.Title level={4} className="my-0 mb-0 font-semibold">
              {detailWorkshop?.name}
            </Typography.Title>
            <Typography.Text className="font-medium text-lg">
              {detailWorkshop?.city_name}
            </Typography.Text>
            <Typography.Text className="font-medium text-gray-500">{`Kota ${detailWorkshop?.city_name}, Provinsi ${detailWorkshop?.province_name}`}</Typography.Text>
          </div>
          <div className="flex flex-col relative overflow-hidden h-full w-full ml-44 items-center justify-center">
            <iframe
              title="map-header-service"
              src={detailWorkshop?.pin_google_map}
              width="100%"
              height="210"
              frameBorder="0"
              style={{
                border: 0,
                borderRadius: 5,
                zIndex: 1,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%'
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              aria-hidden="false"
            />
          </div>
        </div>
      </Card>
      <div className="overflow-hidden" ref={bigCalendarRef}>
        <CalendarCard
          title="Jadwal Test Drive"
          events={calendars}
          dayPropGetter={dayPropGetter}
          eventPropGetter={eventPropGetterTestDrive}
          startDate={new Date(filterCalendar.start_date)}
          onChangeMonth={onChangeMonth}
          onNextWeek={onNextWeek}
          onPrevWeek={onPrevWeek}
        />
      </div>
      <TabsActive data={tabExTaxi} onPress={(index) => setTabExTaxi(index)} />
      <Card className="rounded-lg mt-4">
        <div className="flex flex-col w-full space-y-3">
          <Radio.Group
            className="custom-radio mb-2"
            defaultValue={tab}
            size="large"
            onChange={(e) => handleChangeTab(e.target.value)}>
            <Radio.Button className="custom-radio-left" value={0}>
              Perlu Dikonfirmasi
            </Radio.Button>
            <Radio.Button className="custom-radio-right" value={1}>
              Telah Dikonfirmasi
            </Radio.Button>
          </Radio.Group>
          <div className="flex justify-between items-center">
            <Typography.Text className="font-medium text-lg">
              {tab === 0 ? 'Daftar Unit Perlu Dikonfirmasi' : 'Daftar Unit Telah Dikonfirmasi'}
            </Typography.Text>
            <Button
              className="flex bg-white border border-solid border-gray-300 py-2"
              onClick={() =>
                tabExTaxi.find((item) => item.active === true).name === 'Mobil Bekas'
                  ? router.push(
                      `/test-drive/mobil-bekas/${detailWorkshop?.name}/${idWorkshop}/audit-trail`
                    )
                  : router.push(
                      `/test-drive/ex-taxi/${detailWorkshop?.name}/${idWorkshop}/audit-trail`
                    )
              }>
              <span className="font-medium text-gray-700 text-center text-sm">Audit Trail</span>
            </Button>
          </div>
          <div className="flex flex-row justify-between flex-wrap space-y-3">
            <div className="flex flex-row items-center gap-4">
              {tabExTaxi.find((item) => item.active === true).name === 'Mobil Bekas' ? (
                <Select
                  className="custom-table-limit w-fit text-left [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center"
                  style={{ width: '170px' }}
                  allowClear
                  value={selectedFilter.owner_name}
                  onChange={(e) => handleChange(e ?? '', 'owner_name')}
                  showSearch
                  filterOption={(input, option) => {
                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                  }}>
                  <Select.Option className="custom-table-limit-option" value="">
                    Semua Pemilik
                  </Select.Option>
                  {owners?.map((el, idx) => (
                    <Select.Option key={idx} value={el.value}>
                      {el.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : null}
              <Select
                className="custom-table-limit w-fit text-left [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center"
                style={{ width: '170px' }}
                allowClear
                value={selectedFilter.tester_name}
                onChange={(e) => handleChange(e ?? '', 'tester_name')}
                showSearch
                filterOption={(input, option) => {
                  return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }}>
                <Select.Option className="custom-table-limit-option" value="">
                  Semua Tester
                </Select.Option>
                {testers?.map((el, idx) => (
                  <Select.Option key={idx} value={el.value}>
                    {el.label}
                  </Select.Option>
                ))}
              </Select>
              {tab ? (
                <Select
                  className="custom-table-limit w-fit text-left [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center"
                  style={{ width: '170px' }}
                  allowClear
                  value={selectedFilter.status}
                  onChange={(e) => handleChange(e ?? '', 'status')}
                  showSearch
                  filterOption={(input, option) => {
                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                  }}>
                  <>
                    <Select.Option className="custom-table-limit-option" value="">
                      Semua Status
                    </Select.Option>
                    {statusList?.map((el) => (
                      <Select.Option key={el.value} value={el.value}>
                        {el.label}
                      </Select.Option>
                    ))}
                  </>
                </Select>
              ) : (
                ''
              )}
              <DatePicker.RangePicker
                className="custom-date-picker py-3 w-fit"
                popupClassName="custom-date-picker-dropdown"
                format={['DD/MM/YYYY']}
                placeholder={['Pilih', 'Tanggal']}
                onChange={onChangeDate}
              />
            </div>
            <div className="w-1/4">
              <Formik
                initialValues={{
                  search: ''
                }}>
                <FormInput
                  name="search"
                  placeholder="Pencarian"
                  type="text"
                  isIconLeft
                  iconName="search"
                  onValidate={(value) => handleSearch(value)}
                />
              </Formik>
            </div>
          </div>
          <Table
            columns={columns}
            data={data}
            currentPage={currentPage}
            totalPerPage={totalPerPage}
            dropDownDataFilter={dropDownDataFilter}
            lengthAllData={lengthAllData}
            pending={isLoading}
            onChangePrevPage={onChangePrevPage}
            onChangeNextPage={onChangeNextPage}
            onChangeDropDown={onChangeDropDown}
          />
        </div>
      </Card>
    </Content>
  )
}

export default TestDriveDetailComponent
