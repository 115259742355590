import { apiPost, urls } from 'api'
import { IcCar, IcScissors, IcSearch, IcShoppingBag } from 'assets/icons'
import moment from 'moment'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { showErrorMessage } from 'utils'

const NotificationItem = ({ data, refetch = () => {}, token, onLogout = () => {} }) => {
  const { push } = useHistory()

  const redirectUrl = () => {
    switch (data?.type) {
      case 'servis':
        if (data?.title?.toLowerCase().includes('pickup')) {
          // pickup service section
          if (data?.title?.toLowerCase().includes('pickup servis selesai')) {
            return push(`/services/pickup/detail/${data?.workshop_id}#confirmed`)
          }
          return push(`/services/pickup/detail/${data?.workshop_id}#unconfirmed`)
        } else {
          // jadwal service section
          if (
            data?.title?.toLowerCase().includes('servis selesai') ||
            data?.title?.toLowerCase().includes('servis menunggu pembayaran')
          ) {
            return push(`/services/jadwal/${data?.workshop_id}#confirmed`)
          }
          return push(`/services/jadwal/${data?.workshop_id}#unconfirmed`)
        }

      case 'inspection':
        if (data?.title?.toLowerCase().includes('inspeksi selesai')) {
          return push(`/inspeksi/hasil-inspeksi/${data?.transaction_id}`)
        }
        if (
          // telah dikonfirmasi section
          data?.title?.toLowerCase().includes('inspeksi dikonfirmasi') ||
          data?.title?.toLowerCase().includes('inspeksi dijadwalkan') ||
          data?.title?.toLowerCase().includes('inspeksi berlangsung')
        ) {
          return push(`/inspeksi/jadwal-inspeksi/${data?.workshop_id}#confirmed`)
        }
        // perlu dikonfirmasi section
        return push(`/inspeksi/jadwal-inspeksi/${data?.workshop_id}#unconfirmed`)
      case 'buy':
        return push(`/booking-mobil`)
      case 'sell': {
        if (data?.title?.toLowerCase().includes('in catalog')) {
          return push(`/jual-unit/katalog#mobilBekas`)
        } else {
          return push(`/jual-unit/riwayat-penjualan#mobilBekas`)
        }
      }
      case 'sell-taxi':
        if (data?.title?.toLowerCase().includes('in catalog')) {
          return push(`/jual-unit/katalog#exTaxi`)
        } else {
          return push(`/jual-unit/riwayat-penjualan#exTaxi`)
        }
      case 'test_drive': {
        const result = data?.title?.includes('Penjadwalan') ? 'unconfirmed' : 'confirmed'
        return push(`/test-drive/${data?.workshop_id}#${result}&mobil-bekas`)
      }
      case 'test-drive-taxi': {
        const result = data?.title?.includes('Penjadwalan') ? 'unconfirmed' : 'confirmed'
        return push(`/test-drive/${data?.workshop_id}#${result}&ex-taxi`)
      }
      default:
        return showErrorMessage()
    }
  }

  const renderIcon = () => {
    switch (data?.type) {
      case 'servis':
        return (
          <IcScissors
            className={`${data?.is_read ? 'text-gray-700' : 'text-primary-500'} text-lg mt-1`}
          />
        )
      case 'inspection':
        return (
          <IcSearch
            className={`${data?.is_read ? 'text-gray-700' : 'text-primary-500'} text-lg mt-1`}
          />
        )
      case 'buy':
        return (
          <IcShoppingBag
            className={`${data?.is_read ? 'text-gray-700' : 'text-primary-500'} text-lg mt-1`}
          />
        )

      case 'sell':
        return (
          <IcCar
            className={`${data?.is_read ? 'text-gray-700' : 'text-primary-500'} text-lg mt-1`}
          />
        )

      default:
        return (
          <IcScissors
            className={`${data?.is_read ? 'text-gray-700' : 'text-primary-500'} text-lg mt-1`}
          />
        )
    }
  }

  const readNotification = useMutation({
    mutationKey: 'read-notification',
    mutationFn: async (id) => {
      const res = await apiPost({
        token,
        url: `${urls.auth.user.prefix}/${urls.auth.user.notificationRead}/${id}`,
        onLogout: onLogout
      })
      return res
    },
    onSuccess: (res) => {
      if (res?.resStatus !== 200 && res?.resStatus !== 400) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      } else {
        refetch()
        redirectUrl()
      }
    }
  })

  return (
    <div
      className="flex flex-row gap-3 cursor-pointer"
      onClick={() => {
        data?.is_read ? redirectUrl() : readNotification?.mutate(data?.id)
      }}>
      {renderIcon()}
      <div className="flex flex-col w-full">
        <div
          className={`${
            data?.is_read ? 'text-gray-700' : 'text-primary-500'
          } font-medium text-base`}>
          {data?.title}
        </div>
        <div className="flex flex-row gap-2 justify-between">
          <div className="flex flex-col flex-1 space-y-0.5">
            <div className=" text-gray-700 text-base">{data?.body}</div>
            <div className=" text-gray-500 tracking-tight text-sm">
              {data?.unit_police_number && data?.unit_police_number !== ''
                ? data?.unit_police_number
                : '-'}
            </div>
          </div>
          <div className="text-gray-500 flex-2">
            {data?.created_at ? moment(data?.created_at).fromNow() : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationItem
