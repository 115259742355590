import React, { useState } from 'react'
import { Breadcrumb, Layout, DatePicker } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { CardContainer, FormInput, HeaderContainer, Table, TabsActive } from 'components'
import { Formik } from 'formik'
import { ModalUpdateBiayaLayanan, ModalUpdateTax } from './components'
import { IcEdit } from 'assets/icons'
import moment from 'moment'
import 'moment/locale/en-gb'
import { formatCurrency, showErrorMessage, showSuccessMessage } from 'utils'
import { metaInitialData, perPageInitialData } from 'constant'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet, apiPut, urls } from 'api'
import { useQuery } from 'react-query'
import { logout } from 'store/actions'

const ConfigurationPage = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [currentPage, setCurrentPage] = useState(0)
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)
  const [meta, setMeta] = useState(metaInitialData)
  const [dataTable, setDataTable] = useState([])
  const [filterAudit, setFilterAudit] = useState({
    start_date: '',
    end_date: '',
    dateRangeValue: []
  })
  const [search, setSearch] = useState('')

  const [tabs, setTabs] = useState([
    { name: 'Audit Trail', active: true },
    { name: 'Tax', active: false },
    { name: 'Biaya Layanan', active: false }
  ])

  const onLogout = () => {
    dispatch(logout())
  }

  const {
    isLoading: isLoadingAuditTrail,
    isRefetching: isRefetchAuditTrail,
    refetch: refetchAuditTrail
  } = useQuery({
    queryKey: ['configuration-audit-trail', filterAudit?.start_date, filterAudit?.end_date],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls?.auth.user.prefix}/${urls?.auth.user.auditTrail}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${meta.offset ?? 0}&start_date=${filterAudit?.start_date}&end_date=${
          filterAudit?.end_date
        }&username=${search}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDataTable(data.resData.data)
      setMeta(data.resData.meta)
    }
  })

  const {
    isLoading: isLoadingTax,
    isRefetching: isRefetchTax,
    refetch: refetchTax
  } = useQuery({
    queryKey: ['configuration-tax'],
    refetchOnWindowFocus: false,
    enabled: !!tabs.find((item) => item.active === true).name === 'Tax',
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.configuration.tax.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${meta.offset ?? 0}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDataTable(data.resData.data)
      setMeta(data.resData.meta)
    }
  })

  const {
    isLoading: isLoadingBiayaLayanan,
    isRefetching: isRefetchBiayaLayanan,
    refetch: refetchBiayaLayanan
  } = useQuery({
    queryKey: ['configuration-biaya-layanan'],
    refetchOnWindowFocus: false,
    enabled: !!tabs.find((item) => item.active === true).name === 'Biaya Layanan',
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.configuration.biayaLayanan.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${meta.offset ?? 0}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDataTable(data.resData.data)
      setMeta(data.resData.meta)
    }
  })

  const [modalUpdateTax, setModalUpdateTax] = useState({
    show: false,
    id: 0,
    name: '',
    percentage: ''
  })

  const [modalUpdateBiayaLayanan, setModalUpdateBiayaLayanan] = useState({
    show: false,
    id: 0,
    name: '',
    besarBiaya: ''
  })

  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  const onRefetch = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Audit Trail':
        return refetchAuditTrail()
      case 'Tax':
        return refetchTax()
      case 'Biaya Layanan':
        return refetchBiayaLayanan()
    }
  }

  const onSearch = async (value) => {
    setSearch(value)
    setMeta((prev) => ({ ...prev, offset: 0 }))
    let urlTemp = ''
    const tabActive = tabs.find((item) => item.active === true).name
    if (tabActive === 'Biaya Layanan') {
      urlTemp = `${urls.master.prefix}/${
        urls.master.configuration.biayaLayanan.root
      }?keywords=${value}&limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${0}`
    }
    if (tabActive === 'Tax') {
      urlTemp = `${urls.master.prefix}/${
        urls.master.configuration.tax.root
      }?keywords=${value}&limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${0}`
    }
    if (tabActive === 'Audit Trail') {
      urlTemp = `${urls?.auth.user.prefix}/${urls?.auth.user.auditTrail}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${0}&start_date=${filterAudit?.start_date}&end_date=${
        filterAudit?.end_date
      }&username=${value}`
    }
    const res = await apiGet({
      url: urlTemp,
      token: user.token,
      onLogout
    })
    if (res.resStatus === 200) {
      setDataTable(res.resData.data)
      setMeta(res.resData.meta)
    } else {
      onRefetch()
    }
  }

  const onHandleExtraUrl = () => {
    let extraUrl = ''
    const tabActive = tabs.find((item) => item.active === true).name

    if (tabActive === 'Audit Trail') {
      extraUrl += `&username=${search}&start_date=${filterAudit?.start_date}&end_date=${filterAudit?.end_date}`
    } else {
      if (search && search !== '') {
        extraUrl += `&keywords=${search}`
      }
    }

    return extraUrl
  }

  const onChangePrevNextPage = (offset) => {
    let urlTemp = ''
    setMeta({ ...meta, offset })
    const tabActive = tabs.find((item) => item.active === true).name
    if (tabActive === 'Biaya Layanan') {
      urlTemp = `${urls.master.prefix}/${urls.master.configuration.biayaLayanan.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    if (tabActive === 'Tax') {
      urlTemp = `${urls.master.prefix}/${urls.master.configuration.tax.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    if (tabActive === 'Audit Trail') {
      urlTemp = `${urls?.auth.user.prefix}/${urls?.auth.user.auditTrail}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    setIsLoadingHelper(true)
    const mount = setTimeout(async () => {
      const res = await apiGet({
        url: urlTemp,
        token: user.token,
        onLogout
      })
      if (res.resStatus === 200) {
        setDataTable(res.resData.data)
      } else {
        onRefetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    let urlTemp = ''
    const tabActive = tabs.find((item) => item.active === true).name
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    if (tabActive === 'Biaya Layanan') {
      urlTemp = `${urls.master.prefix}/${
        urls.master.configuration.biayaLayanan.root
      }?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`
    }
    if (tabActive === 'Tax') {
      urlTemp = `${urls.master.prefix}/${
        urls.master.configuration.tax.root
      }?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`
    }
    if (tabActive === 'Audit Trail') {
      urlTemp = `${urls?.auth.user.prefix}/${urls?.auth.user.auditTrail}?limit=${value}&offset=${
        meta.offset ?? 0
      }${onHandleExtraUrl()}`
    }
    setIsLoadingHelper(true)
    const mount = setTimeout(async () => {
      const res = await apiGet({
        url: urlTemp,
        token: user.token,
        onLogout
      })
      if (res.resStatus === 200) {
        setDataTable(res.resData.data)
        setMeta(res.resData.meta)
        setPerPageArr(newArr)
      } else {
        onRefetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  //* Table State //*
  const onChangeDataTable = (index) => {
    setSearch('')
    if (index === 0) {
      refetchAuditTrail()
    }
    if (index === 1) {
      refetchTax()
    }
    if (index === 2) {
      refetchBiayaLayanan()
    }
  }

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates) {
      setFilterAudit((prev) => ({
        ...prev,
        dateRangeValue: dates,
        start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('DD/MM/YYYY'),
        end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('DD/MM/YYYY')
      }))
      setMeta((prev) => ({ ...prev, offset: 0 }))
    } else {
      setFilterAudit((prev) => ({
        ...prev,
        dateRangeValue: [],
        start_date: '',
        end_date: ''
      }))
      setMeta((prev) => ({ ...prev, offset: 0 }))
    }
  }

  const columnsAuditTrail = [
    {
      name: 'Tanggal',
      selector: (row) => row.date_time,
      cell: (row) => (
        <span className="font-medium text-gray-700">
          {moment(row?.date_time).format('DD/MM/YYYY')}
        </span>
      )
    },
    {
      name: 'Waktu',
      selector: (row) => row.date_time,
      cell: (row) => (
        <span className="font-medium text-gray-700">{moment(row?.date_time).format('HH:mm')}</span>
      )
    },
    {
      name: 'User',
      selector: (row) => row.username,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <span className="font-medium text-gray-700">{`${row?.username ?? '-'}`}</span>
          <span className="font-medium text-gray-500">{`${row?.user_email ?? '-'}`}</span>
        </div>
      )
    },
    {
      name: 'Menu',
      selector: (row) => row.menu,
      cell: (row) => (
        <div className="capitalize font-medium text-gray-700">
          {row?.menu?.replaceAll('_', ' ')?.toLowerCase()}
        </div>
      )
    },
    {
      name: 'Aksi',
      selector: (row) => row.action,
      cell: (row) => <span className="font-medium text-gray-700">{row?.action}</span>
    }
  ]

  const columnsTax = [
    {
      name: 'Nama Tax',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>
    },
    {
      name: 'Besar Biaya',
      selector: (row) => row.percentage,
      cell: (row) => <span className="font-medium text-gray-700">{`${row.percentage} %`}</span>
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              setModalUpdateTax({
                show: true,
                id: row.id,
                name: row.name,
                percentage: row.percentage
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div>
        </div>
      )
    }
  ]

  const columnsBiayaLayanan = [
    {
      name: 'Nama Biaya Layanan',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>
    },
    {
      name: 'Besar Biaya',
      selector: (row) => row.price,
      cell: (row) => <span className="font-medium text-gray-700">{formatCurrency(row.price)}</span>
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              setModalUpdateBiayaLayanan({
                show: true,
                id: row.id,
                name: row.name,
                besarBiaya: row.price
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div>
        </div>
      )
    }
  ]

  const onReturnColumnsTable = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Audit Trail':
        return columnsAuditTrail
      case 'Tax':
        return columnsTax
      case 'Biaya Layanan':
        return columnsBiayaLayanan
    }
  }

  const onReturnFormFilter = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Audit Trail':
        return (
          <div className="flex flex-row w-full items-center space-x-5">
            <div className="w-2/4 h-full">
              <RangePicker
                onOpenChange={(open) => setIsShowDatePicker(open)}
                onChange={handleChangeRangePicker}
                format="DD/MM/YYYY"
                open={isShowDatePicker}
                value={filterAudit?.dateRangeValue}
                placeholder={['Tanggal Mulai', 'Tanggal Akhir']}
                className="min-w-1/4 py-3 rounded-md hover:cursor-pointer font-medium text-gray-700 focus:!border-primary-500 active:border-primary-500"
                renderExtraFooter={() => {
                  return (
                    <div className="flex flex-row justify-end items-center space-x-4 my-3">
                      <button
                        type="button"
                        className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-gray-700 bg-white"
                        onClick={() => {
                          setIsShowDatePicker(false)
                        }}>
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-white bg-primary-500"
                        onClick={() => {
                          setIsShowDatePicker(false)
                        }}>
                        Apply
                      </button>
                    </div>
                  )
                }}
              />
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Layout
      style={{
        padding: 24,
        background: '#F5F5F5',
        width: '100%'
      }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Konfigurasi
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {modalUpdateTax.show ? (
          <ModalUpdateTax
            state={modalUpdateTax}
            onClose={() => setModalUpdateTax({ ...modalUpdateTax, show: false })}
            onSubmit={(values) => {
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                const res = await apiPut({
                  url: `${urls.master.prefix}/${urls.master.configuration.tax.root}/${modalUpdateTax.id}`,
                  data: {
                    percentage: parseFloat(values.percentage),
                    name: modalUpdateTax.name
                  },
                  token: user.token,
                  onLogout
                })
                if (res.resStatus === 200) {
                  showSuccessMessage({ content: 'Successfully update tax' })
                } else {
                  showErrorMessage({
                    content: `${res.resData.errors[0].message ?? 'Failed update tax'}`
                  })
                }
                refetchTax()
                setModalUpdateTax({ ...modalUpdateTax, show: false })
                setIsLoadingHelper(false)
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {modalUpdateBiayaLayanan.show ? (
          <ModalUpdateBiayaLayanan
            state={modalUpdateBiayaLayanan}
            onClose={() => setModalUpdateBiayaLayanan({ ...modalUpdateBiayaLayanan, show: false })}
            onSubmit={(values) => {
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                const res = await apiPut({
                  url: `${urls.master.prefix}/${urls.master.configuration.biayaLayanan.root}/${modalUpdateBiayaLayanan.id}`,
                  data: { price: values, name: modalUpdateBiayaLayanan.name },
                  token: user.token,
                  onLogout
                })
                if (res.resStatus === 200) {
                  showSuccessMessage({ content: 'Successfully update biaya layanan' })
                } else {
                  showErrorMessage({
                    content: `${res.resData.errors[0].message ?? 'Failed update biaya layanan'}`
                  })
                }
                refetchBiayaLayanan()
                setModalUpdateBiayaLayanan({ ...modalUpdateBiayaLayanan, show: false })
                setIsLoadingHelper(false)
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        <div className="flex-1 w-full mb-6 mt-2">
          <TabsActive
            data={tabs}
            onPress={(index) => {
              onChangeDataTable(index)
              setSearch('')
              const tempArr = [...tabs]
              tempArr.map((itemCh, indexCh) => {
                if (index === indexCh) {
                  if (itemCh.active) {
                    return false
                  } else {
                    itemCh.active = true
                  }
                } else {
                  itemCh.active = false
                }
              })
              setTabs(tempArr)
            }}
          />
        </div>
        <CardContainer minHeight="60%">
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer title={`${tabs.find((item) => item.active === true).name}`} />
            <div className="flex flex-row w-full items-center justify-between mt-2">
              {onReturnFormFilter()}
              <div className="w-1/4">
                <Formik
                  initialValues={{
                    search: search
                  }}>
                  <FormInput
                    name="search"
                    placeholder="Pencarian"
                    type="text"
                    isIconLeft
                    iconName="search"
                    onValidate={(value) => onSearch(value)}
                  />
                </Formik>
              </div>
            </div>
            <Table
              pending={
                isLoadingHelper ??
                isLoadingAuditTrail ??
                isLoadingTax ??
                isLoadingBiayaLayanan ??
                isRefetchAuditTrail ??
                isRefetchTax ??
                isRefetchBiayaLayanan
              }
              currentPage={meta.offset ? meta.offset + 1 : 1}
              totalPerPage={
                perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
                meta.total
                  ? meta.total
                  : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
              }
              dropDownDataFilter={perPageArr}
              lengthAllData={meta.total}
              columns={onReturnColumnsTable()}
              data={dataTable}
              onChangePrevPage={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onChangePrevNextPage(
                    (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeNextPage={() => {
                if (
                  perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
                  meta.total
                ) {
                  setCurrentPage(currentPage + 1)
                  onChangePrevNextPage(
                    (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeDropDown={(value) => onChangePerPage(value)}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}
export default ConfigurationPage
