import React from 'react'
import { Breadcrumb, Layout } from 'antd'
import ServisMobilComponentStep1 from './components/ServisMobilComponentStep1'
import ServisMobilComponentStep2 from './components/ServisMobilComponentStep2'
import ServisMobilComponentStep3 from './components/ServisMobilComponentStep3'
import ServisMobilComponentStep4 from './components/ServisMobilComponentStep4'

const ServisMobil = (props) => {
  const step = props?.history?.location?.state?.step ?? 1
  const data = props?.history?.location?.state?.data ?? {}
  const simulateData = props?.history?.location?.state?.simulateData ?? {}
  return (
    <Layout className="min-h-full" style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => props.history.go(-2 + (-1 * step + 1))}>
          Internal Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => props.history.go(-1 + (-1 * step + 1))}>
          {data?.unit_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item>Servis Mobil</Breadcrumb.Item>
      </Breadcrumb>
      {step === 1 && <ServisMobilComponentStep1 data={data} />}
      {step === 2 && <ServisMobilComponentStep2 data={data} />}
      {step === 3 && <ServisMobilComponentStep3 data={data} simulateData={simulateData} />}
      {step === 4 && <ServisMobilComponentStep4 data={data} simulateData={simulateData} />}
    </Layout>
  )
}

export default ServisMobil
