import React from 'react'
import {
  CardContainer,
  CatatanCard,
  CyocExTaxiCard,
  InformasiUnitCard,
  InformasiUnitExTaxiCard,
  InformasiUserCard,
  PhotoCarouselAlternativeCard,
  SpesifikasiExTaxiCard
} from 'components'
import _ from 'lodash'
import { formatCurrency } from 'utils'

const BookingLunasExTaxiContent = ({ data }) => {
  return !data ? (
    <CardContainer>
      <span className="font-medium text-lg text-gray-900">
        Data Booking Lunas ex-Taxi Tidak Ditemukan.
      </span>
    </CardContainer>
  ) : (
    <div className="flex flex-col w-full space-y-5">
      <InformasiUnitCard
        unit={data.taxi.name}
        status={data.status && data.status !== '' ? 'dijual' : '-'}
        harga={formatCurrency(data.taxi.price)}
      />
      <InformasiUserCard
        title="Informasi Pembeli"
        data={{
          nama:
            data.buyer_fullname && data.buyer_fullname !== ''
              ? _.startCase(data.buyer_fullname)
              : '-',
          email: data.buyer_email && data.buyer_email !== '' ? data.buyer_email : '-',
          telepon:
            data.buyer_phone_number && data.buyer_phone_number !== ''
              ? data.buyer_phone_number
              : '-',
          alamat:
            data.buyer_address && data.buyer_address !== '' ? _.startCase(data.buyer_address) : '-'
        }}
      />
      <InformasiUnitExTaxiCard
        data={{
          merek: data.taxi.brand_name && data.taxi.brand_name !== '' ? data.taxi.brand_name : '-',
          model: data.taxi.model_name && data.taxi.model_name !== '' ? data.taxi.model_name : '-',
          transmisi:
            data.taxi.transmission_name && data.taxi.transmission_name !== ''
              ? data.taxi.transmission_name
              : '-',
          bahanBakar: data.taxi.fuel_name && data.taxi.fuel_name !== '' ? data.taxi.fuel_name : '-',
          warna: data.color_name && data.color_name !== '' ? data.color_name : '-',
          tahunKeluaran: data.taxi.year && data.taxi.year !== '' ? data.taxi.year : '-'
        }}
      />
      <CyocExTaxiCard data={data.cyoc_spareparts} />
      <SpesifikasiExTaxiCard data={data} />
      <CatatanCard
        desc={
          data.taxi.description && data.taxi.description !== ''
            ? data.taxi.description
            : 'Tidak ada catatan.'
        }
      />
      <PhotoCarouselAlternativeCard data={data.taxi.photos} />
    </div>
  )
}

export default BookingLunasExTaxiContent
