import moment from 'moment'

export const disabledDatePicker = (current) => {
  let customDate = moment().format('DD/MM/YYYY')
  return current && current < moment(customDate, 'DD/MM/YYYY')
}

// disable current date if time more than 17:00
export const disabledDateTimeWorkingPicker = (current) => {
  const currentHours = moment().format('HH:mm')
  const endHours = moment('16:01', 'HH:mm').format('HH:mm')
  let customDate = moment().format('DD/MM/YYYY')
  if (currentHours < endHours) {
    // disable before today
    return current && current < moment(customDate, 'DD/MM/YYYY')
  } else {
    // disable today and before today
    return current && current < moment().endOf('day')
  }
}

// date FROM PROPS MUST FORMAT DD/MM/YYYY
export const disabledTimeDropdown = (time, date) => {
  const hour = time.split('-')
  const currentDate = moment().format('DD/MM/YYYY')
  const currentHours = moment().format('HH:mm')

  if (moment(date, 'DD/MM/YYYY') > moment(currentDate, 'DD/MM/YYYY')) {
    return false
  } else {
    if (moment(date, 'DD/MM/YYYY') < moment(currentDate, 'DD/MM/YYYY')) {
      return true
    } else {
      if (moment(hour[0], 'HH:mm') < moment(currentHours, 'HH:mm')) {
        return true
      } else {
        return false
      }
    }
  }
}

const range = (start, end) => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

// date FROM PROPS MUST FORMAT DD/MM/YYYY
export const disabledDateTimePicker = (date) => ({
  disabledHours: () => {
    const currentDate = moment().format('DD/MM/YYYY')
    const currentHours = moment().format('HH')

    if (moment(date, 'DD/MM/YYYY') > moment(currentDate, 'DD/MM/YYYY')) {
      return range(0, 24).splice(24)
    } else {
      if (moment(date, 'DD/MM/YYYY') < moment(currentDate, 'DD/MM/YYYY')) {
        return range(0, 24).splice(0)
      } else {
        return range(0, 24).slice(0, +currentHours + 1)
      }
    }
  },
  disabledMinutes: () => {
    const currentDate = moment().format('DD/MM/YYYY')

    if (moment(date, 'DD/MM/YYYY') >= moment(currentDate, 'DD/MM/YYYY')) {
      return range(0, 60).splice(60)
    } else {
      return range(0, 60).splice(0)
    }
  }
})
