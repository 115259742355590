import React, { useState } from 'react'
import { Breadcrumb, Checkbox, Layout } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'

import { IcEdit, IcTrash } from 'assets/icons'
import { Button } from 'components'
import KeteranganInspeksiComponent from './components/KeteranganInspeksiComponent'
import ModalKeteranganInspeksi from './components/ModalKeteranganInspeksi'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { logout } from 'store/actions'
import { showErrorMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const KeteranganInspeksi = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: {}
  })
  const [tableState, setTableState] = useState(initialTableState)
  const columns = [
    {
      name: 'Kategori',
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'Unggah Gambar',
      selector: (row) => row.is_upload_image,
      cell: (row) => (
        <Checkbox
          className="custom-checkbox"
          checked={row.is_upload_image === 1}
          onChange={() =>
            saveData({
              ...row,
              is_upload_image: row.is_upload_image === 1 ? 0 : 1
            })
          }
        />
      )
    },
    {
      name: 'Deskripsi',
      selector: (row) => row.is_description,
      cell: (row) => (
        <Checkbox
          className="custom-checkbox"
          checked={row.is_description === 1}
          onChange={() =>
            saveData({
              ...row,
              is_description: row.is_description === 1 ? 0 : 1
            })
          }
        />
      )
    },
    {
      name: 'OK',
      selector: (row) => row.is_ok,
      cell: (row) => (
        <Checkbox
          className="custom-checkbox"
          checked={row.is_ok === 1}
          onChange={() =>
            saveData({
              ...row,
              is_ok: row.is_ok === 1 ? 0 : 1
            })
          }
        />
      )
    },
    {
      name: 'Perlu Perbaikan',
      selector: (row) => row.is_need_repair,
      cell: (row) => (
        <Checkbox
          className="custom-checkbox"
          checked={row.is_need_repair === 1}
          onChange={() =>
            saveData({
              ...row,
              is_need_repair: row.is_need_repair === 1 ? 0 : 1
            })
          }
        />
      )
    },
    {
      name: 'Point',
      selector: (row) => row.point,
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
            onClick={() => setModal({ show: true, type: 'edit', data: row })}>
            <IcEdit className="text-gray-700" size={18} />
          </Button>
          <Button
            className="bg-white border-solid border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ show: true, type: 'delete', data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'keterangan-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        url: `inspeksi-service/admin/inspection-descriptions`,
        data: params,
        token
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const saveData = async (data) => {
    try {
      let res
      let body = {
        name: data?.name,
        is_upload_image: data?.is_upload_image,
        is_description: data?.is_description,
        is_ok: data?.is_ok,
        is_need_repair: data?.is_need_repair,
        point: data?.point
      }

      if (data?.id) {
        const id = data?.id
        res = await apiPut({
          url: `inspeksi-service/admin/inspection-descriptions/${id}`,
          data: body,
          token,
          onLogout: () => dispatch(logout())
        })
      } else {
        res = await apiPost({
          url: `inspeksi-service/admin/inspection-descriptions`,
          data: body,
          token,
          onLogout: () => dispatch(logout())
        })
      }
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        refetch()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteData = async (data) => {
    try {
      setTableState((prevState) => ({
        ...prevState,
        loading: true
      }))
      let res
      res = await apiDelete({
        url: `inspeksi-service/admin/inspection-descriptions/${data?.id}`,
        token,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        refetch()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Master Data</Breadcrumb.Item>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item>Bagian Inspeksi</Breadcrumb.Item>
      </Breadcrumb>
      <ModalKeteranganInspeksi
        isOpen={modal.show}
        type={modal.type}
        data={modal.data}
        onSubmit={saveData}
        onDelete={deleteData}
        onCancel={() => setModal({ ...modal, show: false })}
      />
      <KeteranganInspeksiComponent
        isLoading={isLoading}
        columns={columns}
        data={tableState?.listData}
        onAdd={() => setModal({ show: true, type: 'add', data: {} })}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}

export default KeteranganInspeksi
