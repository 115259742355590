import React, { useState, useCallback } from 'react'
import { Field, ErrorMessage } from 'formik'
import { disabledTimeDropdown } from 'utils'
import { Select } from 'antd'

const FormDropDown = ({
  label,
  name,
  isRequired,
  isEmpty = false,
  errorMessage = '',
  data,
  onChange = () => {},
  status,
  date,
  isSearch = false,
  allowClear = false,
  placeholder = 'Pilih'
}) => {
  return (
    <div className="w-full h-full">
      {label && (
        <label htmlFor={name} className="font-medium block mb-2 text-md text-gray-700">
          {label}
          {isRequired && <span className="text-red-600">{' *'}</span>}
        </label>
      )}
      <div className="relative">
        <Field
          name={name}
          id={name}
          validate={(value) => {
            onChange(value)
          }}
          className="font-medium w-full text-left px-3 py-3 bg-white border border-solid border-gray-300 rounded-md focus:border-primary-500 focus:ring-primary-500 focus:outline-none focus:ring focus:ring-opacity-20 hover:cursor-pointer">
          {({ field, form: { touched, errors } }) => {
            return (
              <Select
                onChange={onChange}
                value={typeof field?.value === 'string' ? `${field?.value}` : field?.value}
                onBlur={(e) => e.preventDefault()}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
                placeholder={placeholder}
                showSearch={isSearch}
                allowClear={allowClear}
                filterOption={(input, option) => {
                  return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                className="custom-table-limit mr-3 w-full text-left [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center">
                {data?.map((item, idx) => {
                  return (
                    <Select.Option
                      key={idx}
                      value={typeof item?.value === 'string' ? `${item.value}` : item?.value}
                      disabled={date ? disabledTimeDropdown(item.name, date) : false}>
                      {item?.name}
                    </Select.Option>
                  )
                })}
              </Select>
            )
          }}
        </Field>
        <ErrorMessage
          name={name}
          render={(msg) => (
            <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
              {`*${msg}`}
            </div>
          )}
        />
        {isEmpty ? (
          <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
            {`*${errorMessage}`}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FormDropDown
