import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'

import { IcPlus, IcSearch } from 'assets/icons'
import { Button, Table } from 'components'
import { useHistory } from 'react-router-dom'
import ModalSparepartUnit from './ModalSparepartUnit'

const UpdateSparepartUnitComponent = ({
  type,
  columns,
  brandList,
  forms,
  modelList,
  typeList,
  setTableState,
  isLoading,
  setForms,
  setSearch,
  onSave
}) => {
  const [modal, setModal] = useState(false)
  const [searchUnit, setSearchUnit] = useState('')
  const router = useHistory()

  const handleForms = useCallback((key, value) => {
    setForms((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }, [])

  const handleSearch = useCallback((key, value) => {
    setSearch((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }, [])

  const filterSparepartUnit = useMemo(() => {
    // return forms?.unit_spareparts
    return forms?.unit_spareparts?.filter((item) =>
      item.name?.toLowerCase().includes(searchUnit.toLowerCase())
    )
  }, [searchUnit, forms?.unit_spareparts])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  return (
    <Content className="h-full">
      <ModalSparepartUnit
        isOpen={modal}
        data={forms.unit_spareparts}
        onSubmit={(data) => {
          let newData = data?.map((item) => {
            return {
              ...item,
              qty: parseInt(item.qty)
            }
          })
          const temp = [...forms.unit_spareparts, ...newData]
          handleForms('unit_spareparts', temp)
          setModal(false)
        }}
        onCancel={() => setModal(false)}
      />
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-xl">
            {type === 'edit' ? 'Edit Sparepart Unit' : 'Tambah Sparepart Unit'}
          </Typography.Text>
        </div>
        <div className="mb-2">
          <div className="ml-[1px]">
            <Typography.Text className="font-medium text-gray-700">Brand</Typography.Text>
          </div>
          <Select
            showSearch
            className="custom-table-limit"
            placeholder="Pilih Brand"
            style={{ width: '50%' }}
            value={forms?.brand_id}
            onSearch={(e) => handleSearch('brand', e)}
            onChange={(value) => {
              if (forms?.brand_id !== value) {
                handleForms('model_id', null)
                handleForms('type_id', null)
              }
              handleForms('brand_id', value)
            }}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {brandList?.map((item) => (
              <Select.Option key={item?.id?.toString()} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="mb-2">
          <div className="ml-[1px]">
            <Typography.Text className="font-medium text-gray-700">Model</Typography.Text>
          </div>
          <Select
            className="custom-table-limit"
            placeholder="Pilih Model"
            disabled={!forms?.brand_id}
            style={{ width: '50%' }}
            value={forms?.model_id}
            onChange={(value) => {
              if (forms?.model_id !== value) {
                handleForms('type_id', null)
              }
              handleForms('model_id', value)
            }}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch>
            {modelList?.map((item) => (
              <Select.Option key={item?.id?.toString()} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="mb-2">
          <div className="ml-[1px]">
            <Typography.Text className="font-medium text-gray-700">Tipe</Typography.Text>
          </div>
          <Select
            className="custom-table-limit"
            placeholder="Pilih Tipe"
            disabled={!forms?.model_id}
            style={{ width: '50%' }}
            value={forms?.type_id}
            onChange={(value) => {
              handleForms('type_id', value)
            }}
            showSearch
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {typeList?.map((item) => (
              <Select.Option key={item?.id?.toString()} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Card>
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5 items-center">
          <Typography.Text className="font-semibold text-xl">List Sparepart</Typography.Text>
          <Button
            className="flex flex-row items-center justify-around rounded-md space-x-1 px-2 text-md text-white bg-primary-500 border-primary-500"
            onClick={() => setModal(true)}>
            <IcPlus className="text-white" size={19} />
            <span>Tambahkan Sparepart</span>
          </Button>
        </div>
        <div className="mb-5 flex justify-between">
          {/* <div className='flex gap-x-2'>
                        <Select className="custom-table-limit" style={{ width: '150px' }} defaultValue="">
                            <Select.Option className="custom-table-limit-option" value="">Semua Kategori</Select.Option>
                        </Select>
                    </div> */}
          <Input
            className="w-1/3 rounded-md py-1.5"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            value={searchUnit}
            onChange={(e) => setSearchUnit(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={filterSparepartUnit}
          pending={isLoading}
          onSort={handleSort}
          useFooter={false}
          className="table-overflow-y"
        />
      </Card>
      <div className="flex items-center justify-end bg-red-20 mt-4">
        <Button
          className={'text-gray-500 bg-gray-50 border-gray-500 mr-4'}
          onClick={() => router.goBack()}>
          Cancel
        </Button>
        <Button
          className={`${!forms?.brand_id ||
            !forms?.model_id ||
            !forms?.type_id
            // filterSparepartUnit?.length < 1
            ? 'text-gray-500 bg-gray-200 border-gray-300'
            : 'text-white bg-primary-500 border-primary-500 flex items-center'
            }`}
          disabled={
            !forms?.brand_id ||
            !forms?.model_id ||
            !forms?.type_id ||
            // filterSparepartUnit?.length < 1 ||
            isLoading
          }
          onClick={onSave}>
          {
            isLoading ?
              <LoadingIcon />
              : 'Submit'
          }
        </Button>
      </div>
    </Content>
  )
}

UpdateSparepartUnitComponent.propTypes = {
  columns: PropTypes.array,
  tableState: PropTypes.object,
  type: PropTypes.string,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  setTableState: PropTypes.func,
  isLoading: PropTypes.bool
}

UpdateSparepartUnitComponent.defaultProps = {
  columns: PropTypes.array,
  tableState: {},
  type: 'add',
  data: [],
  onAdd: () => { },
  setTableState: () => { },
  isLoading: false
}

export default UpdateSparepartUnitComponent

const LoadingIcon = () => {
  return (
    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
}