import { Breadcrumb, Layout } from 'antd'
import { apiGet, apiPut, urls } from 'api'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import UpdateBengkelComponent from './components/UpdateBengkelComponent'
import { showErrorMessage } from 'utils'
import { logout } from 'store/actions'

const EditBengkel = () => {
  const token = useSelector((state) => state.auth.token)
  const { namaBengkel } = useParams()
  const idCabang = namaBengkel?.split('-')?.[namaBengkel?.split('-')?.length - 1]
  const router = useHistory()
  const [dataCabang, setDataCabang] = useState({})
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  // Get Detail Cabang
  const { isLoading, isFetching } = useQuery({
    queryKey: ['detail-cabang', namaBengkel],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}/${idCabang}`,
        onLogout: onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDataCabang(res?.resData?.data)
    }
  })

  // Submit
  const handleSubmit = async (data) => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiPut({
        url: `${urls.master.prefix}/${urls.master.workshops.root}/${idCabang}`,
        data: data,
        token: token,
        onLogout: onLogout
      })

      if (res?.resStatus === 200) {
        router.push(`/workshop-management/${idCabang}`)
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Workshop Management</Breadcrumb.Item>
        <Breadcrumb.Item>{dataCabang?.name}</Breadcrumb.Item>
        <Breadcrumb.Item>Edit Bengkel</Breadcrumb.Item>
      </Breadcrumb>
      <UpdateBengkelComponent
        type="edit"
        data={dataCabang}
        handleSubmit={handleSubmit}
        isLoading={isLoading ?? isFetching}
        isLoadingCRUD={isLoadingCRUD}
        onLogout={onLogout}
      />
    </Layout>
  )
}

export default EditBengkel
