import numbro from 'numbro'

numbro.registerLanguage({
  languageTag: 'en-US',
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => '',
  currency: {
    symbol: 'Rp',
    position: 'prefix',
    code: 'EUR'
  }
})

export const formatCurrency = (value) =>
  numbro(value).formatCurrency({
    thousandSeparated: true,
    spaceSeparated: true,
    mantissa: 2,
    optionalMantissa: true
  })

export const unformatCurrency = (value) => numbro.unformat(value)

export const formatThousandsINTL = (value) =>
  new Intl.NumberFormat('id-ID', {
    style: 'decimal',
    currency: 'IDR'
  }).format(value)
