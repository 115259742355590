import React, { useState } from 'react'
import { BaseIcon, FormInputRupiah, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Formik } from 'formik'

const ModalUpdateBiayaLayanan = ({ state, onClose, onSubmit }) => {
  const [besarBiaya, setBesarBiaya] = useState(state.besarBiaya)

  return (
    <ModalContainer>
      <ModalForm minHeight="min-h-[15%]">
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              Edit Biaya Layanan Booking Mobil
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>

          <div className="flex flex-col w-full space-y-5">
            <Formik
              initialValues={{
                besarBiaya
              }}>
              <FormInputRupiah
                label="Besar Biaya"
                name="besarBiaya"
                value={besarBiaya}
                mode="normal"
                placeholder="Masukkan Besar Biaya"
                isWithLabelRp
                onChange={(value) => setBesarBiaya(value)}
              />
            </Formik>
            <div className="flex flex-row w-full items-center justify-end space-x-4">
              <button
                type="button"
                className="flex flex-row hover:cursor-pointer mt-2 items-center justify-around rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  if (besarBiaya !== '' && besarBiaya >= 0) {
                    onSubmit(besarBiaya)
                  } else {
                    onClose()
                  }
                }}
                className="flex flex-row hover:cursor-pointer mt-2 items-center justify-around rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                Simpan
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalUpdateBiayaLayanan
