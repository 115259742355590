import React from 'react'
import { Popover } from 'antd'
import { IcCheck, IcInvoice, IcSO, IcScissors, IcTrash, IcUser, IcWhatsApp } from 'assets/icons'

const ModalLoadMoreJadwalService = ({
  children,
  status,
  onClickServisSelesai,
  onClickKonfirmasiKehadiran,
  onClickKonfirmasiMekanik,
  onClickDetailServis,
  onClickLihatSO,
  onClickInvoice,
  onClickWhatsApp,
  onClickDeleteServis
}) => {
  const fontStyle = 'font-medium text-gray-500'
  const wrapperFontIcon = 'flex flex-row items-center justify-start space-x-3 hover:cursor-pointer'

  const onReturnByStatus = () => {
    const fontByStatusStyle = 'font-medium text-success-500'
    switch (status) {
      case 'SERVICE_IN_PROGRESS':
      case 'sedang berlangsung':
        return (
          <>
            {onClickServisSelesai && (
              <div onClick={onClickServisSelesai} className={wrapperFontIcon}>
                <IcCheck className="text-success-500" />
                <span className={fontByStatusStyle}>Servis Selesai</span>
              </div>
            )}
            {onClickKonfirmasiMekanik && (
              <div onClick={onClickKonfirmasiMekanik} className={wrapperFontIcon}>
                <IcUser className="text-gray-500" />
                <span className={fontStyle}>Konfirmasi Mekanik</span>
              </div>
            )}
          </>
        )
      case 'SERVICE_CONFIRMED':
      case 'servis terjadwal':
        return (
          onClickKonfirmasiKehadiran && (
            <div onClick={onClickKonfirmasiKehadiran} className={wrapperFontIcon}>
              <IcCheck className="text-success-500" />
              <span className={fontByStatusStyle}>Konfirmasi Kehadiran</span>
            </div>
          )
        )
      default:
        break
    }
  }

  const onReturnContent = () => (
    <div className="grid grid-flow-row gap-5">
      {onReturnByStatus()}

      {onClickDetailServis && (
        <div onClick={onClickDetailServis} className={wrapperFontIcon}>
          <IcScissors className="text-gray-500" />
          <span className={fontStyle}>Detail Servis</span>
        </div>
      )}
      {/* {onClickLihatSO && (
        <div onClick={onClickLihatSO} className={wrapperFontIcon}>
          <IcSO className="text-gray-500" />
          <span className={fontStyle}>Lihat SO</span>
        </div>
      )} */}
      {onClickInvoice && (
        <div onClick={onClickInvoice} className={wrapperFontIcon}>
          <IcInvoice className="text-gray-500" />
          <span className={fontStyle}>Invoice</span>
        </div>
      )}
      {onClickWhatsApp && (
        <div onClick={onClickWhatsApp} className={wrapperFontIcon}>
          <IcWhatsApp className="text-gray-500" />
          <span className={fontStyle}>Whatsapp</span>
        </div>
      )}
      {status === 'SERVICE_CONFIRMED' || status === 'SERVICE_IN_PROGRESS'
        ? onClickDeleteServis && (
            <div onClick={onClickDeleteServis} className={wrapperFontIcon}>
              <IcTrash className="text-danger-500" />
              <span className="font-medium text-danger-500">Delete Servis</span>
            </div>
          )
        : null}
    </div>
  )

  return (
    <Popover
      overlayStyle={{
        backgroundColor: 'transparent'
      }}
      overlayInnerStyle={{
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        // minHeight: '10rem',
        minWidth: '15rem'
      }}
      zIndex={999}
      trigger="click"
      placement="leftTop"
      content={onReturnContent()}>
      {children}
    </Popover>
  )
}

export default ModalLoadMoreJadwalService
