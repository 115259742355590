import React, { useState } from 'react'
import { BaseIcon, FormInput, FormInputRupiah, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'

const ModalCreateUpdateKelasUnit = ({ minHeight, onClose, onSubmit, state }) => {
  const [price, setPrice] = useState(state.type === 'update' ? state.unitClassPrice : '')
  const [isEmptyFieldPrice, setIsEmptyFieldPrice] = useState(false)

  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.type === 'create' ? 'Tambah' : 'Edit'} Kelas Unit`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <Formik
            initialValues={{
              unitClassName: state.type === 'update' ? state.unitClassName : '',
              unitClassPrice: price
            }}
            onSubmit={(values, action) => {
              if (price && (price !== 0 || price !== undefined || price !== '')) {
                onSubmit({
                  unitClassName: values.unitClassName,
                  unitClassPrice: price
                })
              } else {
                setIsEmptyFieldPrice(true)
              }
            }}>
            <Form key="createKelasUnitForm">
              <div className="flex flex-col w-full space-y-5">
                <FormInput
                  label="Kelas Unit"
                  name="unitClassName"
                  placeholder="Masukkan Nama Kelas Unit"
                  isRequired
                  onValidate={(value) => {
                    if (!value) {
                      return 'Silahkan Masukkan Nama Kelas Unit'
                    }
                  }}
                />
                <FormInputRupiah
                  label="Harga"
                  name="unitClassPrice"
                  value={price}
                  mode="normal"
                  placeholder="Masukkan Harga"
                  isEmptyField={isEmptyFieldPrice}
                  isRequired
                  onChange={(value, empty) => {
                    setPrice(value)
                    setIsEmptyFieldPrice(empty)
                  }}
                />
                <div className="flex flex-row w-full items-center justify-end space-x-4">
                  <button
                    type="button"
                    className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                    onClick={() => onClose()}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      if (!price && (price === 0 || price === undefined || price === '')) {
                        setIsEmptyFieldPrice(true)
                      }
                    }}
                    className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                    {`${state.type === 'create' ? 'Tambah Kelas Unit' : 'Simpan'}`}
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateKelasUnit
