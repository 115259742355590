import React, { Fragment, useMemo } from 'react'
import { formatCurrency } from 'utils'
import { IcFacebook, IcInstagram, IcLinkedIn, IcTwitter } from 'assets/icons'
import BirdMobilLogo from 'assets/images/Birdmobil-logo.png'
import moment from 'moment'
import 'moment/locale/en-gb'

const InvoiceCard = React.forwardRef(({ data, isTypeServis = false }, ref) => {
  const fontInvoiceFieldBoldStyle = 'font-medium text-gray-500 text-left font-semibold text-lg'
  const fontInvoiceFieldStyle = 'font-medium text-gray-500 text-left'
  const fontInvoiceValueStyle = 'font-medium text-gray-700 text-right'
  const fontItemTitleStyle = 'font-semibold text-gray-900'
  const fontItemValueStyle = 'font-normal text-gray-700 text-sm'

  const isInternal = useMemo(() => {
    if (data?.ownership?.toLowerCase() === 'mobil internal') {
      return true
    } else {
      return false
    }
  }, [data?.ownership])

  return (
    <div
      ref={ref}
      className="flex min-h-screen overflow-x-hidden"
      style={{
        width: '100%',
        // height: '100%',
        backgroundColor: '#FFFFFF'
      }}>
      <div className="flex flex-col w-full h-full justify-between p-[8%]">
        <div className="flex flex-col">
          <div
            key="header-content"
            className="flex flex-row items-center justify-between border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
            <div className="flex flex-row items-center justify-center space-x-1 bg-white hover:cursor-pointer">
              <img width={30} height={30} src={BirdMobilLogo} alt="" />
              <span className="font-semibold text-gray-900">Birdmobil</span>
            </div>
            <span className="font-normal text-gray-500">
              {moment(data?.created_at).format('LL')}
            </span>
          </div>
          <div key="invoice-information-content" className="grid grid-flow-col pt-8">
            <div className="grid grid-rows-4 gap-2">
              <span className={!isInternal ? fontInvoiceFieldStyle : fontInvoiceFieldBoldStyle}>
                {!isInternal ? 'Invoice No.' : 'Servis Internal Unit'}
              </span>
              <span className={fontInvoiceFieldStyle}>Account</span>
              <span className={fontInvoiceFieldStyle}>Unit</span>
              <span className={fontInvoiceFieldStyle}>Layanan</span>
            </div>
            <div className="grid grid-rows-4 gap-2">
              <span className={fontInvoiceValueStyle}>{!isInternal ? data.invoiceNumber : ''}</span>
              <span className={fontInvoiceValueStyle}>{data.account}</span>
              <span className={fontInvoiceValueStyle}>{data.unit}</span>
              <span className={fontInvoiceValueStyle}>{data.layanan}</span>
            </div>
          </div>

          <div key="items-content" className="flex flex-col py-10">
            {isTypeServis ? (
              <Fragment>
                <div
                  className={`border border-solid border-b-1 border-x-0 border-t-0 pb-3 border-b-gray-300 gap-1 grid grid-cols-11`}>
                  <div className={`text-left ${!isInternal ? 'col-span-4 ' : 'col-span-4'}`}>
                    <span className={fontItemTitleStyle}>Item</span>
                  </div>
                  <div className="text-left">
                    <span className={fontItemTitleStyle}>Qty</span>
                  </div>
                  <div className="text-left col-span-2">
                    <span className={fontItemTitleStyle}>Harga</span>
                  </div>
                  <div className="text-left col-span-2">
                    <span className={fontItemTitleStyle}>Discount</span>
                  </div>
                  <div className="text-right col-span-2">
                    <span className={fontItemTitleStyle}>Total</span>
                  </div>
                </div>
                {data?.items?.sparepart?.length > 0 ? (
                  <>
                    <div className={`pt-3 ${fontItemTitleStyle}`}>Sparepart</div>
                    {data?.items?.sparepart?.map((sparepart, index) => (
                      <div key={index} className={`pt-1 grid grid-cols-11 gap-1`}>
                        <div className={`text-left ${!isInternal ? 'col-span-4' : 'col-span-4'}`}>
                          <span className={fontItemValueStyle}>{sparepart?.sparepart_name}</span>
                        </div>
                        <div className=" text-left">
                          <span className={fontItemValueStyle}>
                            {sparepart?.quantity ? `x${sparepart?.quantity}` : ''}
                          </span>
                        </div>
                        <div className=" text-left col-span-2">
                          <span className={fontItemValueStyle}>
                            {formatCurrency(sparepart?.price)}
                          </span>
                        </div>
                        <div className=" text-left col-span-2">
                          <span className={fontItemValueStyle}>
                            {sparepart?.discount ? formatCurrency(sparepart?.discount) : ''}
                          </span>
                        </div>
                        <div className=" text-right col-span-2">
                          <span className={fontItemValueStyle}>
                            {formatCurrency(sparepart?.total)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
                <div className={`pt-3 ${fontItemTitleStyle}`}>Layanan</div>
                {data?.items?.service?.map((service, index) => (
                  <div key={index} className={`pt-1 grid grid-cols-11 gap-1`}>
                    <div className={`text-left ${!isInternal ? 'col-span-4' : 'col-span-4'}`}>
                      <span className={fontItemValueStyle}>{service?.general_service_name}</span>
                    </div>
                    <div className=" text-left">
                      <span className={fontItemValueStyle}>
                        {service?.quantity ? `x${service?.quantity}` : ''}
                      </span>
                    </div>
                    <div className=" text-left col-span-2">
                      <span className={fontItemValueStyle}>{formatCurrency(service?.price)}</span>
                    </div>
                    <div className=" text-left col-span-2">
                      <span className={fontItemValueStyle}>
                        {service?.discount ? formatCurrency(service?.discount) : ''}
                      </span>
                    </div>
                    <div className=" text-right col-span-2">
                      <span className={fontItemValueStyle}>{formatCurrency(service?.total)}</span>
                    </div>
                  </div>
                ))}
              </Fragment>
            ) : (
              <Fragment>
                <div
                  className={`border border-solid border-b-1 border-x-0 border-t-0 pb-3 border-b-gray-300 ${
                    !isInternal ? 'grid grid-cols-6' : 'grid grid-cols-5'
                  }`}>
                  <div className={`text-left ${!isInternal ? 'col-span-3 ' : 'col-span-4'}`}>
                    <span className={fontItemTitleStyle}>Item</span>
                  </div>
                  {!isInternal ? (
                    <div className=" text-center">
                      <span className={fontItemTitleStyle}>Price</span>
                    </div>
                  ) : null}
                  <div className=" text-center">
                    <span className={fontItemTitleStyle}>Qty</span>
                  </div>
                  {!isInternal ? (
                    <div className=" text-center">
                      <span className={fontItemTitleStyle}>Total</span>
                    </div>
                  ) : null}
                </div>
                {data?.items?.map((item) =>
                  item?.products?.map((product, index) => (
                    <div
                      key={index}
                      className={`pt-3 ${!isInternal ? 'grid grid-cols-6' : 'grid grid-cols-5'}`}>
                      <div className={`text-left ${!isInternal ? 'col-span-3 ' : 'col-span-4'}`}>
                        <span className={fontItemValueStyle}>{product?.name}</span>
                      </div>
                      {!isInternal ? (
                        <div className=" text-center">
                          <span className={fontItemValueStyle}>
                            {formatCurrency(product?.price)}
                          </span>
                        </div>
                      ) : null}
                      <div className=" text-center">
                        <span className={fontItemValueStyle}>{product?.qty}</span>
                      </div>
                      {!isInternal ? (
                        <div className=" text-right">
                          <span className={fontItemValueStyle}>
                            {formatCurrency(product?.priceTotal)}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  ))
                )}
              </Fragment>
            )}

            {/* {!isInternal ? ( */}
            <div className="grid grid-flow-col items-center justify-between pt-8">
              <div className="flex flex-col text-left space-y-5">
                <span className={fontItemTitleStyle}>Subtotal:</span>
                {data?.discount && data?.discount !== 0 ? (
                  <span className={fontItemTitleStyle}>Diskon:</span>
                ) : null}
                {data?.discountCompany && data?.discountCompany !== 0 ? (
                  <span className={fontItemTitleStyle}>Diskon Perusahaan:</span>
                ) : null}
                {data?.tax && data?.tax !== 0 ? (
                  <span className={fontItemTitleStyle}>Pajak:</span>
                ) : null}
                {data?.distanceFee && data?.distanceFee !== 0 ? (
                  <span className={fontItemTitleStyle}>Biaya Pickup:</span>
                ) : null}
                {data?.servicePrice && data?.servicePrice !== 0 ? (
                  <span className={fontItemTitleStyle}>Harga Layanan:</span>
                ) : null}
                <span className={fontItemTitleStyle}>Biaya Admin:</span>
                <span className="font-semibold text-xl-semibold text-primary-500">Total:</span>
              </div>
              <div className="flex flex-col text-right space-y-5">
                <span className={fontItemTitleStyle}>{formatCurrency(data?.subTotal)}</span>
                {data?.discount && data?.discount !== 0 ? (
                  <span className={fontItemTitleStyle}>{`- ${formatCurrency(
                    data?.discount
                  )}`}</span>
                ) : null}
                {data?.discountCompany && data?.discountCompany !== 0 ? (
                  <span className={fontItemTitleStyle}>{`- ${formatCurrency(
                    data?.discountCompany
                  )}`}</span>
                ) : null}
                {data?.tax && data?.tax !== 0 ? (
                  <span className={fontItemTitleStyle}>{`${formatCurrency(data?.tax)}`}</span>
                ) : null}
                {data?.distanceFee && data?.distanceFee !== 0 ? (
                  <span className={fontItemTitleStyle}>{`${formatCurrency(
                    data?.distanceFee
                  )}`}</span>
                ) : null}
                {data?.servicePrice && data?.servicePrice !== 0 ? (
                  <span className={fontItemTitleStyle}>{`${formatCurrency(
                    data?.servicePrice
                  )}`}</span>
                ) : null}
                <span className={fontItemTitleStyle}>{formatCurrency(data?.adminFee ?? 0)}</span>
                <span className="font-semibold text-xl-semibold text-primary-500">
                  {formatCurrency(data?.totalPrice)}
                </span>
              </div>
            </div>
            {/* ) : (
              <div className="grid grid-flow-col items-center justify-between pt-8">
                <div className="flex flex-col text-left space-y-5">
                  <span className={fontItemTitleStyle}>Subtotal:</span>
                  <span className="font-semibold text-primary-500">Total:</span>
                </div>
                <div className="flex flex-col text-right space-y-5">
                  <span className={fontItemTitleStyle}>{formatCurrency(0)}</span>
                  <span className="font-semibold text-sm text-primary-500">
                    {formatCurrency(0)}
                  </span>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div key="footer" className="flex flex-col space-y-5">
          <span className="font-medium text-gray-700">Thank you for your business</span>
          <div className="flex flex-col space-y-1 border border-solid border-b-1 border-x-0 border-t-0 pb-8 border-b-gray-300">
            <span className="font-medium text-gray-700">{data?.workshop_name}</span>
            <span className="font-normal text-gray-500">{data?.workshop_address}</span>
          </div>
          <div className="flex flex-row items-center justify-around pt-5">
            <IcTwitter className="text-gray-400" size={23} />
            <IcLinkedIn className="text-gray-400" size={23} />
            <IcFacebook className="text-gray-400" size={23} />
            <IcInstagram className="text-gray-400" size={23} />
          </div>
        </div>
      </div>
    </div>
  )
})

export default InvoiceCard
