import React, { useEffect, useState } from 'react'
import { Input, Modal, Select, Typography } from 'antd'

import { useQuery } from 'react-query';
import { apiGet } from 'api';
import { useSelector } from 'react-redux';
import { showErrorMessage } from 'utils'

const ModalUpdateBagianInspeksi = ({
  type, isOpen, onCancel, data, onDelete, onSubmit
}) => {
  const token = useSelector(state => state.auth.token);
  const [kategori, setKategori] = useState();
  const [subKategori, setSubKategori] = useState();
  const [bagian, setBagian] = useState('');
  const [bobot, setBobot] = useState(0);
  const [categoryList, setCategoryList] = useState({});
  const [subCategoryList, setSubCategoryList] = useState({});

  const query = useQuery({
    queryKey: ['category-list', isOpen],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        search: '',
        offset: 0,
        limit: 9999
      };
      const res = await apiGet({ url: 'inspeksi-service/admin/inspection-categories', data: params, token });
      return res;
    },
    onSuccess: (res) => {
      console.log(res)
      let newData = {};
      res?.resData?.data?.map(item => {
        newData = {
          ...newData,
          [item.id]: item
        }
      })
      setCategoryList(newData);
    },
    enabled: isOpen
  })

  useEffect(() => {
    if (Object.keys(categoryList)?.length > 0) {
      if(data?.inspectionSubCategory?.inspectionCategory.id) {
        let newSubList = {};
        categoryList[data?.inspectionSubCategory?.inspectionCategory.id]?.inspectionSubCategories?.map(newData => {
          newSubList = {
            ...newSubList,
            [newData.id]: newData
          }
        })
        setSubCategoryList(newSubList);
      }
      console.log(data?.inspectionSubCategory?.inspectionCategory.id)
      setKategori(data?.inspectionSubCategory?.inspectionCategory.id ?? null)
      setSubKategori(data?.inspectionSubCategory?.id ?? null)
      setBagian(data?.name)
      setBobot(data?.weight_point)
    }
  }, [categoryList])

  const onOk = async () => {
    // if(!kategori || !subKategori || !bagian || !bobot) {
    if(!kategori) {
      showErrorMessage({
        content: `Category is a required field`
      })
      return;
    }
    if(!subKategori) {
      showErrorMessage({
        content: `Sub-Category is a required field`
      })
      return;
    }
    if(!bagian) {
      showErrorMessage({
        content: `Bagian Inspeksi is a required field`
      })
      return;
    }
    if(!bobot) {
      showErrorMessage({
        content: `Bobot is a required field`
      })
      return;
    }
    onSubmit({
      id: data?.id ?? null,
      name: bagian,
      inspection_category_id: parseInt(kategori),
      inspection_sub_category_id: parseInt(subKategori),
      type: type,
      weight_point: parseInt(bobot),
    })
  }

  if(type === 'delete') {
    return (
      <Modal
        width={400}
        title="Hapus Bagian Inspeksi"
        open={isOpen}
        onOk={() => onDelete(data)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus bagian inspeksi ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }
  return (
    <Modal
      width={400}
      title={type === 'add' ? 'Tambah Bagian Inspeksi' : 'Edit Bagian Inspeksi'}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      okText={type === 'add' ? 'Tambah Bagian Inspeksi' : 'Edit Bagian Inspeksi'}
      okButtonProps={{ className: 'bg-primary', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
    >
      <Typography.Text className="font-medium">
        Kategori
        <span className="text-[red]"> *</span>
      </Typography.Text>
      <Select 
        className="custom-table-limit mb-4" 
        style={{ width: '100%' }} 
        placeholder="Pilih Kategori"
        disabled={Object.keys(categoryList)?.length === 0 || data?.inspectionSubCategory?.inspectionCategory.id}
        value={kategori? categoryList[kategori]?.name : null}
        showSearch
        filterOption={(input, option) => {
          return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
        }} 
        onChange={(value, index) => {
          setKategori(value);
          let newData = {};
          categoryList[value]?.inspectionSubCategories.map(item => {
            newData = {
              ...newData,
              [item.id]: item
            }
          })
          setSubCategoryList(newData);
        }}
      >
        {Object.keys(categoryList)?.map(id => (
           <Select.Option
            key={id}
            value={id}
          >
            {categoryList[id]?.name}
          </Select.Option>
        ))}
      </Select>
      <Typography.Text className="font-medium">
        Sub-Kategori
        <span className="text-[red]"> *</span>
      </Typography.Text>
      <Select 
        className="custom-table-limit mb-4" 
        style={{ width: '100%' }} 
        placeholder="Pilih Sub-Kategori"
        disabled={subCategoryList.length === 0}
        value={subKategori && Object.keys(subCategoryList).length > 0? subCategoryList[subKategori]?.name : null} 
        onChange={value => {
          setSubKategori(value);
        }}
        showSearch
        filterOption={(input, option) => {
          return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
        }}
      >
        {Object.keys(subCategoryList)?.map(id => (
           <Select.Option
            key={id}
            value={id}
          >
            {subCategoryList[id]?.name}
          </Select.Option>
        ))}
      </Select>
      <Typography.Text className="font-medium">
        Bagian Inpeksi
        <span className="text-[red]"> *</span>
      </Typography.Text>
      <Input
        className="rounded mb-2 pt-1 pb-1"
        placeholder="Masukan Bagian Inspeksi"
        value={bagian}
        onChange={(e) => setBagian(e.target.value)}
      />
      <Typography.Text className="font-medium">
        Bobot
        <span className="text-[red]"> *</span>
      </Typography.Text>
      <Input
        className="rounded mb-2 pt-1 pb-1"
        placeholder="Masukan Bobot"
        type='number'
        value={bobot}
        onChange={(e) => setBobot(e.target.value)}
      />
    </Modal>
  )
}

export default ModalUpdateBagianInspeksi
