import { Modal, Typography } from 'antd'
import { BadgeItem } from 'components'

const ModalBookingDetail = ({ type, isOpen, onCancel, data, onSubmit }) => {
  if (type === 'delete') {
    return (
      <Modal
        width={400}
        title="Hapus User Booking"
        open={isOpen}
        onCancel={onCancel}
        onOk={onSubmit}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500 rounded-lg', type: 'danger' }}
        cancelButtonProps={{
          className: 'hover:text-[#000] hover:border-[#000] rounded-lg',
          type: 'default'
        }}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus user ${data?.buyer_fullname} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      width={300}
      title={'Konfirmasi User Booking'}
      open={isOpen}
      onCancel={onCancel}
      onOk={onSubmit}
      okText={'Confirm'}
      okButtonProps={{ className: 'bg-primary rounded-lg', type: 'danger' }}
      cancelButtonProps={{
        className: 'hover:text-[#000] hover:border-[#000] rounded-lg',
        type: 'default'
      }}>
      <Typography.Text className="font-medium block">
        Apakah Anda yakin ingin mengkonfirmasi user:
      </Typography.Text>
      <div className="mt-2">
        <Typography.Text className="font-medium block">Nama User</Typography.Text>
        <Typography.Text>{data?.buyer_fullname}</Typography.Text>
      </div>
      <div className="mt-2">
        <Typography.Text className="font-medium block">Email</Typography.Text>
        <Typography.Text>{data?.buyer_email}</Typography.Text>
      </div>
      <div className="mt-2">
        <Typography.Text className="font-medium block">Nomor Telepon</Typography.Text>
        <Typography.Text>{data?.buyer_phone_number}</Typography.Text>
      </div>
      <div className="mt-2">
        <Typography.Text className="font-medium block">Status Pembayaran</Typography.Text>
        <BadgeItem type="success">Paid</BadgeItem>
      </div>
    </Modal>
  )
}

export default ModalBookingDetail
