import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { useParams } from 'react-router-dom'
import { Content } from 'antd/lib/layout/layout'
import {
  InformasiPemilikSection,
  InformasiServisSection,
  PaymentReceiptSection
} from './components'
import { unitDataDummies } from 'assets/dummies'
import { CatatanSection, InformasiUnitSection } from 'pages/main/jual-unit/components'

const KatalogRiwayatDetailPage = () => {
  const { unit } = useParams()
  const [units, setUnits] = useState(unitDataDummies.find((item) => item.id === parseInt(0)))
  const [listPayment, setListPayment] = useState([
    {
      title: 'Ganti Oli',
      subCategory: [
        { name: 'Shell Hellix 5L', price: 200000 }
      ]
    },
    {
      title: 'Tune Up Mesin',
      subCategory: [
        { name: '2.0L VTEC Turbo', price: 500000 }
      ]
    }
  ])

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb
        style={{ margin: 8 }}
      >
        <Breadcrumb.Item
          className="font-inter font-semibold text-gray-700"
        >
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500"
        >
          Katalog
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500"
        >
          {unit}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500"
        >
          Detail Riwayat
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content
        style={{ margin: 8 }}
      >
        <div
          className="flex flex-col w-full space-y-5"
        >
          <InformasiServisSection
            tempat="Birdmobil Surabaya"
            tanggal="10/02/2021"
            waktu="07.00 - 09.00"
            stall="2"
            mekanik="Nielsin Norman"
            statusInspeksi="Selesai"
          />
          <InformasiPemilikSection
            nama="Jude Bellingham"
            email="jude.belingham@gmail.com"
            telepon="083944814722"
            alamat="Jln. Bunga Melati No.14, Jakarta"
          />
          <InformasiUnitSection
            data={units}
          />
          <CatatanSection
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc."
          />
          <PaymentReceiptSection
            data={listPayment}
          />
        </div>
      </Content>
    </Layout>
  )
}

export default KatalogRiwayatDetailPage
