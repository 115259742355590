import React, { useEffect, useState } from 'react'
import { Input, Modal, Typography } from 'antd'
import { validationInput } from 'helpers'
import { showErrorMessage } from 'utils'

const ModalUpdateCategory = ({ type, isOpen, onCancel, data, onSubmit, onDelete }) => {
  const [name, setName] = useState({
    value: data?.name ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })

  useEffect(() => {
    if (isOpen) {
      setName({
        ...name,
        value: data?.name ?? '',
        touched: !!data?.name,
        valid: data ? validationInput(data?.name, name.validation).valid : false,
        messageError: validationInput(data?.name, name.validation).messageError
      })
    }
  }, [isOpen])

  const onOk = () => {
    if (!name.valid) {
      showErrorMessage({ content: name?.messageError ?? 'Nama kategori harus diisi' })
      return
    }
    onSubmit({
      id: data?.id,
      name: name.value
    })
  }

  const onChangeInput = (value, identifier, setIdentifier) => {
    let { valid, messageError } = validationInput(value, identifier?.validation)
    setIdentifier({
      ...identifier,
      touched: true,
      value: value,
      valid: valid,
      messageError: messageError
    })
  }

  if (type === 'delete') {
    return (
      <Modal
        width={350}
        title="Hapus Sparepart Category"
        open={isOpen}
        onOk={() => onDelete(data)}
        onCancel={onCancel}
        okText="Confirm"
        centered
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
        maskClosable={false}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus sparepart category ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      centered
      width={350}
      title={type === 'add' ? 'Tambah Kategori Sparepart' : 'Edit Kategori Sparepart'}
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      okText={type === 'add' ? 'Tambah Kategori' : 'Edit Kategori'}
      okButtonProps={{ className: 'bg-primary-500', type: 'danger', disabled: !name.valid }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
      maskClosable={false}>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nama Kategori Sparepart
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !name.valid && name.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Nama Kategori Sparepart"
          value={name.value}
          onChange={(e) => onChangeInput(e.target.value, name, setName)}
        />
        {!name.valid && name.touched && <span className="text-[red]">{name.messageError}</span>}
      </div>
    </Modal>
  )
}

export default ModalUpdateCategory
