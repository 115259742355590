import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from 'helpers'
import { formatCurrency, showErrorMessage, showSuccessMessage } from 'utils'
import { apiPost, urls } from 'api'
import { logout } from 'store/actions'
import { resetStateServis } from 'store/actions/servis'
import { Spin } from 'antd/lib'
const ServisMobilComponentStep4 = ({ data, simulateData }) => {
  const router = useHistory()
  const servis = useSelector((state) => state.servis)
  const token = useSelector((state) => state.auth.token)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = async () => {
    try {
      let packets = []
      let generals = []
      let curPacket = null
      let idx = 0

      Object.keys(servis?.serviceForBE?.service_packets?.data).map((packet) => {
        if (curPacket !== servis?.serviceForBE?.service_packets?.data[packet].packet_id) {
          if (packets.length !== 0) {
            idx++
          }
          packets.push({
            service_id: servis?.serviceForBE?.service_packets?.data[packet].service_id,
            packet_id: servis?.serviceForBE?.service_packets?.data[packet].packet_id,
            general_services: [
              {
                general_service_id:
                  servis?.serviceForBE?.service_packets?.data[packet].general_service_id,
                sparepart_id: servis?.serviceForBE?.service_packets?.data[packet].sparepart_id
              }
            ]
          })
          curPacket = servis?.serviceForBE?.service_packets?.data[packet].packet_id
        } else {
          packets[idx]?.general_services.push({
            general_service_id:
              servis?.serviceForBE?.service_packets?.data[packet].general_service_id,
            sparepart_id: servis?.serviceForBE?.service_packets?.data[packet].sparepart_id
          })
        }
      })
      Object.keys(servis?.serviceForBE?.service_generals?.data).map((key) => {
        generals.push({
          service_id: servis?.serviceForBE?.service_generals?.data[key].service_id,
          general_service_id: servis?.serviceForBE?.service_generals?.data[key]?.general_service_id,
          sparepart_id: servis?.serviceForBE?.service_generals?.data[key]?.sparepart_id
        })
      })

      let body = {
        full_name: servis?.full_name,
        unit_id: servis?.unit_id,
        phone: servis?.phone,
        email: servis?.email,
        address: servis?.address,
        booking_type: 'SERVICE',
        service_date: servis?.service_date,
        service_start_time: servis?.service_start_time,
        service_end_time: servis?.service_end_time,
        workshop_id: servis?.workshop?.id,
        voucher_id: 0,
        stall: servis?.stall,
        service_packets: packets,
        service_generals: generals
      }

      let res = await apiPost({
        url: `${urls?.service?.prefix}/bookings`,
        token,
        data: body,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200) {
        dispatch(resetStateServis())
        showSuccessMessage({ content: 'Servis Mobil Sukses!' })
        router.push(`/internal-unit/${data?.id}`, { data })
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsSubmitLoading(false)
    } catch (error) {
      setIsSubmitLoading(false)
      showErrorMessage({ content: error?.message })
    }
  }

  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="flex">
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#D1FADF] flex items-center justify-center mr-2">
              <Typography.Text className="text-[#12B76A] font-medium">1</Typography.Text>
            </div>
            <Typography.Text className="text-[#12B76A] font-medium w-fit">
              Data Unit
            </Typography.Text>
            <div className="flex-1 h-1 bg-[#6CE9A6] rounded-full mx-2" />
          </div>
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#D1FADF] flex items-center justify-center mr-2">
              <Typography.Text className="text-[#12B76A] font-medium">2</Typography.Text>
            </div>
            <Typography.Text className="text-[#12B76A] font-medium w-fit">
              Data Servis
            </Typography.Text>
            <div className="flex-1 h-1 bg-[#6CE9A6] rounded-full mx-2" />
          </div>
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#D1FADF] flex items-center justify-center mr-2">
              <Typography.Text className="text-[#12B76A] font-medium">3</Typography.Text>
            </div>
            <Typography.Text className="text-[#12B76A] font-medium w-fit">Jadwal</Typography.Text>
            <div className="flex-1 h-1 bg-[#6CE9A6] rounded-full mx-2" />
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#FDE2D3] flex items-center justify-center mr-2">
              <Typography.Text className="text-primary-500 font-medium">4</Typography.Text>
            </div>
            <Typography.Text className="text-primary-500 font-medium w-fit">
              Konfirmasi
            </Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Servis</Typography.Text>
        </div>
        <div className="flex">
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Tempat</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {servis.workshop?.name}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Tanggal</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {formatDate(new Date(servis.service_date))}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Waktu</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {servis.service_start_time} - {servis.service_end_time}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Stall</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">{servis.stall}</Typography.Text>
          </Typography>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Unit</Typography.Text>
        </div>
        <div className="flex">
          <div className="flex-1 pr-4 ml-2">
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Merek
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.brand?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Model
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.model?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Tipe
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.type?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Transmisi
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.transmission?.name}
              </Typography.Text>
            </Typography>
          </div>
          <div className="flex-1 border-0 border-x pl-4 pr-4 border-solid border-gray-300 mr-2">
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Bahan Bakar
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.fuel?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Plat Nomor
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.police_number}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Tahun
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.production_name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Warna
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.color?.name}
              </Typography.Text>
            </Typography>
          </div>
          <div className="flex-1 ml-2">
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Nomor Rangka
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.frame_number}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Nomor Mesin
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.engine_number}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Jarak Tempuh
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.mileage}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Status
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_status}
              </Typography.Text>
            </Typography>
          </div>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Daftar Servis</Typography.Text>
        </div>
        {simulateData?.packets?.map((packet) => (
          <Typography className="mb-2" key={`simulate-service_packets-${packet?.id?.toString()}`}>
            <div className="mb-[1px] py-1 border-gray-200 bg-gray-50 border-solid rounded-md">
              <div className="flex justify-between items-center px-2 mb-1">
                <div className="flex-1 flex items-center">
                  <Typography.Text className="font-medium text-md">
                    {packet?.service_name}
                  </Typography.Text>
                </div>
              </div>
              <div className="ml-6">
                {packet?.spareparts?.map((sparepart, idx) => (
                  <div
                    className="grid grid-cols-12 mb-1 px-2"
                    key={`simulate-packet-sparepart-${idx?.toString()}`}>
                    <div className="col-span-7">
                      <Typography.Text className="font-medium block">
                        {sparepart?.general_service_name}
                      </Typography.Text>
                      <Typography.Text className="block text-gray-500">
                        {sparepart?.sparepart_name}
                      </Typography.Text>
                    </div>
                    <Typography.Text className="col-span-3 text-end flex items-end text-gray-500">
                      {sparepart?.quantity === 0 ? '1' : sparepart?.quantity}x
                    </Typography.Text>
                    <div className="col-span-2 text-end flex justify-end items-end">
                      <Typography.Text className="text-end mr-1 text-gray-500">
                        {formatCurrency(sparepart?.sparepart_price_total?.toString() ?? '0')}
                      </Typography.Text>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Typography>
        ))}
        {simulateData?.general_services?.map((general, idx) => (
          <Typography className="mb-2" key={`simulate-general-${idx?.toString()}`}>
            <div className="mb-[1px] py-1 border-gray-200 bg-gray-50 border-solid rounded-md">
              <div className="grid grid-cols-12 mb-1 px-2">
                <div className="col-span-7">
                  <Typography.Text className="font-medium block">
                    {general?.general_service_name}
                  </Typography.Text>
                  <Typography.Text className="block text-gray-500">
                    {general?.sparepart_name}
                  </Typography.Text>
                </div>
                <Typography.Text className="col-span-2 text-end flex items-center text-gray-500">
                  {general?.quantity}x
                </Typography.Text>
                <div className="col-span-3 text-end flex justify-end items-center">
                  <Typography.Text className="text-end mr-1 text-gray-500">
                    {formatCurrency(general?.sparepart_price_total?.toString() ?? '0')}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </Typography>
        ))}
        {/* {simulateData && <div className="w-full bg-gray-200 h-[2px] mt-4" />}
          {simulateData && (
            <div className="flex justify-between mt-4">
              <Typography.Text className="text-gray-500">Item Total</Typography.Text>
              <Typography.Text className="text-gray-500">
                {formatCurrency(simulateData?.item_total?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )}
          {simulateData && (
            <div className="flex justify-between mt-1">
              <Typography.Text className="text-gray-500">
                Harga servis per jam (Kelas Middle)
              </Typography.Text>
              <Typography.Text className="text-gray-500">
                {formatCurrency(simulateData?.admin_fee?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )}
          {simulateData && (
            <div className="flex justify-between mt-1">
              <Typography.Text className="text-gray-500">Tax</Typography.Text>
              <Typography.Text className="text-gray-500">
                {formatCurrency(simulateData?.tax?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )}
          {simulateData && (
            <div className="flex justify-between mt-1">
              <Typography.Text className="text-gray-500">Potongan Perusahaan</Typography.Text>
              <Typography.Text className="text-gray-500">
                {formatCurrency(simulateData?.company_discount_total?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )} */}
        {/* {simulateData && <div className="w-full bg-gray-200 h-[2px] mt-4" />}
          {simulateData && (
            <div className="flex justify-between mt-1">
              <Typography.Text className="text-gray-500 font-medium">Total Bayar</Typography.Text>
              <Typography.Text className="text-gray-500 font-medium">
                {formatCurrency(simulateData?.price_total?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )} */}
        {/* {Object.keys(servis.service?.service_packets?.data).map((item, idx) => (
            <Typography className="mb-2" key={`service_packets-${idx.toString()}`}>
              <Typography.Text className="font-medium text-lg mb-[1px] block">
                {servis.service?.service_packets?.data?.[item]?.packet_name}
              </Typography.Text>
              <Typography.Text className="font-medium text-lg mb-[1px] ml-[2px] block">
                {servis.service?.service_packets?.data?.[item]?.general_name}
              </Typography.Text>
              <div className="grid grid-cols-12  ml-[2px]">
                <Typography.Text className="col-span-7">
                  {servis.service?.service_packets?.data?.[item]?.name}
                </Typography.Text>
                <Typography.Text className="col-span-1 text-end">
                  1x
                </Typography.Text>
                <div className="col-span-4 text-end flex justify-end items-center">
                  <Typography.Text className="text-end mr-1">
                    {formatCurrency(servis.service?.service_packets?.data?.[item]?.total?.toString())}
                  </Typography.Text>
                </div>
              </div>
            </Typography>
        ))} */}
        {/* <Typography className="mb-2">
          <Typography.Text className="font-medium text-lg mb-[1px]">Ganti Oli</Typography.Text>
          <div className="grid grid-cols-12">
            <Typography.Text className="col-span-7">Shell Hellix 5L</Typography.Text>
            <Typography.Text className="col-span-1 text-end">3x</Typography.Text>
            <div className="col-span-4 text-end flex justify-end items-center">
              <Typography.Text className="text-end mr-1">Rp 200.000</Typography.Text>
            </div>
          </div>
        </Typography>
        <Typography className="mb-2">
          <Typography.Text className="font-medium text-lg mb-[1px]">Tune up mesin</Typography.Text>
          <div className="grid grid-cols-12">
            <Typography.Text className="col-span-7">2.0L VTEC Turbo</Typography.Text>
            <Typography.Text className="col-span-1 text-end">1x</Typography.Text>
            <div className="col-span-4 text-end flex justify-end items-center">
              <Typography.Text className="text-end mr-1">Rp 50.000</Typography.Text>
            </div>
          </div>
        </Typography> */}
      </Card>
      <div className="flex justify-end items-center mb-1">
        <Spin spinning={isSubmitLoading}>
          <Button
            className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
            onClick={() => router.go(-1)}>
            Kembali
          </Button>
        </Spin>
        <Spin spinning={isSubmitLoading}>
          <Button
            className="p-0 px-4 py-2 bg-primary-500 border font-medium text-white hover:opacity-90"
            onClick={() => {
              setIsSubmitLoading(true)
              onSubmit()
            }}>
            Selanjutnya
          </Button>
        </Spin>
      </div>
    </Content>
  )
}

export default ServisMobilComponentStep4
