import { IcEmptyNotif } from 'assets/icons'
import React from 'react'

const NotificationEmpty = () => {
  return (
    <div className="flex flex-col items-center space-y-2">
      <IcEmptyNotif />
      <div className="text-gray-500 text-xl font-semibold">No Notification Yet!</div>
      <div className="text-gray-400 text-md font-medium">
        You have no notifications right now, they’ll show up here
      </div>
    </div>
  )
}

export default NotificationEmpty
