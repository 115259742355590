import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useParams } from 'react-router-dom'
import {
  BagasiToolSection,
  EksteriorSection,
  InformationInspeksiSecton,
  InteriorSection,
  KondisiBanSection,
  RuangMesinSection,
  ScanDiagnosticSection
} from 'components'

const KatalogDetailInspeksiPage = () => {
  const { unit } = useParams()
  const [interiorKelistrikan, setInteriorKelistrikan] = useState([
    { name: 'Pintu Depan', value: 'Lulus' },
    { name: 'Pintu Belakang', value: 'Lulus' },
    { name: 'Pintu Belakang', value: 'Diperbaiki' },
    { name: 'Pintu Belakang', value: 'Tidak Lulus' },
    { name: 'Pintu Depan', value: 'Lulus' },
    { name: 'Pintu Belakang', value: 'Lulus' },
    { name: 'Pintu Belakang', value: 'Diperbaiki' },
    { name: 'Pintu Belakang', value: 'Tidak Lulus' }
  ])

  const [kondisiBan, setKondisiBan] = useState([
    {
      title: 'Ban Depan Kanan',
      status: 'Lulus',
      category: [
        { name: 'Ukuran dan Merk', value: 'Continental' },
        { name: 'Tanggal Produksi', value: '2021' }
      ]
    },
    {
      title: 'Ban Depan Kiri',
      status: 'Lulus',
      category: [
        { name: 'Ukuran dan Merk', value: 'Continental' },
        { name: 'Tanggal Produksi', value: '2021' }
      ]
    },
    {
      title: 'Ban Belakang Kanan',
      status: 'Diperbaiki',
      category: [
        { name: 'Ukuran dan Merk', value: 'Continental' },
        { name: 'Tanggal Produksi', value: '2017' }
      ]
    },
    {
      title: 'Ban Belakang Kiri',
      status: 'Tidak Lulus',
      category: [
        { name: 'Ukuran dan Merk', value: 'Continental' },
        { name: 'Tanggal Produksi', value: '2019' }
      ]
    },
    {
      title: 'Ban Cadangan',
      status: 'Lulus',
      category: [
        { name: 'Ukuran dan Merk', value: 'Continental' },
        { name: 'Tanggal Produksi', value: '2020' }
      ]
    }
  ])

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb
        style={{ margin: 8 }}
      >
        <Breadcrumb.Item
          className="font-inter font-semibold text-gray-700"
        >
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500"
        >
          Katalog
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500"
        >
          {unit}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500"
        >
          Detail Inspeksi
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content
        style={{ margin: 8 }}
      >
        <div
          className="flex flex-col w-full space-y-5"
        >
          <InformationInspeksiSecton
            tempat="Birdmobil Surabaya"
            tanggal="24/10/2022"
            waktu="07.00 - 09.00"
            stall="2"
            inspektor="Nielsen Norman"
            statusInspeksi="Selesai"
            grade="A"
          />
          <InteriorSection
            kelistrikanData={interiorKelistrikan}
          />
          <EksteriorSection />
          <KondisiBanSection
            data={kondisiBan}
          />
          <BagasiToolSection />
          <RuangMesinSection />
          <ScanDiagnosticSection />
        </div>
      </Content>
    </Layout>
  )
}

export default KatalogDetailInspeksiPage
