import { Card, Input, Tag, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, apiPost, apiPut, urls } from 'api'
import { IcClose, IcEdit } from 'assets/icons'
import { Button, Loading, Table } from 'components'
import { useFormik } from 'formik'
import { errorsField, validationInput } from 'helpers'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { formatCurrency, showErrorMessage } from 'utils'
import ModalAdjustHarga from './ModalAdjustharga'
import ModalAttachServisUmum from './ModalAttachServisUmum'

const UpdatePaketServisComponent = ({ type, data, getDataDetail, onLogout }) => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const [selected, setSelected] = useState([])
  const [search, setSearch] = useState('')
  const [listData, setListData] = useState({
    generalService: [],
    generalServiceBySearch: []
  })
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [modal, setModal] = useState({
    show: false,
    data: null
  })
  const [modalAdjust, setModalAdjust] = useState({
    show: false,
    data: null
  })
  const columns = [
    {
      name: 'Nama Servis',
      selector: (row) => row?.general_service?.name,
      cell: (row) => row?.general_service?.name,
      sortable: true,
      width: '200px'
    },
    {
      name: (
        <div className="grid grid-cols-4 gap-4 w-full ">
          <div className="">Sparepart</div>
          <div className="">Harga</div>
          <div className="">Diskon/Margin</div>
          <div className=" text-right">Action</div>
        </div>
      ),
      selector: (row) => row.menu,
      cell: (row) => (
        <div className="w-full truncate flex flex-col space-y-1 ">
          {row?.packet_spareparts?.map((item, idx) => (
            <div key={idx} className="grid grid-cols-4 gap-4 w-full items-center ">
              <div className="">
                <Tag className="rounded-lg text-gray-700 bg-gray-100 truncate max-w-[90%] ">
                  <Typography.Text
                    className=""
                    ellipsis={{
                      tooltip: item?.sparepart?.sku
                    }}>
                    {item?.sparepart?.sku}
                  </Typography.Text>
                </Tag>
              </div>

              <div className=" truncate ">{formatCurrency(item?.sparepart?.price ?? 0)}</div>

              <div className=" truncate ">
                {item?.price
                  ? `${item?.price_type === 'Discount' ? '-' : '+'} 
              ${formatCurrency(item?.price ?? 0)}`
                  : formatCurrency(item?.price ?? 0)}
              </div>

              <div className=" flex justify-end">
                <Button
                  className="bg-white border-gray-300 hover:bg-gray-50 flex items-center my-1"
                  onClick={() => setModalAdjust({ show: true, data: item })}>
                  <IcEdit className="text-gray-700 mr-1" size={18} />
                  <span className="font-medium text-gray-700">Adjust Harga</span>
                </Button>
              </div>
            </div>
          ))}
        </div>
      ),
      minWidth: '730px'
    }
  ]

  // Get All General Services By Search
  useQuery({
    queryKey: ['general-service-search-list', search],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.all}?keywords=${search}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({
        ...prev,
        generalServiceBySearch: res?.resData?.data
      }))
    }
  })

  // Get All General Services
  useQuery({
    queryKey: ['general-service-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.all}?keywords=${search}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({
        ...prev,
        generalService: res?.resData?.data
      }))
    }
  })

  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.name, { isRequired: true })?.valid) {
      errors.name = validationInput(values?.name, { isRequired: true })?.messageError
    }
    if (!validationInput(values?.general_service_ids, { isRequiredArray: true })?.valid) {
      errors.general_service_ids = validationInput(values?.general_service_ids, {
        isRequiredArray: true
      })?.messageError
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? '',
      general_service_ids:
        data?.services?.length > 0 ? data?.services?.map((item) => item?.general_service?.id) : []
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      let body = {
        ...values
      }
      handleSubmit(body)
    }
  })

  // Submit
  const handleSubmit = async (body) => {
    try {
      setIsLoadingCRUD(true)
      let res
      if (type === 'add') {
        res = await apiPost({
          url: `${urls.service.prefix}/${urls.service.serviceManagement.servicePackets.root}`,
          data: body,
          token: token,
          onLogout
        })
      } else {
        res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.serviceManagement.servicePackets.root}/${data?.id}`,
          data: body,
          token: token,
          onLogout
        })
      }

      if (res?.resStatus === 200) {
        router.push({
          pathname: '/services/management',
          hash: 'paket'
        })
        formik.handleReset()
      } else {
        formik.setErrors(errorsField(res?.resData?.errors))
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteDaftarServis = (e, id) => {
    e.preventDefault()
    formik.setFieldValue(
      'general_service_ids',
      values?.general_service_ids?.filter((f) => f !== id)
    )
  }

  return (
    <Content className="min-h-screen">
      <ModalAttachServisUmum
        isOpen={modal.show}
        data={listData?.generalServiceBySearch}
        onCancel={() => setModal({ ...modal, show: false })}
        search={search}
        setSearch={setSearch}
        formik={formik}
        selected={selected}
        setSelected={setSelected}
      />
      <ModalAdjustHarga
        isOpen={modalAdjust.show}
        data={modalAdjust?.data}
        onCancel={() => setModalAdjust({ show: false })}
        token={token}
        idPaket={data?.id}
        getDataDetail={getDataDetail}
        onLogout={onLogout}
      />
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-lg">{`${
            type === 'edit' ? 'Edit' : 'Tambah'
          } Paket Servis`}</Typography.Text>
        </div>
        <Typography.Text className="font-medium">
          Nama Paket <span className="text-[red]">*</span>
        </Typography.Text>
        <div className="w-1/3 mb-3">
          <Input
            className="rounded-md mt-1"
            placeholder="Nama Lengkap"
            name="name"
            value={values?.name}
            onChange={formik.handleChange}
          />
          {touched?.name && errors?.name && <span className="text-[red]">{errors?.name}</span>}
        </div>
        <Typography.Text className="font-medium">
          Daftar Servis <span className="text-[red]">*</span>
        </Typography.Text>
        <div className="w-1/3 mt-1">
          {values?.general_service_ids?.length > 0 && (
            <div className="flex mb-3 gap-y-2 flex-wrap mt-2">
              {values?.general_service_ids?.map((item, index) => (
                <Tag
                  key={`subkategori-${index.toString()}`}
                  className="py-[2px] border-0 bg-[#FEECE2] rounded-lg text-[#A21226] selection:text-black flex items-center gap-[7px]">
                  {listData?.generalService?.find?.((f) => f?.id === item)?.name}
                  <IcClose
                    size={12}
                    className="text-gray-500 cursor-pointer"
                    onClick={(e) => handleDeleteDaftarServis(e, item)}
                  />
                </Tag>
              ))}
            </div>
          )}
          <Button
            className="border-gray-200 bg-white py-1 px-3"
            onClick={() => {
              setModal({ ...modal, show: true })
              setSelected(values?.general_service_ids)
            }}>
            Tambahkan Servis
          </Button>
          {touched?.general_service_ids && errors?.general_service_ids && (
            <div>
              <span className="text-[red]">{errors?.general_service_ids}</span>
            </div>
          )}
        </div>
      </Card>
      {data?.services.length > 0 && (
        <Card className="rounded-lg mb-4">
          <Table columns={columns} data={data?.services} hidePagination={true} />
        </Card>
      )}
      <div className="w-full flex justify-end items-center">
        {isLoadingCRUD ? (
          <Loading title="" visible classNameWidth="" />
        ) : (
          <>
            <Button
              className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50 mt-2"
              onClick={() => {
                router.go(-1)
                formik?.handleReset()
              }}>
              Cancel
            </Button>
            <Button
              className="p-0 px-4 py-2 bg-primary-500 border-primary-500 border font-medium text-white hover:opacity-90 mt-2"
              onClick={() => {
                formik?.handleSubmit()
              }}>
              Simpan
            </Button>
          </>
        )}
      </div>
    </Content>
  )
}

export default UpdatePaketServisComponent
