import React, { useEffect, useState } from 'react'
import { Input, Modal, Typography } from 'antd'
import { validationInput } from 'helpers'
import { showErrorMessage } from 'utils'

const ModalUpdateUser = ({ type, isOpen, onCancel, user, onSubmit, onDelete }) => {
  const [name, setName] = useState({
    value: user?.fullname ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  const [email, setEmail] = useState({
    value: user?.email ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isEmail: true
    }
  })
  const [password, setPassword] = useState({
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isPassword: true
    }
  })
  const [phone, setPhone] = useState({
    value: user?.phone_number ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  })
  const [address, setAddress] = useState(user?.address ?? '')

  useEffect(() => {
    if (isOpen) {
      setName({
        ...name,
        value: user?.fullname ? user?.fullname : user?.companyName ?? '',
        touched: !!user?.fullname,
        valid: user?.fullname ? validationInput(user?.fullname, name.validation).valid : false,
        messageError: user?.fullname
          ? validationInput(user?.fullname, name.validation).messageError
          : null
      })
      setEmail({
        ...email,
        value: user?.email ?? '',
        touched: !!user?.email,
        valid: user?.email ? validationInput(user?.email, email.validation).valid : false,
        messageError: user?.email
          ? validationInput(user?.email, email.validation).messageError
          : null
      })
      setPassword({
        ...password,
        value: '',
        valid: false,
        touched: false
      })
      setPhone({
        ...phone,
        value: user?.phone_number ?? '',
        touched: !!user?.phone_number,
        valid: user?.phone_number
          ? validationInput(user?.phone_number, phone.validation).valid
          : false,
        messageError: user?.phone_number
          ? validationInput(user?.phone_number, phone.validation).messageError
          : null
      })
      setAddress(user?.address)
    }
  }, [isOpen])

  const onChangeInput = (value, identifier, setIdentifier) => {
    let { valid, messageError } = validationInput(value, identifier?.validation)
    setIdentifier({
      ...identifier,
      touched: true,
      value: value,
      valid: valid,
      messageError: messageError
    })
  }

  const onOk = async () => {
    if (!name.valid || !password.valid || !email.valid || !phone.valid) {
      let msg = 'Silahkan lengkapi form'
      if (!name.valid) {
        msg = 'Nama harus diisi'
      } else if (!email.valid) {
        msg = email?.messageError
          ? `Email ${email?.messageError?.toLowerCase()?.replaceAll('email', '')}`
          : 'Email harus diisi'
      } else if (!password.valid) {
        msg = password?.messageError ? `Password ${password?.messageError}` : 'Password harus diisi'
      } else if (!phone.valid) {
        msg = phone?.messageError ? `Phone ${phone?.messageError}` : 'Phone harus diisi'
      }
      showErrorMessage({ content: msg })
      return
    }
    onSubmit({
      id: user?.id,
      fullname: name.value,
      password: password.value,
      email: email.value,
      phone_number: phone.value,
      address: address
    })
  }

  if (type === 'delete') {
    return (
      <Modal
        title="Hapus User"
        open={isOpen}
        onOk={() => onDelete(user)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
        maskClosable={false}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus user ${name?.value} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      title={type === 'add' ? 'Tambah User' : 'Edit User'}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      okText={type === 'add' ? 'Tambah User' : 'Edit User'}
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
      maskClosable={false}>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nama Lengkap
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !name.valid && name.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Nama Admin"
          value={name.value}
          onChange={(e) => onChangeInput(e.target.value, name, setName)}
        />
        {!name.valid && name.touched && <span className="text-[red]">{name.messageError}</span>}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Email
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !email.valid && email.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Email"
          value={email.value}
          onChange={(e) => onChangeInput(e.target.value, email, setEmail)}
        />
        {!email.valid && email.touched && <span className="text-[red]">{email.messageError}</span>}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Password
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input.Password
          className={`rounded ${
            !password.valid && password.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Password"
          value={password.value}
          onChange={(e) => onChangeInput(e.target.value, password, setPassword)}
        />
        {!password.valid && password.touched && (
          <span className="text-[red]">{password.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nomor Telepon
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          type="number"
          className={`rounded ${
            !phone.valid && phone.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Nomor Telepon"
          value={phone.value}
          onChange={(e) => onChangeInput(e.target.value, phone, setPhone)}
        />
        {!phone.valid && phone.touched && <span className="text-[red]">{phone.messageError}</span>}
      </div>
      <Typography.Text className="font-medium">Alamat</Typography.Text>
      <Input.TextArea
        className="rounded mb-4"
        placeholder="Masukan Alamat Lengkap"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
    </Modal>
  )
}

export default ModalUpdateUser
