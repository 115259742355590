import { DatePicker, Input, Typography } from 'antd'
import { apiGet, apiPut, urls } from 'api'
import { IcCheckRound, IcLoadMore, IcSearch, IcTrash } from 'assets/icons'
import {
  CardContainer,
  ModalDeletePickup,
  ModalDetailPickup,
  ModalKonfirmasiPickupSelesai,
  ModalKonfirmasiPickupService,
  ModalLoadMorePickupService,
  Table,
  TabsActive
} from 'components'
import moment from 'moment'
import 'moment/locale/en-gb'
import {
  ModalKonfirmasiMekanik,
  ModalKonfirmasiPenjemputan
} from 'pages/main/services/pickup-service/subpages/pickup-service-detail/components'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { logout } from 'store/actions'
import {
  onReturnStatusTelahDikonfirmasiPickupService,
  onReturnWaktuPerluDikonfirmasi,
  showErrorMessage
} from 'utils'
import { openLinkWA } from 'utils/openLinkWA'

const ServicePickupPerluTelahDikonfirmasiCard = ({
  idCabang,
  refetchSchedule,
  isWorkshop = false
}) => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const tabRef = useRef(null)
  const location = useLocation()
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const hashUrl = location?.hash?.split('-')
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const [tabs, setTabs] = useState([
    {
      name: 'Perlu Dikonfirmasi',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'confirmed' ||
        hashUrl?.[hashUrl?.length - 1] === '#confirmed'
          ? false
          : true,
      hash: 'unconfirmed'
    },
    {
      name: 'Telah Dikonfirmasi',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'confirmed' ||
        hashUrl?.[hashUrl?.length - 1] === '#confirmed'
          ? true
          : false,
      hash: 'confirmed'
    }
  ])

  useEffect(() => {
    const hashUrl = location?.hash?.split('-')
    if (
      hashUrl?.[hashUrl?.length - 1] === '#confirmed' ||
      hashUrl?.[hashUrl?.length - 1] === 'confirmed' ||
      hashUrl?.[hashUrl?.length - 1] === '#unconfirmed' ||
      hashUrl?.[hashUrl?.length - 1] === 'unconfirmed'
    ) {
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [location.hash])

  const [filterConfirmed, setFilterConfirmed] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: [],
    booking_start_date: '',
    booking_end_date: '',
    keywords: '',
    dateRangeValue: []
  })

  const [filterUnconfirmed, setFilterUnconfirmed] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: [],
    booking_start_date: '',
    booking_end_date: '',
    keywords: '',
    dateRangeValue: []
  })

  const [dropDownMekanik, setDropDownMekanik] = useState([])

  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (tabs[0]?.active) {
      if (dates) {
        setFilterUnconfirmed((prev) => ({
          ...prev,
          dateRangeValue: dates,
          booking_start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          booking_end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          offset: 0
        }))
      } else {
        setFilterUnconfirmed((prev) => ({
          ...prev,
          dateRangeValue: [],
          booking_start_date: '',
          booking_end_date: '',
          offset: 0
        }))
      }
    } else {
      if (dates) {
        setFilterConfirmed((prev) => ({
          ...prev,
          dateRangeValue: dates,
          booking_start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          booking_end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          offset: 0
        }))
      } else {
        setFilterConfirmed((prev) => ({
          ...prev,
          dateRangeValue: [],
          booking_start_date: '',
          booking_end_date: '',
          offset: 0
        }))
      }
    }
  }

  // * Modal State
  const [isModalKonfirmasiPickupSelesai, setIsModalKonfirmasiPickupSelesai] = useState({
    show: false,
    data: {}
  })
  const [isModalKonfirmasiMekanik, setIsModalKonfirmasiMekanik] = useState({
    show: false,
    data: {}
  })

  const [isModalDetailPickup, setIsModalDetailPickup] = useState({
    show: false,
    data: {}
  })

  const [isModalPenjemputan, setIsModalPenjemputan] = useState({
    show: false,
    data: {}
  })

  const [isModalPickupService, setIsModalPickupService] = useState({
    show: false,
    data: {}
  })
  const [isModalDeletePickupService, setIsModalDeletePickupService] = useState({
    show: false,
    data: {}
  })
  // *End Modal State

  const columnsUnitPerluDikonfirmasi = [
    {
      name: 'Tanggal',
      selector: (row) => row?.service_date,
      cell: (row) => moment(row?.service_date).format('DD/MM/YYYY'),
      width: '120px'
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          <Typography.Text
            className="font-medium text-gray-700 w-32 2xl:w-full"
            ellipsis={{
              tooltip: row?.unit_name
            }}>
            {row?.unit_name}
          </Typography.Text>

          <span className="font-normal text-gray-500">{`${row?.police_number ?? ''}`}</span>
        </div>
      )
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.full_name,
      cell: (row) => (
        <Typography.Text
          className="w-32 2xl:w-full"
          ellipsis={{
            tooltip: row?.full_name
          }}>
          {row?.full_name}
        </Typography.Text>
      )
    },
    {
      name: 'Alamat',
      selector: (row) => row?.pickup_address ?? '-',
      cell: (row) => (
        <Typography.Text
          className="w-32 2xl:w-full"
          ellipsis={{
            tooltip: row?.pickup_address ?? '-'
          }}>
          {row?.pickup_address ?? '-'}
        </Typography.Text>
      )
    },
    {
      name: 'Waktu Penjemputan',
      selector: (row) => row?.service_start_time,
      cell: (row) =>
        onReturnWaktuPerluDikonfirmasi(
          row?.kuota_layanan,
          `${moment(row?.service_start_time, 'HH:mm').format('HH:mm')} - ${moment(
            row?.service_end_time,
            'HH:mm'
          ).format('HH:mm')}`
        )
    },
    {
      name: 'Waktu Servis',
      selector: (row) => row?.service_start_time,
      cell: (row) =>
        onReturnWaktuPerluDikonfirmasi(
          row?.kuota_layanan,
          `${moment(row?.service_start_time, 'HH:mm').format('HH:mm')} - ${moment(
            row?.service_end_time,
            'HH:mm'
          ).format('HH:mm')}`
        )
    },
    {
      name: 'Action',
      width: '17%',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div className="flex items-center justify-center self-center space-x-2 my-2">
          <div
            className="flex flex-row items-center justify-center space-x-2 hover:cursor-pointer px-3 py-2 border-solid border border-[#12B76A] bg-[#12B76A] rounded-md "
            onClick={() =>
              setIsModalPickupService({
                show: true,
                data: row
              })
            }>
            <IcCheckRound className="text-[#FFFFFF]" size={16} />
            <span className="font-medium text-[#FFFFFF]">Konfirmasi</span>
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() => {
              setIsModalDeletePickupService({
                show: true,
                data: row
              })
            }}>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]
  const handleDateActual = (row) => {
    const start = row?.booking_histories?.find(
      (f) => f?.status === 'PICKUP_IN_PROGRESS'
    )?.completion_time
    const end = row?.booking_histories?.find(
      (f) => f?.status === 'PICKUP_FINISHED'
    )?.completion_time
    const startParse = start ? moment(start).format('DD/MM/YYYY') : ''
    const endParse = end ? moment(end).format('DD/MM/YYYY') : ''

    return startParse
      ? startParse === endParse
        ? `${startParse}`
        : `${startParse} ${endParse ? `- ${endParse}` : ''}`
      : ''
  }

  const handleTimeActual = (row) => {
    const start = row?.booking_histories?.find(
      (f) => f?.status === 'PICKUP_IN_PROGRESS'
    )?.completion_time
    const end = row?.booking_histories?.find(
      (f) => f?.status === 'PICKUP_FINISHED'
    )?.completion_time
    const startParse = start ? moment(start).format('HH:mm') : ''
    const endParse = end ? moment(end).format('HH:mm') : ''

    return startParse ? `${startParse} - ${endParse ?? '-'}` : '-'
  }

  const columnsUnitTelahDikonfirmasi = [
    {
      name: 'Tanggal',
      selector: (row) => row?.service_date,
      cell: (row) => moment(row?.service_date).format('DD/MM/YYYY'),
      width: '120px'
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          <Typography.Text
            className="font-medium text-gray-700 w-32 2xl:w-full"
            ellipsis={{
              tooltip: row?.unit_name
            }}>
            {row?.unit_name}
          </Typography.Text>
          <span className="font-normal text-gray-500">{`${row?.police_number ?? ''}`}</span>
        </div>
      )
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.full_name,
      cell: (row) => (
        <Typography.Text
          className="w-32 2xl:w-full"
          ellipsis={{
            tooltip: row?.full_name
          }}>
          {row?.full_name}
        </Typography.Text>
      )
    },
    {
      name: 'Alamat',
      selector: (row) => row?.pickup_address ?? '-',
      cell: (row) => (
        <Typography.Text
          className="w-32 2xl:w-full"
          ellipsis={{
            tooltip: row?.pickup_address ?? '-'
          }}>
          {row?.pickup_address ?? '-'}
        </Typography.Text>
      )
    },
    {
      name: 'Waktu Aktual',
      selector: (row) => row?.service_start_time,
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden py-1 w-full">
          <span className="font-normal text-gray-500">{handleDateActual(row)}</span>
          <Typography.Text
            className="font-medium text-gray-700 w-32 2xl:w-full"
            ellipsis={{
              tooltip: handleTimeActual(row)
            }}>
            {handleTimeActual(row)}
          </Typography.Text>
        </div>
      ),
      width: '130px'
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
      cell: (row) => onReturnStatusTelahDikonfirmasiPickupService(row?.status)
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '5%',
      cell: (row) => (
        <ModalLoadMorePickupService
          statusPickup={row.status}
          onPressPickupSelesai={() => {
            setIsModalKonfirmasiPickupSelesai((prev) => ({ ...prev, show: true, data: row }))
            setIsModalKonfirmasiMekanik({ show: false, data: {} })
            setIsModalPenjemputan({ show: false, data: {} })
            setIsModalDetailPickup({ show: false, data: {} })
            setIsModalDeletePickupService({ show: false, data: {} })
          }}
          onPressMekanik={() => {
            setIsModalKonfirmasiMekanik((prev) => ({ ...prev, show: true, data: row }))
            setIsModalKonfirmasiPickupSelesai({ show: false, data: {} })
            setIsModalPenjemputan({ show: false, data: {} })
            setIsModalDetailPickup({ show: false, data: {} })
            setIsModalDeletePickupService({ show: false, data: {} })
          }}
          onPressPickupStart={() => {
            setIsModalPenjemputan({ show: true, data: row })
            setIsModalKonfirmasiPickupSelesai({ show: false, data: {} })
            setIsModalKonfirmasiMekanik({ show: false, data: {} })
            setIsModalDetailPickup({ show: false, data: {} })
            setIsModalDeletePickupService({ show: false, data: {} })
          }}
          onPressDetailPickup={() => {
            setIsModalDetailPickup({ show: true, data: row })
            setIsModalKonfirmasiPickupSelesai({ show: false, data: {} })
            setIsModalKonfirmasiMekanik({ show: false, data: {} })
            setIsModalPenjemputan({ show: false, data: {} })
            setIsModalDeletePickupService({ show: false, data: {} })
          }}
          onPressWhatsApp={() => {
            openLinkWA(row?.phone)
          }}
          onPressDeleteService={() => {
            setIsModalDeletePickupService({ show: true, data: row })
            setIsModalKonfirmasiPickupSelesai({ show: false, data: {} })
            setIsModalKonfirmasiMekanik({ show: false, data: {} })
            setIsModalPenjemputan({ show: false, data: {} })
            setIsModalDetailPickup({ show: false, data: {} })
          }}>
          <button
            type="button"
            className="flex items-center justify-center self-center border-0 bg-transparent hover:cursor-pointer">
            <IcLoadMore className="text-gray-500" />
          </button>
        </ModalLoadMorePickupService>
      )
    }
  ]

  // * GET API DATA
  // Get Unconfirmed
  const {
    isLoading: isLoadingUnconfirmed,
    refetch: refetchUnconfirmed,
    isFetching: isFetchingUnconfirmed
  } = useQuery({
    queryKey: [
      'unconfirmed',
      idCabang,
      filterUnconfirmed?.keywords,
      filterUnconfirmed?.stall,
      filterUnconfirmed?.booking_start_date,
      filterUnconfirmed?.booking_end_date,
      filterUnconfirmed?.limit,
      filterUnconfirmed?.offset
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.servicePickup.rootWorkshop}/${idCabang}/${urls?.service?.servicePickup.pickupUnconfirmed}?keywords=${filterUnconfirmed?.keywords}&start_date=${filterUnconfirmed?.booking_start_date}&end_date=${filterUnconfirmed?.booking_end_date}&limit=${filterUnconfirmed?.limit}&offset=${filterUnconfirmed?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setFilterUnconfirmed((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Confirmed
  const {
    isLoading: isLoadingConfirmed,
    refetch: refetchConfirmed,
    isFetching: isFetchingConfirmed
  } = useQuery({
    queryKey: [
      'confirmed',
      idCabang,
      filterConfirmed?.keywords,
      filterConfirmed?.stall,
      filterConfirmed?.booking_start_date,
      filterConfirmed?.booking_end_date,
      filterConfirmed?.limit,
      filterConfirmed?.offset
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.servicePickup.rootWorkshop}/${idCabang}/${urls?.service?.servicePickup.pickupConfirmed}?keywords=${filterConfirmed?.keywords}&start_date=${filterConfirmed?.booking_start_date}&end_date=${filterConfirmed?.booking_end_date}&limit=${filterConfirmed?.limit}&offset=${filterConfirmed?.offset}&is_show_history=true`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setFilterConfirmed((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Mechanic
  useQuery({
    queryKey: ['mechanic'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.auth.user.prefix}/${urls?.auth.user.mechanicByCabang}/${idCabang}/${urls.auth.user.mechanicByCabangAllSuffix}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDropDownMekanik(res?.resData?.data ?? [])
    }
  })

  // *END GET API DATA

  // *Table state
  const handlePerRowsChange = (newPerPage) => {
    if (tabs[0]?.active) {
      setFilterUnconfirmed((prev) => ({
        ...prev,
        limit: newPerPage,
        offset: 0
      }))
    } else {
      setFilterConfirmed((prev) => ({
        ...prev,
        limit: newPerPage,
        offset: 0
      }))
    }
  }

  const onChangePage = (page) => {
    if (tabs[0]?.active) {
      setFilterUnconfirmed((prev) => ({
        ...prev,
        offset: page
      }))
    } else {
      setFilterConfirmed((prev) => ({
        ...prev,
        offset: page
      }))
    }
  }
  // END TABLE STATE

  // Submit
  const handleSubmitPickupSelesai = async () => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.servicePickup.rootPickup}/${isModalKonfirmasiPickupSelesai?.data?.id}/${urls.service.servicePickup.finished}`,
        token: token,
        onLogout
      })
      if (res?.resStatus === 200) {
        setIsModalKonfirmasiPickupSelesai({ show: false, data: {} })
        refetchConfirmed()
        refetchSchedule()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CardContainer minHeight="60%">
      <ModalKonfirmasiPickupSelesai
        desc={`Anda Yakin konfirmasi pickup servis selesai untuk unit 
              ${isModalKonfirmasiPickupSelesai?.data?.unit_name}
              dengan pemilik ${isModalKonfirmasiPickupSelesai?.data?.full_name}`}
        onClose={() => setIsModalKonfirmasiPickupSelesai({ show: false, data: {} })}
        openModal={isModalKonfirmasiPickupSelesai?.show}
        onSubmit={() => handleSubmitPickupSelesai()}
        loading={isLoadingCRUD}
      />

      <ModalDetailPickup
        data={isModalDetailPickup?.data}
        onClose={() => setIsModalDetailPickup({ show: false, data: {} })}
        openModal={isModalDetailPickup.show}
      />

      {isModalPenjemputan.show ? (
        <ModalKonfirmasiPenjemputan
          data={isModalPenjemputan?.data}
          dropDownMekanik={dropDownMekanik}
          onClose={() => setIsModalPenjemputan({ show: false, data: {} })}
          token={token}
          refetch={() => {
            refetchConfirmed()
            refetchSchedule()
          }}
          onLogout={onLogout}
        />
      ) : null}
      <ModalKonfirmasiMekanik
        data={isModalKonfirmasiMekanik?.data}
        dropDownMekanik={dropDownMekanik}
        onClose={() => setIsModalKonfirmasiMekanik({ show: false, data: {} })}
        openModal={isModalKonfirmasiMekanik.show}
        token={token}
        refetch={() => {
          refetchConfirmed()
        }}
        onLogout={onLogout}
      />
      <ModalKonfirmasiPickupService
        data={isModalPickupService?.data}
        onClose={() => setIsModalPickupService({ ...isModalPickupService, show: false })}
        openModal={isModalPickupService?.show}
        title={'Konfirmasi Pickup Servis'}
        token={token}
        refetch={() => {
          refetchUnconfirmed()
          refetchConfirmed()
          refetchSchedule()
        }}
        onLogout={onLogout}
      />
      <ModalDeletePickup
        data={isModalDeletePickupService?.data}
        onClose={() => setIsModalDeletePickupService({ show: false, data: {} })}
        openModal={isModalDeletePickupService?.show}
        token={token}
        tabs={tabs}
        refetch={() => {
          refetchUnconfirmed()
          refetchConfirmed()
          refetchSchedule()
        }}
        onLogout={onLogout}
      />
      <div className="flex flex-col space-y-5">
        <div className="flex-1 w-full mt-2">
          <TabsActive
            data={tabs}
            onPress={(index) => {
              const tempArr = [...tabs]
              tempArr.map((itemCh, indexCh) => {
                if (index === indexCh) {
                  if (itemCh.active) {
                    return false
                  } else {
                    itemCh.active = true
                    if (itemCh?.hash === 'confirmed') {
                      refetchConfirmed()
                    } else {
                      refetchUnconfirmed()
                    }
                    router?.push({
                      hash: isWorkshop ? `service-pickup-${itemCh?.hash}` : itemCh?.hash
                    })
                  }
                } else {
                  itemCh.active = false
                }
              })
              setTabs(tempArr)
            }}
          />
        </div>
        <span className="font-semibold text-2xl text-gray-900 pt-2">
          {`${
            tabs[0].active
              ? 'Daftar Pick Up Unit Perlu Dikonfirmasi'
              : 'Daftar Pick Up Unit Telah Dikonfirmasi'
          }`}
        </span>
        <div className="flex flex-row w-full items-center justify-between mb-3">
          <RangePicker
            onOpenChange={(open) => setIsShowDatePicker(open)}
            onChange={handleChangeRangePicker}
            format="DD/MM/YYYY"
            open={isShowDatePicker}
            value={
              tabs[0]?.active ? filterUnconfirmed?.dateRangeValue : filterConfirmed?.dateRangeValue
            }
            placeholder={['Tanggal Mulai', 'Tanggal Akhir']}
            className="min-w-1/4 rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500 py-2"
            renderExtraFooter={() => {
              return (
                <div className="flex flex-row justify-end items-center space-x-4 my-3">
                  <button
                    type="button"
                    className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-gray-700 bg-white"
                    onClick={() => {
                      setIsShowDatePicker(false)
                    }}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-white bg-primary-500"
                    onClick={() => {
                      setIsShowDatePicker(false)
                    }}>
                    Apply
                  </button>
                </div>
              )
            }}
          />
          <Input
            className="w-1/4 rounded-md py-2"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            value={tabs[0]?.active ? filterUnconfirmed?.keywords : filterConfirmed?.keywords}
            onChange={(e) =>
              tabs[0]?.active
                ? setFilterUnconfirmed((prev) => ({ ...prev, keywords: e?.target?.value }))
                : setFilterConfirmed((prev) => ({ ...prev, keywords: e?.target?.value }))
            }
          />
        </div>
        <div ref={tabRef}>
          <Table
            columns={tabs[0].active ? columnsUnitPerluDikonfirmasi : columnsUnitTelahDikonfirmasi}
            data={tabs[0].active ? filterUnconfirmed?.listData : filterConfirmed?.listData}
            pending={
              tabs[0]?.active
                ? isLoadingUnconfirmed ?? isFetchingUnconfirmed
                : isLoadingConfirmed ?? isFetchingConfirmed
            }
            totalRows={tabs[0]?.active ? filterUnconfirmed?.totalRows : filterConfirmed?.totalRows}
            handlePerRowsChange={handlePerRowsChange}
            pageSize={tabs[0]?.active ? filterUnconfirmed?.limit : filterConfirmed?.limit}
            page={tabs[0]?.active ? filterUnconfirmed?.offset : filterConfirmed?.offset}
            onChangePrevPage={() => {
              if (tabs[0]?.active) {
                if (filterUnconfirmed?.offset > 0) {
                  onChangePage(filterUnconfirmed?.offset - filterUnconfirmed?.limit)
                }
              } else {
                if (filterConfirmed?.offset > 0) {
                  onChangePage(filterConfirmed?.offset - filterConfirmed?.limit)
                }
              }
            }}
            onChangeNextPage={() => {
              if (tabs[0]?.active) {
                if (
                  filterUnconfirmed?.totalRows >
                  filterUnconfirmed?.limit + filterUnconfirmed?.offset
                ) {
                  onChangePage(filterUnconfirmed?.offset + filterUnconfirmed?.limit)
                }
              } else {
                if (filterConfirmed?.totalRows > filterConfirmed?.limit + filterConfirmed?.offset) {
                  onChangePage(filterConfirmed?.offset + filterConfirmed?.limit)
                }
              }
            }}
          />
        </div>
      </div>
    </CardContainer>
  )
}

export default ServicePickupPerluTelahDikonfirmasiCard
