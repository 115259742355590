import moment from 'moment'

export const httpStatus = (code) => {
  let statusText
  switch (code) {
    case 200:
      statusText = 'OK'
      break
    case 400:
      statusText = `Kesalahan pada permintaan, code: ${code}`
      break
    case 401:
      statusText = `Otentikasi tidak valid, code: ${code}`
      break
    case 404:
      statusText = `Endpoint tidak ditemukan, code: ${code}`
      break
    case 405:
      statusText = `Metode tidak diizinkan, code: ${code}`
      break
    case 408:
      statusText = `Waktu permintaan habis, code: ${code}`
      break
    case 409:
      statusText = `Permintaan konflik, code: ${code}`
      break
    case 422:
      statusText = `Entitas tidak dapat diproses, code: ${code}`
      break
    case 431:
      statusText = `Permintaan terlalu besar, code: ${code}`
      break
    case 429:
      statusText = `Permintaan terlalu banyak, code: ${code}`
      break
    default:
      statusText = `Terjadi kesalahan pada server, code: ${code}`
      break
  }
  return statusText
}

export const roleUserText = (roleId) => {
  let role
  switch (roleId) {
    case 1:
      role = 'Superadmin'
      break
    case 2:
      role = 'Personal'
      break
    case 3:
      role = 'Company'
      break
    case 4:
      role = 'Superadmin'
      break
    case 5:
      role = 'Superadmin'
      break
    case 6:
      role = 'Superadmin'
      break
    case 7:
      role = 'Superadmin'
      break
    default:
      role = '-'
      break
  }
  return role
}

export const validationInput = (value, rules, equalObject) => {
  let isValid = true
  let message = ''

  if (rules.isRequired) {
    isValid = value !== '' && isValid
    if (!(value !== '')) {
      message = 'Harus diisi'
    }
  }

  if (rules.isRequiredObject) {
    isValid = value.id !== '' && isValid
    if (!(value.id !== '')) {
      message = 'Harus diisi'
    }
  }
  if (rules.isRequiredArray) {
    isValid = value.length > 0 && isValid
    if (!(value.length > 0)) {
      message = 'Harus diisi'
    }
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/
    isValid = pattern.test(value) && isValid
    if (!pattern.test(value) && value !== '') {
      message = 'Harus diisi dengan angka'
    }
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid
    if (!(value.length >= rules.minLength)) {
      message = 'Panjang karakter minimal ' + rules.minLength + ' karakter'
    }
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid
    if (!(value.length <= rules.maxLength)) {
      message = 'Panjang karakter maksimal ' + rules.maxLength + ' karakter'
    }
  }

  if (rules.valueMinLength === 0 || rules.valueMinLength) {
    isValid = value >= rules.valueMinLength && isValid
    if (!(value >= rules.valueMinLength)) {
      message = 'Minimal ' + rules.valueMinLength
    }
  }

  if (rules.valueMaxLength) {
    isValid = value <= rules.valueMaxLength && isValid
    if (!(value <= rules.valueMaxLength)) {
      message = 'Maksimal ' + rules.valueMaxLength
    }
  }

  if (rules.equalTo) {
    isValid = value === equalObject.value && isValid
    if (!(value === equalObject.value)) {
      message = 'Input harus sama dengan ' + equalObject.label
    }
  }

  if (rules.isEmail) {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    isValid = pattern.test(value) && isValid
    if (!pattern.test(value) && value !== '') {
      message = 'Email tidak valid'
    }
  }

  if (rules?.isPassword) {
    var pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g
    isValid = pattern.test(value) && isValid
    if (!pattern.test(value)) message = 'Minimal 8 karakter, dengan kombinasi huruf dan angka'
  }

  return {
    valid: isValid,
    messageError: message
  }
}

export const formatDate = (date) => {
  const nDate = new Date(date)
  let day = nDate.getDate()
  let month = nDate.getMonth() + 1
  let year = nDate.getFullYear()
  day = day.toString().length > 1 ? day : `0${day}`
  month = month.toString().length > 1 ? month : `0${month}`
  return `${day}/${month}/${year}`
}

export const formatDate2 = (date) => {
  const nDate = new Date(date)
  let day = nDate.getDate()
  let month = nDate.getMonth() + 1
  let year = nDate.getFullYear()
  day = day.toString().length > 1 ? day : `0${day}`
  month = month.toString().length > 1 ? month : `0${month}`
  return `${year}-${month}-${day}`
}

export const toDateFromSlash = (date) => {
  let nDate = date?.split('/')
  return moment(`${nDate[2]}-${nDate[1]}-${nDate[0]}`)
}

export const errorsField = (errors) => {
  let tempErr = {}
  errors?.map((err) => {
    tempErr = {
      ...tempErr,
      [err?.field?.replace(' ', '')]: err?.message
    }
  })
  return tempErr
}

export const getMonthID = (monthNumber) => {
  let month = 'Januari'
  switch (monthNumber) {
    case 1:
      month = 'Februari'
      break
    case 2:
      month = 'Maret'
      break
    case 3:
      month = 'April'
      break
    case 4:
      month = 'Mei'
      break
    case 5:
      month = 'Juni'
      break
    case 6:
      month = 'Juli'
      break
    case 7:
      month = 'Agustus'
      break
    case 8:
      month = 'September'
      break
    case 9:
      month = 'Oktober'
      break
    case 10:
      month = 'November'
      break
    case 11:
      month = 'Desember'
      break
  }
  return month
}
