import { Select } from 'antd'
import { CustomButton } from 'components'
import { useFormik } from 'formik'
import { validationInput } from 'helpers'
import ModalLayout from 'layouts/modal-layout'

const ModalTambahService = ({
  isOpen,
  onClose,
  onSubmit,
  listData,
  listServis = [],
  setListData = () => {}
}) => {
  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.general_service_id, { isRequired: true })?.valid) {
      errors.general_service_id = validationInput(values?.general_service_id, {
        isRequired: true
      })?.messageError
    }
    if (
      !listData?.generals?.find((f) => f?.id === values?.general_service_id)?.is_free &&
      !validationInput(values?.sparepart_id, { isRequired: true })?.valid
    ) {
      errors.sparepart_id = validationInput(values?.sparepart_id, {
        isRequired: true
      })?.messageError
    }
    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      general_service_id: '',
      sparepart_id: ''
    },
    validate: ValidationCheck,
    onSubmit: (values, { resetForm }) => {
      let body = {}
      if (listData?.generals?.find((f) => f?.id === values?.general_service_id)?.is_free) {
        body = {
          ...values,
          sparepart_id: 0
        }
      } else {
        body = values
      }
      onSubmit(body, resetForm)
    }
  })
  return (
    <ModalLayout
      className="min-w-[27%] max-w-[27%] rounded-md"
      headerTitle="Tambah Servis"
      onCancel={() => {
        onClose()
        formik.handleReset()
        setListData((prev) => ({ ...prev, spareparts: [], selectGeneralId: null }))
      }}
      openModal={isOpen}>
      <div className="flex flex-col space-y-5">
        <div className="">
          <label htmlFor="layanan" className="font-medium block mb-2 text-md text-gray-700">
            Layanan
          </label>
          <Select
            showSearch
            className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
            value={values?.general_service_id === '' ? null : values?.general_service_id}
            onChange={(e) => {
              formik.setFieldValue('general_service_id', e)
              formik.setFieldValue('sparepart_id', '')
              setListData((prev) => ({ ...prev, spareparts: [], selectGeneralId: e }))
            }}
            onSearch={(e) => {}}
            optionFilterProp="children"
            placeholder="Pilih/Search Layanan">
            {listData?.generals
              ?.filter(
                (f) =>
                  f?.id !==
                  listServis
                    ?.filter((service) => service?.status !== 'CANCELED')
                    .find((fd) => fd?.general_service_id === f?.id)?.general_service_id
              )
              ?.map((item, idx) => (
                <Select.Option key={idx} value={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
          </Select>
          {touched?.general_service_id && errors?.general_service_id && (
            <span className="text-[red]">{errors?.general_service_id}</span>
          )}
        </div>
        <div className="">
          <label htmlFor="item" className="font-medium block mb-2 text-md text-gray-700">
            Item
          </label>
          <Select
            showSearch
            className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
            value={values?.sparepart_id === '' ? null : values?.sparepart_id}
            onChange={(e) => formik.setFieldValue('sparepart_id', e)}
            onSearch={(e) => {}}
            optionFilterProp="children"
            placeholder="Pilih/Search Item"
            disabled={
              listData?.generals?.find((f) => f?.id === values?.general_service_id)?.is_free
            }>
            {listData?.spareparts?.map((item, idx) => (
              <Select.Option key={idx} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
          {touched?.sparepart_id && errors?.sparepart_id && (
            <span className="text-[red]">{errors?.sparepart_id}</span>
          )}
        </div>
        <div className="flex flex-row w-full items-center justify-end space-x-4">
          <CustomButton
            type="plain"
            onClick={() => {
              onClose()
              formik.handleReset()
              setListData((prev) => ({ ...prev, spareparts: [], selectGeneralId: null }))
            }}>
            Cancel
          </CustomButton>
          <CustomButton onClick={formik.handleSubmit}>Confirm</CustomButton>
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalTambahService
