import React from 'react'
import { Input, Modal, Typography } from 'antd'

const ModalKategoriServis = ({
  type,
  isOpen,
  onCancel,
  data,
  kategori,
  setKategori,
  onSubmit,
  onDelete,
  errors,
  isLoading
}) => {
  if (type === 'delete') {
    return (
      <Modal
        width={400}
        centered
        title="Hapus Kategori Servis"
        open={isOpen}
        onCancel={onCancel}
        onOk={onDelete}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger', loading: isLoading }}
        cancelButtonProps={{
          className: 'hover:text-[#000] hover:border-[#000]',
          type: 'default',
          disabled: isLoading
        }}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus kategori servis ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }
  return (
    <Modal
      width={400}
      centered
      title={type === 'add' ? 'Tambah Kategori Servis' : 'Edit Kategori Servis'}
      open={isOpen}
      onCancel={onCancel}
      okText={'Confirm'}
      onOk={() => onSubmit(kategori)}
      okButtonProps={{ className: 'bg-primary', type: 'danger', loading: isLoading }}
      cancelButtonProps={{
        className: 'hover:text-[#000] hover:border-[#000]',
        type: 'default',
        disabled: isLoading
      }}>
      <Typography.Text className="font-medium">
        Nama Kategori Servis
        <span className="text-[red]"> *</span>
      </Typography.Text>
      <Input
        className="rounded mb-2 pt-1 pb-1"
        placeholder="Masukan Nama Kategori Servis"
        value={kategori}
        onChange={(e) => setKategori(e.target.value)}
      />
      {errors?.name && <span className="text-[red]">{errors?.name}</span>}
    </Modal>
  )
}

export default ModalKategoriServis
