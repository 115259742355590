export const sparepartDataDummies = [
  {
    id: 0,
    sku: 'YYY-123',
    name: 'Exide',
    category: 'Batteries',
    description: 'Exide',
    price: 100000,
    stock: 10
  },
  {
    id: 1,
    sku: 'YYY-123',
    name: 'HSR MYTH04',
    category: 'Tyres & Wheels',
    description: 'HSR MYTH04',
    price: 100000,
    stock: 10
  },
  {
    id: 2,
    sku: 'YYY-123',
    name: 'Shell Hellix Ultra 5L',
    category: 'Bahan Bakar',
    description: 'Shell Hellix Ultra 5L',
    price: 100000,
    stock: 10
  },
  {
    id: 3,
    sku: 'YYY-123',
    name: 'Lamp tire',
    category: 'Electricals',
    description: 'Lamp tire',
    price: 100000,
    stock: 10
  },
  {
    id: 4,
    sku: 'YYY-123',
    name: 'Busi NGK',
    category: 'Engineering',
    description: 'Busi NGK',
    price: 100000,
    stock: 10
  },
  {
    id: 5,
    sku: 'YYY-125',
    name: 'Exide',
    category: 'Batteries',
    description: 'Exide',
    price: 100000,
    stock: 10
  },
  {
    id: 6,
    sku: 'YYY-125',
    name: 'HSR MYTH04',
    category: 'Tyres & Wheels',
    description: 'HSR MYTH04',
    price: 100000,
    stock: 10
  },
  {
    id: 7,
    sku: 'YYY-125',
    name: 'Shell Hellix Ultra 5L',
    category: 'Bahan Bakar',
    description: 'Shell Hellix Ultra 5L',
    price: 100000,
    stock: 10
  },
  {
    id: 8,
    sku: 'YYY-125',
    name: 'Lamp tire',
    category: 'Electricals',
    description: 'Lamp tire',
    price: 100000,
    stock: 10
  },
  {
    id: 9,
    sku: 'YYY-125',
    name: 'Busi NGK',
    category: 'Engineering',
    description: 'Busi NGK',
    price: 100000,
    stock: 10
  }
]
