import { Card, Input, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import { IcPlus, IcSearch, IcSync } from 'assets/icons'
import { Button, Loading, Table } from 'components'

const SparepartCategoryComponent = ({
  columns,
  data,
  tableState,
  onAdd,
  setTableState,
  isLoading,
  syncData,
  isLoadingSync = false
}) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">Sparepart Category</Typography.Text>
          <div className="flex space-x-4">
            {isLoadingSync ? (
              <Loading classNameWidth="" visible />
            ) : (
              <button
                className="flex flex-row items-center justify-around rounded-md border-solid border-primary-500 focus:outline-none space-x-2 px-3 py-2 font-medium text-md text-white bg-primary-500 hover:cursor-pointer"
                onClick={() => syncData()}>
                <IcSync className="text-white" size={17} />
                <span>Sync</span>
              </button>
            )}
            {/* <Button
              className="flex flex-row items-center justify-around rounded-md space-x-1 px-2 py text-md text-white bg-primary-500 border-primary-500"
              onClick={onAdd}>
              <IcPlus className="text-white" size={19} />
              <span>Tambah Kategori</span>
            </Button> */}
          </div>
        </div>
        <div className="mb-5 flex justify-between">
          <Input
            className="w-1/4 rounded-md py-1.5"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

SparepartCategoryComponent.propTypes = {
  columns: PropTypes.array,
  tableState: PropTypes.object,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  setTableState: PropTypes.func,
  isLoading: PropTypes.bool,
  syncData: PropTypes.func
}

SparepartCategoryComponent.defaultProps = {
  columns: PropTypes.array,
  tableState: {},
  data: [],
  onAdd: () => {},
  setTableState: () => {},
  isLoading: false,
  syncData: () => {}
}

export default SparepartCategoryComponent
