import React, { useCallback } from 'react'
import { Card, Input, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'

import { IcPlus, IcSearch } from 'assets/icons'
import { Table } from 'components'

const VoucherComponent = ({ columns, data, tableState, onAdd, setTableState, isLoading }) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value,
      offset: 0
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">Voucher List</Typography.Text>
          <button
            className="flex flex-row items-center justify-around rounded-md border-solid border-primary-500 focus:outline-none space-x-1 px-2 py text-md text-white bg-primary-500 hover:cursor-pointer"
            onClick={onAdd}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Voucher</span>
          </button>
        </div>
        <div className="mb-5 flex justify-between">
          <Input
            className="w-1/4 rounded-md py-2"
            placeholder="Pencarian"
            value={tableState?.keywords}
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

export default VoucherComponent
