import React from 'react'
import { Modal, Typography } from 'antd'

const ModalDeleteBengkel = ({ isOpen, data, onCancel, onDelete, isLoading = false }) => (
  <Modal
    width={300}
    title="Delete Bengkel"
    open={isOpen}
    onCancel={onCancel}
    onOk={onDelete}
    okText="Confirm"
    okButtonProps={{ className: 'bg-primary-500', type: 'danger', loading: isLoading }}
    cancelButtonProps={{
      className: 'hover:text-[#000] hover:border-[#000]',
      type: 'default',
      disabled: isLoading
    }}>
    <Typography.Text className="font-medium">
      {`Apakah Anda yakin ingin menghapus bengkel ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
    </Typography.Text>
  </Modal>
)

export default ModalDeleteBengkel
