import { Image } from 'antd'
import { CardContainer } from 'components'

const InformasiKelengkapanDokumen = ({ data }) => {
  const fieldStyle = 'font-medium text-gray-900 text-md'
  const valueStyle = 'font-normal text-gray-700 text-md break-words'

  return (
    <CardContainer minHeight="20%" className={'grid grid-cols-1 gap-4 mb-2'}>
      <div className="flex w-full pb-4 border border-solid border-b-1 border-x-0 border-t-0 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Kelengkapan Dokumen</span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 w-full gap-3">
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Odometer</span>
            <span className={valueStyle}>{data?.odometer}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>No. Rangka</span>
            <span className={valueStyle}>{data?.noRangka}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Foto No. Rangka</span>
            {data?.imageNoRangka ? (
              <Image
                src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${data?.imageNoRangka}`}
                alt={`image-no-rangka`}
                className="rounded-lg"
                width={100}
                height={100}
              />
            ) : (
              <span className={valueStyle}>-</span>
            )}
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>No. Mesin</span>
            <span className={valueStyle}>{data?.noMesin}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Foto No. Mesin</span>
            {data?.imageNoMesin ? (
              <Image
                src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${data?.imageNoMesin}`}
                alt={`image-no-mesin`}
                className="rounded-lg"
                width={100}
                height={100}
              />
            ) : (
              <span className={valueStyle}>-</span>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>STNK</span>
            <span className={valueStyle}>{data?.stnk}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Foto STNK</span>
            {data?.imageStnk ? (
              <Image
                src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${data?.imageStnk}`}
                alt={`image-stnk`}
                className="rounded-lg"
                width={100}
                height={100}
              />
            ) : (
              <span className={valueStyle}>-</span>
            )}
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>BPKB</span>
            <span className={valueStyle}>{data?.bpkb}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Foto BPKB</span>
            {data?.imageBpkb ? (
              <Image
                src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${data?.imageBpkb}`}
                alt={`image-bpkb`}
                className="rounded-lg"
                width={100}
                height={100}
              />
            ) : (
              <span className={valueStyle}>-</span>
            )}
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Faktur</span>
            <span className={valueStyle}>{data?.faktur}</span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>KTP Pemilik</span>
            <span className={valueStyle}>{data?.ktp}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Kwitansi</span>
            <span className={valueStyle}>{data?.kwitansi}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>KEUR</span>
            <span className={valueStyle}>{data?.keur}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Form A</span>
            <span className={valueStyle}>{data?.formA}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Isi Cilinder</span>
            <span className={valueStyle}>{data?.engine_capacity}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Pajak Berlaku</span>
            <span className={valueStyle}>{data?.tax_expiry}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Tangan Pertama</span>
            <span className={valueStyle}>{data?.unit_ownership}</span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>KM Service Terakhir</span>
            <span className={valueStyle}>{data?.latest_service}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Tanggal Service Terakhir</span>
            <span className={valueStyle}>{data?.latest_service_date}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Warna Eksterior</span>
            <span className={valueStyle}>{data?.exterior_color_name}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Warna Interior</span>
            <span className={valueStyle}>{data?.interior_color_name}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Bahan Interior</span>
            <span className={valueStyle}>{data?.interior_material}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className={fieldStyle}>Remark</span>
            <span className={valueStyle}>{data?.remark}</span>
          </div>
        </div>
      </div>
    </CardContainer>
  )
}

export default InformasiKelengkapanDokumen
