import React, { useState } from 'react'
import UiDynamicAnalyticsDashboard from '../components/ui-dynamic-analytics-dashboard'
import { IcEye } from 'assets/icons'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { formatCurrency, showErrorMessage } from 'utils'
import { dropdownSalesInitialData } from 'constant'
import moment from 'moment'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  listData: [],
  type: '',
  start_date: '',
  end_date: '',
  dateRangeValue: [],
  workshop_id: '',
  unit_name: '',
  payment_type: ''
}

const DynamicTotalPage = () => {
  const { push } = useHistory()
  const { type } = useParams()
  const returnType = () => {
    if (type === 'total-penjualan') return 'Beli'
    else if (type === 'total-servis') return 'Servis'
    else if (type === 'total-inspeksi') return 'Inspeksi'
    else return ''
  }
  const [tableState, setTableState] = useState({ ...initialTableState, type: returnType() })
  const token = useSelector((state) => state.auth.token)
  const [workshop, setWorkshop] = useState()
  const [typeTransaction, setTypeTransaction] = useState([])
  const [units, setUnits] = useState([])
  const [sales, setSales] = useState(dropdownSalesInitialData)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const columnStyle = 'font-medium text-gray-700'

  const columns = [
    {
      name: 'Kode',
      selector: (row) => row?.transaction_code,
      cell: (row) => <span className={columnStyle}>{row?.transaction_code}</span>,
      grow: 1
    },
    {
      name: 'Tipe',
      selector: (row) => row?.transaction_type,
      cell: (row) => <span className={columnStyle}>{row?.transaction_type}</span>,
      grow: 1
    },
    {
      name: 'Cabang',
      selector: (row) => row?.workshop_name,
      cell: (row) => (
        <span className={columnStyle}>
          {row?.workshop_name && row?.workshop_name !== '' ? row?.workshop_name : '-'}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-2 py-3">
          <span className={columnStyle}>{row?.unit_name}</span>
          <span className="font-normal text-gray-500 tracking-tight">
            {row?.unit_police_number && row?.unit_police_number !== ''
              ? row?.unit_police_number
              : '-'}
          </span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Tanggal',
      selector: (row) => row?.created_at,
      cell: (row) => (
        // <span className={columnStyle}>{moment(row?.created_at).format('DD/MM/YYYY')}</span>
        <span className={columnStyle}>{row?.done_at ? moment(row?.done_at).format('DD/MM/YYYY') : '-'}</span>
      ),
      grow: 2
    },
    {
      name: 'Sales',
      selector: (row) => row?.transaction_profit,
      cell: (row) => (
        <span className={columnStyle}>{formatCurrency(row?.transaction_profit ?? '0')}</span>
      ),
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          onClick={() =>
            row?.transaction_type?.toLowerCase() === 'beli'
              ? push(
                  `/analytics-dashboard/penjualan/detail-transaksi/${row?.id}/${
                    row?.unit_id
                  }/${row?.transaction_type?.toLowerCase()}`
                )
              : push(
                  `/analytics-dashboard/penjualan/detail-transaksi/${row?.transaction_id}/${
                    row?.unit_id
                  }/${row?.transaction_type?.toLowerCase()}`
                )
          }
          className="flex items-center gap-2 justify-center self-center px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
          <IcEye className="text-gray-700" />
          <span className="text-sm-medium text-gray-700">Lihat</span>
        </div>
      ),
      grow: 1
    }
  ]

  // API
  // Get transaction list
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'transaction-list',
      tableState.offset,
      tableState.limit,
      tableState.type,
      tableState.start_date,
      tableState.end_date,
      tableState.workshop_id,
      tableState.unit_name,
      tableState.payment_type
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `user-service/admin/transactions?limit=${tableState?.limit}&offset=${tableState?.offset}&type=${tableState?.type}&start_date=${tableState?.start_date}&end_date=${tableState?.end_date}&workshop_id=${tableState?.workshop_id}&unit_name=${tableState?.unit_name}&payment_type=${tableState?.payment_type}`,
        onLogout
      })
      if (res?.resStatus !== 200) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get list types
  useQuery({
    queryKey: ['types-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `user-service/admin/transactions/types`,
        onLogout
      })
      if (res?.resStatus !== 200) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setTypeTransaction(res?.resData?.data ?? [])
    }
  })

  // Get workshop
  useQuery({
    queryKey: ['workshop-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master.workshops.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setWorkshop(res?.resData?.data ?? [])
    }
  })

  // Get Units Transaction
  useQuery({
    queryKey: ['units-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic.prefixUser}/${urls.analytic.transactionBranch.transactionUnitList}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setUnits(res?.resData?.data ?? [])
    }
  })

  return (
    <UiDynamicAnalyticsDashboard
      columns={columns}
      isLoading={isLoading ?? isFetching}
      setTableState={setTableState}
      tableState={tableState}
      typeTransaction={typeTransaction}
      workshop={workshop}
      type={type}
      units={units}
      sales={sales}
    />
  )
}

export default DynamicTotalPage
