import BadgeItem from './badge-item'

function BadgeProgress({ status }) {
  const typeBadge = (status) => {
    switch (status) {
      case 'SERVICE_IN_PROGRESS':
      case 'PICKUP_IN_PROGRESS':
      case 'Sedang Berlangsung':
      case 'ongoing':
      case 'Belum Dikonfirmasi':
      case 'Sedang Inspeksi':
      case 'Sedang Rekondisi':
        return 'default'

      case 'PICKUP_CONFIRMED':
      case 'SERVICE_CONFIRMED':
      case 'WAITING_APPROVAL':
      case 'scheduled':
      case 'Perlu Melakukan Pembayaran':
      case 'Inspeksi Terjadwal':
        return 'warning'

      case 'PICKUP_FINISHED':
      case 'SERVICE_FINISHED':
      case 'selesai':
      case 'Selesai':
      case 'Selesai Rekondisi':
      case 'done':
        return 'success'

      case 'UNCONFIRMED':
      case 'not_confirmed':
      case 'CANCELED':
      case 'Belum dikonfirmasi':
        return 'plain'

      case 'REJECTED_UNCONFIRMED':
        return 'danger'

      default:
        return 'plain'
    }
  }

  const textBadge = (status) => {
    switch (status) {
      case 'SERVICE_IN_PROGRESS':
        return 'Mobil Di Bengkel'
      case 'PICKUP_IN_PROGRESS':
      case 'Sedang Berlangsung':
      case 'ongoing':
        return 'Sedang berlangsung'

      case 'PICKUP_CONFIRMED':
      case 'SERVICE_CONFIRMED':
      case 'WAITING_APPROVAL':
      case 'scheduled':
        return 'Terjadwal'

      case 'PICKUP_FINISHED':
      case 'SERVICE_FINISHED':
      case 'selesai':
      case 'done':
      case 'Selesai':
        return 'Selesai'

      case 'UNCONFIRMED':
      case 'not_confirmed':
        return 'Belum dikonfirmasi'

      case 'REJECTED_UNCONFIRMED':
        return 'Ditolak'

      case 'CANCELED':
        return 'Dibatalkan'

      default:
        return status
    }
  }

  return status ? <BadgeItem type={typeBadge(status)}>{textBadge(status)}</BadgeItem> : null
}

export default BadgeProgress
