import React from 'react'
import CardContainer from 'components/CardContainer'
import { CarouselMultipleLayout } from 'layouts/CarouselMultipleLayout'
import { Image } from 'antd'

const PhotoCarouselAlternativeCard = ({ data = [], title = 'Foto Mobil' }) => (
  <CardContainer className="space-y-5">
    <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
      <span className="font-semibold text-2xl text-gray-900">{title}</span>
    </div>
    <CarouselMultipleLayout slideToShow={4} totalItem={data} className={'cursor-grab'}>
      {data.length !== 0 ? (
        data.map((item, index) => (
          <div key={`photo-${index}`}>
            <Image
              key={index}
              className="object-center object-cover w-72 h-52"
              src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${item.photo_filename}`}
              alt={`pict${index}`}
              preview
            />
          </div>
        ))
      ) : (
        <span className="text-lg font-medium text-gray-700">Belum Terdapat Foto Unit.</span>
      )}
    </CarouselMultipleLayout>
  </CardContainer>
)

export default PhotoCarouselAlternativeCard
