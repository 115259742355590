import React, { useState, useRef } from 'react'
import { Button, Input, Layout, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useDispatch } from 'react-redux'
import { login } from 'store/actions'
import { useHistory } from 'react-router-dom'
import { showErrorMessage } from 'utils'

const { Text, Title } = Typography

const LoginPage = () => {
  const router = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const passwordRef = useRef();

  const onLogin = async () => {
    try {
      const res = await dispatch(login(email, password))
      if (res?.is_need_mfa) {
        router.push('/otp', {
          data: res
        })
      }
    } catch (error) {
      showErrorMessage({
        content: `${error.message ?? 'Failed Login'}`
      })
    }
  }

  return (
    <Layout>
      <Content className="h-screen flex items-center justify-center bg-background">
        <div className="bg-white w-[400px] p-4 rounded">
          <Typography className="mb-8 text-primary-500">
            <Title level={4}>
              Welcome to
              <Text className="text-primary-500 ml-2">BirdMobil - Admin Panel</Text>
            </Title>
            <Text className="text-gray-500">Login to access your admin account</Text>
          </Typography>
          <Text className="font-medium">Email</Text>
          <Input
            className="rounded mb-4"
            placeholder="Email"
            name="email"
            type="email"
            onChange={e => setEmail(e.target.value)}
            value={email}
            autoComplete='email'
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                passwordRef.current.focus();
              }
            }}
          />
          <Text className="font-medium">Password</Text>
          <Input.Password
            ref={passwordRef}
            className="rounded mb-4"
            placeholder="Password"
            onChange={e => setPassword(e.target.value)}
            value={password}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                onLogin();
              }
            }}
          />
          <Button
            type="danger"
            className="w-full bg-primary-500 rounded"
            onClick={onLogin}
          >
            Masuk
          </Button>
        </div>
      </Content>
    </Layout>
  )
}

export default LoginPage
