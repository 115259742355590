import { Select } from 'antd'
import { apiPut, urls } from 'api'
import { CustomButton, Loading } from 'components'
import { useFormik } from 'formik'
import { errorsField, validationInput } from 'helpers'
import ModalLayout from 'layouts/modal-layout'
import { useState } from 'react'
import { showErrorMessage } from 'utils'

const ModalKonfirmasiMekanikJadwalService = ({
  isOpen,
  onClose,
  state,
  dropDownMekanik,
  dropDownStall,
  token,
  refetch,
  onLogout
}) => {
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const ValidationCheck = (values) => {
    const errors = {}

    if (state?.typeConfirmation === 'kehadiran' || state?.typeConfirmation === 'mekanik') {
      if (!validationInput(values?.mechanic_id, { isRequired: true })?.valid) {
        errors.mechanic_id = validationInput(values?.mechanic_id, {
          isRequired: true
        })?.messageError
      }
    }

    if (state?.typeConfirmation === 'kehadiran') {
      if (!validationInput(values?.stall_no, { isRequired: true })?.valid) {
        errors.stall_no = validationInput(values?.stall_no, {
          isRequired: true
        })?.messageError
      }
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      mechanic_id: state?.mekanik ?? '',
      stall_no: state?.stall ?? ''
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      let body
      if (state?.typeConfirmation === 'kehadiran') {
        body = {
          ...values,
          mechanic_id: parseInt(values?.mechanic_id),
          stall_no: parseInt(values?.stall_no)
        }
      } else if (state?.typeConfirmation === 'mekanik') {
        body = {
          mechanic_id: parseInt(values?.mechanic_id)
        }
      }

      handleSubmit(body)
    }
  })

  // Submit
  const handleSubmit = async (body) => {
    try {
      setIsLoadingCRUD(true)
      let res
      if (state?.typeConfirmation === 'kehadiran') {
        res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.servicePickup.rootService}/${state?.data?.id}/${urls.service.servicePickup.assignMechanic}`,
          data: body,
          token: token,
          onLogout
        })
      } else if (state?.typeConfirmation === 'mekanik') {
        res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.servicePickup.root}/${state?.data?.id}/${urls.service.servicePickup.updateMechanic}`,
          data: body,
          token: token,
          onLogout
        })
      }
      if (res?.resStatus === 200) {
        onClose()
        refetch()
        formik.handleReset()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        formik.setErrors(errorsField(res?.resData?.errors))
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  const onReturnForm = () => {
    switch (state.typeConfirmation) {
      case 'kehadiran':
        return (
          <div className="flex flex-col space-y-5">
            <div className="">
              <label htmlFor="stall" className="font-medium block mb-2 text-md text-gray-700">
                Stall
                <span className="text-red-700">*</span>
              </label>
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
                value={values?.stall_no === '' ? null : values?.stall_no}
                onChange={(e) => formik.setFieldValue('stall_no', e)}
                placeholder="Pilih Stall">
                {dropDownStall?.map((stall, idx) => (
                  <Select.Option key={idx} value={stall?.value}>
                    {stall?.name}
                  </Select.Option>
                ))}
              </Select>
              {touched?.stall_no && errors?.stall_no && (
                <span className="text-[red]">{errors?.stall_no}</span>
              )}
            </div>
            <div className="">
              <label htmlFor="mekanik" className="font-medium block mb-2 text-md text-gray-700">
                Mekanik
                <span className="text-red-700">*</span>
              </label>
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
                value={values?.mechanic_id === '' ? null : values?.mechanic_id}
                onChange={(e) => formik.setFieldValue('mechanic_id', e)}
                placeholder="Pilih Mekanik">
                {dropDownMekanik?.map((mekanik, idx) => (
                  <Select.Option key={idx} value={mekanik?.id}>
                    {mekanik?.fullname}
                  </Select.Option>
                ))}
              </Select>
              {touched?.mechanic_id && errors?.mechanic_id && (
                <span className="text-[red]">{errors?.mechanic_id}</span>
              )}
            </div>
          </div>
        )
      case 'kehadiran-inspektor':
        return (
          <div className="flex flex-col space-y-5">
            <div className="">
              <label htmlFor="stall" className="font-medium block mb-2 text-md text-gray-700">
                Stall
              </label>
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
                value={values?.stall_no === '' ? null : values?.stall_no}
                onChange={(e) => formik.setFieldValue('stall_no', e)}
                placeholder="Pilih Stall">
                {dropDownStall?.map((stall, idx) => (
                  <Select.Option key={idx} value={stall?.value}>
                    {stall?.name}
                  </Select.Option>
                ))}
              </Select>
              {touched?.stall_no && errors?.stall_no && (
                <span className="text-[red]">{errors?.stall_no}</span>
              )}
            </div>
            <div className="">
              <label htmlFor="Inspektor" className="font-medium block mb-2 text-md text-gray-700">
                Inspektor
              </label>
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
                value={values?.mechanic_id === '' ? null : values?.mechanic_id}
                onChange={(e) => formik.setFieldValue('mechanic_id', e)}
                placeholder="Pilih Inspektor">
                {dropDownMekanik?.map((mekanik, idx) => (
                  <Select.Option key={idx} value={mekanik?.id}>
                    {mekanik?.fullname}
                  </Select.Option>
                ))}
              </Select>
              {touched?.mechanic_id && errors?.mechanic_id && (
                <span className="text-[red]">{errors?.mechanic_id}</span>
              )}
            </div>
          </div>
        )
      case 'mekanik':
        return (
          <div className="">
            <label htmlFor="mekanik" className="font-medium block mb-2 text-md text-gray-700">
              Mekanik
            </label>
            <Select
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
              value={values?.mechanic_id === '' ? null : values?.mechanic_id}
              onChange={(e) => formik.setFieldValue('mechanic_id', e)}
              placeholder="Pilih Mekanik">
              {dropDownMekanik?.map((mekanik, idx) => (
                <Select.Option key={idx} value={mekanik?.id}>
                  {mekanik?.fullname}
                </Select.Option>
              ))}
            </Select>
            {touched?.mechanic_id && errors?.mechanic_id && (
              <span className="text-[red]">{errors?.mechanic_id}</span>
            )}
          </div>
        )
      case 'inspektor':
        return (
          <div className="">
            <label htmlFor="Inspektor" className="font-medium block mb-2 text-md text-gray-700">
              Inspektor
            </label>
            <Select
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
              value={values?.mechanic_id === '' ? null : values?.mechanic_id}
              onChange={(e) => formik.setFieldValue('mechanic_id', e)}
              placeholder="Pilih Inspektor">
              {dropDownMekanik?.map((mekanik, idx) => (
                <Select.Option key={idx} value={mekanik?.id}>
                  {mekanik?.fullname}
                </Select.Option>
              ))}
            </Select>
            {touched?.mechanic_id && errors?.mechanic_id && (
              <span className="text-[red]">{errors?.mechanic_id}</span>
            )}
          </div>
        )
      default:
        break
    }
  }

  return (
    <ModalLayout
      className="min-w-[22%] max-w-[22%] rounded-md"
      headerTitle={
        state.typeConfirmation === 'mekanik'
          ? 'Konfirmasi Mekanik'
          : state.typeConfirmation === 'inspektor'
          ? 'Konfirmasi Inspektor'
          : state.typeConfirmation === 'kehadiran-inspektor'
          ? 'Konfirmasi Inspeksi'
          : 'Konfirmasi Service'
      }
      onCancel={onClose}
      openModal={isOpen}>
      <div className="flex flex-col w-full space-y-5">
        {onReturnForm()}
        <div className="flex flex-row w-full items-center justify-end space-x-4">
          {isLoadingCRUD ? (
            <Loading title="" visible classNameWidth="" />
          ) : (
            <>
              <CustomButton
                type="plain"
                onClick={() => {
                  onClose()
                  formik.handleReset()
                }}>
                Cancel
              </CustomButton>
              <CustomButton onClick={() => formik.handleSubmit()}>Confirm</CustomButton>
            </>
          )}
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalKonfirmasiMekanikJadwalService
