import React from 'react'
import { CardContainer, FormDropDown, FormInput, FormRadio } from 'components'
import { DatePicker, Typography } from 'antd'
import { Formik } from 'formik'
import { formatThousandsINTL, onlyNumbers, unformatCurrency } from 'utils'

const InformasiUnitForm = ({
  state,
  dropDownManufacture,
  dropDownModel,
  dropDownTipe,
  dropDownTransmission,
  dropDownFuel,
  dropDownWorkshop,
  dropDownColor,
  taxStatus,
  onChangeDropDown = () => {},
  onChangeInput = () => {},
  onChangeRadioButton = () => {},
  showBpkbNumber = true,
  showStnkNumber = true
}) => (
  <CardContainer className="pb-8">
    <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
      <span className="font-semibold text-2xl text-gray-900">Informasi Unit</span>
    </div>
    <div className="flex flex-col w-full space-y-5 mt-4">
      <Formik
        enableReinitialize
        initialValues={{
          manufactureSelector: state.manufacture.value
        }}>
        <FormDropDown
          label="Pabrikan"
          name="manufactureSelector"
          data={dropDownManufacture}
          isRequired
          isSearch
          isEmpty={state.manufacture.isEmpty}
          errorMessage={state.manufacture.message}
          onChange={(value) => onChangeDropDown('manufacture', value)}
        />
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          modelSelector: state.model.value
        }}>
        <FormDropDown
          label="Model"
          name="modelSelector"
          data={dropDownModel}
          isRequired
          isSearch
          isEmpty={state.model.isEmpty}
          errorMessage={state.model.message}
          onChange={(value) => onChangeDropDown('model', value)}
        />
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          tipeSelector: state.tipe.value
        }}>
        <FormDropDown
          label="Tipe"
          name="tipeSelector"
          data={dropDownTipe}
          isRequired
          isSearch
          isEmpty={state.tipe.isEmpty}
          errorMessage={state.tipe.message}
          onChange={(value) => onChangeDropDown('tipe', value)}
        />
      </Formik>
      <div className="flex flex-col">
        <label htmlFor="date" className="font-medium block mb-2 text-md text-gray-700">
          Tahun Keluaran
          <span className="text-red-600">{' *'}</span>
        </label>
        <DatePicker
          picker="year"
          value={state.productionYear.value}
          onChange={(value) => onChangeInput('productionYear', value)}
          className="custom-date-picker py-3 w-full"
          popupClassName="custom-date-picker-dropdown"
          format={['YYYY']}
        />
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          transmissionSelector: state.transmission.value
        }}>
        <FormDropDown
          label="Transmisi"
          name="transmissionSelector"
          data={dropDownTransmission}
          isRequired
          isEmpty={state.transmission.isEmpty}
          errorMessage={state.transmission.message}
          onChange={(value) => onChangeDropDown('transmission', value)}
        />
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          fuelSelector: state.fuel.value
        }}>
        <FormDropDown
          label="Bahan Bakar"
          name="fuelSelector"
          data={dropDownFuel}
          isRequired
          isEmpty={state.fuel.isEmpty}
          errorMessage={state.fuel.message}
          onChange={(value) => onChangeDropDown('fuel', value)}
        />
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          colorSelector: state.color.value
        }}>
        <FormDropDown
          label="Warna"
          name="colorSelector"
          data={dropDownColor}
          isRequired
          isSearch
          isEmpty={state.color.isEmpty}
          errorMessage={state.color.message}
          onChange={(value) => onChangeDropDown('color', value)}
        />
      </Formik>
      <Formik
        initialValues={{
          mileage: state.mileage.value
        }}>
        <FormInput
          label="Jarak Tempuh"
          name="mileage"
          placeholder="Masukkan Jarak Tempuh"
          isRequired
          isKM
          isIconRight
          isValue
          value={formatThousandsINTL(state.mileage.value)}
          isEmpty={state.mileage.isEmpty}
          errorMessage={state.mileage.message}
          onValidate={(value) => {
            let val = ''
            if (onlyNumbers(unformatCurrency(value))) {
              if (value !== '') {
                val = unformatCurrency(value)
              } else {
                val = value
              }
            }
            if (value === '') {
              val = value
            }
            onChangeInput('mileage', val)
          }}
        />
      </Formik>
      <Formik
        initialValues={{
          platNomor: state.policeNumber.value
        }}>
        <FormInput
          label="Plat Nomor"
          name="platNomor"
          placeholder="Masukkan Plat Nomor"
          isRequired
          isEmpty={state.policeNumber.isEmpty}
          errorMessage={state.policeNumber.message}
          onValidate={(value) => onChangeInput('policeNumber', value)}
        />
      </Formik>
      <Formik
        initialValues={{
          nomorMesin: state.engineNumber.value
        }}>
        <FormInput
          label="Nomor Mesin"
          name="nomorMesin"
          placeholder="Masukkan Nomor Mesin"
          onValidate={(value) => onChangeInput('engineNumber', value)}
        />
      </Formik>
      <Formik
        initialValues={{
          nomorRangka: state.frameNumber.value
        }}>
        <FormInput
          label="Nomor Rangka"
          name="nomorRangka"
          placeholder="Masukkan Nomor Rangka"
          isRequired
          isEmpty={state.frameNumber.isEmpty}
          errorMessage={state.frameNumber.message}
          onValidate={(value) => onChangeInput('frameNumber', value)}
        />
      </Formik>
      {showBpkbNumber ? (
        <Formik
          initialValues={{
            bpkbNumber: state.bpkbNumber.value
          }}>
          <FormInput
            label="No. BPKB"
            name="bpkbNumber"
            placeholder="Masukkan No. BPKB"
            isRequired
            isEmpty={state.bpkbNumber.isEmpty}
            errorMessage={state.bpkbNumber.message}
            onValidate={(value) => onChangeInput('bpkbNumber', value)}
          />
        </Formik>
      ) : null}
      {showStnkNumber ? (
        <Formik
          initialValues={{
            stnkNumber: state.stnkNumber.value
          }}>
          <FormInput
            label="No. STNK"
            name="stnkNumber"
            placeholder="Masukkan No. STNK"
            isRequired
            isEmpty={state.stnkNumber.isEmpty}
            errorMessage={state.stnkNumber.message}
            onValidate={(value) => onChangeInput('stnkNumber', value)}
          />
        </Formik>
      ) : null}
      <Formik>
        <FormRadio
          title="Pajak"
          flex="flex-col"
          data={taxStatus}
          check={state.taxStatus.value}
          onChange={(value) => onChangeRadioButton('taxStatus', value)}
        />
      </Formik>
      <div className="relative mb-2">
        <Typography.Text className="font-medium">
          Pajak Berlaku<span className="text-red-600">{' *'}</span>
        </Typography.Text>
        <div className="flex flex-row items-center space-x-4 mt-2">
          <DatePicker
            placeholder="Bulan"
            picker="month"
            value={state.taxActiveEndMonth.parse === '' ? '' : state.taxActiveEndMonth.value}
            onChange={(value) => onChangeInput('taxActiveEndMonth', value)}
            className="custom-date-picker py-3 w-full"
            popupClassName="custom-date-picker-dropdown"
            format={['MM']}
          />
          <DatePicker
            placeholder="Tahun"
            picker="year"
            value={state.taxActiveEndYear.parse === '' ? '' : state.taxActiveEndYear.value}
            onChange={(value) => onChangeInput('taxActiveEndYear', value)}
            className="custom-date-picker py-3 w-full"
            popupClassName="custom-date-picker-dropdown"
            format={['YYYY']}
          />
        </div>
        {state.taxActiveEndMonth.isEmpty || state.taxActiveEndYear.isEmpty ? (
          <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
            {`*${state.taxActiveEndMonth.message}`}
          </div>
        ) : null}
      </div>
      <Formik
        initialValues={{
          nomorKeur: state.keurNumber.value
        }}>
        <FormInput
          label="Nomor Keur"
          name="nomorKeur"
          placeholder="Masukkan Nomor Keur"
          onValidate={(value) => onChangeInput('keurNumber', value)}
        />
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          workshopSelector: state.workshop.value
        }}>
        <FormDropDown
          label="Pilih Workshop"
          name="workshopSelector"
          data={dropDownWorkshop}
          isRequired
          isSearch
          isEmpty={state.workshop.isEmpty}
          errorMessage={state.workshop.message}
          onChange={(value) => onChangeDropDown('workshop', value)}
        />
      </Formik>
    </div>
  </CardContainer>
)

export default InformasiUnitForm
