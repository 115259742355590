import React from 'react'
import { Modal, Typography } from 'antd'

const ModalDeleteSparepartUnit = ({ isOpen, onCancel, data, onSubmit }) => {
  
  return (
    <Modal
      title="Hapus Sparepart Unit"
      open={isOpen}
      onOk={() => onSubmit(data)}
      onCancel={onCancel}
      okText="Confirm"
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
      <Typography.Text className="font-medium">
        {`Apakah Anda yakin ingin menghapus sparepart unit ${data?.brand_name} ${data?.model_name} ${data?.type_name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
      </Typography.Text>
    </Modal>
  )
}

export default ModalDeleteSparepartUnit
