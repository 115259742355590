import React, { useState } from 'react'
import CardContainer from 'components/CardContainer'
import { SpesifikasiExTaxiItemCard, TabsActive } from 'components'

const initialTabs = [
  { name: 'Performance', active: true },
  { name: 'Safety', active: false },
  { name: 'Eksterior', active: false },
  { name: 'Interior', active: false },
  { name: 'Convenience', active: false }
]

const SpesifikasiExTaxiCard = ({ tabsProps = initialTabs, data }) => {
  const [tabs, setTabs] = useState(tabsProps)

  const onReturnContent = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Performance':
        return (
          <SpesifikasiExTaxiItemCard
            title="Performance"
            data={{
              engine: data.taxi.performance_engine,
              transmisi: data.taxi.performance_transmission,
              driveMode: data.taxi.performance_drive_mode ? 'Ada' : 'Tidak Ada',
              drivetrain: data.taxi.performance_drive_train,
              shiftByWire: data.taxi.performance_sbw ? 'Ada' : 'Tidak Ada',
              paddleShifter: data.taxi.performance_paddle_sifter ? 'Ada' : 'Tidak Ada',
              motorDrivenPowerStering: data.taxi.performance_mdps ? 'Ada' : 'Tidak Ada'
            }}
          />
        )
      case 'Safety':
        return (
          <SpesifikasiExTaxiItemCard
            title="Safety"
            data={{
              airbag: data.taxi.safety_airbag,
              tirePressureMonitoringSystem: data.taxi.safety_tpms ? 'Ada' : 'Tidak Ada',
              antiLockBrakeSystem: data.taxi.safety_abs ? 'Ada' : 'Tidak Ada',
              electronicStabillityControl: data.taxi.safety_esc ? 'Ada' : 'Tidak Ada',
              hillAssistControl: data.taxi.safety_hac ? 'Ada' : 'Tidak Ada',
              laneKeepAssistSystem: data.taxi.safety_lka ? 'Ada' : 'Tidak Ada',
              laneFollowingAssist: data.taxi.safety_lfa ? 'Ada' : 'Tidak Ada',
              blindSpotCollisionWarning: data.taxi.safety_bcw ? 'Ada' : 'Tidak Ada',
              blindSpotViewMonitor: data.taxi.safety_bsm ? 'Ada' : 'Tidak Ada',
              safeExitAssist: data.taxi.safety_sea ? 'Ada' : 'Tidak Ada',
              rearSeatOccupantAlert: data.taxi.safety_roa ? 'Ada' : 'Tidak Ada',
              forwardCollisionAvoidanceAssist: data.taxi.safety_fca ? 'Ada' : 'Tidak Ada',
              driverAttentionWarning: data.taxi.safety_daw ? 'Ada' : 'Tidak Ada',
              cruiseControl: data.taxi.safety_cruise_control ? 'Ada' : 'Tidak Ada'
            }}
          />
        )
      case 'Eksterior':
        return (
          <SpesifikasiExTaxiItemCard
            title="Eksterior"
            data={{
              headlamp: data.taxi.exterior_head_lamp,
              drl: data.taxi.eksterior_drl ? 'Ada' : 'Tidak Ada',
              positioningLamp: data.taxi.exterior_positioning_lamp ? 'Ada' : 'Tidak Ada',
              autoLightControl: data.taxi.exterior_auto_light_control ? 'Ada' : 'Tidak Ada',
              grille: data.taxi.exterior_grille,
              bumper: data.taxi.exterior_bumper,
              wiper: data.taxi.exterior_wiper,
              wheel: data.taxi.exterior_wheel,
              sideMirrorColor: data.taxi.exterior_side_mirror_color,
              sideMirrorFolding: data.taxi.exterior_side_mirror_folding ? 'Ada' : 'Tidak Ada',
              sideMirrorHeated: data.taxi.exterior_side_mirror_heated ? 'Ada' : 'Tidak Ada',
              puddleLamp: data.taxi.exterior_puddle_lamp ? 'Ada' : 'Tidak Ada',
              sideMirrorRepeater: data.taxi.exterior_side_mirror_repeater ? 'Ada' : 'Tidak Ada',
              rearLamp: data.taxi.exterior_rear_lamp,
              rearSpoiler: data.taxi.exterior_side_spoiler ? 'Ada' : 'Tidak Ada',
              hmsl: data.taxi.exterior_hmsl ? 'Ada' : 'Tidak Ada',
              muffler: data.taxi.exterior_muffler,
              trunk: data.taxi.exterior_trunk ? 'Ada' : 'Tidak Ada',
              sunroof: data.taxi.exterior_sunroof ? 'Ada' : 'Tidak Ada',
              twoTone: data.taxi.exterior_two_tone ? 'Ada' : 'Tidak Ada',
              roofRack: '-',
              fogLamp: data.taxi.exterior_fog_lamp ? 'Ada' : 'Tidak Ada',
              parkingSensor: data.taxi.exterior_parking_sensor ? 'Ada' : 'Tidak Ada',
              mudGuard: data.taxi.exterior_mod_guard ? 'Ada' : 'Tidak Ada'
            }}
          />
        )
      case 'Interior':
        return (
          <SpesifikasiExTaxiItemCard
            title="Interior"
            data={{
              media: data.taxi.interior_media,
              cluster: data.taxi.interior_cluster,
              speaker: data.taxi.interior_speaker,
              bluetooth: data.taxi.interior_bluetooth ? 'Ada' : 'Tidak Ada',
              steerControl: data.taxi.interior_steer_control ? 'Ada' : 'Tidak Ada',
              seatMaterial: data.taxi.interior_seat_material,
              seatVentilation: data.taxi.interior_seat_ventilation ? 'Ada' : 'Tidak Ada',
              seatAdjust: data.taxi.interior_seat_adjust,
              seatFolding: '-',
              ims: data.taxi.interior_ims ? 'Ada' : 'Tidak Ada',
              seatCapacity: data.taxi.interior_seat_capacity,
              ambientLight: data.taxi.interior_ambient_light ? 'Ada' : 'Tidak Ada',
              cupHolder: data.taxi.interior_cup_holder ? 'Ada' : 'Tidak Ada',
              luggageNet: data.taxi.interior_luggage_net ? 'Ada' : 'Tidak Ada',
              coolingGloveBox: data.taxi.interior_cooling_glove_box ? 'Ada' : 'Tidak Ada'
            }}
          />
        )
      case 'Convenience':
        return (
          <SpesifikasiExTaxiItemCard
            title="Convenience"
            data={{
              key: data.taxi.convenience_key,
              epb: data.taxi.convenience_epb ? 'Ada' : 'Tidak Ada',
              ac: data.taxi.convenience_ac,
              wirelessCharger: data.taxi.convenience_wireless_charger ? 'Ada' : 'Tidak Ada',
              usb: data.taxi.convenience_usb ? 'Ada' : 'Tidak Ada',
              mapLamp: data.taxi.convenience_map_lamp ? 'Ada' : 'Tidak Ada',
              rainSensor: data.taxi.convenience_rain_sensor ? 'Ada' : 'Tidak Ada',
              headUpDisplay: data.taxi.convenience_head_up_display ? 'Ada' : 'Tidak Ada',
              autoDefogger: data.taxi.convenience_auto_defogger ? 'Ada' : 'Tidak Ada'
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <CardContainer>
      <div className="flex w-full pb-4 border border-solid border-b-1  border-x-0 border-t-0 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Spesifikasi Mobil</span>
      </div>
      <div className="flex w-full pb-4 pt-4 border border-solid border-b-1  border-x-0 border-t-0 border-b-gray-300">
        <TabsActive
          data={tabs}
          onPress={(index) => {
            const tempArr = [...tabs]
            tempArr.map((itemCh, indexCh) => {
              if (index === indexCh) {
                if (itemCh.active) {
                  return false
                } else {
                  itemCh.active = true
                }
              } else {
                itemCh.active = false
              }
            })
            setTabs(tempArr)
          }}
        />
      </div>
      {onReturnContent()}
    </CardContainer>
  )
}

export default SpesifikasiExTaxiCard
