//auth
export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const CHANGE_FIRST_LOGGEDIN = 'CHANGE_FIRST_LOGGEDIN';
export const GET_DATA_PROFILE = 'GET_DATA_PROFILE';
export const SET_ACCESS_MENU = 'SET_ACCESS_MENU';

//inspeksi
export const SET_INSPEKSI_STEP1 = 'SET_INSPEKSI_STEP1';
export const SET_INSPEKSI_STEP2 = 'SET_INSPEKSI_STEP2';
export const RESET_INSPEKSI = 'RESET_INSPEKSI';

//servis
export const SET_SELECTED_SERVIS = 'SET_SELECTED_SERVIS';
export const SET_SERVIS_STEP1 = 'SET_SERVIS_STEP1';
export const SET_SERVIS_STEP2 = 'SET_SERVIS_STEP2';
export const SET_SERVIS_STEP3 = 'SET_SERVIS_STEP3';
export const RESET_SERVIS = 'RESET_SERVIS';