import React, { useEffect, useState, useRef } from 'react'
import { Input, Modal, Typography } from 'antd'
import { Button } from 'components'
import UploadImage from 'components/UploadImage'

const ModalBrand = ({ type, isOpen, onCancel, data, onSubmit, onDelete }) => {
  const [logo, setLogo] = useState()
  const [brand, setBrand] = useState(data?.name ?? '')
  const [asal, setAsal] = useState(data?.origin ?? '')
  const [oldLogo, setOldLogo] = useState()

  let logoRef = useRef()

  useEffect(() => {
    if (isOpen) {
      setLogo(data?.logo ?? null)
      setBrand(data?.name ?? '')
      setAsal(data?.origin ?? '')
      if (data?.logo_url) {
        setOldLogo(`${process.env.REACT_APP_IMAGE_URL_GCS}/${data?.logo_url}`)
      } else {
        setOldLogo()
      }
    }
  }, [isOpen])

  const onOk = async () => {
    onSubmit({
      id: data?.id ?? null,
      logo: logo,
      name: brand,
      origin: asal
    })
  }

  const changeLogo = (info) => {
    setLogo(info)
  }

  if (type === 'delete') {
    return (
      <Modal
        width={400}
        centered
        title="Hapus Brand"
        open={isOpen}
        onOk={() => onDelete(data)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus brand ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      width={450}
      centered
      title={type === 'add' ? 'Tambah Brand' : 'Edit Brand'}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      okText={type === 'add' ? 'Tambah Brand' : 'Edit Brand'}
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
      <div className="flex items-center mb-2">
        <UploadImage
          customRef={logoRef}
          containerClassName={'mr-4'}
          onChangeFile={changeLogo}
          file={logo}
          oldFile={oldLogo}
        />
        <div className="flex-1">
          <Button
            className={'bg-white py-1 mb-1 border-gray-500 text-gray-700 hover:bg-gray-50'}
            onClick={logoRef.current ? () => logoRef.current.click() : () => {}}>
            Upload Logo Brand<span className="text-primary-600">*</span>
          </Button>
          <div className="text-gray-500 text-sm">
            Harus setidaknya 200x200px dan lebih kecil dari 1mb
          </div>
        </div>
      </div>
      <Typography.Text className="font-medium">
        Nama Brand
        <span className="text-[red]">*</span>
      </Typography.Text>
      <Input
        className="rounded mb-4"
        placeholder="Masukan Nama Brand"
        value={brand}
        onChange={(e) => setBrand(e.target.value)}
      />
      <Typography.Text className="font-medium">
        Asal Brand
        <span className="text-[red]">*</span>
      </Typography.Text>
      <Input
        className="rounded mb-4"
        placeholder="Masukan Asal Brand"
        value={asal}
        onChange={(e) => setAsal(e.target.value)}
      />
    </Modal>
  )
}

export default ModalBrand
