import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Dropdown, Layout, Menu } from 'antd'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { IcBell, IcChevronDown, IcLogout, IcUser } from 'assets/icons'
import BirdMobilLogo from 'assets/images/Birdmobil-logo-new.png'
import { logout } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { menuData } from 'assets/data/menu-data'
import NotificationItem from 'pages/main/notification/components/notification-item'
import NotificationEmpty from 'pages/main/notification/components/notification-empty'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { isRoleSuperAdmin } from 'utils/isTypeRole'
const { Sider } = Layout

const items = menuData.map((item, index) => {
  const key = String(index + 1)
  return {
    className: 'bg-white hover:text-primary-500 sidemenu-layout',
    access: item.label,
    key: item.key,
    icon: item.icon,
    // icon: React.createElement(icon),
    label: item.children ? (
      item.label
    ) : (
      <NavLink className="hover:text-primary-500" to={item.href ?? '/'}>
        {item.label}
      </NavLink>
    ),
    href: item.children ? null : item.href,
    children: item.children?.map((child, index2) => ({
      className: 'bg-white hover:text-primary-500',
      key: child?.key ?? `submenu-${key}-${index2}`,
      label: child.children ? (
        child.label
      ) : (
        <NavLink className="hover:text-primary-500" to={child.href ?? '/'}>
          {child.label}
        </NavLink>
      ),
      children: child.children?.map((child2, index3) => ({
        className: 'bg-white hover:text-primary-500',
        key: child2?.key ?? `submenu-submenu-${index2}-${index3}`,
        label: (
          <NavLink className="hover:text-primary-500" to={child2.href ?? '/'}>
            {`- ${child2.label}`}
          </NavLink>
        )
      }))
    }))
  }
})

function MainLayout(props) {
  const dispatch = useDispatch()
  const accessMenu = useSelector((state) => state.auth.accessMenu) ?? []
  const filterItems = items.filter((item) => accessMenu.includes(item.access))
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const [notifications, setNotifications] = useState({
    data: [],
    hasNotif: false
  })
  const onLogout = () => {
    dispatch(logout())
  }

  // Get All Notification
  const { refetch } = useQuery({
    queryKey: ['notification-list'],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.auth.user.prefix}/${urls?.auth.user.notificationList}?limit=5`,
        onLogout: onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setNotifications((prev) => ({
        ...prev,
        data: res?.resData?.data ?? prev?.data,
        hasNotif:
          (res?.resData?.data?.filter((f) => f?.is_read === false)?.length > 0 ? true : false) ??
          prev.hasNotif
      }))
    }
  })

  const profileMenuPerusahaan = {
    className: 'hover:text-primary-500 mr-0 pr-1 top-menu-nav-dropdown',
    label: 'Profile Perusahaan',
    key: 'top-menu-1',
    icon: <IcUser className="top-ic-menu text-gray-700" />,
    onClick: () => router.push('/profile')
  }

  const profileMenuLogout = {
    className: 'text-primary-500 mr-0 pr-1',
    label: 'Logout',
    key: 'top-menu-2',
    icon: <IcLogout className="text-primary-500" />,
    onClick: () => dispatch(logout())
  }

  const notifMenu = [
    {
      className: 'hover:bg-white cursor-default',
      label: (
        <div className="p-6 space-y-3 w-full sm:w-[440px] max-w-[440px]">
          <div className="flex flex-row justify-between items-center">
            <div className="text-lg font-medium text-gray-900">Notifikasi</div>
            <Link
              to={'/notification'}
              className="text-sm font-medium text-primary-500 hover:text-primary-400 cursor-pointer">
              Lihat Semua
            </Link>
          </div>
          <hr className="bg-gray-300 h-[1px]" />
          <div className="flex flex-col space-y-4">
            {notifications?.data?.length > 0 ? (
              notifications?.data?.map((item, idx) => (
                <Fragment key={idx}>
                  <NotificationItem data={item} token={token} refetch={refetch} />
                </Fragment>
              ))
            ) : (
              <NotificationEmpty />
            )}
          </div>
        </div>
      ),
      key: 'notif-1'
    }
  ]

  const [selectedKeyPath, setSelectedKeyPath] = useState([])
  const { name, roleId } = props.profile
  const location = window.location.pathname

  useEffect(() => {
    const newPaths = location.split('/')
    const paths = []
    for (let i = newPaths.length - 1; i >= 0; i--) {
      if (newPaths[i] !== '') {
        paths.push(newPaths[i])
      }
    }
    if (paths.length === 0) {
      paths.push('/')
    }
    onChangeSelectedKeyPath(paths)
  }, [location])

  const onChangeSelectedKeyPath = useCallback(
    (keyPath) => {
      setSelectedKeyPath(keyPath)
    },
    [selectedKeyPath]
  )

  return (
    <Layout style={{ overflow: 'hidden', overflowY: 'hidden' }} className="flex h-screen flex-col">
      <div className="bg-white py-2 justify-between ">
        <div className="float-left flex flex-row items-center justify-center ml-5 mt-1">
          <img width={115} height={30} src={BirdMobilLogo} alt="" />
          <span className="ml-2 font-semibold text-md mt-0.5">Admin Panel</span>
        </div>
        <div />
        <div className="float-right w-[280px] flex self-center justify-center items-center">
          <Dropdown
            overlayClassName="bg-primary-500 rounded-lg"
            className="hover:cursor-pointer"
            menu={{ items: notifMenu }}
            placement="bottomRight"
            onOpenChange={(e) => (e ? refetch() : null)}
            trigger={['click']}>
            <div className="h-[20px] relative">
              <IcBell className="text-gray-500" size={20} />
              {notifications?.hasNotif && (
                <div className="w-2 h-2 rounded-lg bg-[#F04438] absolute right-0 top-0" />
              )}
            </div>
          </Dropdown>
          <Dropdown
            overlayClassName="bg-primary-500 rounded-lg"
            className="hover:cursor-pointer"
            menu={{
              items: isRoleSuperAdmin(props?.profile?.roleId)
                ? [profileMenuPerusahaan, profileMenuLogout]
                : [profileMenuLogout]
            }}
            trigger={['click']}>
            <div
              className="flex items-center hover:cursor-pointer"
              onClick={(e) => e.preventDefault()}>
              <div className="ml-4 mr-2 hover:cursor-pointer">
                <label className="block font-semibold text-sm hover:cursor-pointer">{name}</label>
                <label className="block text-gray-500 text-sm hover:cursor-pointer">
                  {roleId === 1 && 'Superadmin'}
                  {roleId === 2 && 'Personal'}
                  {roleId === 3 && 'Company'}
                </label>
              </div>
              <IcChevronDown size={20} className="text-gray-500" />
            </div>
          </Dropdown>
        </div>
      </div>
      <Layout className="flex flex-1">
        {JSON.parse(process.env.REACT_APP_MEKANIK_VIEW_ROLE_ACCESS ?? [])?.includes(
          props?.profile?.roleId
        ) ? null : (
          <Sider
            width={280}
            className="bg-white focus:text-primary-500 overflow-y-scroll overflow-x-hidden">
            <Menu
              selectable
              defaultOpenKeys={selectedKeyPath}
              defaultSelectedKeys={selectedKeyPath}
              selectedKeys={selectedKeyPath}
              mode="inline"
              style={{
                height: '100%',
                borderRight: 0
              }}
              items={filterItems}
              onClick={(e) => onChangeSelectedKeyPath(e.keyPath)}
            />
          </Sider>
        )}
        <div className="overflow-y-scroll w-full flex-1">{props.children}</div>
      </Layout>
    </Layout>
  )
}
export default MainLayout
