import React, { useState } from 'react'
import { Breadcrumb, Layout, Typography } from 'antd'
import InternalUnitComponent from './components/InternalUnitComponent'
import { IcEye, IcTrash } from 'assets/icons'
import { BadgeItem, Button } from 'components'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { apiDelete, apiGet, urls } from 'api'
import { logout } from 'store/actions'
import ModalDeleteInternalUnit from './components/ModalDeleteInternalUnit'
import _ from 'lodash'
import { showErrorMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  workshop: '',
  sort_by: 'id',
  sort_order: null,
  branch_id: ''
}

const InternalUnit = (props) => {
  const [tableState, setTableState] = useState(initialTableState)
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [modal, setModal] = useState({
    show: false,
    data: {}
  })
  const [dropDownWorkshop, setDropDownWorkshop] = useState([])

  useQuery({
    queryKey: ['get-all-workshop'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.workshops.all}`,
        token,
        onLogout: () => dispatch(logout())
      }),
    onSuccess: (res) => {
      setDropDownWorkshop(res?.resData?.data ?? [])
    }
  })

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'internal-unit-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.branch_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order,
        branch_id: tableState.branch_id
      }
      const res = await apiGet({
        url: 'master-service/admin/internal-units',
        token,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: async (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const onHandleBadgeStatus = (status) => {
    let typeBadgeColor = 'gray'

    if (status.toLowerCase().includes('inspeksi')) {
      typeBadgeColor = 'default'
    }
    if (status.toLowerCase().includes('servis')) {
      typeBadgeColor = 'default'
    }
    if (status.toLowerCase().includes('jual')) {
      typeBadgeColor = 'warning'
    }
    if (status.toLowerCase().includes('garasi')) {
      typeBadgeColor = 'primary'
    }
    return <BadgeItem type={typeBadgeColor}>{_.startCase(status)}</BadgeItem>
  }

  const columns = [
    {
      name: 'Unit',
      selector: (row) => (
        <div className="flex flex-col space-y-1">
          <Typography.Text
            className="font-medium text-gray-700 "
            ellipsis={{
              tooltip: row?.unit_name
            }}>
            {row?.unit_name}
          </Typography.Text>
          <div className="text-gray-500">{row?.police_number}</div>
        </div>
      )
    },
    {
      name: 'Cabang',
      selector: (row) => row?.branch_name,
      cell: (row) => row?.branch_name
    },
    {
      name: 'Status',
      selector: (row) => onHandleBadgeStatus(row.unit_status_string),
      cell: (row) => onHandleBadgeStatus(row.unit_status_string)
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      minWidth: '150px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
            onClick={() =>
              props.history.push(`/internal-unit/${row.id}`, {
                id: row?.id
              })
            }>
            <IcEye className="text-gray-700 mr-1" size={18} />
            <span>Lihat</span>
          </Button>
          <Button
            className="bg-white border-solid border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ show: true, data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        url: `master-service/admin/internal-units/${data?.id}`,
        token
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        refetch()
      } else if (res?.resStatus >= 400 && res?.resStatus <= 500) {
        showErrorMessage({
          content: `Unit tidak dapat dihapus, karena sedang ${data?.unit_status_string}.`
        })
      } else if (res?.resStatus > 500) {
        showErrorMessage({
          content: `Terjadi Kesalahan pada server, silahkan coba lagi.`
        })
      }
    } catch (error) {
      console.log(error)
    }
    setModal({
      ...modal,
      show: false
    })
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Internal Unit</Breadcrumb.Item>
      </Breadcrumb>
      <ModalDeleteInternalUnit
        isOpen={modal.show}
        data={modal.data}
        onCancel={() => setModal({ ...modal, show: false })}
        onSubmit={deleteData}
      />
      <InternalUnitComponent
        isLoading={isLoading}
        columns={columns}
        data={tableState?.listData}
        tableState={tableState}
        setTableState={setTableState}
        dropDownWorkshop={dropDownWorkshop}
      />
    </Layout>
  )
}

export default InternalUnit
