import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import {
  CardContainer,
  CatatanCard,
  CyocExTaxiCard,
  InformasiUnitExTaxiCard,
  InformasiUserCard,
  Loading,
  PhotoCarouselAlternativeCard,
  SpesifikasiExTaxiCard
} from 'components'
import { logout } from 'store/actions'
import moment from 'moment'
import 'moment/locale/en-gb'
import _ from 'lodash'

const BookingMobilDetailExTaxi = () => {
  const router = useHistory()
  const { id } = useParams()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [data, setData] = useState()

  const { isLoading } = useQuery({
    queryKey: ['booking-mobile-detail-ex-taxi'],
    refetchOnWindowFocus: true,
    queryFn: async () =>
      await apiGet({
        url: `${urls.booking.prefix}/${urls.booking.exTaxi.root}/${id}`,
        token: user.token,
        onLogout: () => dispatch(logout())
      }),
    onSuccess: (data) => {
      setData(data.resData.data)
    }
  })

  if (isLoading) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Booking Mobil
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Ex Taxi
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Booking
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {!data ? (
          <CardContainer>
            <span className="font-medium text-lg text-gray-900">
              Data Booking Mobil ex-Taxi Tidak Ditemukan.
            </span>
          </CardContainer>
        ) : (
          <div className="flex flex-col w-full space-y-5">
            <InformasiUserCard
              title="Informasi User Booking"
              data={{
                nama:
                  data.buyer_fullname && data.buyer_fullname !== ''
                    ? _.startCase(data.buyer_fullname)
                    : '-',
                email: data.buyer_email && data.buyer_email !== '' ? data.buyer_email : '-',
                telepon:
                  data.buyer_phone_number && data.buyer_phone_number !== ''
                    ? data.buyer_phone_number
                    : '-',
                alamat:
                  data.buyer_address && data.buyer_address !== ''
                    ? _.startCase(data.buyer_address)
                    : '-',
                rentang: `${moment(data.start_time).format('DD/MM/YYYY')} - ${moment(
                  data.end_time
                ).format('DD/MM/YYYY')}`
              }}
            />
            <InformasiUnitExTaxiCard
              data={{
                merek:
                  data.taxi.brand_name && data.taxi.brand_name !== '' ? data.taxi.brand_name : '-',
                model:
                  data.taxi.model_name && data.taxi.model_name !== '' ? data.taxi.model_name : '-',
                transmisi:
                  data.taxi.transmission_name && data.taxi.transmission_name !== ''
                    ? data.taxi.transmission_name
                    : '-',
                bahanBakar:
                  data.taxi.fuel_name && data.taxi.fuel_name !== '' ? data.taxi.fuel_name : '-',
                warna: data.color_name && data.color_name !== '' ? data.color_name : '-',
                tahunKeluaran: data.taxi.year && data.taxi.year !== '' ? data.taxi.year : '-'
              }}
            />
            <CyocExTaxiCard data={data?.cyoc_spareparts} />
            <SpesifikasiExTaxiCard data={data} />
            <CatatanCard
              desc={
                data.taxi.description && data.taxi.description !== ''
                  ? _.startCase(data.taxi.description)
                  : 'Tidak ada catatan.'
              }
            />
            <PhotoCarouselAlternativeCard
              data={[...data.taxi.photos, { photo_filename: data?.taxi?.thumbnail_filename }]}
            />
          </div>
        )}
      </Content>
    </Layout>
  )
}

export default BookingMobilDetailExTaxi
