import React from 'react'

const IcCarCustom = ({ className, size = 20 }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1984_44286)">
      <path d="M17.5 9.58334V15.8333M17.5 9.58334H2.5M17.5 9.58334L16.6319 7.50001M17.5 15.8333V17.9167H16.6667V15.8333M17.5 15.8333H16.6667M2.5 9.58334L4.58333 4.58334H10.8333M2.5 9.58334V15.8333M2.5 15.8333V17.9167H3.33333V15.8333M2.5 15.8333H3.33333M16.6667 15.8333H3.33333M15 2.08334V6.25001M17.0833 4.16668H12.9167M6.25 14.1667C5.91848 14.1667 5.60054 14.035 5.36612 13.8006C5.1317 13.5661 5 13.2482 5 12.9167C5 12.5852 5.1317 12.2672 5.36612 12.0328C5.60054 11.7984 5.91848 11.6667 6.25 11.6667C6.58152 11.6667 6.89946 11.7984 7.13388 12.0328C7.3683 12.2672 7.5 12.5852 7.5 12.9167C7.5 13.2482 7.3683 13.5661 7.13388 13.8006C6.89946 14.035 6.58152 14.1667 6.25 14.1667ZM13.75 14.1667C13.4185 14.1667 13.1005 14.035 12.8661 13.8006C12.6317 13.5661 12.5 13.2482 12.5 12.9167C12.5 12.5852 12.6317 12.2672 12.8661 12.0328C13.1005 11.7984 13.4185 11.6667 13.75 11.6667C14.0815 11.6667 14.3995 11.7984 14.6339 12.0328C14.8683 12.2672 15 12.5852 15 12.9167C15 13.2482 14.8683 13.5661 14.6339 13.8006C14.3995 14.035 14.0815 14.1667 13.75 14.1667Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1984_44286">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IcCarCustom
