import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Input, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button, Table } from 'components'
import { IcPlus, IcSearch } from 'assets/icons'
import PropTypes from 'prop-types'

const KategoriInspeksiComponent = ({ columns, data, tableState, setTableState, isLoading }) => {
  const router = useHistory()

  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">Kategori Inspeksi</Typography.Text>
          <Button
            className="flex flex-row items-center justify-around rounded-md border-solid border-primary-500 focus:outline-none space-x-1 px-2 py text-md text-white bg-primary-500 hover:cursor-pointer"
            onClick={() => router.push('/master/inspeksi/kategori-inspeksi/add', { type: 'add' })}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Kategori Inspeksi</span>
          </Button>
        </div>
        <div className="mb-5">
          <Input
            className="w-1/4 rounded-md py-2"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

KategoriInspeksiComponent.propTypes = {
  tableState: PropTypes.object,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  setTableState: PropTypes.func
}

KategoriInspeksiComponent.defaultProps = {
  tableState: {},
  data: [],
  onAdd: () => {},
  setTableState: () => {}
}

export default KategoriInspeksiComponent
