import React, { useEffect, useState } from 'react'
import { Checkbox, Input, Modal, Tag, Typography } from 'antd'
import { Button } from 'components'

const ModalKeteranganInspeksi = ({ type, isOpen, onCancel, data, onSubmit, onDelete }) => {
  const [name, setName] = useState(data?.name ?? '')
  const [uploadImage, setUploadImage] = useState(data?.is_upload_image ?? 0)
  const [description, setDescription] = useState(data?.is_description ?? 0)
  const [ok, setOk] = useState(data?.is_ok ?? 0)
  const [needRepair, setNeedRepair] = useState(data?.is_need_repair ?? 0)
  const [point, setPoint] = useState(data?.point ?? 0)

  useEffect(() => {
    if (isOpen) {
      setName(data?.name ?? '')
      setUploadImage(data?.is_upload_image ?? 0)
      setDescription(data?.is_description ?? 0)
      setOk(data?.is_ok ?? 0)
      setNeedRepair(data?.is_need_repair ?? 0)
      setPoint(data?.point ?? 0)
    }
  }, [isOpen])

  const onOk = () => {
    onSubmit({
      ...data,
      name: name,
      is_upload_image: uploadImage,
      is_description: description,
      is_ok: ok,
      is_need_repair: needRepair,
      point: parseInt(point)
    })
  }

  if (type === 'delete') {
    return (
      <Modal
        width={400}
        title="Hapus Keterangan Inspeksi"
        open={isOpen}
        onOk={() => onDelete(data)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus Keterangan Inspeksi ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }
  return (
    <Modal
      width={400}
      title={type === 'add' ? 'Tambah keterangan Inspeksi' : 'Edit Keterangan Inspeksi'}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      okText={'Confirm'}
      okButtonProps={{ className: 'bg-primary', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
      <Typography.Text className="font-medium mb-2">
        Keterangan
        <span className="ml-1 text-red-500">*</span>
      </Typography.Text>
      <Input
        className="rounded mb-2 pt-1 pb-1 mt-1"
        placeholder="Masukkan Keterangan Inspeksi"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Typography.Text className="font-medium">Menu</Typography.Text>
      <div className="mt-1">
        <Checkbox
          className="custom-checkbox"
          checked={uploadImage === 1}
          onChange={() => setUploadImage(uploadImage === 1 ? 0 : 1)}
        />
        <Typography.Text className="text-gray-700 ml-1 text-sm">Unggah Gambar</Typography.Text>
      </div>
      <div className="mt-1">
        <Checkbox
          className="custom-checkbox"
          checked={description === 1}
          onChange={() => setDescription(description === 1 ? 0 : 1)}
        />
        <Typography.Text className="text-gray-700 ml-1 text-sm">Deskripsi</Typography.Text>
      </div>
      <div className="mt-1">
        <Checkbox
          className="custom-checkbox"
          checked={ok === 1}
          onChange={() => setOk(ok === 1 ? 0 : 1)}
        />
        <Typography.Text className="text-gray-700 ml-1 text-sm">OK</Typography.Text>
      </div>
      <div className="mt-1">
        <Checkbox
          className="custom-checkbox"
          checked={needRepair === 1}
          onChange={() => setNeedRepair(needRepair === 1 ? 0 : 1)}
        />
        <Typography.Text className="text-gray-700 ml-1 text-sm">Perlu Perbaikan</Typography.Text>
      </div>
      <Typography.Text className="font-medium mt-2">
        Point
        <span className="text-[red]"> *</span>
      </Typography.Text>
      <Input
        className="rounded mb-2 pt-1 pb-1"
        placeholder="Masukan Point"
        type="number"
        value={point}
        onChange={(e) => setPoint(e.target.value)}
      />
    </Modal>
  )
}

export default ModalKeteranganInspeksi
