import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import LoginPage from 'pages/auth/LoginPage'
import OTPPage from 'pages/auth/OTPPage'

const AuthRoute = ({ isFirstLoggedIn, pathname }) => (
  <Switch>
    <Route path="/" exact component={LoginPage} />
    <Route path="/login" exact component={LoginPage} />
    <Route path="/otp" exact component={OTPPage} />
    <Route path="*" exact component={LoginPage}/>
    {!isFirstLoggedIn && pathname === '/login' ? <Redirect to="/" /> : null}
  </Switch>
)

export default AuthRoute
