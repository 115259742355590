import React from 'react'
import { CardContainer } from 'components'

const CatatanSection = ({ desc }) => (
  <CardContainer
    minHeight="40%"
  >
    <div
      className="flex flex-col w-full items-start border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300"
    >
      <span className="font-semibold text-2xl text-gray-900">
                 Catatan
      </span>
    </div>
    <div className="flex flex-col items-start justify-start space-y-4 mt-4">
      <span className="font-medium text-gray-700 text-left text-lg">
        {desc}
      </span>
    </div>
  </CardContainer>
)

export default CatatanSection
