import { apiGet, urls } from 'api'
import {
  CalendarCard,
  Loading,
  ServiceJadwalPerluTelahDikonfirmasiCard,
  ServicePickupPerluTelahDikonfirmasiCard
} from 'components'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { dayPropGetter, eventPropGetter } from 'utils'

const menuItem = [
  { label: 'Pickup Servis', id: 0, hash: 'pickup' },
  { label: 'Jadwal Servis', id: 1, hash: 'jadwal' }
]

const JadwalServisContent = () => {
  const { cabang: idCabang } = useParams()
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const [dataCabang, setDataCabang] = useState({})
  const [filterCalendar, setFilterCalendar] = useState({
    start_date: moment().startOf('week').format('YYYY-MM-DD'),
    end_date: moment().endOf('week').format('YYYY-MM-DD')
  })
  const [events, setEvents] = useState([])
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const handleSelectedMenu = (hash) => {
    router.push({
      hash: `service-${hash}`
    })
  }

  const menu = useMemo(() => {
    return (
      menuItem?.find(
        (f) =>
          `#service-${f?.hash}` ===
          `${location?.hash?.split('-')?.[0]}-${location?.hash?.split('-')?.[1]}`
      )?.id ?? 0
    )
  }, [location?.hash])

  // Get Detail Cabang
  const { isLoading } = useQuery({
    queryKey: ['detail-cabang'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}/${idCabang}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDataCabang(res?.resData?.data)
    }
  })

  // Get Jadwal Service
  const { refetch: refetchSchedule } = useQuery({
    queryKey: ['jadwal-service', filterCalendar?.start_date, filterCalendar?.end_date],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service.prefix}/${urls?.service.servicePickup.rootWorkshop}/${idCabang}/${urls.service.servicePickup.serviceSchedules}?start_date=${filterCalendar?.start_date}&end_date=${filterCalendar?.end_date}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      const reWrite = res?.resData?.data?.map((item) => {
        const parseDate = moment(item?.service_date).format('YYYY-MM-DD')
        return {
          ...item,
          title: item?.unit_name,
          start: moment(parseDate + ' ' + item?.service_start_time).toDate(),
          end: moment(parseDate + ' ' + item?.service_end_time).toDate()
        }
      })
      setEvents(reWrite)
    }
  })

  if (isLoading) {
    return <Loading visible={true} />
  }

  return (
    <>
      <CalendarCard
        title={'Jadwal Servis'}
        events={events}
        startDate={new Date(filterCalendar.start_date)}
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventPropGetter}
        onChangeMonth={(date) => {
          const start = new Date(date)
          start.setDate(1)
          setFilterCalendar({
            start_date: moment(start).startOf('week').format('YYYY-MM-DD'),
            end_date: moment(start).endOf('week').format('YYYY-MM-DD')
          })
        }}
        onNextWeek={() => {
          setFilterCalendar((prev) => ({
            start_date: moment(prev.start_date).add(7, 'days').format('YYYY-MM-DD'),
            end_date: moment(prev.end_date).add(7, 'days').format('YYYY-MM-DD')
          }))
        }}
        onPrevWeek={() => {
          setFilterCalendar((prev) => ({
            start_date: moment(prev.start_date).subtract(7, 'days').format('YYYY-MM-DD'),
            end_date: moment(prev.end_date).subtract(7, 'days').format('YYYY-MM-DD')
          }))
        }}
        onGoToAuditTrail={() =>
          router.push(
            `/workshop-management/jadwal-servis/${dataCabang?.name}/${idCabang}/audit-trail`
          )
        }
      />
      {/* Tabs Pickup Servis & Jadwal Servis */}
      <ol className="grid grid-cols-7 list-none rounded-lg overflow-hidden">
        {menuItem.map(({ label, id, hash }) => (
          <li
            onClick={handleSelectedMenu.bind(this, hash)}
            key={id}
            className={`p-4 text-sm-medium text-gray-900 text-center border-l-0 border-y border-r border-solid border-gray-300 cursor-pointer ${
              id === menu ? 'bg-gray-100 ' : 'bg-white '
            } ${
              id === 0 ? 'rounded-l-lg border-l' : id === menuItem.length - 1 ? 'rounded-r-lg' : ''
            }`}>
            {label}
          </li>
        ))}
      </ol>
      {/* Table Confirmation Card */}
      {menu === 0 ? (
        <ServicePickupPerluTelahDikonfirmasiCard
          idCabang={idCabang}
          refetchSchedule={refetchSchedule}
          isWorkshop={true}
        />
      ) : (
        <ServiceJadwalPerluTelahDikonfirmasiCard
          idCabang={idCabang}
          stall={dataCabang?.stall}
          refetchSchedule={refetchSchedule}
          onLogout={onLogout}
          isWorkshop={true}
        />
      )}
    </>
  )
}

export default JadwalServisContent
