import React from 'react'
import { Popover } from 'antd'
import {
  IcCheck,
  IcCoinStack,
  IcEdit,
  IcEye,
  IcInvoice,
  IcSO,
  IcTrash,
  IcUserX,
  IcWhatsApp
} from 'assets/icons'

const ModalLoadMoreCatalog = ({
  children,
  status,
  onPressKonfirmasiPenjualan = () => {},
  onPressKonfirmasiBooking = () => {},
  onPressKonfirmasiKatalog = () => {},
  onPressDetail = () => {},
  onPressDetailUnit = () => {},
  onPressDetailBooking = () => {},
  onPressSO = () => {},
  onPressInvoice = () => {},
  onPressWhatsApp = () => {},
  onPressBatalPenjualan = () => {},
  onPressBatalBooking = () => {},
  onPressDeleteKatalog = () => {},
  onPressUpdatePrice = () => {},
  onPressEditData = () => {}
}) => {
  const containerStyle = 'flex flex-row items-center space-x-2 hover:cursor-pointer'
  const fontStyle = 'font-medium text-gray-500'
  const fontRedStyle = 'font-medium text-danger-500'

  const onReturnContent = () => (
    <div className="grid grid-flow-row items-center gap-4">
      {onReturnConfirmation()}
      {/* {onReturnUpdatePrice()} */}
      {onReturnDetail()}
      <div onClick={() => onPressEditData()} className={containerStyle}>
        <IcEdit className="text-gray-500" />
        <span className={fontStyle}>Edit Data</span>
      </div>
      {/* <div onClick={() => onPressSO()} className={containerStyle}>
        <IcSO className="text-gray-500" />
        <span className={fontStyle}>Lihat SO</span>
      </div> */}
      {onReturnInvoice()}
      <div onClick={() => onPressWhatsApp()} className={containerStyle}>
        <IcWhatsApp className="text-gray-500" />
        <span className={fontStyle}>Whatsapp</span>
      </div>
      {onReturnDeleteCatalog()}
    </div>
  )

  const onReturnConfirmation = () => {
    switch (status) {
      case 'booking lunas':
        return (
          <div
            onClick={() => onPressKonfirmasiPenjualan()}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcCheck className="text-[#12B76A]" size={19} />
            <span className="font-medium text-[#12B76A]">Konfirmasi Penjualan</span>
          </div>
        )
      case 'booked':
        return (
          <div
            onClick={() => onPressKonfirmasiBooking()}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcCheck className="text-[#12B76A]" size={19} />
            <span className="font-medium text-[#12B76A]">Konfirmasi Penjualan</span>
          </div>
        )
      case 'in katalog':
        return (
          <div
            onClick={() => onPressKonfirmasiKatalog()}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcCheck className="text-[#12B76A]" size={19} />
            <span className="font-medium text-[#12B76A]">Konfirmasi Penjualan</span>
          </div>
        )
      default:
        return null
    }
  }

  const onReturnDetail = () => {
    switch (status) {
      case 'booking lunas':
        return (
          <div onClick={() => onPressDetailUnit()} className={containerStyle}>
            <IcEye className="text-gray-500" />
            <span className={fontStyle}>Lihat Detail Unit</span>
          </div>
        )
      case 'booked':
        return (
          <div onClick={() => onPressDetailBooking()} className={containerStyle}>
            <IcEye className="text-gray-500" />
            <span className={fontStyle}>Lihat Detail Booking</span>
          </div>
        )
      case 'in katalog':
        return (
          <div onClick={() => onPressDetail()} className={containerStyle}>
            <IcEye className="text-gray-500" />
            <span className={fontStyle}>Lihat Detail</span>
          </div>
        )
      default:
        return null
    }
  }

  const onReturnInvoice = () => {
    switch (status) {
      case 'in katalog':
        return null
      default:
        return (
          <div onClick={() => onPressInvoice()} className={containerStyle}>
            <IcInvoice className="text-gray-500" />
            <span className={fontStyle}>Invoice</span>
          </div>
        )
    }
  }
  // const onReturnUpdatePrice = () => {
  //   switch (status) {
  //     case 'in katalog':
  //       return (
  //         <div onClick={() => onPressUpdatePrice()} className={containerStyle}>
  //           <IcCoinStack className="text-gray-500" />
  //           <span className={fontStyle}>Ubah Harga</span>
  //         </div>
  //       )
  //     default:
  //       return null
  //   }
  // }

  const onReturnDeleteCatalog = () => {
    switch (status) {
      case 'booking lunas':
        return (
          <div onClick={() => onPressBatalPenjualan()} className={containerStyle}>
            <IcUserX className="text-danger-500" />
            <span className={fontRedStyle}>Batalkan Penjualan</span>
          </div>
        )
      case 'booked':
        return (
          <div onClick={() => onPressBatalBooking()} className={containerStyle}>
            <IcUserX className="text-danger-500" />
            <span className={fontRedStyle}>Batalkan Booking</span>
          </div>
        )
      case 'in katalog':
        return (
          <div onClick={() => onPressDeleteKatalog()} className={containerStyle}>
            <IcTrash className="text-danger-500" />
            <span className={fontRedStyle}>Hapus dari katalog</span>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Popover
      overlayStyle={{
        backgroundColor: 'transparent'
      }}
      overlayInnerStyle={{
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        minHeight: '8rem',
        minWidth: '14rem'
      }}
      zIndex={999}
      trigger="click"
      placement="leftTop"
      content={onReturnContent()}>
      {children}
    </Popover>
  )
}

export default ModalLoadMoreCatalog
