import { Card, Input, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { IcSearch } from 'assets/icons'
import { Table, TabsActive } from 'components'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

const RilisKendaraanComponent = ({
  columns,
  data,
  tableState,
  setTableState,
  isLoading,
  workshops,
  tabs,
  onChangeTab
}) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value,
      offset: 0
    }))
  }, [])

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex flex-col w-full space-y-5">
          <TabsActive data={tabs} onPress={(index) => onChangeTab(index)} />
          <div className="flex justify-between">
            <Select
              className="custom-table-limit"
              showSearch
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}
              style={{ width: '250px' }}
              value={tableState?.workshop_id ?? ''}
              allowClear
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, workshop_id: e ?? '', offset: 0 }))
              }>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Cabang
              </Select.Option>
              {workshops?.map((workshop, idx) => (
                <Select.Option key={idx} value={workshop?.id}>
                  {workshop?.name}
                </Select.Option>
              ))}
            </Select>
            <Input
              className="w-1/4 rounded-md py-1.5"
              placeholder="Pencarian"
              value={tableState?.keywords}
              prefix={<IcSearch className="text-gray-400" />}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Table
            columns={columns}
            data={data}
            pending={isLoading}
            totalRows={tableState?.totalRows}
            handlePerRowsChange={handlePerRowsChange}
            pageSize={tableState?.limit}
            page={tableState?.offset}
            onChangePrevPage={() => {
              if (tableState?.offset > 0) {
                onChangePage(tableState?.offset - tableState?.limit)
              }
            }}
            onChangeNextPage={() => {
              if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                onChangePage(tableState?.offset + tableState?.limit)
              }
            }}
          />
        </div>
      </Card>
    </Content>
  )
}

RilisKendaraanComponent.propTypes = {
  columns: PropTypes.array,
  tableState: PropTypes.object,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  setTableState: PropTypes.func,
  isLoading: PropTypes.bool,
  tabs: PropTypes.array,
  onChangeTab: PropTypes.func
}

RilisKendaraanComponent.defaultProps = {
  columns: PropTypes.array,
  tableState: {},
  data: [],
  onAdd: () => {},
  setTableState: () => {},
  isLoading: false,
  tabs: [],
  onChangeTab: () => {}
}

export default RilisKendaraanComponent
