import React from 'react'
import { CardContainer } from 'components'

const DocumentUnitCard = ({ data }) => (
  <CardContainer isHeightFull width="90%">
    <div className="flex flex-col w-full items-start border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
      <span className="font-semibold text-2xl text-gray-900">Dokumen</span>
    </div>
    <div className="flex flex-col w-full space-y-4 pt-4">
      {data.map((item, index) => (
        <div key={index} className="grid grid-cols-2 w-full items-center justify-start">
          <span className="font-medium text-gray-900 text-left text-lg">{item.name}</span>
          <span className="font-normal text-gray-700 text-left text-lg -ml-20">{item.value}</span>
        </div>
      ))}
    </div>
  </CardContainer>
)

export default DocumentUnitCard
