import React from 'react'
import CardContainer from 'components/CardContainer'

const InformasiUnitExTaxiCard = ({ data }) => {
  const fieldStyle = 'font-semibold text-gray-900 text-md'
  const valueStyle = 'font-normal text-gray-700 text-md'

  return (
    <CardContainer>
      <div className="flex w-full pb-4 border border-solid border-b-1 border-x-0 border-t-0 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Informasi Unit</span>
      </div>
      <div className="grid grid-cols-1 gap-3 mt-4">
        {Object.entries(data).map((item, index) => (
          <div key={index} className="grid grid-cols-2 w-[30%]">
            <span className={fieldStyle}>
              {`${item[0].substring(0, 1).toUpperCase()}${item[0]
                .substring(1, item[0].length)
                .replace(/([A-Z])/g, ' $1')
                .trim()}`}
            </span>
            <span className={valueStyle}>{item[1]}</span>
          </div>
        ))}
      </div>
    </CardContainer>
  )
}

export default InformasiUnitExTaxiCard
