import { Breadcrumb, Layout } from 'antd'
import { apiGet, urls } from 'api'
import { IcEye } from 'assets/icons'
import { Button } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import JadwalInspeksiComponent from './components/JadwalInspeksiComponent'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null,
  province_id: '',
  city_id: ''
}

const JadwalInspeksi = (props) => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const columns = [
    {
      name: 'Nama Bengkel',
      selector: (row) => row?.name,
      cell: (row) => row?.name
    },
    {
      name: 'Provinsi',
      selector: (row) => row?.province_name,
      cell: (row) => row?.province_name
    },
    {
      name: 'Cabang',
      selector: (row) => row?.city_name,
      cell: (row) => row?.city_name
    },
    {
      name: 'Request',
      selector: (row) => row?.request_count
    },
    {
      name: 'Terkonfirmasi',
      selector: (row) => row?.confirmed_count
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,

      cell: (row) => (
        <Button
          className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
          onClick={() => props.history.push(`/inspeksi/jadwal-inspeksi/${row?.id}`)}>
          <IcEye className="text-gray-700 mr-1" size={18} />
          <span>Lihat</span>
        </Button>
      )
    }
  ]

  // Get API
  // Get All List Cabang
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'jadwal-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.province_id,
      tableState.city_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.inspectionSchedule}?keywords=${tableState.keywords}&province_id=${tableState?.province_id}&city_id=${tableState?.city_id}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Provinces
  useQuery({
    queryKey: ['province-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.location?.province?.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setProvinces(res?.resData?.data ?? [])
    }
  })

  // Get City by province
  useQuery({
    queryKey: ['cities-list', tableState.province_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (tableState.province_id !== '' && tableState.province_id !== null) {
        const res = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.cities?.filterByProvince}/${tableState.province_id}`,
          onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setCities(res?.resData?.data ?? [])
    }
  })

  // *END API

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item>Jadwal Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item>Daftar Cabang</Breadcrumb.Item>
      </Breadcrumb>
      <JadwalInspeksiComponent
        columns={columns}
        isLoading={isLoading ?? isFetching}
        data={tableState?.listData}
        provinces={provinces}
        cities={cities}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}

export default JadwalInspeksi
