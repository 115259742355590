import React from 'react'

function CardContainer({ minHeight, width, isHeightFull, className, children, px }) {
  const heightFull = isHeightFull ? 'h-full' : ''
  const widthDynamic = width ?? 'w-full'
  const paddingX = px ?? 'px-6'
  const minH = minHeight ? `min-h[${minHeight}]` : 'min-h-fit'

  return (
    <div
      className={`flex-1 ${widthDynamic} ${className} ${heightFull} ${minH} ${paddingX} py-6 my-2 rounded-md shadow-md bg-white`}>
      {children}
    </div>
  )
}

export default CardContainer
