import React from 'react'
import { IcChevronLeft, IcDownload } from 'assets/icons'

const HeaderPrint = ({ titleDownload, onPressDownload = () => {}, onPressBack = () => {} }) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <div
        onClick={() => onPressBack()}
        className="flex flex-row items-center justify-center space-x-2 hover:cursor-pointer">
        <IcChevronLeft className="text-gray-500" />
        <span className="font-medium text-gray-500">Kembali</span>
      </div>
      <div
        onClick={() => onPressDownload()}
        className="flex flex-row items-center justify-center space-x-2 rounded-lg py-2 px-3 border border-solid border-gray-300 bg-white hover:cursor-pointer">
        <IcDownload className="text-gray-700" />
        <span className="font-medium text-gray-700">{titleDownload}</span>
      </div>
    </div>
  )
}

export default HeaderPrint
