import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import TestDriveComponent from './components/TestDriveComponent'
import { Button } from 'components'
import { IcEye } from 'assets/icons'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { perPageInitialData } from 'constant'
import { logout } from 'store/actions'

const initialSelectedLocation = {
  province_id: '',
  city_id: ''
}

const TestDrive = (props) => {
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [selectedLocation, setSelectedLocation] = useState({ ...initialSelectedLocation })

  // list state
  const [workshops, setWorkshops] = useState([])
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])

  // pagination
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)
  const [meta, setMeta] = useState({
    http_code: undefined,
    total: undefined,
    offset: undefined,
    limit: undefined
  })

  const onLogout = () => dispatch(logout())

  const { refetch } = useQuery({
    queryKey: ['getWorkshops'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.list.prefix}/${urls.testDrive.list.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=0`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setWorkshops(data.resData.data)
      setCurrentPage(0)
      setMeta(data.resData.meta)
    }
  })

  useQuery({
    queryKey: 'getProvinces',
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.additional.prefix}/${urls.testDrive.additional.provinces.all}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      const newData = data?.resData?.data?.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    }
  })

  useQuery({
    queryKey: ['getCities', selectedLocation.province_id],
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.additional.prefix}/${urls.testDrive.additional.cities.root}/${selectedLocation.province_id}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      const newData = data?.resData?.data?.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    enabled: selectedLocation.province_id !== ''
  })

  const onHandleExtraUrl = () => {
    let extraUrl = ''
    if (keyword !== '') {
      extraUrl += `&keywords=${keyword}`
    }
    if (selectedLocation.province_id !== '' && selectedLocation.province_id > -1) {
      extraUrl += `&province_id=${selectedLocation.province_id}`
    }
    if (selectedLocation.city_id !== '' && selectedLocation.city_id > -1) {
      extraUrl += `&city_id=${selectedLocation.city_id}`
    }
    return extraUrl
  }

  const onChangePrevNextPage = (offset) => {
    setIsLoadingCRUD(true)
    setMeta({ ...meta, offset })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.testDrive.list.prefix}/${urls.testDrive.list.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${offset}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setWorkshops(response.resData.data)
      } else {
        refetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    setIsLoadingCRUD(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.testDrive.list.prefix}/${
          urls.testDrive.list.root
        }?limit=${value}&offset=0${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setWorkshops(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        refetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const handleSearch = async (value) => {
    let extraUrl = ''
    if (selectedLocation.province_id !== '' && selectedLocation.province_id > -1) {
      extraUrl += `&province_id=${selectedLocation.province_id}`
    }
    if (selectedLocation.city_id !== '' && selectedLocation.city_id > -1) {
      extraUrl += `&city_id=${selectedLocation.city_id}`
    }
    const response = await apiGet({
      url: `${urls.testDrive.list.prefix}/${
        urls.testDrive.list.root
      }?keywords=${value}${extraUrl}&limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=0`,
      token: user.token,
      onLogout
    })
    if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
      setWorkshops(response.resData.data)
      setMeta(response.resData.meta)
    } else {
      refetch()
    }
    setKeyword(value)
  }

  const handleChangeCallback = (province_id, city_id) => {
    let extraUrl = ''

    if (province_id !== '' && province_id > -1) {
      extraUrl += `&province_id=${province_id}`
    }
    if (city_id !== '' && city_id > -1) {
      extraUrl += `&city_id=${city_id}`
    }

    setIsLoadingCRUD(true)
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.testDrive.list.prefix}/${
          urls.testDrive.list.root
        }?keywords=${keyword}${extraUrl}&limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=0`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setWorkshops(response.resData.data)
        setMeta(response.resData.meta)
      } else {
        refetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const handleChange = (value, name) => {
    if (name === 'province_id') {
      setSelectedLocation({
        province_id: value,
        city_id: ''
      })
      handleChangeCallback(value, '')
    } else {
      setSelectedLocation((prev) => ({
        ...prev,
        city_id: value
      }))
      handleChangeCallback(selectedLocation.province_id, value)
    }
  }

  const fontColumnValueStyle = 'font-medium text-gray-700'

  const columns = [
    {
      name: 'Nama Bengkel',
      selector: (row) => row.name,
      cell: (row) => <span className={fontColumnValueStyle}>{row.name}</span>
    },
    {
      name: 'Provinsi',
      selector: (row) => row.province_name,
      cell: (row) => <span className={fontColumnValueStyle}>{row.province_name}</span>
    },
    {
      name: 'Cabang',
      selector: (row) => row.city_name,
      cell: (row) => <span className={fontColumnValueStyle}>{row.city_name}</span>
    },
    {
      name: 'Request',
      selector: (row) => row.request_count,
      cell: (row) => <span className={fontColumnValueStyle}>{row.request_count}</span>
    },
    {
      name: 'Terkonfirmasi',
      selector: (row) => row.confirmed_count,
      cell: (row) => <span className={fontColumnValueStyle}>{row.confirmed_count}</span>
    },
    {
      name: 'Action',
      button: false,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '150px',
      cell: (row) => (
        <Button
          className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
          onClick={() => props.history.push(`/test-drive/${row.id}#unconfirmed&Mobil%20Bekas`)}>
          <IcEye className="text-gray-700 mr-1" size={18} />
          <span>Lihat</span>
        </Button>
      )
    }
  ]
  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Test Drive
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-500">
          Daftar Cabang
        </Breadcrumb.Item>
      </Breadcrumb>
      <TestDriveComponent
        isLoading={isLoadingCRUD}
        cities={cities}
        provinces={provinces}
        selectedLocation={selectedLocation}
        handleChange={handleChange}
        handleSearch={handleSearch}
        columns={columns}
        data={workshops}
        currentPage={meta.offset ? meta.offset + 1 : 1}
        totalPerPage={
          perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) > meta.total
            ? meta.total
            : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
        }
        dropDownDataFilter={perPageArr}
        lengthAllData={meta.total}
        onChangePrevPage={() => {
          if (currentPage > 0) {
            setCurrentPage(currentPage - 1)
            onChangePrevNextPage(
              (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
            )
          }
        }}
        onChangeNextPage={() => {
          if (
            perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
            meta.total
          ) {
            setCurrentPage(currentPage + 1)
            onChangePrevNextPage(
              (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
            )
          }
        }}
        onChangeDropDown={(value) => onChangePerPage(value)}
      />
    </Layout>
  )
}

export default TestDrive
