import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { CalendarCard, HeaderMapService, Loading } from 'components'
import ServiceJadwalDaftarUnit from 'components/card/service-jadwal-daftar-unit'
import moment from 'moment'
import 'moment/locale/en-gb'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { dayPropGetter, eventPropGetter } from 'utils'

const MekanikJadwalServiceDetailPage = () => {
  const { idCabang } = useParams()
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const [dataCabang, setDataCabang] = useState({})
  const [filterCalendar, setFilterCalendar] = useState({
    start_date: moment().startOf('week').format('YYYY-MM-DD'),
    end_date: moment().endOf('week').format('YYYY-MM-DD')
  })
  const [events, setEvents] = useState([])
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  // Get Detail Cabang
  const { isLoading } = useQuery({
    queryKey: ['detail-cabang'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}/${idCabang}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDataCabang(res?.resData?.data)
    }
  })

  // Get Jadwal Service
  const { refetch: refetchSchedule } = useQuery({
    queryKey: ['jadwal-service', filterCalendar?.start_date, filterCalendar?.end_date],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service.prefix}/${urls?.service.servicePickup.rootWorkshop}/${idCabang}/${urls.service.servicePickup.serviceSchedules}?start_date=${filterCalendar?.start_date}&end_date=${filterCalendar?.end_date}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      const reWrite = res?.resData?.data?.map((item) => {
        const parseDate = moment(item?.service_date).format('YYYY-MM-DD')
        return {
          ...item,
          title: item?.unit_name,
          start: moment(parseDate + ' ' + item?.service_start_time).toDate(),
          end: moment(parseDate + ' ' + item?.service_end_time).toDate()
        }
      })
      setEvents(reWrite)
    }
  })

  if (isLoading) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">Servis</Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.push('/mekanik/services/jadwal')}>
          Jadwal Service
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          {dataCabang?.name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <div className="flex flex-col w-full space-y-5">
          <HeaderMapService
            namaBengkel={dataCabang?.name}
            cabang={dataCabang?.city_name}
            alamat={`Kota ${dataCabang?.city_name}, Provinsi ${dataCabang?.province_name}`}
            pin_google_map={dataCabang?.pin_google_map}
          />
          <CalendarCard
            title="Jadwal Servis"
            events={events}
            startDate={new Date(filterCalendar.start_date)}
            dayPropGetter={dayPropGetter}
            eventPropGetter={eventPropGetter}
            onChangeMonth={(date) => {
              const start = new Date(date)
              start.setDate(1)
              setFilterCalendar({
                start_date: moment(start).startOf('week').format('YYYY-MM-DD'),
                end_date: moment(start).endOf('week').format('YYYY-MM-DD')
              })
            }}
            onNextWeek={() => {
              setFilterCalendar((prev) => ({
                start_date: moment(prev.start_date).add(7, 'days').format('YYYY-MM-DD'),
                end_date: moment(prev.end_date).add(7, 'days').format('YYYY-MM-DD')
              }))
            }}
            onPrevWeek={() => {
              setFilterCalendar((prev) => ({
                start_date: moment(prev.start_date).subtract(7, 'days').format('YYYY-MM-DD'),
                end_date: moment(prev.end_date).subtract(7, 'days').format('YYYY-MM-DD')
              }))
            }}
            onGoToAuditTrail={() =>
              router.push(`/mekanik/services/jadwal/${dataCabang?.name}/${idCabang}/audit-trail`)
            }
          />
          <ServiceJadwalDaftarUnit
            idCabang={idCabang}
            stall={dataCabang?.stall}
            refetchSchedule={refetchSchedule}
            onLogout={onLogout}
          />
        </div>
      </Content>
    </Layout>
  )
}

export default MekanikJadwalServiceDetailPage
