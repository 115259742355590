import React from 'react'
import { CardContainer } from 'components'
import { formatCurrency } from 'utils'

const Header = ({ sku, name, price, stock }) => {
  const fontFieldStyle = 'font-medium text-lg text-gray-900'
  const fontValueStyle = 'font-normal text-gray-700'
  const wrapperColStyle = 'flex flex-col h-full justify-center'

  return (
    <CardContainer>
      <div className="flex flex-row items-center justify-between flex-wrap w-full">
        <div className={wrapperColStyle}>
          <span className={fontFieldStyle}>SKU</span>
          <span className={fontValueStyle}>{sku}</span>
        </div>
        <div className={wrapperColStyle}>
          <span className={fontFieldStyle}>Nama Sparepart</span>
          <span className={fontValueStyle}>{name}</span>
        </div>
        <div className={wrapperColStyle}>
          <span className={fontFieldStyle}>Harga</span>
          <span className={fontValueStyle}>{formatCurrency(price)}</span>
        </div>
        <div className={wrapperColStyle}>
          <span className={fontFieldStyle}>Stock</span>
          <span className={fontValueStyle}>{stock}</span>
        </div>
      </div>
    </CardContainer>
  )
}

export default Header
