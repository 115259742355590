import { Input } from 'antd'
import React from 'react'

function NormalFormInput({ data, state, onChange, isEmpty, message }) {
  const { label, ...inputProps } = data
  return (
    <div className="grid grid-cols-1 gap-0 mb-2">
      <p className="text-sm-medium text-gray-700">{label}</p>
      <div>
        <Input
          className="min-h-[2.5rem] rounded-lg relative"
          {...inputProps}
          value={state}
          onChange={(e) => onChange(e.target.value)}
        />
        {isEmpty && (
          <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
            {message}
          </div>
        )}
      </div>
    </div>
  )
}

export default NormalFormInput
