import React from 'react'
import { BaseIcon, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { IcImage } from 'assets/icons'

const ModalPaymentProof = ({ proof_of_payment_photo, minHeight, maxWidth, minWidth, onClose }) => {
  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight} maxWidth={maxWidth} minWidth={minWidth}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">Bukti Pembayaran</span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div
            className={
              'flex flex-col min-w-full h-60 bg-gray-100 rounded-lg justify-center border-2 border-dashed border-gray-300'
            }>
            {proof_of_payment_photo ? (
              <img
                src={proof_of_payment_photo}
                alt="payment-proof-photo"
                className={`h-full w-full rounded-lg object-center object-fill hover:cursor-none`}
              />
            ) : (
              <div className="flex flex-col space-y-2 items-center">
                <IcImage className="text-gray-400" size={60} />
              </div>
            )}
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalPaymentProof
