import { apiPut, urls } from 'api'
import { CustomButton, Loading } from 'components'
import ModalLayout from 'layouts/modal-layout'
import moment from 'moment'
import { useState } from 'react'
import { showErrorMessage } from 'utils'

const ModalDeleteJadwalUnitService = ({ isOpen, data, onClose, token, refetch, onLogout }) => {
  const fontFieldStyle = 'font-medium text-gray-900'
  const fontValueStyle = 'font-normal text-gray-700'

  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const handleDeleteBookings = async (e) => {
    e?.preventDefault()

    try {
      setIsLoadingCRUD(true)
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.servicePickup.root}/${data?.id}/${urls.service.servicePickup.cancel}`,
        token: token,
        onLogout
      })
      if (res?.resStatus === 200) {
        onClose()
        refetch()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ModalLayout
      className={'max-w-sm'}
      headerTitle="Hapus Data Unit Servis"
      openModal={isOpen}
      onCancel={onClose}>
      <div className="grid grid-cols-1 gap-4">
        <span className="text-sm-medium text-gray-500 max-w-[90%]">
          Apakah Anda yakin ingin menghapus unit servis:
        </span>
        <div className="grid grid-cols-1 gap-4">
          <div className="flex flex-col space-y-1">
            <span className={fontFieldStyle}>Unit</span>
            <span className={fontValueStyle}>{data?.unit_name ?? ''}</span>
          </div>
          <div className="flex flex-col space-y-1">
            <span className={fontFieldStyle}>Pemilik</span>
            <span className={fontValueStyle}>{data?.full_name}</span>
          </div>
          <div className="flex flex-col space-y-1">
            <span className={fontFieldStyle}>Tanggal</span>
            <span className={fontValueStyle}>
              {moment(data?.service_date).format('DD/MM/YYYY')}
            </span>
          </div>
          <div className="flex flex-col space-y-1">
            <span className={fontFieldStyle}>Waktu Servis</span>
            <span className={fontValueStyle}>{`${moment(data?.service_start_time, 'HH:mm').format(
              'HH:mm'
            )} - ${moment(data?.service_end_time, 'HH:mm').format('HH:mm')}`}</span>
          </div>
          {data?.stall_no ? (
            <div className="flex flex-col space-y-1">
              <span className={fontFieldStyle}>Stall</span>
              <span className={fontValueStyle}>{data?.stall_no}</span>
            </div>
          ) : null}
        </div>
        <div className="flex flex-row w-full items-center justify-end space-x-4">
          {isLoadingCRUD ? (
            <Loading title="" visible classNameWidth="" />
          ) : (
            <>
              <CustomButton type="plain" onClick={() => onClose()}>
                Cancel
              </CustomButton>
              <CustomButton onClick={handleDeleteBookings}>Delete</CustomButton>
            </>
          )}
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalDeleteJadwalUnitService
