import React from 'react'
import { CardContainer } from 'components'

const InformasiPemilikSection = ({
  nama,
  email,
  telepon,
  alamat
}) => {
  const fieldStyle = 'font-medium text-gray-900 text-lg'
  const valueStyle = 'font-normal text-gray-700 text-md'

  return (
    <CardContainer
      minHeight="20%"
    >
      <div
        className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300"
      >
        <span className="font-semibold text-2xl text-gray-900">
                   Informasi Pemilik
        </span>
      </div>
      <div className="grid grid-cols-4 gap-4 w-full pt-4">
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Nama
          </span>
          <span className={valueStyle}>
            {nama}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Email
          </span>
          <span className={valueStyle}>
            {email}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Nomor Telepon
          </span>
          <span className={valueStyle}>
            {telepon}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Alamat
          </span>
          <span className={valueStyle}>
            {alamat}
          </span>
        </div>
      </div>
    </CardContainer>
  )
}

export default InformasiPemilikSection
