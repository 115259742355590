import { Breadcrumb, Layout } from 'antd'
import { apiGet, urls } from 'api'
import { IcEye } from 'assets/icons'
import { Button } from 'components'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { logout } from 'store/actions'
import BookingMobilComponent from './components/BookingMobilComponent'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  unit_name: '',
  listData: []
}

const BookingMobil = () => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [tableStateExTaxi, setTableStateExTaxi] = useState(initialTableState)
  const [units, setUnits] = useState([])
  const location = useLocation()
  const hashUrl = location?.hash?.split('-')
  const [tabs, setTabs] = useState([
    {
      name: 'Mobil Bekas',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'exTaxi' || hashUrl?.[hashUrl?.length - 1] === '#exTaxi'
          ? false
          : true,
      hash: 'mobilBekas'
    },
    {
      name: 'ex-Taxi',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'exTaxi' || hashUrl?.[hashUrl?.length - 1] === '#exTaxi'
          ? true
          : false,
      hash: 'exTaxi'
    }
  ])

  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const columns = [
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="inline-flex flex-col gap-1">
          <span className="text-gray-700 font-medium">{row?.unit_name}</span>
          <span className="text-gray-500 font-medium">{row?.police_number}</span>
        </div>
      ),
      sortable: true,
      sortField: 'unit_name'
    },
    {
      name: 'Rentang Booking',
      selector: (row) => row?.start_time,
      cell: (row) => (
        <div>
          <span className="whitespace-normal break-all font-medium text-gray-700">{`${moment(
            row?.start_time
          ).format('DD/MM/YYYY')} - ${moment(row?.end_time).format('DD/MM/YYYY')}`}</span>
        </div>
      ),
      sortable: true,
      sortField: 'start_time'
    },
    {
      name: 'Nama Pemesan',
      selector: (row) => row?.buyer_fullname,
      cell: (row) => <span className="font-medium text-gray-700">{row?.buyer_fullname}</span>,
      sortable: true,
      sortField: 'buyer_fullname'
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <Button
          className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
          onClick={() => {
            if (tabs.find((item) => item.active === true).name === 'Mobil Bekas') {
              return router.push(`/booking-mobil/${row?.unit_id}/${row?.catalog_id}`)
            } else {
              return router.push(`/booking-mobil/ex-taxi/${row.id}`)
            }
          }}>
          <IcEye className="text-gray-700 mr-1" size={18} />
          <span>Lihat</span>
        </Button>
      )
    }
  ]

  // Get All List
  const { isLoading, refetch: refetchMobilBekas } = useQuery({
    queryKey: [
      'bookings-list',
      tableState.offset,
      tableState.limit,
      tableState.keywords,
      tableState.unit_name
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.booking.prefix}/${urls?.booking.rootAdmin}?keywords=${tableState.keywords}&limit=${tableState?.limit}&offset=${tableState?.offset}&unit_name=${tableState?.unit_name}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get All List Ex-Taxi
  const { isLoading: isLoadingExTaxi, refetch: refetchExTaxi } = useQuery({
    queryKey: [
      'bookings-list-ex-taxi',
      tableStateExTaxi.offset,
      tableStateExTaxi.limit,
      tableStateExTaxi.keywords,
      tableStateExTaxi.unit_name
    ],
    refetchOnWindowFocus: false,
    enabled:
      !!tabs.find((item) => item.active === true).name === 'ex-Taxi' ||
      !tableStateExTaxi ||
      !!tableStateExTaxi,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.booking.prefix}/${urls?.booking.exTaxi.root}?keywords=${tableStateExTaxi.keywords}&limit=${tableStateExTaxi?.limit}&offset=${tableStateExTaxi?.offset}&unit_name=${tableStateExTaxi?.unit_name}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableStateExTaxi((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Units
  const { refetch: refetchUnitMobilBekas } = useQuery({
    queryKey: ['units-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.booking.prefix}/${urls?.booking.units}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setUnits(res?.resData?.data ?? [])
    }
  })

  // Get Units Ex-Taxi
  const { refetch: refetchUnitExTaxi } = useQuery({
    queryKey: ['units-list-ex-taxi'],
    refetchOnWindowFocus: false,
    enabled: !!tabs.find((item) => item.active === true).name === 'ex-Taxi',
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.booking.prefix}/${urls?.booking.exTaxi.units}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setUnits(res?.resData?.data ?? [])
    }
  })

  const onChangeDataTable = (index) => {
    if (index === 0) {
      refetchMobilBekas()
      refetchUnitMobilBekas()
    }
    if (index === 1) {
      refetchExTaxi()
      refetchUnitExTaxi()
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Booking Mobil
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-500">
          {tabs[0].active ? 'Mobil Bekas' : 'Ex Taxi'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <BookingMobilComponent
        isLoading={isLoading ?? isLoadingExTaxi}
        columns={columns}
        data={
          tabs.find((item) => item.active === true).name === 'ex-Taxi'
            ? tableStateExTaxi.listData
            : tableState?.listData
        }
        tableState={
          tabs.find((item) => item.active === true).name === 'ex-Taxi'
            ? tableStateExTaxi
            : tableState
        }
        setTableState={(prev) =>
          tabs.find((item) => item.active === true).name === 'ex-Taxi'
            ? setTableStateExTaxi(prev)
            : setTableState(prev)
        }
        units={units}
        tabs={tabs}
        onChangeTab={(index) => {
          onChangeDataTable(index)
          const tempArr = [...tabs]
          tempArr.map((itemCh, indexCh) => {
            if (index === indexCh) {
              if (itemCh.active) {
                return false
              } else {
                itemCh.active = true
                router?.push({
                  hash: itemCh?.hash
                })
              }
            } else {
              itemCh.active = false
            }
          })
          setTabs(tempArr)
        }}
      />
    </Layout>
  )
}
export default BookingMobil
