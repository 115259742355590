import React, { useState } from 'react'
import { Breadcrumb, Layout, DatePicker } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory, useLocation } from 'react-router-dom'
import { CardContainer, FormDropDown, FormInput, Loading, Table, TabsActive } from 'components'
import { HeaderContainerJualUnit } from 'pages/main/jual-unit/components'
import { Formik } from 'formik'
import moment from 'moment'
import 'moment/locale/en-gb'
import { IcEye } from 'assets/icons'
import { formatCurrency } from 'utils'
import { metaInitialData, perPageInitialData } from 'constant'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import _ from 'lodash'

const RiwayatPenjualanPage = () => {
  const router = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [currentPage, setCurrentPage] = useState(0)
  const [meta, setMeta] = useState(metaInitialData)
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)
  const [listJualUnitRiwayatPenjualan, setListJualUnitRiwayatPenjualan] = useState()
  const [dropDownOwners, setDropDownOwners] = useState([{ name: 'Semua Pemilik', value: -1 }])
  const [dropDownBuyers, setDropDownBuyers] = useState([{ name: 'Semua Pembeli', value: -1 }])
  const location = useLocation()
  const hashUrl = location?.hash?.split('-')
  const [tabs, setTabs] = useState([
    {
      name: 'Mobil Bekas',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'exTaxi' || hashUrl?.[hashUrl?.length - 1] === '#exTaxi'
          ? false
          : true,
      hash: 'mobilBekas'
    },
    {
      name: 'ex-Taxi',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'exTaxi' || hashUrl?.[hashUrl?.length - 1] === '#exTaxi'
          ? true
          : false,
      hash: 'exTaxi'
    }
  ])

  const onLogout = () => {
    dispatch(logout())
  }

  const { isLoading: isLoadingMobilBekas, refetch: refetchMobilBekas } = useQuery({
    queryKey: ['jual-unit-booking-histories-mobil-bekas'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await Promise.all([
        apiGet({
          url: `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.root}?limit=${
            perPageArr.find((item) => item.selected === true).value
          }&offset=${0}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.buyers}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.owners}`,
          token: user.token,
          onLogout
        })
      ]),
    onSuccess: (data) => {
      setListJualUnitRiwayatPenjualan(data[0].resData.data)
      setMeta(data[0].resData.meta)
      setCurrentPage(0)
      if (data[1].resStatus === 200) {
        if (data[1].resData.data.length !== 0) {
          data[1].resData.data.map((item) => {
            if (item !== '') {
              setDropDownBuyers((prev) => [...prev, { name: item, value: item }])
            }
          })
        }
      }
      if (data[2].resStatus === 200) {
        if (data[2].resData.data.length !== 0) {
          data[2].resData.data.map((item) => {
            if (item !== '') {
              setDropDownOwners((prev) => [...prev, { name: item, value: item }])
            }
          })
        }
      }
    }
  })

  const { isLoading: isLoadingExTaxi, refetch: refetchExTaxi } = useQuery({
    queryKey: ['jual-unit-booking-histories-ex-taxi'],
    refetchOnWindowFocus: false,
    enabled: !!tabs.find((item) => item.active === true).name === 'ex-Taxi',
    queryFn: async () =>
      await Promise.all([
        apiGet({
          url: `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.exTaxi.root}?limit=${
            perPageArr.find((item) => item.selected === true).value
          }&offset=${0}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.exTaxi.buyers}`,
          token: user.token,
          onLogout
        })
      ]),
    onSuccess: (data) => {
      setListJualUnitRiwayatPenjualan(data[0].resData.data)
      setMeta(data[0].resData.meta)
      setCurrentPage(0)
      if (data[1].resStatus === 200) {
        if (data[1].resData.data.length !== 0) {
          data[1].resData.data.map((item) => {
            if (item !== '') {
              setDropDownBuyers((prev) => [...prev, { name: item, value: item }])
            }
          })
        }
      }
    }
  })

  const onRefetch = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Mobil Bekas':
        return refetchMobilBekas()
      case 'ex-Taxi':
        return refetchExTaxi()
    }
  }

  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const [tempDate, setTempDate] = useState([])
  const [dateRangeValue, setDateRangeValue] = useState([])
  const [ownerSelected, setOwnerSelected] = useState(-1)
  const [buyerSelected, setBuyerSelected] = useState(-1)
  const [searchBookingHistory, setSearchBookingHistory] = useState('')
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)
  const [isDateFilterHelper, setIsDateFilterHelper] = useState(false)

  const onClearInitialState = () => {
    setSearchBookingHistory('')
    setOwnerSelected(-1)
    setBuyerSelected(-1)
    setDateRangeValue([])
    setIsDateFilterHelper(false)
    setDropDownBuyers([{ name: 'Semua Pembeli', value: -1 }])
    setDropDownOwners([{ name: 'Semua Pemilik', value: -1 }])
  }

  const onFilterSearchListBookingHistory = async (
    owner,
    buyer,
    search,
    startDate,
    endDate,
    isDateFilter
  ) => {
    setMeta((prev) => ({ ...prev, offset: 0 }))
    setCurrentPage(0)
    let extraUrl = ''
    let urlTemp = ''
    const tabActive = tabs.find((item) => item.active === true).name
    if (owner && owner !== -1) {
      extraUrl += `&owner_fullname=${owner}`
    }
    if (buyer && buyer !== -1) {
      extraUrl += `&buyer_fullname=${buyer}`
    }
    if (search && search !== '') {
      extraUrl += `&keywords=${search}`
    }
    if (startDate && endDate && isDateFilter === true) {
      extraUrl += `&start_date=${startDate}&end_date=${endDate}`
    }
    if (tabActive === 'Mobil Bekas') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${0}${extraUrl}`
    }
    if (tabActive === 'ex-Taxi') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.exTaxi.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${0}${extraUrl}`
    }
    const response = await apiGet({
      url: urlTemp,
      token: user.token,
      onLogout
    })
    if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
      setListJualUnitRiwayatPenjualan(response.resData.data)
      setMeta(response.resData.meta)
    } else {
      onRefetch()
    }
  }

  //* Table State
  const onHandleExtraUrl = () => {
    let extraUrl = ''

    if (ownerSelected && (ownerSelected !== -1 || parseInt(ownerSelected) > -1)) {
      extraUrl += `&owner_fullname=${ownerSelected}`
    }
    if (buyerSelected && buyerSelected !== -1) {
      extraUrl += `&buyer_fullname=${buyerSelected}`
    }
    if (searchBookingHistory && searchBookingHistory !== '') {
      extraUrl += `&keywords=${searchBookingHistory}`
    }
    if (dateRangeValue?.[0] && dateRangeValue?.[1] && isDateFilterHelper === true) {
      extraUrl += `&start_date=${
        dateRangeValue?.[0] ? moment(dateRangeValue[0]).format('DD/MM/YYYY') : ''
      }&end_date=${dateRangeValue?.[1] ? moment(dateRangeValue[1]).format('DD/MM/YYYY') : ''}`
    }

    return extraUrl
  }

  const onChangePrevNextPage = (offset) => {
    let urlTemp = ''
    const tabActive = tabs.find((item) => item.active === true).name
    setMeta({ ...meta, offset })
    setIsLoadingHelper(true)
    if (tabActive === 'Mobil Bekas') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    if (tabActive === 'ex-Taxi') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.exTaxi.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: urlTemp,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListJualUnitRiwayatPenjualan(response.resData.data)
      } else {
        onRefetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    let urlTemp = ''
    const tabActive = tabs.find((item) => item.active === true).name
    if (tabActive === 'Mobil Bekas') {
      urlTemp = `${urls.jualUnit.prefix}/${
        urls.jualUnit.bookingHistory.root
      }?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`
    }
    if (tabActive === 'ex-Taxi') {
      urlTemp = `${urls.jualUnit.prefix}/${
        urls.jualUnit.bookingHistory.exTaxi.root
      }?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`
    }
    setIsLoadingHelper(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: urlTemp,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListJualUnitRiwayatPenjualan(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        onRefetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangeDataTable = (index) => {
    onClearInitialState()
    if (index === 0) {
      refetchMobilBekas()
    }
    if (index === 1) {
      refetchExTaxi()
    }
  }

  const fontColumnStyle = 'font-medium text-gray-700'

  const columnsMobilBekas = [
    {
      name: 'Tanggal',
      selector: (row) => row.created_at,
      cell: (row) => (
        <span className={fontColumnStyle}>{moment(row.created_at).format('DD/MM/YYYY')}</span>
      )
    },
    {
      name: 'Unit',
      selector: (row) => row.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <span className={fontColumnStyle}>{row.unit_name}</span>
          <span className="font-normal text-gray-500 text-xs tracking-tight">
            {row.police_number && row.police_number !== '' ? row.police_number : '-'}
          </span>
        </div>
      )
    },
    {
      name: 'Pemilik',
      selector: (row) => row.owner_fullname,
      cell: (row) => (
        <span className={fontColumnStyle}>
          {row.owner_fullname === '' ? '-' : _.startCase(row.owner_fullname)}
        </span>
      )
    },
    {
      name: 'Pembeli',
      selector: (row) => row.buyer_fullname,
      cell: (row) => <span className={fontColumnStyle}>{_.startCase(row.buyer_fullname)}</span>
    },
    {
      name: 'Harga',
      selector: (row) => row.price,
      cell: (row) => <span className={fontColumnStyle}>{formatCurrency(row.price)}</span>
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          onClick={() => router.push(`/jual-unit/riwayat-penjualan/detail/${row.id}/Mobil Bekas`)}
          className="flex items-center justify-center self-center px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
          <IcEye className="text-gray-700" />
        </div>
      )
    }
  ]

  const columnsExTaxi = [
    {
      name: 'Tanggal',
      selector: (row) => row.created_at,
      cell: (row) => (
        <span className={fontColumnStyle}>{moment(row.created_at).format('DD/MM/YYYY')}</span>
      )
    },
    {
      name: 'Model Mobil',
      selector: (row) => row.unit_name,
      cell: (row) => <span className={fontColumnStyle}>{row.unit_name}</span>
    },
    {
      name: 'Pembeli',
      selector: (row) => row.buyer_fullname,
      cell: (row) => <span className={fontColumnStyle}>{_.startCase(row.buyer_fullname)}</span>
    },
    {
      name: 'Harga',
      selector: (row) => row.price,
      cell: (row) => <span className={fontColumnStyle}>{formatCurrency(row.price)}</span>
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div
          onClick={() => router.push(`/jual-unit/riwayat-penjualan/detail/${row.id}/ex-Taxi`)}
          className="flex items-center justify-center self-center px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
          <IcEye className="text-gray-700" />
        </div>
      )
    }
  ]

  const onReturnColumnsTable = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Mobil Bekas':
        return columnsMobilBekas
      case 'ex-Taxi':
        return columnsExTaxi
    }
  }

  if (isLoadingMobilBekas ?? isLoadingExTaxi ?? isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Riwayat Penjualan
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <TabsActive
              data={tabs}
              onPress={(index) => {
                onChangeDataTable(index)
                const tempArr = [...tabs]
                tempArr.map((itemCh, indexCh) => {
                  if (index === indexCh) {
                    if (itemCh.active) {
                      return false
                    } else {
                      itemCh.active = true
                      router?.push({
                        hash: itemCh?.hash
                      })
                    }
                  } else {
                    itemCh.active = false
                  }
                })
                setTabs(tempArr)
              }}
            />
            <HeaderContainerJualUnit title="Riwayat Penjualan" />
            <div className="flex flex-row w-full items-center justify-between mt-2">
              <div className="flex flex-row w-full items-center space-x-5">
                {tabs.find((item) => item.active === true).name === 'Mobil Bekas' ? (
                  <div className="w-1/6">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        ownerSelector: ownerSelected
                      }}>
                      <FormDropDown
                        name="ownerSelector"
                        data={dropDownOwners}
                        onChange={(value) => {
                          setOwnerSelected(value)
                          onFilterSearchListBookingHistory(
                            value ?? '',
                            buyerSelected,
                            searchBookingHistory,
                            dateRangeValue?.[0]
                              ? moment(dateRangeValue[0]).format('DD/MM/YYYY')
                              : '',
                            dateRangeValue?.[1]
                              ? moment(dateRangeValue[1]).format('DD/MM/YYYY')
                              : '',
                            isDateFilterHelper
                          )
                        }}
                        placeholder="Pilih Pemilik"
                        allowClear={true}
                        isSearch={true}
                      />
                    </Formik>
                  </div>
                ) : null}
                <div className="w-1/6">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      buyerSelector: buyerSelected
                    }}>
                    <FormDropDown
                      name="buyerSelector"
                      data={dropDownBuyers}
                      onChange={(value) => {
                        setBuyerSelected(value)
                        onFilterSearchListBookingHistory(
                          ownerSelected,
                          value ?? '',
                          searchBookingHistory,
                          dateRangeValue?.[0] ? moment(dateRangeValue[0]).format('DD/MM/YYYY') : '',
                          dateRangeValue?.[1] ? moment(dateRangeValue[1]).format('DD/MM/YYYY') : '',
                          isDateFilterHelper
                        )
                      }}
                      placeholder="Pilih Pemilik"
                      allowClear={true}
                      isSearch={true}
                    />
                  </Formik>
                </div>
                <div className="w-1/4 h-full">
                  <RangePicker
                    onOpenChange={(open) => setIsShowDatePicker(true)}
                    onChange={(dates) => {
                      setTempDate(dates)
                      if (!dates) {
                        setTempDate([])
                        setDateRangeValue(dates)
                        setIsDateFilterHelper(false)
                        onFilterSearchListBookingHistory(
                          ownerSelected,
                          buyerSelected,
                          searchBookingHistory,
                          '',
                          '',
                          false
                        )
                      }
                    }}
                    format="DD/MM/YYYY"
                    open={isShowDatePicker}
                    value={dateRangeValue}
                    className="py-3 rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500"
                    renderExtraFooter={() => (
                      <div className="flex flex-row justify-end items-center space-x-4 my-3">
                        <button
                          type="button"
                          className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-gray-700 bg-white"
                          onClick={() => {
                            setTempDate([])
                            setIsShowDatePicker(false)
                          }}>
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-white bg-primary-500"
                          onClick={() => {
                            setIsShowDatePicker(false)
                            if (tempDate?.length > 0) {
                              setDateRangeValue(tempDate)
                              setIsDateFilterHelper(true)
                              onFilterSearchListBookingHistory(
                                ownerSelected,
                                buyerSelected,
                                searchBookingHistory,
                                moment(tempDate[0]).format('DD/MM/YYYY'),
                                moment(tempDate[1]).format('DD/MM/YYYY'),
                                true
                              )
                              setTempDate([])
                            }
                          }}>
                          Apply
                        </button>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="w-1/4">
                <Formik
                  initialValues={{
                    bookingHistorySearch: searchBookingHistory
                  }}>
                  <FormInput
                    name="bookingHistorySearch"
                    placeholder="Pencarian"
                    type="text"
                    isIconLeft
                    iconName="search"
                    onValidate={(value) => {
                      setSearchBookingHistory(value)
                      onFilterSearchListBookingHistory(
                        ownerSelected,
                        buyerSelected,
                        value,
                        dateRangeValue?.[0] ? moment(dateRangeValue[0]).format('DD/MM/YYYY') : '',
                        dateRangeValue?.[1] ? moment(dateRangeValue[1]).format('DD/MM/YYYY') : '',
                        isDateFilterHelper
                      )
                    }}
                  />
                </Formik>
              </div>
            </div>
            <Table
              currentPage={meta.offset ? meta.offset + 1 : 1}
              totalPerPage={
                perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
                meta.total
                  ? meta.total
                  : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
              }
              dropDownDataFilter={perPageArr}
              lengthAllData={meta.total}
              columns={onReturnColumnsTable()}
              data={listJualUnitRiwayatPenjualan}
              onChangePrevPage={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onChangePrevNextPage(
                    (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeNextPage={() => {
                if (
                  perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
                  meta.total
                ) {
                  setCurrentPage(currentPage + 1)
                  onChangePrevNextPage(
                    (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeDropDown={(value) => onChangePerPage(value)}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default RiwayatPenjualanPage
