import React, { useState } from 'react'
import { Breadcrumb, Image, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import {
  BaseIcon,
  CardContainer,
  CatatanCard,
  DocumentUnitCard,
  InformasiSpekUnitVerticalCard,
  InspeksiCard,
  InspeksiCardNullify,
  Loading
} from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import _ from 'lodash'
import { CarouselMultipleLayout } from 'layouts/CarouselMultipleLayout'
import HistoryUnitServicesInspectionCard from 'components/card/history-unit-services-inspection-card'
import BadgeProgress from 'components/badge-progress'
import { formatCurrency } from 'utils'
import moment from 'moment'
import { mdiChevronRight } from '@mdi/js'

const UnitDetailPage = () => {
  const { id } = useParams()
  const router = useHistory()
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const onLogout = () => dispatch(logout())
  const [unit, setUnit] = useState()
  const [historyInspection, setHistoryInspection] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: []
  })

  const [historyServices, setHistoryServices] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: []
  })

  // Get Detail Unit
  const { isLoading } = useQuery({
    queryKey: ['detail-unit'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.perusahaan.detail.unit.root}/${id}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setUnit(data.resData.data)
    }
  })

  // Get List History Inspeksi
  const { isLoading: isLoadingInspeksi, isFetching: isFetchingInspeksi } = useQuery({
    queryKey: ['historyInspection-list', historyInspection.offset, historyInspection.limit, id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token: user.token,
        url: `${urls?.inspection.prefix}/${urls?.inspection.history}/${id}?limit=${historyInspection?.limit}&offset=${historyInspection?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setHistoryInspection((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get List History Service
  const { isLoading: isLoadingServices, isFetching: isFetchingServices } = useQuery({
    queryKey: ['historyServices-list', historyServices.offset, historyServices.limit, id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token: user.token,
        url: `${urls?.service.prefix}/${urls?.service?.historiesBooking}/${id}?limit=${historyServices?.limit}&offset=${historyServices?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setHistoryServices((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  const columnsHistoryInspection = [
    {
      name: 'Title',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-medium text-lg text-gray-900">{`Inspeksi - ${row?.workshop_name}`}</span>
          <span className="font-normal text-lg text-gray-900">{row?.date}</span>
        </div>
      )
    },
    {
      name: 'Status',
      width: 'auto',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-normal text-lg text-gray-900">Status</span>
          <div>
            <BadgeProgress status={row?.status} />
          </div>
        </div>
      )
    },
    {
      name: 'Action',
      width: 'auto',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => router.push(`/inspeksi/hasil-inspeksi/${row?.id}`)}>
          <BaseIcon path={mdiChevronRight} iconSize={21} color="#E12B25" />
        </div>
      )
    }
  ]

  const columnsHistoryServices = [
    {
      name: 'Title',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-medium text-lg text-gray-900">{row?.service_name}</span>
          <span className="font-normal text-lg text-gray-900">
            {moment(row?.updated_at).format('ll')}
          </span>
        </div>
      )
    },
    {
      name: 'Result',
      width: 'auto',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-normal text-left text-lg text-gray-900">{`${
            row?.item_count ?? 0
          } item`}</span>
          <span className="font-semibold text-left text-lg text-[#E12B25]">
            {formatCurrency(row?.price_total ?? 0)}
          </span>
        </div>
      )
    },
    {
      name: 'Status',
      width: 'auto',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-normal text-lg text-gray-900">Status</span>
          <div>
            <BadgeProgress status={row?.status} />
          </div>
        </div>
      )
    },
    {
      name: 'Action',
      width: 'auto',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => router.push(`/services/jadwal/detail/${row?.id}`)}>
          {' '}
          <BaseIcon path={mdiChevronRight} iconSize={21} color="#E12B25" />
        </div>
      )
    }
  ]

  if (isLoading) {
    return <Loading visible={true} />
  }

  return (
    <Layout
      style={{
        padding: 24,
        background: '#F5F5F5',
        width: '100%',
        overflowY: 'hidden'
      }}
      className="overflow-y-hidden">
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Master Data
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Perusahaan
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Perusahaan
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Unit
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content className="space-y-10" style={{ margin: 8 }}>
        {!unit ? (
          <CardContainer>
            <span className="font-medium text-lg text-gray-900">
              Data Detail Unit Tidak Ditemukan.
            </span>
          </CardContainer>
        ) : (
          <div className="flex flex-col w-full space-y-5">
            <div className="grid grid-cols-2 w-full space-x-5 items-start justify-start">
              <InformasiSpekUnitVerticalCard
                data={{
                  merek: unit.brand.name,
                  model: unit.model.name,
                  tipe: unit.type.name,
                  transmisi: unit.transmission.name,
                  tahun: unit.production_year,
                  platNomor: unit.police_number,
                  warna: unit.color.name,
                  bahanBakar: unit.fuel.name,
                  nomorRangka:
                    unit.frame_number && unit.frame_number !== '' ? unit.frame_number : '-',
                  nomorMesin:
                    unit.engine_number && unit.engine_number !== '' ? unit.engine_number : '-',
                  tag:
                    unit.type.categories.length !== ''
                      ? unit.type.categories.map((item) => item.name).join(' ')
                      : ''
                }}
              />
              <DocumentUnitCard
                data={[
                  {
                    name: 'STNK',
                    value: unit.document_stnk_status ? 'Ada' : 'Tidak Ada'
                  },
                  {
                    name: 'BPKB',
                    value: unit.document_bpkb_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'Faktur',
                    value: unit.document_faktur_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'KTP Pemilik',
                    value: unit.document_ktp_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'Kwitansi',
                    value: unit.document_kwitansi_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'KEUR',
                    value: unit.document_keur_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'Form A',
                    value: unit.document_form_a_status ? 'Ada' : 'Tidak ada'
                  }
                ]}
              />
            </div>
            <CatatanCard
              desc={
                unit.description && unit.description !== ''
                  ? _.startCase(unit.description)
                  : 'Tidak ada catatan.'
              }
            />
            {unit.is_inspected ? (
              <InspeksiCard data={unit.inspection_result} onGoToDetailInspeksi={() => {}} />
            ) : (
              <InspeksiCardNullify />
            )}
            <CardContainer className="space-y-5">
              <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
                <span className="font-semibold text-2xl text-gray-900">Foto Mobil</span>
              </div>
              <CarouselMultipleLayout
                slideToShow={4}
                totalItem={unit.photos.length}
                slidesToScroll={-1}
                className={'cursor-grab'}>
                {unit.photos.length !== 0 ? (
                  unit.photos.map((item, index) => (
                    <Image
                      key={index}
                      className="object-center object-cover pr-4 w-72 h-52"
                      src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${item.photo_filename}`}
                      alt={`pict${index}`}
                      preview
                    />
                  ))
                ) : (
                  <span className="text-lg font-medium text-gray-700">
                    Belum Terdapat Foto Unit.
                  </span>
                )}
              </CarouselMultipleLayout>
            </CardContainer>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
              <HistoryUnitServicesInspectionCard
                title="Riwayat Servis"
                columns={columnsHistoryServices}
                data={historyServices?.listData}
                tableState={historyServices}
                setTableState={setHistoryServices}
                isLoading={isLoadingServices ?? isFetchingServices}
              />
              <HistoryUnitServicesInspectionCard
                title="Riwayat Inspeksi"
                columns={columnsHistoryInspection}
                data={historyInspection?.listData}
                tableState={historyInspection}
                setTableState={setHistoryInspection}
                isLoading={isLoadingInspeksi ?? isFetchingInspeksi}
              />
            </div>
          </div>
        )}
      </Content>
    </Layout>
  )
}

export default UnitDetailPage
