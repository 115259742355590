import React, { useState } from 'react'
import { IcEye, IcScissors, IcSearch, IcShoppingBag } from 'assets/icons'
import { AccumulationCard, BadgeItem, CardContainer, Table } from 'components'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { Breadcrumb, Input, Layout, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useQuery } from 'react-query'
import { logout } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet, urls } from 'api'
import { formatDate } from 'helpers'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  owner_name: '',
  status: '',
  sort_by: 'id',
  sort_order: null
}

const AnalyticsUnit = () => {
  const { push, go } = useHistory()
  const [statusList, setStatusList] = useState([])
  const [ownerList, setOwnerList] = useState([])
  const [tableState, setTableState] = useState(initialTableState)

  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [listTotal, setListTotal] = useState({
    sell: 0,
    service: 0,
    inspection: 0
  })
  useQuery({
    queryKey: ['status-data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        url: `master-service/admin/analytics/units/statuses`,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setStatusList(res?.resData?.data)
    }
  })

  useQuery({
    queryKey: ['owner-data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        url: `master-service/admin/analytics/units/owners`,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setOwnerList(res?.resData?.data)
    }
  })

  const { isLoading } = useQuery({
    queryKey: [
      'unit-data',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.owner_name,
      tableState.status
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order,
        owner_name: tableState.owner_name,
        status: tableState.status
      }
      const res = await apiGet({
        url: `master-service/admin/analytics/units`,
        data: params,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // const handleSort = (column, sortDirection) => {
  //   setTableState((prevState) => ({
  //     ...prevState,
  //     sort_by: column.sortField,
  //     sort_order: sortDirection
  //   }))
  // }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  const handleRenderStatusBadgeItem = (status) => {
    let typeBadgeColor = 'default'

    if (status.toLowerCase().includes('inspeksi')) {
      typeBadgeColor = 'success'
    }
    if (status.toLowerCase().includes('dijual')) {
      typeBadgeColor = 'default'
    }
    if (status.toLowerCase().includes('servis')) {
      typeBadgeColor = 'warning'
    }
    if (status.toLowerCase().includes('garasi')) {
      typeBadgeColor = 'gray'
    }
    return <BadgeItem type={typeBadgeColor}>{_.startCase(status)}</BadgeItem>
  }

  const columnStyle = 'font-medium text-gray-700 text-left'

  const columns = [
    {
      name: 'Tanggal',
      selector: (row) => formatDate(new Date(row.updated_at)),
      cell: (row) => <span className={columnStyle}>{formatDate(new Date(row.updated_at))}</span>,
      grow: 1.5
    },
    {
      name: 'Model Mobil',
      selector: (row) => row.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-2 py-3">
          <span className={columnStyle}>{row?.unit_name}</span>
          <span className="font-normal text-gray-500 tracking-tight">
            {row?.police_number && row?.police_number !== '' ? row?.police_number : '-'}
          </span>
        </div>
      ),
      grow: 4
    },
    {
      name: 'Pemilik',
      selector: (row) => row.unit_owner,
      cell: (row) => <span className={columnStyle}>{row?.unit_owner}</span>,
      grow: 2
    },
    {
      name: 'Status',
      selector: (row) => handleRenderStatusBadgeItem(row.unit_status),
      cell: (row) => (
        <span className={columnStyle}>{handleRenderStatusBadgeItem(row.unit_status)}</span>
      ),
      grow: 1.5
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          onClick={handleNavigationDetail.bind(this, row?.id, row?.unit_name)}
          className="flex items-center gap-2 justify-center self-center px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
          <IcEye className="text-gray-700" />
          <span className="text-sm-medium text-gray-700">Lihat</span>
        </div>
      ),
      grow: 1
    }
  ]

  const handleNavigationDetail = (id, name) => {
    push(`/analytics-dashboard/unit/${id}/${name}`)
  }

  const handleNavigationReport = (path) => {
    push(`/analytics-dashboard/unit/${path}`)
  }

  // Get total sell
  useQuery({
    queryKey: ['sell-total'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixJualBeli}/${urls?.analytic?.unit?.jualCount}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, sell: res?.resData?.data ?? 0 }))
    }
  })

  // Get total service
  useQuery({
    queryKey: ['service-total'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixService}/${urls?.analytic?.unit?.serviceCount}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, service: res?.resData?.data?.count ?? 0 }))
    }
  })

  // Get total inspection
  useQuery({
    queryKey: ['inspection-total'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixInspeksi}/${urls?.analytic?.unit?.inspectionCount}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, inspection: res?.resData?.data ?? 0 }))
    }
  })

  const accumulationData = [
    {
      label: 'Total Jual',
      icon: <IcShoppingBag className={'stroke-blue-500'} />,
      desc: listTotal?.sell ?? 0,
      bgClassColor: 'bg-blue-50',
      path: 'total-jual'
    },
    {
      label: 'Total Servis',
      icon: <IcScissors className={'stroke-warning-500'} />,
      desc: listTotal?.service ?? 0,
      bgClassColor: 'bg-warning-50',
      path: 'total-servis'
    },
    {
      label: 'Total Inspeksi',
      icon: <IcSearch className={'stroke-success-500'} />,
      desc: listTotal?.inspection ?? 0,
      bgClassColor: 'bg-success-50',
      path: 'total-inspeksi'
    }
  ]

  return (
    <Layout
      className="grid grid-cols-1 gap-6"
      style={{ padding: 32, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Unit</Breadcrumb.Item>
      </Breadcrumb>
      <Content className="grid grid-cols-1 gap-6">
        {/* Total Accumulation Card */}
        <div className="grid grid-cols-3 gap-6">
          {accumulationData.map((data, index) => (
            <AccumulationCard
              onClick={handleNavigationReport.bind(this, data.path)}
              key={index}
              data={data}
            />
          ))}
        </div>
        {/* Table Daftar Unit */}
        <CardContainer className={'grid grid-cols-1 gap-5'}>
          <p className="display-xs-semibold text-gray-900">Daftar Unit</p>
          <div className="flex items-center justify-between">
            <div className="flex flex-row items-center gap-3">
              <Select
                showSearch
                allowClear
                placeholder="Semua Pemilik"
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
                style={{ width: '200px' }}
                onSearch={(e) => {}}
                optionFilterProp="children"
                value={tableState?.owner_name === '' ? null : tableState?.owner_name}
                onChange={(e) =>
                  setTableState((prev) => ({ ...prev, owner_name: e ?? '', offset: 0 }))
                }>
                {ownerList?.map((owner, idx) => (
                  <Select.Option key={`owner-${idx}`} value={owner}>
                    {owner}
                  </Select.Option>
                ))}
              </Select>
              <Select
                showSearch
                allowClear
                placeholder="Semua Status"
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
                style={{ width: '200px' }}
                onSearch={(e) => {}}
                optionFilterProp="children"
                value={tableState?.status === '' ? null : tableState?.status}
                onChange={(e) =>
                  setTableState((prev) => ({ ...prev, status: e ?? '', offset: 0 }))
                }>
                {statusList?.map((status, idx) => (
                  <Select.Option key={`status-${idx}`} value={status}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Input
              className="w-1/4 rounded-md py-3"
              placeholder="Pencarian"
              prefix={<IcSearch className="text-gray-400" />}
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
              }
            />
          </div>
          <Table
            columns={columns}
            data={tableState?.listData}
            pending={isLoading}
            // onSort={handleSort}
            totalRows={tableState?.totalRows}
            handlePerRowsChange={handlePerRowsChange}
            pageSize={tableState?.limit}
            page={tableState?.offset}
            onChangePrevPage={() => {
              if (tableState?.offset > 0) {
                onChangePage(tableState?.offset - tableState?.limit)
              }
            }}
            onChangeNextPage={() => {
              if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                onChangePage(tableState?.offset + tableState?.limit)
              }
            }}
          />
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default AnalyticsUnit
