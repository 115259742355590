import React, { useEffect, useState } from 'react'
import { BaseIcon, CustomButton, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Input, Typography } from 'antd'
import { validationInput } from 'helpers'

const ModalCreateUpdateKategoriCYOC = ({ state, onClose, onSubmit }) => {
  const [kategori, setKategori] = useState({
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    },
    messageError: ''
  })

  const onChangeInput = (value, identifier, setIdentifier) => {
    let { valid, messageError } = validationInput(value, identifier?.validation)
    setIdentifier({
      ...identifier,
      touched: true,
      value: value,
      valid: valid,
      messageError: messageError
    })
  }

  useEffect(() => {
    if (state?.type !== 'create') {
      const validated = validationInput(state.data.name, kategori?.validation)
      setKategori({
        ...kategori,
        value: state.data.name,
        valid: validated.valid,
        messageError: validated.messageError,
        touched: true
      })
    }
  }, [])

  const onOk = async () => {
    // if (!kategori.valid) {
    //   return
    // }
    if (state.type === 'create') {
      onSubmit({
        name: kategori.value
      })
    } else {
      onSubmit({
        id: state?.data?.id,
        name: kategori.value
      })
    }
  }

  return (
    <ModalContainer>
      <ModalForm minHeight="min-h-[20%]" maxHeight="max-h-[50%]">
        <div className="flex flex-col w-full space-y-5">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.type === 'create' ? 'Tambah' : 'Edit'} Kategori`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div className="w-full">
            <Typography.Text className="font-medium">
              Nama Kategori
              <span className="text-[red]">*</span>
            </Typography.Text>
            <Input
              style={{ margin: 0 }}
              className={`rounded block ${
                !kategori.valid && kategori.touched && 'border-red-500 border border-solid'
              }`}
              placeholder="Masukkan Nama Kategori"
              onChange={(e) => onChangeInput(e.target.value, kategori, setKategori)}
              value={kategori.value}
            />
            {!kategori.valid && kategori.touched && (
              <span className="text-[red]">*{kategori.messageError}</span>
            )}
          </div>
          <div className="flex items-center justify-end space-x-2 mt-4">
            <CustomButton type="plain" onClick={() => onClose()}>
              Cancel
            </CustomButton>
            <CustomButton onClick={() => onOk()}>
              {state.type === 'create' ? 'Tambah Kategori' : 'Simpan'}
            </CustomButton>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateKategoriCYOC
