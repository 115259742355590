import React, { useState } from 'react'
import { Breadcrumb, Card, Image, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  CardContainer,
  CatatanCard,
  DocumentUnitCard,
  InformasiUnitCard,
  InformasiUserCard,
  InspeksiCard,
  InspeksiCardNullify,
  Loading
} from 'components'
import { apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { formatCurrency, formatThousandsINTL } from 'utils'
import InformasiSpekUnitVerticalCard from 'components/card/informasi-spek-unit-vertical-card'
import _ from 'lodash'
import { CarouselMultipleLayout } from 'layouts/CarouselMultipleLayout'
import { logout } from 'store/actions'
import moment from 'moment'

const StockUnitDetailPage = () => {
  const { id } = useParams()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [stockUnits, setStockUnits] = useState()
  const { isLoading } = useQuery({
    queryKey: ['jual-unit-stocks-detail'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}/${id}`,
        token: user.token,
        onLogout: () => dispatch(logout())
      }),
    onSuccess: (data) => {
      setStockUnits(data.resData.data)
    }
  })

  const { isLoading: workshopLoading } = useQuery({
    queryKey: ['workshop-by-id'],
    refetchOnWindowFocus: false,
    enabled: !!stockUnits,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.workshops.root}/${stockUnits.branch_id}`,
        token: user.token,
        onLogout: () => dispatch(logout())
      }),
    onSuccess: (data) => {
      setStockUnits({
        ...stockUnits,
        workshop_address:
          data.resData.data.address && data.resData.data.address !== ''
            ? data.resData.data.address
            : '-'
      })
    },
    onError: () => {
      setStockUnits({
        ...stockUnits,
        workshop_address: '-'
      })
    }
  })

  if (isLoading ?? workshopLoading) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500 cursor-pointer" onClick={() => push('/jual-unit/stock')}>Stock</Breadcrumb.Item>
        {stockUnits ? (
          <Breadcrumb.Item className="font-inter font-medium text-gray-500">
            {stockUnits.unit_name}
          </Breadcrumb.Item>
        ) : null}
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {!stockUnits ? (
          <CardContainer>
            <span className="font-medium text-lg text-gray-900">
              Data Detail Stock Tidak Ditemukan.
            </span>
          </CardContainer>
        ) : (
          <div className="flex flex-col w-full space-y-5">
            <InformasiUnitCard
              unit={stockUnits.unit_name}
              status={_.startCase(stockUnits.status)}
              harga={formatCurrency(stockUnits.price)}
              alamat={stockUnits.workshop_address ?? '-'}
            />
            <InformasiUserCard
              title="Informasi Pemilik"
              data={{
                nama:
                  stockUnits.owner_fullname && stockUnits.owner_fullname !== ''
                    ? stockUnits.owner_fullname
                    : '-',
                email:
                  stockUnits.owner_email && stockUnits.owner_email !== ''
                    ? stockUnits.owner_email
                    : '-',
                telepon:
                  stockUnits.owner_phone_number && stockUnits.owner_phone_number !== ''
                    ? stockUnits.owner_phone_number
                    : '-',
                alamat:
                  stockUnits.owner_address && stockUnits.owner_address !== ''
                    ? stockUnits.owner_address
                    : '-'
              }}
            />
            <div className="grid grid-cols-2 w-full space-x-5 items-start justify-start">
              <InformasiSpekUnitVerticalCard
                data={{
                  merek: stockUnits.brand_name,
                  model: stockUnits.model_name,
                  tipe: stockUnits.type_name,
                  transmisi: stockUnits.transmission_name,
                  tahun: stockUnits.production_year,
                  platNomor: stockUnits.police_number,
                  warna:
                    stockUnits.color_name && stockUnits.color_name !== ''
                      ? stockUnits.color_name
                      : '-',
                  bahanBakar:
                    stockUnits.fuel_name && stockUnits.fuel_name !== ''
                      ? stockUnits.fuel_name
                      : '-',
                  jarakTempuh:
                    stockUnits.mileage && stockUnits.mileage !== ''
                      ? `${formatThousandsINTL(stockUnits.mileage)} KM`
                      : '-',
                  nomorRangka:
                    stockUnits.frame_number && stockUnits.frame_number !== ''
                      ? stockUnits.frame_number
                      : '-',
                  nomorMesin:
                    stockUnits.engine_number && stockUnits.engine_number !== ''
                      ? stockUnits.engine_number
                      : '-',
                  tag:
                    stockUnits.tags && stockUnits.tags.length !== 0
                      ? stockUnits.tags.map((item) => item.name).join(' ')
                      : '-'
                }}
              />
              <DocumentUnitCard
                data={[
                  {
                    name: 'STNK',
                    value: stockUnits.document_stnk_status ? 'Ada' : 'Tidak Ada'
                  },
                  {
                    name: 'No. STNK',
                    value:
                      stockUnits.document_stnk && stockUnits.document_stnk !== ''
                        ? stockUnits.document_stnk
                        : '-'
                  },
                  {
                    name: 'BPKB',
                    value: stockUnits.document_bpkb_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'No. BPKB',
                    value:
                      stockUnits.document_bpkb && stockUnits.document_bpkb !== ''
                        ? stockUnits.document_bpkb
                        : '-'
                  },
                  {
                    name: 'Faktur',
                    value: stockUnits.document_faktur_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'KTP Pemilik',
                    value: stockUnits.document_ktp_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'Kwitansi',
                    value: stockUnits.document_kwitansi_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'KEUR',
                    value: stockUnits.document_keur_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'No. KEUR',
                    value:
                      stockUnits.document_keur && stockUnits.document_keur !== ''
                        ? stockUnits.document_keur
                        : '-'
                  },
                  {
                    name: 'Form A',
                    value: stockUnits.document_form_a_status ? 'Ada' : 'Tidak ada'
                  },
                  {
                    name: 'Pajak',
                    value: stockUnits.tax_status ? 'Hidup' : 'Mati'
                  },
                  {
                    name: 'Pajak Berlaku',
                    value:
                      stockUnits.tax_active_end && stockUnits.tax_active_end !== ''
                        ? moment(stockUnits.tax_active_end, 'YYYY-MM-DD').format('DD-MM-YYYY')
                        : '-'
                  }
                ]}
              />
            </div>
            <CatatanCard
              desc={
                stockUnits.description && stockUnits.description !== ''
                  ? stockUnits.description
                  : 'Tidak ada catatan.'
              }
            />
            {stockUnits.is_inspected ? (
              <InspeksiCard data={stockUnits.inspection_result} onGoToDetailInspeksi={() => { }} />
            ) : (
              <InspeksiCardNullify />
            )}
            <CardContainer className="space-y-5">
              <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
                <span className="font-semibold text-2xl text-gray-900">Foto Mobil</span>
              </div>
              <CarouselMultipleLayout
                slideToShow={4}
                totalItem={stockUnits.photos.length}
                slidesToScroll={-1}
                className={'cursor-grab'}>
                {stockUnits?.thumbnail_filename && (
                  <div key={`photo-thumbnail`}>
                    <Image
                      className="object-center object-cover w-72 h-52"
                      src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${stockUnits?.thumbnail_filename}`}
                      alt={`picture-thumbnail`}
                      preview
                    />
                  </div>
                )}
                {stockUnits.photos.length !== 0
                  ? stockUnits.photos.map((item, index) => (
                    <div key={`photo-stock-${index}`}>
                      <Image
                        key={index}
                        className="object-center object-cover pr-4 w-72 h-52"
                        src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${item.photo_filename}`}
                        alt={`pict${index}`}
                        preview
                      />
                    </div>
                  ))
                  : null}
              </CarouselMultipleLayout>
            </CardContainer>
          </div>
        )}
      </Content>
    </Layout>
  )
}

export default StockUnitDetailPage
