import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { AccumulationCard } from 'components'
import { Table } from 'components'
import { IcEye, IcFlag, IcScissors, IcSearch, IcShoppingBag } from 'assets/icons'

const CabangComponent = ({
  columns,
  listTotal,
  tableState,
  setTableState,
  provinces,
  cities,
  isLoading,
  handlePerRowsChange,
  onChangePage
}) => {
  const { push } = useHistory()
  const handleNavigationReport = (path) => {
    push(`/analytics-dashboard/cabang/${path}`)
  }

  const accumulationData = [
    {
      label: 'Total Cabang',
      icon: <IcFlag className={'stroke-primary-500'} />,
      desc: listTotal?.cabang,
      bgClassColor: 'bg-primary-100',
      path: 'total-cabang'
    },
    {
      label: 'Test Drive',
      icon: <IcShoppingBag className={'stroke-blue-500'} />,
      desc: listTotal?.testDrive,
      bgClassColor: 'bg-blue-100',
      path: 'test-drive'
    },
    {
      label: 'Total Mekanik',
      icon: <IcScissors className={'stroke-warning-500'} />,
      desc: listTotal?.mechanic,
      bgClassColor: 'bg-warning-100',
      path: 'total-mekanik'
    },
    {
      label: 'Total Inspektor',
      icon: <IcSearch className={'stroke-success-500'} />,
      desc: listTotal?.inspektor,
      bgClassColor: 'bg-success-100',
      path: 'total-inspektor'
    },
    {
      label: 'Total Security',
      icon: <IcEye className={'stroke-purple-500'} />,
      desc: listTotal?.security,
      bgClassColor: 'bg-purple-100',
      path: 'total-security'
    }
  ]

  return (
    <Content className="grid grid-cols-1 gap-6">
      {/* content */}
      {/* selective */}
      <div className="grid grid-cols-5 gap-6">
        {accumulationData.map((data, index) => (
          <AccumulationCard
            onClick={handleNavigationReport.bind(this, data.path)}
            key={index}
            data={data}
          />
        ))}
      </div>

      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-xl">Daftar Cabang</Typography.Text>
        </div>
        {/* filter */}
        <div className="grid grid-cols-1 gap-5">
          <div className="flex justify-between">
            <div className="flex flex-row items-center space-x-5">
              <Select
                showSearch
                allowClear
                placeholder="Semua Provinsi"
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                style={{ minWidth: '200px' }}
                onSearch={(e) => {}}
                optionFilterProp="children"
                value={tableState?.province_id === '' ? null : tableState?.province_id}
                onChange={(e) =>
                  setTableState((prev) => ({
                    ...prev,
                    province_id: e ?? '',
                    city_id: '',
                    offset: 0
                  }))
                }>
                {provinces?.map((province, idx) => (
                  <Select.Option key={idx} value={province?.id}>
                    {province?.name}
                  </Select.Option>
                ))}
              </Select>
              <Select
                showSearch
                allowClear
                placeholder="Semua Cabang"
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                style={{ minWidth: '200px' }}
                onSearch={(e) => {}}
                optionFilterProp="children"
                value={tableState?.city_id === '' ? null : tableState?.city_id}
                onChange={(e) =>
                  setTableState((prev) => ({ ...prev, city_id: e ?? '', offset: 0 }))
                }>
                {cities?.map((city, idx) => (
                  <Select.Option key={idx} value={city?.id}>
                    {city?.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Input
              className="w-1/4 rounded-md"
              placeholder="Pencarian"
              prefix={<IcSearch className="text-gray-400" />}
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
              }
            />
          </div>

          <Table
            columns={columns}
            data={tableState?.listData}
            pending={isLoading}
            totalRows={tableState?.totalRows}
            handlePerRowsChange={handlePerRowsChange}
            pageSize={tableState?.limit}
            page={tableState?.offset}
            onChangePrevPage={() => {
              if (tableState?.offset > 0) {
                onChangePage(tableState?.offset - tableState?.limit)
              }
            }}
            onChangeNextPage={() => {
              if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                onChangePage(tableState?.offset + tableState?.limit)
              }
            }}
          />
        </div>
      </Card>
    </Content>
  )
}

export default CabangComponent
