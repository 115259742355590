import React from 'react'
import { Field } from 'formik'
import { formatCurrency, formatThousandsINTL, unformatCurrency } from 'utils'

const onFormatValue = (value, isWithLabelRp) => {
  if (value !== '' && value >= 0) {
    if (isWithLabelRp) {
      return formatThousandsINTL(value)
    }
    return formatCurrency(value)
  }
  return ''
}

const enumMode = 'normal' || 'dark' || 'disable'

const FormInputRupiah = ({
  label,
  name,
  isRequired,
  isDisable,
  isEmptyField = false,
  isWithLabelRp = false,
  mode = enumMode,
  message,
  value,
  placeholder,
  onChange,
  onPress
}) => {
  const onBgBorderColor = (mode) => {
    switch (mode) {
      case 'normal':
        return 'bg-white border-gray-300 font-normal'
      case 'dark':
        return 'bg-gray-200 border-gray-200 font-semibold'
      case 'disable':
        return 'bg-gray-200 border-gray-300 font-normal'
      default:
        return 'bg-white border-gray-300 font-normal'
    }
  }

  const onLabelRpStyle = (mode) => {
    switch (mode) {
      case 'normal':
        return 'font-normal'
      case 'dark':
        return 'font-semibold'
      case 'disable':
        return 'font-normal'
      default:
        return 'font-normal'
    }
  }

  return (
    <div className="flex flex-col w-full h-full">
      {label && (
        <label htmlFor={name} className="font-medium block mb-2 text-md text-gray-700">
          {label}
          {isRequired && <span className="text-red-600">{' *'}</span>}
        </label>
      )}
      <div className="flex flex-col">
        <div className="relative">
          <div className="relative">
            <Field name={name} id={name}>
              {({ field, form: { touched, errors } }) => (
                <div onClick={onPress ? () => onPress() : () => {}}>
                  <input
                    {...field}
                    disabled={isDisable}
                    placeholder={placeholder ?? ''}
                    value={onFormatValue(value, isWithLabelRp)}
                    onChange={(e) => {
                      if (e.target.value) {
                        onChange(unformatCurrency(e.target.value, isEmptyField))
                      } else {
                        onChange('', isEmptyField)
                      }
                    }}
                    onBlur={(e) => e.target.blur()}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault()
                        e.target.blur()
                      }
                    }}
                    type="text"
                    enterKeyHint="enter"
                    className={`${onBgBorderColor(mode)} ${
                      isDisable ? 'hover:cursor-pointer' : ''
                    } ${
                      isWithLabelRp ? 'pl-9 pr-3' : 'px-3'
                    } w-full text-left  py-3 text-gray-700  placeholder-gray-500  border border-solid rounded-md focus:border-primary-500 focus:ring-primary-500 focus:outline-none focus:ring focus:ring-opacity-20`}
                  />
                </div>
              )}
            </Field>
            {isWithLabelRp ? (
              <div className="flex justify-center items-center absolute inset-y-0 left-0 w-10">
                <span className={`${onLabelRpStyle(mode)} text-gray-700`}>Rp</span>
              </div>
            ) : null}
          </div>
          {isEmptyField && isRequired ? (
            <div className="flex absolute justify-start items-start px-1 py-2 bg-transparent font-semibold text-xs text-red-500">
              {`${message ? `*${message}` : `*Silahkan Masukkan ${label ?? name}`}`}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default FormInputRupiah
