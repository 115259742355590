export const dayPropGetter = (date) => ({
  style: {
    backgroundColor: '#F9FAFB',
    color: '#101828'
  }
})

export const eventPropGetter = (event, start, end, isSelected) => ({
  style: {
    backgroundColor: '#E12B25',
    color: '#FFFFFF',
    borderColor: '#E12B25',
    opacity: 0.9,
    display: 'block'
  }
})
export const eventPropGetterInspection = (event, start, end, isSelected) => ({
  style: {
    backgroundColor: '#fffaeb',
    color: '#b54708',
    borderColor: '#fffaeb',
    opacity: 0.9,
    display: 'block'
  }
})

export const eventPropGetterTestDrive = (event, start, end, isSelected) => ({
  style: {
    backgroundColor: '#eff8ff',
    color: '#175cd3',
    borderColor: '#eff8ff',
    opacity: 0.9,
    display: 'block'
  }
})
