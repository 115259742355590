import * as actionTypes from "../actionTypes";

const initialState = {
    form: {
        unit_id: null,
        user_id: null,
        unit_name: '',
        unit_police_number: '',
        workshop_id: null,
        workshop_name: '',
        schedule_id: null,
        stall: null,
        inspection_date: null,
        owner_name: '',
        owner_email: '',
        owner_phone_number: '',
        owner_address: ''
    },
    workshop: {}
}

const inspeksiReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.SET_INSPEKSI_STEP1: {
            return {
                ...state,
                form: {
                    ...state.form,
                    user_id: action.user_id,
                    unit_id: action.unit_id,
                    unit_name: action.unit_name,
                    unit_police_number: action.unit_police_number,
                    owner_name: action.owner_name,
                    owner_email: action.owner_email,
                    owner_phone_number: action.owner_phone_number,
                    owner_address: action.owner_address
                }
            }
        }
        case actionTypes.SET_INSPEKSI_STEP2: {
            return {
                ...state,
                form: {
                    ...state.form,
                    workshop_id: action.workshop_id,
                    workshop_name: action.workshop_name,
                    schedule_id: action.schedule_id,
                    schedule_name: action.schedule_name,
                    stall: action.stall,
                    inspection_date: action.inspection_date,
                },
                workshop: action.workshop
            }
        }
        case actionTypes.RESET_INSPEKSI: {
            return {
                ...initialState
            }
        }
        default: {
            return state;
        }
    }
}

export default inspeksiReducer;