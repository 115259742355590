import React from 'react'
import { Breadcrumb, Layout } from 'antd'
import DetailServisComponent from './components/DetailServisComponent'
import { useParams } from 'react-router-dom'
import { apiGet } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { useQuery } from 'react-query'

const DetailServis = (props) => {
  const params = useParams()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const { isLoading, data } = useQuery({
    queryKey: ['servis-detail', params?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = null
      let body = {
        is_show_relation: 'true',
        is_show_progress: 'true',
        is_show_recommendation_service: 'true',
        is_show_history: 'true'
      }
      if (params?.id) {
        res = await apiGet({
          data: body,
          token,
          url: `servis-service/admin/bookings/${params?.id}`,
          onLogout: () => dispatch(logout())
        })
      }
      return res
    }
  })

  return (
    <Layout className="h-full" style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => props.history.go(-2)}
        >
          Internal Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => props.history.go(-1)}
        >
          {data?.resData?.data?.unit_information?.unit_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item>Detail Servis</Breadcrumb.Item>
      </Breadcrumb>
      <DetailServisComponent 
        data={data?.resData?.data}
      />
    </Layout>
  )
}

export default DetailServis
