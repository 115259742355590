import React from 'react'
import { Collapse } from 'antd'
import { CardContainer } from 'components'
import { IcChevronDown } from 'assets/icons'

const RuangMesinSection = () => {
  const fieldPanelStyle = 'font-medium text-gray-500'
  const panelBorderBottom = 'flex border border-solid border-b-1 border-x-0 border-t-0 border-b-gray-300 my-3 mx-4'

  return (
    <CardContainer
      minHeight="20%"
      px="px-2"
    >
      <div
        className="flex border border-solid border-b-1 border-x-0 border-t-0 border-b-gray-300 pb-4 mx-4"
      >
        <span className="font-semibold text-2xl text-gray-900">
          Ruang Mesin
        </span>
      </div>
      <Collapse
        className="inspeksi-detail-interior pt-2"
        defaultActiveKey={[]}
        ghost
        expandIconPosition="end"
        expandIcon={(collapse) => {
          const className = collapse.isActive ? '-rotate-180' : ''
          return (
            <div>
              <IcChevronDown className={`text-gray-900 ${className}`} size={18} />
            </div>
          )
        }}
      >
        <Collapse.Panel
          header="A. Kelistrikan Area Ruang Mesin"
          key="ruang-mesin-0"
          className="inspeksi-collapse-header font-medium text-md"
        >
          <div
            className="flex"
          >
            <span
              className={fieldPanelStyle}
            >
              Lom tau
            </span>
          </div>
        </Collapse.Panel>
        <div
          className={panelBorderBottom}
        />
        <Collapse.Panel
          header="B. Engine Area Ruang Mesin"
          key="ruang-mesin-1"
          className="inspeksi-collapse-header font-medium text-md"
        >
          <div
            className="flex"
          >
            <span
              className={fieldPanelStyle}
            >
              Lom tau
            </span>
          </div>
        </Collapse.Panel>
      </Collapse>
    </CardContainer>
  )
}

export default RuangMesinSection
