import { Checkbox, Input, Modal, Radio, Select, Typography } from 'antd'
import { apiGet, urls } from 'api'
import { IcSearch } from 'assets/icons'
import { Table } from 'components'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { formatCurrency } from 'utils'

const ModalSparepart = ({
  isOpen,
  onCancel,
  type,
  formik,
  selected,
  setSelected,
  listData,
  setListData,
  valueSparepart,
  valueSparepartDefault
}) => {
  const token = useSelector((state) => state.auth.token)
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('')

  // Filter  By Category
  const filterDataCategories = useMemo(() => {
    return category === ''
      ? listData?.sparepart
      : listData?.sparepart?.filter((f) => f?.sparepart_category_id === category)
  }, [listData?.sparepart, category])

  const handleChangeCheckAll = (e) => {
    if (e?.target?.checked) {
      setSelected(e?.target?.value)
    } else {
      setSelected([])
    }
  }

  const handleChangeCheck = (e) => {
    if (e?.target?.checked) {
      setSelected((prev) => [...prev, e?.target?.value])
    } else {
      setSelected((prev) => prev?.filter?.((f) => f !== e?.target?.value))
    }
  }

  const handleChangeRadio = (value) => {
    setSelected(value)
  }

  const columnsSparepart = [
    {
      name: (
        <Checkbox
          id={`checkbox-all`}
          name="item-all"
          className="custom-checkbox"
          value={filterDataCategories?.map((item) => item?.id)}
          onChange={handleChangeCheckAll}
          checked={
            selected?.length !== 0 && selected?.length === filterDataCategories?.length
              ? true
              : false
          }
        />
      ),
      width: '50px',
      cell: (row) => (
        <Checkbox
          id={`checkbox-${row?.id}`}
          name="item"
          className="custom-checkbox"
          value={row?.id}
          onChange={(e) => handleChangeCheck(e)}
          checked={selected?.some?.((s) => s === row?.id)}
        />
      )
    },
    {
      name: 'Sparepart',
      selector: (row) => row?.name,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <Typography.Text className="font-medium text-gray-500 text-[11px] py-1">
            {row?.sku}
          </Typography.Text>
          <Typography.Text className="font-medium">{row?.name}</Typography.Text>
        </div>
      )
    },
    {
      name: 'Harga Layanan',
      selector: (row) => (row?.price ? formatCurrency(row?.price ?? 0) : '-')
    }
  ]

  const columnsSparepartDefault = [
    {
      name: '',
      width: '50px',
      cell: (row) => (
        <Radio
          id={`radio-${row?.id}`}
          name="item"
          className="custom-radio-btn"
          value={row?.id}
          onChange={(e) => handleChangeRadio(e?.target?.value)}
          checked={selected === row?.id ? true : false}
        />
      )
    },
    {
      name: 'Sparepart',
      selector: (row) => row?.name,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <Typography.Text className="font-medium text-gray-500 text-[11px] py-1">
            {row?.sku}
          </Typography.Text>
          <Typography.Text className="font-medium">{row?.name}</Typography.Text>
        </div>
      )
    },
    {
      name: 'Harga Layanan',
      selector: (row) => (row?.price ? formatCurrency(row?.price ?? 0) : '-')
    }
  ]

  // Get sparepart categories
  useQuery({
    queryKey: ['sparepart-categories-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepartCategories?.all}?keywords=${search}`
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({ ...prev, sparepartCategories: res?.resData?.data ?? [] }))
    }
  })

  // Get sparepart by search
  useQuery({
    queryKey: ['sparepart-list', search],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepart?.all}?keywords=${search}`
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({ ...prev, sparepart: res?.resData?.data ?? [] }))
    }
  })

  const handleSubmit = () => {
    if (type === 'default') {
      formik?.setFieldValue('default_sparepart_id', selected)
    } else {
      formik?.setFieldValue(
        'sparepart_ids',
        listData?.sparepartAll
          ?.filter((f) => selected?.find((fd) => fd === f?.id) === f?.id)
          ?.map((m) => m?.id)
      )
      if (!selected?.includes(valueSparepartDefault)) {
        formik?.setFieldValue('default_sparepart_id', '')
      }
    }
    setSearch('')
    setCategory('')
    setSelected('')
  }

  return (
    <Modal
      width={400}
      title={type === 'default' ? 'Pilih Sparepart Default' : 'Attach Item/Sparepart'}
      open={isOpen}
      onCancel={() => {
        onCancel()
        setSearch('')
        setCategory('')
        setSelected('')
      }}
      onOk={() => {
        onCancel()
        handleSubmit()
      }}
      okText={'Confirm'}
      okButtonProps={{ className: 'bg-primary-500 rounded-md', type: 'danger' }}
      cancelButtonProps={{
        className: 'hover:text-[#000] rounded-md hover:border-[#000]',
        type: 'default'
      }}>
      <div className="bg-gray-50 p-2 rounded-lg">
        <Input
          className="rounded-md py-1.5 mb-1"
          placeholder="Cari Layanan"
          prefix={<IcSearch className="text-gray-400" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          className="custom-table-limit"
          style={{ width: '100%' }}
          value={category}
          onChange={(e) => setCategory(e)}
          placeholder="Kategori Sparepart">
          <Select.Option value="">Semua Kategori Sparepart</Select.Option>
          {listData?.sparepartCategories?.map((category, idx) => (
            <Select.Option key={idx} value={category?.id}>
              {category?.name}
            </Select.Option>
          ))}
        </Select>
        <div className="overflow-y-auto h-40 mt-2">
          <Table
            columns={type === 'default' ? columnsSparepartDefault : columnsSparepart}
            data={
              type === 'default'
                ? filterDataCategories?.filter(
                    (fl) => valueSparepart?.find?.((fd) => fd === fl?.id) === fl?.id
                  )
                : filterDataCategories
            }
            hidePagination={true}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalSparepart
