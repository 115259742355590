import React from 'react'

const IcShoppingBag = ({ className, size = 20 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 4.99996L5 1.66663H15L17.5 4.99996M2.5 4.99996V16.6666C2.5 17.1087 2.67559 17.5326 2.98816 17.8451C3.30072 18.1577 3.72464 18.3333 4.16667 18.3333H15.8333C16.2754 18.3333 16.6993 18.1577 17.0118 17.8451C17.3244 17.5326 17.5 17.1087 17.5 16.6666V4.99996M2.5 4.99996H17.5M13.3333 8.33329C13.3333 9.21735 12.9821 10.0652 12.357 10.6903C11.7319 11.3154 10.8841 11.6666 10 11.6666C9.11594 11.6666 8.2681 11.3154 7.64298 10.6903C7.01786 10.0652 6.66667 9.21735 6.66667 8.33329"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IcShoppingBag
