import { Checkbox } from 'antd'
import { IcCheckRound } from 'assets/icons'
import { CustomButton } from 'components'
import CardContainer from 'components/CardContainer'
import Loading from 'components/Loading'

const ServiceProgressCard = ({
  data,
  handleModalServiceSelesai,
  onSubmit,
  isLoading,
  disabled = false,
  statusService
}) => {
  const handleCheckbox = (e, item) => {
    const body = {
      is_finished: e?.target?.checked
    }
    onSubmit(item, body)
  }

  return (
    <CardContainer isHeightFull width="90%">
      <div className="flex justify-between items-center w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Progress Servis</span>
        {statusService !== 'SERVICE_FINISHED' ? (
          <CustomButton onClick={handleModalServiceSelesai} type="success">
            <div className="flex justify-center items-center gap-2">
              <IcCheckRound className={'w-5 h-5 text-white'} />
              <span>Servis Selesai</span>
            </div>
          </CustomButton>
        ) : null}
      </div>
      <div className="flex flex-col space-y-5 mt-4 relative">
        {data?.map((item, idx) => (
          <div key={idx} className="flex flex-row">
            <Checkbox
              className="custom-checkbox"
              checked={item?.is_finished}
              onChange={(e) => handleCheckbox(e, item)}
              disabled={disabled}
            />
            <div className="flex flex-col space-y-1 ml-3 w-full">
              {item?.packet_name && (
                <span className="font-medium text-gray-800 text-md">{item?.packet_name}</span>
              )}
              <span className="font-medium text-gray-700 text-md">{item?.layanan}</span>
              <div className="grid grid-cols-4 w-full">
                <span className="col-span-3 font-normal text-gray-500 text-sm">
                  {item?.sparepart_name}
                </span>
                <span className="col-span-1 font-normal text-gray-500 text-sm text-right">
                  {item?.quantity ? `${item?.quantity}x` : ''}
                </span>
              </div>
            </div>
          </div>
        ))}
        {isLoading ? <Loading visible display="absolute" classNameWidth="" /> : null}
      </div>
    </CardContainer>
  )
}

export default ServiceProgressCard
