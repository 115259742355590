import { Card, Typography } from 'antd'
import { IcPlus, IcSync } from 'assets/icons'
import { CustomButton, Loading, Table } from 'components'
import React from 'react'
import ModalUpdateUnitList from './components/ModalUpdateUnitList'
import useUnitList from './hooks/useUnitList'

const UnitListCard = ({ data }) => {
  const {
    modalOption,
    dataColumns,
    columns,
    syncLoading,
    getUnitListLoading,
    updateLoading,
    getSyncUnitList,
    closeModal,
    openModal,
    deleteUnit,
    submitUnit
  } = useUnitList(data)
  return (
    <>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">List Unit</Typography.Text>
          <div className="flex gap-2 items-center justify-center">
            {syncLoading ? (
              <Loading classNameWidth="" visible />
            ) : (
              <CustomButton type="plain" onClick={getSyncUnitList}>
                <div className="flex justify-center items-center gap-2">
                  <IcSync className="stroke-gray-900" size={17} />
                  Sync
                </div>
              </CustomButton>
            )}
            <CustomButton onClick={() => openModal('tambah', null)}>
              <div className="flex items-center justify-center gap-2">
                <IcPlus className="text-white" size={19} />
                Tambah Unit
              </div>
            </CustomButton>
          </div>
        </div>

        <Table
          columns={columns}
          data={dataColumns}
          pagination={false}
          hidePagination={true}
          pending={getUnitListLoading}
        />
      </Card>
      {modalOption.isOpen ? (
        <ModalUpdateUnitList
          updateLoading={updateLoading}
          isOpen={modalOption.isOpen}
          typeButton={modalOption.typeButton}
          onCancel={closeModal}
          onDelete={deleteUnit}
          onSubmit={submitUnit}
          data={modalOption.data}
        />
      ) : null}
    </>
  )
}

export default UnitListCard
