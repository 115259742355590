import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Input, Tag, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button } from 'components'

const UpdateKategoriInspeksiComponent = ({ data, type, onSubmit }) => {
  const formatSubKategory = (data) => {
    if (!data) {
      return {}
    }
    let result = {}
    data?.map((item) => {
      result = {
        ...result,
        [item?.id]: item
      }
    })
    return result
  }
  const router = useHistory()
  const [kategori, setKategori] = useState(data?.name ?? '')
  const [subKategori, setSubKategori] = useState(
    formatSubKategory(data?.inspectionSubCategories ?? null)
  )
  const [inputSubKategori, setInputSubKategori] = useState('')

  const onDeleteSubKategori = (item) => {
    let newSubKategori = subKategori
    delete newSubKategori[item?.id]
    setSubKategori(newSubKategori)
  }

  const onSave = async () => {
    let newSubKategori = []
    Object.keys(subKategori)?.map((item) => {
      newSubKategori.push({
        sub_category_names: subKategori[item].name,
        sub_category_ids: subKategori[item]?.id ?? 0
      })
    })
    onSubmit({
      ...data,
      name: kategori,
      sub_categories: newSubKategori
    })
  }

  const idx = useRef(0)

  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-lg">
            {type === 'add' ? 'Tambah Kategori Inspeksi' : 'Edit Kategori Inspeksi'}
          </Typography.Text>
        </div>
        <div className="w-[400px]">
          <Typography.Text className="font-medium">
            Kategori<span className="text-red-500">*</span>
          </Typography.Text>
          <Input
            className="rounded-md mt-1 mb-4 py-2"
            placeholder="Kategori"
            value={kategori}
            onChange={(e) => setKategori(e.target.value)}
          />
        </div>
        <Typography.Text className="font-medium block mb-2">
          Sub-Kategori<span className="text-red-500">*</span>
        </Typography.Text>
        {Object.values(subKategori)?.length > 0 && (
          <div
            className={`flex w-[500px] flex-wrap ${
              Object.values(subKategori)?.length <= 0 && 'mb-0 mt-0 h-0'
            }`}>
            {Object.values(subKategori)?.map((item, index) => (
              <Tag
                key={`subkategori-${index.toString()}`}
                className="py-[2px] border-0 bg-[#FEECE2] rounded-lg text-[#A21226] mb-2 selection:text-black"
                onClose={() => onDeleteSubKategori(item)}
                closable>
                {item?.name}
              </Tag>
            ))}
          </div>
        )}
        <Input
          className="rounded-md mb-3 w-[200px] py-2"
          placeholder="Tambahkan Sub-Kategori"
          value={inputSubKategori}
          onChange={(e) => setInputSubKategori(e.target.value)}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              idx.current++
              setInputSubKategori('')
              setSubKategori({
                ...subKategori,
                ['add-' + idx.current]: {
                  name: e.target.value
                }
              })
            }
          }}
        />
      </Card>
      <div className="flex float-right items-center mb-1">
        <Button
          className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => router.go(-1)}>
          Batal
        </Button>
        <Button
          className="p-0 px-4 py-2 bg-primary-500 border font-medium text-white hover:opacity-90"
          onClick={onSave}>
          Simpan
        </Button>
      </div>
    </Content>
  )
}

export default UpdateKategoriInspeksiComponent
