import { Card, Input, Typography } from 'antd'
import { IcSearch } from 'assets/icons'
import { Table } from 'components'

const HistoryUnitUserBookingCard = ({ columns, data, isLoading, tableState, setTableState }) => {
  const rowsStyles = {
    '&:first-child': {
      background: '#FEECE2 '
    }
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <Card className="rounded-lg">
      <div className="mb-5">
        <Typography.Text className="font-semibold text-2xl">
          Daftar Riwayat User Booking
        </Typography.Text>
      </div>

      <div className="flex justify-start mb-5">
        <Input
          className="w-1/4 rounded-md py-2"
          placeholder="Pencarian"
          prefix={<IcSearch className="text-gray-400" />}
          value={tableState?.keywords}
          onChange={(e) =>
            setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
          }
        />
      </div>

      <Table
        columns={columns}
        data={data}
        rowsStyles={rowsStyles}
        pending={isLoading}
        totalRows={tableState?.totalRows}
        handlePerRowsChange={handlePerRowsChange}
        pageSize={tableState?.limit}
        page={tableState?.offset}
        onChangePrevPage={() => {
          if (tableState?.offset > 0) {
            onChangePage(tableState?.offset - tableState?.limit)
          }
        }}
        onChangeNextPage={() => {
          if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
            onChangePage(tableState?.offset + tableState?.limit)
          }
        }}
      />
    </Card>
  )
}

export default HistoryUnitUserBookingCard
