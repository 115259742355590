import React, { useState } from 'react'
import { BaseIcon, CustomButton, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'
import { KategoriItem } from '..'

const ModalPilihTipeKategori = ({ onClose, onSubmit, onSearch, data, onSelected }) => {
  const [searchCategory, setSearchCategory] = useState('')

  return (
    <ModalContainer zIndex="z-40">
      <ModalForm
        zIndex="z-50"
        overflowY="overflow-y-hidden"
        minWidth="min-w-[22%]"
        maxWidth="max-w-[22%]"
        height="h-[63%]">
        <div className="flex flex-col h-full w-full space-y-5 pb-5">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">Attach Layanan Servis</span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div className="flex flex-col max-h-[78%] min-h-[78%] space-y-5 bg-gray-100 rounded-lg p-2">
            <Formik
              initialValues={{
                search: searchCategory
              }}>
              <Form>
                <FormInput
                  name="search"
                  placeholder="Cari Layanan"
                  type="text"
                  isIconLeft
                  iconName="search"
                  onValidate={(value) => {
                    setSearchCategory(value)
                    onSearch(value)
                  }}
                />
              </Form>
            </Formik>
            {data.length === 0 ? (
              <div className="flex flex-col h-full justify-center">
                <span className="font-medium text-md text-gray-500 text-center">
                  Data Kategori Tidak Ditemukan
                </span>
              </div>
            ) : (
              <div
                key="kategori-content-scrollbar"
                className="flex flex-col space-y-2 h-full overflow-y-auto">
                {data.map((item, index) => (
                  <KategoriItem
                    key={index}
                    title={item.name}
                    isSelected={item.isSelected}
                    onSelected={() => onSelected(item.id)}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-row w-full items-end justify-end space-x-4">
            <CustomButton type="plain" onClick={() => onClose()}>
              Cancel
            </CustomButton>
            <CustomButton onClick={() => onSubmit()}>Confirm</CustomButton>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalPilihTipeKategori
