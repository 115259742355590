import React, { useState } from 'react'
import { Modal, Select, Switch, Typography } from 'antd'
import { validationInput } from 'helpers'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'

const initialForms = {
  brand: {
    value: null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  model: {
    value: null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  type: {
    value: null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  cyoc: false
}

const ModalUpdateCYOCUnit = ({ typeButton, isOpen, onCancel, data, onSubmit, onDelete }) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [forms, setForms] = useState(initialForms)

  const onChangeInput = (value, identifier) => {
    if (forms[identifier]?.validation) {
      let { valid, messageError } = validationInput(value, forms[identifier]?.validation)
      setForms((prevState) => {
        return {
          ...prevState,
          [identifier]: {
            ...prevState[identifier],
            touched: true,
            value: value,
            valid: valid,
            messageError: messageError
          }
        }
      })
    } else {
      setForms((prevState) => {
        return {
          ...prevState,
          [identifier]: value
        }
      })
    }
  }

  const brandList = useQuery({
    queryKey: ['cyoc-brand-unit'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (data?.id) {
        onChangeInput(data?.brand_id, 'brand')
        onChangeInput(data?.model_id, 'model')
        onChangeInput(data?.type_id, 'type')
        onChangeInput(data?.create_your_own_car, 'cyoc')
      } else {
        setForms(initialForms)
      }
      const res = await apiGet({
        token,
        url: `master-service/admin/brands/all`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: isOpen
  })

  const modelList = useQuery({
    queryKey: ['cyoc-model-unit', forms.brand.value],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let params = {
        brand_id: forms.brand.value
      }
      if (forms.brand.value) {
        const res = await apiGet({
          token,
          data: params,
          url: `master-service/admin/models/all`,
          onLogout: () => dispatch(logout())
        })
        return res
      }
    },
    enabled: isOpen && !brandList.isLoading
  })

  const typeList = useQuery({
    queryKey: ['cyoc-type-unit', forms.model.value, forms.brand.value],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let params = {
        brand_id: forms.brand.value,
        model_id: forms.model.value
      }
      if (forms.model.value && forms.brand.value) {
        const res = await apiGet({
          token,
          data: params,
          url: `master-service/admin/types/all`,
          onLogout: () => dispatch(logout())
        })
        return res
      }
    },
    enabled: isOpen && !brandList.isLoading && !modelList.isLoading
  })

  const onOk = async () => {
    let brand = brandList?.data?.resData?.data?.findIndex((item) => item.id === forms.brand.value)
    let model = modelList?.data?.resData?.data?.findIndex((item) => item.id === forms.model.value)
    let type = typeList?.data?.resData?.data?.findIndex((item) => item.id === forms.type.value)
    if (typeButton === 'add') {
      onSubmit({
        brand_id: forms.brand.value,
        brand_name: brandList?.data?.resData?.data[brand]?.name,
        model_id: forms.model.value,
        model_name: modelList?.data?.resData?.data[model]?.name,
        type_id: forms.type.value,
        type_name: typeList?.data?.resData?.data[type]?.name,
        create_your_own_car: forms.cyoc
      })
    } else {
      onSubmit({
        id: data?.id,
        brand_id: forms.brand.value,
        brand_name: brandList?.data?.resData?.data[brand]?.name,
        model_id: forms.model.value,
        model_name: modelList?.data?.resData?.data[model]?.name,
        type_id: forms.type.value,
        type_name: typeList?.data?.resData?.data[type]?.name,
        create_your_own_car: forms.cyoc
      })
    }
  }

  if (typeButton === 'delete') {
    return (
      <Modal
        title="Hapus Unit"
        open={isOpen}
        onOk={() => onDelete(data)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
        maskClosable={false}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus Unit ${data?.brand_name} ${data?.model_name} ${data?.type_name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      title={typeButton === 'add' ? 'Tambah Unit' : 'Edit Unit'}
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      width={450}
      okText={typeButton === 'add' ? 'Tambah Unit' : 'Edit Unit'}
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
      maskClosable={false}>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Brand
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Select
          className="custom-table-limit mr-3"
          placeholder="Pilih Brand"
          style={{ width: '100%' }}
          value={forms.brand.value}
          onChange={(e) => {
            if (e !== forms.brand.value) {
              onChangeInput(e, 'brand')
              onChangeInput(null, 'model')
              onChangeInput(null, 'type')
            }
          }}
          showSearch
          filterOption={(input, option) => {
            return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }}>
          {brandList?.data?.resData?.data?.map((item) => (
            <Select.Option key={`b-${item?.id?.toString()}`} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
        {!forms.brand.valid && forms.brand.touched && (
          <span className="text-[red]">{forms.brand.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Model
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Select
          className="custom-table-limit mr-3"
          placeholder="Pilih Model"
          style={{ width: '100%' }}
          value={forms.model.value}
          onChange={(e) => {
            if (e !== forms.model.value) {
              onChangeInput(e, 'model')
              onChangeInput(null, 'type')
            }
          }}
          showSearch
          filterOption={(input, option) => {
            return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }}>
          {modelList?.data?.resData?.data?.map((item) => (
            <Select.Option key={`b-${item?.id?.toString()}`} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
        {!forms.model.valid && forms.model.touched && (
          <span className="text-[red]">{forms.model.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Type
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Select
          className="custom-table-limit mr-3"
          placeholder="Pilih Model"
          style={{ width: '100%' }}
          value={forms.type.value}
          onChange={(e) => onChangeInput(e, 'type')}
          showSearch
          filterOption={(input, option) => {
            return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }}>
          {typeList?.data?.resData?.data?.map((item) => (
            <Select.Option key={item?.id?.toString()} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
        {!forms.type.valid && forms.type.touched && (
          <span className="text-[red]">{forms.type.messageError}</span>
        )}
      </div>
      <div className="flex flex-col space-y-1">
        <span className="font-medium text-gray-700">Create Your Own Car</span>
        <div className="max-w-md">
          <Switch
            className={`${
              forms.cyoc ? 'bg-primary-500 border-primary-500' : 'bg-gray-200 border-gray-200'
            }`}
            checked={forms.cyoc}
            onChange={(checked, e) => onChangeInput(checked, 'cyoc')}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalUpdateCYOCUnit
