import { Breadcrumb, Input, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { IcSearch } from 'assets/icons'
import { Button, CardContainer, Loading, Table } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { formatCurrency } from 'utils'

const initialTableState = {
  totalRows: 0,
  keywords: '',
  offset: 0,
  limit: 10,
  listData: [],
  general_service_id: ''
}

const parsingTime = (time) => {
  if (time < 60) {
    return `${time} menit`
  } else {
    return `${Math.floor(time / 60)} jam ${time % 60 !== 0 ? `${time % 60} menit` : ''}`
  }
}

const HargaServicePage = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [dropdownLayanan, setDropdownLayanan] = useState([])
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const columns = [
    {
      name: 'Nama Servis',
      selector: (row) => row?.name,
      cell: (row) => row?.name,
      width: '25%'
    },
    {
      name: 'Kelas',
      selector: (row) => row?.class_name,
      cell: (row) => row?.class_name,
      width: '18%'
    },
    {
      name: 'Durasi',
      selector: (row) => parsingTime(row?.duration ?? 0),
      cell: (row) => parsingTime(row?.duration ?? 0),
      width: '18%'
    },
    {
      name: (
        <div className="grid grid-cols-2 gap-4 w-full ">
          <div className="">Sparepart</div>
          <div className="">Harga</div>
        </div>
      ),
      cell: (row) => (
        <div className="py-3 w-full">
          {row?.spareparts?.map((data, index) => (
            <div key={`${row?.id}-${index}-S`} className="grid grid-cols-2 gap-4 items-center">
              <p className="py-2 px-2 w-fit text-xs-medium relative text-primary-500-700 border-0 bg-[#FEECE2] rounded-lg text-[#A21226] selection:text-black">
                {data?.name}
              </p>

              <p className="py-2">{formatCurrency(data?.price_total ?? 0)}</p>
            </div>
          ))}
        </div>
      ),
      minWidth: '160px'
    }
  ]

  // Get API
  // Get All List price
  const { isLoading } = useQuery({
    queryKey: ['price-list', tableState.keywords, tableState.general_service_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.priceList}?keywords=${tableState?.keywords}&general_service_id=${tableState?.general_service_id}&limit=${tableState?.limit}&offset=${tableState?.offset}&sort_by=name&order_by=asc`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data
          ? res?.resData?.data?.map((data) => ({
              key: `${data?.id}-${data?.class_name}`,
              ...data
            }))
          : [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  const getMoreList = async (newOffset) => {
    setIsLoadingCRUD(true)
    setTableState((prev) => ({ ...prev, offset: newOffset }))
    const res = await apiGet({
      token,
      url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.priceList}?keywords=${tableState?.keywords}&general_service_id=${tableState?.general_service_id}&limit=${tableState?.limit}&offset=${newOffset}&sort_by=name&order_by=asc`,
      onLogout
    })

    if (res.resStatus === 200) {
      const temp =
        res?.resData?.data?.map((data) => ({
          key: `${data?.id}-${data?.class_name}`,
          ...data
        })) ?? []

      setTableState((prev) => ({
        ...prev,
        listData: [...prev.listData, ...temp],
        totalRows: res?.resData?.meta?.total
      }))
    }
    setIsLoadingCRUD(false)
  }

  // Get Provinces
  useQuery({
    queryKey: ['province-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDropdownLayanan(res?.resData?.data ?? [])
    }
  })

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">Servis</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Harga Servis
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <div className="mb-5 flex justify-between">
          <div className="flex">
            <Select
              className="custom-table-limit mr-3"
              style={{ minWidth: '150px' }}
              value={tableState?.general_service_id ?? ''}
              onChange={(e) =>
                setTableState((prev) => ({
                  ...prev,
                  general_service_id: e ?? '',
                  offset: 0
                }))
              }
              showSearch
              allowClear
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Layanan Servis
              </Select.Option>
              {dropdownLayanan?.map((layanan, idx) => (
                <Select.Option key={`d-${idx}`} value={layanan?.id}>
                  {layanan?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Input
            className="w-1/4 rounded-md"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) =>
              setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
            }
          />
        </div>
        <CardContainer>
          <div className="flex flex-col w-full space-y-5">
            <div className="flex flex-col space-y-5">
              <Table
                keyField="key"
                columns={columns}
                data={tableState?.listData}
                pending={isLoading}
                totalRows={tableState?.totalRows}
                handlePerRowsChange={handlePerRowsChange}
                pageSize={tableState?.limit}
                page={tableState?.offset}
                onChangePrevPage={() => {
                  if (tableState?.offset > 0) {
                    onChangePage(tableState?.offset - tableState?.limit)
                  }
                }}
                onChangeNextPage={() => {
                  if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                    onChangePage(tableState?.offset + tableState?.limit)
                  }
                }}
                hidePagination={true}
              />
              {tableState?.totalRows > tableState?.limit + tableState?.offset ? (
                <div className="py-2">
                  {isLoadingCRUD ? (
                    <Loading visible title="" classNameWidth="" />
                  ) : (
                    <Button
                      className="p-0 px-4 py-2 w-full bg-primary-500 border border-primary-500 font-medium text-white hover:opacity-90"
                      onClick={() => getMoreList(tableState?.offset + tableState?.limit)}
                      disabled={
                        tableState?.totalRows > tableState?.limit + tableState?.offset
                          ? false
                          : true
                      }>
                      Tampilkan Lebih Banyak
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default HargaServicePage
