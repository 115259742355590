import React from 'react'
import CardContainer from 'components/CardContainer'
import { CustomButton } from 'components'
import { IcDownload } from 'assets/icons'

const HeaderSO = ({ onPrintSO }) => (
  <CardContainer>
    <div className="grid grid-flow-col items-center justify-between">
      <span className="font-semibold text-2xl text-gray-900">Sales Order</span>
      <CustomButton type="plain" onClick={onPrintSO}>
        <div className="flex flex-row items-center justify-center space-x-2">
          <IcDownload className="text-gray-700" />
          <span className="font-medium text-gray-700">Print SO</span>
        </div>
      </CustomButton>
    </div>
  </CardContainer>
)

export default HeaderSO
