import React from 'react'

const IcInvoice = ({ className, size = 20 }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.25016 5.24967H5.2585M16.5752 10.5913L10.6002 16.5663C10.4454 16.7213 10.2616 16.8442 10.0592 16.9281C9.8569 17.012 9.64002 17.0552 9.421 17.0552C9.20197 17.0552 8.98509 17.012 8.78276 16.9281C8.58043 16.8442 8.39662 16.7213 8.24183 16.5663L1.0835 9.41634V1.08301H9.41683L16.5752 8.24134C16.8856 8.55361 17.0598 8.97603 17.0598 9.41634C17.0598 9.85665 16.8856 10.2791 16.5752 10.5913Z" stroke="#667085" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default IcInvoice
