import { Input, Typography } from 'antd'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { IcEdit, IcPlus, IcSearch, IcTrash } from 'assets/icons'
import { Button, CardContainer, ModalCreateUpdateUser, ModalDeleteUser, Table } from 'components'
import { errorsField } from 'helpers'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { showErrorMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const DataSecurityContent = () => {
  const { cabang: idCabang } = useParams()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [modalUpdate, setModalUpdate] = useState({
    show: false,
    type: 'add',
    user: null
  })
  const [modalDelete, setModalDelete] = useState({
    show: false,
    user: null
  })

  const columns = [
    {
      name: 'ID',
      selector: (row) => row?.profile?.security_no,
      cell: (row) => (
        <Typography.Text
          ellipsis={{
            tooltip: row?.profile?.security_no
          }}>
          {row?.profile?.security_no}
        </Typography.Text>
      ),
      width: '10%'
    },
    {
      name: 'Nama',
      selector: (row) => row?.fullname,
      cell: (row) => row?.fullname
    },
    {
      name: 'Nomor Telepon',
      selector: (row) => row?.phone_number,
      cell: (row) => row?.phone_number
    },
    {
      name: 'Email',
      selector: (row) => row?.email,
      cell: (row) => row?.email
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => setModalUpdate({ show: true, type: 'edit', user: row })}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModalDelete({ show: true, user: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  // Get All security list
  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: [
      'security-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.auth?.user?.prefix}/${urls?.auth?.user?.securityByCabang}/${idCabang}?keywords=${tableState.keywords}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Table State
  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  const handleSubmitCreateUpdateUser = async (body, setErrors, resetForm) => {
    try {
      setIsLoadingCRUD(true)
      let res
      if (modalUpdate?.type === 'add') {
        res = await apiPost({
          url: `${urls?.auth?.user?.prefix}/${urls?.auth?.user?.security}`,
          data: body,
          token: token,
          onLogout
        })
      } else {
        res = await apiPut({
          url: `${urls?.auth?.user?.prefix}/${urls?.auth?.user?.security}/${modalUpdate?.user?.id}`,
          data: body,
          token: token,
          onLogout
        })
      }

      if (res?.resStatus === 200) {
        resetForm()
        setModalUpdate((prev) => ({ ...prev, show: false, user: {} }))
        refetch()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        setErrors(errorsField(res?.resData?.errors))
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteCreateUpdateUser = async () => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiDelete({
        url: `${urls?.auth?.user?.prefix}/${urls?.auth?.user?.security}/${modalDelete?.user?.id}`,
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        setModalDelete((prev) => ({ ...prev, show: false, user: {} }))
        refetch()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ModalCreateUpdateUser
        title="Security"
        isOpen={modalUpdate.show}
        type={modalUpdate.type}
        user={modalUpdate.user}
        onCancel={() => setModalUpdate((prev) => ({ ...prev, show: false, user: {} }))}
        idWorkshop={idCabang}
        onSubmit={handleSubmitCreateUpdateUser}
        isLoading={isLoadingCRUD}
      />
      <ModalDeleteUser
        title="Security"
        isOpen={modalDelete.show}
        type={modalDelete.type}
        user={modalDelete.user}
        onCancel={() => setModalDelete((prev) => ({ ...prev, show: false }))}
        onSubmit={handleDeleteCreateUpdateUser}
        isLoading={isLoadingCRUD}
      />
      {/* Table Security List */}
      <CardContainer className={'grid grid-cols-1 gap-5'}>
        <div className="flex justify-between items-center">
          <span className="display-xs-semibold text-gray-900">Security</span>
          <button
            className="flex flex-row py-2 items-center justify-around rounded-md border-solid border-primary-500 focus:outline-none space-x-1 px-2 py text-md text-white bg-primary-500 hover:cursor-pointer"
            onClick={() => setModalUpdate({ type: 'add', show: true, user: null })}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Security</span>
          </button>
        </div>
        <div className="flex justify-start">
          <Input
            className="w-1/4 rounded-md py-2"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) =>
              setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
            }
          />
        </div>
        <Table
          columns={columns}
          data={tableState?.listData}
          pending={isLoading ?? isFetching}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </CardContainer>
    </>
  )
}

export default DataSecurityContent
