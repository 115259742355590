import React from 'react'
import { BaseIcon, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'

const ModalInspectionProgress = ({ onClose, title, text = 'Inspeksi Sedang Berlangsung.' }) => (
  <ModalContainer>
    <ModalForm minHeight="min-h-[15%]" maxWidth="max-w-[22%]" minWidth="min-w-[22%]">
      <div className="flex flex-col w-full space-y-2">
        <div className="flex flex-row items-center justify-between">
          <span className="font-semibold text-gray-900 text-lg">{title}</span>
          <div
            className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
            onClick={() => onClose()}>
            <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
          </div>
        </div>
        <div>
          <span className="font-medium text-left text-gray-500">{text}</span>
        </div>
        <div className="flex flex-row w-full items-center justify-end">
          <button
            onClick={() => onClose()}
            type="submit"
            className="flex flex-row mt-2 items-center justify-center hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
            Kembali
          </button>
        </div>
      </div>
    </ModalForm>
  </ModalContainer>
)

export default ModalInspectionProgress
