import React from 'react'
import { CardContainer } from 'components'
import { IcDislike, IcLike, IcSettings } from 'assets/icons'
import { Typography } from 'antd'

const InspeksiCard = ({
  data = { id: -1, grade: '', inspectionCategoryList: [] },
  onGoToDetailInspeksi = () => {}
}) => {
  const onReturnBadgeGrade = (grade) => {
    switch (grade?.toLowerCase()) {
      case 'ok': {
        return (
          <div className="py-1 px-3 rounded-xl bg-success-50 flex gap-1 items-center">
            <IcLike size={12} />
            <Typography.Text className="font-medium text-sm text-success-700">
              {grade ?? '-'}
            </Typography.Text>
          </div>
        )
      }

      case 'perlu perbaikan': {
        return (
          <div className="py-1 px-3 rounded-xl bg-warning-50 flex gap-1 items-center">
            <IcSettings className="text-warning-500" size={12} />
            <Typography.Text className="font-medium text-sm text-danger-700">
              {grade ?? '-'}
            </Typography.Text>
          </div>
        )
      }

      case 'jelek': {
        return (
          <div className="py-1 px-3 rounded-xl bg-danger-50 flex gap-1 items-center">
            <IcDislike size={12} />
            <Typography.Text className="font-medium text-sm text-danger-700">
              {grade ?? '-'}
            </Typography.Text>
          </div>
        )
      }

      default: {
        return (
          <div className="py-1 px-3 rounded-xl bg-gray-100 flex gap-1">
            <Typography.Text className="font-medium text-sm text-gray-700">
              {grade === '' ? 'Belum Ada' : grade}
            </Typography.Text>
          </div>
        )
      }
    }
  }

  return (
    <CardContainer isHeightFull width="90%" className={'grid grid-cols-1 gap-2'}>
      <div className="flex flex-col w-full items-start border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Inspeksi</span>
      </div>
      <div className="flex pt-4">
        {data.inspectionCategoryList.length !== 0 ? (
          <>
            <div className="grid grid-cols-1 w-full gap-3">
              {data.inspectionCategoryList.map((item, index) => (
                <div key={index} className="flex justify-between">
                  <span className="text-lg-semibold">{item.name}</span>
                  {onReturnBadgeGrade(item?.grade)}
                </div>
              ))}
              <button
                onClick={() => onGoToDetailInspeksi()}
                type="button"
                className="bg-white rounded-md border border-solid py-2 border-gray-300 hover:cursor-pointer">
                <span className="text-center font-medium text-gray-700">Lihat Detail Inspeksi</span>
              </button>
            </div>
          </>
        ) : (
          <span className="text-lg font-medium text-gray-700">Belum Terdapat Inspeksi.</span>
        )}
      </div>
    </CardContainer>
  )
}

export default InspeksiCard
