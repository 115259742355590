import { Button, Card, DatePicker, Dropdown, Input, Radio, Select, Typography } from 'antd'
import { apiGet, urls } from 'api'
import {
  IcCheck,
  IcCheckRound,
  IcInvoice,
  IcLoadMore,
  IcSearch,
  IcTrash,
  IcUser,
  IcWhatsApp
} from 'assets/icons'
import { Table } from 'components'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { onReturnStatusTelahDikonfirmasiJadwalService, onReturnWaktuPerluDikonfirmasi } from 'utils'
import ModalInspeksiDetail from './ModalInspeksiDetail'
import { openLinkWA } from 'utils/openLinkWA'

const JadwalInspeksiViewComponent = ({
  idCabang,
  token,
  onLogout,
  refetchSchedule,
  isWorkshop
}) => {
  const location = useLocation()
  const router = useHistory()
  const tabRef = useRef(null)

  const [listFilter, setListFilter] = useState({
    customers: [],
    places: [],
    stalls: []
  })
  const [modal, setModal] = useState({
    type: 'delete',
    data: {},
    show: false
  })

  const [filterConfirmed, setFilterConfirmed] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: [],
    stall: '',
    customer: '',
    place: '',
    start_date: '',
    end_date: '',
    keywords: '',
    dateRangeValue: []
  })

  const [filterUnconfirmed, setFilterUnconfirmed] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: [],
    stall: '',
    customer: '',
    place: '',
    start_date: '',
    end_date: '',
    keywords: '',
    dateRangeValue: []
  })

  const isTabUnconfirmed = useMemo(() => {
    const hashUrl = location?.hash?.split('-')

    return hashUrl?.[hashUrl?.length - 1] === '#confirmed' ||
      hashUrl?.[hashUrl?.length - 1] === 'confirmed'
      ? false
      : true
    // return location?.hash !== '#confirmed' ? true : false
  }, [location?.hash])

  useEffect(() => {
    const hashUrl = location?.hash?.split('-')
    if (
      hashUrl?.[hashUrl?.length - 1] === '#confirmed' ||
      hashUrl?.[hashUrl?.length - 1] === 'confirmed' ||
      hashUrl?.[hashUrl?.length - 1] === '#unconfirmed' ||
      hashUrl?.[hashUrl?.length - 1] === 'unconfirmed'
    ) {
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [location.hash])

  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (isTabUnconfirmed) {
      if (dates) {
        setFilterUnconfirmed((prev) => ({
          ...prev,
          dateRangeValue: dates,
          start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('DD/MM/YYYY'),
          end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('DD/MM/YYYY'),
          offset: 0
        }))
      } else {
        setFilterUnconfirmed((prev) => ({
          ...prev,
          dateRangeValue: [],
          start_date: '',
          end_date: '',
          offset: 0
        }))
      }
    } else {
      if (dates) {
        setFilterConfirmed((prev) => ({
          ...prev,
          dateRangeValue: dates,
          start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('DD/MM/YYYY'),
          end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('DD/MM/YYYY'),
          offset: 0
        }))
      } else {
        setFilterConfirmed((prev) => ({
          ...prev,
          dateRangeValue: [],
          start_date: '',
          end_date: '',
          offset: 0
        }))
      }
    }
  }

  const columnStyle = 'font-medium text-gray-700'

  const columnsPerluKonfirmasi = [
    {
      name: 'Tanggal',
      selector: (row) => (row?.date ? moment(row?.date).format('DD/MM/YYYY') : ''),
      cell: (row) => (
        <span className={columnStyle}>
          {row?.date ? moment(row?.date).format('DD/MM/YYYY') : ''}
        </span>
      ),
      grow: 1
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1.5 overflow-hidden py-2">
          <span className={columnStyle}>{row?.unit_name}</span>
          <span className="font-normal tracking-tight text-gray-500">{`${
            row?.police_number ?? ''
          }`}</span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.unit_owner,
      cell: (row) => <span className={columnStyle}>{row?.unit_owner}</span>,
      grow: 2
    },
    {
      name: 'Tempat',
      selector: (row) => row?.place,
      cell: (row) => <span className={columnStyle}>{row?.place}</span>,
      grow: 1
    },
    {
      name: 'Waktu',
      selector: (row) => onReturnWaktuPerluDikonfirmasi(row.kuotaLayanan, row?.time),
      cell: (row) => onReturnWaktuPerluDikonfirmasi(row.kuotaLayanan, row?.time),
      grow: 2
    },
    {
      name: 'Action',
      width: '160px',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div className="flex items-center justify-center self-center space-x-2 my-2 mr-4">
          <Button
            className="flex flex-row items-center justify-center space-x-2 px-3 border-solid border border-[#12B76A] bg-[#12B76A] focus:bg-[#12B76A] focus:border-[#12B76A] hover:text-white hover:bg-[#12B76A] hover:border-[#12B76A] rounded-md"
            onClick={() => setModal({ show: true, type: 'konfirmasi', data: row })}>
            <IcCheckRound className="text-[#FFFFFF]" size={16} />
            <span className="font-medium text-[#FFFFFF]">Konfirmasi</span>
          </Button>
          <Button
            className="p-2 border-solid border border-[#FDA19B] hover:border-[#FDA19B] focus:border-[#FDA19B] rounded-md bg-white"
            onClick={() => {
              setModal({ show: true, type: 'delete', data: row })
            }}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const columnsTelahKonfirmasi = [
    {
      name: 'Waktu Estimasi',
      selector: (row) => [row?.date, row?.time],
      cell: (row) => (
        <div className="flex flex-col space-y-1.5 overflow-hidden py-2">
          <span className="font-normal text-xs tracking-tight text-gray-500">
            {row?.date && row?.date !== '' ? moment(row?.date).format('DD/MM/YYYY') : '-'}
          </span>
          <span className={columnStyle}>{row?.time && row?.time !== '' ? row?.time : '-'}</span>
        </div>
      ),
      grow: 1.5
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1.5 overflow-hidden py-2">
          <span className={columnStyle}>{row?.unit_name}</span>
          <span className="font-normal tracking-tight text-gray-500">{`${
            row?.police_number ?? ''
          }`}</span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.unit_owner,
      cell: (row) => <span className={columnStyle}>{row?.unit_owner}</span>,
      grow: 2
    },
    {
      name: 'Tempat',
      selector: (row) => row?.place,
      cell: (row) => <span className={columnStyle}>{row?.place}</span>,
      grow: 1
    },
    // {
    //   name: 'Stall',
    //   selector: (row) => (row?.stall ? row?.stall : '-'),
    //   cell: (row) => <span className={columnStyle}>{row?.stall ? row?.stall : '-'}</span>,
    //   center: true,
    //   grow: 1
    // },
    {
      name: 'Waktu Aktual',
      selector: (row) => row?.time,
      cell: (row) =>
        onReturnActualTimeTelahKonfirmasi(
          row?.unit_status,
          row?.inspection_start_time,
          row?.inspection_done_time
        ),
      grow: 2
    },
    {
      name: 'Status',
      selector: (row) => onReturnStatusTelahDikonfirmasiJadwalService(row?.unit_status),
      cell: (row) => onReturnStatusTelahDikonfirmasiJadwalService(row?.unit_status),
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '50px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Dropdown
            menu={{
              items: [
                row?.unit_status === 'Inspeksi Terjadwal' && {
                  key: '8',
                  label: (
                    <div className="flex items-center">
                      <IcCheck className="text-success-500 mr-2" size={16} />
                      <Typography.Text className="text-success-500 text-sm">
                        Konfirmasi Kehadiran
                      </Typography.Text>
                    </div>
                  ),
                  onClick: () =>
                    setModal({
                      show: true,
                      type: 'konfirmasi-kehadiran',
                      data: row
                    })
                },
                row?.unit_status === 'Sedang Berlangsung' && {
                  key: '2',
                  label: (
                    <div className="flex items-center">
                      <IcUser className="text-gray-700 mr-2" size={16} />
                      <Typography.Text className="text-gray-700 text-sm">
                        Konfirmasi Inspektor
                      </Typography.Text>
                    </div>
                  ),
                  onClick: () =>
                    setModal({
                      show: true,
                      type: 'konfirmasi-inspektor',
                      data: row
                    })
                },
                {
                  key: '3',
                  label: (
                    <div className="flex items-center">
                      <IcSearch className="text-gray-700 mr-2" size={16} />
                      <Typography.Text className="text-gray-700 text-sm">
                        Detail Inspeksi
                      </Typography.Text>
                    </div>
                  ),
                  onClick: () => router.push(`/inspeksi/jadwal-inspeksi/${row?.id}/detail-inspeksi`)
                },
                // {
                //   key: '4',
                //   label: (
                //     <div className="flex items-center">
                //       <IcSO className="text-gray-700 mr-2" size={16} />
                //       <Typography.Text className="text-gray-700 text-sm">Lihat SO</Typography.Text>
                //     </div>
                //   ),
                //   onClick: () => router.push(`/inspeksi/jadwal-inspeksi/${row?.id}/sales-order`)
                // },
                {
                  key: '5',
                  label: (
                    <div className="flex items-center">
                      <IcInvoice className="text-gray-700 mr-2" size={16} />
                      <Typography.Text className="text-gray-700 text-sm">Invoice</Typography.Text>
                    </div>
                  ),
                  onClick: () =>
                    router.push(`/inspeksi/jadwal-inspeksi/${row?.inspection_invoice_id}/invoice`)
                },
                {
                  key: '6',
                  label: (
                    <div className="flex items-center">
                      <IcWhatsApp className="text-gray-700 mr-2" size={16} />
                      <Typography.Text className="text-gray-700 text-sm">Whatsapp</Typography.Text>
                    </div>
                  ),
                  onClick: () => openLinkWA(row?.phone_number)
                },
                row?.unit_status === 'Sedang Berlangsung' ||
                row?.unit_status === 'Inspeksi Terjadwal'
                  ? {
                      key: '9',
                      label: (
                        <div className="flex items-center">
                          <IcTrash className="text-danger-500 mr-2" size={16} />
                          <Typography.Text className="text-danger-500 text-sm">
                            Delete Inspeksi
                          </Typography.Text>
                        </div>
                      ),
                      onClick: () => setModal({ show: true, type: 'delete', data: row })
                    }
                  : null
              ]
            }}
            placement="bottomRight"
            trigger={['click']}>
            <div className="cursor-pointer" onClick={(e) => e.preventDefault()}>
              <IcLoadMore className="text-gray-700" size={16} />
            </div>
          </Dropdown>
        </div>
      )
    }
  ]

  const onReturnActualTimeTelahKonfirmasi = (status, startTime, doneTime) => {
    switch (status) {
      case 'Selesai':
        return (
          <div className="flex flex-col space-y-1.5 overflow-hidden py-2">
            <span className="font-normal text-xs tracking-tight text-gray-500">
              {`${startTime && startTime !== '' ? moment(startTime).format('DD/MM/YYYY') : '-'} - ${
                doneTime && doneTime !== '' ? moment(doneTime).format('DD/MM/YYYY') : '-'
              }`}
            </span>
            <span className={columnStyle}>
              {`${startTime && startTime !== '' ? moment(startTime).format('LT') : '-'} - ${
                doneTime && doneTime !== '' ? moment(doneTime).format('LT') : '-'
              }`}
            </span>
          </div>
        )
      case 'Sedang Berlangsung':
        return (
          <div className="flex flex-col space-y-1.5 overflow-hidden py-2">
            <span className="font-normal text-xs tracking-tight text-gray-500">
              {`${
                startTime && startTime !== '' ? moment(startTime).format('DD/MM/YYYY') : '-'
              } - ${`-`}`}
            </span>
            <span className={columnStyle}>
              {`${startTime && startTime !== '' ? moment(startTime).format('LT') : '-'} - ${`-`}`}
            </span>
          </div>
        )
      default:
        return <span className={columnStyle}>-</span>
    }
  }

  // API
  // Get list customers
  useQuery({
    queryKey: ['list-customers', idCabang],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection.prefix}/${urls?.inspection.customers}/${idCabang}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListFilter((prev) => ({ ...prev, customers: res?.resData?.data ?? [] }))
    }
  })

  // Get list stall
  useQuery({
    queryKey: ['list-stall', idCabang],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master.stall}/${idCabang}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListFilter((prev) => ({ ...prev, stalls: res?.resData?.data ?? [] }))
    }
  })

  // Get Unconfirmed
  const {
    isLoading: isLoadingUnconfirmed,
    refetch: refetchUnconfirmed,
    isFetching: isFetchingUnconfirmed
  } = useQuery({
    queryKey: [
      'unconfirmed',
      idCabang,
      filterUnconfirmed?.keywords,
      filterUnconfirmed?.stall,
      filterUnconfirmed?.customer,
      filterUnconfirmed?.place,
      filterUnconfirmed?.start_date,
      filterUnconfirmed?.end_date,
      filterUnconfirmed?.limit,
      filterUnconfirmed?.offset
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection?.prefix}/${urls?.inspection.schedules}/${idCabang}?${urls?.inspection?.schedulesUnconfirmed}&keywords=${filterUnconfirmed?.keywords}&stall=${filterUnconfirmed?.stall}&owner=${filterUnconfirmed?.customer}&place=${filterUnconfirmed?.place}&start_date=${filterUnconfirmed?.start_date}&end_date=${filterUnconfirmed?.end_date}&limit=${filterUnconfirmed?.limit}&offset=${filterUnconfirmed?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setFilterUnconfirmed((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Confirmed
  const {
    isLoading: isLoadingConfirmed,
    refetch: refetchConfirmed,
    isFetching: isFetchingConfirmed
  } = useQuery({
    queryKey: [
      'confirmed',
      idCabang,
      filterConfirmed?.keywords,
      filterConfirmed?.stall,
      filterConfirmed?.customer,
      filterConfirmed?.place,
      filterConfirmed?.start_date,
      filterConfirmed?.end_date,
      filterConfirmed?.limit,
      filterConfirmed?.offset
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection?.prefix}/${urls?.inspection.schedules}/${idCabang}?${urls?.inspection?.schedulesConfirmed}&keywords=${filterConfirmed?.keywords}&stall=${filterConfirmed?.stall}&owner=${filterConfirmed?.customer}&place=${filterConfirmed?.place}&start_date=${filterConfirmed?.start_date}&end_date=${filterConfirmed?.end_date}&limit=${filterConfirmed?.limit}&offset=${filterConfirmed?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setFilterConfirmed((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // *Table state
  const handlePerRowsChange = (newPerPage) => {
    if (isTabUnconfirmed) {
      setFilterUnconfirmed((prev) => ({
        ...prev,
        limit: newPerPage,
        offset: 0
      }))
    } else {
      setFilterConfirmed((prev) => ({
        ...prev,
        limit: newPerPage,
        offset: 0
      }))
    }
  }

  const onChangePage = (page) => {
    if (isTabUnconfirmed) {
      setFilterUnconfirmed((prev) => ({
        ...prev,
        offset: page
      }))
    } else {
      setFilterConfirmed((prev) => ({
        ...prev,
        offset: page
      }))
    }
  }

  return (
    <Card className="rounded-lg">
      <ModalInspeksiDetail
        isOpen={modal?.show}
        type={modal?.type}
        data={modal?.data}
        listStall={listFilter?.stalls}
        onCancel={() => setModal({ ...modal, show: false })}
        onLogout={onLogout}
        token={token}
        refetchConfirmed={refetchConfirmed}
        refetchUnconfirmed={refetchUnconfirmed}
        refetchSchedule={refetchSchedule}
        idCabang={idCabang}
      />
      <Radio.Group className="custom-radio mb-2" value={isTabUnconfirmed ? 0 : 1} size="large">
        <Radio.Button
          className="custom-radio-left"
          onClick={() => {
            router.push({
              hash: isWorkshop ? `inspection-unconfirmed` : 'unconfirmed'
            })
            refetchUnconfirmed()
          }}
          value={0}>
          Perlu Dikonfirmasi
        </Radio.Button>
        <Radio.Button
          className="custom-radio-right"
          onClick={() => {
            router.push({
              hash: isWorkshop ? `inspection-confirmed` : 'confirmed'
            })
            refetchConfirmed()
          }}
          value={1}>
          Telah Dikonfirmasi
        </Radio.Button>
      </Radio.Group>
      <div className="flex justify-between items-center my-3">
        <Typography.Text className="font-medium text-lg">
          {isTabUnconfirmed ? 'Daftar Unit Perlu Konfirmasi' : 'Unit Terkonfirmasi'}
        </Typography.Text>
      </div>
      <div className="flex justify-between mb-5 gap-2">
        <div className="flex items-center gap-4">
          <Select
            className="custom-table-limit"
            style={{ width: '150px' }}
            value={isTabUnconfirmed ? filterUnconfirmed?.customer : filterConfirmed?.customer}
            onChange={(e) =>
              isTabUnconfirmed
                ? setFilterUnconfirmed((prev) => ({ ...prev, customer: e ?? '', offset: 0 }))
                : setFilterConfirmed((prev) => ({ ...prev, customer: e ?? '', offset: 0 }))
            }
            showSearch
            allowClear
            filterOption={(input, option) => {
              return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }}>
            <Select.Option className="custom-table-limit-option" value="">
              Semua Pemilik
            </Select.Option>
            {listFilter?.customers?.map((item, idx) => (
              <Select.Option
                key={idx}
                className="custom-table-limit-option"
                value={item?.owner_name}>
                {item?.owner_name}
              </Select.Option>
            ))}
          </Select>
          <Select
            className="custom-table-limit"
            style={{ width: '150px' }}
            value={isTabUnconfirmed ? filterUnconfirmed?.place : filterConfirmed?.place}
            onChange={(e) =>
              isTabUnconfirmed
                ? setFilterUnconfirmed((prev) => ({ ...prev, place: e ?? '', offset: 0 }))
                : setFilterConfirmed((prev) => ({ ...prev, place: e ?? '', offset: 0 }))
            }
            showSearch
            allowClear
            filterOption={(input, option) => {
              return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }}>
            <Select.Option className="custom-table-limit-option" value="">
              Semua Tempat
            </Select.Option>
            <Select.Option className="custom-table-limit-option" value={true}>
              Bengkel
            </Select.Option>
            <Select.Option className="custom-table-limit-option" value={false}>
              Rumah
            </Select.Option>
          </Select>
          <Select
            className="custom-table-limit"
            style={{ width: '150px' }}
            value={isTabUnconfirmed ? filterUnconfirmed?.stall : filterConfirmed?.stall}
            onChange={(e) =>
              isTabUnconfirmed
                ? setFilterUnconfirmed((prev) => ({ ...prev, stall: e ?? '', offset: 0 }))
                : setFilterConfirmed((prev) => ({ ...prev, stall: e ?? '', offset: 0 }))
            }
            showSearch
            allowClear
            filterOption={(input, option) => {
              return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }}>
            <Select.Option className="custom-table-limit-option" value="">
              Semua Stall
            </Select.Option>
            {listFilter?.stalls?.map((item, idx) => (
              <Select.Option key={idx} className="custom-table-limit-option" value={item}>
                {`Stall ${item}`}
              </Select.Option>
            ))}
          </Select>
          <DatePicker.RangePicker
            onOpenChange={(open) => setIsShowDatePicker(open)}
            onChange={handleChangeRangePicker}
            format="DD/MM/YYYY"
            open={isShowDatePicker}
            value={
              isTabUnconfirmed ? filterUnconfirmed?.dateRangeValue : filterConfirmed?.dateRangeValue
            }
            placeholder={['Tanggal Mulai', 'Tanggal Akhir']}
            popupClassName="custom-date-picker-dropdown"
            className="custom-date-picker py-[6px] w-fit rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500"
          />
        </div>
        <Input
          className="w-1/4 rounded-md py-1.5"
          placeholder="Pencarian"
          prefix={<IcSearch className="text-gray-400" />}
          value={isTabUnconfirmed ? filterUnconfirmed?.keywords : filterConfirmed?.keywords}
          onChange={(e) => {
            isTabUnconfirmed
              ? setFilterUnconfirmed((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
              : setFilterConfirmed((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
          }}
        />
      </div>
      <div ref={tabRef}>
        <Table
          columns={isTabUnconfirmed ? columnsPerluKonfirmasi : columnsTelahKonfirmasi}
          data={isTabUnconfirmed ? filterUnconfirmed?.listData : filterConfirmed?.listData}
          pending={
            isTabUnconfirmed
              ? isLoadingUnconfirmed ?? isFetchingUnconfirmed
              : isLoadingConfirmed ?? isFetchingConfirmed
          }
          totalRows={isTabUnconfirmed ? filterUnconfirmed?.totalRows : filterConfirmed?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={isTabUnconfirmed ? filterUnconfirmed?.limit : filterConfirmed?.limit}
          page={isTabUnconfirmed ? filterUnconfirmed?.offset : filterConfirmed?.offset}
          onChangePrevPage={() => {
            if (isTabUnconfirmed) {
              if (filterUnconfirmed?.offset > 0) {
                onChangePage(filterUnconfirmed?.offset - filterUnconfirmed?.limit)
              }
            } else {
              if (filterConfirmed?.offset > 0) {
                onChangePage(filterConfirmed?.offset - filterConfirmed?.limit)
              }
            }
          }}
          onChangeNextPage={() => {
            if (isTabUnconfirmed) {
              if (
                filterUnconfirmed?.totalRows >
                filterUnconfirmed?.limit + filterUnconfirmed?.offset
              ) {
                onChangePage(filterUnconfirmed?.offset + filterUnconfirmed?.limit)
              }
            } else {
              if (filterConfirmed?.totalRows > filterConfirmed?.limit + filterConfirmed?.offset) {
                onChangePage(filterConfirmed?.offset + filterConfirmed?.limit)
              }
            }
          }}
        />
      </div>
    </Card>
  )
}

export default JadwalInspeksiViewComponent
