import React, { useState } from 'react'
import { Input, Modal, Radio, Typography } from 'antd'
import { errorsField, validationInput } from 'helpers'
import { useFormik } from 'formik'
import { apiPut, urls } from 'api'
import { showErrorMessage } from 'utils'

const ModalAdjustHarga = ({ isOpen, onCancel, data, token, getDataDetail, idPaket, onLogout }) => {
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.price_type, { isRequired: true })?.valid) {
      errors.price_type = validationInput(values?.price_type, { isRequired: true })?.messageError
    }
    if (!validationInput(values?.price, { isRequired: true, isNumeric: true })?.valid) {
      errors.price = validationInput(values?.price, {
        isRequired: true,
        isNumeric: true
      })?.messageError
    }

    return errors
  }
  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: data?.price ?? '',
      price_type: data?.price_type ?? ''
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      let body = {
        ...values,
        price: parseInt(values?.price)
      }
      handleSubmit(body)
    }
  })

  // Submit
  const handleSubmit = async (body) => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.serviceManagement.servicePackets.sparepart}/${data?.id}`,
        data: body,
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        formik.handleReset()
        getDataDetail?.mutate(idPaket)
        onCancel()
      } else {
        formik.setErrors(errorsField(res?.resData?.errors))
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      width={400}
      title="Adjust Harga"
      open={isOpen}
      onCancel={() => {
        onCancel()
        formik.handleReset()
      }}
      onOk={() => {
        formik.handleSubmit()
      }}
      okText="Confirm"
      okButtonProps={{ className: 'bg-primary-500', type: 'danger', loading: isLoadingCRUD }}
      cancelButtonProps={{
        className: 'hover:text-[#000] hover:border-[#000]',
        type: 'default',
        disabled: isLoadingCRUD
      }}>
      <div className="mb-3">
        <Typography.Text className="font-medium block mb-1">Diskon/Margin</Typography.Text>
        <Radio.Group className="custom-radio-btn" name="price_type" value={values?.price_type}>
          <Radio value="Discount" onChange={formik.handleChange}>
            Diskon
          </Radio>
          <Radio value="Margin" onChange={formik.handleChange}>
            Margin
          </Radio>
        </Radio.Group>
        {touched?.price_type && errors?.price_type && (
          <div>
            <span className="text-[red]">{errors?.price_type}</span>
          </div>
        )}
      </div>
      <div>
        <Typography.Text className="font-medium block mb-1">Besar Diskon/Margin</Typography.Text>
        <Input
          className="rounded mb-2 pt-1 pb-1"
          placeholder="Besaran"
          name="price"
          type="number"
          value={values?.price}
          onChange={formik.handleChange}
        />
        {touched?.price && errors?.price && <span className="text-[red]">{errors?.price}</span>}
      </div>
    </Modal>
  )
}

export default ModalAdjustHarga
