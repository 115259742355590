import React from 'react'

function BaseIcon(props) {
  const { iconSize, path, color } = props
  return (
    <svg
      viewBox="0 0 24 24"
      width={iconSize}
      height={iconSize}
      className="inline-block"
    >
      <path fill={color ?? 'currentColor'} d={path} />
    </svg>
  )
}

export default BaseIcon
