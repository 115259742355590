import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Button } from 'components'
import { IcEdit, IcEye, IcTrash } from 'assets/icons'
import ExTaxiUnitComponent from './components/ExTaxiUnitComponent'
import { apiDelete, apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import ModalDeleteExTaxi from './components/ModalDeleteExTaxi'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  brand: null,
  sort_by: 'id',
  sort_order: null
}

const ExTaxiUnit = (props) => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [tableState, setTableState] = useState(initialTableState)
  const [brandSearch, setBrandSearch] = useState('')
  const [modal, setModal] = useState({
    show: false,
    data: {}
  })

  const brand = useQuery({
    queryKey: ['brand-list', brandSearch],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: brandSearch,
        offset: 0,
        limit: 0
      }
      const res = await apiGet({
        token,
        data: params,
        url: `${urls.master.prefix}/${urls.master.products.brand.all}`,
        onLogout: () => dispatch(logout())
      })
      return res
    }
  })

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'extaxi-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.brand
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order,
        brand_id: tableState.brand
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'jual-beli-service/admin/taxis',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const columns = [
    {
      name: 'Tahun',
      selector: (row) => row?.year,
      cell: (row) => <span className="font-medium text-gray-700">{row?.year}</span>,
      grow: 1
    },
    {
      name: 'Brand',
      selector: (row) => row?.brand_name,
      cell: (row) => <span className="font-medium text-gray-700">{row?.brand_name}</span>,
      grow: 1
    },
    {
      name: 'Model',
      selector: (row) => [row.year, row.model_name],
      cell: (row) => <span className="font-medium text-gray-700">{row?.model_name}</span>,
      grow: 1
    },
    {
      name: 'Deskripsi',
      selector: (row) => row?.description,
      cell: (row) => <span className="font-medium text-gray-700">{row?.description}</span>,
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '180px',
      cell: (row) => (
        <div className="flex items-center space-x-1">
          <Button
            className="bg-white border-gray-300 hover:bg-gray-50 flex items-center"
            onClick={() =>
              props.history.push(`/ex-taxi/unit/${row.id}`, {
                id: row?.id
              })
            }>
            <IcEye className="text-gray-700 mr-1" size={18} />
            <span>Lihat</span>
          </Button>
          <Button
            className="bg-white border-gray-300 hover:bg-gray-50"
            onClick={() =>
              props.history.push(`/ex-taxi/unit/${row?.id}/edit-unit`, {
                id: row?.id
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ show: true, data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        url: `jual-beli-service/admin/taxis/${data?.id}`,
        token,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        refetch()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Ex-Taxi</Breadcrumb.Item>
        <Breadcrumb.Item>Unit</Breadcrumb.Item>
      </Breadcrumb>
      <ModalDeleteExTaxi
        isOpen={modal.show}
        data={modal.data}
        onCancel={() => setModal({ ...modal, show: false })}
        onSubmit={deleteData}
      />
      <ExTaxiUnitComponent
        isLoading={isLoading}
        columns={columns}
        data={tableState?.listData}
        tableState={tableState}
        onAdd={() => router.push('/ex-taxi/unit/tambah-unit')}
        setTableState={setTableState}
        brandData={brand?.data?.resData?.data ?? []}
        brandSearch={brandSearch}
        setBrandSearch={setBrandSearch}
      />
    </Layout>
  )
}

export default ExTaxiUnit
