import { DatePicker, Input, Modal, Select, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { apiDelete, apiGet, apiPut, urls } from 'api'
import { useFormik } from 'formik'
import { errorsField, validationInput } from 'helpers'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import {
  disabledDatePicker,
  disabledTimeDropdown,
  showErrorMessage,
  showSuccessMessage
} from 'utils'

const ModalInspeksiDetail = ({
  type,
  isOpen,
  onCancel,
  data,
  token,
  onLogout,
  refetchConfirmed,
  refetchUnconfirmed,
  refetchSchedule,
  listStall = [],
  idCabang
}) => {
  const [listData, setListData] = useState({
    schedules: [],
    availableStall: [],
    availableInspector: []
  })
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const ValidationCheck = (values) => {
    const errors = {}

    if (type === 'konfirmasi') {
      if (!validationInput(values?.inspection_date, { isRequired: true })?.valid) {
        errors.inspection_date = validationInput(values?.inspection_date, {
          isRequired: true
        })?.messageError
      }
    }

    if (type === 'konfirmasi') {
      if (!validationInput(values?.schedule_id, { isRequired: true })?.valid) {
        errors.schedule_id = validationInput(values?.schedule_id, {
          isRequired: true
        })?.messageError
      }
    }

    if (type === 'konfirmasi-kehadiran' && data?.place?.toLowerCase() === 'bengkel') {
      if (!validationInput(values?.stall, { isRequired: true })?.valid) {
        errors.stall = validationInput(values?.stall, {
          isRequired: true
        })?.messageError
      }
    }
    if (
      type === 'konfirmasi-kehadiran' ||
      type === 'konfirmasi-inspektor' ||
      (type === 'konfirmasi' && data?.place?.toLowerCase() === 'rumah')
    ) {
      if (!validationInput(values?.inspector_id, { isRequired: true })?.valid) {
        errors.inspector_id = validationInput(values?.inspector_id, {
          isRequired: true
        })?.messageError
      }
    }
    if (
      (type === 'konfirmasi-kehadiran' || type === 'konfirmasi') &&
      data?.place?.toLowerCase() === 'rumah'
    ) {
      if (!validationInput(values?.address, { isRequired: true })?.valid) {
        errors.address = validationInput(values?.address, {
          isRequired: true
        })?.messageError
      }
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      inspection_date: data?.date ? moment(data?.date).format('DD/MM/YYYY') : '',
      schedule_id: data?.schedule_id ?? '',
      stall: data?.stall ? data?.stall : '',
      inspector_id: data?.inspector_id ? data?.inspector_id : '',
      address: `${
        data?.sub_district_name && data?.sub_district_name !== ''
          ? `${data?.sub_district_name}, `
          : ''
      }${data?.city_name && data?.city_name !== '' ? `${data?.city_name}, ` : ''}${
        data?.province_name && data?.province_name !== '' ? `${data?.province_name}, ` : ''
      }${data?.owner_address && data?.owner_address !== '' ? `${data?.owner_address}, ` : ''}${
        data?.postal_code && data?.postal_code !== '' ? data?.postal_code : ''
      }`
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      let body = {
        inspection_date: moment(values?.inspection_date, 'DD/MM/YYYY').format('DD/MM/YYYY'),
        schedule_id: parseInt(values?.schedule_id)
      }

      if (type === 'konfirmasi') {
        if (data?.place?.toLowerCase() === 'rumah') {
          body = {
            ...body,
            address: values?.address,
            inspector_id: parseInt(values?.inspector_id),
            inspector_name: listData?.availableInspector?.find(
              (f) => f?.id === values?.inspector_id
            )?.name
          }
        }

        const nowDate = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
        const nowTime = moment(new Date(), 'HH:mm').format('HH:mm')
        const scheduleTime = moment(
          listData?.schedules?.find((f) => f?.id === body?.schedule_id)?.time?.split('-')?.[0],
          'HH:mm'
        ).format('HH:mm')
        const rangeDate = moment(
          moment(body?.inspection_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
        ).diff(nowDate, 'days')

        if (rangeDate < 0 || (rangeDate === 0 && nowTime > scheduleTime)) {
          showErrorMessage({ content: 'Jadwal Tidak Tersedia, Mohon Periksa Kembali.' })
          return true
        }
      } else if (type === 'konfirmasi-kehadiran' && data?.place?.toLowerCase() === 'bengkel') {
        body = {
          ...body,
          stall: parseInt(values?.stall),
          inspector_id: parseInt(values?.inspector_id),
          inspector_name: listData?.availableInspector?.find((f) => f?.id === values?.inspector_id)
            ?.name
        }
      } else if (type === 'konfirmasi-kehadiran' && data?.place?.toLowerCase() === 'rumah') {
        body = {
          ...body,
          inspector_id: parseInt(values?.inspector_id),
          inspector_name: listData?.availableInspector?.find((f) => f?.id === values?.inspector_id)
            ?.name,
          address: values?.address
        }
      } else if (type === 'konfirmasi-inspektor') {
        body = {
          ...body,
          inspector_id: parseInt(values?.inspector_id),
          inspector_name: listData?.availableInspector?.find((f) => f?.id === values?.inspector_id)
            ?.name
        }
      }

      handleSubmit(body)
    }
  })

  // Submit
  const handleSubmit = async (body) => {
    try {
      setIsLoadingCRUD(true)
      let url = ''
      if (type === 'konfirmasi-inspektor') {
        url = `${urls.inspection.prefix}/${urls.inspection.confirmInspector}/${data?.id}`
      } else if (type === 'konfirmasi-kehadiran' && data?.place?.toLowerCase() === 'bengkel') {
        url = `${urls.inspection.prefix}/${urls.inspection.attendanceConfirmWorkshop}/${data?.id}`
      } else if (type === 'konfirmasi-kehadiran' && data?.place?.toLowerCase() === 'rumah') {
        url = `${urls.inspection.prefix}/${urls.inspection.attendanceConfirmHouse}/${data?.id}`
      } else {
        // type konfirmasi
        if (data?.place?.toLowerCase() === 'bengkel') {
          url = `${urls.inspection.prefix}/${urls.inspection.confirmInspectionWorkshop}/${data?.id}`
        } else {
          url = `${urls.inspection.prefix}/${urls.inspection.confirmInspectionHouse}/${data?.id}`
        }
      }

      const res = await apiPut({
        url: url,
        data: body,
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        if (
          type === 'konfirmasi' ||
          type === 'konfirmasi-kehadiran' ||
          type === 'konfirmasi-inspektor'
        ) {
          onCancel()
          refetchConfirmed()
          refetchSchedule()
          refetchAvailableInspector()
        }
        if (type === 'konfirmasi') {
          refetchUnconfirmed()
        }
        formik.handleReset()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        formik.setErrors(errorsField(res?.resData?.errors))
        if (type === 'konfirmasi-kehadiran') {
          if (data?.place?.toLowerCase() === 'bengkel') {
            refetchStalls()
          }
          refetchAvailableInspector()
        } else if (
          type === 'konfirmasi-inspektor' ||
          (type === 'konfirmasi' && data?.place?.toLowerCase() === 'rumah')
        ) {
          refetchAvailableInspector()
        }
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  // Delete
  const handleDelete = async () => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiDelete({
        url: `${urls.inspection.prefix}/${urls.inspection.rootAdmin}/${data?.id}`,
        token: token,
        onLogout
      })
      if (res?.resStatus === 200) {
        refetchConfirmed()
        refetchUnconfirmed()
        refetchSchedule()
        onCancel()
        showSuccessMessage({ content: res.resData.message ?? 'Berhasil Menghapus Jadwal Inspeksi' })
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  // GET API
  // Get list available schedules
  useQuery({
    queryKey: ['list-schedules'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection.prefix}/${urls?.inspection.listSchedules}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({ ...prev, schedules: res?.resData?.data ?? [] }))
    }
  })

  // Get list available stalls
  const { refetch: refetchStalls } = useQuery({
    queryKey: ['list-available-stalls', values?.inspection_date, values?.schedule_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (values?.inspection_date && values?.schedule_id) {
        const res = await apiGet({
          token,
          url: `${urls?.inspection.prefix}/${urls?.inspection.availableStall}/${idCabang}?date=${values?.inspection_date}&schedule_id=${values?.schedule_id}`,
          onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      if (values?.inspection_date && values?.schedule_id) {
        setListData((prev) => ({ ...prev, availableStall: res?.resData?.data ?? [] }))
      }
    }
  })

  // Get list available inspectors
  const { refetch: refetchAvailableInspector } = useQuery({
    queryKey: ['list-available-inspectors', values?.inspection_date, values?.schedule_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (values?.inspection_date && values?.schedule_id) {
        const res = await apiGet({
          token,
          url: `${urls?.inspection.prefix}/${urls?.inspection.availableInspector}/${idCabang}?date=${values?.inspection_date}&schedule_id=${values?.schedule_id}`,
          onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      if (values?.inspection_date && values?.schedule_id) {
        setListData((prev) => ({ ...prev, availableInspector: res?.resData?.data ?? [] }))
      }
    }
  })

  if (type === 'delete') {
    return (
      <Modal
        width={400}
        title="Hapus Data Unit Inspeksi"
        open={isOpen}
        centered
        onCancel={() => {
          onCancel()
          formik.handleReset()
        }}
        onOk={() => {
          switch (type) {
            case 'delete':
              return handleDelete()
            default:
              return onCancel()
          }
        }}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger', loading: isLoadingCRUD }}
        cancelButtonProps={{
          className: 'hover:text-[#000] hover:border-[#000]',
          type: 'default',
          disabled: isLoadingCRUD
        }}>
        <Typography.Text className="font-medium text-gray-900">
          {`Apakah Anda yakin ingin menghapus unit inspeksi:`}
        </Typography.Text>
        <div className="mt-2">
          <Typography.Text className="font-medium block text-gray-900">Unit</Typography.Text>
          <Typography.Text className="text-gray-700">{data?.unit_name}</Typography.Text>
        </div>
        <div className="mt-2">
          <Typography.Text className="font-medium block text-gray-900">Pemilik</Typography.Text>
          <Typography.Text className="text-gray-700">{data?.unit_owner}</Typography.Text>
        </div>
        <div className="mt-2">
          <Typography.Text className="font-medium block text-gray-900">
            Tempat Inspeksi
          </Typography.Text>
          <Typography.Text className="text-gray-700">{data?.place}</Typography.Text>
        </div>
        <div className="mt-2">
          <Typography.Text className="font-medium block text-gray-900">Tanggal</Typography.Text>
          <Typography.Text className="text-gray-700">{data?.date}</Typography.Text>
        </div>
        <div className="mt-2">
          <Typography.Text className="font-medium block text-gray-900">
            Waktu Inspeksi
          </Typography.Text>
          <Typography.Text className="text-gray-700">{data?.time}</Typography.Text>
        </div>
      </Modal>
    )
  }

  if (type === 'konfirmasi-inspektor') {
    return (
      <Modal
        width={400}
        centered
        title="Konfirmasi Inspektor"
        open={isOpen}
        onCancel={() => {
          onCancel()
          formik.handleReset()
        }}
        onOk={formik.handleSubmit}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger', loading: isLoadingCRUD }}
        cancelButtonProps={{
          className: 'hover:text-[#000] hover:border-[#000]',
          type: 'default',
          disabled: isLoadingCRUD
        }}>
        <div className="">
          <Typography.Text className="font-medium block mb-1">Inspektor</Typography.Text>
          <Select
            className="custom-table-limit mb-1"
            style={{ width: '100%' }}
            placeholder="Pilih Inspektor"
            value={values?.inspector_id === '' ? null : values?.inspector_id}
            onChange={(e) => formik.setFieldValue('inspector_id', e)}>
            {listData?.availableInspector?.map((inspektor, idx) => (
              <Select.Option
                key={idx}
                className="custom-table-limit-option"
                value={inspektor?.id}
                disabled={!inspektor?.is_available}>
                {inspektor?.name}
              </Select.Option>
            ))}
          </Select>
          {touched?.inspector_id && errors?.inspector_id && (
            <span className="text-[red]">{errors?.inspector_id}</span>
          )}
        </div>
      </Modal>
    )
  }

  if (type === 'konfirmasi-kehadiran') {
    return (
      <Modal
        width={400}
        centered
        title={'Konfirmasi Inspeksi'}
        open={isOpen}
        onCancel={() => {
          onCancel()
          formik.handleReset()
        }}
        onOk={formik.handleSubmit}
        okText={'Confirm'}
        okButtonProps={{ className: 'bg-primary', type: 'danger', loading: isLoadingCRUD }}
        cancelButtonProps={{
          className: 'hover:text-[#000] hover:border-[#000]',
          type: 'default',
          disabled: isLoadingCRUD
        }}>
        {data?.place?.toLowerCase() === 'bengkel' && (
          <div className="mb-2">
            <Typography.Text className="font-medium block mb-1">
              Stall
              <span className="text-red-700">*</span>
            </Typography.Text>
            <Select
              className="custom-table-limit mb-1"
              style={{ width: '100%' }}
              placeholder="Pilih Stall"
              value={values?.stall === '' ? null : values?.stall}
              onChange={(e) => formik.setFieldValue('stall', e)}>
              {listStall?.map((stall, idx) => (
                <Select.Option
                  key={idx}
                  className="custom-table-limit-option"
                  value={stall}
                  disabled={
                    !listData?.availableStall?.find((f) => f?.stall === stall)?.is_available
                  }>
                  {`Stall ${stall}`}
                </Select.Option>
              ))}
            </Select>
            {touched?.stall && errors?.stall && <span className="text-[red]">{errors?.stall}</span>}
          </div>
        )}
        {data?.place?.toLowerCase() === 'rumah' && (
          <div className="mb-2">
            <Typography.Text className="font-medium block mb-1">
              Alamat
              <span className="text-red-700">*</span>
            </Typography.Text>
            <TextArea
              className="rounded py-2"
              style={{
                height: '95px'
              }}
              placeholder="Alamat"
              name="address"
              value={values?.address}
              onChange={formik.handleChange}
            />
            {touched?.address && errors?.address && (
              <span className="text-[red]">{errors?.address}</span>
            )}
          </div>
        )}
        <div className="">
          <Typography.Text className="font-medium block mb-1">
            Inspektor
            <span className="text-red-700">*</span>
          </Typography.Text>
          <Select
            className="custom-table-limit mb-1"
            style={{ width: '100%' }}
            placeholder="Pilih Inspektor"
            value={values?.inspector_id === '' ? null : values?.inspector_id}
            onChange={(e) => formik.setFieldValue('inspector_id', e)}>
            {listData?.availableInspector?.map((inspektor, idx) => (
              <Select.Option
                key={idx}
                className="custom-table-limit-option"
                value={inspektor?.id}
                disabled={!inspektor?.is_available}>
                {inspektor?.name}
              </Select.Option>
            ))}
          </Select>
          {touched?.inspector_id && errors?.inspector_id && (
            <span className="text-[red]">{errors?.inspector_id}</span>
          )}
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      width={450}
      title={'Konfirmasi Jadwal'}
      open={isOpen}
      centered
      onCancel={() => {
        onCancel()
        formik.handleReset()
      }}
      onOk={formik.handleSubmit}
      okText={'Confirm'}
      okButtonProps={{ className: 'bg-primary', type: 'danger', loading: isLoadingCRUD }}
      cancelButtonProps={{
        className: 'hover:text-[#000] hover:border-[#000]',
        type: 'default',
        disabled: isLoadingCRUD
      }}>
      <div className="mb-4">
        <Typography.Text className="font-medium block mb-1">User</Typography.Text>
        <Input
          className="rounded"
          value={data.unit_owner && data.unit_owner !== '' ? data.unit_owner : '-'}
          disabled
        />
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium block mb-1">Nomor Telepon</Typography.Text>
        <Input
          className="rounded"
          value={data.phone_number && data.phone_number !== '' ? data.phone_number : '-'}
          disabled
        />
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium block mb-1">
          Tanggal <span className="text-red-600">{' *'}</span>
        </Typography.Text>
        <DatePicker
          className="custom-date-picker w-full mb-1 pt-1 pb-1"
          popupClassName="custom-date-picker-dropdown"
          format={['DD/MM/YYYY']}
          placeholder="Pilih Tanggal"
          value={values?.inspection_date ? moment(values?.inspection_date, 'DD/MM/YYYY') : ''}
          onChange={(dates, dateStrings) => formik.setFieldValue('inspection_date', dateStrings)}
          disabledDate={disabledDatePicker}
        />
        {touched?.inspection_date && errors?.inspection_date && (
          <span className="text-[red]">{errors?.inspection_date}</span>
        )}
      </div>
      <div className="mb-2">
        <Typography.Text className="font-medium block mb-1">
          Waktu <span className="text-red-600">{' *'}</span>
        </Typography.Text>
        <Select
          className="custom-table-limit mb-1"
          style={{ width: '100%' }}
          placeholder="Pilih Waktu"
          value={values?.schedule_id === '' ? null : values?.schedule_id}
          onChange={(e) => formik.setFieldValue('schedule_id', e)}>
          {listData?.schedules?.map((schedule, idx) => (
            <Select.Option
              key={idx}
              className="custom-table-limit-option"
              value={schedule?.id}
              disabled={disabledTimeDropdown(schedule?.time, values?.inspection_date)}>
              {schedule?.time}
            </Select.Option>
          ))}
        </Select>
        {touched?.schedule_id && (errors?.schedule_id || errors?.InspectionSchedule) && (
          <span className="text-[red]">{errors?.schedule_id ?? errors?.InspectionSchedule}</span>
        )}
      </div>
      {data?.place?.toLowerCase() === 'rumah' && (
        <div className="mb-2">
          <Typography.Text className="font-medium block mb-1">Alamat</Typography.Text>
          <TextArea
            className="rounded py-2"
            style={{
              height: '95px'
            }}
            placeholder="Alamat"
            name="address"
            value={values?.address}
            onChange={formik.handleChange}
          />
          {touched?.address && errors?.address && (
            <span className="text-[red]">{errors?.address}</span>
          )}
        </div>
      )}
      {data?.place?.toLowerCase() === 'rumah' && (
        <div className="">
          <Typography.Text className="font-medium block mb-1">Inspektor</Typography.Text>
          <Select
            className="custom-table-limit mb-1"
            style={{ width: '100%' }}
            placeholder="Pilih Inspektor"
            value={values?.inspector_id === '' ? null : values?.inspector_id}
            onChange={(e) => formik.setFieldValue('inspector_id', e)}>
            {listData?.availableInspector?.map((inspektor, idx) => (
              <Select.Option
                key={idx}
                className="custom-table-limit-option"
                value={inspektor?.id}
                disabled={!inspektor?.is_available}>
                {inspektor?.name}
              </Select.Option>
            ))}
          </Select>
          {touched?.inspector_id && errors?.inspector_id && (
            <span className="text-[red]">{errors?.inspector_id}</span>
          )}
        </div>
      )}
    </Modal>
  )
}

export default ModalInspeksiDetail
