import { IcChevronRight } from 'assets/icons'
import React from 'react'

const AccumulationCard = ({ data, onClick }) => {
  const { label, desc, bgClassColor, icon } = { ...data }
  return (
    <div
      onClick={onClick}
      className="bg-white flex cursor-pointer justify-between p-6 border border-solid rounded-lg border-gray-300">
      {/* Container */}
      <div className="grid grid-cols-1 gap-3">
        {/* Icon & Title */}
        <div className="flex gap-3 items-center">
          <div className={`p-3 rounded-lg flex justify-center items-center ${bgClassColor}`}>
            {icon}
          </div>
          <p className="text-sm-medium text-gray-700 m-0">{label}</p>
        </div>
        {/* desc */}
        <p className="text-xl-semibold text-gray-900 m-0">{desc}</p>
      </div>
      <div className="flex justify-center items-center">
        <IcChevronRight />
      </div>
    </div>
  )
}

export default AccumulationCard
