import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import JadwalInspeksiSOComponent from './components/JadwalInspeksiSOComponent'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'

const JadwalInspeksiSO = () => {
  const { id } = useParams()
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const [detail, setDetail] = useState({})

  // Get Detail Unit
  useQuery({
    queryKey: ['detail-unit', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection.prefix}/${urls?.inspection.rootAdmin}/${id}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDetail(res?.resData?.data ?? {})
    }
  })

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item
          className="cursor-pointer"
          onClick={() => router.push(`/inspeksi/jadwal-inspeksi`)}>
          Jadwal Inspeksi
        </Breadcrumb.Item>
        <Breadcrumb.Item className="cursor-pointer" onClick={() => router.go(-1)}>
          {detail?.workshop_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{detail?.unit_name}</Breadcrumb.Item>
        <Breadcrumb.Item>Sales Order</Breadcrumb.Item>
      </Breadcrumb>
      <JadwalInspeksiSOComponent data={detail} />
    </Layout>
  )
}

export default JadwalInspeksiSO
