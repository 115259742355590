import React, { useState } from 'react'
import { BaseIcon, FormDropDown, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Formik } from 'formik'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'

const ModalCreateUpdateLocation = ({ minHeight, onClose, onSubmit, state, dropDownProvince }) => {
  const user = useSelector((state) => state.auth)
  const [dropDownCity, setDropDownCity] = useState([])
  const [dropDownSubDistrict, setDropDownSubDistrict] = useState([])
  const [provinceSelectorState, setProvinceSelectorState] = useState(
    state.type === 'update' ? state.provinceId : null
  )
  const [citySelectorState, setCitySelectorState] = useState(
    state.type === 'update' ? state.cityId : null
  )
  const [subDistrictSelectorState, setSubDistrictSelectorState] = useState(
    state.type === 'update' ? state.subDistrictId : null
  )
  const [kelurahanState, setKelurahanState] = useState(state.type === 'update' ? state.name : '')
  const [postalCodeState, setPostalCodeState] = useState(
    state.type === 'update' ? state.postalCode : ''
  )

  const [isEmptyForm, setIsEmptyForm] = useState({
    province: {
      isEmpty: false,
      message: 'Silahkan Pilih Provinsi'
    },
    city: {
      isEmpty: false,
      message: 'Silahkan Pilih Kota/Kabupaten'
    },
    subDistrict: {
      isEmpty: false,
      message: 'Silahkan Pilih Kecamatan'
    },
    kelurahan: {
      isEmpty: false,
      message: 'Silahkan Masukkan Nama Kelurahan/Desa'
    },
    postalCode: {
      isEmpty: false,
      message: 'Silahkan Masukkan Kode Pos'
    }
  })

  const { isLoading, data } = useQuery({
    queryKey: ['initial-cities-subdistricts'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await Promise.all([
        apiGet({
          url: `${urls.master.prefix}/${urls.master.location.cities.filterByProvince}/${state.provinceId}`,
          token: user.token
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.location.subDistrict.filterByCity}/${state.cityId}`,
          token: user.token
        })
      ]),
    onSuccess: (data) => {
      setDropDownCity([])
      setDropDownSubDistrict([])
      if (state.type === 'update') {
        if (data[0].resData.data.length !== 0) {
          data[0].resData.data.map((item) => {
            setDropDownCity((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
        if (data[1].resData.data.length !== 0) {
          data[1].resData.data.map((item) => {
            setDropDownSubDistrict((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.type === 'create' ? 'Tambah' : 'Edit'} Lokasi`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div className="flex flex-col w-full space-y-5">
            <Formik
              enableReinitialize
              initialValues={{
                provinceSelector: provinceSelectorState
              }}>
              <FormDropDown
                isSearch
                allowClear
                placeholder="Semua Provinsi"
                label="Provinsi"
                name="provinceSelector"
                isRequired
                isEmpty={isEmptyForm.province.isEmpty}
                errorMessage={isEmptyForm.province.message}
                data={dropDownProvince}
                onChange={async (value) => {
                  setProvinceSelectorState(value ? parseInt(value) : null)
                  setDropDownCity([])
                  if (!value || parseInt(value) < 1) {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province,
                        isEmpty: true
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province,
                        isEmpty: false
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode
                      }
                    })
                    const response = await apiGet({
                      url: `${urls.master.prefix}/${
                        urls.master.location.cities.filterByProvince
                      }/${parseInt(value)}`
                    })
                    if (response.resStatus === 200) {
                      if (response.resData.data.length !== 0) {
                        response.resData.data.map((item) => {
                          setDropDownCity((prev) => [...prev, { name: item.name, value: item.id }])
                        })
                      }
                    }
                  }
                }}
              />
            </Formik>
            <Formik
              enableReinitialize
              initialValues={{
                citySelector: citySelectorState
              }}>
              <FormDropDown
                isSearch
                allowClear
                placeholder="Semua Kota/Kabupaten"
                label="Kota/Kabupaten"
                name="citySelector"
                isRequired
                isEmpty={isEmptyForm.city.isEmpty}
                errorMessage={isEmptyForm.city.message}
                data={dropDownCity}
                onChange={async (value) => {
                  setCitySelectorState(value ? parseInt(value) : null)
                  setDropDownSubDistrict([])
                  if (!value || parseInt(value) < 1) {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city,
                        isEmpty: true
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city,
                        isEmpty: false
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode
                      }
                    })
                    const response = await apiGet({
                      url: `${urls.master.prefix}/${
                        urls.master.location.subDistrict.filterByCity
                      }/${parseInt(value)}`
                    })
                    if (response.resStatus === 200) {
                      if (response.resData.data.length !== 0) {
                        response.resData.data.map((item) => {
                          setDropDownSubDistrict((prev) => [
                            ...prev,
                            { name: item.name, value: item.id }
                          ])
                        })
                      }
                    }
                  }
                }}
              />
            </Formik>
            <Formik
              enableReinitialize
              initialValues={{
                subDistrictSelector: subDistrictSelectorState
              }}>
              <FormDropDown
                isSearch
                allowClear
                placeholder="Semua Kecamatan"
                label="Kecamatan"
                name="subDistrictSelector"
                isRequired
                isEmpty={isEmptyForm.subDistrict.isEmpty}
                errorMessage={isEmptyForm.subDistrict.message}
                data={dropDownSubDistrict}
                onChange={(value) => {
                  setSubDistrictSelectorState(value ? parseInt(value) : null)
                  if (!value || parseInt(value) < 1) {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict,
                        isEmpty: true
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict,
                        isEmpty: false
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode
                      }
                    })
                  }
                }}
              />
            </Formik>
            <Formik
              initialValues={{
                kelurahan: kelurahanState
              }}>
              <FormInput
                label="Kelurahan"
                name="kelurahan"
                placeholder="Masukkan Kelurahan/Desa"
                isRequired
                isEmpty={isEmptyForm.kelurahan.isEmpty}
                errorMessage={isEmptyForm.kelurahan.message}
                onValidate={(value) => {
                  setKelurahanState(value)
                  if (!value) {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan,
                        isEmpty: true
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan,
                        isEmpty: false
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode
                      }
                    })
                  }
                }}
              />
            </Formik>
            <Formik
              initialValues={{
                postalCode: postalCodeState
              }}>
              <FormInput
                label="Kode Pos"
                name="postalCode"
                placeholder="Masukkan Kode Pos"
                isRequired
                isEmpty={isEmptyForm.postalCode.isEmpty}
                errorMessage={isEmptyForm.postalCode.message}
                onValidate={(value) => {
                  setPostalCodeState(value)
                  if (!value) {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode,
                        isEmpty: true
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      },
                      kelurahan: {
                        ...isEmptyForm.kelurahan
                      },
                      postalCode: {
                        ...isEmptyForm.postalCode,
                        isEmpty: false
                      }
                    })
                  }
                }}
              />
            </Formik>
            <div className="flex flex-row w-full items-center justify-end space-x-4">
              <button
                type="button"
                className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  if (
                    provinceSelectorState &&
                    provinceSelectorState > 0 &&
                    citySelectorState &&
                    citySelectorState > 0 &&
                    subDistrictSelectorState &&
                    subDistrictSelectorState > 0 &&
                    kelurahanState &&
                    kelurahanState !== '' &&
                    postalCodeState &&
                    postalCodeState !== ''
                  ) {
                    onSubmit({
                      provinceSelector: provinceSelectorState,
                      citySelector: citySelectorState,
                      subDistrictSelector: subDistrictSelectorState,
                      kelurahan: kelurahanState,
                      postalCode: postalCodeState
                    })
                  }
                  setIsEmptyForm({
                    province: {
                      ...isEmptyForm.province,
                      isEmpty: !provinceSelectorState || provinceSelectorState < 1 ? true : false
                    },
                    city: {
                      ...isEmptyForm.city,
                      isEmpty: !citySelectorState || citySelectorState < 1 ? true : false
                    },
                    subDistrict: {
                      ...isEmptyForm.subDistrict,
                      isEmpty:
                        !subDistrictSelectorState || subDistrictSelectorState < 1 ? true : false
                    },
                    kelurahan: {
                      ...isEmptyForm.kelurahan,
                      isEmpty: !kelurahanState || kelurahanState === '' ? true : false
                    },
                    postalCode: {
                      ...isEmptyForm.postalCode,
                      isEmpty: !postalCodeState || postalCodeState === '' ? true : false
                    }
                  })
                }}
                className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                {`${state.type === 'create' ? 'Tambah Lokasi' : 'Simpan'}`}
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateLocation
