import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
import { Card, Input, Typography } from "antd"
import { Content } from "antd/lib/layout/layout"
import { IcPlus, IcSearch } from "assets/icons"
import { Button, Table } from "components"

const UserPageComponent = ({ columns, data, tableState, onAdd, setTableState, isLoading }) => {
    
    const handleSearch = useCallback((value) => {
        setTableState((prevState) => ({
            ...prevState,
            keywords: value,
        }));
    }, []);
    
    const handleSort = (column, sortDirection) => {
        setTableState((prevState) => ({
            ...prevState,
            sort_by: column.sortField,
            sort_order: sortDirection,
        }));
    };

    const handlePerRowsChange = (newPerPage) => {
        setTableState((prevState) => ({
          ...prevState,
          limit: newPerPage,
          offset: 0
        }));
    };

    const onChangePage = (page) => {
        setTableState((prevState) => ({
          ...prevState,
          offset: page
        }));
    };

    return (
        <Content className="h-screen">
            <Card className="rounded-lg">
                <div className="flex justify-between mb-5">
                    <Typography.Text className="font-semibold text-2xl">User List</Typography.Text>
                    <Button
                        className="flex items-center py-0 text-white bg-primary-500 border-primary-500"
                        onClick={onAdd}
                    >
                        <IcPlus className="text-white" size={19} />
                        <span>Tambah User</span>
                    </Button>
                </div>
                <div className="mb-5">
                    <Input
                        className="w-1/4 rounded-md py-1.5"
                        placeholder="Pencarian"
                        prefix={<IcSearch className="text-gray-400 w-[19px] h-[19px]" />}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <Table
                    columns={columns}
                    data={data}
                    pending={isLoading}
                    onSort={handleSort}
                    totalRows={tableState?.totalRows}
                    handlePerRowsChange={handlePerRowsChange}
                    pageSize={tableState?.limit}
                    page={tableState?.offset}
                    onChangePrevPage={() => {
                        if (tableState?.offset > 0) {
                            onChangePage(tableState?.offset - tableState?.limit)
                        }
                    }}
                    onChangeNextPage={() => {
                        if(tableState?.totalRows > tableState?.limit + tableState?.offset) {
                            onChangePage(tableState?.offset + tableState?.limit)
                        }
                    }}
                />
            </Card>
        </Content>
    )

}

UserPageComponent.propTypes = {
    tableState: PropTypes.object,
    data: PropTypes.array,
    onAdd: PropTypes.func,
    setTableState: PropTypes.func,
};

UserPageComponent.defaultProps = {
    tableState: {},
    data: [],
    onAdd: () => {},
    setTableState: () => {},
};

export default UserPageComponent