import ModalLayout from 'layouts/modal-layout'
import moment from 'moment'
import React from 'react'

const ModalDetailPickup = ({ data, onClose, openModal }) => {
  const fieldStyle = 'text-lg-medium text-gray-900'
  const valueStyle = 'text-md-regular text-gray-700'
  const containerStyle = 'flex flex-col gap-1'
  return (
    <ModalLayout
      className={'min-w-[300px]'}
      headerTitle={'Detail Pickup'}
      onCancel={onClose}
      openModal={openModal}>
      <div className="grid grid-cols-1 gap-4">
        <div className={containerStyle}>
          <span className={fieldStyle}>Unit</span>
          <span className={valueStyle}>{data?.unit_name}</span>
        </div>
        <div className={containerStyle}>
          <span className={fieldStyle}>Tanggal</span>
          <span className={valueStyle}>{moment(data?.service_date).format('DD/MM/YYYY')}</span>
        </div>
        <div className={containerStyle}>
          <span className={fieldStyle}>Waktu Penjemputan</span>
          <span className={valueStyle}>{`${moment(data?.service_start_time, 'HH:mm').format(
            'HH:mm'
          )} - ${moment(data?.service_end_time, 'HH:mm').format('HH:mm')}`}</span>
        </div>
        <div className={containerStyle}>
          <span className={fieldStyle}>Pemilik</span>
          <span className={valueStyle}>{data?.full_name}</span>
        </div>
        <div className={containerStyle}>
          <span className={fieldStyle}>Alamat</span>
          <span className={valueStyle}>{`
          ${data?.pickup_address ? data?.pickup_address : ''}
          `}</span>
          {/* ${data?.location_name ? data?.location_name + ', ' : ''}
          ${data?.subdistrict_name ? data?.subdistrict_name + ', ' : ''}
          ${data?.city_name ? data?.city_name + ', ' : ''}
          ${data?.province_name ? data?.province_name : ''}
          ${data?.postal_code ? data?.postal_code : ''} */}
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalDetailPickup
