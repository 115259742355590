import React, { useState } from 'react'
import { BaseIcon, FormInput, FormInputRupiah, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'
import { showErrorMessage } from 'utils'

const ModalCreateUpdateHargaJarak = ({ minHeight, onClose, onSubmit, state }) => {
  const [fromDistance, setFromDistance] = useState(state.type === 'edit' ? state.fromDistance : '')
  const [toDistance, setToDistance] = useState(state.type === 'edit' ? state.toDistance : '')
  const [price, setPrice] = useState(state.type === 'edit' ? state.price : '')
  const [isEmptyForm, setIsEmptyForm] = useState({
    fromDistance: { isEmpty: false, message: 'Masukkan Jarak Dari' },
    toDistance: { isEmpty: false, message: 'Masukkan Jarak Hingga' },
    price: {
      isEmpty: false,
      message: 'Masukkan Harga'
    }
  })
  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.type === 'create' ? 'Tambah' : 'Edit'} Harga Jarak`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>

          <div className="flex flex-col w-full space-y-5">
            <Formik
              initialValues={{
                fromDistance: fromDistance
              }}>
              <FormInput
                label="Dari"
                name="fromDistance"
                placeholder="Masukkan Jarak Dari"
                isRequired
                isIconRight
                iconName="distance"
                isEmpty={isEmptyForm.fromDistance.isEmpty}
                errorMessage={isEmptyForm.fromDistance.message}
                onValidate={(value) => {
                  setFromDistance(value)
                  if (!value) {
                    setIsEmptyForm((prev) => ({
                      ...prev,
                      fromDistance: {
                        ...isEmptyForm.fromDistance,
                        isEmpty: true
                      }
                    }))
                  } else {
                    setIsEmptyForm((prev) => ({
                      ...prev,
                      fromDistance: {
                        ...isEmptyForm.fromDistance,
                        isEmpty: false
                      }
                    }))
                  }
                }}
              />
            </Formik>
            <Formik
              initialValues={{
                toDistance: toDistance
              }}>
              <FormInput
                label="Hingga"
                name="toDistance"
                placeholder="Masukkan Jarak Hingga"
                isRequired
                isIconRight
                iconName="distance"
                isEmpty={isEmptyForm.toDistance.isEmpty}
                errorMessage={isEmptyForm.toDistance.message}
                onValidate={(value) => {
                  setToDistance(value)
                  if (!value) {
                    setIsEmptyForm((prev) => ({
                      ...prev,
                      toDistance: {
                        ...isEmptyForm.toDistance,
                        isEmpty: true
                      }
                    }))
                  } else {
                    setIsEmptyForm((prev) => ({
                      ...prev,
                      toDistance: {
                        ...isEmptyForm.toDistance,
                        isEmpty: false
                      }
                    }))
                  }
                }}
              />
            </Formik>
            <Formik
              initialValues={{
                price: price
              }}>
              <FormInputRupiah
                label="Harga"
                name="price"
                value={price}
                mode="normal"
                placeholder="Masukkan Harga"
                isRequired
                onChange={(value) => {
                  setPrice(value)
                  if (!value) {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      price: {
                        ...isEmptyForm.price,
                        isEmpty: true
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      price: {
                        ...isEmptyForm.price,
                        isEmpty: false
                      }
                    })
                  }
                }}
                isEmptyField={isEmptyForm.price.isEmpty}
                message={isEmptyForm.price.message}
              />
            </Formik>

            <div className="flex flex-row w-full items-center justify-end space-x-4">
              <button
                type="button"
                className="flex flex-row hover:cursor-pointer mt-2 items-center justify-around rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                type="submit"
                className="flex flex-row hover:cursor-pointer mt-2 items-center justify-around rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500"
                onClick={() => {
                  {
                    if (
                      fromDistance &&
                      fromDistance !== '' &&
                      toDistance &&
                      toDistance !== '' &&
                      Number(toDistance) > Number(fromDistance) &&
                      price &&
                      price !== ''
                    ) {
                      onSubmit({ fromDistance: fromDistance, toDistance: toDistance }, price)
                    } else if (
                      toDistance !== '' &&
                      fromDistance !== '' &&
                      Number(toDistance) <= Number(fromDistance)
                    ) {
                      showErrorMessage({
                        content:
                          'Jarak Hingga tidak boleh lebih kecil atau sama dengan dari Jarak Dari'
                      })
                    } else {
                      setIsEmptyForm({
                        fromDistance: {
                          ...isEmptyForm.fromDistance,
                          isEmpty: !fromDistance || fromDistance === '' ? true : false
                        },
                        toDistance: {
                          ...isEmptyForm.toDistance,
                          isEmpty: !toDistance || toDistance === '' ? true : false
                        },
                        price: {
                          ...isEmptyForm.price,
                          isEmpty: !price || price === '' ? true : false
                        }
                      })
                    }
                  }
                }}>
                {`${state.type === 'create' ? 'Tambah Harga Jarak' : 'Simpan'}`}
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateHargaJarak
