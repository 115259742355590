import { rootService } from 'api/api-root'
import { apiResponse } from 'api/api-response'
import * as Sentry from '@sentry/react'
import _ from 'lodash'

export const apiGet = async ({ token, data, url, onLogout = () => {} }) => {
  let headers
  let obj = apiResponse()

  if (token) {
    headers = {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  } else {
    headers = {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  try {
    const res = await rootService.get(`/${url}`, {
      headers,
      responseType: 'json',
      params: data
    })
    obj.resData = res.data
    obj.resStatus = res.status
    return obj
  } catch (error) {
    if (error.response.status === 401) {
      _.throttle(() => {
        onLogout()
      }, 3000)()
    }
    Sentry.withScope(function (scope) {
      scope.setTransactionName('AXIOS GET')
      // scope.setLevel('')
      Sentry.captureException(error)
    })
    obj.resData = error.response.data
    obj.resStatus = error.response.status
    return obj
  }
}
