import { combineReducers } from 'redux';

import authReducer from './auth';
import inspeksiReducer from './inspeksi';
import servisReducer from './servis'

const reducers = combineReducers({
    auth: authReducer,
    inspeksi: inspeksiReducer,
    servis: servisReducer
})

export default reducers;