import React, { useEffect, useState } from 'react'
import { Input, Modal, Tag, Typography } from 'antd'
import ModalRoleMenu from './ModalRoleMenu';
import { Button } from 'components';
import { showErrorMessage } from 'utils';

const ModalUpdateRole = ({
    type, isOpen, onCancel, data, onSubmit, onDelete
}) => {
    const [name, setName] = useState('');
    const [modal, setModal] = useState({
        show: false,
        data: {}
    })
    const [itemMenu, setItemMenu] = useState([]);
    useEffect(() => {
        if (isOpen) {
            setName(data?.name ?? '')
            setItemMenu(data?.menus ?? [])
        }
    }, [isOpen])
    if (type === 'delete') {
        return (
            <Modal
                width={400}
                title="Hapus Role"
                open={isOpen}
                onOk={() => onDelete(data)}
                onCancel={onCancel}
                okText="Confirm"
                okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
                cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
            >
                <Typography.Text className="font-medium">
                    {`Apakah Anda yakin ingin menghapus role ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
                </Typography.Text>
            </Modal>
        )
    }

    const onOk = () => {
        if (!name) {
            showErrorMessage({
                content: `Name is a required field`
            })
            return;
        }
        if (itemMenu.length < 1) {
            showErrorMessage({
                content: `Selected Menu must be more than 1`
            })
            return;
        }
        onSubmit({
            id: data?.id,
            name: name,
            type: 'admin',
            menus: itemMenu
        })
    }

    return (
        <Modal
            width={400}
            title={type === 'add' ? 'Tambah Role' : 'Edit Role'}
            open={isOpen}
            onCancel={onCancel}
            onOk={onOk}
            okText={'Confirm'}
            okButtonProps={{ className: 'bg-primary', type: 'danger' }}
            cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
            maskClosable={false}
        >
            <ModalRoleMenu
                isOpen={modal.show}
                data={itemMenu}
                onCancel={() => setModal({ ...modal, show: false })}
                onSubmit={(menus) => {
                    setItemMenu(menus);
                    setModal({
                        ...modal,
                        show: false
                    })
                }}
            />
            <Typography.Text className="font-medium">
                Nama Role
                <span className="text-[red]"> *</span>
            </Typography.Text>
            <Input
                className="rounded mb-2 pt-1 pb-1"
                placeholder="Masukan Nama Role"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <Typography.Text className="font-medium">Menu <span className="text-[red]">*</span></Typography.Text>
            <div className='mt-1'>
                {itemMenu.length > 0 && <div className="flex mb-3 flex-wrap mt-2">
                    {itemMenu.map((item, index) => (
                        <Tag
                            key={`item-${index.toString()}`}
                            className="py-[2px] border-0 bg-[#FEECE2] rounded-lg text-[#A21226] mb-2 selection:text-black"
                        >
                            {item}
                        </Tag>
                    ))}
                </div>}
                <Button className="border-gray-200 bg-white py-1 px-3" onClick={() => setModal({ ...modal, show: true })}>
                    Pilih Menu
                </Button>
            </div>
        </Modal>
    )
}

export default ModalUpdateRole
