import React, { useState } from 'react'
import { Breadcrumb, Layout, Typography } from 'antd'
import { Button } from 'components'
import { IcEye } from 'assets/icons'
import HasilInspeksiComponent from './components/HasilInspeksiComponent'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { apiGet, urls } from 'api'
import { formatDate } from 'helpers'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const HasilInspeksi = (props) => {
  const [tableState, setTableState] = useState(initialTableState)
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [search, setSearch] = useState({
    owner: '',
    workshop: '',
    stall: ''
  })
  const [selectDropdown, setSelectDropdown] = useState({
    owner: '',
    workshop: '',
    stall: '',
    start_date: '',
    end_date: '',
    dateRangeValue: [],
    isShowDatePicker: false
  })

  const columns = [
    {
      name: 'Tanggal',
      selector: (row) => formatDate(row?.created_at),
      cell: (row) => formatDate(row?.created_at),
      width: '120px'
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          <Typography.Text
            className="w-32 xl:w-full font-medium text-gray-700"
            ellipsis={{
              tooltip: row?.unit_name
            }}>
            {row?.unit_name}
          </Typography.Text>
          <Typography.Text className="text-sm text-gray-500">
            {row?.unit_police_number}
          </Typography.Text>
        </div>
      )
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.unit_owner,
      cell: (row) => row?.unit_owner
    },
    {
      name: 'Cabang',
      selector: (row) => row?.workshop_name,
      cell: (row) => row?.workshop_name
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '150px',
      cell: (row) => (
        <Button
          className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
          onClick={() =>
            props.history.push(`/inspeksi/hasil-inspeksi/${row.id}`, {
              id: row?.id
            })
          }>
          <IcEye className="text-gray-700 mr-1" size={18} />
          <span>Lihat</span>
        </Button>
      )
    }
  ]

  const { isLoading } = useQuery({
    queryKey: [
      'hasil-inspeksi-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      selectDropdown.owner,
      selectDropdown.workshop,
      selectDropdown.stall,
      selectDropdown.start_date,
      selectDropdown.end_date
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        workshop_id: selectDropdown.workshop,
        owner: selectDropdown.owner,
        stall: selectDropdown.stall,
        start_date: selectDropdown?.start_date,
        end_date: selectDropdown?.end_date
      }
      const res = await apiGet({
        url: 'inspeksi-service/admin/inspection-unit/results',
        token,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    },
    onError: (e) => {
      console.log(e)
    }
  })

  const dropdownOwner = useQuery({
    queryKey: ['dropdown-data-owner', search.owner],
    queryFn: async () => {
      const res = await apiGet({
        url: `inspeksi-service/admin/inspection-unit/customers/all`,
        token: token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const dropdownWorkshop = useQuery({
    queryKey: ['dropdown-data-workshop', search.workshop],
    queryFn: async () => {
      let params = {
        keywords: search.workshop,
        offset: 0,
        limit: 10
      }
      const res = await apiGet({
        url: `${urls.master.prefix}/${urls.master.workshops.all}`,
        token: token,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const dropdownStall = useQuery({
    queryKey: ['dropdown-data-stall', search.stall],
    queryFn: async () => {
      let params = {
        keywords: search.stall,
        offset: 0,
        limit: 10
      }
      const res = await apiGet({
        url: `${urls.master.prefix}/${urls.master.stall}`,
        token: token,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item>Hasil Inspeksi</Breadcrumb.Item>
      </Breadcrumb>
      <HasilInspeksiComponent
        isLoading={isLoading}
        columns={columns}
        data={tableState?.listData}
        tableState={tableState}
        setTableState={setTableState}
        dropdownWorkshop={dropdownWorkshop?.data?.resData?.data}
        dropdownOwner={dropdownOwner?.data?.resData?.data}
        dropdownStall={dropdownStall?.data?.resData?.data}
        searchDropdown={search}
        onSearchDropdown={setSearch}
        selectDropdown={selectDropdown}
        setSelectDropdown={setSelectDropdown}
      />
    </Layout>
  )
}

export default HasilInspeksi
