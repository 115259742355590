import { Breadcrumb, Divider, Image, Layout } from 'antd'
import { CardContainer } from 'components'
import React from 'react'
import Car1 from 'assets/images/Car1.png'
const DetailSparepartPage = () => {
  return (
    <Layout className="grid grid-cols-1 gap-6 p-8" style={{ background: '#F5F5F5', width: '100%' }}>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Workshop Management
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Birdmobil Surabaya
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Sparepart
        </Breadcrumb.Item>
      </Breadcrumb>

      <CardContainer className={'p-6 flex gap-6'}>
        {/* Image */}
        <div className="py-8 px-4 w-[150px] border-[1px] border-solid border-gray-200 rounded-lg">
          <Image className="object-center object-contain" src={Car1} alt={`pict0`} preview />
        </div>
        {/* Spek Produk */}
        <div className="grid w-full grid-cols-4 justify-between place-content-center">
          <div className="flex flex-col gap-1">
            <span className="text-lg-medium text-gray-900">SKU</span>
            <span className="text-md-regular text-gray-700">YYY-123</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-lg-medium text-gray-900">Nama Sparepart</span>
            <span className="text-md-regular text-gray-700">Exide</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-lg-medium text-gray-900">Harga</span>
            <span className="text-md-regular text-gray-700">Rp 100.000</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-lg-medium text-gray-900">Stock</span>
            <span className="text-md-regular text-gray-700">10</span>
          </div>
        </div>
      </CardContainer>
      {/* Deskripsi */}
      <CardContainer className={'grid grid-cols-1 gap-5'}>
        <span className="font-semibold text-gray-900 text-2xl">Deskripsi</span>
        <Divider className="m-0" />
        <div className="grid grid-cols-1 gap-3">
          <span>Keunggulan GS Hybrid:</span>
          <ol className="list-inside">
            <li>
              Minim perawatan menggunakan teknologi Hybrid sehingga penguapan air aki lebih rendah
              dan penambahan air aki lebih lama 4 sampai 5 kali dibandingkan aki konvensional.
            </li>
            <li>
              Siap pakai : Aki sudah terisi accu zuur standar pabrik dan sudah terisi setrum
              sehingga siap langsung dipakai.
            </li>
            <li>
              Indikator elektrolit: dilengkapi indikator elektrolit untuk mengetahui kondisi
              permukaan, isi air aki dan daya setrum aki.
            </li>
            <li>Didesain khusus untuk iklim tropis.</li>
          </ol>
          <span>Aplikasi mobil Untuk:</span>
          <span>
            Toyota Avanza (E/G/S) 2003-2009, Toyota Rush (G/S) 2006-2010, Toyota Starlet Series
            1996-1999, Toyota Etios Valco, Daihatsu Espass, Daihatsu Xenia (Mi/Li/Xi)
            2003-2009,Daihatsu Terios (TS/TX) 2006, Suzuki Carry Series (1976-2000), Suzuki Futura
            1990, Suzuki Real Van, Suzuki Katana, Mitsubishi T-120SS OLD 1972-1980, Mitsubishi L-300
            Series (1981-2004), Mitsubishi Mirage, Mitsubishi Outlander.
          </span>
        </div>
      </CardContainer>
    </Layout>
  )
}

export default DetailSparepartPage
