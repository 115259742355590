import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import LandingPageComponent from './component/landing-page-component'
import ModalLandingPage from './component/modal-landing-page'
import { Button } from 'components'
import { IcChevronDown, IcEdit, IcTrash } from 'assets/icons'
import IcChevronUp from 'assets/icons/IcChevronUp'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { logout } from 'store/actions'
import { imageDimension, showErrorMessage, showSuccessMessage } from 'utils'

const initialModal = {
  show: false,
  type: 'add',
  data: null,
  loading: false,
}

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  loading: true,
  listData: [],
}

const LandingPage = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [tableState, setTableState] = useState(initialTableState)
  const [modal, setModal] = useState({ ...initialModal })

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'banner-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        url: `${urls.contentManagement.landingPage.prefix}/${urls.contentManagement.landingPage.root}/paginate`,
        data: params,
        token: token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total,
        loading: false
      })
    }
  })

  const sendData = async (data) => {
    setModal(prev => ({
      ...prev,
      loading: true,
    }))
    try {
      let res
      let bannerSend = data.image_filename

      if (modal.type !== 'add' && modal?.data?.old_image === data?.image_filename) {
        bannerSend = null
      }
      const formDataBanner = new FormData()
      formDataBanner.append('image', bannerSend)

      if (modal.type !== 'add') {
        const id = data?.id
        res = await apiPut({
          url: `${urls.contentManagement.landingPage.prefix}/${urls.contentManagement.landingPage.root}/${id}`,
          data: formDataBanner,
          token: token,
          isFormData: true,
          onLogout: () => dispatch(logout())
        })
      } else {
        res = await apiPost({
          url: `${urls.contentManagement.landingPage.prefix}/${urls.contentManagement.landingPage.root}`,
          data: formDataBanner,
          token: token,
          isFormData: true,
          onLogout: () => dispatch(logout())
        })
      }
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({ content: `Successfully ${data?.id ? 'Edit' : 'Create a New'} Banner.` })
        setModal(prev => ({
          ...prev,
          show: false,
          loading: false,
        }))
      } else {
        showErrorMessage({
          content: `${res.resData.message ?? res.resData.errors[0].message ?? `Failed to ${data?.id ? 'Edit' : 'Create'} Banner.`
            }`
        })
        setTableState((prevState) => ({
          ...prevState,
          loading: false
        }))
        setModal((prevState) => ({
          ...prevState,
          loading: false
        }))
      }
      refetch()
    } catch (error) {
      setTableState((prevState) => ({
        ...prevState,
        loading: false
      }))
      setModal((prevState) => ({
        ...prevState,
        loading: false
      }))
    }
  }

  const validateBannerSend = async (data) => {
    const dimension = await imageDimension(data?.image_filename)
    const aspectRatio = dimension.width / dimension.height
    if (data?.image_filename === undefined) {
      showErrorMessage({ content: `Banner belum di upload` })
    } else if (!data?.image_filename?.type?.includes('image')) {
      showErrorMessage({ content: `Banner harus berupa gambar` })
    } else if ((data?.image_filename?.size / 1048576) > 10) {
      showErrorMessage({ content: `Banner harus kurang dari 10 mb` })
    } else if (aspectRatio.toFixed(2) != '1.78') {
      showErrorMessage({ content: `Banner harus berukuran 16:9` })
    } else {
      sendData(data)
    }
  }

  const saveData = (data) => {
    if (modal.type === 'add') {
      validateBannerSend(data)
    } else {
      const isNotChange = modal?.data?.old_image === data?.image_filename
      if (isNotChange) {
        sendData(data)
      } else {
        validateBannerSend(data)
      }
    }
  }

  const deleteData = async (data) => {
    try {
      setTableState((prevState) => ({
        ...prevState,
        loading: true
      }))
      let res
      res = await apiDelete({
        url: `${urls.contentManagement.landingPage.prefix}/${urls.contentManagement.landingPage.root}/${data?.id}`,
        token
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({ content: 'Successfully Delete Banner.' })
        setModal(prev => ({
          ...prev,
          show: false,
          loading: false,
        }))
        refetch()
      } else {
        showErrorMessage({
          content: `${res.resData.message ?? res.resData.errors[0].message ?? 'Failed to Delete Banner.'
            }`
        })
      }
    } catch (error) {
      setTableState((prevState) => ({
        ...prevState,
        loading: false
      }))
    }
  }

  const handleOrderBanner = async (id, direction) => {
    try {
      setTableState((prevState) => ({
        ...prevState,
        loading: true
      }))
      let res
      res = await apiPut({
        url: `${urls.contentManagement.landingPage.prefix}/${urls.contentManagement.landingPage.root}/${id}/${direction}`,
        token
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({ content: 'Successfully Upate Banner Order.' })
        setModal(prev => ({
          ...prev,
          show: false,
          loading: false,
        }))
        refetch()
      } else {
        showErrorMessage({
          content: `${res.resData.message ?? res.resData.errors[0].message ?? 'Failed to Upate Banner Order.'
            }`
        })
      }
    } catch (error) {
      setTableState((prevState) => ({
        ...prevState,
        loading: false
      }))
    }
  }

  const handleAddBanner = () => {
    setModal(prev => ({
      ...prev,
      type: 'add',
      show: true,
      data: null,
    }))
  }

  const handleDeleteModal = (row) => {
    setModal(prev => ({
      ...prev,
      show: true,
      type: 'delete',
      data: row
    }))
  }

  const handleEditModal = (row) => {
    setModal(prev => ({
      ...prev,
      show: true,
      type: 'edit',
      data: {
        ...row,
        image_filename: `${process.env.REACT_APP_IMAGE_URL_GCS}/${row.image_filename}`,
        old_image: `${process.env.REACT_APP_IMAGE_URL_GCS}/${row.image_filename}`
      },
    }))
  }

  const handleCancel = () => {
    setModal({ ...initialModal })
  }

  const columns = [
    {
      name: 'Urutan',
      selector: (row) => row.order_no,
      cell: (row, index) => (
        <div className='flex justify-between w-full items-center'>
          <span className="font-medium text-gray-700">{row.order_no}</span>
          <div className='flex gap-2'>
            {/* down arrow */}
            {
              index === tableState?.listData?.length - 1 ? (
                <Button
                  className="bg-primary-500 border-primary-500 stroke-white opacity-50 cursor-default">
                  <IcChevronDown className="text-white " />
                </Button>
              ) : (
                <Button
                  className="bg-primary-500 border-primary-500 stroke-white"
                  onClick={() => handleOrderBanner(row.id, 'down')}>
                  <IcChevronDown className="text-white " />
                </Button>
              )
            }
            {/* up arrow */}
            {
              index === 0 ? (
                <Button
                  className="bg-primary-500 border-primary-500 stroke-white opacity-50 cursor-default">
                  <IcChevronUp className="text-white" />
                </Button>
              ) : (
                <Button
                  className="bg-primary-500 border-primary-500 stroke-white"
                  onClick={() => handleOrderBanner(row.id, 'up')}>
                  <IcChevronUp className="text-white" />
                </Button>
              )
            }
          </div>
        </div>
      ),
      width: '20%'
    },
    {
      name: 'Banner',
      selector: (row) => row.image_filename,
      cell: (row) => <img className='w-[85px] h-[48px] rounded-md my-3' src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${row.image_filename}`} />
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => handleEditModal(row)}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => handleDeleteModal(row)}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Content Management
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-500">
          Landing Page
        </Breadcrumb.Item>
      </Breadcrumb>
      <ModalLandingPage
        isOpen={modal.show}
        type={modal.type}
        data={modal.data}
        setModal={setModal}
        onSubmit={saveData}
        onCancel={handleCancel}
        onDelete={deleteData}
        isLoading={modal.loading}
      />
      <LandingPageComponent
        data={tableState.listData || []}
        columns={columns}
        handleAddBanner={handleAddBanner}
        tableState={tableState}
        setTableState={setTableState}
        isLoading={tableState.loading || isLoading}
      />
    </Layout>
  )
}

export default LandingPage
