import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button } from 'components'
import { useDispatch } from 'react-redux'
import { setServisStep1 } from 'store/actions'

const ServisMobilComponentStep1 = ({ data }) => {
  const router = useHistory()
  const dispatch = useDispatch()
  const checkingExist = (isExist) => {
    if (isExist) {
      return 'Ada'
    }
    return 'Tidak Ada'
  }
  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="flex">
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#FDE2D3] flex items-center justify-center mr-2">
              <Typography.Text className="text-primary-500 font-medium">1</Typography.Text>
            </div>
            <Typography.Text className="text-primary-500 font-medium w-fit">
              Data Unit
            </Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mr-2">
              <Typography.Text className="text-gray-500 font-medium">2</Typography.Text>
            </div>
            <Typography.Text className="text-gray-500 font-medium w-fit">
              Data Servis
            </Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mr-2">
              <Typography.Text className="text-gray-500 font-medium">3</Typography.Text>
            </div>
            <Typography.Text className="text-gray-500 font-medium w-fit">Jadwal</Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mr-2">
              <Typography.Text className="text-gray-500 font-medium">4</Typography.Text>
            </div>
            <Typography.Text className="text-gray-500 font-medium w-fit">
              Konfirmasi
            </Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-lg">Informasi Pemilik</Typography.Text>
        </div>
        <div className="flex">
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Nama</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {data?.unitOwner?.name}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Email</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {data?.unitOwner?.email}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">
              Nomor Telepon
            </Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {data?.unitOwner?.phone_number ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Alamat</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {data?.unitOwner?.address}
            </Typography.Text>
          </Typography>
        </div>
      </Card>
      <div className="flex mb-4">
        <Card className="flex-1 mr-1 rounded-lg">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Informasi Unit</Typography.Text>
          </div>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Merek</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.brand?.name}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Model</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.model?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Tipe</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.type?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Transmisi</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.transmission?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Tahun</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.production_name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Plat Nomor</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.police_number ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Warna</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.color?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Bahan Bakar</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.fuel?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Nomor Rangka</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.frame_number}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Nomor Mesin</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.engine_number}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Tag</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              SUV, Mobil Keluarga
            </Typography.Text>
          </Typography>
        </Card>
        <Card className="flex-1 ml-1 rounded-lg">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Dokumen</Typography.Text>
          </div>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">STNK</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_stnk_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">BPKB</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_bpkb_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Faktur</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_faktur_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">KTP Pemilik</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_ktp_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Kwitansi</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_kwitansi_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">KEUR</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_keur_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Form A</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_form_status)}
            </Typography.Text>
          </Typography>
        </Card>
      </div>
      <div className="flex float-right items-center mb-1">
        <Button
          className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => router.go(-1)}>
          Kembali
        </Button>
        <Button
          className="p-0 px-4 py-2 bg-primary-500 border font-medium text-white hover:opacity-90 border-primary-500"
          onClick={async () => {
            await dispatch(
              setServisStep1(data?.id, {
                full_name: data?.unitOwner?.name,
                phone: data?.unitOwner?.phone_number ?? '-',
                email: data?.unitOwner?.email,
                address: data?.unitOwner?.address
              })
            )
            router.push(`/internal-unit/${data?.id}/servis-mobil`, { step: 2, data })
          }}>
          Selanjutnya
        </Button>
      </div>
    </Content>
  )
}

export default ServisMobilComponentStep1
