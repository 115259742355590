import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { IcEdit, IcTrash } from 'assets/icons'
import { Button, ModalDelete } from 'components'
import GradeInspeksiComponent from './components/GradeInspeksiComponent'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import ModalCreateUpdateGradeInspeksi from './components/ModalCreateUpdateGradeInspeksi'
import { showErrorMessage, showSuccessMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const GradeInspeksi = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const router = useHistory()
  const [modalDelete, setModalDelete] = useState({
    show: false,
    data: {}
  })
  const [modalCreateUpdate, setModalCreateUpdate] = useState({
    show: false,
    type: 'create',
    data: {}
  })
  const [tableState, setTableState] = useState(initialTableState)
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  const columns = [
    {
      name: 'Grade',
      selector: (row) => row?.grade,
      sortable: true
    },
    {
      name: 'Rentang',
      selector: (row) => [row.start_grade, row.end_grade],
      cell: (row) => (
        <span className="font-medium text-gray-700">{`${row.start_grade} - ${row.end_grade}`}</span>
      ),
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
            onClick={() => setModalCreateUpdate({ show: true, type: 'update', data: row })}>
            <IcEdit className="text-gray-700" size={18} />
          </Button>
          <Button
            className="bg-white border-solid border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModalDelete({ show: true, data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'category-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        url: `${urls.master.inspection.prefix}/${urls.master.inspection.grade.root}`,
        data: params,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Master Data</Breadcrumb.Item>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item>Grade Inspeksi</Breadcrumb.Item>
      </Breadcrumb>
      {modalDelete.show ? (
        <ModalDelete
          minHeight="min-h-[20%]"
          minWidth="max-w-[22%]"
          maxWidth="max-w-[22%]"
          title="Hapus Grade Inspeksi"
          desc={`Apakah Anda yakin ingin menghapus grade inspeksi [${modalDelete.data.grade}] ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
          onClose={() => setModalDelete({ ...modalDelete, show: false })}
          onSubmit={() => {
            setIsLoadingHelper(true)
            const mount = setTimeout(async () => {
              const res = await apiDelete({
                url: `${urls.master.inspection.prefix}/${urls.master.inspection.grade.root}/${modalDelete.data.id}`,
                token,
                onLogout: () => dispatch(logout())
              })
              if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                refetch()
                showSuccessMessage({ content: 'Successfully Delete Grade Inspeksi.' })
              } else {
                showErrorMessage({
                  content: res.resData.errors[0].message ?? 'Faild to Delete Grade Inspeksi.'
                })
              }
              setIsLoadingHelper(false)
              setModalDelete({ ...modalDelete, show: false })
            })
            return () => clearTimeout(mount)
          }}
        />
      ) : null}
      {modalCreateUpdate.show ? (
        <ModalCreateUpdateGradeInspeksi
          state={modalCreateUpdate}
          onClose={() => setModalCreateUpdate({ ...modalCreateUpdate, show: false })}
          onSubmit={(values) => {
            let res
            setIsLoadingHelper(true)
            const mount = setTimeout(async () => {
              if (modalCreateUpdate.type === 'create') {
                res = await apiPost({
                  url: `${urls.master.inspection.prefix}/${urls.master.inspection.grade.root}`,
                  token,
                  data: {
                    grade: values.grade,
                    start_range: values.start_grade,
                    end_range: values.end_grade
                  },
                  onLogout: () => dispatch(logout())
                })
              } else {
                res = await apiPut({
                  url: `${urls.master.inspection.prefix}/${urls.master.inspection.grade.root}/${modalCreateUpdate.data.id}`,
                  token,
                  data: {
                    grade: values.grade,
                    start_range: values.start_grade,
                    end_range: values.end_grade
                  },
                  onLogout: () => dispatch(logout())
                })
              }
              if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                refetch()
                if (modalCreateUpdate.type === 'create') {
                  showSuccessMessage({ content: 'Successfully Create Grade Inspeksi.' })
                } else {
                  showSuccessMessage({ content: 'Successfully Update Grade Inspeksi.' })
                }
              } else {
                showSuccessMessage({
                  content:
                    res.resData.errors[0].message ?? modalCreateUpdate.type === 'create'
                      ? 'Failed to Create Grade Inspeksi.'
                      : 'Failed to Update Grade Inspeksi.'
                })
              }
              setIsLoadingHelper(false)
              setModalCreateUpdate({ ...modalCreateUpdate, show: false })
            })
            return () => clearTimeout(mount)
          }}
        />
      ) : null}
      <GradeInspeksiComponent
        isLoading={isLoading ?? isLoadingHelper}
        onCreateGrade={() => setModalCreateUpdate({ show: true, type: 'create' })}
        columns={columns}
        data={tableState?.listData}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}

export default GradeInspeksi
