import { Breadcrumb } from 'antd'
import { pathStringify } from 'utils/pathStringify'

export const DynamicBreadcrumbItem = (rootMenu, pathLength) => {
  rootMenu = rootMenu.substring(1, pathLength).split('/')
  const parentBreadcrumbStyle = 'font-semibold text-gray-700'
  const childBreadcrumbStyle = 'font-medium text-gray-500'
  return rootMenu.map((item, index) => (
    <Breadcrumb.Item
      key={index}
      className={`${index === 0 ? `${parentBreadcrumbStyle}` : `${childBreadcrumbStyle}`}`}>
      {pathStringify(item)}
    </Breadcrumb.Item>
  ))
}
