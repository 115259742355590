import React, { useState } from 'react'
import { BaseIcon, FormDropDown, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'
import { showErrorMessage } from 'utils'

const ModalCreateUpdateDistrict = ({ minHeight, onClose, onSubmit, dropDownProvince, state }) => {
  const [selectProvince, setSelectProvince] = useState(
    state.type === 'update' ? state.provinceId : null
  )
  const [selectCity, setSelectCity] = useState(state.type === 'update' ? state.cityName : '')
  const [isEmptyProvince, setIsEmptyProvince] = useState(false)
  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.type === 'create' ? 'Tambah' : 'Edit'} Lokasi`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              provinceSelector: selectProvince,
              cityName: selectCity
            }}
            onSubmit={(values) => {
              if (
                values?.provinceSelector === '' ||
                !values?.provinceSelector ||
                values?.cityName === '' ||
                !values?.cityName
              ) {
                showErrorMessage({ content: 'Provinsi wajib diisi' })
              } else {
                onSubmit(values)
              }
            }}>
            <Form key="createUpdateDistrictForm">
              <div className="flex flex-col w-full space-y-5">
                <FormDropDown
                  isSearch
                  allowClear
                  label="Provinsi"
                  placeholder="Pilih Provinsi"
                  name="provinceSelector"
                  isRequired
                  data={dropDownProvince}
                  isEmpty={isEmptyProvince}
                  errorMessage={isEmptyProvince ? 'Silahkan Pilih Provinsi' : null}
                  onChange={(value) => {
                    if (!value || value === 'Pilih Provinsi') {
                      setIsEmptyProvince(true)
                    } else {
                      setIsEmptyProvince(false)
                    }
                    setSelectProvince(value ? value : null)
                  }}
                />
                <FormInput
                  label="Kota/Kabupaten"
                  name="cityName"
                  placeholder="Masukkan Nama Kota/Kabupaten"
                  isRequired
                  onValidate={(value) => {
                    if (!value) {
                      return 'Silahkan Masukkan Nama Kota/Kabupaten'
                    }
                    setSelectCity(value)
                  }}
                />
                <div className="flex flex-row w-full items-center justify-end space-x-4">
                  <button
                    type="button"
                    className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                    onClick={() => onClose()}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                    {`${state.type === 'create' ? 'Tambah Lokasi' : 'Simpan'}`}
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateDistrict
