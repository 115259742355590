import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { IcEdit } from 'assets/icons'
import { CardContainer, Loading } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { showErrorMessage } from 'utils'
import ModalEditProfile from './components/ModalEditProfile'

const ProfileDetail = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [profile, setProfile] = useState({})
  const [modalEditProfile, setModalEditProfile] = useState({
    show: false,
    data: {}
  })

  // API
  // Get Detail
  const { isLoading: isLoadingDetail, refetch: refetchDetail } = useQuery({
    queryKey: ['detail-data'],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const res = await apiGet({
        token: token,
        url: `${urls?.jualUnit?.prefix}/${urls?.jualUnit?.contactBirdmobil}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      if (res?.resStatus === 200) {
        // console.log('Profile', res?.resData?.data)
        setProfile(res?.resData?.data ?? {})
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    }
  })

  if (isLoadingDetail) {
    return <Loading visible />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Profile Perusahaan
        </Breadcrumb.Item>
      </Breadcrumb>
      <ModalEditProfile
        onClose={() => setModalEditProfile((prev) => ({ ...prev, show: false, data: {} }))}
        isOpen={modalEditProfile?.show}
        data={modalEditProfile?.data}
        token={token}
        refetch={refetchDetail}
        onLogout={onLogout}
      />
      <Content style={{ margin: 8 }}>
        <div className="flex flex-col w-full space-y-5">
          <CardContainer>
            <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300 justify-between">
              <span className="font-semibold text-2xl text-gray-900">
                Informasi Profile Perusahaan
              </span>
              <div
                onClick={() =>
                  setModalEditProfile((prev) => ({ ...prev, show: true, data: profile }))
                }
                className="flex flex-row items-center justify-center space-x-2 rounded-md py-2 px-3 border border-solid border-gray-300 bg-white hover:cursor-pointer">
                <IcEdit className="text-gray-700" />
                <span className="font-medium text-gray-700">Edit</span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 w-full pt-4">
              <div className="flex flex-col">
                <span className="font-medium text-gray-700 text-lg">Nama Perusahaan</span>
                <span className="font-normal text-gray-500 text-md">{profile?.name}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-gray-700 text-lg">Email</span>
                <span className="font-normal text-gray-500 text-md">{profile?.email}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-gray-700 text-lg">Nomor Telepon</span>
                <span className="font-normal text-gray-500 text-md">{profile?.phone_number}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-gray-700 text-lg">Alamat</span>
                <span className="font-normal text-gray-500 text-md">{profile?.address}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-gray-700 text-lg">Provinsi</span>
                <span className="font-normal text-gray-500 text-md">{profile?.province_name}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-gray-700 text-lg">Kota/Kabupaten</span>
                <span className="font-normal text-gray-500 text-md">{profile?.city_name}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-gray-700 text-lg">Kecamatan</span>
                <span className="font-normal text-gray-500 text-md">
                  {profile?.subdistrict_name}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-gray-700 text-lg">Kelurahan/Desa</span>
                <span className="font-normal text-gray-500 text-md">{profile?.location_name}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-gray-700 text-lg">Kode POS</span>
                <span className="font-normal text-gray-500 text-md">{profile?.postal_code}</span>
              </div>
            </div>
          </CardContainer>
        </div>
      </Content>
    </Layout>
  )
}

export default ProfileDetail
