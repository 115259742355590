import { Breadcrumb, Input, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { IcEye, IcSearch } from 'assets/icons'
import { CardContainer, Table } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null,
  province_id: '',
  city_id: ''
}

const PickupServiceListPage = () => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])

  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const columns = [
    {
      name: 'Nama Bengkel',
      selector: (row) => row?.name,
      cell: (row) => row?.name,
      sortable: true
    },
    {
      name: 'Provinsi',
      selector: (row) => row?.province_name,
      // width: '150px',
      cell: (row) => row?.province_name,
      sortable: true
    },
    {
      name: 'Cabang',
      selector: (row) => row?.city_name,
      // width: '150px',
      cell: (row) => row?.city_name,
      sortable: true
    },
    {
      name: 'Request',
      selector: (row) => row?.request_count,
      // width: '150px',
      cell: (row) => row?.request_count,
      sortable: true
    },
    {
      name: 'Terkonfirmasi',
      selector: (row) => row?.confirmed_count,
      // width: '150px',
      cell: (row) => row?.confirmed_count,
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          onClick={() => router.push(`/services/pickup/detail/${row?.id}`)}
          className="flex flex-row items-center justify-center self-center space-x-1 px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
          <IcEye className="text-gray-700" size={19} />
          <span className="font-medium text-gray-700">Lihat</span>
        </div>
      )
    }
  ]

  // Get API
  // Get All List Pickup
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'pickup-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.province_id,
      tableState.city_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.pickupSchedule}?keywords=${tableState.keywords}&province_id=${tableState?.province_id}&city_id=${tableState?.city_id}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Provinces
  useQuery({
    queryKey: ['province-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.location?.province?.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setProvinces(res?.resData?.data ?? [])
    }
  })

  // Get City by province
  useQuery({
    queryKey: ['cities-list', tableState.province_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (tableState.province_id !== '' && tableState.province_id !== null) {
        const res = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.cities?.filterByProvince}/${tableState.province_id}`,
          onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setCities(res?.resData?.data ?? [])
    }
  })

  // *END API

  // *Table State
  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }
  // *End

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">Servis</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Pickup Servis
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <span className="font-semibold text-2xl text-gray-900">Daftar Cabang</span>
            <div className="flex flex-col space-y-5">
              <div className="flex justify-between">
                <div className="flex">
                  <Select
                    className="custom-table-limit mr-3"
                    style={{ minWidth: '150px' }}
                    value={tableState?.province_id ?? ''}
                    onChange={(e) =>
                      setTableState((prev) => ({
                        ...prev,
                        province_id: e ?? '',
                        city_id: '',
                        offset: 0
                      }))
                    }
                    showSearch
                    allowClear
                    filterOption={(input, option) => {
                      return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }}>
                    <Select.Option className="custom-table-limit-option" value="">
                      Semua Provinsi
                    </Select.Option>
                    {provinces?.map((province, idx) => (
                      <Select.Option key={idx} value={province?.id}>
                        {province?.name}
                      </Select.Option>
                    ))}
                  </Select>
                  <Select
                    className="custom-table-limit"
                    style={{ minWidth: '150px' }}
                    value={tableState?.city_id ?? ''}
                    onChange={(e) => setTableState((prev) => ({ ...prev, city_id: e ?? '' }))}
                    showSearch
                    allowClear
                    filterOption={(input, option) => {
                      return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }}>
                    <Select.Option className="custom-table-limit-option" value="">
                      Semua Cabang
                    </Select.Option>
                    {cities?.map((city, idx) => (
                      <Select.Option key={idx} value={city?.id}>
                        {city?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <Input
                  className="w-1/4 rounded-md"
                  placeholder="Pencarian"
                  prefix={<IcSearch className="text-gray-400" />}
                  onChange={(e) =>
                    setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
                  }
                />
              </div>
              <Table
                columns={columns}
                data={tableState?.listData}
                pending={isLoading ?? isFetching}
                totalRows={tableState?.totalRows}
                handlePerRowsChange={handlePerRowsChange}
                pageSize={tableState?.limit}
                page={tableState?.offset}
                onChangePrevPage={() => {
                  if (tableState?.offset > 0) {
                    onChangePage(tableState?.offset - tableState?.limit)
                  }
                }}
                onChangeNextPage={() => {
                  if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                    onChangePage(tableState?.offset + tableState?.limit)
                  }
                }}
              />
            </div>
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default PickupServiceListPage
