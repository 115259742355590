import React from 'react'
import CardContainer from 'components/CardContainer'
import { BadgeItem, CustomButton } from 'components'
import { IcPlus } from 'assets/icons'
import { formatCurrency } from 'utils'
import { Collapse, Typography } from 'antd'
import ChevronDownIcon from 'assets/icons/chevron-down'
import ChevronRightIcon from 'assets/icons/chevron-right'
import XCircleIcon from 'assets/icons/x-circle'
const { Panel } = Collapse
const ServiceListCard = ({
  onTambahServis,
  onDetailRekomendasiServis,
  data,
  dataRekomendasiServis,
  onDeleteService,
  disabled = false
}) => {
  return (
    <CardContainer isHeightFull width="90%">
      <div className="flex flex-row w-full items-center justify-between border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <div className="flex gap-2">
          <span className="font-semibold text-2xl text-gray-900">Daftar Servis</span>
          {data?.ownership == 'Mobil Internal' ? (
            <BadgeItem type={'bluelight'}>Internal Unit</BadgeItem>
          ) : null}
        </div>
        {data?.ownership !== 'Mobil Internal' ? (
          <CustomButton onClick={onTambahServis} disabled={disabled}>
            <div className="flex flex-row items-center justify-center space-x-2">
              <IcPlus className="text-white" />
              <span>Tambah Servis</span>
            </div>
          </CustomButton>
        ) : null}
      </div>
      <div className="flex flex-col space-y-5 mt-4">
        {/* List Services */}
        {data?.packets?.map((packet, key) => (
          <Collapse
            key={packet.packet_id}
            accordion
            bordered={false}
            className="text-lg-medium flex flex-col gap-2 text-gray-900 rounded-[4px] bg-white"
            expandIconPosition={'start'}
            expandIcon={(props) => {
              return props.isActive ? (
                <ChevronDownIcon className={'stroke-gray-400 w-3 h-3'} />
              ) : (
                <ChevronRightIcon className={'stroke-gray-400 w-3 h-3'} />
              )
            }}>
            <Panel
              style={{ border: '1px solid #EAECF0' }}
              className="bg-gray-50"
              header={headerContentCollapse(
                packet.packet_id,
                packet.packet_name,
                packet.price,
                'DEFAULT',
                () => {
                  onDeleteService(packet)
                },
                'packet',
                packet?.spareparts
                  ?.map((general_service) => general_service?.general_service_name)
                  .join(', ')
              )}
              key={key}>
              {packet?.spareparts?.length > 0 && (
                <div>{renderPacketServiceCollapse(packet?.spareparts)}</div>
              )}
            </Panel>
          </Collapse>
        ))}
        {data?.general_services?.map(
          (service, key) =>
            service?.status !== 'CANCELED' && (
              <Collapse
                key={service?.id}
                accordion
                bordered={false}
                className="text-lg-medium flex flex-col gap-2 text-gray-900 rounded-[4px] bg-white"
                expandIconPosition={'start'}
                expandIcon={(props) => {
                  return props.isActive ? (
                    <ChevronDownIcon className={'stroke-gray-400'} />
                  ) : (
                    <ChevronRightIcon className={'stroke-gray-400'} />
                  )
                }}>
                <Panel
                  style={{ border: '1px solid #EAECF0' }}
                  className="bg-gray-50 flex flex-col gap-2"
                  header={headerContentCollapse(
                    service?.id,
                    service?.general_service_name,
                    service.price_total,
                    service?.status,
                    () => {
                      onDeleteService(service)
                    }
                  )}
                  key={key}>
                  {renderGeneralServiceCollapse(service)}
                </Panel>
              </Collapse>
            )
        )}
        <hr />
        <div className="flex justify-between items-center">
          <div className="flex flex-col justify-center items-start">
            <span className="text-md-regular text-gray-700">Biaya Default</span>
            <span className="text-lg-regular text-gray-700">
              {formatCurrency(dataRekomendasiServis?.price_total_before ?? 0)}
            </span>
          </div>
          <div className="flex flex-col justify-center items-start">
            {dataRekomendasiServis?.recommendation_services?.length > 0 && (
              <>
                <span className="text-md-regular text-gray-700">Biaya Setelah Rekomendasi</span>
                <div className="flex gap-2 items-center">
                  <span className="text-lg-medium text-gray-900">
                    {formatCurrency(dataRekomendasiServis?.grand_price_total ?? 0)}
                  </span>
                  <span
                    onClick={() => {
                      onDetailRekomendasiServis()
                    }}
                    className="text-primary-600 hover:text-primary-800 text-sm-medium cursor-pointer">
                    Detail
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </CardContainer>
  )
}

function headerContentCollapse(
  id,
  titleName,
  price,
  status,
  onCancel,
  packetType,
  generalPacketList = ''
) {
  const typeBadge = (status) => {
    if (status === 'DEFAULT') {
      return 'plain'
    } else if (status === 'APPROVED') {
      return 'success'
    } else if (status === 'WAITING_APPROVAL') {
      return 'warning'
    } else if (status === 'REJECTED' || status === 'CANCELED') {
      return 'danger'
    } else {
      return 'plain'
    }
  }

  const typeText = (status) => {
    if (status === 'DEFAULT') {
      return 'Default'
    } else if (status === 'APPROVED') {
      return 'Approved'
    } else if (status === 'WAITING_APPROVAL') {
      return 'Menunggu Approval'
    } else if (status === 'REJECTED') {
      return 'Rejected'
    } else if (status === 'CANCELED') {
      return 'Canceled'
    } else {
      return status
    }
  }
  return (
    <div key={id} className="flex xl:gap-4 p-1 bg-gray-50">
      {/* content */}
      <div className="grid grid-cols-1 w-full gap-0">
        <div className="flex justify-between items-center text-xs-medium xl:text-sm-medium text-gray-700">
          {packetType === 'packet' ? (
            <Typography.Text
              className="text-gray-500 flex gap-2 truncate "
              ellipsis={{
                tooltip: generalPacketList
              }}>
              <span className="text-gray-700">{titleName} </span>- {generalPacketList}
            </Typography.Text>
          ) : (
            <span>{titleName}</span>
          )}
          <div className="flex gap-4 items-center">
            <span className="whitespace-nowrap text-right">{formatCurrency(price ?? 0)}</span>

            <BadgeItem type={typeBadge(status)}>{typeText(status)}</BadgeItem>
          </div>
        </div>
      </div>

      {/* remove button */}
      {status === 'WAITING_APPROVAL' && (
        <div
          className="w-[20px]  cursor-pointer text-center flex justify-center items-center"
          onClick={(e) => {
            onCancel()
            e.stopPropagation()
          }}>
          <XCircleIcon className="stroke-gray-400" />
        </div>
      )}
    </div>
  )
}
function renderPacketServiceCollapse(general_services) {
  let layananList = []
  let sparepartList = []
  general_services?.map((item) => {
    if (item?.sparepart_name !== '') {
      sparepartList.push({
        label: item.sparepart_name,
        price: item.sparepart_price * item.quantity,
        quantity: item.quantity,
        total: item.sparepart_price_total,
        discounts: item?.voucher_sparepart_detail !== null ? item?.voucher_sparepart_detail : []
      })
    }
    layananList.push({
      label: item.general_service_name,
      price: item.class_price + item.service_price,
      quantity: 0,
      total: item.service_price_total,
      discounts: item?.voucher_service_detail !== null ? item?.voucher_service_detail : []
    })
  })
  return (
    <>
      <div className="text-xs-regular text-gray-500 pb-2">
        {sparepartList?.length > 0 && (
          <>
            <p className="text-xs-medium">Sparepart</p>
            {sparepartList?.map((item, key) => (
              <div key={key} className="grid grid-cols-[0.8fr_30px_0.5fr_0.5fr]">
                <p>{item?.label}</p>
                <div className="flex justify-center ">x{item?.quantity}</div>
                {
                  <div className="flex flex-col gap-1 text-primary-600 text-xs-medium">
                    {item?.discounts?.length > 0
                      ? item?.discounts?.map((el, i) => (
                          <p key={i}>
                            {el.price_total > 0
                              ? `- ${
                                  el.discount_type === 'PERCENT'
                                    ? `(${el?.discount}%)`
                                    : `${formatCurrency(el.price_total)}`
                                }`
                              : ''}
                          </p>
                        ))
                      : ''}
                  </div>
                }
                <p className="text-right"> {formatCurrency(item?.total)}</p>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="text-xs-regular text-gray-500 pb-2">
        <p className="text-xs-medium">Layanan</p>
        {layananList.map((item, key) => (
          <div key={key} className="grid grid-cols-[0.8fr_30px_0.5fr_0.5fr]">
            <p>{item?.label}</p>
            <span></span>
            {
              <div className="flex flex-col gap-1 text-primary-600 text-xs-medium">
                {item?.discounts?.length > 0
                  ? item?.discounts?.map((el, i) => (
                      <p key={i}>
                        {el.price_total > 0
                          ? `- ${
                              el.discount_type === 'PERCENT'
                                ? `(${el?.discount}%)`
                                : `${formatCurrency(el.price_total)}`
                            }`
                          : ''}
                      </p>
                    ))
                  : ''}
              </div>
            }
            <p className="text-right"> {formatCurrency(item?.total)}</p>
          </div>
        ))}
      </div>
    </>
  )
}
function renderGeneralServiceCollapse(service) {
  return (
    <div>
      <>
        <div className="text-xs-regular text-gray-500 pb-2">
          {service?.sparepart_name !== '' && (
            <>
              <p className="text-xs-medium">Sparepart</p>
              <div key={service?.id} className="grid grid-cols-[0.8fr_30px_0.5fr_0.5fr]">
                <p>{service?.sparepart_name}</p>
                <div className="flex justify-center">x{service?.quantity}</div>
                <div className="flex flex-col gap-1 text-primary-600 text-xs-medium">
                  {service?.voucher_sparepart_detail?.length > 0 ||
                  service?.voucher_sparepart_detail !== null
                    ? service?.voucher_sparepart_detail.map((el, i) => (
                        <p key={i}>
                          {el.price_total > 0
                            ? `- ${
                                el.discount_type === 'PERCENT'
                                  ? `(${el?.discount}%)`
                                  : `${formatCurrency(el.price_total)}`
                              }`
                            : ''}
                        </p>
                      ))
                    : ''}
                </div>
                <p className="text-right">{formatCurrency(service?.sparepart_price_total)}</p>
              </div>
            </>
          )}
        </div>
        <div className="text-xs-regular text-gray-500 pb-2">
          <p className="text-xs-medium">Layanan</p>
          <div className="grid grid-cols-[0.8fr_30px_0.5fr_0.5fr]">
            <p>{service?.general_service_name}</p>
            <span></span>
            {
              <div className="flex flex-col gap-1 text-primary-600 text-xs-medium">
                {service?.voucher_service_detail?.length > 0 ||
                service?.voucher_sparepart_detail !== null
                  ? service?.voucher_service_detail?.map((el, i) => (
                      <div key={i}>
                        {el.price_total > 0
                          ? `- ${
                              el.discount_type === 'PERCENT'
                                ? `(${el?.discount}%)`
                                : `${formatCurrency(el.price_total)}`
                            }`
                          : ''}
                      </div>
                    ))
                  : ''}
              </div>
            }
            <p className="text-right">{formatCurrency(service?.service_price_total)}</p>
          </div>
        </div>
      </>
    </div>
  )
}

export default ServiceListCard
