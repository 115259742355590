import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { IcEdit, IcTrash } from 'assets/icons'
import { Button } from 'components'
import { useQuery } from 'react-query'
import { sparepartUnitList } from 'assets/dummies/sparepart-unit'
import { useHistory } from 'react-router-dom'
import UpdateSparepartUnitComponent from './components/UpdateSparepartUnitComponent'
import { apiGet, apiPost, apiPut } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import ModalSparepart from './components/ModalSparepart'
import { showErrorMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const UpdateSparepartUnit = () => {
  const [tableState, setTableState] = useState(initialTableState)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState({
    brand: '',
    model: '',
    type: ''
  })
  const [forms, setForms] = useState({
    brand_id: null,
    model_id: null,
    type_id: null,
    unit_spareparts: []
  })
  const router = useHistory()
  const type = router?.location?.state?.type ?? 'add'
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: null
  })
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const columns = [
    {
      name: 'Nama Sparepart',
      selector: (row) => row?.name,
      sortable: true
    },
    // {
    //   name: 'Kategori',
    //   selector: (row) => row?.brand,
    //   sortable: true
    // },
    {
      name: 'Jumlah',
      selector: (row) => row?.qty,
      sortable: true
    },
    {
      name: 'Satuan',
      selector: (row) => row?.uom,
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() =>
              setModal({
                ...modal,
                show: true,
                data: row,
                type: 'edit'
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() =>
              setModal({
                ...modal,
                show: true,
                data: row,
                type: 'delete'
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const { isLoading, refetch, data } = useQuery({
    queryKey: ['sparepart-unit-data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = null
      if (router?.location?.state?.data?.id) {
        setSearch({
          model: router?.location?.data?.model_name,
          brand: router?.location?.data?.brand_name,
          type: router?.location?.data?.type_name
        })
        res = await apiGet({
          url: `servis-service/admin/units/${router?.location?.state?.data?.id}`,
          token,
          onLogout: () => dispatch(logout())
        })
        res = res?.resData?.data
        let unit_spareparts = res?.unit_spareparts?.map((unit) => {
          return {
            id: unit?.id,
            sparepart_id: unit?.sparepart_id,
            name: unit?.sparepart.name,
            qty: unit.qty,
            uom: unit.uom
          }
        })
        setForms({
          brand_id: res?.brand_id,
          model_id: res?.model_id,
          type_id: res?.type_id,
          unit_spareparts: unit_spareparts
        })
      }
      return res
    }
  })

  const brand = useQuery({
    queryKey: ['brand-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: 'master-service/admin/brands/all',
        onLogout: () => dispatch(logout())
      })
      return res
    }
  })

  const model = useQuery({
    queryKey: ['model-list', forms.brand_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        brand_id: forms.brand_id,
        offset: 0,
        limit: 10
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/models/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const typeList = useQuery({
    queryKey: ['type-unit', forms.model_id, forms.brand_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let params = {
        brand_id: forms.brand_id,
        model_id: forms.model_id
      }
      const res = await apiGet({
        token,
        data: params,
        url: `master-service/admin/types/all`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading && !brand.isLoading && !model.isLoading
  })

  const saveData = async () => {
    setLoading(true)
    try {
      let res
      let body = {
        ...forms
      }

      if (data?.id) {
        const id = data?.id
        res = await apiPut({
          url: `servis-service/admin/units/${id}`,
          data: body,
          token,
          onLogout: () => dispatch(logout())
        })
      } else {
        res = await apiPost({
          url: 'servis-service/admin/units',
          data: body,
          token,
          onLogout: () => dispatch(logout())
        })
      }
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        router.goBack()
        refetch()
        setLoading(false)
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Sparepart</Breadcrumb.Item>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => router.goBack()}>
          Sparepart Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item>Tambah Sparepart Unit</Breadcrumb.Item>
      </Breadcrumb>
      <ModalSparepart
        isOpen={modal.show}
        type={modal.type}
        onSubmit={(data) => {
          setForms((prev) => ({
            ...prev,
            unit_spareparts: forms?.unit_spareparts?.map?.((item) => {
              if (item?.sparepart_id === modal?.data?.sparepart_id) {
                return {
                  ...item,
                  qty: parseInt(data?.qty),
                  uom: data?.uom
                }
              } else {
                return item
              }
            })
          }))
          setModal({
            ...modal,
            show: false,
            data: null
          })
        }}
        onDelete={() => {
          let newSparepartUnits = forms.unit_spareparts.filter(
            (data) => modal?.data?.sparepart_id !== data?.sparepart_id
          )
          setForms((prevState) => {
            return {
              ...prevState,
              unit_spareparts: newSparepartUnits
            }
          })
          setModal({
            ...modal,
            show: false,
            data: null
          })
        }}
        data={modal.data}
        onCancel={() => setModal({ ...modal, show: false, data: null })}
      />
      <UpdateSparepartUnitComponent
        isLoading={isLoading || loading}
        columns={columns}
        type={type}
        brandList={brand?.data?.resData?.data}
        modelList={model?.data?.resData?.data}
        typeList={typeList?.data?.resData?.data}
        forms={forms}
        setForms={setForms}
        setSearch={setSearch}
        // data={tableState?.listData}
        data={sparepartUnitList}
        onSave={saveData}
        onAdd={() => router.push('/sparepart/unit/add')}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}
export default UpdateSparepartUnit
