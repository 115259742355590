import { mdiPlus } from '@mdi/js'
import { BaseIcon } from 'components'
import React from 'react'

const HeaderContainerJualUnit = ({
  title,
  onPressAuditTrail,
  titleAdd,
  onPressAdd,
  isPressAdd
}) => (
  <div className="flex w-full flex-row items-center justify-between">
    <span className="font-semibold text-2xl text-gray-900">{title}</span>
    <div className="flex flex-row space-x-4 items-center justify-center">
      {onPressAuditTrail && (
        <button
          className="flex flex-row items-center justify-center rounded-md border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white hover:cursor-pointer"
          onClick={() => onPressAuditTrail()}>
          <span>Audit Trail</span>
        </button>
      )}
      {isPressAdd ? (
        <button
          className="flex flex-row items-center justify-around rounded-md border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500 hover:cursor-pointer"
          onClick={() => onPressAdd()}>
          <BaseIcon path={mdiPlus} iconSize={19} color="#FFFFFF" />
          <span>{titleAdd}</span>
        </button>
      ) : null}
    </div>
  </div>
)

export default HeaderContainerJualUnit
