import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { CardContainer, FormInput, HeaderContainer, Loading, ModalDelete, Table } from 'components'
import { Formik } from 'formik'
import { IcEdit, IcTrash } from 'assets/icons'
import { ModalCreateUpdateBahanBakar } from './components'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { metaInitialData, perPageInitialData } from 'constant'
import { logout } from 'store/actions'

const ProdukBahanBakarPage = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [fuelList, setFuelList] = useState()
  const [meta, setMeta] = useState(metaInitialData)
  const [currentPage, setCurrentPage] = useState(0)
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)

  const onLogout = () => dispatch(logout())

  const { isLoading, refetch } = useQuery({
    queryKey: ['fuel-list'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.fuel.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=0`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setFuelList(data.resData.data)
      setCurrentPage(0)
      setMeta(data.resData.meta)
    }
  })

  const [modalDelete, setModalDelete] = useState({
    status: false,
    fuelId: 0,
    title: '',
    desc: ''
  })

  const [modalCreateUpdate, setModalCreateUpdate] = useState({
    status: false,
    type: 'create',
    fuelId: 0,
    fuelName: ''
  })

  const [searchFuel, setSearchFuel] = useState('')
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const onHandleExtraUrl = () => {
    let extraUrl = ''
    if (searchFuel !== '') {
      extraUrl += `&keywords=${searchFuel}`
    }

    return extraUrl
  }

  const onChangePrevNextPage = (offset) => {
    setIsLoadingCRUD(true)
    setMeta({ ...meta, offset })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.fuel.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${offset}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setFuelList(response.resData.data)
      } else {
        refetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    setIsLoadingCRUD(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.master.prefix}/${
          urls.master.products.fuel.root
        }?limit=${value}&offset=0${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })

      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setFuelList(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        refetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const columns = [
    {
      name: 'Jenis Bahan Bakar',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              setModalCreateUpdate({
                status: true,
                type: 'update',
                fuelId: row.id,
                fuelName: row.name
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setModalDelete({
                status: true,
                fuelId: row.id,
                title: 'Hapus Bahan Bakar',
                desc: `Apakah anda yakin ingin menghapus bahan bakar [${row.name}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  if (isLoading ?? isLoadingCRUD) {
    return <Loading visible={true} />
  }

  return (
    <Layout
      style={{
        padding: 24,
        background: '#F5F5F5',
        width: '100%'
      }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Master Data
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Produk</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Bahan Bakar
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content className="h-screen" style={{ margin: 8 }}>
        {modalDelete.status ? (
          <ModalDelete
            minHeight="min-h-[20%]"
            minWidth="min-w-[20%]"
            maxWidth="max-w-[20%]"
            title={modalDelete.title}
            desc={modalDelete.desc}
            onClose={() => setModalDelete({ ...modalDelete, status: false })}
            onSubmit={() => {
              setIsLoadingCRUD(true)
              const mount = setTimeout(async () => {
                await apiDelete({
                  url: `${urls.master.prefix}/${urls.master.products.fuel.root}/${modalDelete.fuelId}`,
                  token: user.token,
                  onLogout
                })
                setModalDelete({ ...modalDelete, status: false })
                refetch()
                setIsLoadingCRUD(false)
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {modalCreateUpdate.status ? (
          <ModalCreateUpdateBahanBakar
            minHeight="min-h-[20%]"
            state={modalCreateUpdate}
            onClose={() => setModalCreateUpdate({ ...modalCreateUpdate, status: false })}
            onSubmit={(values) => {
              setIsLoadingCRUD(true)
              const mount = setTimeout(async () => {
                if (modalCreateUpdate.type === 'create') {
                  await apiPost({
                    url: `${urls.master.prefix}/${urls.master.products.fuel.root}`,
                    data: {
                      name: values.fuelName
                    },
                    token: user.token,
                    onLogout
                  })
                }
                if (modalCreateUpdate.type === 'update') {
                  await apiPut({
                    url: `${urls.master.prefix}/${urls.master.products.fuel.root}/${modalCreateUpdate.fuelId}`,
                    data: {
                      name: values.fuelName
                    },
                    token: user.token,
                    onLogout
                  })
                }
                setModalCreateUpdate({ ...modalCreateUpdate, status: false })
                setIsLoadingCRUD(false)
                refetch()
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer
              isPressAdd
              title="Bahan Bakar"
              titleAdd="Tambah Bahan Bakar"
              onPressAdd={() =>
                setModalCreateUpdate({
                  ...modalCreateUpdate,
                  status: true,
                  type: 'create'
                })
              }
            />
            <Formik
              initialValues={{
                fuelSearch: searchFuel
              }}>
              <div className="w-1/5 mt-2">
                <FormInput
                  name="fuelSearch"
                  placeholder="Pencarian"
                  type="text"
                  isIconLeft
                  iconName="search"
                  onValidate={async (value) => {
                    const response = await apiGet({
                      url: `${urls.master.prefix}/${
                        urls.master.products.fuel.root
                      }?keywords=${value}&limit=${
                        perPageArr.find((item) => item.selected === true).value
                      }&offset=0`,
                      token: user.token,
                      onLogout
                    })
                    if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
                      setFuelList(response.resData.data)
                      setMeta(response.resData.meta)
                    } else {
                      refetch()
                    }
                    setSearchFuel(value)
                  }}
                />
              </div>
            </Formik>
            <Table
              currentPage={meta.offset ? meta.offset + 1 : 1}
              totalPerPage={
                perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
                meta.total
                  ? meta.total
                  : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
              }
              dropDownDataFilter={perPageArr}
              lengthAllData={meta.total}
              columns={columns}
              data={fuelList}
              onChangePrevPage={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onChangePrevNextPage(
                    (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeNextPage={() => {
                if (
                  perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
                  meta.total
                ) {
                  setCurrentPage(currentPage + 1)
                  onChangePrevNextPage(
                    (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeDropDown={(value) => onChangePerPage(value)}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default ProdukBahanBakarPage
