import { Input, Modal, Tag, Typography } from 'antd'
import { apiDelete, apiPost, apiPut, urls } from 'api'
import { IcClose } from 'assets/icons'
import { Button } from 'components'
import { useFormik } from 'formik'
import { errorsField, validationInput } from 'helpers'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import ModalAttachServisUmum from './ModalAttachServisUmum'
import { showErrorMessage } from 'utils'

const ModalLayananServis = ({
  type,
  isOpen,
  onCancel,
  data,
  listData,
  searchGeneral,
  setSearchGeneral,
  searchPacket,
  setSearchPacket,
  refetch,
  onLogout
}) => {
  const token = useSelector((state) => state.auth.token)
  const [selected, setSelected] = useState([])
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [modal, setModal] = useState({
    show: false,
    data: {},
    type: ''
  })

  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.name, { isRequired: true })?.valid) {
      errors.name = validationInput(values?.name, { isRequired: true })?.messageError
    }
    if (values?.general_service_ids?.length === 0 && values?.packet_ids?.length === 0) {
      errors.general_service_ids = 'Harus diisi salah satu, servis umum atau paket servis'
    }
    if (!validationInput(values?.description, { isRequired: true })?.valid) {
      errors.description = validationInput(values?.description, { isRequired: true })?.messageError
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? '',
      general_service_ids:
        data?.general_services?.length > 0 ? data?.general_services?.map((item) => item?.id) : [],
      packet_ids: data?.packets?.length > 0 ? data?.packets?.map((item) => item?.id) : [],
      description: data?.description ?? ''
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      let body = {
        ...values
      }
      handleSubmit(body)
    }
  })

  // Submit
  const handleSubmit = async (body) => {
    try {
      setIsLoadingCRUD(true)
      let res
      if (type === 'add') {
        res = await apiPost({
          url: `${urls.service.prefix}/${urls.service.serviceManagement.service.root}`,
          data: body,
          token: token,
          onLogout
        })
      } else {
        res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.serviceManagement.service.root}/${data?.id}`,
          data: body,
          token: token,
          onLogout
        })
      }

      if (res?.resStatus === 200) {
        refetch()
        formik.handleReset()
        onCancel()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        formik.setErrors(errorsField(res?.resData?.errors))
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteDaftarServis = (e, id) => {
    e.preventDefault()
    formik.setFieldValue(
      'general_service_ids',
      values?.general_service_ids?.filter((f) => f !== id)
    )
  }
  const handleDeletePaketServis = (e, id) => {
    e.preventDefault()
    formik.setFieldValue(
      'packet_ids',
      values?.packet_ids?.filter((f) => f !== id)
    )
  }

  // Delete
  const handleDeleteService = async (id) => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiDelete({
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.service.root}/${id}`,
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        refetch()
        onCancel()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (type === 'delete') {
    return (
      <Modal
        width={400}
        centered
        title="Hapus Layanan Servis"
        open={isOpen}
        onOk={() => handleDeleteService(data?.id)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger', loading: isLoadingCRUD }}
        cancelButtonProps={{
          className: 'hover:text-[#000] hover:border-[#000]',
          type: 'default',
          disabled: isLoadingCRUD
        }}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus servis ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      width={500}
      centered
      title={type === 'add' ? 'Tambah Layanan Servis' : 'Edit Layanan Servis'}
      open={isOpen}
      onCancel={() => {
        onCancel()
        formik.handleReset()
      }}
      onOk={() => {
        formik.handleSubmit()
      }}
      okText={'Confirm'}
      okButtonProps={{ className: 'bg-primary', type: 'danger', loading: isLoadingCRUD }}
      cancelButtonProps={{
        className: 'hover:text-[#000] hover:border-[#000]',
        type: 'default',
        disabled: isLoadingCRUD
      }}>
      <ModalAttachServisUmum
        isOpen={modal.show}
        data={
          modal?.type === 'general'
            ? listData?.generalServiceBySearch
            : listData?.packetServiceBySearch
        }
        onCancel={() => setModal({ ...modal, show: false })}
        search={modal?.type === 'general' ? searchGeneral : searchPacket}
        setSearch={modal?.type === 'general' ? setSearchGeneral : setSearchPacket}
        formik={formik}
        selected={selected}
        setSelected={setSelected}
        type={modal?.type}
      />
      <div className="mb-2">
        <Typography.Text className="font-medium">
          Nama Layanan <span className="text-[red]"> *</span>
        </Typography.Text>
        <Input
          className="rounded pt-1 pb-1"
          placeholder="Masukan Nama Layanan"
          name="name"
          value={values?.name}
          onChange={formik.handleChange}
        />
        {touched?.name && errors?.name && <span className="text-[red]">{errors?.name}</span>}
      </div>
      <Typography.Text className="font-medium">Daftar Servis Umum</Typography.Text>
      <div className="mt-1 mb-2">
        {values?.general_service_ids?.length > 0 && (
          <div className="flex mb-3 flex-wrap mt-2 gap-y-2">
            {values?.general_service_ids?.map((item, index) => (
              <Tag
                key={`item-${index.toString()}`}
                className="py-[2px] border-0 bg-[#FEECE2] rounded-lg text-[#A21226] selection:text-black flex items-center gap-[7px]">
                {listData?.generalService?.find?.((f) => f?.id === item)?.name}
                <IcClose
                  size={12}
                  className="text-gray-500 cursor-pointer"
                  onClick={(e) => handleDeleteDaftarServis(e, item)}
                />
              </Tag>
            ))}
          </div>
        )}
        <Button
          className="border-gray-200 bg-white py-1 px-3"
          onClick={() => {
            setModal({ ...modal, show: true, type: 'general' })
            setSelected(values?.general_service_ids)
          }}>
          Pilih Servis Umum
        </Button>
        {touched?.general_service_ids && errors?.general_service_ids && (
          <div>
            <span className="text-[red]">{errors?.general_service_ids}</span>
          </div>
        )}
      </div>
      <Typography.Text className="font-medium">Pilih Paket Servis</Typography.Text>
      <div className="mt-1 mb-2">
        {values?.packet_ids?.length > 0 && (
          <div className="flex mb-3 flex-wrap mt-2 gap-y-2">
            {values?.packet_ids?.map((item, index) => (
              <Tag
                key={`item-${index.toString()}`}
                className="py-[2px] border-0 bg-[#FEECE2] rounded-lg text-[#A21226] selection:text-black flex items-center gap-[7px]">
                {listData?.packetService?.find?.((f) => f?.id === item)?.name}
                <IcClose
                  size={12}
                  className="text-gray-500 cursor-pointer"
                  onClick={(e) => handleDeletePaketServis(e, item)}
                />
              </Tag>
            ))}
          </div>
        )}
        <Button
          className="border-gray-200 bg-white py-1 px-3"
          onClick={() => {
            setModal({ ...modal, show: true, type: 'packet' })
            setSelected(values?.packet_ids)
          }}>
          Pilih Daftar Paket
        </Button>
        {touched?.packet_ids && errors?.packet_ids && (
          <div>
            <span className="text-[red]">{errors?.packet_ids}</span>
          </div>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Deskripsi <span className="text-[red]"> *</span>
        </Typography.Text>
        <Input.TextArea
          className="rounded"
          placeholder="Masukkan Deskripsi"
          name="description"
          value={values?.description}
          onChange={formik.handleChange}
        />
        {touched?.description && errors?.description && (
          <span className="text-[red]">{errors?.description}</span>
        )}
      </div>
    </Modal>
  )
}

export default ModalLayananServis
