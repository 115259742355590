import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, DatePicker, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button } from 'components'
import { IcSearch } from 'assets/icons'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout, setInspeksiStep2 } from 'store/actions'
import { formatDate, toDateFromSlash } from 'helpers'
import { disabledDateTimeWorkingPicker, disabledTimeDropdown } from 'utils'
import moment from 'moment'

const LakukanInspeksiComponentStep2 = () => {
  const router = useHistory()
  const [listWorkshopRef, setListWorkshopRef] = useState([])
  const data = router.location.state.data
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const inspeksiData = useSelector((state) => state.inspeksi)
  const [selectedBranch, setSelectedBranch] = useState(inspeksiData?.workshop)
  const [selectedDate, setSelectedDate] = useState(
    inspeksiData?.form?.inspection_date
      ? toDateFromSlash(inspeksiData?.form?.inspection_date)
      : null
  )
  const [selectedTime, setSelectedTime] = useState(inspeksiData?.form?.schedule_id)
  // const [selectedStall, setSelectedStall] = useState(inspeksiData?.form?.stall ?? null)

  const branchList = useQuery({
    queryKey: ['branch-list', search],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: search,
        offset: 0
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/workshops',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      if (res?.resData?.data?.length !== 0) {
        let resData = res?.resData?.data?.find((item) => item.id === data?.branch_id) ?? null
        let latLng = resData?.pin_google_map?.split(', ') ?? null
        let lat = latLng?.[0] ?? null
        let lng = latLng?.[1] ?? null
        setSelectedBranch({
          ...resData,
          lat: lat,
          lng: lng
        })

        // create reference id from list workshop
        const assign = res.resData.data.reduce((acc, value) => {
          acc[value.id] = React.createRef()
          return acc
        }, {})
        setListWorkshopRef(assign)
      }
    }
  })

  // scroll to spesific selected workshop
  useEffect(() => {
    if (listWorkshopRef && listWorkshopRef.length !== 0) {
      listWorkshopRef[selectedBranch?.id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }, [listWorkshopRef])

  const waktuList = useQuery({
    queryKey: ['waktu-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `inspeksi-service/admin/inspection-unit/register/schedules`,
        onLogout: () => dispatch(logout())
      })
      return res?.resData?.data
    }
  })

  // const stallList = useQuery({
  //   queryKey: ['stall-list', selectedBranch],
  //   refetchOnWindowFocus: false,
  //   queryFn: async () => {
  //     let data = []
  //     if (selectedBranch) {
  //       const res = await apiGet({
  //         token,
  //         url: `inspeksi-service/admin/inspection-unit/register/available-stalls/${selectedBranch.id}`,
  //         onLogout: () => dispatch(logout())
  //       })
  //       data = res?.resData?.data
  //     }
  //     return data
  //   }
  // })

  const onNext = async () => {
    try {
      if (!selectedDate || !selectedBranch || !selectedTime) {
        throw {
          message: 'Validation Error'
        }
      }
      let date = formatDate(selectedDate)
      let idTime = waktuList?.data?.findIndex((waktu) => waktu.id === selectedTime)
      await dispatch(
        setInspeksiStep2(
          selectedBranch?.id,
          selectedBranch?.name,
          selectedTime,
          waktuList?.data[idTime]?.time,
          null, // discard stall, parsing null
          date,
          selectedBranch
        )
      )
      router.push(`/internal-unit/${data?.id}/inspeksi`, { step: 3, data: data })
    } catch (error) {
      console.log(error)
    }
  }

  const onReturnPinGoogleMap = () => {
    if (
      selectedBranch?.pin_google_map &&
      selectedBranch?.pin_google_map !== null &&
      selectedBranch?.pin_google_map !== ''
    ) {
      return `https://maps.google.com/maps?q=${selectedBranch?.pin_google_map?.split(',')?.[0]},${
        selectedBranch?.pin_google_map?.split(',')?.[1]
      }&hl=es;z=14&output=embed`
    } else {
      return null
    }
  }

  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="flex">
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#D1FADF] flex items-center justify-center mr-2">
              <Typography.Text className="text-[#12B76A] font-medium">1</Typography.Text>
            </div>
            <Typography.Text className="text-[#12B76A] font-medium w-fit">
              Data Unit
            </Typography.Text>
            <div className="flex-1 h-1 bg-[#6CE9A6] rounded-full mx-2" />
          </div>
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#FDE2D3] flex items-center justify-center mr-2">
              <Typography.Text className="text-primary-500 font-medium">2</Typography.Text>
            </div>
            <Typography.Text className="text-primary-500 font-medium w-fit">Jadwal</Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mr-2">
              <Typography.Text className="text-gray-500 font-medium">3</Typography.Text>
            </div>
            <Typography.Text className="text-gray-500 font-medium w-fit">
              Konfirmasi
            </Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
        </div>
      </Card>
      <div className="flex mb-4">
        <Card className="flex-1 mr-1 rounded-lg">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Tempat Inspeksi</Typography.Text>
          </div>
          <div className="mb-4">
            <iframe
              title="lokasi caready"
              className="h-[200px] w-full rounded-lg"
              id="gmap_canvas"
              src={onReturnPinGoogleMap()}
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            />
          </div>
          <div className="pt-4 bg-gray-50 rounded-lg">
            <div className="px-4">
              <Input
                className="w-full rounded-md py-2"
                placeholder="Cari Lokasi Bengkel"
                prefix={<IcSearch className="text-gray-400" />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="overflow-y-scroll h-[340px] min-h-full px-4 py-4">
              {branchList?.data?.resData?.data?.map((branch, idx) => {
                let selected = branch?.id === selectedBranch?.id
                return (
                  <div
                    key={branch?.id}
                    ref={listWorkshopRef[branch?.id]}
                    className={`${
                      selected ? 'bg-[#FEECE2] border-[#F6937A]' : 'bg-white border-gray-300'
                    } p-2 w-full rounded-lg border border-solid mb-3 flex cursor-pointer`}
                    onClick={() => {
                      let latLng = branch?.pin_google_map?.split(', ') ?? null
                      let lat = latLng?.[0] ?? null
                      let lng = latLng?.[1] ?? null
                      setSelectedBranch({
                        ...branch,
                        lat: lat,
                        lng: lng
                      })
                    }}>
                    <div className="border border-solid p-1 rounded-full border-primary-500 w-fit h-fit mt-[2px] mr-2">
                      <div
                        className={`w-2 h-2 ${
                          selected ? 'bg-primary-500' : 'bg-transparent'
                        } rounded-full`}
                      />
                    </div>
                    <Typography>
                      <Typography.Text className="font-semibold">{branch?.name}</Typography.Text>
                      <Typography.Paragraph className="text-sm">
                        {`${branch?.address}`}
                      </Typography.Paragraph>
                    </Typography>
                  </div>
                )
              })}
              {/* <div className="bg-[#FEECE2] p-2 w-full rounded-lg border border-solid border-[#F6937A] mb-3 flex">
                <div className="border border-solid p-1 rounded-full border-primary-500 w-fit h-fit mt-[2px] mr-2">
                  <div className="w-2 h-2 bg-primary-500 rounded-full" />
                </div>
                <Typography>
                  <Typography.Text className="font-semibold">Birdmobil Surabaya</Typography.Text>
                  <Typography.Paragraph className="text-sm">
                    Jln. Raya Darmo Kali No. 2-6, Keputran, Kec. Tegalsari, Kota Surabaya, Jawa
                    Timur 60211
                  </Typography.Paragraph>
                </Typography>
              </div> */}
              {/* <div className="bg-white p-2 w-full rounded-lg border border-solid border-gray-300 mb-3 flex">
                <div className="border border-solid p-1 rounded-full border-primary-500 w-fit h-fit mt-[2px] mr-2">
                  <div className="w-2 h-2 bg-transparent rounded-full" />
                </div>
                <Typography>
                  <Typography.Text className="font-semibold">Birdmobil Surabaya</Typography.Text>
                  <Typography.Paragraph className="text-sm">
                    Jln. Raya Darmo Kali No. 2-6, Keputran, Kec. Tegalsari, Kota Surabaya, Jawa
                    Timur 60211
                  </Typography.Paragraph>
                </Typography>
              </div>
              <div className="bg-white p-2 w-full rounded-lg border border-solid border-gray-300 mb-3 flex">
                <div className="border border-solid p-1 rounded-full border-primary-500 w-fit h-fit mt-[2px] mr-2">
                  <div className="w-2 h-2 bg-transparent rounded-full" />
                </div>
                <Typography>
                  <Typography.Text className="font-semibold">Birdmobil Surabaya</Typography.Text>
                  <Typography.Paragraph className="text-sm">
                    Jln. Raya Darmo Kali No. 2-6, Keputran, Kec. Tegalsari, Kota Surabaya, Jawa
                    Timur 60211
                  </Typography.Paragraph>
                </Typography>
              </div> */}
            </div>
          </div>
        </Card>
        <Card className="flex-1 ml-1 rounded-lg h-fit">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Jadwal Inspeksi</Typography.Text>
          </div>
          <Typography.Text className="font-medium">Tanggal</Typography.Text>
          <DatePicker
            className="w-full mt-1 mb-3 custom-date-picker"
            popupClassName="custom-date-picker-dropdown"
            format={'DD/MM/YYYY'}
            placeholder="Pilih Tanggal"
            value={selectedDate}
            onChange={(value) => {
              setSelectedDate(value)
              setSelectedTime(null)
            }}
            disabledDate={disabledDateTimeWorkingPicker}
          />
          <Typography.Text className="font-medium">Waktu</Typography.Text>
          <Select
            className="custom-table-limit w-full mt-1 mb-3"
            placeholder="Pilih Jam"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e)}>
            {waktuList?.data?.map((waktu) => (
              <Select.Option
                key={`jam-${waktu?.id}`}
                value={waktu?.id}
                disabled={disabledTimeDropdown(
                  waktu?.time,
                  selectedDate ? moment(selectedDate).format('DD/MM/YYYY') : ''
                )}>
                {waktu?.time}
              </Select.Option>
            ))}
          </Select>
          {/* <Typography.Text className="font-medium">Stall</Typography.Text>
          <Select
            className="custom-table-limit w-full mt-1"
            disabled={!selectedBranch}
            placeholder="Pilih stall"
            value={selectedStall}
            onChange={(e) => setSelectedStall(e)}>
            {stallList?.data?.map((stall) => (
              <Select.Option key={`stall-${stall?.stall}`} value={stall?.stall}>
                {stall?.stall}
              </Select.Option>
            ))}
          </Select> */}
        </Card>
      </div>
      <div className="flex justify-end items-center mb-1">
        <Button
          className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => router.go(-1)}>
          Kembali
        </Button>
        <Button
          className={`p-0 px-4 py-2 border font-medium hover:opacity-90  ${
            !selectedDate || !selectedBranch || !selectedTime
              ? ' bg-gray-300 text-gray-100 border-gray-300'
              : ' bg-primary-500 text-white border-primary-500'
          }`}
          disabled={!selectedDate || !selectedBranch || !selectedTime}
          onClick={onNext}>
          Selanjutnya
        </Button>
      </div>
    </Content>
  )
}

export default LakukanInspeksiComponentStep2
