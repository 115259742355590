import { Breadcrumb, Input, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { IcEye, IcSearch } from 'assets/icons'
import { CardContainer, Table } from 'components'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  workshop_id: ''
}

const AnalyticsUnitTotalServisPage = () => {
  const { push, goBack } = useHistory()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [workshops, setWorkshops] = useState([])
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const columnStyle = 'font-medium text-gray-700'

  const columns = [
    {
      name: 'Tanggal',
      selector: (row) => (row?.service_date ? moment(row?.service_date).format('DD/MM/YYYY') : '-'),
      cell: (row) => (
        <span className={columnStyle}>
          {row?.service_date ? moment(row?.service_date).format('DD/MM/YYYY') : '-'}
        </span>
      ),
      grow: 1
    },
    {
      name: 'Model Mobil',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-2 py-3">
          <span className={columnStyle}>{row?.unit_name}</span>
          <span className="font-normal text-gray-500 tracking-tight">
            {row?.police_number && row?.police_number !== '' ? row?.police_number : '-'}
          </span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Cabang',
      selector: (row) => row?.workshop_name,
      cell: (row) => <span className={columnStyle}>{row?.workshop_name}</span>,
      grow: 2
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.full_name,
      cell: (row) => <span className={columnStyle}>{row?.full_name}</span>,
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          onClick={() =>
            push({
              pathname: `/analytics-dashboard/unit/${row?.unit_id}/${row?.unit_name}`,
              state: {
                idBooking: row?.id
              }
            })
          }
          className="flex items-center gap-2 justify-center self-center px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
          <IcEye className="text-gray-700" />
          <span className="text-sm-medium text-gray-700">Lihat</span>
        </div>
      ),
      grow: 1
    }
  ]

  // Get Api
  // Get service list
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'total-service-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.province_id,
      tableState.workshop_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic.prefixService}/${urls.analytic.unit.serviceTotal}?keywords=${tableState.keywords}&workshop_id=${tableState?.workshop_id}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get all workshop
  useQuery({
    queryKey: ['cities-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master.workshops.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setWorkshops(res?.resData?.data ?? [])
    }
  })

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <Layout style={{ padding: 32, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => goBack()}>
          Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Total Servis
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <CardContainer minHeight="65%" className={'grid grid-cols-1 gap-5'}>
          <p className="display-xs-semibold text-gray-900 m-0">Daftar Servis</p>
          <div className="flex justify-between space-x-2">
            <Select
              showSearch
              allowClear
              placeholder="Semua Cabang"
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
              style={{ width: '250px' }}
              onSearch={(e) => {}}
              optionFilterProp="children"
              value={tableState?.workshop_id === '' ? null : tableState?.workshop_id}
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, workshop_id: e ?? '', offset: 0 }))
              }>
              {workshops?.map((workshop, idx) => (
                <Select.Option key={idx} value={workshop?.id}>
                  {workshop?.name}
                </Select.Option>
              ))}
            </Select>
            <Input
              className="w-1/4 rounded-md"
              placeholder="Pencarian"
              prefix={<IcSearch className="text-gray-400" />}
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
              }
            />
          </div>
          <div className="flex flex-col w-full space-y-5">
            <div className="flex flex-col space-y-5">
              <Table
                columns={columns}
                data={tableState?.listData}
                pending={isLoading ?? isFetching}
                totalRows={tableState?.totalRows}
                handlePerRowsChange={handlePerRowsChange}
                pageSize={tableState?.limit}
                page={tableState?.offset}
                onChangePrevPage={() => {
                  if (tableState?.offset > 0) {
                    onChangePage(tableState?.offset - tableState?.limit)
                  }
                }}
                onChangeNextPage={() => {
                  if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                    onChangePage(tableState?.offset + tableState?.limit)
                  }
                }}
              />
            </div>
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default AnalyticsUnitTotalServisPage
