import React from 'react'
import { Breadcrumb, Layout } from 'antd'
import { useHistory } from 'react-router-dom'
import DetailExTaxiUnitComponent from './components/DetailExTaxiUnitComponent'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { apiGet } from 'api'
import { useQuery } from 'react-query'

const DetailExTaxiUnit = () => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()

  const { data } = useQuery({
    queryKey: ['ex-taxi', router.location?.state?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `jual-beli-service/admin/taxis/${router.location?.state?.id}`,
        onLogout: () => dispatch(logout())
      })
      return res?.resData?.data
    }
  })

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Ex-Taxi</Breadcrumb.Item>
        <Breadcrumb.Item className="cursor-pointer" onClick={() => router.goBack()}>
          Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item>{data?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <DetailExTaxiUnitComponent data={data} />
    </Layout>
  )
}

export default DetailExTaxiUnit
