import { Col, Divider } from 'antd'
import React from 'react'

function CardSectionHeader({ headerTitle = '', children, hideDivider }) {
  return (
    <Col span={24} className="flex flex-col bg-white border border-gray-200 p-6 rounded-[4px]">
      {/* Header Form */}
      {headerTitle ? (
        <div
          className={`flex flex-col gap-5 text-xl-medium text-gray-900 ${
            hideDivider ? 'mb-5' : 'mb-0'
          }`}>
          {headerTitle}
          {!hideDivider && <Divider className="m-0 mb-5" />}
        </div>
      ) : null}
      {children}
    </Col>
  )
}

export default CardSectionHeader
