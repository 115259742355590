import { Breadcrumb, Layout, Radio } from 'antd'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import KategoriServis from './KategoriServis'
import LayananServis from './LayananServis'
import PaketServis from './PaketServis'
import ServisUmum from './ServisUmum'

const ManagemenServis = () => {
  const [tab, setTab] = useState('kategori')
  const router = useHistory()
  const location = useLocation()

  const routingTab = (route) => {
    router.push({
      pathname: '/services/management',
      hash: route
    })
  }

  const ManagemenServisComponent = () => {
    if (location?.hash?.replace('#', '') === 'servis') {
      return <ServisUmum />
    } else if (location?.hash?.replace('#', '') === 'paket') {
      return <PaketServis />
    } else if (location?.hash?.replace('#', '') === 'layanan') {
      return <LayananServis />
    } else {
      return <KategoriServis />
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Servis</Breadcrumb.Item>
        <Breadcrumb.Item>Management Servis</Breadcrumb.Item>
      </Breadcrumb>
      <Radio.Group
        className="custom-radio mb-4"
        value={location?.hash?.replace('#', '') === '' ? tab : location?.hash?.replace('#', '')}
        size="large"
        onChange={(e) => {
          setTab(e.target.value)
          routingTab(e?.target?.value)
        }}>
        <Radio.Button className="custom-radio-left" value="kategori">
          Kategori Servis
        </Radio.Button>
        <Radio.Button value="servis">Servis Umum</Radio.Button>
        <Radio.Button value="paket">Paket Servis</Radio.Button>
        <Radio.Button className="custom-radio-right" value="layanan">
          Layanan Servis
        </Radio.Button>
      </Radio.Group>
      <ManagemenServisComponent />
    </Layout>
  )
}

export default ManagemenServis
