import { DatePicker, Input, Select, TimePicker } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { apiPut, urls } from 'api'
import { CustomButton, Loading, ModalContainer, ModalForm } from 'components'
import { useFormik } from 'formik'
import { errorsField, validationInput } from 'helpers'
import moment from 'moment'
import 'moment/locale/en-gb'
import { useState } from 'react'
import { disabledDatePicker, disabledDateTimePicker, showErrorMessage } from 'utils'

const ModalKonfirmasiPenjemputan = ({
  data,
  onClose,
  token,
  refetch,
  dropDownMekanik,
  onLogout = () => {}
}) => {
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.service_date, { isRequired: true })?.valid) {
      errors.service_date = validationInput(values?.service_date, {
        isRequired: true
      })?.messageError
    }

    if (values?.service_start_time && values?.service_end_time) {
      if (values?.service_start_time > values?.service_end_time) {
        errors.service_start_time = 'Harus lebih kecil dari waktu akhir'
      }
    }

    if (!validationInput(values?.service_start_time, { isRequired: true })?.valid) {
      errors.service_start_time = validationInput(values?.service_start_time, {
        isRequired: true
      })?.messageError
    }

    if (!validationInput(values?.service_end_time, { isRequired: true })?.valid) {
      errors.service_end_time = validationInput(values?.service_end_time, {
        isRequired: true
      })?.messageError
    }

    if (!validationInput(values?.full_name, { isRequired: true })?.valid) {
      errors.full_name = validationInput(values?.full_name, {
        isRequired: true
      })?.messageError
    }

    if (!validationInput(values?.pickup_address, { isRequired: true })?.valid) {
      errors.pickup_address = validationInput(values?.pickup_address, {
        isRequired: true
      })?.messageError
    }
    if (!validationInput(values?.mechanic_id, { isRequired: true })?.valid) {
      errors.mechanic_id = validationInput(values?.mechanic_id, {
        isRequired: true
      })?.messageError
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      service_date: data?.service_date ? moment(data?.service_date).format('DD/MM/YYYY') : '',
      service_start_time: data?.service_start_time ?? '',
      service_end_time: data?.service_end_time ?? '',
      full_name: data?.full_name ?? '',
      pickup_address: data?.pickup_address ?? '',
      // pickup_address: `${data?.subdistrict_name ?? ''}, ${data?.city_name ?? ''}, ${
      //   data?.province_name ?? ''
      // }, ${data?.pickup_address ?? ''}, ${data?.postal_code ?? ''}`,
      mechanic_id: data?.pickup_mechanic_id ?? ''
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      let body = {
        ...values,
        service_date: moment(values?.service_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        service_start_time: moment(values?.service_start_time, 'HH:mm').format('HH:mm'),
        service_end_time: moment(values?.service_end_time, 'HH:mm').format('HH:mm'),
        mechanic_id: parseInt(values?.mechanic_id)
      }

      const nowDate = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
      const nowTime = moment(new Date(), 'HH:mm').format('HH:mm')
      const rangeDate = moment(moment(body?.service_date, 'YYYY-MM-DD').format('YYYY-MM-DD')).diff(
        nowDate,
        'days'
      )

      if (rangeDate < 0 || (rangeDate === 0 && nowTime > body?.service_start_time)) {
        showErrorMessage({ content: 'Jadwal Tidak Tersedia, Mohon Periksa Kembali.' })
        return true
      }

      handleSubmit(body)
    }
  })

  // Submit
  const handleSubmit = async (body) => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.servicePickup.rootPickup}/${data?.id}/${urls.service.servicePickup.inProgress}`,
        data: body,
        token: token,
        onLogout
      })
      if (res?.resStatus === 200) {
        onClose()
        refetch()
        formik.handleReset()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        formik.setErrors(errorsField(res?.resData?.errors))
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ModalContainer>
      <ModalForm>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-start">
            <span className="font-semibold text-gray-900 text-lg">
              Konfirmasi Mulai Penjemputan
            </span>
          </div>
          <div className="flex flex-col w-full space-y-5">
            <div className="flex flex-col">
              <div className="flex gap-1">
                <label htmlFor="date" className="font-medium block mb-2 text-md text-gray-700">
                  Tanggal
                </label>
                <span className="text-[red]"> *</span>
              </div>
              <DatePicker
                value={values?.service_date ? moment(values?.service_date, 'DD/MM/YYYY') : ''}
                onChange={(dates, dateStrings) => formik.setFieldValue('service_date', dateStrings)}
                className="custom-date-picker py-2 w-full"
                popupClassName="custom-date-picker-dropdown"
                format={['DD/MM/YYYY']}
                disabledDate={disabledDatePicker}
              />
              {touched?.service_date && errors?.service_date && (
                <span className="text-[red]">{errors?.service_date}</span>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex gap-1">
                <label
                  htmlFor="date-start"
                  className="font-medium block mb-2 text-md text-gray-700">
                  Waktu Mulai
                </label>
                <span className="text-[red]"> *</span>
              </div>
              <TimePicker
                value={
                  values?.service_start_time ? moment(values?.service_start_time, 'HH:mm') : ''
                }
                className="custom-date-picker py-2"
                onChange={(value, dateStrings) =>
                  formik.setFieldValue('service_start_time', dateStrings)
                }
                format="HH:mm"
                disabledTime={() => disabledDateTimePicker(values?.service_date)}
              />
              {touched?.service_start_time && errors?.service_start_time && (
                <span className="text-[red]">{errors?.service_start_time}</span>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex gap-1">
                <label htmlFor="date-end" className="font-medium block mb-2 text-md text-gray-700">
                  Waktu Akhir
                </label>
                <span className="text-[red]"> *</span>
              </div>
              <TimePicker
                value={values?.service_end_time ? moment(values?.service_end_time, 'HH:mm') : ''}
                className="custom-date-picker py-2"
                onChange={(value, dateStrings) =>
                  formik.setFieldValue('service_end_time', dateStrings)
                }
                format="HH:mm"
                disabledTime={() => disabledDateTimePicker(values?.service_date)}
              />
              {touched?.service_end_time && errors?.service_end_time && (
                <span className="text-[red]">{errors?.service_end_time}</span>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex gap-1">
                <label htmlFor="mekanik" className="font-medium block mb-2 text-md text-gray-700">
                  Mekanik
                </label>
                <span className="text-[red]"> *</span>
              </div>
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
                value={values?.mechanic_id === '' ? null : values?.mechanic_id}
                onChange={(e) => formik.setFieldValue('mechanic_id', e)}
                placeholder="Pilih Mekanik">
                {dropDownMekanik?.map((mekanik, idx) => (
                  <Select.Option key={idx} value={mekanik?.id}>
                    {mekanik?.fullname}
                  </Select.Option>
                ))}
              </Select>
              {touched?.mechanic_id && errors?.mechanic_id && (
                <span className="text-[red]">{errors?.mechanic_id}</span>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex gap-1">
                <label htmlFor="date-end" className="font-medium block mb-2 text-md text-gray-700">
                  Pemilik
                </label>
                <span className="text-[red]"> *</span>
              </div>
              <Input
                className="rounded py-2"
                placeholder="Pemilik"
                name="full_name"
                value={values?.full_name}
                onChange={formik.handleChange}
              />
              {touched?.full_name && errors?.full_name && (
                <span className="text-[red]">{errors?.full_name}</span>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex gap-1">
                <label htmlFor="date-end" className="font-medium block mb-2 text-md text-gray-700">
                  Alamat
                </label>
                <span className="text-[red]"> *</span>
              </div>
              <TextArea
                className="rounded py-2"
                style={{
                  height: '95px'
                }}
                placeholder="Alamat"
                name="pickup_address"
                value={values?.pickup_address}
                onChange={formik.handleChange}
              />
              {touched?.pickup_address && errors?.pickup_address && (
                <span className="text-[red]">{errors?.pickup_address}</span>
              )}
            </div>
            <div className="flex flex-row w-full items-center justify-end  space-x-4">
              {isLoadingCRUD ? (
                <Loading title="" visible classNameWidth="" />
              ) : (
                <>
                  <CustomButton
                    type="plain"
                    onClick={() => {
                      onClose()
                      formik.handleReset()
                    }}>
                    Cancel
                  </CustomButton>
                  <CustomButton onClick={formik.handleSubmit}>Confirm</CustomButton>
                </>
              )}
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalKonfirmasiPenjemputan
