import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, DatePicker, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { AccumulationCard, Table } from 'components'
import { IcFlag, IcScissors, IcSearch, IcShoppingBag } from 'assets/icons'
import { Line } from '@ant-design/plots'
import { apiGet, urls } from 'api'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { formatCurrency } from 'utils'
import { getMonthID } from 'helpers'
import moment from 'moment'
import 'moment/locale/en-gb'
import { dropdownSalesInitialData } from 'constant'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  listData: [],
  province_id: null,
  city_id: null,
  unit_name: null,
  payment_type: null,
  start_date: '',
  end_date: ''
}

const PenjualanComponent = ({ columns, columnsBranch, columnsBrand }) => {
  const { push } = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [provinces, setProvinces] = useState([])
  const [graphYear, setGraphYear] = useState(null)
  const [cities, setCities] = useState([])
  const [units, setUnits] = useState([])
  const [sales, setSales] = useState(dropdownSalesInitialData)

  const [data, setData] = useState([])
  const [transactionTable, setTransactionTable] = useState(initialTableState)
  const [topBrands, setTopBrands] = useState([])
  const [topBranches, setTopBranches] = useState([])

  //* date state
  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const [tempDate, setTempDate] = useState([])
  const [dateRangeValue, setDateRangeValue] = useState([])

  const [accumulationData, setAccumulationData] = useState({
    sales: {
      label: 'Total Sales',
      icon: <IcFlag className={'stroke-primary-500'} />,
      desc: formatCurrency(0),
      bgClassColor: 'bg-primary-50',
      path: 'total-sales'
    },
    jual: {
      label: 'Total Jual',
      icon: <IcShoppingBag className={'stroke-blue-500'} />,
      desc: formatCurrency(0),
      bgClassColor: 'bg-blue-50',
      path: 'total-penjualan'
    },
    servis: {
      label: 'Total Servis',
      icon: <IcScissors className={'stroke-warning-500'} />,
      desc: formatCurrency(0),
      bgClassColor: 'bg-warning-50',
      path: 'total-servis'
    },
    inspeksi: {
      label: 'Total Inspeksi',
      icon: <IcSearch className={'stroke-success-500'} />,
      desc: formatCurrency(0),
      bgClassColor: 'bg-success-50',
      path: 'total-inspeksi'
    }
  })

  const { isLoading: loadingGraph } = useQuery({
    queryKey: ['graph-data', transactionTable.province_id, transactionTable.city_id, graphYear],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        province_id: transactionTable.province_id,
        city_id: transactionTable.city_id,
        year: graphYear
      }
      const res = await apiGet({
        url: `user-service/admin/analytics/charts`,
        token,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      let newData = []
      Object.keys(res?.resData?.data?.buy_sell).map((key, idx) => {
        newData.push({
          key: getMonthID(idx),
          type: 'Penjualan',
          value: res?.resData?.data?.buy_sell[key]
        })
      })
      Object.keys(res?.resData?.data?.inspection).map((key, idx) => {
        newData.push({
          key: getMonthID(idx),
          type: 'Inspeksi',
          value: res?.resData?.data?.inspection[key]
        })
      })
      Object.keys(res?.resData?.data?.service).map((key, idx) => {
        newData.push({
          key: getMonthID(idx),
          type: 'Servis',
          value: res?.resData?.data?.service[key]
        })
      })
      setData(newData)
    }
  })

  const { isLoading: loadingData } = useQuery({
    queryKey: [
      'total-data',
      transactionTable.province_id,
      transactionTable.city_id,
      transactionTable.start_date,
      transactionTable.end_date
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        province_id: transactionTable.province_id,
        city_id: transactionTable.city_id,
        start_date: transactionTable.start_date,
        end_date: transactionTable.end_date
      }
      const res = await apiGet({
        url: `user-service/admin/analytics/totals`,
        token,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setAccumulationData((prevState) => {
        return {
          ...prevState,
          sales: {
            ...prevState.sales,
            desc: formatCurrency(res?.resData?.data?.sales ?? '0')
          },
          jual: {
            ...prevState.jual,
            desc: formatCurrency(res?.resData?.data?.buy_sell ?? '0')
          },
          servis: {
            ...prevState.servis,
            desc: formatCurrency(res?.resData?.data?.service ?? '0')
          },
          inspeksi: {
            ...prevState.inspeksi,
            desc: formatCurrency(res?.resData?.data?.inspection ?? '0')
          }
        }
      })
    },
    enabled: !loadingGraph
  })

  const { isLoading: loadingTransaction } = useQuery({
    queryKey: [
      'transaction-data',
      transactionTable.offset,
      transactionTable.limit,
      transactionTable.province_id,
      transactionTable.city_id,
      transactionTable.unit_name,
      transactionTable.payment_type,
      transactionTable.start_date,
      transactionTable.end_date
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        offset: transactionTable.offset,
        limit: transactionTable.limit,
        province_id: transactionTable.province_id,
        city_id: transactionTable.city_id,
        unit_name: transactionTable.unit_name,
        payment_type: transactionTable.payment_type,
        start_date: transactionTable.start_date,
        end_date: transactionTable.end_date
      }
      const res = await apiGet({
        url: `user-service/admin/transactions`,
        data: params,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTransactionTable((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    },
    onError: (er) => {
      console.log(er)
    },
    enabled: !loadingData && !loadingGraph
  })

  const { isLoading: loadingTop } = useQuery({
    queryKey: ['top-data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const resBrand = await apiGet({
        url: `user-service/admin/analytics/top-brands?limit=5`,
        token,
        onLogout: () => dispatch(logout())
      })
      const resBranch = await apiGet({
        url: `user-service/admin/analytics/top-branches?limit=5`,
        token,
        onLogout: () => dispatch(logout())
      })
      return {
        brands: resBrand?.resData?.data,
        branches: resBranch?.resData?.data
      }
    },
    onSuccess: (res) => {
      setTopBrands(res?.brands ?? [])
      setTopBranches(res?.branches ?? [])
    }
    // enabled: !loadingData && !loadingGraph && !loadingTransaction
  })

  // Get Provinces
  useQuery({
    queryKey: ['province-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.location?.province?.all}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setProvinces(res?.resData?.data ?? [])
    }
  })

  // Get City by province
  useQuery({
    queryKey: ['cities-list', transactionTable.province_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (transactionTable.province_id !== '' && transactionTable.province_id !== null) {
        const res = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.cities?.filterByProvince}/${transactionTable.province_id}`,
          onLogout: () => dispatch(logout())
        })
        return res
      }
    },
    onSuccess: (res) => {
      setCities(res?.resData?.data ?? [])
    }
  })

  // Get Units Transaction
  useQuery({
    queryKey: ['units-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic.prefixUser}/${urls.analytic.transactionBranch.transactionUnitList}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setUnits(res?.resData?.data ?? [])
    }
  })

  const config = {
    data,
    xField: 'key',
    yField: 'value',
    seriesField: 'type',
    color: ({ type }) => {
      return type === 'Penjualan' ? '#F4664A' : type === 'Inspeksi' ? '#30BF78' : '#FAAD14'
    },
    lineStyle: ({ type }) => {
      if (type === 'Penjualan') {
        return {
          // lineDash: [4, 4],
          opacity: 1
        }
      }
      return {
        opacity: 0.5
      }
    }
  }

  const handleChangeGraphYear = (value) => {
    if (value !== null) {
      // console.log('1')
      const yearFormat = moment(value).format('YYYY')
      setGraphYear(yearFormat)
    } else {
      // console.log('2')
      setGraphYear(null)
    }
  }

  const handlePerRowsChange = (newPerPage) => {
    setTransactionTable((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTransactionTable((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  const handleNavigationReport = (path) => {
    push(`/analytics-dashboard/penjualan/${path}`)
  }
  return (
    <Content className="h-full grid grid-cols-1 gap-6">
      {/* chart */}
      <Card className="w-full ">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-xl">Informasi Penjualan</Typography.Text>
          <DatePicker
            placeholder="Pilih Tahun"
            picker="year"
            allowClear
            // value={graphYear}
            onChange={(value) => handleChangeGraphYear(value)}
            className="custom-date-picker py-1.5"
            popupClassName="custom-date-picker-dropdown"
            format={['YYYY']}
          />
        </div>
        <div>
          <Line {...config} />
        </div>
      </Card>
      {/* filter */}
      <div className="flex items-center gap-3">
        <Select
          showSearch
          allowClear
          placeholder="Semua Provinsi"
          className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
          style={{ width: '200px' }}
          onSearch={(e) => {}}
          optionFilterProp="children"
          value={transactionTable.province_id ?? null}
          onChange={(e) =>
            setTransactionTable((prev) => ({
              ...prev,
              province_id: e ?? null,
              city_id: null,
              offset: 0
            }))
          }>
          {provinces?.map((province, idx) => (
            <Select.Option key={idx} value={province?.id}>
              {province?.name}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          allowClear
          placeholder="Semua Cabang"
          className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
          style={{ width: '200px' }}
          onSearch={(e) => {}}
          optionFilterProp="children"
          value={transactionTable.city_id ?? null}
          onChange={(e) =>
            setTransactionTable((prev) => ({ ...prev, city_id: e ?? null, offset: 0 }))
          }>
          {cities?.map((city, idx) => (
            <Select.Option key={idx} value={city?.id}>
              {city?.name}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          allowClear
          placeholder="Semua Unit"
          className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
          style={{ width: '200px' }}
          onSearch={(e) => {}}
          optionFilterProp="children"
          value={transactionTable.unit_name ?? null}
          onChange={(e) =>
            setTransactionTable((prev) => ({ ...prev, unit_name: e ?? null, offset: 0 }))
          }>
          {units?.map((unit, idx) => (
            <Select.Option key={idx} value={unit}>
              {unit}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          allowClear
          placeholder="Semua Sales"
          className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
          style={{ width: '200px' }}
          onSearch={(e) => {}}
          optionFilterProp="children"
          value={transactionTable.payment_type ?? null}
          onChange={(e) =>
            setTransactionTable((prev) => ({ ...prev, payment_type: e ?? null, offset: 0 }))
          }>
          {sales?.map((item, idx) => (
            <Select.Option key={idx} value={item.value}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
        <RangePicker
          onOpenChange={(open) => setIsShowDatePicker(true)}
          onChange={(dates) => {
            setTempDate(dates)
            if (!dates) {
              setTempDate([])
              setDateRangeValue(dates)
              setTransactionTable((prev) => ({
                ...prev,
                start_date: '',
                end_date: '',
                offset: 0
              }))
            }
          }}
          format="DD/MM/YYYY"
          open={isShowDatePicker}
          value={dateRangeValue}
          className="custom-date-picker py-3 w-fit"
          renderExtraFooter={() => (
            <div className="flex flex-row justify-end items-center space-x-4 my-3">
              <button
                type="button"
                className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-gray-700 bg-white"
                onClick={() => {
                  setTempDate([])
                  setIsShowDatePicker(false)
                }}>
                Cancel
              </button>
              <button
                type="submit"
                className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-white bg-primary-500"
                onClick={() => {
                  setIsShowDatePicker(false)
                  if (tempDate?.length > 0) {
                    setDateRangeValue(tempDate)
                    setTransactionTable((prev) => ({
                      ...prev,
                      start_date: moment(tempDate[0]).format('DD/MM/YYYY'),
                      end_date: moment(tempDate[1]).format('DD/MM/YYYY'),
                      offset: 0
                    }))
                    setTempDate([])
                  }
                }}>
                Apply
              </button>
            </div>
          )}
        />
      </div>
      {/* selective */}
      <div className="grid grid-cols-4 gap-6">
        {Object.keys(accumulationData).map((key) => (
          <AccumulationCard
            onClick={handleNavigationReport.bind(this, accumulationData[key].path)}
            key={key}
            data={accumulationData[key]}
          />
        ))}
      </div>

      {/* content */}
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-xl">Transaksi Baru</Typography.Text>
        </div>
        <Table
          columns={columns}
          data={transactionTable?.listData}
          pending={loadingTransaction && loadingTop}
          totalRows={transactionTable?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={transactionTable?.limit}
          page={transactionTable?.offset}
          onChangePrevPage={() => {
            if (transactionTable?.offset > 0) {
              onChangePage(transactionTable?.offset - transactionTable?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (transactionTable?.totalRows > transactionTable?.limit + transactionTable?.offset) {
              onChangePage(transactionTable?.offset + transactionTable?.limit)
            }
          }}
        />
      </Card>
      <div className="grid grid-cols-2 gap-6">
        <Card className="rounded-lg">
          <div className="flex justify-between mb-5">
            <Typography.Text className="font-semibold text-xl">Top 5 Brand</Typography.Text>
          </div>
          <Table columns={columnsBrand} data={topBrands} hidePagination />
        </Card>
        <Card className="rounded-lg">
          <div className="flex justify-between mb-5">
            <Typography.Text className="font-semibold text-xl">Top 5 Branch</Typography.Text>
          </div>
          <Table columns={columnsBranch} data={topBranches} hidePagination />
        </Card>
      </div>
    </Content>
  )
}

export default PenjualanComponent
