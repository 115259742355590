import React, { useCallback, useState } from 'react'
import { Breadcrumb, Input, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { CardContainer, HeaderContainer, ModalDelete, Table } from 'components'
import { IcEdit, IcSearch, IcTrash } from 'assets/icons'
import { ModalCreateUpdateKategoriCYOC } from '../components'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorMessage, showSuccessMessage } from 'utils'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const CreateYourOwnCarKategoriPage = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()

  const onLogout = () => dispatch(logout())

  const [tableState, setTableState] = useState(initialTableState)

  //* modal state
  const [isModalDelete, setIsModalDelete] = useState({
    show: false,
    title: '',
    desc: ''
  })

  const [isModalCreateUpdate, setIsModalCreateUpdate] = useState({
    show: false,
    type: 'create',
    name: ''
  })

  //* columns table
  const columns = [
    {
      name: 'Nama Kategori',
      selector: (row) => row.name
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              setIsModalCreateUpdate({
                show: true,
                type: 'update',
                data: row
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setIsModalDelete({
                show: true,
                title: 'Hapus Kategori',
                desc: `Apakah anda yakin ingin menghapus kategori [${`${row.name}`}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`,
                onSubmit: () => deleteData(row)
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]
  //* end columns table

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'cyoc-kategori-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'jual-beli-service/admin/create-your-own-car/category',
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const onCheckFieldEmpty = (data) => {
    if (data.name !== '' && data.name !== null && data.name !== undefined) {
      return true
    } else {
      showErrorMessage({ content: 'Silahkan Lengkapi Field Nama Kategori' })
      return false
    }
  }

  const saveData = async (data) => {
    if (onCheckFieldEmpty(data)) {
      try {
        let res
        let body = {
          ...data
        }
        if (isModalCreateUpdate.type === 'update') {
          res = await apiPut({
            url: `jual-beli-service/admin/create-your-own-car/category/${data.id}`,
            token,
            data: body,
            onLogout
          })
        } else {
          res = await apiPost({
            url: `jual-beli-service/admin/create-your-own-car/category`,
            token,
            data: body,
            onLogout
          })
        }
        if (res?.resStatus === 200 || res?.resStatus === 201) {
          showSuccessMessage({
            content: `${
              res.resData.message ??
              `Successfully ${isModalCreateUpdate.type === 'create' ? 'Create' : 'Edit'} Kategori.`
            }`
          })
          setIsModalCreateUpdate({
            ...isModalCreateUpdate,
            show: false
          })
        } else {
          showErrorMessage({
            content: `${
              res.resData.errors[0].message ??
              `Failed to ${isModalCreateUpdate.type === 'create' ? 'Create' : 'Edit'} Sparepart.`
            }`
          })
        }
        refetch()
      } catch (error) {
        setIsModalCreateUpdate({
          ...isModalCreateUpdate,
          show: false
        })
      }
    }
  }

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        url: `jual-beli-service/admin/create-your-own-car/category/${data?.id}`,
        token,
        onLogout
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({
          content: `${res.resData.message ?? 'Successfully Delete Kategori.'}`
        })
        setIsModalDelete({
          ...isModalDelete,
          show: false
        })
      } else {
        showErrorMessage({
          content: `${res.resData.errors[0].message ?? 'Failed to Delete Kategori.'}`
        })
      }
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Create Your Own Car
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Kategori</Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {isModalCreateUpdate.show ? (
          <ModalCreateUpdateKategoriCYOC
            state={isModalCreateUpdate}
            onClose={() => setIsModalCreateUpdate({ ...isModalCreateUpdate, show: false })}
            onSubmit={saveData}
          />
        ) : null}
        {isModalDelete.show ? (
          <ModalDelete
            minHeight="min-h-[18%]"
            minWidth="min-w-[20%]"
            maxWidth="max-w-[20%]"
            title={isModalDelete.title}
            desc={isModalDelete.desc}
            onClose={() => setIsModalDelete({ ...isModalDelete, show: false })}
            onSubmit={isModalDelete.onSubmit}
          />
        ) : null}
        <CardContainer>
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer
              title="Kategori"
              isPressAdd
              titleAdd="Tambah Kategori"
              onPressAdd={() =>
                setIsModalCreateUpdate({
                  show: true,
                  type: 'create',
                  name: ''
                })
              }
            />
            <Input
              className="w-1/4 rounded-md"
              placeholder="Pencarian"
              prefix={<IcSearch className="text-gray-400" />}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Table
              columns={columns}
              data={tableState.listData}
              pending={isLoading}
              onSort={handleSort}
              totalRows={tableState?.totalRows}
              handlePerRowsChange={handlePerRowsChange}
              pageSize={tableState?.limit}
              page={tableState?.offset}
              onChangePrevPage={() => {
                if (tableState?.offset > 0) {
                  onChangePage(tableState?.offset - tableState?.limit)
                }
              }}
              onChangeNextPage={() => {
                if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                  onChangePage(tableState?.offset + tableState?.limit)
                }
              }}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default CreateYourOwnCarKategoriPage
