import { mdiChevronRight } from '@mdi/js'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiDelete, apiGet, urls } from 'api'
import { IcTrash } from 'assets/icons'
import {
  BaseIcon,
  Button,
  DocumentUnitCard,
  InformasiSpekUnitVerticalCard,
  InformasiUserCard,
  InspeksiCard,
  Loading,
  PhotoCarousel
} from 'components'
import BadgeProgress from 'components/badge-progress'
import HistoryUnitServicesInspectionCard from 'components/card/history-unit-services-inspection-card'
import HistoryUnitUserBookingCard from 'components/card/history-unit-user-booking-card'
import { CatatanSection } from 'pages/main/jual-unit/components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { formatCurrency, formatThousandsINTL, showErrorMessage } from 'utils'
import ModalBookingDetail from './components/ModalBookingDetail'
import moment from 'moment'

const BookingMobilDetail = () => {
  const { id: idUnit, idCatalog } = useParams()
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [detailCatalog, setDetailCatalog] = useState({})

  const [historyUserBooking, setHistoryUserBooking] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: [],
    keywords: ''
  })

  const [historyInspection, setHistoryInspection] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: []
  })

  const [historyServices, setHistoryServices] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: []
  })

  const [modal, setModal] = useState({
    type: 'delete',
    data: {},
    show: false
  })

  const columnsHistoryUserBooking = [
    {
      name: 'Antrian',
      selector: (row) => row.queue_number,
      sortable: true,
      sortField: 'queue_number',
      width: '100px'
    },
    {
      name: 'Rentang Booking',
      selector: (row) => row?.start_time,
      cell: (row) => (
        <div>
          <span className="whitespace-normal break-all">{`${moment(row?.start_time).format(
            'DD/MM/YYYY'
          )} - ${moment(row?.end_time).format('DD/MM/YYYY')}`}</span>
        </div>
      ),
      sortable: true,
      sortField: 'start_time'
    },
    {
      name: 'User',
      selector: (row) => row.buyer_fullname,
      sortable: true,
      sortField: 'buyer_fullname'
    },
    {
      name: 'Email',
      selector: (row) => row.buyer_email,
      sortable: true,
      sortField: 'buyer_email'
    },
    {
      name: 'Nomor Telepon',
      selector: (row) => row.buyer_phone_number,
      sortable: true,
      sortField: 'buyer_phone_number'
    },
    {
      name: 'Action',
      width: '160px',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <Button
          className="p-2 border-solid border border-[#FDA19B] rounded-md bg-white"
          onClick={() => {
            setModal({ show: true, type: 'delete', data: row })
          }}>
          <IcTrash className="text-primary-500" size={16} />
        </Button>
      )
    }
  ]

  const columnsHistoryInspection = [
    {
      name: 'Title',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-medium text-lg text-gray-900">{`Inspeksi - ${row?.workshop_name}`}</span>
          <span className="font-normal text-lg text-gray-900">{row?.date}</span>
        </div>
      )
    },
    {
      name: 'Status',
      width: 'auto',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-normal text-lg text-gray-900">Status</span>
          <div>
            <BadgeProgress status={row?.status} />
          </div>
        </div>
      )
    },
    {
      name: 'Action',
      width: 'auto',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => router.push(`/inspeksi/hasil-inspeksi/${row?.id}`)}>
          <BaseIcon path={mdiChevronRight} iconSize={21} color="#E12B25" />
        </div>
      )
    }
  ]

  const columnsHistoryServices = [
    {
      name: 'Title',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-medium text-lg text-gray-900">{row?.service_name}</span>
          <span className="font-normal text-lg text-gray-900">
            {moment(row?.updated_at).format('ll')}
          </span>
        </div>
      )
    },
    {
      name: 'Result',
      width: 'auto',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-normal text-left text-lg text-gray-900">{`${row?.item_count ?? 0
            } item`}</span>
          <span className="font-semibold text-left text-lg text-[#E12B25]">
            {formatCurrency(row?.price_total ?? 0)}
          </span>
        </div>
      )
    },
    {
      name: 'Status',
      width: 'auto',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-normal text-lg text-gray-900">Status</span>
          <div>
            <BadgeProgress status={row?.status} />
          </div>
        </div>
      )
    },
    {
      name: 'Action',
      width: 'auto',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => router.push(`/services/jadwal/detail/${row?.id}`)}>
          {' '}
          <BaseIcon path={mdiChevronRight} iconSize={21} color="#E12B25" />
        </div>
      )
    }
  ]

  // API
  // Get List History Booking
  const { isLoading: isLoadingBooking, refetch: refetchBooking } = useQuery({
    queryKey: [
      'historyUserBooking-list',
      historyUserBooking.offset,
      historyUserBooking.limit,
      historyUserBooking.sort_by,
      historyUserBooking.sort_order,
      historyUserBooking.keywords,
      idUnit
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.booking.prefix}/${urls?.booking.rootAdmin}/${idUnit}/${urls.booking.users}?keywords=${historyUserBooking.keywords}&limit=${historyUserBooking?.limit}&offset=${historyUserBooking?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setHistoryUserBooking((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get List History Inspeksi
  const { isLoading: isLoadingInspeksi, isFetching: isFetchingInspeksi } = useQuery({
    queryKey: ['historyInspection-list', historyInspection.offset, historyInspection.limit, idUnit],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection.prefix}/${urls?.inspection.history}/${idUnit}?limit=${historyInspection?.limit}&offset=${historyInspection?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setHistoryInspection((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get List History Service
  const { isLoading: isLoadingServices, isFetching: isFetchingServices } = useQuery({
    queryKey: ['historyServices-list', historyServices.offset, historyServices.limit, idUnit],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service.prefix}/${urls?.service?.historiesBooking}/${idUnit}?limit=${historyServices?.limit}&offset=${historyServices?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setHistoryServices((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Detail Catalog
  const { isLoading: isLoadingCatalog, isFetching: isFetchingCatalog } = useQuery({
    queryKey: ['detail-catalog', idCatalog],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.booking.prefix}/${urls?.booking.catalog.prefix}/${idCatalog}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDetailCatalog(res?.resData?.data ?? {})
    }
  })

  // Delete History User Booking
  const handleDeleteHistoryUserBooking = async () => {
    try {
      const res = await apiDelete({
        url: `${urls?.booking.prefix}/${urls?.booking.rootAdmin}/${modal?.data?.id}`,
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        refetchBooking()
        setModal({ ...modal, show: false, data: {} })
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (isLoadingCatalog || isFetchingCatalog) {
    return <Loading visible />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item
          className="font-inter font-semibold text-gray-700">
          Booking Mobil
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Mobil Bekas
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Booking
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <div className="flex flex-col w-full space-y-5">
          <InformasiUserCard
            title="Informasi Pemilik"
            data={{
              nama: detailCatalog?.owner_fullname,
              email: detailCatalog?.owner_email,
              telepon: detailCatalog?.owner_phone_number,
              alamat: detailCatalog?.owner_address
            }}
          />
          <div className="grid grid-cols-2 w-full space-x-5 items-start justify-start">
            <InformasiSpekUnitVerticalCard
              data={{
                merek: detailCatalog?.brand_name ?? '-',
                model: detailCatalog?.model_name ?? '-',
                tipe: detailCatalog?.type_name ?? '-',
                platNomor: detailCatalog?.police_number ?? '-',
                tahun: detailCatalog?.production_year ?? '-',
                transmisi: detailCatalog?.transmission_name ?? '-',
                warna: detailCatalog?.color_name ?? '-',
                bahanBakar: detailCatalog?.fuel_name ?? '-',
                jarakTempuh:
                  detailCatalog.mileage && detailCatalog.mileage !== ''
                    ? `${formatThousandsINTL(detailCatalog.mileage)} KM`
                    : '-',
                noRangka: detailCatalog?.frame_number ?? '-',
                noMesin:
                  detailCatalog?.engine_number && detailCatalog?.engine_number !== ''
                    ? detailCatalog?.engine_number
                    : '-',
                tag:
                  detailCatalog?.tags?.map(
                    (item, idx) =>
                      `${item?.name}${idx + 1 === detailCatalog?.tags?.length ? '' : ', '}`
                  ) ?? ''
              }}
            />
            <DocumentUnitCard
              data={[
                {
                  name: 'STNK',
                  value: detailCatalog?.document_stnk_status ? 'Ada' : 'Tidak Ada'
                },
                {
                  name: 'No. STNK',
                  value:
                    detailCatalog.document_stnk && detailCatalog.document_stnk !== ''
                      ? detailCatalog.document_stnk
                      : '-'
                },
                {
                  name: 'BPKB',
                  value: detailCatalog?.document_bpkb_status ? 'Ada' : 'Tidak Ada'
                },
                {
                  name: 'No. BPKB',
                  value:
                    detailCatalog.document_bpkb && detailCatalog.document_bpkb !== ''
                      ? detailCatalog.document_bpkb
                      : '-'
                },
                {
                  name: 'Faktur',
                  value: detailCatalog?.document_faktur_status ? 'Ada' : 'Tidak Ada'
                },
                {
                  name: 'KTP Pemilik',
                  value: detailCatalog?.document_ktp_status ? 'Ada' : 'Tidak Ada'
                },
                {
                  name: 'Kwitansi',
                  value: detailCatalog?.document_kwitansi_status ? 'Ada' : 'Tidak Ada'
                },
                {
                  name: 'KEUR',
                  value: detailCatalog?.document_keur_status ? 'Ada' : 'Tidak Ada'
                },
                {
                  name: 'No. KEUR',
                  value:
                    detailCatalog.document_keur && detailCatalog.document_keur !== ''
                      ? detailCatalog.document_keur
                      : '-'
                },
                {
                  name: 'Form A',
                  value: detailCatalog?.document_form_a_status ? 'Ada' : 'Tidak Ada'
                },
                {
                  name: 'Pajak',
                  value: detailCatalog.tax_status ? 'Hidup' : 'Mati'
                },
                {
                  name: 'Pajak Berlaku',
                  value:
                    detailCatalog.tax_active_end && detailCatalog.tax_active_end !== ''
                      ? moment(detailCatalog.tax_active_end, 'YYYY-MM-DD').format('DD-MM-YYYY')
                      : '-'
                }
              ]}
            />
          </div>
          <CatatanSection
            desc={
              detailCatalog?.description && detailCatalog?.description !== ''
                ? detailCatalog?.description
                : 'Tidak ada catatan.'
            }
          />

          <InspeksiCard
            onGoToDetailInspeksi={() =>
              router.push(
                `/booking-mobil/${detailCatalog?.unit_name}/${detailCatalog?.inspection_result?.id}/detail-inspeksi`
              )
            }
            data={{
              ...detailCatalog?.inspection_result,
              inspectionCategoryList:
                detailCatalog?.inspection_result?.inspectionCategoryList?.map((item) => ({
                  ...item,
                  count: `${item?.ok_total}/${item?.part_total}`
                })) ?? []
            }}
          />
          <PhotoCarousel
            title={'Foto Mobil'}
            data={detailCatalog?.photos?.map((item) => item?.photo_filename) ?? []}
          />

          <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
            <HistoryUnitServicesInspectionCard
              title="Riwayat Servis"
              columns={columnsHistoryServices}
              data={historyServices?.listData}
              tableState={historyServices}
              setTableState={setHistoryServices}
              isLoading={isLoadingServices ?? isFetchingServices}
            />
            <HistoryUnitServicesInspectionCard
              title="Riwayat Inspeksi"
              columns={columnsHistoryInspection}
              data={historyInspection?.listData}
              tableState={historyInspection}
              setTableState={setHistoryInspection}
              isLoading={isLoadingInspeksi ?? isFetchingInspeksi}
            />
          </div>
          <HistoryUnitUserBookingCard
            data={historyUserBooking?.listData}
            columns={columnsHistoryUserBooking}
            isLoading={isLoadingBooking}
            tableState={historyUserBooking}
            setTableState={setHistoryUserBooking}
          />
        </div>
      </Content>

      {/* Modal Delete History Riwayat User Booking */}
      <ModalBookingDetail
        isOpen={modal.show}
        type={modal.type}
        data={modal.data}
        onCancel={() => setModal({ ...modal, show: false, data: {} })}
        onSubmit={handleDeleteHistoryUserBooking}
      />
    </Layout>
  )
}

export default BookingMobilDetail
