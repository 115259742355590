import { Collapse, DatePicker, Input, TimePicker, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { apiPut, urls } from 'api'
import { IcChevronDown } from 'assets/icons'
import { CustomButton, Loading } from 'components'
import { useFormik } from 'formik'
import { errorsField, validationInput } from 'helpers'
import ModalLayout from 'layouts/modal-layout'
import { useState } from 'react'
import { disabledDatePicker, disabledDateTimePicker, showErrorMessage } from 'utils'
import moment from 'moment'

const ModalKonfirmasiPickupService = ({
  title,
  openModal,
  data,
  onClose,
  token,
  refetch,
  onLogout
}) => {
  const [actualDate, setActualDate] = useState(moment(new Date()))
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.service_date, { isRequired: true })?.valid) {
      errors.service_date = validationInput(values?.service_date, {
        isRequired: true
      })?.messageError
    }

    if (values?.service_start_time && values?.service_end_time) {
      if (values?.service_start_time > values?.service_end_time) {
        errors.service_start_time = 'Harus lebih kecil dari waktu akhir'
      }
    }

    if (!validationInput(values?.service_start_time, { isRequired: true })?.valid) {
      errors.service_start_time = validationInput(values?.service_start_time, {
        isRequired: true
      })?.messageError
    }

    if (!validationInput(values?.service_end_time, { isRequired: true })?.valid) {
      errors.service_end_time = validationInput(values?.service_end_time, {
        isRequired: true
      })?.messageError
    }

    if (!validationInput(values?.full_name, { isRequired: true })?.valid) {
      errors.full_name = validationInput(values?.full_name, {
        isRequired: true
      })?.messageError
    }

    if (!validationInput(values?.pickup_address, { isRequired: true })?.valid) {
      errors.pickup_address = validationInput(values?.pickup_address, {
        isRequired: true
      })?.messageError
    }

    return errors
  }

  const validationServiceDateAndTime = (service_date, service_start_time, service_end_time) => {
    let valid = true
    let message = 'Jadwal Tidak Tersedia, Mohon Periksa Kembali.'
    const currentDate = moment().format('DD/MM/YYYY')
    const currentHour = moment().format('HH:mm')

    // CHECK IF DATE IS LOWER THAN CURRENT
    if (moment(service_date, 'DD/MM/YYYY').isBefore(moment(currentDate, 'DD/MM/YYYY'))) {
      valid = false
    }
    // IF DATE SAME AS CURRENT, CHECK IF START TIME AND END TIME LOWER THAN CURRENT HOUR
    else if (moment(service_date, 'DD/MM/YYYY').isSame(moment(currentDate, 'DD/MM/YYYY'))) {
      if (service_start_time < currentHour && service_end_time < currentHour) valid = false
    }

    return { valid: valid, message: message }
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      service_date: data?.service_date ? moment(data?.service_date).format('DD/MM/YYYY') : '',
      service_start_time: data?.service_start_time ?? '',
      service_end_time: data?.service_end_time ?? '',
      full_name: data?.full_name ?? '',
      pickup_address: `${data?.subdistrict_name ? `${data?.subdistrict_name}, ` : ''}${
        data?.city_name && data?.city_name !== '' ? `${data?.city_name}, ` : ''
      }${data?.province_name && data?.province_name !== '' ? `${data?.province_name}, ` : ''}${
        data?.pickup_address && data?.pickup_address !== '' ? `${data?.pickup_address}, ` : ''
      }${data?.postal_code && data?.postal_code !== '' ? data?.postal_code : ''}`
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      let body = {
        ...values,
        service_date: moment(values?.service_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        service_start_time: moment(values?.service_start_time, 'HH:mm').format('HH:mm'),
        service_end_time: moment(values?.service_end_time, 'HH:mm').format('HH:mm')
      }

      const checkResult = validationServiceDateAndTime(
        moment(values?.service_date, 'DD/MM/YYYY'),
        moment(values?.service_start_time, 'HH:mm').format('HH:mm'),
        moment(values?.service_end_time, 'HH:mm').format('HH:mm')
      )
      if (!checkResult.valid) {
        return showErrorMessage({ content: checkResult.message })
      }

      handleSubmit(body)
    }
  })

  // Submit
  const handleSubmit = async (body) => {
    const rangeDate = moment(moment(body?.service_date).format('YYYY-MM-DD')).diff(
      actualDate,
      'days'
    )
    if (rangeDate > -1) {
      try {
        setIsLoadingCRUD(true)
        const res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.servicePickup.rootPickup}/${data?.id}/${urls.service.servicePickup.confirmed}`,
          data: body,
          token: token,
          onLogout
        })
        if (res?.resStatus === 200) {
          onClose()
          refetch()
          formik.handleReset()
        } else {
          showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
          formik.setErrors(errorsField(res?.resData?.errors))
        }
        setIsLoadingCRUD(false)
      } catch (error) {
        console.log(error)
      }
    } else {
      showErrorMessage({ content: 'Jadwal Tidak Tersedia, Mohon Periksa Kembali.', duration: 2000 })
    }
  }

  return (
    <ModalLayout
      className={'w-full max-w-lg'}
      headerTitle={title}
      openModal={openModal}
      onCancel={onClose}
      loading={isLoadingCRUD}>
      <div className="flex flex-col w-full space-y-5">
        <div className="flex flex-col">
          <label htmlFor="date-end" className="font-medium block mb-2 text-md text-gray-700">
            Pemilik <span className="text-red-600">{' *'}</span>
          </label>
          <Input
            className="rounded py-2"
            placeholder="Pemilik"
            name="full_name"
            value={values?.full_name}
            onChange={formik.handleChange}
          />
          {touched?.full_name && errors?.full_name && (
            <span className="text-[red]">{errors?.full_name}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="date" className="font-medium block mb-2 text-md text-gray-700">
            Nomor Telepon
          </label>
          <Input
            className="rounded py-2 bg-gray-100"
            placeholder="Nomor Telepon"
            name="phone"
            value={data?.phone}
            readOnly
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="detail-servis" className="font-medium block mb-2 text-md text-gray-700">
            Detail Servis
          </label>
          {data?.general_services?.length > 0 ? (
            <Collapse
              className="border-gray-200 border-solid border"
              ghost
              expandIconPosition="end"
              expandIcon={(collapse) => {
                const className = collapse.isActive ? '-rotate-180' : ''
                return (
                  <div>
                    <IcChevronDown className={`text-gray-900 ${className}`} size={18} />
                  </div>
                )
              }}>
              <Collapse.Panel
                header={'Basic Servis'}
                className="bg-gray-50 !rounded-sm font-medium custom-collapse [&.custom-collapse>.ant-collapse-header>.ant-collapse-header-text]:!text-gray-700 [&.custom-collapse>.ant-collapse-header>.ant-collapse-header-text]:!text-[14px]">
                <div className="grid grid-cols-1 space-y-2">
                  {data?.general_services?.map((item, idx) => (
                    <div key={idx} className="flex justify-between space-y-2 space-x-2 items-end">
                      <div className="flex flex-col space-y-1">
                        <Typography.Text className="text-gray-600 font-medium text-[12px]">
                          {item?.general_service_name ?? '-'}
                        </Typography.Text>
                        {item?.sparepart_name ? (
                          <Typography.Text className="text-gray-500 font-normal text-[12px]">
                            {item?.sparepart_name ?? '-'}
                          </Typography.Text>
                        ) : null}
                      </div>
                      <Typography.Text className="text-gray-500 font-normal text-[12px]">
                        {item?.quantity ? `x${item?.quantity}` : ''}
                      </Typography.Text>
                    </div>
                  ))}
                </div>
              </Collapse.Panel>
            </Collapse>
          ) : null}
          {data?.packets?.length > 0
            ? data?.packets?.map((packet, idx) => (
                <Collapse
                  key={idx}
                  className="mt-2 border-gray-200 border-solid border"
                  ghost
                  expandIconPosition="end"
                  expandIcon={(collapse) => {
                    const className = collapse.isActive ? '-rotate-180' : ''
                    return (
                      <div>
                        <IcChevronDown className={`text-gray-900 ${className}`} size={18} />
                      </div>
                    )
                  }}>
                  <Collapse.Panel
                    header={packet?.packet_name ?? '-'}
                    className="bg-gray-50 !rounded-sm font-medium custom-collapse [&.custom-collapse>.ant-collapse-header>.ant-collapse-header-text]:!text-gray-700 [&.custom-collapse>.ant-collapse-header>.ant-collapse-header-text]:!text-[14px]">
                    <div className="grid grid-cols-1 space-y-2">
                      {packet?.spareparts?.map((item, idy) => (
                        <div
                          key={`${idx}-${idy}`}
                          className="flex justify-between space-y-2 space-x-2 items-end">
                          <div className="flex flex-col space-y-1">
                            <Typography.Text className="text-gray-600 font-medium text-[12px]">
                              {item?.general_service_name ?? '-'}
                            </Typography.Text>
                            {item?.sparepart_name ? (
                              <Typography.Text className="text-gray-500 font-normal text-[12px]">
                                {item?.sparepart_name ?? '-'}
                              </Typography.Text>
                            ) : null}
                          </div>
                          <Typography.Text className="text-gray-500 font-normal text-[12px]">
                            {item?.quantity ? `x${item?.quantity}` : ''}
                          </Typography.Text>
                        </div>
                      ))}
                    </div>
                  </Collapse.Panel>
                </Collapse>
              ))
            : null}
        </div>
        <div className="flex flex-col">
          <label htmlFor="date" className="font-medium block mb-2 text-md text-gray-700">
            Tanggal <span className="text-red-600">{' *'}</span>
          </label>
          <DatePicker
            value={values?.service_date ? moment(values?.service_date, 'DD/MM/YYYY') : ''}
            onChange={(dates, dateStrings) => formik.setFieldValue('service_date', dateStrings)}
            className="custom-date-picker py-2 w-full"
            popupClassName="custom-date-picker-dropdown"
            format={['DD/MM/YYYY']}
            disabledDate={disabledDatePicker}
          />
          {touched?.service_date && errors?.service_date && (
            <span className="text-[red]">{errors?.service_date}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="date-start" className="font-medium block mb-2 text-md text-gray-700">
            Waktu Mulai <span className="text-red-600">{' *'}</span>
          </label>
          <TimePicker
            value={values?.service_start_time ? moment(values?.service_start_time, 'HH:mm') : ''}
            className="custom-date-picker py-2"
            onChange={(value, dateStrings) =>
              formik.setFieldValue('service_start_time', dateStrings)
            }
            format="HH:mm"
            disabledTime={() => disabledDateTimePicker(values?.service_date)}
            showNow={false}
          />
          {touched?.service_start_time && errors?.service_start_time && (
            <span className="text-[red]">{errors?.service_start_time}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="date-end" className="font-medium block mb-2 text-md text-gray-700">
            Waktu Akhir <span className="text-red-600">{' *'}</span>
          </label>
          <TimePicker
            value={values?.service_end_time ? moment(values?.service_end_time, 'HH:mm') : ''}
            className="custom-date-picker py-2"
            onChange={(value, dateStrings) => formik.setFieldValue('service_end_time', dateStrings)}
            format="HH:mm"
            disabledTime={() => disabledDateTimePicker(values?.service_date)}
            showNow={false}
          />
          {touched?.service_end_time && errors?.service_end_time && (
            <span className="text-[red]">{errors?.service_end_time}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="date-end" className="font-medium block mb-2 text-md text-gray-700">
            Alamat <span className="text-red-600">{' *'}</span>
          </label>
          <TextArea
            className="rounded py-2"
            style={{
              height: '95px'
            }}
            placeholder="Alamat"
            name="pickup_address"
            value={values?.pickup_address}
            onChange={formik.handleChange}
          />
          {touched?.pickup_address && errors?.pickup_address && (
            <span className="text-[red]">{errors?.pickup_address}</span>
          )}
        </div>
        <div className="flex flex-row w-full items-center justify-end  space-x-4">
          {isLoadingCRUD ? (
            <Loading title="" visible classNameWidth="" />
          ) : (
            <>
              <CustomButton
                type="plain"
                onClick={() => {
                  onClose()
                  formik.handleReset()
                }}>
                Cancel
              </CustomButton>
              <CustomButton onClick={formik.handleSubmit}>Confirm</CustomButton>
            </>
          )}
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalKonfirmasiPickupService
