import { Tooltip } from 'antd'
import { IcCommandSign } from 'assets/icons'

export const onReturnWaktuPerluDikonfirmasi = (available_time, waktu) => {
  switch (available_time) {
    case true:
      return (
        <div className="flex flex-row items-center justify-start space-x-2">
          <span className="font-medium text-start text-primary-500">{waktu}</span>
          <Tooltip placement="topRight" title="Jadwal sedang penuh">
            <div className="hover:cursor-pointer flex self-center">
              <IcCommandSign className="text-[#F79009]" size={16} />
            </div>
          </Tooltip>
        </div>
      )
    default:
      return <span className="font-medium text-start text-gray-700">{waktu}</span>
  }
}

export const onReturnStatusTelahDikonfirmasiPickupService = (status) => {
  let str = ''
  switch (status) {
    case 'PICKUP_IN_PROGRESS':
      str = 'Sedang Dijemput'
      break
    case 'PICKUP_CONFIRMED':
      str = 'Pickup Terjadwal'
      break
    case 'SERVICE_CONFIRMED':
    case 'SERVICE_IN_PROGRESS':
    case 'SERVICE_FINISHED':
    case 'PICKUP_FINISHED':
      str = 'Selesai'
      break
    default:
      str = ''
  }

  switch (status) {
    case 'PICKUP_IN_PROGRESS':
    case 'Sedang Dijemput':
      return (
        <div className="rounded-lg px-3 py-2 bg-blue-50">
          <span className="font-medium text-blue-700">{str}</span>
        </div>
      )
    case 'PICKUP_CONFIRMED':
    case 'Pickup Terjadwal':
      return (
        <div className="flex flex-row items-center justify-start space-x-2">
          <div className="rounded-lg px-3 py-2 bg-warning-50">
            <span className="font-medium text-warning-700">{str}</span>
          </div>
          <Tooltip placement="topRight" title="Segera lakukan penjemputan">
            <div className="hover:cursor-pointer">
              <IcCommandSign className="text-warning-500 mt-1" size={16} />
            </div>
          </Tooltip>
        </div>
      )
    case 'SERVICE_CONFIRMED':
    case 'SERVICE_IN_PROGRESS':
    case 'SERVICE_FINISHED':
    case 'PICKUP_FINISHED':
    case 'Selesai':
      return (
        <div className="rounded-lg px-3 py-2 bg-success-50">
          <span className="font-medium text-success-700">{str}</span>
        </div>
      )
    default:
      break
  }
}

export const onReturnStatusTelahDikonfirmasiJadwalService = (status) => {
  let str = ''
  switch (status) {
    case 'SERVICE_IN_PROGRESS':
    case 'ongoing':
    case 'Sedang Berlangsung':
      str = 'Mobil Di Bengkel'
      break
    case 'SERVICE_CONFIRMED':
    case 'scheduled':
      str = 'Terjadwal'
      break
    case 'SERVICE_FINISHED':
    case 'done':
    case 'Selesai':
      str = 'Selesai'
      break
    case 'UNCONFIRMED':
    case 'not_confirmed':
    case 'Belum Dikonfirmasi':
      str = 'Belum Dikonfirmasi'
      break
    case 'Perlu Melakukan Pembayaran':
      str = 'Perlu Melakukan Pembayaran'
      break
    case 'Inspeksi Terjadwal':
      str = 'Inspeksi Terjadwal'
      break
    default:
      str = ''
  }

  switch (status) {
    case 'SERVICE_IN_PROGRESS':
    case 'ongoing':
    case 'Sedang Berlangsung':
      return (
        <div className="rounded-lg px-3 py-2 bg-blue-50 text-left break-normal">
          <span className="font-medium text-blue-700">{str}</span>
        </div>
      )
    case 'UNCONFIRMED':
    case 'not_confirmed':
    case 'Belum Dikonfirmasi':
    case 'Perlu Melakukan Pembayaran':
      return (
        <div className="rounded-lg px-3 py-2 bg-primary-50 text-left break-normal">
          <span className="font-medium text-primary-700">{str}</span>
        </div>
      )
    case 'SERVICE_CONFIRMED':
    case 'scheduled':
    case 'Inspeksi Terjadwal':
      return (
        <div className="flex flex-row items-center justify-start text-left break-normal space-x-2">
          <div className="rounded-lg px-3 py-2 bg-warning-50">
            <span className="font-medium text-warning-700">{str}</span>
          </div>
          <Tooltip placement="topRight" title="Segera lakukan konfirmasi kehadiran">
            <div className="hover:cursor-pointer">
              <IcCommandSign className="text-warning-500 mt-1" size={16} />
            </div>
          </Tooltip>
        </div>
      )
    case 'SERVICE_FINISHED':
    case 'done':
    case 'Selesai':
      return (
        <div className="rounded-lg px-3 py-2 bg-success-50 break-normal">
          <span className="font-medium text-success-700">{str}</span>
        </div>
      )
    default:
      break
  }
}
