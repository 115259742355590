import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { useHistory } from 'react-router-dom'
import UpdateExTaxiUnitComponent from './components/UpdateExTaxiUnitComponent'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'
import { Loading } from 'components'
import moment from 'moment'
import _ from 'lodash'

const UpdateExTaxiUnit = () => {
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const dispatch = useDispatch()

  const [forms, setForms] = useState({
    brand_id: null,
    brand_name: null,
    model_id: null,
    model_name: null,
    transmission_id: null,
    transmission_name: null,
    fuel_name: null,
    fuel_id: null,
    description: '',
    price: 0,
    year: {
      parse: '',
      value: moment(new Date(), 'YYYY')
    },
    performance_engine: '',
    performance_transmission: '',
    performance_drive_mode: null,
    performance_drive_train: '',
    performance_sbw: null,
    performance_paddle_shifter: null,
    performance_mdps: null,
    safety_airbag: '',
    safety_tpms: null,
    safety_abs: null,
    safety_esc: null,
    safety_hac: null,
    safety_lka: null,
    safety_lfa: null,
    safety_bcw: null,
    safety_bsm: null,
    safety_sea: null,
    safety_roa: null,
    safety_fca: null,
    safety_daw: null,
    safety_cruise_control: null,
    exterior_head_lamp: '',
    exterior_drl: null,
    exterior_positioning_lamp: null,
    exterior_auto_light_control: null,
    exterior_grille: '',
    exterior_bumper: '',
    exterior_wiper: '',
    exterior_wheel: '',
    exterior_side_mirror_color: '',
    exterior_side_mirror_folding: null,
    exterior_side_mirror_heated: null,
    exterior_side_mirror_repeater: null,
    exterior_puddle_lamp: null,
    exterior_rear_lamp: '',
    exterior_side_spoiler: null,
    exterior_hmsl: null,
    exterior_muffler: '',
    exterior_trunk: null,
    exterior_sunroof: null,
    exterior_two_tone: null,
    exterior_fog_lamp: null,
    exterior_parking_sensor: null,
    exterior_mud_guard: null,
    interior_media: '',
    interior_cluster: '',
    interior_speaker: '',
    interior_bluetooth: null,
    interior_steer_control: null,
    interior_seat_material: '',
    interior_seat_ventilation: null,
    interior_seat_folding: null,
    interior_seat_adjust: '',
    interior_seat_capacity: '',
    interior_ims: null,
    interior_ambient_light: null,
    interior_cup_holder: null,
    interior_luggage_net: null,
    interior_cooling_glove_box: null,
    convenience_key: '',
    convenience_epb: null,
    convenience_ac: '',
    convenience_usb: null,
    convenience_wireless_charger: null,
    convenience_map_lamp: null,
    convenience_rain_sensor: null,
    convenience_head_up_display: null,
    convenience_auto_defogger: null,
    thumbnail: { file: undefined, blob: undefined },
    photos: []
  })

  const [search, setSearch] = useState({
    brand: '',
    model: ''
  })

  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  const { isLoading, refetch } = useQuery({
    queryKey: ['ex-taxi', router.location?.state?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (router.location?.state?.id) {
        const res = await apiGet({
          token,
          url: `jual-beli-service/admin/taxis/${router.location?.state?.id}`,
          onLogout: () => dispatch(logout())
        })

        let arrPhotos =
          res?.resData?.data?.photos.length !== 0
            ? res?.resData?.data?.photos.map((item) => ({
                file: undefined,
                blob: `${process.env.REACT_APP_IMAGE_URL_GCS}/${item.photo_filename}`,
                id: item.id ?? undefined
              }))
            : []

        setForms({
          ...res?.resData?.data,
          year: {
            parse:
              res.resData.data.year && res.resData.data.year !== '' ? res.resData.data.year : '',
            value:
              res.resData.data.year && res.resData.data.year !== ''
                ? moment(res.resData.data.year, 'YYYY')
                : moment(new Date(), 'YYYY')
          },
          thumbnail: {
            ...forms.thumbnail.file,
            blob: `${process.env.REACT_APP_IMAGE_URL_GCS}/${res?.resData?.data?.thumbnail_filename}`
          },
          photos: arrPhotos
        })
      }
    }
  })

  const brandList = useQuery({
    queryKey: ['brand-list', search.brand],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: search.brand
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/brands/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onError: (e) => {
      console.log(e)
    },
    enabled: !isLoading
  })

  const modelList = useQuery({
    queryKey: ['model-list', forms.brand_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        brand_id: forms.brand_id ?? ''
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/models/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading && !!forms.brand_id
  })

  const transmissionList = useQuery({
    queryKey: ['transmisi-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: 'master-service/admin/transmissions/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const fuelList = useQuery({
    queryKey: ['fuel-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: 'master-service/admin/fuels/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const handleAddPhoto = async (file) => {
    const formDataPhoto = new FormData()
    formDataPhoto.append('taxi_id', router.location?.state?.id)
    formDataPhoto.append('photo', file)
    await apiPost({
      url: `${urls.exTaxi.prefix}/${urls.exTaxi.createPhotoV2}`,
      token,
      isFormData: true,
      data: formDataPhoto,
      onLogout: () => dispatch(logout())
    })
    refetch()
  }

  const handleDeletePhoto = async (id) => {
    await apiDelete({
      url: `${urls.exTaxi.prefix}/${urls.exTaxi.deletePhoto}/${id}`,
      token,
      onLogout: () => dispatch(logout())
    })
    refetch()
  }

  const onCheckFieldEmpty = () =>
    Object.entries(forms).every((item) => {
      if (
        (item[0] !== 'photos' || item[0] !== 'thumbnail' || item[0] !== 'year') &&
        item[1] !== undefined &&
        item[1] !== '' &&
        item[1] !== null
      ) {
        return true
      }
      showErrorMessage({ content: `Silahkan Lengkapi Field ${_.startCase(item[0])}` })
      return false
    })

  const onAdditionalCheckFieldEmpty = () => {
    if (forms.year.parse !== '') {
      return true
    } else {
      showErrorMessage({ content: `Silahkan Lengkapi Field Tahun Keluaran` })
      return false
    }
  }

  const saveData = async () => {
    if (onCheckFieldEmpty() && onAdditionalCheckFieldEmpty()) {
      try {
        const formData = new FormData()
        let res
        const onReturnValue = (value) => {
          if (value === false) {
            return 'false'
          } else if (value === true) {
            return 'true'
          } else {
            return value.toString()
          }
        }
        Object.entries(forms).map((item) => {
          if (
            item[0] !== 'photos' &&
            item[0] !== 'thumbnail' &&
            item[0] !== 'thumbnail_filename' &&
            item[0] !== 'year' &&
            item[1] !== null &&
            item[1] !== undefined &&
            item[1] !== ''
          ) {
            formData.append(`${item[0]}`, onReturnValue(item[1]))
          }
        })
        formData.append('year', forms.year.parse)
        if (forms.thumbnail.file) {
          formData.append('thumbnail', forms.thumbnail.file)
        }
        if (!router.location?.state?.id) {
          Array.from(forms.photos).forEach((image) => {
            formData.append('photos', image.file)
          })
        }
        setIsLoadingHelper(true)
        const mount = setTimeout(async () => {
          if (router.location?.state?.id) {
            res = await apiPut({
              url: `${urls.exTaxi.prefix}/${urls.exTaxi.createV2}/${router.location?.state?.id}`,
              token,
              isFormData: true,
              data: formData,
              onLogout: () => dispatch(logout())
            })
          } else {
            res = await apiPost({
              url: `${urls.exTaxi.prefix}/${urls.exTaxi.createV2}`,
              token,
              isFormData: true,
              data: formData,
              onLogout: () => dispatch(logout())
            })
          }
          setIsLoadingHelper(false)
          if (res?.resStatus === 200 || res?.resStatus === 201) {
            router.goBack()
            showSuccessMessage({
              content: router.location?.state?.id
                ? 'Successfully Update Ex-Taxi Unit.'
                : 'Successfully Create Ex-Taxi Unit.'
            })
          } else {
            showErrorMessage({
              content:
                res.resData.errors[0].message ??
                (router.location?.state?.id
                  ? 'Failed to Update Ex-Taxi Unit.'
                  : 'Failed to Create Ex-Taxi Unit.')
            })
          }
        })
        return () => clearTimeout(mount)
      } catch (error) {
        setIsLoadingHelper(false)
        console.log(error)
      }
    }
  }

  if (isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Ex-Taxi</Breadcrumb.Item>
        <Breadcrumb.Item>Unit</Breadcrumb.Item>
        <Breadcrumb.Item>
          {router.location?.state?.id ? 'Edit Unit' : 'Tambah Unit'}
        </Breadcrumb.Item>
        {router.location?.state?.id && (
          <Breadcrumb.Item>{router.location?.state?.id ? forms?.name : ''}</Breadcrumb.Item>
        )}
      </Breadcrumb>
      <UpdateExTaxiUnitComponent
        isEditing={router.location?.state?.id ? true : false}
        brandList={brandList?.data?.resData?.data ?? []}
        modelList={modelList?.data?.resData?.data ?? []}
        transmissionList={transmissionList?.data?.resData?.data ?? []}
        fuelList={fuelList?.data?.resData?.data ?? []}
        setSearch={setSearch}
        forms={forms}
        setForms={setForms}
        saveData={saveData}
        handleDeletePhoto={(id) => handleDeletePhoto(id)}
        handleAddPhoto={(file) => handleAddPhoto(file)}
      />
    </Layout>
  )
}

export default UpdateExTaxiUnit
