import { Breadcrumb, Input, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { IcSearch } from 'assets/icons'
import { CardContainer, HeaderContainer, Table } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  workshop_id: ''
}

const AnalyticsDashboardCabangTotalSecurityPage = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [dropDownBegkel, setDropDownBegkel] = useState([])
  const dispatch = useDispatch()
  const router = useHistory()
  const onLogout = () => {
    dispatch(logout())
  }

  // Get All Security
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'security-list',
      tableState.offset,
      tableState.limit,
      tableState.keywords,
      tableState.workshop_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixUser}/${urls?.analytic?.cabang?.security}?keywords=${tableState.keywords}&workshop_id=${tableState?.workshop_id}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get workshop
  useQuery({
    queryKey: ['workshop-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master.workshops.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDropDownBegkel(res?.resData?.data ?? [])
    }
  })

  const columnStyle = 'font-medium text-gray-500 py-1'

  const columns = [
    {
      name: 'ID',
      selector: (row) => row?.profile?.security_no,
      cell: (row) => <span className={columnStyle}>{row?.profile?.security_no}</span>,
      grow: 1
    },
    {
      name: 'Nama',
      selector: (row) => row?.fullname,
      cell: (row) => <span className={columnStyle}>{row?.fullname}</span>,
      grow: 2
    },
    {
      name: 'Nomor Telepon',
      selector: (row) => row?.phone_number,
      cell: (row) => <span className={columnStyle}>{row?.phone_number}</span>,
      grow: 2
    },
    {
      name: 'Email',
      selector: (row) => row?.email,
      cell: (row) => <span className={columnStyle}>{row?.email}</span>,
      grow: 2
    },
    {
      name: 'Bengkel',
      selector: (row) => row?.profile?.workshop_name,
      cell: (row) => <span className={columnStyle}>{row?.profile?.workshop_name}</span>,
      grow: 2
    }
  ]

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Cabang
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Total Security
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer title="Daftar Security" />
            <div className="mb-5 space-x-2 flex justify-between">
              <Select
                showSearch
                allowClear
                placeholder="Semua Cabang"
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
                style={{ minWidth: '250px' }}
                onSearch={(e) => {}}
                optionFilterProp="children"
                value={tableState?.workshop_id === '' ? null : tableState?.workshop_id}
                onChange={(e) =>
                  setTableState((prev) => ({
                    ...prev,
                    workshop_id: e ?? '',
                    city_id: '',
                    offset: 0
                  }))
                }>
                {dropDownBegkel?.map((bengkel, idx) => (
                  <Select.Option key={idx} value={bengkel?.id}>
                    {bengkel?.name}
                  </Select.Option>
                ))}
              </Select>
              <Input
                className="w-1/4 rounded-md"
                placeholder="Pencarian"
                prefix={<IcSearch className="text-gray-400" />}
                onChange={(e) =>
                  setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
                }
              />
            </div>
            <Table
              columns={columns}
              data={tableState?.listData}
              pending={isLoading ?? isFetching}
              totalRows={tableState?.totalRows}
              handlePerRowsChange={handlePerRowsChange}
              pageSize={tableState?.limit}
              page={tableState?.offset}
              onChangePrevPage={() => {
                if (tableState?.offset > 0) {
                  onChangePage(tableState?.offset - tableState?.limit)
                }
              }}
              onChangeNextPage={() => {
                if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                  onChangePage(tableState?.offset + tableState?.limit)
                }
              }}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default AnalyticsDashboardCabangTotalSecurityPage
