import React, { useState } from 'react'
import { Breadcrumb, Layout, DatePicker } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { HeaderContainerJualUnit } from 'pages/main/jual-unit/components'
import {
  CardContainer,
  FormDropDown,
  FormInput,
  Loading,
  ModalDelete,
  Table,
  TabsActive
} from 'components'
import { Formik } from 'formik'
import moment from 'moment'
import 'moment/locale/en-gb'
import { useHistory, useLocation } from 'react-router-dom'
import { IcLoadMore } from 'assets/icons'
import { ModalConfirmationCatalog, ModalLoadMoreCatalog, ModalUpdatePrice } from './components'
import { metaInitialData, perPageInitialData } from 'constant'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPatch, apiResponse, urls } from 'api'
import _ from 'lodash'
import { logout } from 'store/actions'
import { formatCurrency, showErrorMessage, showSuccessMessage } from 'utils'

const KatalogPage = () => {
  const router = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [currentPage, setCurrentPage] = useState(0)
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)
  const [meta, setMeta] = useState(metaInitialData)
  const [listJualUnitKatalog, setListJualUnitKatalog] = useState()
  const [dropDownOwners, setDropDownOwners] = useState([{ name: 'Semua Pemilik', value: -1 }])
  const location = useLocation()
  const hashUrl = location?.hash?.split('-')
  const [tabs, setTabs] = useState([
    {
      name: 'Mobil Bekas',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'exTaxi' || hashUrl?.[hashUrl?.length - 1] === '#exTaxi'
          ? false
          : true,
      hash: 'mobilBekas'
    },
    {
      name: 'ex-Taxi',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'exTaxi' || hashUrl?.[hashUrl?.length - 1] === '#exTaxi'
          ? true
          : false,
      hash: 'exTaxi'
    }
  ])

  const onLogout = () => {
    dispatch(logout())
  }

  const { isLoading: isLoadingMobilBekas, refetch: refetchMobilBekas } = useQuery({
    queryKey: ['jual-unit-catalogs-mobil-bekas'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${meta.offset ?? 0}`,
        token: user.token,
        onLogout: () => onLogout()
      }),
    onSuccess: (data) => {
      setListJualUnitKatalog(data.resData.data)
      setMeta(data.resData.meta)
      // setCurrentPage(0)
    }
  })

  useQuery({
    queryKey: ['jual-unit-catalogs-all-owners'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.owners}`,
        token: user.token,
        onLogout: () => onLogout()
      }),
    onSuccess: (data) => {
      setDropDownOwners([{ name: 'Semua Pemilik', value: -1 }])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            if (item !== '') {
              setDropDownOwners((prev) => [...prev, { name: item, value: item }])
            }
          })
        }
      }
    }
  })

  const { isLoading: isLoadingExTaxi, refetch: refetchExTaxi } = useQuery({
    queryKey: ['jual-unit-catalogs-ex-taxi'],
    refetchOnWindowFocus: false,
    enabled: !!tabs.find((item) => item.active === true).name === 'ex-Taxi',
    queryFn: async () =>
      await apiGet({
        url: `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${meta.offset ?? 0}`,
        token: user.token,
        onLogout: () => onLogout()
      }),
    onSuccess: (data) => {
      setListJualUnitKatalog(data.resData.data)
      setMeta(data.resData.meta)
      // setCurrentPage(0)
    }
  })

  const onRefetch = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Mobil Bekas':
        return refetchMobilBekas()
      case 'ex-Taxi':
        return refetchExTaxi()
    }
  }

  const [modalKonfirmasiKatalog, setModalKonfirmasiKatalog] = useState({
    show: false,
    idCatalog: -1,
    unitName: '',
    status: '',
    action: '',
    name: '',
    price: 0,
    address: '',
    phoneNumber: '',
    antrian: '',
    tab: ''
  })

  const [modalUpdateHarga, setModalUpdateHarga] = useState({
    show: false,
    idCatalog: -1,
    status: '',
    action: '',
    name: '',
    price: 0,
    address: '',
    phoneNumber: '',
    antrian: '',
    tab: ''
  })

  const [modalKonfirmasiDelete, setModalKonfirmasiDelete] = useState({
    show: false,
    idCatalog: -1,
    status: '',
    title: '',
    desc: ''
  })

  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const [tempDate, setTempDate] = useState([])
  const [dateRangeValue, setDateRangeValue] = useState([])
  const [isDateFilterHelper, setIsDateFilterHelper] = useState(false)
  const [ownerSelected, setOwnerSelected] = useState(-1)
  const [catalogSearch, setCatalogSearch] = useState('')
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  const onClearInitialState = () => {
    setCatalogSearch('')
    setOwnerSelected(-1)
    setDateRangeValue([])
    setIsDateFilterHelper(false)
  }

  const onFilterSearchListCatalog = async (owner, search, startDate, endDate, isDateFilter) => {
    setMeta((prev) => ({ ...prev, offset: 0 }))
    setCurrentPage(0)
    let extraUrl = ''
    let urlTemp = ''
    const tabActive = tabs.find((item) => item.active === true).name
    if (owner !== -1) {
      extraUrl += `&owner_fullname=${owner ?? ''}`
    }
    if (search && search !== '') {
      extraUrl += `&keywords=${search}`
    }
    if (startDate && endDate && isDateFilter === true) {
      extraUrl += `&start_date=${startDate}&end_date=${endDate}`
    }
    if (tabActive === 'Mobil Bekas') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${0}${extraUrl}`
    }
    if (tabActive === 'ex-Taxi') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${0}${extraUrl}`
    }
    const response = await apiGet({
      url: urlTemp,
      token: user.token,
      onLogout
    })
    if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
      setListJualUnitKatalog(response.resData.data)
      setMeta(response.resData.meta)
    } else {
      onRefetch()
    }
  }

  const onHandleExtraUrl = () => {
    let extraUrl = ''

    if (ownerSelected && (ownerSelected !== -1 || parseInt(ownerSelected) > -1)) {
      extraUrl += `&owner_fullname=${ownerSelected}`
    }
    if (catalogSearch && catalogSearch !== '') {
      extraUrl += `&keywords=${catalogSearch}`
    }
    if (dateRangeValue?.[0] && dateRangeValue?.[1] && isDateFilterHelper === true) {
      extraUrl += `&start_date=${
        dateRangeValue?.[0] ? moment(dateRangeValue[0]).format('DD/MM/YYYY') : ''
      }&end_date=${dateRangeValue?.[1] ? moment(dateRangeValue[1]).format('DD/MM/YYYY') : ''}`
    }

    return extraUrl
  }

  const onChangePrevNextPage = (offset) => {
    let urlTemp = ''
    const tabActive = tabs.find((item) => item.active === true).name
    setIsLoadingHelper(true)
    setMeta({ ...meta, offset })
    if (tabActive === 'Mobil Bekas') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    if (tabActive === 'ex-Taxi') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${offset}${onHandleExtraUrl()}`
    }
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: urlTemp,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListJualUnitKatalog(response.resData.data)
      } else {
        onRefetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    let urlTemp = ''
    const tabActive = tabs.find((item) => item.active === true).name
    setIsLoadingHelper(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    if (tabActive === 'Mobil Bekas') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}?limit=${value}&offset=${
        meta.offset ?? 0
      }${onHandleExtraUrl()}`
    }
    if (tabActive === 'ex-Taxi') {
      urlTemp = `${urls.jualUnit.prefix}/${
        urls.jualUnit.catalog.exTaxi.root
      }?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`
    }
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: urlTemp,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListJualUnitKatalog(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        onRefetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  //* Table State //*
  const onChangeDataTable = (index) => {
    onClearInitialState()
    if (index === 0) {
      refetchMobilBekas()
    }
    if (index === 1) {
      refetchExTaxi()
    }
  }

  const onFilterBgColor = (title) => {
    switch (title.toLowerCase()) {
      case 'booking lunas':
        return 'bg-warning-50'
      case 'booked':
        return 'bg-purple-50'
      case 'in katalog':
        return 'bg-blue-50'
      default:
        return 'bg-[#C2C2C2]'
    }
  }

  const onFilterTxtColor = (title) => {
    switch (title.toLowerCase()) {
      case 'booking lunas':
        return 'text-danger-700'
      case 'booked':
        return 'text-purple-700'
      case 'in katalog':
        return 'text-blue-700'
      default:
        return 'text-[#FFFFFF]'
    }
  }

  const columnFontStyle = 'font-medium text-gray-700'

  const onCatalogDetail = async (id, row, action) => {
    setModalUpdateHarga({ ...modalUpdateHarga, show: false })
    setModalKonfirmasiDelete({ ...modalKonfirmasiDelete, show: false })
    let urlTemp = ''
    const tabActive = tabs.find((item) => item?.active === true)?.name
    if (tabActive === 'Mobil Bekas') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}/${id}/buyer`
    }
    if (tabActive === 'ex-Taxi') {
      urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.root}/${id}`
    }
    if (row?.status === 'Booked' || row?.status === 'Booking Lunas') {
      const res = await apiGet({
        url: urlTemp,
        token: user.token,
        onLogout
      })
      if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
        const data = res?.resData?.data
        setModalKonfirmasiKatalog({
          ...modalKonfirmasiKatalog,
          show: true,
          taxiId: data?.taxi?.id,
          unitName: data?.taxi?.name,
          idCatalog: id,
          status: row?.status,
          action,
          name: data?.buyer_fullname && data?.buyer_fullname !== '' ? data?.buyer_fullname : '-',
          address: data?.buyer_address && data?.buyer_address !== '' ? data?.buyer_address : '-',
          phoneNumber:
            data?.buyer_phone_number && data?.buyer_phone_number !== ''
              ? data?.buyer_phone_number
              : '-',
          antrian: data?.queue_number ?? '-',
          price:
            tabActive === 'ex-Taxi' ? formatCurrency(data.total_price) : formatCurrency(data.price),
          tab: tabs.find((item) => item.active === true).name,
          email: data?.buyer_email ?? '-'
        })
      } else {
        console.log(res.resData)
        showErrorMessage({ content: `${res.resData.errors?.[0].message ?? 'Cannot get owner!'}` })
      }
    } else {
      setModalKonfirmasiKatalog({
        ...modalKonfirmasiKatalog,
        show: true,
        idCatalog: id,
        status: row.status,
        action,
        name: '',
        address: '',
        phoneNumber: '',
        antrian: '',
        price: 0,
        tab: tabs.find((item) => item.active === true).name,
        email: ''
      })
    }
  }

  // const onUpdatePrice = async (id, row) => {
  //   setModalKonfirmasiKatalog({ ...modalKonfirmasiKatalog, show: false })
  //   setModalKonfirmasiDelete({ ...modalKonfirmasiDelete, show: false })
  //   let urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}/${id}`
  //   if (row?.status === 'In Katalog') {
  //     const res = await apiGet({
  //       url: urlTemp,
  //       token: user.token,
  //       onLogout
  //     })
  //     if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
  //       const data = res.resData.data
  //       setModalUpdateHarga({
  //         ...modalUpdateHarga,
  //         show: true,
  //         idCatalog: id,
  //         status: row?.status,
  //         unit_name: data?.unit_name,
  //         owner_fullname: data?.owner_fullname,
  //         owner_phone_number: data?.owner_phone_number,
  //         price: data?.price
  //       })
  //     } else {
  //       showErrorMessage({ content: `${res.resData.errors[0].message ?? 'Cannot get owner!'}` })
  //     }
  //   }
  // }

  const columnsMobilBekas = [
    {
      name: 'Tanggal',
      selector: (row) => row.created_at,
      cell: (row) => (
        <span className={columnFontStyle}>{moment(row.created_at).format('DD/MM/YYYY')}</span>
      )
    },
    {
      name: 'Model Mobil',
      selector: (row) => row.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <span className={columnFontStyle}>{row.unit_name}</span>
          <span className={'font-medium text-gray-500'}>{row?.police_number}</span>
        </div>
      )
    },
    {
      name: 'Pemilik',
      selector: (row) => row.owner_fullname,
      cell: (row) => (
        <span className={columnFontStyle}>
          {row.owner_fullname && row.owner_fullname !== '' ? row.owner_fullname : '-'}
        </span>
      )
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => (
        <div
          className={`${onFilterBgColor(
            row.status
          )} px-3 py-2 items-center justify-center rounded-xl`}>
          <span className={`font-medium ${onFilterTxtColor(row.status)} text-center`}>
            {_.startCase(row.status)}
          </span>
        </div>
      )
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <ModalLoadMoreCatalog
          status={row.status.toLowerCase()}
          // onPressUpdatePrice={() => onUpdatePrice(row.id, row)}
          onPressKonfirmasiPenjualan={() => onCatalogDetail(row.id, row, 'confirmation')}
          onPressKonfirmasiBooking={() => onCatalogDetail(row.id, row, 'confirmation')}
          onPressKonfirmasiKatalog={() => onCatalogDetail(row.id, row, 'confirmation')}
          onPressDetail={() =>
            router.push(`/jual-unit/katalog/${`${row.id}/Mobil Bekas/${row.status}`}`)
          }
          onPressDetailBooking={() =>
            router.push(`/jual-unit/katalog/${`${row.id}/Mobil Bekas/${row.status}`}`)
          }
          onPressDetailUnit={() =>
            router.push(`/jual-unit/katalog/${`${row.id}`}/Mobil Bekas/${row.status}`)
          }
          onPressSO={() => router.push(`/jual-unit/katalog/so/${`${row.unit_name}`}`)}
          onPressInvoice={() => router.push(`/jual-unit/katalog/invoice/${row.id}/Mobil Bekas`)}
          onPressBatalPenjualan={() =>
            setModalKonfirmasiDelete({
              ...modalKonfirmasiDelete,
              show: true,
              idCatalog: row.id,
              status: row.status,
              title: 'Konfirmasi Pembatalan Penjualan',
              desc: `Batalkan penjualan untuk unit [${row.unit_name}] kepada [${
                row.buyer_fullname && row.buyer_fullname !== '' ? row.buyer_fullname : '-'
              }].`
            })
          }
          onPressBatalBooking={() => onCatalogDetail(row.id, row, 'cancel')}
          onPressDeleteKatalog={() => {
            setModalUpdateHarga({ ...modalUpdateHarga, show: false })
            setModalKonfirmasiKatalog({ ...modalKonfirmasiKatalog, show: false })
            setModalKonfirmasiDelete({
              ...modalKonfirmasiDelete,
              show: true,
              idCatalog: row.id,
              status: row.status,
              title: 'Konfirmasi Hapus Dari Katalog',
              desc: `Hapus dari katalog untuk unit [${row.unit_name}].`
            })
          }}
          onPressEditData={() => router.push(`/jual-unit/katalog/${`${row.id}/edit-unit`}`)}>
          <button
            type="button"
            className="flex items-center justify-center self-center border-0 bg-transparent hover:cursor-pointer">
            <IcLoadMore className="text-gray-500" size={18} />
          </button>
        </ModalLoadMoreCatalog>
      )
    }
  ]

  const columnsExTaxi = [
    {
      name: 'Tanggal',
      selector: (row) => row.created_at,
      cell: (row) => (
        <span className={columnFontStyle}>{moment(row.created_at).format('DD/MM/YYYY')}</span>
      )
    },
    {
      name: 'Model Mobil',
      selector: (row) => row.unit_name,
      cell: (row) => <span className={columnFontStyle}>{row.unit_name}</span>
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => (
        <div
          className={`${onFilterBgColor(
            row.status
          )} px-3 py-2 items-center justify-center rounded-xl`}>
          <span className={`font-medium ${onFilterTxtColor(row.status)} text-center`}>
            {_.startCase(row.status)}
          </span>
        </div>
      )
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <ModalLoadMoreCatalog
          status={row.status.toLowerCase()}
          onPressKonfirmasiPenjualan={() => onCatalogDetail(row.id, row, 'confirmation')}
          onPressKonfirmasiBooking={() => onCatalogDetail(row.id, row, 'confirmation')}
          onPressDetail={() =>
            router.push(`/jual-unit/katalog/${`${row.id}/ex-Taxi/${row.status}`}`)
          }
          onPressDetailBooking={() =>
            router.push(`/jual-unit/katalog/${`${row.id}/ex-Taxi/${row.status}`}`)
          }
          onPressDetailUnit={() =>
            router.push(`/jual-unit/katalog/${`${row.id}/ex-Taxi/${row.status}`}`)
          }
          onPressSO={() => router.push(`/jual-unit/katalog/so/${`${row.unit_name}`}`)}
          onPressInvoice={() => router.push(`/jual-unit/katalog/invoice/${row.id}/ex-Taxi`)}
          onPressBatalPenjualan={() =>
            setModalKonfirmasiDelete({
              ...modalKonfirmasiDelete,
              show: true,
              idCatalog: row.id,
              status: row.status,
              title: 'Konfirmasi Pembatalan Penjualan',
              desc: `Batalkan penjualan untuk unit [${row.unit_name}] kepada [${
                row.buyer_fullname && row.buyer_fullname !== '' ? row.buyer_fullname : '-'
              }].`
            })
          }
          onPressBatalBooking={() => onCatalogDetail(row.id, row, 'cancel')}
          onPressDeleteKatalog={() =>
            setModalKonfirmasiDelete({
              ...modalKonfirmasiDelete,
              show: true,
              idCatalog: row.id,
              status: row.status,
              title: 'Konfirmasi Hapus Dari Katalog',
              desc: `Hapus dari katalog untuk unit [${row.unit_name}].`
            })
          }>
          <button
            type="button"
            className="flex items-center justify-center self-center border-0 bg-transparent hover:cursor-pointer">
            <IcLoadMore className="text-gray-500" size={18} />
          </button>
        </ModalLoadMoreCatalog>
      )
    }
  ]

  const onReturnColumnsTable = () => {
    switch (tabs.find((item) => item.active === true).name) {
      case 'Mobil Bekas':
        return columnsMobilBekas
      case 'ex-Taxi':
        return columnsExTaxi
    }
  }

  if (isLoadingMobilBekas ?? isLoadingExTaxi ?? isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Katalog</Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {modalKonfirmasiKatalog.show ? (
          <ModalConfirmationCatalog
            state={modalKonfirmasiKatalog}
            onClose={() => setModalKonfirmasiKatalog({ ...modalKonfirmasiKatalog, show: false })}
            onSubmit={(data) => {
              let urlTemp = ''
              const tabActive = tabs.find((item) => item.active === true).name
              if (tabActive === 'Mobil Bekas') {
                if (
                  modalKonfirmasiKatalog.status.toLowerCase() === 'booking lunas' &&
                  modalKonfirmasiKatalog.action === 'confirmation'
                ) {
                  urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.confirmSell}/${modalKonfirmasiKatalog.idCatalog}`
                }
                if (
                  modalKonfirmasiKatalog.status.toLowerCase() === 'in katalog' &&
                  modalKonfirmasiKatalog.action === 'confirmation'
                ) {
                  urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.confirmBuy}/${modalKonfirmasiKatalog.idCatalog}`
                }
                if (modalKonfirmasiKatalog.status.toLowerCase() === 'booked') {
                  if (modalKonfirmasiKatalog.action === 'confirmation') {
                    urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.confirmBooked}/${modalKonfirmasiKatalog.idCatalog}`
                  }
                  if (modalKonfirmasiKatalog.action === 'cancel') {
                    urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.declineBooked}/${modalKonfirmasiKatalog.idCatalog}`
                  }
                }
              }
              if (tabActive === 'ex-Taxi') {
                if (
                  modalKonfirmasiKatalog.status.toLowerCase() === 'booking lunas' &&
                  modalKonfirmasiKatalog.action === 'confirmation'
                ) {
                  urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.confirmSell}/${modalKonfirmasiKatalog.idCatalog}`
                }
                if (modalKonfirmasiKatalog.status.toLowerCase() === 'booked') {
                  if (modalKonfirmasiKatalog.action === 'confirmation') {
                    urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.confirmBooked}/${modalKonfirmasiKatalog.idCatalog}`
                  }
                  if (modalKonfirmasiKatalog.action === 'cancel') {
                    urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.declineBooked}/${modalKonfirmasiKatalog.idCatalog}`
                  }
                }
              }
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                let res
                if (
                  modalKonfirmasiKatalog.status.toLowerCase() === 'in katalog' &&
                  modalKonfirmasiKatalog.action === 'confirmation'
                ) {
                  res = await apiPatch({
                    url: urlTemp,
                    data: data,
                    token: user.token,
                    onLogout
                  })
                } else {
                  res = await apiPatch({
                    url: urlTemp,
                    data: {
                      taxi_unit_id: data
                    },
                    token: user.token,
                    onLogout
                  })
                }
                if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                  if (
                    modalKonfirmasiKatalog.status.toLowerCase() === 'booking lunas' &&
                    modalKonfirmasiKatalog.action === 'confirmation'
                  ) {
                    showSuccessMessage({ content: 'Successfully Confirmation Sell.' })
                  }
                  if (
                    modalKonfirmasiKatalog.status.toLowerCase() === 'booked' &&
                    modalKonfirmasiKatalog.action === 'confirmation'
                  ) {
                    showSuccessMessage({ content: 'Successfully Confirmation Booking.' })
                  }
                  if (
                    modalKonfirmasiKatalog.status.toLowerCase() === 'in katalog' &&
                    modalKonfirmasiKatalog.action === 'confirmation'
                  ) {
                    showSuccessMessage({ content: 'Successfully Confirmation.' })
                  }
                  if (
                    modalKonfirmasiKatalog.status.toLowerCase() === 'booked' &&
                    modalKonfirmasiKatalog.action === 'cancel'
                  ) {
                    showSuccessMessage({ content: 'Successfully Decline Booking.' })
                  }
                } else {
                  showErrorMessage({
                    content: res?.resData?.errors?.[0]?.message ?? 'Oopps, something went wrong!'
                  })
                }
                onRefetch()
                setIsLoadingHelper(false)
                setModalKonfirmasiKatalog({ ...modalKonfirmasiKatalog, show: false })
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {modalUpdateHarga.show ? (
          <ModalUpdatePrice
            state={modalUpdateHarga}
            onClose={() => setModalUpdateHarga({ ...modalUpdateHarga, show: false })}
            onSubmit={(data) => {
              if (data !== '' && data !== null && data !== 0) {
                let urlTemp = ''
                const tabActive = tabs.find((item) => item.active === true).name
                if (tabActive === 'Mobil Bekas') {
                  if (modalUpdateHarga.status.toLowerCase() === 'in katalog') {
                    urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}/${modalUpdateHarga.idCatalog}/update`
                  }
                }
                setIsLoadingHelper(true)
                const mount = setTimeout(async () => {
                  let res
                  if (modalUpdateHarga.status.toLowerCase() === 'in katalog') {
                    res = await apiPatch({
                      url: urlTemp,
                      data: { price: data },
                      token: user.token,
                      onLogout
                    })
                  }
                  if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                    if (modalUpdateHarga.status.toLowerCase() === 'in katalog') {
                      showSuccessMessage({ content: 'Successfully Update.' })
                    }
                  } else {
                    showErrorMessage({
                      content: res?.resData?.errors?.[0]?.message ?? 'Oopps, something went wrong!'
                    })
                  }
                  onRefetch()
                  setIsLoadingHelper(false)
                  setModalUpdateHarga({ ...modalUpdateHarga, show: false })
                })
                return () => clearTimeout(mount)
              }
            }}
          />
        ) : null}
        {modalKonfirmasiDelete.show ? (
          <ModalDelete
            minHeight="min-h-[15%]"
            maxWidth="max-w-[20%]"
            minWidth="min-w-[20%]"
            title={modalKonfirmasiDelete.title}
            desc={modalKonfirmasiDelete.desc}
            onClose={() => setModalKonfirmasiDelete({ ...modalKonfirmasiDelete, show: false })}
            onSubmit={() => {
              let urlTemp = ''
              const tabActive = tabs.find((item) => item.active === true).name
              if (
                tabActive === 'Mobil Bekas' &&
                modalKonfirmasiDelete.status.toLowerCase() === 'booking lunas'
              ) {
                urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.declineSell}/${modalKonfirmasiDelete.idCatalog}`
              }
              if (
                tabActive === 'ex-Taxi' &&
                modalKonfirmasiDelete.status.toLowerCase() === 'booking lunas'
              ) {
                urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.declineSell}/${modalKonfirmasiDelete.idCatalog}`
              }
              if (
                tabActive === 'Mobil Bekas' &&
                modalKonfirmasiDelete.status.toLowerCase() === 'in katalog'
              ) {
                urlTemp = `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}/${modalKonfirmasiDelete.idCatalog}`
              }
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                let res = apiResponse()
                if (modalKonfirmasiDelete.status.toLowerCase() === 'booking lunas') {
                  res = await apiPatch({
                    url: urlTemp,
                    token: user.token,
                    onLogout
                  })
                }
                if (modalKonfirmasiDelete.status.toLowerCase() === 'in katalog') {
                  res = await apiDelete({
                    url: urlTemp,
                    token: user.token,
                    onLogout
                  })
                }
                if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                  if (modalKonfirmasiDelete.status.toLowerCase() === 'in katalog') {
                    showSuccessMessage({ content: 'Successfully Delete Katalog.' })
                  } else {
                    showSuccessMessage({ content: 'Successfully Decline Sell.' })
                  }
                } else {
                  showErrorMessage({ content: 'Oopps, something went wrong!' })
                }
                onRefetch()
                setIsLoadingHelper(false)
                setModalKonfirmasiDelete({ ...modalKonfirmasiDelete, show: false })
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <TabsActive
              data={tabs}
              onPress={(index) => {
                onChangeDataTable(index)
                const tempArr = [...tabs]
                tempArr.map((itemCh, indexCh) => {
                  if (index === indexCh) {
                    if (itemCh.active) {
                      return false
                    } else {
                      itemCh.active = true
                      router?.push({
                        hash: itemCh?.hash
                      })
                    }
                  } else {
                    itemCh.active = false
                  }
                })
                setTabs(tempArr)
              }}
            />
            <HeaderContainerJualUnit
              title="Katalog Unit"
              onPressAuditTrail={() =>
                router.push(
                  `/jual-unit/katalog/${tabs?.[0]?.active ? 'mobil-bekas' : 'ex-taxi'}/audit-trail`
                )
              }
            />

            <div className="flex flex-row w-full items-center justify-between mt-2">
              <div className="flex flex-row w-full items-center space-x-5">
                {tabs.find((item) => item.active === true).name === 'Mobil Bekas' ? (
                  <div className="w-1/6">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        ownerSelector: ownerSelected
                      }}>
                      <FormDropDown
                        name="ownerSelector"
                        data={dropDownOwners}
                        onChange={(value) => {
                          setOwnerSelected(value)
                          onFilterSearchListCatalog(
                            value ?? '',
                            catalogSearch,
                            dateRangeValue?.[0]
                              ? moment(dateRangeValue[0]).format('DD/MM/YYYY')
                              : '',
                            dateRangeValue?.[1]
                              ? moment(dateRangeValue[1]).format('DD/MM/YYYY')
                              : '',
                            isDateFilterHelper
                          )
                        }}
                        allowClear={true}
                        isSearch={true}
                      />
                    </Formik>
                  </div>
                ) : null}
                <div className="w-1/4 h-full">
                  <RangePicker
                    onOpenChange={(open) => setIsShowDatePicker(true)}
                    onChange={(dates) => {
                      setTempDate(dates)
                      if (!dates) {
                        setTempDate([])
                        setDateRangeValue(dates)
                        setIsDateFilterHelper(false)
                        onFilterSearchListCatalog(ownerSelected, catalogSearch, '', '', false)
                      }
                    }}
                    format="DD/MM/YYYY"
                    open={isShowDatePicker}
                    value={dateRangeValue}
                    className="py-3 rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500"
                    renderExtraFooter={() => (
                      <div className="flex flex-row justify-end items-center space-x-4 my-3">
                        <button
                          type="button"
                          className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-gray-700 bg-white"
                          onClick={() => {
                            setTempDate([])
                            setIsShowDatePicker(false)
                          }}>
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-white bg-primary-500"
                          onClick={() => {
                            setIsShowDatePicker(false)
                            if (tempDate?.length > 0) {
                              setDateRangeValue(tempDate)
                              setIsDateFilterHelper(true)
                              onFilterSearchListCatalog(
                                ownerSelected,
                                catalogSearch,
                                moment(tempDate[0]).format('DD/MM/YYYY'),
                                moment(tempDate[1]).format('DD/MM/YYYY'),
                                true
                              )
                              setTempDate([])
                            }
                          }}>
                          Apply
                        </button>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="w-1/4">
                <Formik
                  initialValues={{
                    catalogSearch: catalogSearch
                  }}>
                  <FormInput
                    name="catalogSearch"
                    placeholder="Pencarian"
                    type="text"
                    isIconLeft
                    iconName="search"
                    onValidate={(value) => {
                      setCatalogSearch(value)
                      onFilterSearchListCatalog(
                        ownerSelected,
                        value ?? '',
                        dateRangeValue?.[0] ? moment(dateRangeValue[0]).format('DD/MM/YYYY') : '',
                        dateRangeValue?.[1] ? moment(dateRangeValue[1]).format('DD/MM/YYYY') : '',
                        isDateFilterHelper
                      )
                    }}
                  />
                </Formik>
              </div>
            </div>
            <Table
              currentPage={meta.offset ? meta.offset + 1 : 1}
              totalPerPage={
                perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
                meta.total
                  ? meta.total
                  : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
              }
              dropDownDataFilter={perPageArr}
              lengthAllData={meta.total}
              columns={onReturnColumnsTable()}
              data={listJualUnitKatalog}
              onChangePrevPage={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onChangePrevNextPage(
                    (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeNextPage={() => {
                if (
                  perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
                  meta.total
                ) {
                  setCurrentPage(currentPage + 1)
                  onChangePrevNextPage(
                    (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeDropDown={(value) => onChangePerPage(value)}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default KatalogPage
