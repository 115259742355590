import React from 'react'
import { CardContainer } from 'components'
import { formatCurrency } from 'utils'

const PaymentReceiptSection = ({ data }) => {
  const titleFontStyle = 'font-medium text-gray-900'
  const fieldFontStyle = 'font-normal text-gray-700'
  const valueFontStyle = 'font-normal text-gray-700'
  const adminFee = 50000
  const discountVoucher = 50000

  const onCalculateTotalPayment = () => {
    let initialValue = 0
    data.map((item) => item.subCategory.map((itemCtg) => {
      if (itemCtg.price) {
        initialValue += itemCtg.price
      }
    }))
    return (initialValue + adminFee)
  }

  return (
    <CardContainer
      minHeight="20%"
    >
      <div
        className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300"
      >
        <span className="font-semibold text-2xl text-gray-900">
                   Payment Receipt
        </span>
      </div>
      <div
        className="flex flex-col w-full pt-4 space-y-5"
      >
        <div
          className="flex flex-col space-y-5 border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300"
        >
          {
            data.map((item, index) => (
              <div
                key={index}
                className="flex flex-col space-y-1"
              >
                <span
                  className={titleFontStyle}
                >
                  {item.title}
                </span>
                {item.subCategory.map((itemCtg, indexCtg) => (
                  <div
                    key={indexCtg}
                    className="flex flex-col"
                  >
                    <div
                      className="flex flex-row items-start justify-between"
                    >
                      <span
                        className={fieldFontStyle}
                      >
                        {itemCtg.name}
                      </span>
                      <span
                        className={valueFontStyle}
                      >
                        {formatCurrency(itemCtg.price)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ))
          }
          <div
            className="flex flex-row items-start justify-between"
          >
            <span
              className={fieldFontStyle}
            >
                    Biaya Admin
            </span>
            <span
              className={valueFontStyle}
            >
              {formatCurrency(adminFee)}
            </span>
          </div>
        </div>
        <div
          className="flex flex-col space-y-5 border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300"
        >
          <div
            className="flex flex-row items-start justify-between"
          >
            <span
              className={fieldFontStyle}
            >
                    Total
            </span>
            <span
              className={valueFontStyle}
            >
              {formatCurrency(onCalculateTotalPayment())}
            </span>
          </div>
          {discountVoucher ? (
            <div
              className="flex flex-row items-start justify-between"
            >
              <span
                className={fieldFontStyle}
              >
                    Discount Voucher
              </span>
              <span
                className={valueFontStyle}
              >
                {`- ${formatCurrency(discountVoucher)}`}
              </span>
            </div>
          ) : (null)}
        </div>
        <div
          className="flex flex-row items-start justify-between"
        >
          <span
            className={fieldFontStyle}
          >
                    Total Pembayaran
          </span>
          <span
            className={titleFontStyle}
          >
            {formatCurrency(onCalculateTotalPayment() - discountVoucher)}
          </span>
        </div>
      </div>
    </CardContainer>
  )
}

export default PaymentReceiptSection
