import { Breadcrumb, Input, Layout, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { IcSearch } from 'assets/icons'
import { CardContainer, HeaderContainer, Table } from 'components'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  workshop_id: ''
}

const AnalyticsDashboardCabangTestDrivePage = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [dropDownBegkel, setDropDownBegkel] = useState([])
  const dispatch = useDispatch()
  const router = useHistory()
  const onLogout = () => {
    dispatch(logout())
  }

  // Get API
  // Get All List testdrive
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'testdrive-list',
      tableState.offset,
      tableState.limit,
      tableState.keywords,
      tableState.workshop_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixJualBeli}/${urls?.analytic?.rootAdmin}/${urls?.analytic?.cabang?.testDrive}?keywords=${tableState.keywords}&workshop_id=${tableState?.workshop_id}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get workshop
  useQuery({
    queryKey: ['workshop-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master.workshops.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDropDownBegkel(res?.resData?.data ?? [])
    }
  })

  //* columns table
  const columns = [
    {
      name: 'Tanggal',
      selector: (row) => moment(row?.created_at).format('DD/MM/YYYY'),
      width: '120px',
      cell: (row) => moment(row?.created_at).format('DD/MM/YYYY')
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          <Typography.Text
            className="font-medium text-gray-700 w-32 xl:w-36 2xl:w-full"
            ellipsis={{
              tooltip: row?.unit_name
            }}>
            {row?.unit_name}
          </Typography.Text>
          <span className="font-normal text-gray-500">{`${row?.unit_police_number}`}</span>
        </div>
      )
    },
    {
      name: 'Bengkel',
      selector: (row) => row?.workshop_name,
      cell: (row) => row?.workshop_name
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.unit_owner_fullname,
      cell: (row) => (
        <Typography.Text
          className="w-32 xl:w-36 2xl:w-full"
          ellipsis={{
            tooltip: row?.unit_owner_fullname
          }}>
          {row?.unit_owner_fullname}
        </Typography.Text>
      )
    },
    {
      name: 'Tester',
      selector: (row) => row?.tester_fullname,
      cell: (row) => (
        <Typography.Text
          className="w-32 xl:w-36 2xl:w-full"
          ellipsis={{
            tooltip: row?.tester_fullname
          }}>
          {row?.tester_fullname}
        </Typography.Text>
      )
    },
    {
      name: 'Waktu',
      right: true,
      center: true,
      selector: (row) => row?.test_drive_schedule?.time,
      cell: (row) => row?.test_drive_schedule?.time,
      width: '130px'
    }
  ]
  //*end columns table

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }
  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Cabang
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Test Drive
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer title="Daftar Test Drive" />
            <div className="mb-5 space-x-2 flex justify-between">
              <Select
                showSearch
                allowClear
                placeholder="Semua Cabang"
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
                style={{ minWidth: '250px' }}
                onSearch={(e) => {}}
                optionFilterProp="children"
                value={tableState?.workshop_id === '' ? null : tableState?.workshop_id}
                onChange={(e) =>
                  setTableState((prev) => ({
                    ...prev,
                    workshop_id: e ?? '',
                    city_id: '',
                    offset: 0
                  }))
                }>
                {dropDownBegkel?.map((bengkel, idx) => (
                  <Select.Option key={idx} value={bengkel?.id}>
                    {bengkel?.name}
                  </Select.Option>
                ))}
              </Select>
              <Input
                className="w-1/4 rounded-md"
                placeholder="Pencarian"
                prefix={<IcSearch className="text-gray-400" />}
                onChange={(e) =>
                  setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
                }
              />
            </div>
            <Table
              columns={columns}
              data={tableState?.listData}
              pending={isLoading ?? isFetching}
              totalRows={tableState?.totalRows}
              handlePerRowsChange={handlePerRowsChange}
              pageSize={tableState?.limit}
              page={tableState?.offset}
              onChangePrevPage={() => {
                if (tableState?.offset > 0) {
                  onChangePage(tableState?.offset - tableState?.limit)
                }
              }}
              onChangeNextPage={() => {
                if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                  onChangePage(tableState?.offset + tableState?.limit)
                }
              }}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default AnalyticsDashboardCabangTestDrivePage
