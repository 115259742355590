import React from 'react'
import { CardContainer } from 'components'

const InspeksiCardNullify = () => (
  <CardContainer>
    <div className="flex flex-col w-full items-start border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
      <span className="font-semibold text-2xl text-gray-900">Inspeksi</span>
    </div>
    <div className="flex flex-col items-start justify-start space-y-4 mt-4">
      <span className="text-lg font-medium text-gray-700">
        {`Mobil Ini belum melakukan inspeksi. Silahkan lakukan inspeksi untuk lihat hasil inspeksi mobil.`}
      </span>
    </div>
  </CardContainer>
)

export default InspeksiCardNullify
