import { showErrorMessage } from 'utils/showMessage'

export function openLinkWA(phone = '') {
  let temp = phone
  if (phone) {
    if (phone[0] === '0') {
      temp = temp.replace('0', '+62')
    }
    temp.replaceAll('-', '')
    temp.replaceAll(' ', '')
    temp.replaceAll('(', '')
    temp.replaceAll(')', '')
    window.open(`https://wa.me/${temp}`)
  } else {
    showErrorMessage({ content: 'Nomor telepon kosong' })
  }
}
