import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button } from 'components'
import { useSelector } from 'react-redux'

const LakukanInspeksiComponentStep3 = ({ onSubmit }) => {
  const router = useHistory()
  const data = router.location.state.data
  const inspeksiData = useSelector((state) => state.inspeksi)

  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="flex">
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#D1FADF] flex items-center justify-center mr-2">
              <Typography.Text className="text-[#12B76A] font-medium">1</Typography.Text>
            </div>
            <Typography.Text className="text-[#12B76A] font-medium w-fit">
              Data Unit
            </Typography.Text>
            <div className="flex-1 h-1 bg-[#6CE9A6] rounded-full mx-2" />
          </div>
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#D1FADF] flex items-center justify-center mr-2">
              <Typography.Text className="text-[#12B76A] font-medium">2</Typography.Text>
            </div>
            <Typography.Text className="text-[#12B76A] font-medium w-fit">Jadwal</Typography.Text>
            <div className="flex-1 h-1 bg-[#6CE9A6] rounded-full mx-2" />
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#FDE2D3] flex items-center justify-center mr-2">
              <Typography.Text className="text-primary-500 font-medium">3</Typography.Text>
            </div>
            <Typography.Text className="text-primary-500 font-medium w-fit">
              Konfirmasi
            </Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Inspeksi</Typography.Text>
        </div>
        <div className="flex">
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Tempat</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {inspeksiData?.form?.workshop_name}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Tanggal</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {inspeksiData?.form?.inspection_date}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Waktu</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {inspeksiData?.form?.schedule_name}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Stall</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {inspeksiData?.form?.stall}
            </Typography.Text>
          </Typography>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Unit</Typography.Text>
        </div>
        <div className="flex">
          <div className="flex-1 pr-4 ml-2">
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Merek
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.brand?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Model
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.model?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Tipe
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.type?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Transmisi
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.transmission?.name}
              </Typography.Text>
            </Typography>
          </div>
          <div className="flex-1 border-0 border-x pl-4 pr-4 border-solid border-gray-300 mr-2">
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Plat Nomor
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.police_number}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Tahun
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.production_name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Warna
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.color?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Warna
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.color?.name}
              </Typography.Text>
            </Typography>
          </div>
          <div className="flex-1 ml-2">
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Bahan Bakar
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.fuel?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Nomor Rangka
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.frame_number}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Nomor Mesin
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.engine_number}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Tag
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">-</Typography.Text>
            </Typography>
          </div>
        </div>
      </Card>
      <div className="flex justify-end items-center mb-1">
        <Button
          className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => router.go(-1)}>
          Kembali
        </Button>
        <Button
          className="p-0 px-4 py-2 bg-primary-500 border font-medium text-white hover:opacity-90"
          onClick={() => onSubmit(inspeksiData)}>
          Selanjutnya
        </Button>
      </div>
    </Content>
  )
}

export default LakukanInspeksiComponentStep3
