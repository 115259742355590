import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory } from 'react-router-dom'
import { DocumentForm, HargaJualForm, InformasiPemilikForm, InformasiUnitForm } from './components'
import {
  CatatanFormCard,
  Loading,
  PhotoContainerCard,
  PhotoMultipleCard,
  PhotoThumbnailCard
} from 'components'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet, apiPost, urls } from 'api'
import { showErrorMessage, showSuccessMessage, showWarningMessage } from 'utils'
import { logout } from 'store/actions'
import moment from 'moment'
import 'moment/locale/en-gb'

const initialValuesInformasiPemilikForm = {
  namaLengkap: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan nama lengkap'
  },
  email: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan email'
  },
  telepon: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan telepon'
  },
  alamat: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan alamat'
  },
  province: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih provinsi'
  },
  city: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih kota/kabupaten'
  },
  subDistrict: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih kecamatan'
  },
  kelurahan: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih kelurahan'
  },
  postalCode: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan kode pos'
  }
}

const initialValuesInformasiUnitForm = {
  manufacture: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih manufacture'
  },
  model: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih model'
  },
  tipe: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih tipe'
  },
  productionYear: {
    value: moment(new Date(), 'YYYY')
  },
  transmission: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih transmission'
  },
  fuel: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih bahan bakar'
  },
  color: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih warna'
  },
  mileage: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan jarak tempuh'
  },
  policeNumber: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan plat nomor'
  },
  engineNumber: {
    value: ''
  },
  frameNumber: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan nomor rangka'
  },
  bpkbNumber: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan nomor BPKB'
  },
  stnkNumber: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan nomor STNK'
  },
  taxStatus: {
    value: false
  },
  taxActiveEndMonth: {
    parse: '',
    value: moment(new Date(), 'MM'),
    isEmpty: false,
    message: 'Silahkan pilih tanggal pajak berlaku'
  },
  taxActiveEndYear: {
    parse: '',
    value: moment(new Date(), 'YYYY'),
    isEmpty: false,
    message: 'Silahkan pilih tanggal pajak berlaku'
  },
  keurNumber: {
    value: ''
  },
  workshop: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih workshop'
  }
}

const initialValuesDocumentForm = {
  stnk: false,
  bpkb: false,
  faktur: false,
  ktp: false,
  kwitansi: false,
  keur: false,
  formA: false
}

const StockCreateUnitPage = () => {
  const router = useHistory()
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)
  const key = 'createable'

  // initial state informasi pemilik
  const [informasiPemilikForm, setInformasiPemilikForm] = useState(
    initialValuesInformasiPemilikForm
  )
  const [dropDownProvince, setDropDownProvince] = useState([{ name: 'Pilih Provinsi', value: -1 }])
  const [dropDownCity, setDropDownCity] = useState([{ name: 'Pilih Kota/Kabupaten', value: -1 }])
  const [dropDownSubDistrict, setDropDownSubDistrict] = useState([
    { name: 'Pilih Kecamatan', value: -1 }
  ])
  const [dropDownKelurahan, setDropDownKelurahan] = useState([
    { name: 'Pilih Kelurahan', value: -1 }
  ])

  // initial state informasi unit
  const [informasiUnitForm, setInformasiUnitForm] = useState(initialValuesInformasiUnitForm)
  const [dropDownWorkShop, setDropDownWorkShop] = useState([{ name: 'Pilih Workshop', value: -1 }])
  const [dropDownManufacture, setDropDownManufacture] = useState([
    { name: 'Pilih Pabrikan', value: -1 }
  ])
  const [dropDownModel, setDropDownModel] = useState([{ name: 'Pilih Model', value: -1 }])
  const [dropDownTipe, setDropDownTipe] = useState([{ name: 'Pilih Tipe', value: -1 }])
  const [dropDownTransmission, setDropDownTransmission] = useState([
    { name: 'Pilih Transmission', value: -1 }
  ])
  const [dropDownFuel, setDropDownFuel] = useState([{ name: 'Pilih Bahan Bakar', value: -1 }])
  const [dropDownColor, setDropDownColor] = useState([{ name: 'Pilih Warna', value: -1 }])

  // intial state document form
  const [documentStateForm, setDocumentStateForm] = useState(initialValuesDocumentForm)
  const [stnk, setStnk] = useState([
    { name: 'stnk', value: true, label: 'ADA' },
    { name: 'stnk', value: false, label: 'TIDAK ADA' }
  ])
  const [bpkb, setBpkb] = useState([
    { name: 'bpkb', value: true, label: 'ADA' },
    { name: 'bpkb', value: false, label: 'TIDAK ADA' }
  ])
  const [faktur, setFaktur] = useState([
    { name: 'faktur', value: true, label: 'ADA' },
    { name: 'faktur', value: false, label: 'TIDAK ADA' }
  ])
  const [ktp, setKtp] = useState([
    { name: 'ktp', value: true, label: 'ADA' },
    { name: 'ktp', value: false, label: 'TIDAK ADA' }
  ])
  const [kwitansi, setKwitansi] = useState([
    { name: 'kwitansi', value: true, label: 'ADA' },
    { name: 'kwitansi', value: false, label: 'TIDAK ADA' }
  ])
  const [keur, setKeur] = useState([
    { name: 'keur', value: true, label: 'ADA' },
    { name: 'keur', value: false, label: 'TIDAK ADA' }
  ])
  const [formA, setFormA] = useState([
    { name: 'formA', value: true, label: 'ADA' },
    { name: 'formA', value: false, label: 'TIDAK ADA' }
  ])
  const [taxStatus, setTaxStatus] = useState([
    { name: 'taxStatus', value: true, label: 'Hidup' },
    { name: 'taxStatus', value: false, label: 'Mati' }
  ])

  const [price, setPrice] = useState({
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan harga jual'
  })
  const [photoThumbnail, setPhotoThumbnail] = useState({ file: undefined, blob: undefined })
  const [listPhoto, setListPhoto] = useState([])
  const [catatanForm, setCatatanForm] = useState({
    isEditing: false,
    desc: '',
    placeholder: 'Tidak ada catatan.',
    isEmpty: false,
    message: 'Silahkan masukkan catatan (akan diberi default jika kosong)'
  })

  const onLogout = () => dispatch(logout())

  const { isLoading, isFetching } = useQuery({
    queryKey: ['initial-stock-create-unit'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await Promise.all([
        apiGet({
          url: `${urls.master.prefix}/${urls.master.location.province.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.products.brand.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.products.transmissions.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.products.fuel.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.workshops.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/website/colors/all`,
          token: user.token,
          onLogout
        })
      ]),
    onSuccess: (data) => {
      if (data[0].resStatus === 200) {
        if (data[0].resData.data.length !== 0) {
          data[0].resData.data.map((item) => {
            setDropDownProvince((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[1].resStatus === 200) {
        if (data[1].resData.data.length !== 0) {
          data[1].resData.data.map((item) => {
            setDropDownManufacture((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[2].resStatus === 200) {
        if (data[2].resData.data.length !== 0) {
          data[2].resData.data.map((item) => {
            setDropDownTransmission((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[3].resStatus === 200) {
        if (data[3].resData.data.length !== 0) {
          data[3].resData.data.map((item) => {
            setDropDownFuel((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[4].resStatus === 200) {
        if (data[4].resData.data.length !== 0) {
          data[4].resData.data.map((item) => {
            setDropDownWorkShop((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[5].resStatus === 200) {
        if (data[5].resData.data.length !== 0) {
          data[5].resData.data.map((item) => {
            setDropDownColor((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const onResetForm = () => {
    setInformasiPemilikForm(initialValuesInformasiPemilikForm)
    setInformasiUnitForm(initialValuesInformasiUnitForm)
    setDocumentStateForm(initialValuesDocumentForm)
    setPrice({ ...price, value: '' })
    setPhotoThumbnail({ file: undefined, blob: undefined })
    setListPhoto([])
    setCatatanForm({ ...catatanForm, desc: '' })
  }

  if (isLoading ?? isFetching ?? isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Stock</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Tambah Unit
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }} className="h-screen">
        <div className="flex flex-col w-full space-y-5">
          <InformasiPemilikForm
            state={informasiPemilikForm}
            dropDownProvince={dropDownProvince}
            dropDownCity={dropDownCity}
            dropDownSubDistrict={dropDownSubDistrict}
            dropDownKelurahan={dropDownKelurahan}
            onChangeInput={(field, value) => {
              setInformasiPemilikForm({
                ...informasiPemilikForm,
                [field]: {
                  ...informasiPemilikForm[field],
                  value,
                  isEmpty: value && value !== '' ? false : true
                }
              })
            }}
            onChangeDropDown={async (field, value) => {
              setInformasiPemilikForm({
                ...informasiPemilikForm,
                [field]: {
                  ...informasiPemilikForm[field],
                  value: parseInt(value),
                  isEmpty: parseInt(value) > 0 ? false : true
                }
              })
              if (field === 'province' && parseInt(value) > 0) {
                setDropDownCity([{ name: 'Pilih Kota/Kabupaten', value: -1 }])
                const response = await apiGet({
                  url: `${urls.master.prefix}/${
                    urls.master.location.cities.filterByProvince
                  }/${parseInt(value)}`
                })
                if (response.resStatus === 200) {
                  if (response.resData.data.length !== 0) {
                    response.resData.data.map((item) => {
                      setDropDownCity((prev) => [...prev, { name: item.name, value: item.id }])
                    })
                  }
                }
              }
              if (field === 'city' && parseInt(value) > 0) {
                setDropDownSubDistrict([{ name: 'Pilih Kecamatan', value: -1 }])
                const response = await apiGet({
                  url: `${urls.master.prefix}/${
                    urls.master.location.subDistrict.filterByCity
                  }/${parseInt(value)}`,
                  token: user.token,
                  onLogout
                })
                if (response.resStatus === 200) {
                  if (response.resData.data.length !== 0) {
                    response.resData.data.map((item) => {
                      setDropDownSubDistrict((prev) => [
                        ...prev,
                        { name: item.name, value: item.id }
                      ])
                    })
                  }
                }
              }
              if (field === 'subDistrict' && parseInt(value) > 0) {
                setDropDownKelurahan([{ name: 'Pilih Kelurahan', value: -1 }])
                const response = await apiGet({
                  url: `${urls.master.prefix}/${
                    urls.master.location.all.filterBySubDistrict
                  }/${parseInt(value)}`,
                  token: user.token,
                  onLogout
                })
                if (response.resStatus === 200) {
                  if (response.resData.data.length !== 0) {
                    response.resData.data.map((item) => {
                      setDropDownKelurahan((prev) => [...prev, { name: item.name, value: item.id }])
                    })
                  }
                }
              }
            }}
          />
          <InformasiUnitForm
            state={informasiUnitForm}
            dropDownManufacture={dropDownManufacture}
            dropDownModel={dropDownModel}
            dropDownTipe={dropDownTipe}
            dropDownTransmission={dropDownTransmission}
            dropDownFuel={dropDownFuel}
            dropDownWorkshop={dropDownWorkShop}
            dropDownColor={dropDownColor}
            taxStatus={taxStatus}
            onChangeRadioButton={(field, value) => {
              setInformasiUnitForm({
                ...informasiUnitForm,
                [field]: {
                  ...informasiUnitForm[field],
                  value: value === 'true' ? true : false,
                  isEmpty: value && value !== '' ? false : true
                }
              })
            }}
            onChangeInput={(field, value) => {
              if (field === 'taxActiveEndMonth') {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    parse: value && value !== '' ? moment(value).format('MM') : '',
                    value,
                    isEmpty: value && value !== '' ? false : true
                  }
                })
              } else if (field === 'taxActiveEndYear') {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    parse: value && value !== '' ? moment(value).format('YYYY') : '',
                    value,
                    isEmpty: value && value !== '' ? false : true
                  }
                })
              } else {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    value,
                    isEmpty:
                      field === 'engineNumber' || field === 'keurNumber'
                        ? false
                        : value && value !== ''
                        ? false
                        : true
                  }
                })
              }
            }}
            onChangeDropDown={async (field, value) => {
              setInformasiUnitForm({
                ...informasiUnitForm,
                [field]: {
                  ...informasiUnitForm[field],
                  value: parseInt(value),
                  isEmpty: parseInt(value) > 0 ? false : true
                }
              })
              if (field === 'manufacture' && parseInt(value) > 0) {
                setDropDownModel([{ name: 'Pilih Model', value: -1 }])
                const response = await apiGet({
                  url: `${urls.master.prefix}/${urls.master.products.model.all}?brand_id=${parseInt(
                    value
                  )}`,
                  token: user.token,
                  onLogout
                })
                if (response.resStatus === 200) {
                  if (response.resData.data.length !== 0) {
                    response.resData.data.map((item) => {
                      setDropDownModel((prev) => [...prev, { name: item.name, value: item.id }])
                    })
                  }
                }
              }
              if (field === 'model' && parseInt(value) > 0) {
                setDropDownTipe([{ name: 'Pilih Tipe', value: -1 }])
                const response = await apiGet({
                  url: `${urls.master.prefix}/${urls.master.products.tipe.all}?model_id=${parseInt(
                    value
                  )}`,
                  token: user.token,
                  onLogout
                })
                if (response.resData.data.length !== 0) {
                  response.resData.data.map((item) => {
                    setDropDownTipe((prev) => [...prev, { name: item.name, value: item.id }])
                  })
                }
              }
            }}
          />
          <DocumentForm
            stnk={stnk}
            bpkb={bpkb}
            faktur={faktur}
            ktp={ktp}
            kwitansi={kwitansi}
            keur={keur}
            formA={formA}
            state={documentStateForm}
            onChange={(field, value) => {
              setDocumentStateForm({
                ...documentStateForm,
                [field]: value === 'true' ? true : false
              })
            }}
          />
          <HargaJualForm
            name="price"
            state={price}
            onChangePrice={(value) =>
              setPrice({ ...price, value, isEmpty: value && value !== '' ? false : true })
            }
          />
          <PhotoContainerCard>
            <PhotoThumbnailCard
              isRequired={true}
              photoThumbnail={photoThumbnail.blob}
              onChangePhotoThumbnail={({ file, blob }) => setPhotoThumbnail({ file, blob })}
            />
            <PhotoMultipleCard
              isRequired={false}
              data={listPhoto}
              onAddPhoto={(value) => setListPhoto([...listPhoto, ...value])}
              onDeletePhoto={(index) => {
                const temp = [...listPhoto]
                temp.splice(index, 1)
                setListPhoto(temp)
              }}
            />
          </PhotoContainerCard>
          <CatatanFormCard
            title={'Edit'}
            isEditing={catatanForm.isEditing}
            state={catatanForm}
            onEdit={() =>
              setCatatanForm({
                ...catatanForm,
                isEditing: !catatanForm.isEditing,
                isEmpty: catatanForm.desc && catatanForm.desc !== '' ? false : true
              })
            }
            onBlurEditing={(value) =>
              setCatatanForm({
                ...catatanForm,
                isEditing: false,
                desc: value,
                isEmpty: value && value !== '' ? false : true
              })
            }
          />
        </div>
        <div className="flex flex-row w-full items-center justify-end space-x-4">
          <button
            type="button"
            className="flex flex-row mt-4 mb-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
            onClick={() => router.goBack()}>
            Batal
          </button>
          <button
            onClick={() => {
              setInformasiPemilikForm({
                ...informasiPemilikForm,
                namaLengkap: {
                  ...informasiPemilikForm.namaLengkap,
                  isEmpty: informasiPemilikForm.namaLengkap.value === '' ? true : false
                },
                email: {
                  ...informasiPemilikForm.email,
                  isEmpty: informasiPemilikForm.email.value === '' ? true : false
                },
                telepon: {
                  ...informasiPemilikForm.telepon,
                  isEmpty: informasiPemilikForm.telepon.value === '' ? true : false
                },
                alamat: {
                  ...informasiPemilikForm.alamat,
                  isEmpty: informasiPemilikForm.alamat.value === '' ? true : false
                },
                province: {
                  ...informasiPemilikForm.province,
                  isEmpty: informasiPemilikForm.province.value < 1 ? true : false
                },
                city: {
                  ...informasiPemilikForm.city,
                  isEmpty: informasiPemilikForm.city.value < 1 ? true : false
                },
                subDistrict: {
                  ...informasiPemilikForm.subDistrict,
                  isEmpty: informasiPemilikForm.subDistrict.value < 1 ? true : false
                },
                kelurahan: {
                  ...informasiPemilikForm.kelurahan,
                  isEmpty: informasiPemilikForm.kelurahan.value < 1 ? true : false
                },
                postalCode: {
                  ...informasiPemilikForm.postalCode,
                  isEmpty: informasiPemilikForm.postalCode.value === '' ? true : false
                }
              })
              setInformasiUnitForm({
                ...informasiUnitForm,
                manufacture: {
                  ...informasiUnitForm.manufacture,
                  isEmpty: informasiUnitForm.manufacture.value < 1 ? true : false
                },
                model: {
                  ...informasiUnitForm.model,
                  isEmpty: informasiUnitForm.model.value < 1 ? true : false
                },
                tipe: {
                  ...informasiUnitForm.tipe,
                  isEmpty: informasiUnitForm.tipe.value < 1 ? true : false
                },
                transmission: {
                  ...informasiUnitForm.transmission,
                  isEmpty: informasiUnitForm.transmission.value < 1 ? true : false
                },
                fuel: {
                  ...informasiUnitForm.fuel,
                  isEmpty: informasiUnitForm.fuel.value < 1 ? true : false
                },
                color: {
                  ...informasiUnitForm.color,
                  isEmpty: informasiUnitForm.color.value < 1 ? true : false
                },
                mileage: {
                  ...informasiUnitForm.mileage,
                  isEmpty: informasiUnitForm.mileage.value === '' ? true : false
                },
                policeNumber: {
                  ...informasiUnitForm.policeNumber,
                  isEmpty: informasiUnitForm.policeNumber.value === '' ? true : false
                },
                engineNumber: {
                  ...informasiUnitForm.engineNumber
                },
                frameNumber: {
                  ...informasiUnitForm.frameNumber,
                  isEmpty: informasiUnitForm.frameNumber.value === '' ? true : false
                },
                bpkbNumber: {
                  ...informasiUnitForm.bpkbNumber,
                  isEmpty: informasiUnitForm.bpkbNumber.value === '' ? true : false
                },
                stnkNumber: {
                  ...informasiUnitForm.stnkNumber,
                  isEmpty: informasiUnitForm.stnkNumber.value === '' ? true : false
                },
                taxActiveEndMonth: {
                  ...informasiUnitForm.taxActiveEndMonth,
                  isEmpty: informasiUnitForm.taxActiveEndMonth.parse === '' ? true : false
                },
                taxActiveEndYear: {
                  ...informasiUnitForm.taxActiveEndYear,
                  isEmpty: informasiUnitForm.taxActiveEndYear.parse === '' ? true : false
                },
                workshop: {
                  ...informasiUnitForm.workshop,
                  isEmpty: informasiUnitForm.workshop.value < 1 ? true : false
                }
              })
              setPrice({ ...price, isEmpty: price.value === '' ? true : false })
              setCatatanForm({ ...catatanForm, isEmpty: catatanForm.desc === '' ? true : false })
              if (
                // Object.entries(informasiPemilikForm).find(
                //   (item) => item[1].value === '' || item[1].value < 1
                // ) ||
                // Object.entries(informasiUnitForm).find(
                //   (item) => item[1].value === '' || item[1].value < 1
                // ) ||
                Object.entries(informasiPemilikForm).find((item) => item[1].isEmpty === true) ||
                Object.entries(informasiUnitForm).find((item) => item[1].isEmpty === true) ||
                price.value === ''
              ) {
                return false
              } else if (photoThumbnail.file === undefined) {
                showWarningMessage({ content: 'Silahkan Masukkan Foto Thumbnail.' })
                return false
              } else {
                // HIT API
                setIsLoadingHelper(true)
                const formData = new FormData()
                const mount = setTimeout(async () => {
                  formData.append('owner_fullname', informasiPemilikForm.namaLengkap.value)
                  formData.append('owner_email', informasiPemilikForm.email.value)
                  formData.append('owner_phone_number', informasiPemilikForm.telepon.value)
                  formData.append('owner_address', informasiPemilikForm.alamat.value)
                  formData.append(
                    'owner_province_id',
                    informasiPemilikForm.province.value.toString()
                  )
                  formData.append('owner_city_id', informasiPemilikForm.city.value.toString())
                  formData.append(
                    'owner_subdistrict_id',
                    informasiPemilikForm.subDistrict.value.toString()
                  )
                  formData.append(
                    'owner_location_id',
                    informasiPemilikForm.kelurahan.value.toString()
                  )
                  formData.append('owner_postal_code', informasiPemilikForm.postalCode.value)
                  formData.append('brand_id', informasiUnitForm.manufacture.value.toString())
                  formData.append('model_id', informasiUnitForm.model.value.toString())
                  formData.append('type_id', informasiUnitForm.tipe.value.toString())
                  formData.append(
                    'production_year',
                    moment(informasiUnitForm.productionYear.value, 'YYYY').format('YYYY')
                  )
                  formData.append('transmision_id', informasiUnitForm.transmission.value.toString())
                  formData.append('fuel_id', informasiUnitForm.fuel.value.toString())
                  formData.append('color_id', informasiUnitForm.color.value.toString())
                  formData.append('mileage', informasiUnitForm.mileage.value)
                  formData.append('police_number', informasiUnitForm.policeNumber.value)
                  formData.append('engine_number', informasiUnitForm.engineNumber.value)
                  formData.append('frame_number', informasiUnitForm.frameNumber.value)
                  formData.append('branch_id', informasiUnitForm.workshop.value.toString())
                  formData.append('document_bpkb', informasiUnitForm.bpkbNumber.value)
                  formData.append('document_stnk', informasiUnitForm.stnkNumber.value)
                  formData.append(
                    'tax_status',
                    informasiUnitForm.taxStatus.value ? 'true' : 'false'
                  )
                  formData.append(
                    'tax_active_end',
                    `${moment(informasiUnitForm.taxActiveEndMonth.value, 'MM').format(
                      'MM'
                    )}/${moment(informasiUnitForm.taxActiveEndYear.value, 'YYYY').format('YYYY')}`
                  )
                  formData.append('document_keur', informasiUnitForm.keurNumber.value)
                  formData.append('document_stnk_status', documentStateForm.stnk ? 'true' : 'false')
                  formData.append('document_bpkb_status', documentStateForm.bpkb ? 'true' : 'false')
                  formData.append(
                    'document_faktur_status',
                    documentStateForm.faktur ? 'true' : 'false'
                  )
                  formData.append('document_ktp_status', documentStateForm.ktp ? 'true' : 'false')
                  formData.append(
                    'document_kwitansi_status',
                    documentStateForm.kwitansi ? 'true' : 'false'
                  )
                  formData.append('document_keur_status', documentStateForm.keur ? 'true' : 'false')
                  formData.append(
                    'document_form_a_status',
                    documentStateForm.formA ? 'true' : 'false'
                  )
                  formData.append('price', price.value)
                  formData.append(
                    'description',
                    catatanForm.desc === '' ? catatanForm.placeholder : catatanForm.desc
                  )
                  formData.append('thumbnail', photoThumbnail.file)
                  Array.from(listPhoto).forEach((image) => {
                    formData.append('photos', image.file)
                  })
                  const res = await apiPost({
                    url: 'jual-beli-service/admin/v2/stocks',
                    token: user.token,
                    data: formData,
                    isFormData: true,
                    onLogout
                  })
                  if (res.resStatus === 200) {
                    router.goBack()
                    showSuccessMessage({ content: 'Successfully Create Stock' })
                    onResetForm()
                  } else {
                    showErrorMessage({
                      content: `${
                        res.resData.message ??
                        res.resData.errors[0].message ??
                        'Failed Create Stock'
                      }`
                    })
                  }
                  setIsLoadingHelper(false)
                })
                return () => clearTimeout(mount)
              }
            }}
            type="submit"
            className="flex flex-row mt-4 mb-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
            Simpan
          </button>
        </div>
      </Content>
    </Layout>
  )
}

export default StockCreateUnitPage
