import { Breadcrumb, Layout } from 'antd'
import { apiGet, urls } from 'api'
import { IcEye } from 'assets/icons'
import { Button } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import WorkshopManagementComponent from './components/WorkshopManagementComponent'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null,
  province_id: '',
  city_id: ''
}

const WorkshopManagementPage = (props) => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const columnStyle = 'font-medium text-gray-500'

  const columns = [
    {
      name: 'Nama Bengkel',
      selector: (row) => row.name,
      cell: (row) => <span className={columnStyle}>{row?.name}</span>,
      grow: 1
    },
    {
      name: 'Daerah',
      selector: (row) => row.province_name,
      cell: (row) => <span className={columnStyle}>{row?.province_name}</span>,
      grow: 1
    },
    {
      name: 'Cabang',
      selector: (row) => row.city_name,
      cell: (row) => <span className={columnStyle}>{row?.city_name}</span>,
      grow: 1
    },
    {
      name: 'Jumlah Stall',
      selector: (row) => row.stall,
      cell: (row) => <span className={columnStyle}>{row?.stall}</span>,
      grow: 1
    },
    {
      name: 'Mekanik',
      selector: (row) => row.mechanic_total,
      cell: (row) => <span className={columnStyle}>{row?.mechanic_total}</span>,
      grow: 1
    },
    {
      name: 'Inspektor',
      selector: (row) => row.inspector_total,
      cell: (row) => <span className={columnStyle}>{row?.inspector_total}</span>,
      grow: 1
    },
    {
      name: 'Security',
      selector: (row) => row.security_total,
      cell: (row) => <span className={columnStyle}>{row?.security_total}</span>,
      grow: 1
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <Button
          className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
          onClick={() => props.history.push(`/workshop-management/${row.id}`)}>
          <IcEye className="text-gray-700 mr-1" size={18} />
          <span>Lihat</span>
        </Button>
      )
    }
  ]

  // Get All Workshops
  const { isLoading, isFetching, isRefetching } = useQuery({
    queryKey: [
      'workshops-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.province_id,
      tableState.city_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}?keywords=${tableState.keywords}&province_id=${tableState?.province_id}&city_id=${tableState?.city_id}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout: onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Provinces
  useQuery({
    queryKey: ['province-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.location?.province?.all}`,
        onLogout: onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setProvinces(res?.resData?.data ?? [])
    }
  })

  // Get City by province
  useQuery({
    queryKey: ['cities-list', tableState.province_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (tableState.province_id !== '' && tableState.province_id !== null) {
        const res = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.cities?.filterByProvince}/${tableState.province_id}`,
          onLogout: onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setCities(res?.resData?.data ?? [])
    }
  })

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Workshop Management</Breadcrumb.Item>
      </Breadcrumb>
      <WorkshopManagementComponent
        columns={columns}
        isLoading={isLoading ?? isFetching ?? isRefetching}
        data={tableState?.listData}
        provinces={provinces}
        cities={cities}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}

export default WorkshopManagementPage
