import React, { useCallback } from 'react'
import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button, Table } from 'components'
import { IcPlus, IcSearch } from 'assets/icons'

const ExTaxiUnitComponent = ({
  isLoading,
  columns,
  data,
  tableState,
  onAdd,
  setTableState,
  brandData,
  brandSearch,
  setBrandSearch
}) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleBrandSearch = useCallback((value) => {
    setBrandSearch(value)
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const handleChangeBrand = (value) => {
    setBrandSearch('')
    setTableState((prevState) => ({
      ...prevState,
      brand: value
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="mb-5 flex justify-between">
          <Typography.Text className="font-semibold text-2xl">Unit</Typography.Text>
          <Button
            className="flex flex-row items-center justify-around rounded-md space-x-1 px-2 py text-md text-white bg-primary-500 border-primary-500"
            onClick={onAdd}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Unit</span>
          </Button>
        </div>
        <div className="mb-5 flex justify-between">
          <div className="flex">
            <Select
              showSearch
              allowClear
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
              style={{ width: '250px' }}
              optionFilterProp="children"
              onSearch={handleBrandSearch}
              onChange={handleChangeBrand}
              placeholder="Semua Brand"
              filterOption={false}>
              {brandData?.map((brand) => (
                <Select.Option key={brand?.id?.toString()} value={brand?.id}>
                  {brand?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Input
            className="w-1/4 rounded-md"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

export default ExTaxiUnitComponent
