import React, { useState } from 'react'
import { Breadcrumb, Layout, Tag } from 'antd'
import { Button } from 'components'
import { IcEdit, IcTrash } from 'assets/icons'
import ModalUpdateRole from './components/ModalUpdateRole'
import RoleManagementComponent from './components/RoleManagementComponent'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { isRoleCompany, isRoleCostumer } from 'utils/isTypeRole'
import { showErrorMessage, showSuccessMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const RoleManagement = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [tableState, setTableState] = useState(initialTableState)
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: {}
  })
  const columns = [
    {
      name: 'Role',
      selector: (row) => row?.name,
      width: '200px'
    },
    {
      name: 'Menu',
      selector: (row) => row?.menus,
      cell: (row) => (
        <div className="flex flex-wrap mt-2">
          {row?.menus?.map((data, index) => (
            <Tag
              key={`subkategori-${index.toString()}`}
              className="px-2 py-[2px] border-0 bg-[#FEECE2] rounded-lg text-[#A21226] mb-2 selection:text-black">
              {data}
            </Tag>
          ))}
        </div>
      )
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '150px',
      cell: (row) => {
        if (!isRoleCompany(row.id) && !isRoleCostumer(row.id)) {
          return (
            <div className="flex items-center justify-center self-center">
              <Button
                className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
                onClick={() => setModal({ show: true, type: 'edit', data: row })}>
                <IcEdit className="text-gray-700" size={16} />
              </Button>
              <Button
                className="bg-white border-solid border-[#FDA19B] hover:bg-red-50"
                onClick={() => setModal({ show: true, type: 'delete', data: row })}>
                <IcTrash className="text-primary" size={16} />
              </Button>
            </div>
          )
        }
      }
    }
  ]
  const { isLoading, refetch } = useQuery({
    queryKey: [
      'role-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'user-service/admin/roles',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const saveData = async (data) => {
    try {
      setTableState((prevState) => ({
        ...prevState,
        loading: true
      }))
      let res
      let body = {
        ...data
      }

      body = {
        ...body
      }

      if (data?.id) {
        let id = data?.id
        res = await apiPut({
          token,
          data: body,
          url: `user-service/admin/roles/${id}`,
          onLogout: () => dispatch(logout())
        })
      } else {
        res = await apiPost({
          token,
          data: body,
          url: `user-service/admin/roles`,
          onLogout: () => dispatch(logout())
        })
      }
      console.log(res)
      if (res?.resStatus === 201 || res?.resStatus === 200) {
        showSuccessMessage({ content: `Successfully ${data?.id ? 'Edit' : 'Create a New'} Role Management.` })
        setModal({
          ...modal,
          show: false
        })
        refetch()
      } else {
        showErrorMessage({
          content: `${res.resData.message ?? res.resData.errors[0].message ?? `Failed to ${data?.id ? 'Edit' : 'Create'} Role Management.`
            }`
        })
      }
    } catch (error) {
      console.log(error)
      setModal({
        ...modal,
        show: false
      })
    }
  }

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        token,
        data: {},
        url: `user-service/admin/roles/${data?.id}`,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 201 || res?.resStatus === 200) {
        showSuccessMessage({ content: 'Successfully Delete Role Management.' })
        setModal({
          ...modal,
          show: false
        })
        refetch()
      } else {
        showErrorMessage({
          content: `${res.resData.message ?? res.resData.errors[0].message ?? 'Failed to Delete Role Management.'
            }`
        })
      }
    } catch (error) {
      console.log(error)
      setModal({
        ...modal,
        show: false
      })
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Role Management</Breadcrumb.Item>
      </Breadcrumb>
      <ModalUpdateRole
        isOpen={modal.show}
        type={modal.type}
        data={modal.data}
        onSubmit={saveData}
        onDelete={deleteData}
        onCancel={() => setModal({ ...modal, show: false })}
      />
      <RoleManagementComponent
        isLoading={isLoading}
        columns={columns}
        data={tableState?.listData}
        onAdd={() => setModal({ show: true, type: 'add', data: {} })}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}

export default RoleManagement
