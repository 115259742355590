import React from 'react'

const IcChrome = ({ className, size = 20 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1951_43123)">
      <path
        d="M9.99984 6.66663C8.15889 6.66663 6.6665 8.15901 6.6665 9.99996C6.6665 11.8409 8.15889 13.3333 9.99984 13.3333C11.8408 13.3333 13.3332 11.8409 13.3332 9.99996C13.3332 8.15901 11.8408 6.66663 9.99984 6.66663ZM9.99984 6.66663H17.6415M3.2915 5.04996L7.1165 11.6666M9.0665 18.2833L12.8832 11.6666M18.3332 9.99996C18.3332 14.6023 14.6022 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6023 1.6665 9.99996C1.6665 5.39759 5.39746 1.66663 9.99984 1.66663C14.6022 1.66663 18.3332 5.39759 18.3332 9.99996Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1951_43123">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IcChrome
