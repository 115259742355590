import { Card, Collapse, Image, Typography } from 'antd'
import { IcChevronDown, IcDislike, IcLike, IcSettings } from 'assets/icons'

const InspeksiResultCard = ({ data = [] }) => {
  const onReturnBadgeGrade = (grade) => {
    switch (grade?.toLowerCase()) {
      case 'ok': {
        return (
          <div className="py-1 px-3 rounded-xl bg-success-50 flex gap-1 items-center">
            <IcLike size={12} />
            <Typography.Text className="font-medium text-sm text-success-700">
              {grade ?? '-'}
            </Typography.Text>
          </div>
        )
      }

      case 'perlu perbaikan': {
        return (
          <div className="py-1 px-3 rounded-xl bg-warning-50 flex gap-1 items-center">
            <IcSettings className="text-warning-500" size={12} />
            <Typography.Text className="font-medium text-sm text-danger-700">
              {grade ?? '-'}
            </Typography.Text>
          </div>
        )
      }

      case 'jelek': {
        return (
          <div className="py-1 px-3 rounded-xl bg-danger-50 flex gap-1 items-center">
            <IcDislike size={12} />
            <Typography.Text className="font-medium text-sm text-danger-700">
              {grade ?? '-'}
            </Typography.Text>
          </div>
        )
      }

      default: {
        return (
          <div className="py-1 px-3 rounded-xl bg-gray-100 flex gap-1">
            <Typography.Text className="font-medium text-sm text-gray-700">
              {grade === '' ? 'Belum Ada' : grade}
            </Typography.Text>
          </div>
        )
      }
    }
  }

  return data?.map((category, idx) => (
    <Card key={idx} className="rounded-lg mb-4">
      <div className="flex items-center gap-2 justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
        <Typography.Text className="font-semibold text-2xl">{category?.name}</Typography.Text>

        <div className="flex gap-2 items-center">
          <Typography.Text className="font-normal text-sm text-gray-500">{`Total Penilaian: ${
            category?.assessment_point ?? '-'
          }`}</Typography.Text>
          {onReturnBadgeGrade(category?.grade)}
        </div>
      </div>
      <Collapse
        className="inspeksi-collapse"
        ghost
        expandIconPosition="end"
        expandIcon={(collapse) => {
          const className = collapse.isActive ? '-rotate-180' : ''
          return (
            <div>
              <IcChevronDown className={`text-gray-900 ${className}`} size={18} />
            </div>
          )
        }}>
        {category?.inspectionSubCategoryResults?.map((sub, idy) => (
          <Collapse.Panel
            key={idy}
            header={sub?.name}
            className="inspeksi-collapse-header font-medium text-[18px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {sub?.inspectionPartResults?.map((part, idz) => (
                <div
                  key={idz}
                  className="flex flex-col space-y-2 w-full h-fit items-start justify-start ">
                  <div className="flex justify-between gap-2 items-center w-full">
                    <Typography.Text className="text-gray-500 font-normal text-[14px] text-left">
                      {part?.name}
                    </Typography.Text>
                    <div className="flex bg-gray-100 px-3 py-1 rounded-2xl">
                      <Typography.Text className="text-sm text-bluegray-700 whitespace-nowrap">
                        {part?.inspectionDescriptionResult?.name ?? '-'}
                      </Typography.Text>
                    </div>
                  </div>
                  <div className="flex flex-row space-x-4 items-start justify-start">
                    {part?.inspectionDescriptionResult?.photo_filename &&
                    part?.inspectionDescriptionResult?.photo_filename !== '' ? (
                      <Image
                        src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${part?.inspectionDescriptionResult?.photo_filename}`}
                        alt={`img-${part?.inspectionDescriptionResult?.inspection_part_result_id}`}
                        className="rounded-lg"
                        width={100}
                        height={100}
                      />
                    ) : null}
                    {part?.inspectionDescriptionResult?.description &&
                    part?.inspectionDescriptionResult?.description !== '' ? (
                      <span className="font-normal text-gray-500 text-sm">{`catatan: ${part?.inspectionDescriptionResult?.description}`}</span>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </Collapse.Panel>
        ))}
      </Collapse>
    </Card>
  ))
}

export default InspeksiResultCard
