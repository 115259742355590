import React, { useState, useRef } from 'react'
import { Button, Layout, Typography } from 'antd'
import Countdown from 'react-countdown'
import { Content } from 'antd/lib/layout/layout'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { apiGet, apiPost, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { authenticate } from 'store/actions'
import { getProfile } from 'store/actions/auth'
import { setAccessMenu } from 'store/actions/auth'
import OtpInput from 'components/OtpInput'
import { showErrorMessage } from 'utils'

const { Text, Title } = Typography

const OTPPage = () => {
  const [otp, setOtp] = useState('')
  const otpRef = useRef()
  const ref = useRef(Date.now())
  const router = useHistory()
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.auth.profile)

  const [countdown, setCountdown] = useState({
    key: 1,
    show: true
  })
  const time = 120000
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      setCountdown((prev) => ({
        ...prev,
        show: false
      }))
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      )
    }
  }

  useQuery({
    queryKey: ['data-auth', router?.location?.state?.data?.access_token],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (!router?.location?.state?.data?.access_token) {
        router.replace('/login')
      }
    }
  })

  const onChange = (value) => setOtp(value)

  const onSubmit = async () => {
    try {
      const res = await apiPost({
        url: `${urls.auth.user.prefix}/auth/mfa/otp/validate`,
        token: router?.location?.state?.data?.access_token,
        data: {
          otp_code: otp,
          otp_type: 1
        }
      })
      if (res?.resData?.data?.is_valid) {
        await dispatch(getProfile(res?.resData?.data?.access_token, res?.resData?.data?.user_id))
        await dispatch(authenticate(res?.resData?.data?.access_token))
        await dispatch(setAccessMenu(res?.resData?.data?.access_token))

        // replace landing page by role
        switch (profile.roleId) {
          // Mekanik
          case 4:
            return router.replace('/mekanik/services/jadwal')
          // others
          default:
            if (profile.pathMenu) {
              return router.replace(`${profile.pathMenu}`)
            }
        }
      } else {
        throw {
          message: 'OTP is Failed'
        }
      }
    } catch (error) {
      showErrorMessage({
        content: `${error.message ?? 'Internal Server Error'}`
      })
    }
  }

  const handleClickResend = () => {
    ref.current = Date.now()
    setCountdown((prev) => ({
      key: prev + 1,
      show: true
    }))
    resendOTP()
  }

  const resendOTP = async () => {
    try {
      await apiGet({
        url: `${urls.auth.user.prefix}/auth/mfa/otp/send`,
        token: router?.location?.state?.data?.access_token
      })
    } catch (error) {
      showErrorMessage({
        content: `${error.message ?? 'Internal Server Error'}`
      })
    }
  }

  return (
    <Layout>
      <Content className="h-screen flex items-center justify-center bg-background">
        <div className="bg-white w-[500px] p-4 rounded">
          <Typography className="mb-10">
            <Title level={4}>
              Welcome to
              <Text className="text-primary-500"> BirdMobil - Admin Panel</Text>
            </Title>
            <Text style={{ color: '#667085' }}>Login to access your admin account</Text>
          </Typography>
          <Title level={3} className="text-center text-medium text-md text-gray-900">
            Masukan kode OTP
          </Title>
          {/* <InputCode length={6} splitInput={3} onChange={setOtp} value={otp} /> */}
          {/* <div className="flex row mb-10 justify-around"> */}
          {/* <div
              className="border rounded-md border-solid h-[64px] w-[64px] align-center items-center justify-center flex border-gray-300"
              onClick={() => otpRef.current.focus()}>
              <Text className="font-medium text-[48px] text-gray-300">{otp?.[0] ?? '0'}</Text>
            </div>
            <div className="border rounded-md border-solid h-[64px] w-[64px] align-center items-center justify-center flex border-gray-300">
              <Text className="font-medium text-[48px] text-gray-300">{otp?.[1] ?? '0'}</Text>
            </div>
            <div className="border rounded-md border-solid h-[64px] w-[64px] align-center items-center justify-center flex border-gray-300">
              <Text className="font-medium text-[48px] text-gray-300">{otp?.[2] ?? '0'}</Text>
            </div>
            <Text className="font-medium text-[48px] text-gray-300 h-[64px]">-</Text>
            <div className="border rounded-md border-solid h-[64px] w-[64px] align-center items-center justify-center flex border-gray-300">
              <Text className="font-medium text-[48px] text-gray-300">{otp?.[3] ?? '0'}</Text>
            </div>
            <div className="border rounded-md border-solid h-[64px] w-[64px] align-center items-center justify-center flex border-gray-300">
              <Text className="font-medium text-[48px] text-gray-300">{otp?.[4] ?? '0'}</Text>
            </div>
            <div className="border rounded-md border-solid h-[64px] w-[64px] align-center items-center justify-center flex border-gray-300">
              <Text className="font-medium text-[48px] text-gray-300">{otp?.[5] ?? '0'}</Text>
            </div> */}
          {/* </div> */}
          <OtpInput value={otp} valueLength={6} onChange={onChange} />
          <input
            ref={otpRef}
            className="hidden"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <div className="text-center mb-2 mt-4">
            <Typography.Text className="text-gray-500 font-medium">
              Tidak menerima kode OTP?{' '}
              {countdown.show ? (
                <span className="text-sm-medium text-gray-300">
                  Kirim ulang dalam{' '}
                  <Countdown
                    key={countdown.key}
                    date={ref.current + time}
                    zeroPadTime={3}
                    renderer={renderer}
                  />
                </span>
              ) : (
                <a onClick={handleClickResend} className="text-primary-500 hover:text-primary-500">
                  Kirim ulang kode verifikasi
                </a>
              )}
            </Typography.Text>
          </div>
          <Button
            type="danger"
            className="w-full bg-primary-500"
            style={{ borderRadius: 6 }}
            onClick={onSubmit}>
            Masuk
          </Button>
        </div>
      </Content>
    </Layout>
  )
}

export default OTPPage
