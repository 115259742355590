import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, apiPatch, urls } from 'api'
import { IcCheckRound } from 'assets/icons'
import {
  CatatanCard,
  InformasiKelengkapanDokumen,
  InformationInspeksiSecton,
  Loading,
  PhotoCarousel
} from 'components'
import InspeksiResultCard from 'components/card/inspeksi-result-card'
import moment from 'moment'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import {
  disabledTimeDropdown,
  formatThousandsINTL,
  showErrorMessage,
  showSuccessMessage
} from 'utils'
import {
  ModalInspectionProgress,
  ModalKonfirmasiStockJadwalInspeksi,
  ModalKonfirmasiStockUnit
} from '../stock-unit/components'
import _ from 'lodash'

const StockUnitHasilInspeksi = () => {
  const router = useHistory()
  const { id, idStock } = useParams()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const [detail, setDetail] = useState({})
  const [dataStock, setDataStock] = useState()
  const [dropDownTime, setDropDownTime] = useState([])
  const [modalKonfirmasiUnit, setModalKonfirmasiUnit] = useState({
    show: false,
    id: 0,
    unit: '',
    price: 0,
    owner: ''
  })
  const [modalKonfirmasiJadwalInspeksi, setModalKonfirmasiJadwalInspeksi] = useState({
    id: 0,
    show: false,
    date: moment().format('DD/MM/YYYY'),
    time: '',
    bengkel: ''
  })
  const [modalInspectionProgress, setModalInspectionProgress] = useState({
    show: false,
    title: '',
    text: ''
  })
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  // Get Detail Unit
  const { isLoading, isRefetching } = useQuery({
    queryKey: ['detail-unit', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection.prefix}/${urls?.inspection.rootAdmin}/${id}`,
        onLogout
      })
      if (res?.resStatus !== 200) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setDetail(res?.resData?.data ?? {})
    }
  })

  //   Get Detail Stock
  const { refetch: refetchStock } = useQuery({
    queryKey: ['data-stock', idStock],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}/${idStock}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDataStock(res?.resData?.data ?? {})
    }
  })

  useQuery({
    queryKey: ['schedule-time'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.jualUnit.stock.schedule.prefix}/${urls.jualUnit.stock.schedule.list}`,
        token: token,
        onLogout
      }),
    onSuccess: (data) => {
      if (data?.resStatus === 200) {
        if (data?.resData.data.length !== 0) {
          setDropDownTime(data?.resData.data.map((item) => ({ name: item.time, value: item.id })))
        }
      }
    }
  })

  if (isLoading ?? isRefetching) {
    return <Loading visible />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }} className="min-h-screen">
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className={'font-semibold text-gray-700'}>Jual Unit</Breadcrumb.Item>
        <Breadcrumb.Item
          className={`${'font-medium text-gray-500'} cursor-pointer`}
          onClick={() => router.goBack()}>
          Stock
        </Breadcrumb.Item>
        <Breadcrumb.Item className={'font-medium text-gray-500'}>
          {detail?.unit_name ?? '-'}
        </Breadcrumb.Item>
        <Breadcrumb.Item className={'font-medium text-gray-500'}>
          Cek hasil inspeksi
        </Breadcrumb.Item>
      </Breadcrumb>
      {modalInspectionProgress.show ? (
        <ModalInspectionProgress
          title={modalInspectionProgress.title}
          onClose={() => setModalInspectionProgress({ show: false, title: '' })}
          text={modalInspectionProgress.text}
        />
      ) : null}
      {modalKonfirmasiUnit.show ? (
        <ModalKonfirmasiStockUnit
          state={modalKonfirmasiUnit}
          onClose={() => setModalKonfirmasiUnit({ ...modalKonfirmasiUnit, show: false })}
          onSubmit={(values) => {
            setIsLoadingHelper(true)
            const mount = setTimeout(async () => {
              const res = await apiPatch({
                url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.v2}/${modalKonfirmasiUnit.id}/confirm`,
                token: token,
                data: {
                  price: values.harga
                },
                onLogout
              })
              if (res.resStatus === 200) {
                showSuccessMessage({ content: 'Konfirmasi Unit Telah Berhasil' })
                router.goBack()
              } else {
                showErrorMessage({
                  content: `${res.resData.errors[0].message ?? 'Gagal Konfirmasi Unit'}`
                })
              }
              setModalKonfirmasiUnit({ ...modalKonfirmasiUnit, show: false })
              setIsLoadingHelper(false)
            })
            return () => clearTimeout(mount)
          }}
          isLoading={isLoadingHelper}
        />
      ) : null}
      {modalKonfirmasiJadwalInspeksi.show ? (
        <ModalKonfirmasiStockJadwalInspeksi
          state={modalKonfirmasiJadwalInspeksi}
          dropDownTime={dropDownTime}
          onClose={() =>
            setModalKonfirmasiJadwalInspeksi({ ...modalKonfirmasiJadwalInspeksi, show: false })
          }
          onSubmit={(values) => {
            if (
              disabledTimeDropdown(
                dropDownTime?.find((f) => +f?.value === +values?.time)?.name,
                values?.date
              )
            ) {
              showErrorMessage({ content: 'Waktu tidak tersedia' })
            } else if (!values?.date || values?.date === '') {
              showErrorMessage({ content: 'Mohon pilih tanggal' })
            } else {
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                const res = await apiPatch({
                  url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}/${modalKonfirmasiJadwalInspeksi.id}/inspection`,
                  token: token,
                  data: {
                    inspection_date: values.date,
                    inspection_schedule_id: parseInt(values.time)
                  },
                  onLogout
                })
                if (res.resStatus === 200) {
                  showSuccessMessage({
                    content: _.startCase(res.resData.message ?? 'Berhasil Rekondisi Unit.')
                  })
                } else {
                  showErrorMessage({
                    content: `${res.resData.errors[0].message ?? 'Gagal Rekondisi Unit.'}`
                  })
                }
                refetchStock()
                setIsLoadingHelper(false)
                setModalKonfirmasiJadwalInspeksi({ ...modalKonfirmasiJadwalInspeksi, show: false })
              })
              return () => clearTimeout(mount)
            }
          }}
        />
      ) : null}
      <Content style={{ margin: 8 }} className="space-y-6">
        <div className="flex flex-row justify-end space-x-3">
          <div
            onClick={async () => {
              if (!detail?.is_recondition || dataStock?.recondition_status === 'Belum Rekondisi') {
                setModalKonfirmasiJadwalInspeksi({
                  ...modalKonfirmasiJadwalInspeksi,
                  show: true,
                  id: dataStock?.id,
                  date: moment().format('DD/MM/YYYY'),
                  time: dataStock?.time,
                  bengkel: dataStock?.branch_name
                })
              }
            }}
            className={`flex items-center justify-center w-36 px-3 py-3 rounded-md border border-solid bg-white ${
              detail?.is_recondition || dataStock?.recondition_status === 'Sedang Rekondisi'
                ? 'border-gray-200 hover:cursor-default'
                : 'border-gray-300 hover:cursor-pointer'
            }`}>
            <span
              className={`font-medium text-center ${
                detail?.is_recondition || dataStock?.recondition_status === 'Sedang Rekondisi'
                  ? 'text-gray-300'
                  : 'text-gray-700'
              }`}>
              Rekondisi Unit
            </span>
          </div>
          <div
            className={`flex flex-row items-center justify-center space-x-2 hover:cursor-pointer px-3 py-3 border-solid border ${
              dataStock?.is_inspected && dataStock?.recondition_status === 'Selesai Rekondisi'
                ? 'border-success-500 bg-success-500'
                : 'border-success-200 bg-success-200 hover:cursor-default'
            } rounded-md`}
            onClick={() => {
              if (
                dataStock?.is_inspected &&
                dataStock?.recondition_status === 'Selesai Rekondisi'
              ) {
                setModalKonfirmasiUnit({
                  show: true,
                  id: dataStock?.id,
                  unit: dataStock?.unit_name,
                  price: dataStock?.price,
                  owner: dataStock?.owner_fullname,
                  owner_phone_number: dataStock?.owner_phone_number
                })
              } else {
                setModalInspectionProgress({
                  show: true,
                  title: 'Konfirmasi Unit',
                  text:
                    dataStock?.recondition_status === 'Belum Rekondisi'
                      ? 'Lakukan Rekondisi Unit Dahulu.'
                      : 'Inspeksi Sedang Berlangsung.'
                })
              }
            }}>
            <IcCheckRound className="text-[#FFFFFF]" size={16} />
            <span className="font-medium text-[#FFFFFF]">Konfirmasi katalog</span>
          </div>
        </div>
        <InformationInspeksiSecton
          tempat={
            detail?.is_workshop
              ? detail?.workshop_name
              : `
            ${detail?.owner_address ? detail?.owner_address + ', ' : ''}
            ${detail?.location_name ? detail?.location_name + ', ' : ''}
            ${detail?.sub_district_name ? detail?.sub_district_name + ', ' : ''}
            ${detail?.city_name ? detail?.city_name + ', ' : ''}
            ${detail?.province_name ? detail?.province_name : ''}
            ${detail?.postal_code ? detail?.postal_code : ''}
            `
          }
          tanggal={
            detail?.inspection_date ? moment(detail?.inspection_date).format('DD/MM/YYYY') : ''
          }
          waktu={`${detail?.start_hour} - ${detail?.end_hour}`}
          stall={detail?.stall === '' || detail?.stall === 0 ? '-' : detail?.stall}
          inspektor={detail?.inspector_name ?? '-'}
          statusInspeksi={detail?.status ?? '-'}
        />

        <InformasiKelengkapanDokumen
          data={{
            odometer: detail?.inspectionResult?.odometer
              ? `${formatThousandsINTL(detail?.inspectionResult?.odometer ?? 0)} KM`
              : '-',
            noRangka: detail?.inspectionResult?.frame_number ?? '-',
            imageNoRangka: detail?.inspectionResult?.frame_number_photo_filename,
            noMesin: detail?.inspectionResult?.engine_number ?? '-',
            imageNoMesin: detail?.inspectionResult?.engine_number_photo_filename,
            stnk: detail?.inspectionResult?.document_stnk_status ? 'Ada' : 'Tidak Ada',
            imageStnk: detail?.inspectionResult?.stnk_photo,
            bpkb: detail?.inspectionResult?.document_bpkb_status ? 'Ada' : 'Tidak Ada',
            imageBpkb: detail?.inspectionResult?.bpkb_photo,
            faktur: detail?.inspectionResult?.document_faktur_status ? 'Ada' : 'Tidak Ada',
            ktp: detail?.inspectionResult?.document_ktp_status ? 'Ada' : 'Tidak Ada',

            kwitansi: detail?.inspectionResult?.document_kwitansi_status ? 'Ada' : 'Tidak Ada',
            keur: detail?.inspectionResult?.document_keur_status ? 'Ada' : 'Tidak Ada',
            formA: detail?.inspectionResult?.document_form_status ? 'Ada' : 'Tidak Ada',
            remark: detail?.inspectionResult?.remark ?? '-',
            engine_capacity: detail?.inspectionResult?.engine_capacity
              ? `${formatThousandsINTL(detail?.inspectionResult?.engine_capacity ?? 0)} CC`
              : '-',
            tax_expiry: detail?.inspectionResult?.tax_expiry
              ? moment(detail?.inspectionResult?.tax_expiry).format('DD-MM-YYYY')
              : '-',
            unit_ownership: detail?.inspectionResult?.unit_ownership ?? '-',
            latest_service: detail?.inspectionResult?.latest_service
              ? `${formatThousandsINTL(detail?.inspectionResult?.latest_service ?? 0)} KM`
              : '-',
            latest_service_date: detail?.inspectionResult?.latest_service_date
              ? moment(detail?.inspectionResult?.latest_service_date).format('DD-MM-YYYY')
              : '-',
            exterior_color_name: detail?.inspectionResult?.exterior_color_name ?? '-',
            interior_color_name: detail?.inspectionResult?.interior_color_name ?? '-',
            interior_material: detail?.inspectionResult?.interior_material ?? '-'
          }}
        />

        <InspeksiResultCard data={detail?.inspectionResult?.inspectionCategoryResults} />
        <PhotoCarousel
          title={'Foto Mobil'}
          data={
            detail?.inspectionResult?.inspectionResultPhotos?.map((item) => item?.photo_filename) ??
            []
          }
        />
        <CatatanCard
          desc={`${
            detail?.inspectionResult?.note && detail?.inspectionResult?.note !== ''
              ? detail?.inspectionResult?.note
              : 'Tidak ada catatan'
          }`}
        />
      </Content>
    </Layout>
  )
}

export default StockUnitHasilInspeksi
