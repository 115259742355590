import React from 'react'
import { CardContainer } from 'components'

const InformasiUserCard = ({ title, data }) => {
  const fieldStyle = 'font-medium text-lg text-gray-900'
  const valueStyle = 'font-normal text-gray-700'
  const { nama, email, telepon, alamat, rentang, unit, pemilik } = data
  return (
    <CardContainer minHeight="20%">
      <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">{title}</span>
      </div>
      <div className="grid grid-cols-4 gap-4 w-full mt-4">
        {nama && (
          <div className="flex flex-col h-full">
            <span className={fieldStyle}>Nama</span>
            <span className={valueStyle}>{nama}</span>
          </div>
        )}
        {unit && (
          <div className="flex flex-col h-full">
            <span className={fieldStyle}>Unit</span>
            <span className={valueStyle}>{unit}</span>
          </div>
        )}
        {pemilik && (
          <div className="flex flex-col h-full">
            <span className={fieldStyle}>Pemilik</span>
            <span className={valueStyle}>{pemilik}</span>
          </div>
        )}
        {email && (
          <div className="flex flex-col h-full">
            <span className={fieldStyle}>Email</span>
            <span className={valueStyle}>{email}</span>
          </div>
        )}
        <div className="flex flex-col h-full">
          <span className={fieldStyle}>Nomor Telepon</span>
          <span className={valueStyle}>{telepon}</span>
        </div>
        <div className="flex flex-col h-full">
          <span className={fieldStyle}>Alamat</span>
          <span className={valueStyle}>{alamat}</span>
        </div>
        {rentang && (
          <div className="flex flex-col h-full">
            <span className={fieldStyle}>Rentang Booking</span>
            <span className={valueStyle}>{rentang}</span>
          </div>
        )}
      </div>
    </CardContainer>
  )
}

export default InformasiUserCard
