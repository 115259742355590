import { Input, Modal, Typography } from 'antd'
import { IcSearch, IcTool } from 'assets/icons'
import { CustomCheckbox, Table } from 'components'

const ModalAttachServisUmum = ({
  isOpen,
  onCancel,
  data,
  search,
  setSearch,
  formik,
  selected,
  setSelected,
  type = 'general'
}) => {
  const handleCheck = (e) => {
    const value = parseInt(e?.target?.value)
    if (e?.target?.checked) {
      setSelected((prev) => [...prev, value])
    } else {
      setSelected((prev) => prev?.filter?.((f) => f !== value))
    }
  }

  const isChecked = (val) => {
    return selected?.some((s) => s === val)
  }

  const handleOk = () => {
    if (type === 'packet') {
      formik?.setFieldValue('packet_ids', selected)
    } else {
      formik?.setFieldValue('general_service_ids', selected)
    }
    setSelected([])
    setSearch('')
    onCancel()
  }

  const columnData = [
    {
      name: '',
      cell: (row) => (
        <label
          htmlFor={`check-${row?.id}`}
          className={[
            'p-2 flex items-center rounded-lg my-1 border-solid border w-full',
            isChecked(row?.id) ? 'bg-primary-50 border-primary-300' : 'bg-white border-white'
          ].join(' ')}>
          <span className="bg-primary-100 rounded-full flex w-fit items-center justify-center p-2">
            <IcTool className="text-primary-500" size={16} />
          </span>
          <Typography.Text className="flex-1 mx-2">{row?.name}</Typography.Text>
          <CustomCheckbox
            id={`check-${row?.id}`}
            value={row?.id}
            onChange={handleCheck}
            checked={selected?.some?.((s) => s === row?.id)}
          />
        </label>
      )
    }
  ]

  return (
    <Modal
      width={400}
      title={`Attach ${type === 'packet' ? 'Paket Servis' : 'Servis Umum'}`}
      open={isOpen}
      onCancel={() => {
        onCancel()
        setSelected([])
        setSearch('')
      }}
      onOk={handleOk}
      okText={'Confirm'}
      okButtonProps={{ className: 'bg-primary-500 rounded-md', type: 'danger' }}
      cancelButtonProps={{
        className: 'hover:text-[#000] rounded-md hover:border-[#000]',
        type: 'default'
      }}>
      <div className="bg-gray-50 p-2 rounded-lg">
        <Input
          className="rounded-md py-1.5"
          placeholder="Cari Layanan"
          prefix={<IcSearch className="text-gray-400" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="overflow-y-auto h-52 mt-2">
          <Table
            columns={columnData}
            data={data}
            hidePagination={true}
            noTableHead={true}
            cellStyles={{ paddingLeft: '0px', paddingRight: '0px' }}
            tableStyles={{ background: 'none', border: '0px' }}
            rowsStyles={{ background: 'none', borderBottom: '0px !important' }}
            noStripped={true}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalAttachServisUmum
