import React from 'react'

const IcBox = ({ className, size = 20, currentColor = "#C11B23" }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.18 4.64124L8 8.0079L13.82 4.64124M8 14.7212V8.00124M14 10.6679V5.33457C13.9998 5.10075 13.938 4.87111 13.821 4.66868C13.704 4.46625 13.5358 4.29815 13.3333 4.18124L8.66667 1.51457C8.46397 1.39755 8.23405 1.33594 8 1.33594C7.76595 1.33594 7.53603 1.39755 7.33333 1.51457L2.66667 4.18124C2.46418 4.29815 2.29599 4.46625 2.17897 4.66868C2.06196 4.87111 2.00024 5.10075 2 5.33457V10.6679C2.00024 10.9017 2.06196 11.1314 2.17897 11.3338C2.29599 11.5362 2.46418 11.7043 2.66667 11.8212L7.33333 14.4879C7.53603 14.6049 7.76595 14.6665 8 14.6665C8.23405 14.6665 8.46397 14.6049 8.66667 14.4879L13.3333 11.8212C13.5358 11.7043 13.704 11.5362 13.821 11.3338C13.938 11.1314 13.9998 10.9017 14 10.6679Z"
      stroke={currentColor}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IcBox
