import { apiPut, urls } from 'api'
import { CustomButton, Loading } from 'components'
import ModalLayout from 'layouts/modal-layout'
import moment from 'moment'
import { useState } from 'react'
import { showErrorMessage } from 'utils'

const ModalDeletePickup = ({ data, onClose, openModal, token, tabs, refetch, onLogout = () => { } }) => {
  const fieldStyle = 'text-lg-medium text-gray-900'
  const valueStyle = 'text-md-regular text-gray-700'
  const containerStyle = 'flex flex-col gap-1'

  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const handleDelete = async (e) => {
    e?.preventDefault()

    try {
      setIsLoadingCRUD(true)
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.servicePickup.root}/${data?.id}/${urls.service.servicePickup.cancel}`,
        token: token,
        onLogout
      })
      if (res?.resStatus === 200) {
        onClose()
        refetch()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <ModalLayout
      className={'max-w-sm'}
      headerTitle={'Hapus Data Pickup Unit Servis'}
      onCancel={onClose}
      openModal={openModal}
      loading={isLoadingCRUD}>
      <div className="grid grid-cols-1 gap-4">
        <div className="text-md-medium text-gray-500">
          Apakah Anda yakin ingin menghapus unit pickup servis:
        </div>
        <div className={containerStyle}>
          <span className={fieldStyle}>Unit</span>
          <span className={valueStyle}>{data?.unit_name}</span>
        </div>
        <div className={containerStyle}>
          <span className={fieldStyle}>Tanggal</span>
          <span className={valueStyle}>{moment(data?.service_date).format('DD/MM/YYYY')}</span>
        </div>
        <div className={containerStyle}>
          <span className={fieldStyle}>Waktu</span>
          <span className={valueStyle}>{`${moment(data?.service_start_time, 'HH:mm').format(
            'HH:mm'
          )} - ${moment(data?.service_end_time, 'HH:mm').format('HH:mm')}`}</span>
        </div>
        <div className={containerStyle}>
          <span className={fieldStyle}>Pemilik</span>
          <span className={valueStyle}>{data?.full_name}</span>
        </div>
        <div className={containerStyle}>
          <span className={fieldStyle}>Alamat</span>
          {
            tabs[0].active ? (
              <span className={valueStyle}>{
                `${data?.subdistrict_name ?? ''}, ${data?.city_name ?? ''}, ${data?.province_name ?? ''
                }, ${data?.pickup_address ?? ''}, ${data?.postal_code ?? ''}`
              }</span>
            ) : (
              <span className={valueStyle}>{data?.pickup_address}</span>
            )
          }
        </div>
        <div className="flex flex-row w-full items-center justify-end  space-x-4">
          {isLoadingCRUD ? (
            <Loading title="" visible classNameWidth="" />
          ) : (
            <>
              <CustomButton
                type="plain"
                onClick={() => {
                  onClose()
                }}>
                Cancel
              </CustomButton>
              <CustomButton onClick={handleDelete}>Confirm</CustomButton>
            </>
          )}
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalDeletePickup
