import CardContainer from 'components/CardContainer'
import React from 'react'

const fieldStyle = 'font-medium text-lg text-gray-900'
const valueStyle = 'font-normal text-gray-700'
const InformasiOdooCard = ({ roNumber, soNumber, invoiceNumber }) => {
  return (
    <CardContainer minHeight="40%">
      <div className="flex flex-col w-full items-start border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Informasi Odoo</span>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 pt-4 justify-items-start place-items-center">
        {soNumber && (
          <div className="flex flex-col h-full">
            <span className={fieldStyle}>Nomor SO</span>
            <span className={valueStyle}>{soNumber}</span>
          </div>
        )}
        {roNumber && (
          <div className="flex flex-col h-full">
            <span className={fieldStyle}>Nomor RO</span>
            <span className={valueStyle}>{roNumber}</span>
          </div>
        )}
        {invoiceNumber && (
          <div className="flex flex-col h-full">
            <span className={fieldStyle}>Nomor Invoice</span>
            <span className={valueStyle}>{invoiceNumber}</span>
          </div>
        )}
      </div>
    </CardContainer>
  )
}

export default InformasiOdooCard
