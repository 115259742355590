import React, { useCallback } from 'react'
import { Card, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button, FormInput, Table } from 'components'
import { IcPlus } from 'assets/icons'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

const GradeInspeksiComponent = ({
  columns,
  data,
  tableState,
  setTableState,
  isLoading,
  onCreateGrade = () => {}
}) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">Grade Inspeksi</Typography.Text>
          <Button
            className="flex flex-row items-center justify-around rounded-md border-solid border-primary-500 focus:outline-none space-x-1 px-2 py text-md text-white bg-primary-500 hover:cursor-pointer"
            onClick={onCreateGrade}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Grade Inspeksi</span>
          </Button>
        </div>
        <div className="mb-5">
          <div className="w-1/5">
            <Formik
              initialValues={{
                inspectionGradeSearch: ''
              }}>
              <FormInput
                name="inspectionGradeSearch"
                placeholder="Pencarian"
                type="text"
                isIconLeft
                iconName="search"
                onValidate={handleSearch}
              />
            </Formik>
          </div>
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

GradeInspeksiComponent.propTypes = {
  tableState: PropTypes.object,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  setTableState: PropTypes.func
}

GradeInspeksiComponent.defaultProps = {
  tableState: {},
  data: [],
  onAdd: () => {},
  setTableState: () => {}
}

export default GradeInspeksiComponent
