import React, { useEffect, useState } from 'react'
import { Input, Modal, Select, Typography } from 'antd'
import { validationInput } from 'helpers'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { apiGet } from 'api'

const ModalUpdateAdmin = ({ type, isOpen, onCancel, user, onSubmit, onDelete }) => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()

  const [name, setName] = useState({
    value: user?.fullname ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  const [email, setEmail] = useState({
    value: user?.email ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isEmail: true
    }
  })
  const [password, setPassword] = useState({
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  const [role, setRole] = useState({
    value: user?.role_id ?? null,
    valid: false,
    touched: false
  })
  const [phone, setPhone] = useState({
    value: user?.phone_number ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  })
  const [address, setAddress] = useState(user?.address ?? '')

  useEffect(() => {
    if (isOpen) {
      setName({
        ...name,
        value: user?.fullname ? user?.fullname : user?.companyName ?? '',
        touched: !!user?.fullname,
        valid: validationInput(user?.fullname, name.validation).valid,
        messageError: validationInput(user?.fullname, name.validation).messageError
      })
      setEmail({
        ...email,
        value: user?.email ?? '',
        touched: !!user?.email,
        valid: validationInput(user?.email, email.validation).valid,
        messageError: validationInput(user?.email, email.validation).messageError
      })
      setPassword({
        ...password,
        value: '',
        valid: false,
        touched: false
      })
      setRole({
        ...role,
        value: user?.role_id ?? null,
        touched: !!user?.role_id,
        valid: !!user?.role_id,
        messageError: 'Harus Diisi'
      })
      setPhone({
        ...phone,
        value: user?.phone_number ?? '',
        touched: !!user?.phone_number,
        valid: validationInput(user?.phone_number, phone.validation).valid,
        messageError: validationInput(user?.phone_number, phone.validation).messageError
      })
      setAddress(user?.address)
    }
  }, [isOpen])

  const onOk = () => {
    if (!name.valid || !password.valid || !email.valid || !phone.valid || !role.valid) {
      alert('error')
      return
    }
    onSubmit({
      id: user?.id,
      fullname: name.value,
      password: password.value,
      email: email.value,
      phone_number: phone.value,
      address: address,
      role_id: role.value
    })
  }

  const onChangeInput = (value, identifier, setIdentifier) => {
    let { valid, messageError } = validationInput(value, identifier?.validation)
    setIdentifier({
      ...identifier,
      touched: true,
      value: value,
      valid: valid,
      messageError: messageError
    })
  }

  const { data } = useQuery({
    queryKey: ['role-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        role_type: 'adm'
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'user-service/admin/roles/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onError: (error) => {
      console.log(error)
    },
    enabled: isOpen
  })

  if (type === 'delete') {
    return (
      <Modal
        title="Hapus Admin"
        open={isOpen}
        onOk={() => onDelete(user)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
        maskClosable={false}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus admin ${name.value} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      title={type === 'add' ? 'Tambah Admin' : 'Edit Admin'}
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      okText={type === 'add' ? 'Tambah Admin' : 'Edit Admin'}
      okButtonProps={{
        className: 'bg-primary-500',
        type: 'danger',
        disabled: !name.valid || !password.valid || !email.valid || !phone.valid
      }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
      maskClosable={false}>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nama Lengkap
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !name.valid && name.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Nama Admin"
          value={name.value}
          onChange={(e) => onChangeInput(e.target.value, name, setName)}
        />
        {!name.valid && name.touched && <span className="text-[red]">{name.messageError}</span>}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Email
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !email.valid && email.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Email"
          value={email.value}
          onChange={(e) => onChangeInput(e.target.value, email, setEmail)}
        />
        {!email.valid && email.touched && <span className="text-[red]">{email.messageError}</span>}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Password
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input.Password
          className={`rounded ${
            !password.valid && password.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Password"
          value={password.value}
          onChange={(e) => onChangeInput(e.target.value, password, setPassword)}
        />
        {!password.valid && password.touched && (
          <span className="text-[red]">{password.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Role
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Select
          className="custom-table-limit"
          style={{ width: '100%' }}
          placeholder="Pilih Role"
          value={role.value}
          onChange={(value) => setRole({ ...role, value: value, valid: true, touched: true })}>
          {data?.resData?.data?.map((role) => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
        {!role.valid && role.touched && <span className="text-[red]">{role.messageError}</span>}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nomor Telepon
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          type="number"
          className={`rounded ${
            !phone.valid && phone.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Nomor Telepon"
          value={phone.value}
          onChange={(e) => onChangeInput(e.target.value, phone, setPhone)}
        />
        {!phone.valid && phone.touched && <span className="text-[red]">{phone.messageError}</span>}
      </div>
      <Typography.Text className="font-medium">Alamat</Typography.Text>
      <Input.TextArea
        className="rounded mb-4"
        placeholder="Masukan Alamat Lengkap"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
    </Modal>
  )
}

export default ModalUpdateAdmin
