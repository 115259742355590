import React, { useState } from 'react'
import { BaseIcon, FormInput, FormInputRupiah, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'
import { formatCurrency } from 'utils'
import { Select } from 'antd'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { apiGet } from 'api'

const ModalConfirmationCatalog = ({ minHeight, onClose, onSubmit, state }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const { data: dataUnitList } = useQuery({
    queryKey: ['getUnitList'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token: user.token,
        url: `jual-beli-service/admin/taxis/units/${state?.taxiId}/list`,
        onLogout: () => dispatch(logout())
      })
      return res?.resData?.data
    },
    enabled: state.status === 'Booked' && Boolean(state.taxiId)
  })

  const [forms, setForms] = useState({
    name: state.name,
    address: state.address,
    phoneNumber: state.phoneNumber,
    ...(state.status === 'Booking Lunas' && {
      price: formatCurrency(state.price ?? 0)
    }),

    ...(state.status === 'Booked' && {
      antrian: state.antrian
    }),
    ...(state.status === 'In Katalog' && {
      email: state.email,
      price: state.price ?? 0
    })
  })
  const [isRequiredHarga, setIsRequiredHarga] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState(null)

  const handleChange = (key, value) => {
    setForms((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleSubmit = () => {
    if (state.status === 'In Katalog' && state.tab === 'Mobil Bekas') {
      const data = {
        name: forms?.name,
        email: forms?.email,
        address: forms?.address,
        phone_number: forms?.phoneNumber,
        price: forms?.price
      }
      onSubmit(data)
    } else {
      onSubmit(selectedUnit)
    }
  }

  const onReturnContentForm = () => {
    if (state.status === 'Booking Lunas') {
      return (
        <>
          <FormInput label="Terjual Kepada" name="name" isDisabled isButton />
          <FormInput label="Harga" name="price" isDisabled isButton />
        </>
      )
    }
    if (state.status === 'Booked') {
      return (
        <>
          {state.status !== 'In Katalog' && state.tab !== 'Mobil Bekas' ? (
            <>
              <div className="flex flex-col gap-2">
                <span className="text-gray-900 text-md-medium">Unit</span>
                <span>{state.unitName}</span>
              </div>

              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
                value={selectedUnit}
                onChange={(e) => {
                  setSelectedUnit(e)
                }}
                placeholder="Pilih Unit">
                {dataUnitList?.map((unit, idx) => (
                  <Select.Option key={idx} value={unit?.id}>
                    <div className="flex flex-col gap-2">
                      <span className="text-md-normal text-gray-900">{unit?.serial_number}</span>
                      <span className="text-xs-regular text-gray-500">{unit?.unit_name}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </>
          ) : null}

          {state.action === 'confirmation' ? (
            <FormInput label="Deal kepada user booking" name="name" isDisabled isButton />
          ) : (
            <FormInput label="User Booking" name="name" isDisabled isButton />
          )}
          {state.tab === 'Mobil Bekas' ? (
            <FormInput label="Antrian" name="antrian" isDisabled isButton />
          ) : null}
        </>
      )
    }
    if (state.status === 'In Katalog') {
      return (
        <>
          {state.tab === 'Mobil Bekas' ? (
            <>
              <FormInput
                label="Terjual Kepada"
                name="name"
                onValidate={(value) => {
                  if (!value) {
                    return 'Silahkan Masukkan Nama'
                  } else {
                    handleChange('name', value)
                  }
                }}
                isButton
              />
              <FormInput
                label="Email"
                name="email"
                onValidate={(value) => {
                  if (!value) {
                    return 'Silahkan Masukkan Email'
                  } else {
                    handleChange('email', value)
                  }
                }}
                isButton
              />
              <FormInputRupiah
                name="price"
                value={forms?.price}
                mode="normal"
                label="Harga"
                isWithLabelRp
                isEmptyField={isRequiredHarga}
                isRequired={isRequiredHarga}
                message="Silahkan Masukkan Harga"
                onChange={(value) => {
                  handleChange('price', value)
                  if (value && value !== '') {
                    setIsRequiredHarga(false)
                  } else {
                    setIsRequiredHarga(true)
                  }
                }}
              />
            </>
          ) : null}
        </>
      )
    }
  }

  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight} minWidth="min-w-[21%]">
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {state.status === 'Booked' && state.action === 'cancel'
                ? 'Konfirmasi Batalkan Booking'
                : 'Konfirmasi Penjualan'}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div className="flex flex-col w-full space-y-5">
            {state.status === 'Booked' && state.action === 'cancel' ? (
              <span className="font-medium text-gray-500">Batalkan booking untuk user:</span>
            ) : null}
            <Formik initialValues={forms}>
              <Form key="konfirmasiPenjualanForm">
                <div className="flex flex-col w-full space-y-4">
                  {onReturnContentForm()}
                  <FormInput
                    label="Alamat"
                    name="address"
                    component="textarea"
                    isDisabled={
                      state.status === 'In Katalog' && state.tab === 'Mobil Bekas' ? false : true
                    }
                    onValidate={(value) => {
                      if (!value) {
                        return 'Silahkan Masukkan Alamat'
                      } else {
                        handleChange('address', value)
                      }
                    }}
                    isButton
                  />
                  <FormInput
                    label="Nomor Telepon"
                    name="phoneNumber"
                    isDisabled={
                      state.status === 'In Katalog' && state.tab === 'Mobil Bekas' ? false : true
                    }
                    onValidate={(value) => {
                      if (!value) {
                        return 'Silahkan Masukkan Nomor Telepon'
                      } else {
                        handleChange('phoneNumber', value)
                      }
                    }}
                    isButton
                  />
                </div>
              </Form>
            </Formik>
            <div className="flex flex-row w-full items-center justify-end space-x-4">
              <button
                type="button"
                className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                type="button"
                className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500"
                onClick={handleSubmit}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalConfirmationCatalog
