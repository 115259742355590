import React from 'react'
import { CustomButton, Loading } from 'components'
import ModalLayout from 'layouts/modal-layout'

const ModalServiceSelesaiJadwalService = ({
  openModal,
  onClose,
  desc,
  status = false,
  onSubmit,
  loading = false
}) => {
  return (
    <ModalLayout
      className="max-w-md rounded-md"
      headerTitle="Konfirmasi Selesai"
      onCancel={onClose}
      openModal={openModal}>
      <div className="flex flex-col space-y-5">
        <span className="font-normal text-gray-600 mt-1">{desc ?? ''}</span>
        <div className="flex flex-row w-full items-center justify-end  space-x-4">
          {loading ? (
            <Loading title="" visible classNameWidth="" />
          ) : (
            <>
              <CustomButton type={status ? 'plain' : 'primary'} onClick={() => onClose()}>
                Close
              </CustomButton>
              {status ? <CustomButton onClick={onSubmit}>Confirm</CustomButton> : null}
            </>
          )}
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalServiceSelesaiJadwalService
