import React from 'react'

const IcCar = ({ className, size = 20 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 7.5L16.5 1.5H3.5L1 7.5M19 7.5V15M19 7.5H1M19 15V17.5H18V15M19 15H18M1 7.5V15M1 15V17.5H2V15M1 15H2M18 15H2M5.5 13C5.10218 13 4.72064 12.842 4.43934 12.5607C4.15804 12.2794 4 11.8978 4 11.5C4 11.1022 4.15804 10.7206 4.43934 10.4393C4.72064 10.158 5.10218 10 5.5 10C5.89782 10 6.27936 10.158 6.56066 10.4393C6.84196 10.7206 7 11.1022 7 11.5C7 11.8978 6.84196 12.2794 6.56066 12.5607C6.27936 12.842 5.89782 13 5.5 13ZM14.5 13C14.1022 13 13.7206 12.842 13.4393 12.5607C13.158 12.2794 13 11.8978 13 11.5C13 11.1022 13.158 10.7206 13.4393 10.4393C13.7206 10.158 14.1022 10 14.5 10C14.8978 10 15.2794 10.158 15.5607 10.4393C15.842 10.7206 16 11.1022 16 11.5C16 11.8978 15.842 12.2794 15.5607 12.5607C15.2794 12.842 14.8978 13 14.5 13Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IcCar
