import React from 'react'
import { Content } from 'antd/lib/layout/layout'
import { Card, Typography } from 'antd'
import { Button, FormInput, Table } from 'components'
import { IcPlus } from 'assets/icons'
import { Formik } from 'formik'

const KategoriServisComponent = ({
  data,
  columns,
  keywords,
  onAdd,
  currentPage,
  totalPerPage,
  dropDownDataFilter,
  lengthAllData,
  onChangePrevPage,
  onChangeNextPage,
  onChangeDropDown,
  handleSearchKeyword,
  isLoading
}) => {
  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">Kategori Servis</Typography.Text>
          <Button
            className="flex flex-row items-center justify-around rounded-md space-x-1 px-2 py text-md text-white bg-primary-500 border-primary-500"
            onClick={onAdd}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Kategori Servis</span>
          </Button>
        </div>
        <div className="mb-5">
          <div className="w-1/4 rounded-md py-1.5">
            <Formik
              initialValues={{
                searchHargaJarak: keywords
              }}>
              <FormInput
                name="searchHargaJarak"
                placeholder="Pencarian"
                type="text"
                isIconLeft
                iconName="search"
                onValidate={(value) => handleSearchKeyword(value)}
              />
            </Formik>
          </div>
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          currentPage={currentPage}
          totalPerPage={totalPerPage}
          dropDownDataFilter={dropDownDataFilter}
          lengthAllData={lengthAllData}
          onChangePrevPage={onChangePrevPage}
          onChangeNextPage={onChangeNextPage}
          onChangeDropDown={onChangeDropDown}
        />
      </Card>
    </Content>
  )
}

export default KategoriServisComponent
