import React, { useMemo, useState } from 'react'
import { BaseIcon, FormDropDown, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Formik } from 'formik'
import { apiGet, urls } from 'api'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { ModalPilihTipeKategori } from '..'
import { IcClose } from 'assets/icons'
import { Select } from 'antd'

const ModalCreateUpdateTipe = ({
  onClose,
  onSubmit,
  state,
  dataDropDownBrand,
  dataDropDownKelasUnit
}) => {
  const user = useSelector((state) => state.auth)
  const [isModalPilihKategori, setIsModalPilihKategori] = useState({
    show: false
  })
  const [dataDropDownModel, setDataDropDownModel] = useState([])
  const [tipeNameState, setTipeNameState] = useState(state.type === 'update' ? state.tipeName : '')
  const [brandSelectorState, setBrandSelectorState] = useState(
    state.type === 'update' ? state.brandId : 0
  )
  const [modelSelectorState, setModelSelectorState] = useState(
    state.type === 'update' ? state.modelId : 0
  )
  const [kelasUnitSelectorState, setKelasUnitSelectorState] = useState(
    state.type === 'update' ? state.classId : 0
  )

  const [isEmptyForm, setIsEmptyForm] = useState({
    tipe: {
      isEmpty: false,
      message: 'Silahkan Masukkan Nama Tipe'
    },
    brand: {
      isEmpty: false,
      message: 'Silahkan Pilih Brand'
    },
    model: {
      isEmpty: false,
      message: 'Silahkan Pilih Model'
    },
    classUnit: {
      isEmpty: false,
      message: 'Silahkan Pilih Kelas Unit'
    },
    categories: {
      isEmpty: false,
      message: 'Silihkan Pilih Kategori minimal 1'
    }
  })

  const [categories, setCategories] = useState([])
  const [isSearchCategory, setIsSearchCategory] = useState(false)
  const [categoriesSearch, setCategoriesSearch] = useState([])

  useQuery({
    queryKey: ['model-by-brandId'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.model.all}?brand_id=${state.brandId}`,
        token: user.token
      }),
    onSuccess: (data) => {
      setDataDropDownModel([{ name: 'Pilih Model', value: 0 }])
      if (state.type === 'update') {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDataDropDownModel((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const { refetch: refetchCategories } = useQuery({
    queryKey: ['categories-tipe'],
    refetchOnWindowFocus: true,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.category.root}`,
        token: user.token
      }),
    onSuccess: (data) => {
      if (data.resStatus === 200 && data.resData.meta.http_code === 200) {
        let temp = [...data.resData.data]
        if (state.type === 'create' && temp.length !== 0) {
          temp.map((item) => {
            item.isSelected = false
          })
        }
        if (state.type === 'update') {
          temp.map((item) => {
            if (state.categories.find((itemCh) => itemCh.id === item.id)) {
              item.isSelected = true
            } else {
              item.isSelected = false
            }
          })
        }
        setCategories(temp)
        setCategoriesSearch(temp)
      }
    }
  })

  const model = useMemo(
    () => ({
      modelDropDown: dataDropDownModel
    }),
    [dataDropDownModel, setDataDropDownModel]
  )

  const onReturnItemKategoriSelected = (title, id) => (
    <div
      key={id}
      className="flex flex-row items-center justify-center space-x-2 py-1 px-1 rounded-xl bg-primary-50">
      <span className="font-medium text-xs text-primary-700">{title}</span>
      <div
        onClick={() => {
          const tempArr = [...categories]
          const itemSelected = tempArr.find((item) => item.id === id)
          itemSelected.isSelected = false
          setCategories(tempArr)
          setIsEmptyForm({
            tipe: {
              ...isEmptyForm.tipe
            },
            brand: {
              ...isEmptyForm.brand
            },
            model: {
              ...isEmptyForm.model
            },
            classUnit: {
              ...isEmptyForm.classUnit
            },
            categories: {
              ...isEmptyForm.categories,
              isEmpty: !tempArr.find((item) => item.isSelected === true) ? true : false
            }
          })
        }}
        className="flex items-center justify-center hover:cursor-pointer">
        <IcClose className="text-primary-500" size={14} />
      </div>
    </div>
  )

  return (
    <ModalContainer>
      <ModalForm minWidth="min-w-[26%]" maxWidth="max-w-[26%]" zIndex="z-20">
        {isModalPilihKategori.show ? (
          <ModalPilihTipeKategori
            data={isSearchCategory ? categoriesSearch : categories}
            onSelected={(id) => {
              const tempArr = [...categories]
              const itemSelected = tempArr.find((item) => item.id === id)
              itemSelected.isSelected = !itemSelected.isSelected
              setCategories(tempArr)
              setIsEmptyForm({
                tipe: {
                  ...isEmptyForm.tipe
                },
                brand: {
                  ...isEmptyForm.brand
                },
                model: {
                  ...isEmptyForm.model
                },
                classUnit: {
                  ...isEmptyForm.classUnit
                },
                categories: {
                  ...isEmptyForm.categories,
                  isEmpty: false
                }
              })
            }}
            onSearch={(value) => {
              if (categories.length !== 0) {
                setIsSearchCategory(true)
                if (value && value !== '') {
                  const tempSearchArr = categories.filter((item) =>
                    item.name.toLowerCase().includes(value.toLowerCase())
                  )
                  setCategoriesSearch(tempSearchArr)
                } else {
                  setIsSearchCategory(false)
                }
              }
            }}
            onClose={() => {
              setIsSearchCategory(false)
              refetchCategories()
              setIsModalPilihKategori({ show: false })
            }}
            onSubmit={() => {
              setIsSearchCategory(false)
              setIsModalPilihKategori({ show: false })
            }}
          />
        ) : null}
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.type === 'create' ? 'Tambah' : 'Edit'} Tipe`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>

          <div className="flex flex-col w-full space-y-5">
            <Formik
              initialValues={{
                tipeName: tipeNameState
              }}>
              <FormInput
                label="Nama Tipe"
                name="tipeName"
                placeholder="Masukkan Nama Tipe"
                isRequired
                isEmpty={isEmptyForm.tipe.isEmpty}
                errorMessage={isEmptyForm.tipe.message}
                onValidate={(value) => {
                  setTipeNameState(value)
                  if (!value) {
                    setIsEmptyForm({
                      tipe: {
                        ...isEmptyForm.tipe,
                        isEmpty: true
                      },
                      brand: {
                        ...isEmptyForm.brand
                      },
                      model: {
                        ...isEmptyForm.model,
                        isEmpty: modelSelectorState
                      },
                      classUnit: {
                        ...isEmptyForm.classUnit
                      },
                      categories: {
                        ...isEmptyForm.categories
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      tipe: {
                        ...isEmptyForm.tipe,
                        isEmpty: false
                      },
                      brand: {
                        ...isEmptyForm.brand
                      },
                      model: {
                        ...isEmptyForm.model
                      },
                      classUnit: {
                        ...isEmptyForm.classUnit
                      },
                      categories: {
                        ...isEmptyForm.categories
                      }
                    })
                  }
                }}
              />
            </Formik>
            <div className="flex flex-col w-full space-y-2 mb-2">
              <span className="font-medium block text-md text-gray-700">
                Brand
                <span className="text-red-600">{' *'}</span>
              </span>
              <div className="relative">
                <Select
                  showSearch
                  allowClear
                  className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                  value={parseInt(brandSelectorState)}
                  onChange={async (value) => {
                    setBrandSelectorState(value ? parseInt(value) : 0)
                    setModelSelectorState(0)
                    if (!value || parseInt(value) < 1) {
                      setDataDropDownModel([{ name: 'Pilih Model', value: 0 }])
                      setIsEmptyForm({
                        tipe: {
                          ...isEmptyForm.tipe
                        },
                        brand: {
                          ...isEmptyForm.brand,
                          isEmpty: true
                        },
                        model: {
                          ...isEmptyForm.model
                        },
                        classUnit: {
                          ...isEmptyForm.classUnit
                        },
                        categories: {
                          ...isEmptyForm.categories
                        }
                      })
                    } else {
                      setIsEmptyForm({
                        tipe: {
                          ...isEmptyForm.tipe
                        },
                        brand: {
                          ...isEmptyForm.brand,
                          isEmpty: false
                        },
                        model: {
                          ...isEmptyForm.model
                        },
                        classUnit: {
                          ...isEmptyForm.classUnit
                        },
                        categories: {
                          ...isEmptyForm.categories
                        }
                      })
                      setDataDropDownModel([{ name: 'Pilih Model', value: 0 }])
                      const response = await apiGet({
                        url: `${urls.master.prefix}/${
                          urls.master.products.model.all
                        }?brand_id=${parseInt(value)}`,
                        token: user.token
                      })
                      if (response.resStatus === 200) {
                        if (response.resData.data.length !== 0) {
                          response.resData.data.map((item) => {
                            setDataDropDownModel((prev) => [
                              ...prev,
                              { name: item.name, value: item.id }
                            ])
                          })
                        }
                      }
                    }
                  }}
                  onSearch={(e) => {}}
                  optionFilterProp="children"
                  placeholder="Pilih/Search Brand">
                  {dataDropDownBrand.map((itemCh, indexCh) => (
                    <Select.Option key={indexCh} value={itemCh.value}>
                      {itemCh.name}
                    </Select.Option>
                  ))}
                </Select>
                {isEmptyForm.brand.isEmpty ? (
                  <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                    {`*${isEmptyForm.brand.message}`}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col w-full space-y-2 mb-2">
              <span className="font-medium block text-md text-gray-700">
                Model
                <span className="text-red-600">{' *'}</span>
              </span>
              <div className="relative">
                <Select
                  showSearch
                  allowClear
                  className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                  value={parseInt(modelSelectorState)}
                  onChange={async (value) => {
                    setModelSelectorState(value ? parseInt(value) : 0)
                    if (!value || parseInt(value) < 1) {
                      setIsEmptyForm({
                        tipe: {
                          ...isEmptyForm.tipe
                        },
                        brand: {
                          ...isEmptyForm.brand
                        },
                        model: {
                          ...isEmptyForm.model,
                          isEmpty: true
                        },
                        classUnit: {
                          ...isEmptyForm.classUnit
                        },
                        categories: {
                          ...isEmptyForm.categories
                        }
                      })
                    } else {
                      setIsEmptyForm({
                        tipe: {
                          ...isEmptyForm.tipe
                        },
                        brand: {
                          ...isEmptyForm.brand
                        },
                        model: {
                          ...isEmptyForm.model,
                          isEmpty: false
                        },
                        classUnit: {
                          ...isEmptyForm.classUnit
                        },
                        categories: {
                          ...isEmptyForm.categories
                        }
                      })
                    }
                  }}
                  onSearch={(e) => {}}
                  optionFilterProp="children"
                  placeholder="Pilih/Search Model">
                  {model.modelDropDown.map((itemCh, indexCh) => (
                    <Select.Option key={indexCh} value={itemCh.value}>
                      {itemCh.name}
                    </Select.Option>
                  ))}
                </Select>
                {isEmptyForm.model.isEmpty ? (
                  <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                    {`*${isEmptyForm.model.message}`}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <span className="font-medium text-gray-700">Kategori</span>
              {categories.filter((item) => item.isSelected === true).length > 0 ? (
                <div className="grid grid-cols-3 gap-2 w-full items-center">
                  {categories
                    .filter((itemFr) => itemFr.isSelected === true)
                    .slice(
                      0,
                      categories.filter((item) => item.isSelected === true).length < 5
                        ? categories.filter((item) => item.isSelected === true).length
                        : 4
                    )
                    .map((itemCh) => onReturnItemKategoriSelected(itemCh.name, itemCh.id))}
                  {categories.filter((item) => item.isSelected === true).length > 4 ? (
                    <div
                      onClick={() => setIsModalPilihKategori({ show: true })}
                      className="flex hover:cursor-pointer">
                      <span className="font-medium text-primary-600">{`${
                        categories.filter((item) => item.isSelected === true).length - 4
                      }+ Kategori Lainnya`}</span>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="flex flex-col space-y-1">
                <div className="max-w-xs">
                  <button
                    type="button"
                    className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                    onClick={() => {
                      setIsModalPilihKategori({ show: true })
                    }}>
                    Pilih Kategori
                  </button>
                  {isEmptyForm.categories.isEmpty ? (
                    <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                      {`*${isEmptyForm.categories.message}`}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full space-y-2 mb-2">
              <span className="font-medium block text-md text-gray-700">
                Kelas Unit
                <span className="text-red-600">{' *'}</span>
              </span>
              <div className="relative">
                <Select
                  showSearch
                  allowClear
                  className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                  value={parseInt(kelasUnitSelectorState)}
                  onChange={async (value) => {
                    setKelasUnitSelectorState(value ? value : 0)
                    if (!value || parseInt(value) < 1) {
                      setIsEmptyForm({
                        tipe: {
                          ...isEmptyForm.tipe
                        },
                        brand: {
                          ...isEmptyForm.brand
                        },
                        model: {
                          ...isEmptyForm.model
                        },
                        classUnit: {
                          ...isEmptyForm.classUnit,
                          isEmpty: true
                        },
                        categories: {
                          ...isEmptyForm.categories
                        }
                      })
                    } else {
                      setIsEmptyForm({
                        tipe: {
                          ...isEmptyForm.tipe
                        },
                        brand: {
                          ...isEmptyForm.brand
                        },
                        model: {
                          ...isEmptyForm.model
                        },
                        classUnit: {
                          ...isEmptyForm.classUnit,
                          isEmpty: false
                        },
                        categories: {
                          ...isEmptyForm.categories
                        }
                      })
                    }
                  }}
                  onSearch={(e) => {}}
                  optionFilterProp="children"
                  placeholder="Pilih/Search Kelas Unit">
                  {dataDropDownKelasUnit.map((itemCh, indexCh) => (
                    <Select.Option key={indexCh} value={itemCh.value}>
                      {itemCh.name}
                    </Select.Option>
                  ))}
                </Select>
                {isEmptyForm.classUnit.isEmpty ? (
                  <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                    {`*${isEmptyForm.classUnit.message}`}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-row w-full items-center justify-end space-x-4">
              <button
                type="button"
                className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  if (
                    tipeNameState &&
                    tipeNameState !== '' &&
                    brandSelectorState &&
                    brandSelectorState > 0 &&
                    modelSelectorState &&
                    modelSelectorState > 0 &&
                    kelasUnitSelectorState &&
                    kelasUnitSelectorState > 0 &&
                    !categories.find((item) => item.isSelected === true) === false
                  ) {
                    onSubmit({
                      tipeName: tipeNameState,
                      brandSelector: brandSelectorState,
                      modelSelector: modelSelectorState,
                      kelasUnitSelector: kelasUnitSelectorState,
                      categories: categories.filter((item) => item.isSelected === true)
                    })
                  }
                  setIsEmptyForm({
                    tipe: {
                      ...isEmptyForm.tipe,
                      isEmpty: !tipeNameState || tipeNameState === '' ? true : false
                    },
                    brand: {
                      ...isEmptyForm.brand,
                      isEmpty: !brandSelectorState || brandSelectorState < 1 ? true : false
                    },
                    model: {
                      ...isEmptyForm.model,
                      isEmpty: !modelSelectorState || modelSelectorState < 1 ? true : false
                    },
                    classUnit: {
                      ...isEmptyForm.classUnit,
                      isEmpty: !kelasUnitSelectorState || kelasUnitSelectorState < 1 ? true : false
                    },
                    categories: {
                      ...isEmptyForm.categories,
                      isEmpty: !categories.find((item) => item.isSelected === true) ? true : false
                    }
                  })
                }}
                className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                {`${state.type === 'create' ? 'Tambah Tipe' : 'Simpan'}`}
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateTipe
