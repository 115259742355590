import React, { useState } from 'react'
import { BaseIcon, ModalContainer, ModalForm, PhotoThumbnailCard } from 'components'
import { mdiClose } from '@mdi/js'

const ModalConfirmationBills = ({
  minHeight,
  maxWidth,
  minWidth,
  titleOnClose = 'Cancel',
  titleOnSubmit = 'Confirm',
  onClose,
  onSubmit
}) => {
  const [photoPaymentProof, setPhotoPaymentProof] = useState({ file: undefined, blob: undefined })
  const [isPaymentProof, setIsPaymentProof] = useState(false)

  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight} maxWidth={maxWidth} minWidth={minWidth}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">Konfirmasi Pembayaran</span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div>
            <PhotoThumbnailCard
              title="Upload bukti pembayaran"
              notes="Format file jpg, jpeg, png."
              width="w-full"
              height="h-60"
              isBorderDashed={true}
              photoDisplay="object-contain"
              isRequired={true}
              photoThumbnail={photoPaymentProof.blob}
              onChangePhotoThumbnail={({ file, blob }) => {
                setPhotoPaymentProof({ file, blob })
                if (file) {
                  setIsPaymentProof(false)
                } else {
                  setIsPaymentProof(true)
                }
              }}
            />
            {isPaymentProof && (
              <div className="flex absolute justify-start items-start px-1 py-0.5 bg-transparent font-semibold text-xs text-red-500">
                *Silahkan upload bukti foto
              </div>
            )}
          </div>
          <div className="flex flex-row w-full items-center justify-end space-x-4">
            <button
              type="button"
              className="flex flex-row mt-2 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
              onClick={() => onClose()}>
              {titleOnClose}
            </button>
            <button
              type="button"
              onClick={() => {
                if (photoPaymentProof.file) {
                  onSubmit(photoPaymentProof.file)
                  setIsPaymentProof(false)
                } else {
                  setIsPaymentProof(true)
                }
              }}
              className="flex flex-row mt-2 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
              {titleOnSubmit}
            </button>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalConfirmationBills
