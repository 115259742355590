import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import MainLayout from 'layouts/MainLayout'
import KelasUnitPage from 'pages/main/master/kelasunit/KelasUnitPage'
import { PerusahanPage, PerusahaanDetailPage, UnitDetailPage } from 'pages/main/master/perusahaan'
import {
  ProdukBahanBakarPage,
  ProdukKategoriPage,
  ProdukModelPage,
  ProdukTipePage,
  ProdukTransmisiPage,
  ProdukWarnaPage
} from 'pages/main/master/products'
import UserPage from 'pages/main/master/user/UserPage'
import AdminPage from 'pages/main/master/admin/AdminPage'
import BrandPage from 'pages/main/master/products/subpages/brand'
import VoucherPage from 'pages/main/master/voucher/VoucherPage'
import VoucherDetailPage from 'pages/main/master/voucher/VoucherDetailPage'
import InternalUnit from 'pages/main/internal-unit/InternalUnit'
import InternalUnitDetail from 'pages/main/internal-unit/InternalUnitDetail'
import InspeksiDetail from 'pages/main/internal-unit/InspeksiDetail'
import ServisMobil from 'pages/main/internal-unit/ServisMobil'
import AddInternalUnit from 'pages/main/internal-unit/AddInternalUnit'
import KategoriInspeksi from 'pages/main/master/inspeksi/kategori/KategoriInspeksi'
import UpdateKategoriInspeksi from 'pages/main/master/inspeksi/kategori/UpdateKategoriInspeksi'
import Penjualan from 'pages/main/analytics/penjualan/Penjualan'
import Cabang from 'pages/main/analytics/cabang/Cabang'
import TestDrive from 'pages/main/test-drive/TestDrive'
import TestDriveDetail from 'pages/main/test-drive/TestDriveDetail'
import BagianInspeksi from 'pages/main/master/inspeksi/bagian/BagianInspeksi'
import KeteranganInspeksi from 'pages/main/master/inspeksi/keterangan/KeteranganInspeksi'
import DetailServis from 'pages/main/internal-unit/DetailServis'
import ConfigurationPage from 'pages/main/configuration/ConfigurationPage'
import AuditTrailPage from 'pages/main/dynamic-pages/audit-trail/AuditTrailPage'
import {
  KatalogPage,
  KatalogDetailPage,
  KatalogDetailInspeksiPage,
  KatalogRiwayatDetailPage,
  KatalogSOPage,
  StockCreateUnitPage,
  StockUnitPage,
  RiwayatPenjualanPage,
  RiwayatPenjualanDetailPage,
  StockUnitDetailPage,
  StockUnitHasilInspeksi,
  KatalogEditPage
} from 'pages/main/jual-unit/'
import {
  PickupServiceDetailPage,
  PickupServiceListPage
} from 'pages/main/services/pickup-service/subpages'
import { SparepartDetailPage, SparepartInventoryPage } from 'pages/main/sparepart/inventory'
import {
  HargaJarakPage,
  LocationPage,
  LocationProvincePage,
  LocationDistrictPage,
  LocationSubDistrictPage
} from 'pages/main/master/location'
import {
  JadwalServicePage,
  JadwalServiceDetailPage,
  JadwalServiceDetailServicePage,
  JadwalServiceSO,
  MekanikJadwalServicePage,
  MekanikJadwalServiceDetailPage,
  MekanikJadwalServiceDetailServicePage
} from 'pages/main/services/jadwal-service/subpages'
import HargaServicePage from 'pages/main/services/harga-service/harga-service-page'
import {
  DetailSparepartPage,
  DetailTotalUnitCabang,
  DetailTransaksiUnitPage,
  DetailWorkshopPage,
  TestDriveCabang,
  TotalUnitCabang,
  WorkshopJadwalServisDetailServisPage,
  WorkshopManagementPage
} from 'pages/main/workshop-management'
import TotalPendapatanCabang from 'pages/main/workshop-management/detail/transaksi-cabang/total-pendapatan/total-pendapatan-cabang'
import ManagemenServis from 'pages/main/services/managemen-service/ManagemenServis'
import SalesOrderPage from 'pages/main/dynamic-pages/sales-order/sales-order-page'
import InspeksiDetailPage from 'pages/main/dynamic-pages/detail-inspeksi/detail-inspeksi-page'
import UpdatePaketServis from 'pages/main/services/managemen-service/UpdatePaketServis'
import RoleManagement from 'pages/main/role-management/RoleManagement'
import {
  AnalyticsDashboardCabangTestDrivePage,
  AnalyticsDashboardCabangTotalCabangPage,
  AnalyticsDashboardCabangTotalMekanikPage,
  AnalyticsDashboardCabangTotalSecurityPage,
  AnalyticsTotalInspektorPage
} from 'pages/main/analytics/cabang/subpages'
import {
  CreateYourOwnCarDaftarUnitPage,
  CreateYourOwnCarKategoriPage,
  CreateYourOwnCarSparepartPage
} from 'pages/main/create-your-own-car'
import {
  AnalyticsUnit,
  AnalyticsUnitTotalInspeksiPage,
  AnalyticsUnitTotalJualPage,
  AnalyticsUnitTotalServisPage,
  DetailAnalyticsUnit
} from 'pages/main/analytics'
import InvoicePage from 'pages/main/dynamic-pages/invoice/InvoicePage'
import DetailServisPage from 'pages/main/dynamic-pages/detail-servis/detail-servis-page'
import DetailTotalPenjualan from 'pages/main/analytics/penjualan/detail-transasksi/detail-transasksi-penjualan'
import HasilInspeksi from 'pages/main/inspeksi/hasil-inspeksi/hasil-inspeksi'
import JadwalInspeksi from 'pages/main/inspeksi/jadwal-inspeksi/JadwalInspeksi'
import DetailHasilInspeksi from 'pages/main/inspeksi/hasil-inspeksi/detail-hasil-inspeksi'
import LakukanInspeksi from 'pages/main/internal-unit/LakukanInspeksi'
import DynamicTotalPage from 'pages/main/analytics/penjualan/dynamic-total-pages/dynamic-total-page'
import DetailTransaksiSales from 'pages/main/analytics/penjualan/detail-transasksi/detail-transaksi-sales'
import DetailServisAnalyticsPage from 'pages/main/analytics/penjualan/detail-servis/detail-servis-page'
import DetailInspeksiPage from 'pages/main/analytics/penjualan/detail-inspeksi/detail-inspeksi-page'
import KatalogInspeksiDetailPage from 'pages/main/analytics/penjualan/detail-inspeksi/katalog-inspeksi-page'
import JadwalInspeksiView from 'pages/main/inspeksi/jadwal-inspeksi/JadwalInspeksiView'
import TambahBengkel from 'pages/main/workshop-management/tambah-bengkel'
import EditBengkel from 'pages/main/workshop-management/edit-bengkel'
import JadwalDetailInspeksi from 'pages/main/inspeksi/jadwal-inspeksi/JadwalInspeksiDetail'
import ExTaxiUnit from 'pages/main/ex-taxi/unit/ExTaxiUnit'
import UpdateExTaxiUnit from 'pages/main/ex-taxi/unit/UpdateExTaxiUnit'
import DetailExTaxiUnit from 'pages/main/ex-taxi/unit/DetailExTaxiUnit'
import JadwalInspeksiSO from 'pages/main/inspeksi/jadwal-inspeksi/JadwalInspkesiSO'
import SparepartUnit from 'pages/main/sparepart/unit/SparepartUnit'
import UpdateSparepartUnit from 'pages/main/sparepart/unit/UpdateSparepartUnit'
import AddEditUserCompany from 'pages/main/master/perusahaan/subpages/perusahaandetail/add-edit-user-company'
import BookingMobil from 'pages/main/booking-mobil/BookingMobil'
import BookingMobilDetail from 'pages/main/booking-mobil/BookingMobilDetail'
import BookingMobilDetailExTaxi from 'pages/main/booking-mobil/BookingMobilDetailExTaxi'
import SparepartCategory from 'pages/main/sparepart/category/SparepartCategory'
import GradeInspeksi from 'pages/main/master/inspeksi/grade/GradeInspeksi'
import DetailInspeksiTransaksiCabang from 'pages/main/workshop-management/detail/transaksi-cabang/detail-inspeksi-transaksi-cabang'
import DetailInspeksiAnalyticUnit from 'pages/main/analytics/unit/detail-inspeksi-analytic-unit'
import NotificationPage from 'pages/main/notification/notification-page'
import RilisKendaraaan from 'pages/main/rilis-kendaraan/RilisKendaraan'
import DetailTransaksiAnalyticPage from 'pages/main/analytics/penjualan/detail-transasksi/detail-transaksi'
import HargaRekomendasiPage from 'pages/main/master/harga-rekomendasi/HargaRekomendasiPage'
import ProfileDetail from 'pages/main/profile/profile-detail'
import { isRoleSuperAdmin } from 'utils/isTypeRole'
import LandingPage from 'pages/main/content-management/landing-page/landing-page'

const MainRoute = ({ profile }) => (
  <MainLayout profile={profile}>
    {JSON.parse(process.env.REACT_APP_MEKANIK_VIEW_ROLE_ACCESS ?? [])?.includes(profile?.roleId) ? (
      <Switch>
        {/* Switch / Route - Role Mekanik Access */}
        <Route path="/" exact render={() => <Redirect to="/mekanik/services/jadwal" />} />
        <Route path="/mekanik/services/jadwal" exact component={MekanikJadwalServicePage} />
        <Route
          path="/mekanik/services/jadwal/:idCabang"
          exact
          component={MekanikJadwalServiceDetailPage}
        />
        <Route
          path="/mekanik/services/jadwal/detail/:idUnit"
          exact
          component={MekanikJadwalServiceDetailServicePage}
        />
        <Route
          path="/mekanik/services/jadwal/:namaBengkel/:id/audit-trail"
          exact
          component={AuditTrailPage}
        />

        <Route path="/notification" exact component={NotificationPage} />
        <Route path="*" exact render={() => <Redirect to="/" />} />
      </Switch>
    ) : (
      <Switch>
        {/* Switch / Route - Type role except Mechanic Access */}
        <Route
          path="/"
          exact
          render={() => {
            if (profile.pathMenu) {
              return <Redirect to={`${profile.pathMenu}`} />
            }
          }}
        />

        {/* Profile */}
        {isRoleSuperAdmin(profile?.roleId) && (
          <Route path="/profile" exact component={ProfileDetail} />
        )}

        {/* audit trail jual-unit */}
        <Route path="/jual-unit/katalog/mobil-bekas/audit-trail" exact component={AuditTrailPage} />
        <Route path="/jual-unit/katalog/ex-taxi/audit-trail" exact component={AuditTrailPage} />
        <Route path="/jual-unit/stock/audit-trail" exact component={AuditTrailPage} />
        {/* <Route path="/jual-unit/riwayat-penjualan/audit-trail" exact component={AuditTrailPage} /> */}
        {/* audit trail services */}
        <Route
          path="/services/pickup/:namaBengkel/:id/audit-trail"
          exact
          component={AuditTrailPage}
        />
        <Route
          path="/services/jadwal/:namaBengkel/:id/audit-trail"
          exact
          component={AuditTrailPage}
        />
        {/* Audit Trail Workshop Management */}
        {/* Jadwal Servis */}
        <Route
          path="/workshop-management/jadwal-servis/:namaBengkel/:id/audit-trail"
          exact
          component={AuditTrailPage}
        />
        {/* Jadwal Inspeksi */}
        <Route
          path="/workshop-management/jadwal-inspeksi/:namaBengkel/:id/audit-trail"
          exact
          component={AuditTrailPage}
        />
        {/* sales order */}
        <Route path="/sales-order/:path" exact component={SalesOrderPage} />
        {/* analytics dashboard */}

        <Route path="/analytics-dashboard" exact component={Penjualan} />
        <Route
          path="/analytics-dashboard/penjualan/detail-penjualan/:id"
          exact
          component={DetailTotalPenjualan}
        />
        <Route
          path="/analytics-dashboard/penjualan/detail-inspeksi/:id/detail"
          exact
          component={() => (
            <InspeksiDetailPage
              showInspeksiHarga={false}
              showOwner={false}
              showUnit={true}
              showInspeksi={true}
            />
          )}
        />
        <Route
          path="/analytics-dashboard/penjualan/detail-inspeksi/:id"
          exact
          component={DetailInspeksiPage}
        />
        <Route
          path="/analytics-dashboard/penjualan/detail-sales/:id"
          exact
          component={DetailTransaksiSales}
        />
        <Route
          path="/analytics-dashboard/penjualan/detail-servis/:id"
          exact
          component={DetailServisAnalyticsPage}
        />
        <Route
          path="/analytics-dashboard/penjualan/detail-penjualan/:id"
          exact
          component={DetailTotalPenjualan}
        />
        <Route
          path="/analytics-dashboard/penjualan/detail-transaksi/:transactionId/:unitId/:type"
          exact
          component={DetailTransaksiAnalyticPage}
        />
        <Route path="/analytics-dashboard/penjualan/:type" exact component={DynamicTotalPage} />
        <Route path="/analytics-dashboard/penjualan" exact component={Penjualan} />
        {/* analytics dashboard - cabang */}
        <Route path="/analytics-dashboard/cabang" exact component={Cabang} />
        <Route
          path="/analytics-dashboard/cabang/total-inspektor"
          exact
          component={AnalyticsTotalInspektorPage}
        />
        <Route
          path="/analytics-dashboard/cabang/total-cabang"
          exact
          component={AnalyticsDashboardCabangTotalCabangPage}
        />
        <Route
          path="/analytics-dashboard/cabang/test-drive"
          exact
          component={AnalyticsDashboardCabangTestDrivePage}
        />
        <Route
          path="/analytics-dashboard/cabang/total-mekanik"
          exact
          component={AnalyticsDashboardCabangTotalMekanikPage}
        />
        <Route
          path="/analytics-dashboard/cabang/total-security"
          exact
          component={AnalyticsDashboardCabangTotalSecurityPage}
        />
        {/* Analytics Dashboard - Unit */}
        <Route path="/analytics-dashboard/unit" exact component={AnalyticsUnit} />
        <Route
          path="/analytics-dashboard/unit/total-jual"
          exact
          component={AnalyticsUnitTotalJualPage}
        />
        <Route
          path="/analytics-dashboard/unit/total-servis"
          exact
          component={AnalyticsUnitTotalServisPage}
        />
        <Route
          path="/analytics-dashboard/unit/total-inspeksi"
          exact
          component={AnalyticsUnitTotalInspeksiPage}
        />
        <Route path="/analytics-dashboard/unit/:id/:unit" exact component={DetailAnalyticsUnit} />
        {/* Lihat detail Inspeksi */}
        <Route
          path="/analytics-dashboard/unit/:unit/:id/detail-inspeksi"
          exact
          component={() => <InspeksiDetailPage showOwner={false} />}
        />
        <Route
          path="/analytics-dashboard/unit/:unitName/detail-servis"
          exact
          component={DetailServisPage}
        />

        {/* test drive */}
        <Route path="/test-drive" exact component={TestDrive} />
        <Route path="/test-drive/:id" exact component={TestDriveDetail} />
        <Route path="/test-drive/:id/sales-order" exact component={SalesOrderPage} />
        <Route
          path="/test-drive/mobil-bekas/:namaBengkel/:id/audit-trail"
          exact
          component={AuditTrailPage}
        />
        <Route
          path="/test-drive/ex-taxi/:namaBengkel/:id/audit-trail"
          exact
          component={AuditTrailPage}
        />
        <Route path="/test-drive/:namaBengkel/invoice" exact component={InvoicePage} />
        {/* internal unit */}
        <Route path="/internal-unit" exact component={InternalUnit} />
        <Route path="/internal-unit/add" exact component={AddInternalUnit} />
        <Route path="/internal-unit/:id" exact component={InternalUnitDetail} />
        <Route
          path="/internal-unit/:unit/:id/detail-inspeksi"
          exact
          component={() => (
            <InspeksiDetailPage
              showInspeksiHarga={false}
              showOwner={false}
              showUnit={false}
              showInspeksi={true}
            />
          )}
        />
        <Route path="/internal-unit/:id/servis-mobil" exact component={ServisMobil} />
        <Route path="/internal-unit/detail-servis/:id" exact component={DetailServis} />
        <Route path="/internal-unit/:id/inspeksi" exact component={LakukanInspeksi} />
        {/* ex-taxi */}
        <Route path="/ex-taxi/unit" exact component={ExTaxiUnit} />
        <Route path="/ex-taxi/unit/tambah-unit" exact component={UpdateExTaxiUnit} />
        <Route path="/ex-taxi/unit/:id" exact component={DetailExTaxiUnit} />
        <Route path="/ex-taxi/unit/:id/edit-unit" exact component={UpdateExTaxiUnit} />
        {/* master */}
        <Route path="/master" exact component={UserPage} />
        <Route path="/master/user" exact component={UserPage} />
        <Route path="/master/admin" exact component={AdminPage} />
        <Route path="/master/voucher" exact component={VoucherPage} />
        <Route path="/master/voucher/:id" exact component={VoucherDetailPage} />
        <Route path="/master/lokasi/all" exact component={LocationPage} />
        <Route path="/master/lokasi/provinsi" exact component={LocationProvincePage} />
        <Route path="/master/lokasi/kota" exact component={LocationDistrictPage} />
        <Route path="/master/lokasi/kecamatan" exact component={LocationSubDistrictPage} />
        <Route path="/master/lokasi/harga" exact component={HargaJarakPage} />
        <Route path="/master/kelasunit" exact component={KelasUnitPage} />
        <Route path="/master/hargarekomendasi" exact component={HargaRekomendasiPage} />
        <Route path="/master/perusahaan" exact component={PerusahanPage} />
        <Route
          path="/master/perusahaan/edituserperusahaan/:idUser"
          exact
          component={AddEditUserCompany}
        />
        <Route
          path="/master/perusahaan/tambahuserperusahaan/:idCompany"
          exact
          component={AddEditUserCompany}
        />
        <Route
          path="/master/perusahaan/perusahaandetail/:id/:tabActive"
          exact
          component={PerusahaanDetailPage}
        />
        <Route
          path="/master/perusahaan/perusahaandetail/:id/unitdetail/:id"
          exact
          component={UnitDetailPage}
        />
        <Route path="/master/inspeksi/bagian-inspeksi" exact component={BagianInspeksi} />
        <Route path="/master/inspeksi/kategori-inspeksi" exact component={KategoriInspeksi} />
        <Route
          path="/master/inspeksi/kategori-inspeksi/add"
          exact
          component={UpdateKategoriInspeksi}
        />
        <Route
          path="/master/inspeksi/kategori-inspeksi/edit"
          exact
          component={UpdateKategoriInspeksi}
        />
        <Route path="/master/inspeksi/keterangan-inspeksi" exact component={KeteranganInspeksi} />
        <Route path="/master/inspeksi/grade-inspeksi" exact component={GradeInspeksi} />
        <Route path="/master/products/brand" exact component={BrandPage} />
        <Route path="/master/products/model" exact component={ProdukModelPage} />
        <Route path="/master/products/tipe" exact component={ProdukTipePage} />
        <Route path="/master/products/transmisi" exact component={ProdukTransmisiPage} />
        <Route path="/master/products/bahanbakar" exact component={ProdukBahanBakarPage} />
        <Route path="/master/products/warna" exact component={ProdukWarnaPage} />
        <Route path="/master/products/kategori" exact component={ProdukKategoriPage} />
        {/* configuration */}
        <Route path="/configuration" exact component={ConfigurationPage} />
        {/* jual unit */}
        <Route path="/jual-unit/katalog" exact component={KatalogPage} />
        <Route path="/jual-unit/katalog/:id/:type/:status" exact component={KatalogDetailPage} />
        <Route
          path="/jual-unit/katalog/detail-inspeksi/:unit"
          exact
          component={KatalogDetailInspeksiPage}
        />
        <Route
          path="/jual-unit/katalog/detail-riwayat/:unit"
          exact
          component={KatalogRiwayatDetailPage}
        />
        <Route path="/jual-unit/katalog/so/:unit" exact component={KatalogSOPage} />
        <Route path="/jual-unit/katalog/:id/edit-unit" exact component={KatalogEditPage} />
        <Route path="/jual-unit/stock" exact component={StockUnitPage} />
        <Route path="/jual-unit/stock/detail/:id" exact component={StockUnitDetailPage} />
        <Route path="/jual-unit/stock/create-unit" exact component={StockCreateUnitPage} />
        <Route
          path="/jual-unit/stock/:id/:idStock/detail-inspeksi"
          exact
          component={StockUnitHasilInspeksi}
        />
        <Route path="/jual-unit/riwayat-penjualan" exact component={RiwayatPenjualanPage} />
        <Route
          path="/jual-unit/riwayat-penjualan/detail/:id/:type"
          exact
          component={RiwayatPenjualanDetailPage}
        />
        <Route
          path="/jual-unit/katalog/:id/detail-inspeksi"
          exact
          component={() => (
            <InspeksiDetailPage
              showInspeksiHarga={false}
              showOwner={false}
              showUnit={false}
              showInspeksi={true}
              showRecommendationService={true}
            />
          )}
        />
        {/* inspeksi */}
        <Route path="/inspeksi/jadwal-inspeksi" exact component={JadwalInspeksi} />
        <Route path="/inspeksi/jadwal-inspeksi/:id" exact component={JadwalInspeksiView} />

        <Route
          path="/inspeksi/jadwal-inspeksi/:namaBengkel/:id/audit-trail"
          exact
          component={AuditTrailPage}
        />
        <Route
          path="/inspeksi/jadwal-inspeksi/:id/detail-inspeksi"
          exact
          component={() => <InspeksiDetailPage showOwner={false} showInspeksiHarga={true} />}
        />
        <Route
          path="/inspeksi/jadwal-inspeksi/:id/sales-order"
          exact
          component={JadwalInspeksiSO}
        />
        <Route path="/inspeksi/hasil-inspeksi" exact component={HasilInspeksi} />
        <Route path="/inspeksi/hasil-inspeksi/:id" exact component={DetailHasilInspeksi} />
        {/* services */}
        <Route path="/services/pickup" exact component={PickupServiceListPage} />
        <Route path="/services/pickup/detail/:idCabang" exact component={PickupServiceDetailPage} />
        <Route path="/services/jadwal" exact component={JadwalServicePage} />
        <Route path="/services/jadwal/:idCabang" exact component={JadwalServiceDetailPage} />
        <Route
          path="/services/jadwal/detail/:idUnit"
          exact
          component={JadwalServiceDetailServicePage}
        />
        <Route path="/services/jadwal/so/:idCabang" exact component={JadwalServiceSO} />
        <Route path="/services/management" exact component={ManagemenServis} />
        <Route
          path="/services/management/add-paket-servis"
          exact
          component={() => <UpdatePaketServis type="add" />}
        />
        <Route
          path="/services/management/edit-paket-servis/:id"
          exact
          component={() => <UpdatePaketServis type="edit" />}
        />
        <Route path="/services/harga" exact component={HargaServicePage} />
        {/* Workshop Management */}
        <Route path="/workshop-management" exact component={WorkshopManagementPage} />
        <Route path="/workshop-management/tambah-bengkel" exact component={TambahBengkel} />
        <Route path="/workshop-management/:cabang" exact component={DetailWorkshopPage} />
        <Route
          path="/workshop-management/:namaBengkel/:id/audit-trail"
          exact
          component={AuditTrailPage}
        />
        <Route
          path="/workshop-management/:namaBengkel/edit-bengkel"
          exact
          component={EditBengkel}
        />
        <Route
          path="/workshop-management/:namaBengkel/audit-trail"
          exact
          component={AuditTrailPage}
        />
        {/* Workshop Management - Transaksi Cabang */}
        <Route
          path="/workshop-management/:id/:cabang/total-pendapatan"
          exact
          component={TotalPendapatanCabang}
        />
        <Route
          path="/workshop-management/:cabang/detail-transaksi/:transactionId/:unitId/:type"
          exact
          component={DetailTransaksiUnitPage}
        />
        <Route
          path="/workshop-management/:id/detail-transaksi/detail-inspeksi"
          exact
          component={() => <InspeksiDetailPage showOwner={false} />}
        />
        {/* Workshop Management - Total Unit */}
        <Route
          path="/workshop-management/:id/:cabang/total-unit"
          exact
          component={TotalUnitCabang}
        />
        <Route
          path="/workshop-management/:cabang/total-unit/detail-unit/:id/:unit"
          exact
          component={DetailTotalUnitCabang}
        />
        {/* Workshop Management - Jadwal Servis */}
        <Route
          path="/workshop-management/jadwal-servis/detail-servis/:idCabang"
          exact
          component={WorkshopJadwalServisDetailServisPage}
        />
        <Route
          path="/workshop-management/jadwal-servis/invoice/:idCabang"
          exact
          component={InvoicePage}
        />
        {/* Workshop Management - Jadwal Inspeksi */}
        <Route
          path="/workshop-management/jadwal-inspeksi/detail-inspeksi/:id"
          exact
          component={InspeksiDetailPage}
        />
        <Route
          path="/workshop-management/jadwal-inspeksi/sales-order/:id"
          exact
          component={SalesOrderPage}
        />

        {/* Workshop Management - Detail Sparepart */}
        <Route
          path="/workshop-management/:cabang/sparepart/detail-sparepart/:id"
          exact
          component={DetailSparepartPage}
        />

        <Route
          path="/workshop-management/:id/:cabang/test-drive"
          exact
          component={TestDriveCabang}
        />
        {/* sparepart */}
        <Route path="/sparepart/sparepart-inventory" exact component={SparepartInventoryPage} />
        <Route
          path="/sparepart/sparepart-inventory/detail/:idSparepart"
          exact
          component={SparepartDetailPage}
        />
        <Route path="/sparepart/category" exact component={SparepartCategory} />
        <Route path="/sparepart/unit" exact component={SparepartUnit} />
        <Route path="/sparepart/unit/add" exact component={UpdateSparepartUnit} />
        <Route path="/sparepart/unit/edit/:id" exact component={UpdateSparepartUnit} />
        {/* Role Management */}
        <Route path="/role-management" exact component={RoleManagement} />
        {/* Create Your Own Car */}
        <Route
          path="/create-your-own-car/cyoc-daftar-unit"
          exact
          component={CreateYourOwnCarDaftarUnitPage}
        />
        <Route
          path="/create-your-own-car/cyoc-kategori"
          exact
          component={CreateYourOwnCarKategoriPage}
        />
        <Route
          path="/create-your-own-car/cyoc-sparepart"
          exact
          component={CreateYourOwnCarSparepartPage}
        />
        {/* {isFirstLoggedIn? <Redirect to="/" /> : null} */}
        {/* COntent Management */}
        <Route path="/content-management/landing-page" exact component={LandingPage} />
        {/* booking mobil */}
        <Route path="/booking-mobil" exact component={BookingMobil} />
        <Route path="/booking-mobil/ex-taxi/:id" exact component={BookingMobilDetailExTaxi} />
        <Route path="/booking-mobil/:id/:idCatalog" exact component={BookingMobilDetail} />
        <Route
          path="/booking-mobil/:unit/:id/detail-inspeksi"
          exact
          component={InspeksiDetailPage}
        />

        {/* rilis kendaraan */}
        <Route path="/rilis-kendaraan" exact component={RilisKendaraaan} />

        <Route path="/notification" exact component={NotificationPage} />
        <Route path="*" exact render={() => <Redirect to="/" />} />
      </Switch>
    )}
  </MainLayout>
)

export default MainRoute
