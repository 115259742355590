import React from 'react'
import { BaseIcon, CardContainer } from 'components'
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js'
import { formatCurrency } from 'utils'
import _ from 'lodash'

const InformasiPerusahaanCard = ({ data, tabs, onEditPerusahaan, onDeletePerusahaan }) => {
  const fieldStyle = 'font-medium text-gray-900 text-lg'
  const valueStyle = 'font-normal text-gray-700 text-md'

  const onReturnField = (field) => {
    switch (field) {
      case 'npwp':
        return <span className={fieldStyle}>{_.upperCase(field)}</span>
      case 'kabupaten':
        return <span className={fieldStyle}>Kota/Kabupaten</span>
      default:
        return (
          <span className={fieldStyle}>
            {`${field.substring(0, 1).toUpperCase()}${field
              .substring(1, field.length)
              .replace(/([A-Z])/g, ' $1')
              .trim()}`}
          </span>
        )
    }
  }

  const onReturnValue = (field, value) => {
    switch (field) {
      case 'potonganHarga':
        return (
          <span className={valueStyle}>
            {value && value !== 0 && value !== ''
              ? `- ${data.discountType === 1 ? formatCurrency(value) : `${value} %`}`
              : '-'}
          </span>
        )
      case 'jatuhTempo':
        return (
          <span className={valueStyle}>
            {value && value !== 0 && value !== '' ? `h+ ${value}` : '-'}
          </span>
        )
      case 'discountType':
        return (
          <span className={valueStyle}>
            {value && value > 0 ? (value === 1 ? 'Rupiah' : 'Percent') : '-'}
          </span>
        )
      case 'maksimalPotongan':
        return (
          <span className={valueStyle}>{value !== undefined ? formatCurrency(value) : '-'}</span>
        )
      case 'email':
        return <span className={valueStyle}>{value && value !== '' ? value : '-'}</span>
      default:
        return <span className={valueStyle}>{_.startCase(value)}</span>
    }
  }

  return (
    <CardContainer minHeight="20%" className={'grid grid-cols-1 gap-4'}>
      <div className="flex w-full flex-row items-center justify-between border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Informasi Perusahaan</span>
        <div className="flex flex-row items-center justify-end space-x-2">
          <button
            className="flex hover:cursor-pointer border border-solid border-gray-300 rounded-md space-x-1 bg-white justify-center items-center  px-2 py-1"
            onClick={() => onEditPerusahaan()}>
            <BaseIcon path={mdiSquareEditOutline} iconSize={16} color="#344054" />
            <span className="font-medium text-gray-700"> Edit </span>
          </button>
          {tabs[1].active ? (
            <button
              className="flex hover:cursor-pointer border border-solid border-[#FDA19B] rounded-md space-x-1 bg-white justify-center items-center  px-2 py-1"
              onClick={() => onDeletePerusahaan()}>
              <BaseIcon path={mdiTrashCanOutline} iconSize={16} color="#B32318" />
              <span className="font-medium text-[#B32318]"> Hapus </span>
            </button>
          ) : null}
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 w-full pt-3">
        {Object.entries(data)
          .filter((item) => item[0] !== 'id' && item[0] !== 'users')
          .map((item, index) => (
            <div key={index} className="flex flex-col">
              {onReturnField(item[0])}
              {onReturnValue(item[0], item[1])}
            </div>
          ))}
      </div>
    </CardContainer>
  )
}

export default InformasiPerusahaanCard
