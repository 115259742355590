import { DatePicker, Modal, Typography } from 'antd'
import { IcCalendarWeek, IcChevronDown, IcChevronLeft, IcChevronRight } from 'assets/icons'
import CardContainer from 'components/CardContainer'
import moment from 'moment'
import 'moment/locale/en-gb'
import { useMemo, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'

const CalendarCard = ({
  title,
  onNextWeek,
  onPrevWeek,
  onChangeMonth,
  onGoToAuditTrail,
  events,
  dayPropGetter,
  eventPropGetter,
  startDate = new Date()
}) => {
  const [openCalendar, setOpenCalendar] = useState(false)
  const [modal, setModal] = useState({
    show: false,
    data: {}
  })

  const nextPrevStyle =
    'flex items-center justify-center p-2 rounded-md border border-solid border-gray-300 bg-white hover:cursor-pointer'
  const localizer = momentLocalizer(moment)
  const momentCurrent = moment()

  const titleMonth = useMemo(() => {
    const now = moment(startDate).format('MMMM YYYY')
    const next = moment(startDate).add(7, 'days').format('MMMM YYYY')
    const splitNew = now?.split(' ')
    const splitNext = next?.split(' ')

    if (splitNew[1] === splitNext[1]) {
      if (splitNew[0] === splitNext[0]) {
        return now
      } else {
        return `${splitNew[0]} - ${next}`
      }
    } else {
      return `${now} - ${next}`
    }
  }, [startDate])

  return (
    <CardContainer className="flex flex-col w-full space-y-5">
      {/* Header Calendar */}
      <div className="flex flex-col w-full space-y-3">
        <div className="flex flex-row items-center justify-between">
          <span className="font-semibold text-gray-900 text-2xl">{title}</span>
          {onGoToAuditTrail && (
            <div
              onClick={() => onGoToAuditTrail()}
              className="flex items-center justify-center hover:cursor-pointer px-3 py-2 bg-white border border-solid border-gray-300 rounded-md">
              <span className="font-medium text-gray-700 text-center">Audit Trail</span>
            </div>
          )}
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-4">
            <div className="flex flex-row items-center justify-center space-x-2">
              <div className={nextPrevStyle} onClick={() => onPrevWeek()}>
                <IcChevronLeft className="text-gray-700" />
              </div>
              <div className={nextPrevStyle} onClick={() => onNextWeek()}>
                <IcChevronRight className="text-gray-700" />
              </div>
            </div>
            <div className="flex flex-col space-x-2 items-center">
              <div
                className="flex flex-row items-center space-x-2 cursor-pointer"
                onClick={() => setOpenCalendar((prev) => !prev)}>
                <span className="font-semibold text-gray-900 text-xl">{titleMonth}</span>
                <IcChevronDown className="text-gray-500" />
              </div>
              <DatePicker
                open={openCalendar}
                onOpenChange={(e) => setOpenCalendar(e)}
                value={moment(startDate)}
                onChange={(dates, dateStrings) => {
                  onChangeMonth(dateStrings)
                }}
                picker="month"
                className="custom-date-picker w-full opacity-0 h-0 p-0 m-0"
                popupClassName="custom-date-picker-dropdown"
                format={['YYYY-MM-DD']}
              />
            </div>
          </div>
          <div className="flex flex-row space-x-2 items-center justify-center px-3 py-2 bg-white border border-solid border-gray-300 rounded-md">
            <IcCalendarWeek className="text-gray-700" />
            <span className="font-medium text-gray-700 text-center text-lg">Minggu</span>
          </div>
        </div>
      </div>
      <Calendar
        defaultView={'week'}
        date={startDate}
        onNavigate={() => {}}
        toolbar={false}
        localizer={localizer}
        scrollToTime={momentCurrent.set({ h: 8, m: 0 }).toDate()}
        startAccessor="start"
        endAccessor="end"
        views={'week'}
        events={events}
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventPropGetter}
        style={{
          height: '500px',
          width: '100%'
        }}
        className="font-medium text-gray-700"
        dayLayoutAlgorithm="no-overlap"
        popup
        onSelectEvent={(e) => setModal({ show: true, data: e })}
      />
      <Modal open={modal?.show} onCancel={() => setModal({ show: false, data: {} })} footer={false}>
        <div className="flex flex-col mb-2">
          <Typography.Text className="text-lg-medium text-gray-900">Unit</Typography.Text>
          <Typography.Text className="text-md-regular text-gray-700">
            {modal?.data?.title}
          </Typography.Text>
        </div>
        <div className="flex flex-col mb-2">
          <Typography.Text className="text-lg-medium text-gray-900">Pemilik</Typography.Text>
          <Typography.Text className="text-md-regular text-gray-700">
            {modal?.data?.full_name ?? modal?.data.owner_name}
          </Typography.Text>
        </div>
        {modal?.data?.stall ? (
          <div className="flex flex-col mb-2">
            <Typography.Text className="text-lg-medium text-gray-900">Stall</Typography.Text>
            <Typography.Text className="text-md-regular text-gray-700">
              {modal?.data?.stall}
            </Typography.Text>
          </div>
        ) : null}
        {modal?.data?.start && (
          <div className="flex flex-col mb-2">
            <Typography.Text className="text-lg-medium text-gray-900">Tanggal</Typography.Text>
            <Typography.Text className="text-md-regular text-gray-700">
              {moment(modal?.data?.start).format('DD-MM-YYYY')}
            </Typography.Text>
          </div>
        )}
        <div className="flex flex-col mb-2">
          <Typography.Text className="text-lg-medium text-gray-900">Jam</Typography.Text>
          <Typography.Text className="text-md-regular text-gray-700">
            {`${moment(modal?.data?.start).format('HH:mm')} - ${moment(modal?.data?.end).format(
              'HH:mm'
            )}`}
          </Typography.Text>
        </div>
        {modal?.data?.inspector_name && (
          <div className="flex flex-col mb-2">
            <Typography.Text className="text-lg-medium text-gray-900">
              Nama Inspektor
            </Typography.Text>
            <Typography.Text className="text-md-regular text-gray-700">
              {modal?.data?.inspector_name}
            </Typography.Text>
          </div>
        )}
        {modal?.data?.mechanic_name && (
          <div className="flex flex-col mb-2">
            <Typography.Text className="text-lg-medium text-gray-900">Nama Mekanik</Typography.Text>
            <Typography.Text className="text-md-regular text-gray-700">
              {modal?.data?.mechanic_name}
            </Typography.Text>
          </div>
        )}
      </Modal>
    </CardContainer>
  )
}

export default CalendarCard
