import { apiGet, urls } from 'api'
import { CalendarCard, Loading } from 'components'
import moment from 'moment'
import JadwalInspeksiViewComponent from 'pages/main/inspeksi/jadwal-inspeksi/components/JadwalInspeksiViewComponent'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { dayPropGetter, eventPropGetterInspection } from 'utils'

const JadwalInspeksiContent = () => {
  const { cabang: id } = useParams()
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const [dataCabang, setDataCabang] = useState({})
  const [filterCalendar, setFilterCalendar] = useState({
    start_date: moment().startOf('week').format('YYYY-MM-DD'),
    end_date: moment().endOf('week').format('YYYY-MM-DD')
  })
  const [events, setEvents] = useState([])
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  // Get Detail Cabang
  const { isLoading } = useQuery({
    queryKey: ['detail-cabang', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}/${id}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDataCabang(res?.resData?.data)
    }
  })

  // Get Jadwal Service
  const { refetch: refetchSchedule } = useQuery({
    queryKey: ['jadwal-test-drive', filterCalendar?.start_date, filterCalendar?.end_date],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection?.prefix}/${
          urls?.inspection?.calendarSchedule
        }/${id}?start_date=${moment(filterCalendar?.start_date).format(
          'DD/MM/YYYY'
        )}&end_date=${moment(filterCalendar?.end_date).format('DD/MM/YYYY')}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      const reWrite = res?.resData?.data?.map((item) => {
        const parseDate = moment(item?.inspection_date).format('YYYY-MM-DD')
        return {
          ...item,
          title: item?.unit_name,
          start: moment(parseDate + ' ' + item?.start_hour).toDate(),
          end: moment(parseDate + ' ' + item?.end_hour).toDate()
        }
      })
      setEvents(reWrite)
    }
  })

  if (isLoading) {
    return <Loading visible={true} />
  }

  return (
    <>
      <CalendarCard
        title={'Jadwal Inspeksi'}
        events={events}
        startDate={new Date(filterCalendar.start_date)}
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventPropGetterInspection}
        onChangeMonth={(date) => {
          const start = new Date(date)
          start.setDate(1)
          setFilterCalendar({
            start_date: moment(start).startOf('week').format('YYYY-MM-DD'),
            end_date: moment(start).endOf('week').format('YYYY-MM-DD')
          })
        }}
        onNextWeek={() => {
          setFilterCalendar((prev) => ({
            start_date: moment(prev.start_date).add(7, 'days').format('YYYY-MM-DD'),
            end_date: moment(prev.end_date).add(7, 'days').format('YYYY-MM-DD')
          }))
        }}
        onPrevWeek={() => {
          setFilterCalendar((prev) => ({
            start_date: moment(prev.start_date).subtract(7, 'days').format('YYYY-MM-DD'),
            end_date: moment(prev.end_date).subtract(7, 'days').format('YYYY-MM-DD')
          }))
        }}
        onGoToAuditTrail={() =>
          router.push(`/workshop-management/jadwal-inspeksi/${dataCabang?.name}/${id}/audit-trail`)
        }
      />
      {/* Tabs Pickup Servis & Jadwal Servis */}

      {/* Table Confirmation Card */}
      <JadwalInspeksiViewComponent
        stall={dataCabang?.stall}
        refetchSchedule={refetchSchedule}
        onLogout={onLogout}
        token={token}
        idCabang={id}
        isWorkshop={true}
      />
    </>
  )
}

export default JadwalInspeksiContent
