import React from 'react'

const Button = ({ className, children, onClick, onSubmit, disabled }) => (
  <button
    className={`hover:cursor-pointer rounded-lg p-2 border-solid border-[1.5px] ${className}`}
    onSubmit={onSubmit}
    onClick={onClick}
    disabled={disabled}>
    {children}
  </button>
)

export default Button
