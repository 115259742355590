import React, { useEffect, useRef, useState } from 'react'
import { Modal, Typography } from 'antd'
import { IcEdit, IcImage } from 'assets/icons'
import UploadImage from 'components/UploadImage'
import { Button } from 'components'

const ModalLandingPage = ({ type, isOpen, onCancel, data, onSubmit, onDelete, setModal, isLoading }) => {
  const [banner, setBanner] = useState(null)

  let bannerRef = useRef()

  useEffect(() => {
    if (isOpen) {
      setBanner(data?.image_filename ?? null)
    }
  }, [isOpen])

  const changeBanner = (convertBanner, rawBanner) => {
    setBanner(convertBanner)
    setModal(prev => ({
      ...prev,
      data: {
        ...prev.data,
        image_filename: rawBanner
      }
    }))
  }
  const onOk = async () => {
    onSubmit({
      id: data?.id ?? null,
      image_filename: data?.image_filename,
    })
  }

  if (type === 'delete') {
    return (
      <Modal
        width={400}
        centered
        title="Hapus Banner"
        open={isOpen}
        onOk={() => onDelete(data)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus banner ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      width={450}
      centered
      title={type === 'add' ? 'Tambah Banner' : 'Edit Banner'}
      open={isOpen}
      onCancel={onCancel}
      onOk={() => onOk(data)}
      confirmLoading={isLoading}
      okText={type === 'add' ? 'Tambah Banner' : 'Edit Banner'}
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
      <div className="flex flex-col items-center mb-2 w-full gap-2">
        <div className='h-[206px] w-full relative rounded-md bg-gray-100'>
          <UploadImage
            customRef={bannerRef}
            onChangeFile={changeBanner}
            file={banner}
            oldFile={null}
            sizeClassName='h-full w-full'
            isRawFile={true}
          />
          {
            banner !== null && (
              <div className='absolute top-2 right-2 '>
                <Button
                  className="bg-white border-gray-300 hover:bg-gray-50"
                  onClick={bannerRef.current ? () => bannerRef.current.click() : () => { }}>
                  <IcEdit className="text-gray-700" size={16} />
                </Button>
              </div>
            )
          }
        </div>
        <div className="text-gray-500 text-sm">
          Ukuran gambar 16:9, ukuran file maksimal 10mb, dengan format file jpg, jpeg, png.
        </div>
      </div>

    </Modal>
  )
}

export default ModalLandingPage
