import React, { useMemo, useState } from 'react'
import { Input, Modal, Typography } from 'antd'
import { IcCheck, IcSearch } from 'assets/icons'
import { useQuery } from 'react-query'
import { logout } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet } from 'api'

const ModalSelectUnitCYOC = ({ isOpen, onCancel, onSubmit, data }) => {
  const [selectedData, setSelectedData] = useState(data ?? [])
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)

  const unitData = useQuery({
    queryKey: ['cyoc-unit-select-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: 'jual-beli-service/admin/create-your-own-car/unit-list',
        onLogout: () => dispatch(logout())
      })
      let newData = res?.resData?.data?.map((item) => {
        return {
          ...item,
          name: `${item?.brand_name} ${item?.model_name} ${item?.type_name}`
        }
      })
      return newData
    },
    onSuccess: () => {
      setSelectedData(data ?? [])
      setSearch('')
    },
    enabled: isOpen
  })

  const filterData = useMemo(() => {
    return unitData?.data?.filter((item) =>
      item?.name?.toLowerCase().includes(search.toLowerCase())
    )
  }, [search, unitData.data])

  const onClickitem = (item) => {
    let newArray = [...selectedData]
    let index = newArray.findIndex((unit) => unit?.id === item.id)
    if (index >= 0) {
      newArray.splice(index, 1)
    } else {
      newArray.push(item)
    }
    setSelectedData(newArray)
  }

  return (
    <Modal
      centered
      width={400}
      title={'Pilih Unit'}
      open={isOpen}
      onCancel={onCancel}
      okText={'Confirm'}
      onOk={() => onSubmit(selectedData)}
      okButtonProps={{ className: 'bg-primary-500 rounded-md', type: 'danger' }}
      cancelButtonProps={{
        className: 'hover:text-[#000] rounded-md hover:border-[#000]',
        type: 'default'
      }}>
      <div className="bg-gray-50 p-2 rounded-lg">
        <Input
          className="rounded-md py-1.5"
          placeholder="Cari Item"
          prefix={<IcSearch className="text-gray-400" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="overflow-y-auto h-40 mt-2">
          {filterData?.map((item) => (
            <div
              key={item.id}
              className="bg-white p-2 flex items-center rounded-lg mb-1 border-solid border-white hover:cursor-pointer"
              onClick={() => onClickitem(item)}>
              <span className="bg-primary-50 rounded-full flex w-fit items-center justify-center p-2"></span>
              <Typography.Text className="flex-1 mx-2">{item.name}</Typography.Text>

              {selectedData.some((e) => e.id === item.id) ? (
                <div className="w-4 h-4 rounded-lg bg-primary-900 flex items-center justify-center">
                  <IcCheck className="text-white" size={13} />
                </div>
              ) : (
                <div className="w-4 h-4 rounded-md bg-whites border-solid border-gray-200" />
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default ModalSelectUnitCYOC
