import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useParams } from 'react-router-dom'
import {
  CatatanCard,
  HeaderSO,
  InformasiServisCard,
  InformasiSpekHorizontalUnitCard,
  PaymentReceiptCard
} from 'components'
import { cabangDataDummies, dummyTransaksiCabang, unitDataDummies } from 'assets/dummies'
import { useHistory } from 'react-router-dom'

const JadwalServiceSO = () => {
  const { idCabang } = useParams()
  const router = useHistory()
  const [cabang, setCabang] = useState(
    cabangDataDummies.find((item) => item.cabangId === parseInt(idCabang))
  )
  const [unit, setUnit] = useState(unitDataDummies.find((item) => item.id === 0))
  const [informationService, setInformationService] = useState({
    tempat: 'Birdmobil Surabaya',
    tanggal: '24/10/2022',
    waktu: '07.00 - 09.00',
    stall: '2',
    mekanik: 'Nielsen Norman',
    statusInspeksi: 'Sedang Berlangsung'
  })
  const [payments, setPayments] = useState(dummyTransaksiCabang[0].paymentDetail)

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">Servis</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Jadwal Service
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          {cabang?.namaBengkel}
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          {`${unit.tahun} ${unit.merek} ${unit.model} ${unit.tipe} ${unit.tag}`}
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">SO</Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <div className="flex flex-col w-full space-y-5">
          <HeaderSO onPrintSO={() => router.push(`/services/jadwal/sales-order-print`)} />
          <InformasiServisCard tipe="Service" data={informationService} />
          <InformasiSpekHorizontalUnitCard data={unit} />
          <CatatanCard desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc." />
          <PaymentReceiptCard data={payments} />
        </div>
      </Content>
    </Layout>
  )
}

export default JadwalServiceSO
