import { Breadcrumb, Layout } from 'antd'
import { apiDelete, apiGet, urls } from 'api'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import LocationCabangCard from './components/location-cabang-card'
import DataInspektorContent from './data-inspektor/data-inspektor-content'
import DataMekanikContent from './data-mekanik/data-mekanik-content'
import JadwalInspeksiContent from './jadwal-inspeksi/jadwal-inspeksi-content'
import JadwalServisContent from './jadwal-servis/jadwal-servis-content'
import ModalDeleteBengkel from './modal/ModalDeleteBengkel'
// import ProdukServisContent from './produk-servis/produk-servis-content'
// import SparepartContent from './sparepart/sparepart-content'
import TransaksiCabangContent from './transaksi-cabang/transaksi-cabang-content'
import { logout } from 'store/actions'
import DataSecurityContent from './data-security/data-security-content'
import { showErrorMessage } from 'utils'
import { Loading } from 'components'

const menuItem = [
  { label: 'Transaksi Cabang', id: 0, component: TransaksiCabangContent, hash: 'transaction' },
  { label: 'Jadwal Servis', id: 1, component: JadwalServisContent, hash: 'service' },
  { label: 'Jadwal Inspeksi', id: 2, component: JadwalInspeksiContent, hash: 'inspection' },
  { label: 'Data Inspektor', id: 3, component: DataInspektorContent, hash: 'inspector' },
  { label: 'Data Mekanik', id: 4, component: DataMekanikContent, hash: 'mechanic' },
  { label: 'Data Security', id: 5, component: DataSecurityContent, hash: 'security' }
  // { label: 'Produk Servis', id: 5, component: ProdukServisContent, hash: 'product' },
  // { label: 'Sparepart', id: 6, component: SparepartContent, hash: 'sparepart' }
]

const DetailWorkshopPage = () => {
  const { cabang: idCabang } = useParams()
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const location = useLocation()
  const bigCalendarRef = useRef(null)
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const [dataCabang, setDataCabang] = useState({})
  const handleSelectedMenu = (hash) => {
    router.push({
      hash: hash
    })
  }
  const menu = useMemo(() => {
    return menuItem?.find((f) => `#${f?.hash}` === location?.hash?.split('-')?.[0])?.id ?? 0
  }, [location?.hash])

  const [modal, setModal] = useState({
    show: false
  })

  useEffect(() => {
    if (location?.hash === '#service' || location?.hash === '#inspection') {
      const mount = setTimeout(
        () =>
          window.requestAnimationFrame(() =>
            bigCalendarRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          ),
        500
      )
      return () => clearTimeout(mount)
    }
  }, [location?.hash])

  // Get Detail Cabang
  const { isLoading } = useQuery({
    queryKey: ['detail-cabang', idCabang],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}/${idCabang}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setDataCabang(res?.resData?.data)
    }
  })

  // Delete Cabang
  const handleDelete = async () => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiDelete({
        url: `${urls.master.prefix}/${urls.master.workshops.root}/${idCabang}`,
        token: token,
        onLogout: () => dispatch(logout())
      })

      if (res?.resStatus === 200) {
        router.push(`/workshop-management`)
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (isLoading) {
    return <Loading visible />
  }

  const RenderComponent = menuItem.at(menu).component

  return (
    <Layout className="grid grid-cols-1 gap-6 p-8" style={{ background: '#F5F5F5', width: '100%' }}>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Workshop Management
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          {dataCabang?.name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <ModalDeleteBengkel
        isOpen={modal.show}
        onCancel={() => setModal({ ...modal, show: false })}
        data={dataCabang}
        onDelete={handleDelete}
        isLoading={isLoadingCRUD}
      />

      {/* Location Cabang Card */}
      <LocationCabangCard
        data={dataCabang}
        onDeleteBengkel={() => setModal({ ...modal, show: true })}
      />

      {/* Tab bar */}
      <ol className="grid grid-cols-6 list-none rounded-lg overflow-hidden">
        {menuItem.map(({ label, id, hash }) => (
          <li
            onClick={handleSelectedMenu.bind(this, hash)}
            key={id}
            className={`p-4 text-sm-medium text-gray-900 text-center border-l-0 border-y border-r border-solid border-gray-300 cursor-pointer ${
              id === menu ? 'bg-gray-100 ' : 'bg-white '
            } ${
              id === 0 ? 'rounded-l-lg border-l' : id === menuItem.length - 1 ? 'rounded-r-lg' : ''
            }`}>
            {label}
          </li>
        ))}
      </ol>
      {/* Content */}
      {
        <div className="overflow-hidden" ref={bigCalendarRef}>
          <RenderComponent />
        </div>
      }
    </Layout>
  )
}

export default DetailWorkshopPage
