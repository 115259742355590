import React from 'react'

const TabsActive = ({ data, onPress }) => (
  <div className="flex flex-row bg-transparent">
    {data.map((item, index) => (
      <button
        type="button"
        key={index}
        onClick={() => onPress(index)}
        className={`py-3 my-4 mb-2 px-6 shadow-sm text-gray-900 hover:cursor-pointer text-center border border-solid border-gray-300 border-t-1 border-b-1
                 ${item.active ? 'bg-gray-100 font-semibold' : 'bg-white font-medium'}
                 ${index === 0 ? 'rounded-l-md border-r-1' : 'rounded-none border-l-0'}
                 ${data.length - 1 === index ? 'border-l-0 rounded-r-md' : 'border-l-1'}`}>
        <span>{item.name}</span>
      </button>
    ))}
  </div>
)

export default TabsActive
