import React from 'react'
import { CardContainer } from 'components'
import BadgeProgress from 'components/badge-progress'

const InformationInspeksiSecton = ({
  tempat,
  tanggal,
  waktu,
  stall,
  inspektor,
  statusInspeksi,
  grade,
  harga
}) => {
  const fieldStyle = 'font-medium text-gray-900 text-lg'
  const valueStyle = 'font-normal text-gray-700 text-md'

  return (
    <CardContainer minHeight="20%">
      <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Informasi Inspeksi</span>
      </div>
      <div className="grid grid-cols-4 gap-4 w-full pt-4">
        <div className="flex flex-col">
          <span className={fieldStyle}>Tempat</span>
          <span className={valueStyle}>{tempat}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Tanggal</span>
          <span className={valueStyle}>{tanggal}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Waktu</span>
          <span className={valueStyle}>{waktu}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Stall</span>
          <span className={valueStyle}>{stall}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Inspektor</span>
          <span className={valueStyle}>{inspektor}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Status</span>
          <BadgeProgress status={statusInspeksi} />
        </div>
        {grade && (
          <div className="flex flex-col">
            <span className={fieldStyle}>Grade</span>
            <span className={valueStyle}>{grade}</span>
          </div>
        )}
        {harga && (
          <div className="flex flex-col">
            <span className={fieldStyle}>Harga</span>
            <span className={valueStyle}>{harga}</span>
          </div>
        )}
      </div>
    </CardContainer>
  )
}

export default InformationInspeksiSecton
