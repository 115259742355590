import { Content } from 'antd/lib/layout/layout'
import {
  CatatanCard,
  InformasiKelengkapanDokumen,
  InformasiOdooCard,
  InformasiUserCard,
  InformationInspeksiSecton,
  PhotoCarousel
} from 'components'
import InspeksiRecommendationServiceCard from 'components/card/inspeksi-recommendation-service-card'
import InspeksiResultCard from 'components/card/inspeksi-result-card'
import moment from 'moment'
import { formatThousandsINTL, statusLayanan } from 'utils'

const DetailHasilInspeksiComponent = ({ data }) => {
  return (
    <Content className="min-h-screen space-y-5">
      <InformasiUserCard
        title="Informasi Unit"
        data={{
          unit: data?.unit_name ?? '-',
          pemilik: data?.owner_name ?? '-',
          telepon: data?.owner_phone_number ?? '-',
          alamat: data?.owner_address ?? '-'
        }}
      />
      <InformationInspeksiSecton
        tempat={
          data?.is_workshop
            ? data?.workshop_name
            : `
            ${data?.owner_address ? data?.owner_address + ', ' : ''}
            ${data?.location_name ? data?.location_name + ', ' : ''}
            ${data?.sub_district_name ? data?.sub_district_name + ', ' : ''}
            ${data?.city_name ? data?.city_name + ', ' : ''}
            ${data?.province_name ? data?.province_name : ''}
            ${data?.postal_code ? data?.postal_code : ''}
            `
        }
        tanggal={data?.inspection_date ? moment(data?.inspection_date).format('DD/MM/YYYY') : ''}
        waktu={`${data?.start_hour} - ${data?.end_hour}`}
        stall={data?.stall === '' || data?.stall === 0 ? '-' : data?.stall}
        inspektor={data?.inspector_name ?? '-'}
        statusInspeksi={data?.status ?? '-'}
      />
      {statusLayanan(data?.status) === 'Selesai' &&
      (data?.odoo_repair_order?.invoice_name ||
        data?.odoo_repair_order?.repair_order_name ||
        data?.odoo_sale_order?.sale_order_name) ? (
        <InformasiOdooCard
          invoiceNumber={data?.odoo_repair_order?.invoice_name}
          roNumber={data?.odoo_repair_order?.repair_order_name}
        />
      ) : null}
      {/* RECOMMENDATION SERVICE CARD */}
      <InspeksiRecommendationServiceCard data={data?.serviceRecomendation} />

      <InformasiKelengkapanDokumen
        data={{
          odometer: data?.inspectionResult?.odometer
            ? `${formatThousandsINTL(data?.inspectionResult?.odometer) ?? 0} KM`
            : '-',
          noRangka: data?.inspectionResult?.frame_number ?? '-',
          imageNoRangka: data?.inspectionResult?.frame_number_photo_filename,
          noMesin: data?.inspectionResult?.engine_number ?? '-',
          imageNoMesin: data?.inspectionResult?.engine_number_photo_filename,
          stnk: data?.inspectionResult?.document_stnk_status ? 'Ada' : 'Tidak Ada',
          imageStnk: data?.inspectionResult?.stnk_photo,
          bpkb: data?.inspectionResult?.document_bpkb_status ? 'Ada' : 'Tidak Ada',
          imageBpkb: data?.inspectionResult?.bpkb_photo,
          faktur: data?.inspectionResult?.document_faktur_status ? 'Ada' : 'Tidak Ada',
          ktp: data?.inspectionResult?.document_ktp_status ? 'Ada' : 'Tidak Ada',

          kwitansi: data?.inspectionResult?.document_kwitansi_status ? 'Ada' : 'Tidak Ada',
          keur: data?.inspectionResult?.document_keur_status ? 'Ada' : 'Tidak Ada',
          formA: data?.inspectionResult?.document_form_status ? 'Ada' : 'Tidak Ada',
          remark: data?.inspectionResult?.remark ?? '-',
          engine_capacity: data?.inspectionResult?.engine_capacity
            ? `${formatThousandsINTL(data?.inspectionResult?.engine_capacity)} CC`
            : '-',
          tax_expiry: data?.inspectionResult?.tax_expiry
            ? moment(data?.inspectionResult?.tax_expiry).format('DD-MM-YYYY')
            : '-',
          unit_ownership: data?.inspectionResult?.unit_ownership ?? '-',
          latest_service: data?.inspectionResult?.latest_service
            ? `${formatThousandsINTL(data?.inspectionResult?.latest_service) ?? 0} KM`
            : '-',
          latest_service_date: data?.inspectionResult?.latest_service_date
            ? moment(data?.inspectionResult?.latest_service_date).format('DD-MM-YYYY')
            : '-',
          exterior_color_name: data?.inspectionResult?.exterior_color_name ?? '-',
          interior_color_name: data?.inspectionResult?.interior_color_name ?? '-',
          interior_material: data?.inspectionResult?.interior_material ?? '-'
        }}
      />

      <InspeksiResultCard data={data?.inspectionResult?.inspectionCategoryResults} />
      <PhotoCarousel
        title={'Foto Mobil'}
        data={
          data?.inspectionResult?.inspectionResultPhotos?.map((item) => item?.photo_filename) ?? []
        }
      />
      <CatatanCard
        desc={`${
          data?.inspectionResult?.note && data?.inspectionResult?.note !== ''
            ? data?.inspectionResult?.note
            : 'Tidak ada catatan'
        }`}
      />
    </Content>
  )
}

export default DetailHasilInspeksiComponent
