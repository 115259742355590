import React from 'react'
import { Field, ErrorMessage } from 'formik'
import BaseIcon from 'components/BaseIcon'
import { IcSearch } from 'assets/icons'

const onSelectedIcon = ({ iconName }) => {
  switch (iconName) {
    case 'search':
      return <IcSearch className="text-gray-400" />
    case 'distance':
      return (
        <div className="flex py-3 px-2 items-center justify-center bg-gray-100 rounded-r-md">
          <span className="font-normal text-gray-900 text-center">KM</span>
        </div>
      )
    default:
      break
  }
}

const FormInput = ({
  label,
  name,
  isValue,
  value,
  type,
  isRequired,
  placeholder,
  min,
  max,
  icon,
  iconName,
  iconSize,
  iconColor,
  isIconLeft,
  isIconRight,
  isDisabled,
  isButton,
  isEmpty = false,
  isPercentage = false,
  isKM = false,
  errorMessage = '',
  onValidate = () => {},
  onKeyPress = () => {},
  onShowPassword,
  component,
  onBlur = () => {},
  height
}) => {
  const paddingFormIcon =
    isIconLeft || isIconRight
      ? (isIconLeft ? 'pl-10 pr-3' : '') || (isIconRight ? 'pl-3 pr-10' : '')
      : 'px-3'
  const iconPosition = (isIconLeft ? 'left-0' : '') || (isIconRight ? 'right-0' : '')
  return (
    <div className="w-full h-full">
      {label && (
        <label htmlFor={name} className="font-medium block mb-2 text-md text-gray-700">
          {label}
          {isRequired && <span className="text-red-600">{' *'}</span>}
        </label>
      )}
      <div className="relative">
        <div className={icon || iconName ? 'relative' : ''}>
          <Field name={name} id={name} validate={(value) => onValidate(value)}>
            {({ field, form: { touched, errors } }) =>
              component ? (
                isValue ? (
                  <textarea
                    {...field}
                    value={value}
                    disabled={isDisabled}
                    placeholder={placeholder ?? ''}
                    onBlur={(e) => onBlur(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault()
                        e.target.blur()
                        onBlur(e.target.value)
                      }
                    }}
                    style={{
                      height: height ?? ''
                    }}
                    className={`w-full font-normal text-left ${paddingFormIcon} py-3 text-gray-700  placeholder-gray-500 ${
                      isDisabled && !isButton ? 'bg-gray-200' : 'bg-white'
                    } border border-solid border-gray-300 rounded-md focus:border-primary-500 focus:ring-primary-500 focus:outline-none focus:ring focus:ring-opacity-20`}
                  />
                ) : (
                  <textarea
                    {...field}
                    disabled={isDisabled}
                    placeholder={placeholder ?? ''}
                    onBlur={(e) => onBlur(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault()
                        e.target.blur()
                        onBlur(e.target.value)
                      }
                    }}
                    style={{
                      height: height ?? ''
                    }}
                    className={`w-full font-normal text-left ${paddingFormIcon} py-3 text-gray-700  placeholder-gray-500 ${
                      isDisabled && !isButton ? 'bg-gray-200' : 'bg-white'
                    } border border-solid border-gray-300 rounded-md focus:border-primary-500 focus:ring-primary-500 focus:outline-none focus:ring focus:ring-opacity-20`}
                  />
                )
              ) : isValue ? (
                <input
                  {...field}
                  type={type ?? 'text'}
                  disabled={isDisabled}
                  placeholder={placeholder ?? ''}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault()
                      e.target.blur()
                      onKeyPress(e.target.value)
                    }
                  }}
                  value={value}
                  min={min ?? ''}
                  max={max ?? ''}
                  enterKeyHint="enter"
                  onBlur={onBlur}
                  className={`w-full font-normal text-left ${paddingFormIcon} py-3 text-gray-700  placeholder-gray-500 ${
                    isDisabled && !isButton ? 'bg-gray-200' : 'bg-white'
                  } border border-solid border-gray-300 rounded-md focus:border-primary-500 focus:ring-primary-500 focus:outline-none focus:ring focus:ring-opacity-20`}
                />
              ) : (
                <input
                  {...field}
                  type={type ?? 'text'}
                  disabled={isDisabled}
                  placeholder={placeholder ?? ''}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault()
                      e.target.blur()
                      onKeyPress(e.target.value)
                    }
                  }}
                  min={min ?? ''}
                  max={max ?? ''}
                  enterKeyHint="enter"
                  className={`w-full font-normal text-left ${paddingFormIcon} py-3 text-gray-700  placeholder-gray-500 ${
                    isDisabled && !isButton ? 'bg-gray-200' : 'bg-white'
                  } border border-solid border-gray-300 rounded-md focus:border-primary-500 focus:ring-primary-500 focus:outline-none focus:ring focus:ring-opacity-20`}
                />
              )
            }
          </Field>
          {isPercentage ? (
            <div
              className={`flex justify-center items-center absolute inset-y-0 ${iconPosition} w-10`}>
              <span className="font-normal text-gray-700">%</span>
            </div>
          ) : null}
          {isKM ? (
            <div
              className={`flex justify-center items-center absolute inset-y-0 bg-gray-200 mx-[0.9px] my-[1.25px] rounded-r-md ${iconPosition} w-10`}>
              <span className="font-normal text-gray-700">KM</span>
            </div>
          ) : null}
          {iconName ? (
            <div
              className={`flex justify-center items-center absolute inset-y-0 ${iconPosition} w-10`}>
              {onSelectedIcon({ iconName })}
            </div>
          ) : null}
          {icon ? (
            <div
              onClick={() => onShowPassword()}
              className={`flex hover:cursor-pointer justify-center items-center absolute inset-y-0 ${iconPosition} w-10`}>
              <BaseIcon path={icon} iconSize={iconSize} color={iconColor} />
            </div>
          ) : null}
        </div>
        <ErrorMessage
          name={name}
          render={(msg) => (
            <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
              {`*${msg}`}
            </div>
          )}
        />
        {isEmpty ? (
          <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
            {`*${errorMessage}`}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FormInput
