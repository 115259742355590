import { Breadcrumb, Input, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { IcEye, IcSearch } from 'assets/icons'
import { CardContainer, Table } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { formatCurrency, showErrorMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  type: ''
}

const TotalPendapatanCabang = () => {
  const { push, goBack } = useHistory()
  const { id } = useParams()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [dataCabang, setDataCabang] = useState()
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [dropdownTransaksi, setdropdownTransaksi] = useState([])

  const columnStyle = 'font-medium text-gray-700'

  const columns = [
    {
      name: 'Kode',
      selector: (row) => row?.transaction_code,
      cell: (row) => <span className={columnStyle}>{row?.transaction_code}</span>,
      grow: 1
    },
    {
      name: 'Tipe',
      selector: (row) => row?.transaction_type,
      cell: (row) => <span className={columnStyle}>{row?.transaction_type}</span>,
      grow: 1
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-2 py-3">
          <span className={columnStyle}>{row?.unit_name}</span>
          <span className="font-normal text-gray-500 tracking-tight">
            {row?.unit_police_number}
          </span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Sales',
      selector: (row) => formatCurrency(row?.transaction_profit ?? 0),
      cell: (row) => (
        <span className={columnStyle}>{formatCurrency(row?.transaction_profit ?? 0)}</span>
      ),
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          onClick={() =>
            row?.transaction_type?.toLowerCase() === 'beli'
              ? push(
                  `/workshop-management/${dataCabang?.name}/detail-transaksi/${row?.id}/${
                    row?.unit_id
                  }/${row?.transaction_type?.toLowerCase()}`
                )
              : push(
                  `/workshop-management/${dataCabang?.name}/detail-transaksi/${
                    row?.transaction_id
                  }/${row?.unit_id}/${row?.transaction_type?.toLowerCase()}`
                )
          }
          className="flex items-center gap-2 justify-center self-center px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
          <IcEye className="text-gray-700" />
          <span className="text-sm-medium text-gray-700">Lihat</span>
        </div>
      ),
      grow: 1
    }
  ]

  // Get Api
  // Get Detail Cabang
  useQuery({
    queryKey: ['detail-cabang', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}/${id}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDataCabang(res?.resData?.data)
    }
  })

  // Get transaction list
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'transaction-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.type
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixUser}/${urls.analytic.transactionBranch.salesTotalList}/${id}?keywords=${tableState.keywords}&limit=${tableState?.limit}&offset=${tableState?.offset}&type=${tableState?.type}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get list types
  useQuery({
    queryKey: ['types-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixUser}/${urls.analytic.transactionBranch.transactionTypes}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setdropdownTransaksi(res?.resData?.data ?? [])
    }
  })

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <Layout style={{ padding: 32, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Workshop Management
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => goBack()}>
          Birdmobil Surabaya
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Total Pendapatan
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <CardContainer minHeight="65%" className={'grid grid-cols-1 gap-5'}>
          <p className="display-xs-semibold text-gray-900 m-0">Daftar Transaksi</p>
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row w-full items-center space-x-3">
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 mr-3"
                style={{ minWidth: '150px' }}
                value={tableState?.type}
                onChange={(e) => setTableState((prev) => ({ ...prev, type: e, offset: 0 }))}>
                <Select.Option value={''}>Semua Transaksi</Select.Option>
                {dropdownTransaksi?.map((transaksi, idx) => (
                  <Select.Option key={idx} value={transaksi}>
                    {transaksi}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Input
              className="w-1/4 rounded-md py-2"
              placeholder="Pencarian"
              prefix={<IcSearch className="text-gray-400" />}
              value={tableState?.keywords}
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
              }
            />
          </div>
          <div className="flex flex-col w-full space-y-5">
            <div className="flex flex-col space-y-5">
              <Table
                columns={columns}
                data={tableState?.listData}
                pending={isLoading ?? isFetching}
                totalRows={tableState?.totalRows}
                handlePerRowsChange={handlePerRowsChange}
                pageSize={tableState?.limit}
                page={tableState?.offset}
                onChangePrevPage={() => {
                  if (tableState?.offset > 0) {
                    onChangePage(tableState?.offset - tableState?.limit)
                  }
                }}
                onChangeNextPage={() => {
                  if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                    onChangePage(tableState?.offset + tableState?.limit)
                  }
                }}
              />
            </div>
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default TotalPendapatanCabang
