import { Breadcrumb, Layout } from 'antd'
import {
  CatatanCard,
  InformasiServisCard,
  InformasiUnitCard,
  InformasiUserCard,
  InspeksiCard,
  PaymentReceiptCard
} from 'components'
import InformasiSpekUnitHorizontalCard from 'components/card/informasi-spek-unit-horizontal-card'
import { dummyTransaksiCabang } from 'pages/main/workshop-management/detail/transaksi-cabang/constant/dummyTransaksiCabang'
import React from 'react'
import { useHistory } from 'react-router-dom'

const DetailInspeksiPage = () => {
  const { push } = useHistory()
  const dummyData = dummyTransaksiCabang.filter((itemData) => itemData.id === 1)[0]
  return (
    <Layout className="grid grid-cols-1 gap-6 p-8">
      {/* BREADCRUMB */}
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Workshop Management
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Transaksi
        </Breadcrumb.Item>
      </Breadcrumb>
      {dummyData?.tipe === 'Jual Beli' ? (
        <InformasiUnitCard
          unit={dummyData?.unit}
          status={dummyData.serviceDetail.status}
          harga={dummyData.serviceDetail.harga}
        />
      ) : (
        <InformasiServisCard tipe={dummyData?.tipe} data={dummyData.serviceDetail} />
      )}
      <InformasiUserCard title={'Informasi Pemilik'} data={dummyData?.ownerDetail} />
      {dummyData?.buyerDetail ? (
        <InformasiUserCard title={'Informasi Pembeli'} data={dummyData?.buyerDetail} />
      ) : null}
      {dummyData?.unitDetail ? (
        <InformasiSpekUnitHorizontalCard data={dummyData?.unitDetail} />
      ) : null}
      {dummyData?.catatan ? <CatatanCard desc={dummyData?.catatan} /> : null}
      {dummyData.tipe === 'Inspeksi' ? (
        <InspeksiCard
          onGoToDetailInspeksi={() =>
            push(`/analytics-dashboard/penjualan/detail-inspeksi/${1}/detail`)
          }
          data={dummyData?.serviceDetail.summaryResultInspection}
        />
      ) : null}
      <PaymentReceiptCard data={dummyData?.paymentDetail} />
    </Layout>
  )
}

export default DetailInspeksiPage
