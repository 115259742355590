import { CardContainer, CustomButton } from 'components'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

const LocationCabangCard = ({ data, onDeleteBengkel }) => {
  const router = useHistory()

  const map = useMemo(() => {
    if (data?.pin_google_map === '' || data?.pin_google_map === null) {
      return null
    } else {
      return `https://maps.google.com/maps?q=${data?.pin_google_map?.split(',')?.[0]},${
        data?.pin_google_map?.split(',')?.[1]
      }&hl=es;z=14&output=embed`
    }
  }, [data?.pin_google_map])

  return (
    <CardContainer className={'grid grid-cols-2 gap-5'}>
      {/* Left : Location Cabang Bengkel */}
      <div className="grid grid-cols-1 gap-2">
        {/* Name Cabang & Audit Trail Button */}
        <div className="flex justify-between items-center">
          <span className="display-xs-semibold text-gray-900">{data?.name}</span>
          <CustomButton
            onClick={() =>
              router.push(`/workshop-management/${data?.name}/${data?.id}/audit-trail`)
            }
            type="plain">
            Audit Trail
          </CustomButton>
        </div>
        {/* Location Cabang */}
        <div className="grid grid-cols-1 gap-2">
          <span className="text-lg-medium text-gray-700">{data?.city_name}</span>
          <span className="text-md-medium text-gray-500">
            {`Kota ${data?.city_name}, Provinsi ${data?.province_name}`}
          </span>
        </div>
        {/* Button Container */}
        <div className="grid grid-cols-2 gap-5">
          <CustomButton
            type="plain"
            onClick={() =>
              router.push(
                `/workshop-management/${data?.name?.toLowerCase()?.replaceAll(' ', '-')}-${
                  data?.id
                }/edit-bengkel`
              )
            }>
            Edit Data
          </CustomButton>
          <CustomButton type="danger" onClick={onDeleteBengkel}>
            Hapus Bengkel
          </CustomButton>
        </div>
      </div>
      {/* Right : Map */}
      {map && (
        <div>
          <iframe
            title="map-header-service"
            src={map}
            width="100%"
            height="210"
            frameBorder="0"
            style={{
              border: 0,
              borderRadius: 5,
              zIndex: 1,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '100%'
            }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            aria-hidden="false"
          />
        </div>
      )}
    </CardContainer>
  )
}

export default LocationCabangCard
