import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { apiDelete, apiGet, apiPatch, apiPost, urls } from 'api'
import {
  CatatanFormCard,
  Loading,
  PhotoContainerCard,
  PhotoMultipleCard,
  PhotoThumbnailCard
} from 'components'
import moment from 'moment'
import 'moment/locale/en-gb'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'
import {
  DocumentForm,
  HargaJualForm,
  InformasiPemilikForm,
  InformasiUnitForm
} from 'pages/main/jual-unit/stock/subpages/stock-create-unit/components'

const initialValuesInformasiPemilikForm = {
  namaLengkap: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan nama lengkap'
  },
  email: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan email'
  },
  telepon: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan telepon'
  },
  alamat: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan alamat'
  },
  province: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih provinsi'
  },
  city: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih kota/kabupaten'
  },
  subDistrict: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih kecamatan'
  },
  kelurahan: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih kelurahan'
  },
  postalCode: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan kode pos'
  }
}

const initialValuesInformasiUnitForm = {
  manufacture: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih manufacture'
  },
  model: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih model'
  },
  tipe: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih tipe'
  },
  productionYear: {
    isEmpty: false,
    message: 'Silahkan pilih tahun keluaran',
    value: moment(new Date(), 'YYYY')
  },
  transmission: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih transmission'
  },
  fuel: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih bahan bakar'
  },
  color: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih warna'
  },
  mileage: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan jarak tempuh'
  },
  policeNumber: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan plat nomor'
  },
  engineNumber: {
    value: ''
  },
  frameNumber: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan nomor rangka'
  },
  // bpkbNumber: {
  //   value: '',
  //   isEmpty: false,
  //   message: 'Silahkan masukkan nomor BPKB'
  // },
  // stnkNumber: {
  //   value: '',
  //   isEmpty: false,
  //   message: 'Silahkan masukkan nomor STNK'
  // },
  taxStatus: {
    value: false
  },
  taxActiveEndMonth: {
    parse: '',
    value: moment(new Date(), 'MM'),
    isEmpty: false,
    message: 'Silahkan pilih tanggal pajak berlaku'
  },
  taxActiveEndYear: {
    parse: '',
    value: moment(new Date(), 'YYYY'),
    isEmpty: false,
    message: 'Silahkan pilih tanggal pajak berlaku'
  },
  keurNumber: {
    value: ''
  },
  workshop: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih workshop'
  }
}

const initialValuesDocumentForm = {
  stnk: false,
  bpkb: false,
  faktur: false,
  ktp: false,
  kwitansi: false,
  keur: false,
  formA: false
}

const KatalogEditPage = () => {
  const router = useHistory()
  const { id } = useParams()
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  // initial state informasi pemilik
  const [informasiPemilikForm, setInformasiPemilikForm] = useState(
    initialValuesInformasiPemilikForm
  )
  const [dropDownProvince, setDropDownProvince] = useState([{ name: 'Pilih Provinsi', value: -1 }])
  const [dropDownCity, setDropDownCity] = useState([{ name: 'Pilih Kota/Kabupaten', value: -1 }])
  const [dropDownSubDistrict, setDropDownSubDistrict] = useState([
    { name: 'Pilih Kecamatan', value: -1 }
  ])
  const [dropDownKelurahan, setDropDownKelurahan] = useState([
    { name: 'Pilih Kelurahan', value: -1 }
  ])

  // initial state informasi unit
  const [informasiUnitForm, setInformasiUnitForm] = useState(initialValuesInformasiUnitForm)
  const [dropDownWorkShop, setDropDownWorkShop] = useState([{ name: 'Pilih Workshop', value: -1 }])
  const [dropDownManufacture, setDropDownManufacture] = useState([
    { name: 'Pilih Pabrikan', value: -1 }
  ])
  const [dropDownModel, setDropDownModel] = useState([{ name: 'Pilih Model', value: -1 }])
  const [dropDownTipe, setDropDownTipe] = useState([{ name: 'Pilih Tipe', value: -1 }])
  const [dropDownTransmission, setDropDownTransmission] = useState([
    { name: 'Pilih Transmission', value: -1 }
  ])
  const [dropDownFuel, setDropDownFuel] = useState([{ name: 'Pilih Bahan Bakar', value: -1 }])
  const [dropDownColor, setDropDownColor] = useState([{ name: 'Pilih Warna', value: -1 }])

  // intial state document form
  const [documentStateForm, setDocumentStateForm] = useState(initialValuesDocumentForm)

  const stnk = [
    { name: 'stnk', value: true, label: 'ADA' },
    { name: 'stnk', value: false, label: 'TIDAK ADA' }
  ]
  const bpkb = [
    { name: 'bpkb', value: true, label: 'ADA' },
    { name: 'bpkb', value: false, label: 'TIDAK ADA' }
  ]
  const faktur = [
    { name: 'faktur', value: true, label: 'ADA' },
    { name: 'faktur', value: false, label: 'TIDAK ADA' }
  ]
  const ktp = [
    { name: 'ktp', value: true, label: 'ADA' },
    { name: 'ktp', value: false, label: 'TIDAK ADA' }
  ]
  const kwitansi = [
    { name: 'kwitansi', value: true, label: 'ADA' },
    { name: 'kwitansi', value: false, label: 'TIDAK ADA' }
  ]
  const keur = [
    { name: 'keur', value: true, label: 'ADA' },
    { name: 'keur', value: false, label: 'TIDAK ADA' }
  ]
  const formA = [
    { name: 'formA', value: true, label: 'ADA' },
    { name: 'formA', value: false, label: 'TIDAK ADA' }
  ]
  const taxStatus = [
    { name: 'taxStatus', value: true, label: 'Hidup' },
    { name: 'taxStatus', value: false, label: 'Mati' }
  ]

  const [price, setPrice] = useState({
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan harga jual'
  })
  const [photoThumbnail, setPhotoThumbnail] = useState({ file: undefined, blob: undefined })
  const [listPhoto, setListPhoto] = useState([])
  const [catatanForm, setCatatanForm] = useState({
    isEditing: false,
    desc: '',
    placeholder: 'Tidak ada catatan.',
    isEmpty: false,
    message: 'Silahkan masukkan catatan (akan diberi default jika kosong)'
  })

  const onLogout = () => dispatch(logout())

  const { isLoading, isFetching } = useQuery({
    queryKey: ['initial-katalog-edit-unit'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await Promise.all([
        apiGet({
          url: `${urls.master.prefix}/${urls.master.location.province.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.products.brand.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.products.transmissions.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.products.fuel.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.workshops.all}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/website/colors/all`,
          token: user.token,
          onLogout
        })
      ]),
    onSuccess: (data) => {
      if (data[0].resStatus === 200) {
        if (data[0].resData.data.length !== 0) {
          data[0].resData.data.map((item) => {
            setDropDownProvince((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[1].resStatus === 200) {
        if (data[1].resData.data.length !== 0) {
          data[1].resData.data.map((item) => {
            setDropDownManufacture((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[2].resStatus === 200) {
        if (data[2].resData.data.length !== 0) {
          data[2].resData.data.map((item) => {
            setDropDownTransmission((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[3].resStatus === 200) {
        if (data[3].resData.data.length !== 0) {
          data[3].resData.data.map((item) => {
            setDropDownFuel((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[4].resStatus === 200) {
        if (data[4].resData.data.length !== 0) {
          data[4].resData.data.map((item) => {
            setDropDownWorkShop((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      if (data[5].resStatus === 200) {
        if (data[5].resData.data.length !== 0) {
          data[5].resData.data.map((item) => {
            setDropDownColor((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const { isLoading: isLoadingData, isFetching: isFetchingData } = useQuery({
    queryKey: ['get-detail-catalog', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        url: `jual-beli-service/admin/catalogs/${id}`,
        token: user.token,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      if (res?.resStatus === 200) {
        const data = res?.resData?.data
        setInformasiPemilikForm((prev) => ({
          ...prev,
          namaLengkap: {
            ...prev.namaLengkap,
            value: data?.owner_fullname ?? '',
            isEmpty: data?.owner_fullname ? false : true
          },
          email: {
            ...prev.email,
            value: data?.owner_email ?? '',
            isEmpty: data?.owner_email ? false : true
          },
          telepon: {
            ...prev.telepon,
            value: data?.owner_phone_number ?? '',
            isEmpty: data?.owner_phone_number ? false : true
          },
          alamat: {
            ...prev.alamat,
            value: data?.owner_address ?? '',
            isEmpty: data?.owner_address ? false : true
          },
          province: {
            ...prev.province,
            value: data?.owner_province_id ? data?.owner_province_id : -1,
            isEmpty: data?.owner_province_id ? false : true
          },
          city: {
            ...prev.city,
            value: data?.owner_city_id ? data?.owner_city_id : -1,
            isEmpty: data?.owner_city_id ? false : true
          },
          subDistrict: {
            ...prev.subDistrict,
            value: data?.owner_subdistrict_id ? data?.owner_subdistrict_id : -1,
            isEmpty: data?.owner_subdistrict_id ? false : true
          },
          kelurahan: {
            ...prev.kelurahan,
            value: data?.owner_location_id ? data?.owner_location_id : -1,
            isEmpty: data?.owner_location_id ? false : true
          },
          postalCode: {
            ...prev.postalCode,
            value: data?.owner_postal_code ?? '',
            isEmpty: data?.owner_postal_code ? false : true
          }
        }))

        setInformasiUnitForm((prev) => ({
          ...prev,
          manufacture: {
            ...prev.manufacture,
            value: data?.brand_id ? data?.brand_id : -1,
            isEmpty: data?.brand_id ? false : true
          },
          model: {
            ...prev.model,
            value: data?.model_id ? data?.model_id : -1,
            isEmpty: data?.model_id ? false : true
          },
          tipe: {
            ...prev.tipe,
            value: data?.type_id ? data?.type_id : -1,
            isEmpty: data?.type_id ? false : true
          },
          productionYear: {
            ...prev.productionYear,
            value: data?.production_year
              ? moment(data?.production_year, 'YYYY')
              : moment(new Date(), 'YYYY'),
            isEmpty: data?.production_year ? false : true
          },
          transmission: {
            ...prev.transmission,
            value: data?.transmision_id ? data?.transmision_id : -1,
            isEmpty: data?.transmision_id ? false : true
          },
          fuel: {
            ...prev.fuel,
            value: data?.fuel_id ? data?.fuel_id : -1,
            isEmpty: data?.fuel_id ? false : true
          },
          policeNumber: {
            ...prev.policeNumber,
            value: data?.police_number ?? '',
            isEmpty: data?.police_number ? false : true
          },
          engineNumber: {
            value: data?.engine_number ?? ''
          },
          frameNumber: {
            ...prev.frameNumber,
            value: data?.frame_number ?? '',
            isEmpty: data?.frame_number ? false : true
          },
          workshop: {
            ...prev.workshop,
            value: data?.branch_id ? data?.branch_id : -1,
            isEmpty: data?.branch_id ? false : true
          },
          color: {
            ...prev.color,
            value: data?.color_id ? data?.color_id : -1,
            isEmpty: data?.color_id ? false : true
          },
          mileage: {
            ...prev.mileage,
            value: data?.mileage ? data?.mileage : '',
            isEmpty: data?.mileage ? false : true
          },
          // bpkbNumber: {
          //   ...prev.bpkbNumber,
          //   value: data?.document_bpkb ? data?.document_bpkb : '',
          //   isEmpty: data?.document_bpkb ? false : true
          // },
          // stnkNumber: {
          //   ...prev.stnkNumber,
          //   value: data?.document_stnk ? data?.document_stnk : '',
          //   isEmpty: data?.document_stnk ? false : true
          // },
          taxStatus: {
            ...prev.taxStatus,
            value: data?.tax_status ? data?.tax_status : false,
            isEmpty: false
          },
          taxActiveEndMonth: {
            ...prev.taxActiveEndMonth,
            parse: data?.tax_active_end ? moment(data?.tax_active_end).format('MMM') : '',
            value: data?.tax_active_end ? moment(data?.tax_active_end) : '',
            isEmpty: data?.tax_active_end ? false : true
          },
          taxActiveEndYear: {
            ...prev.taxActiveEndYear,
            parse: data?.tax_active_end ? moment(data?.tax_active_end).format('YYYY') : '',
            value: data?.tax_active_end ? moment(data?.tax_active_end) : '',
            isEmpty: data?.tax_active_end ? false : true
          },
          keurNumber: {
            value: data?.document_keur ?? ''
          }
        }))

        setDocumentStateForm((prev) => ({
          ...prev,
          stnk: data?.document_stnk_status ?? false,
          bpkb: data?.document_bpkb_status ?? false,
          faktur: data?.document_faktur_status ?? false,
          ktp: data?.document_ktp_status ?? false,
          kwitansi: data?.document_kwitansi_status ?? false,
          keur: data?.document_keur_status ?? false,
          formA: data?.document_form_a_status ?? false
        }))

        setPhotoThumbnail((prev) => ({
          ...prev,
          blob: `${process.env.REACT_APP_IMAGE_URL_GCS}/${data?.thumbnail_filename}`
        }))

        const arrPhotos = data?.photos?.map((item) => ({
          file: undefined,
          blob: `${process.env.REACT_APP_IMAGE_URL_GCS}/${item?.photo_filename}`,
          id: item.id ?? undefined
        }))

        setListPhoto(arrPhotos)

        setPrice((prev) => ({ ...prev, value: data?.price, isEmpty: data?.price ? false : true }))
        setCatatanForm((prev) => ({ ...prev, desc: data?.description ?? '' }))
      }
    }
  })

  useQuery({
    queryKey: ['get-cities', informasiPemilikForm.province.value],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (parseInt(informasiPemilikForm.province.value) > 0) {
        const res = await apiGet({
          url: `${urls.master.prefix}/${urls.master.location.cities.filterByProvince}/${parseInt(
            informasiPemilikForm.province.value
          )}`,
          token: user.token,
          onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setDropDownCity([{ name: 'Pilih Kota/Kabupaten', value: -1 }])
      if (res?.resStatus === 200) {
        if (res?.resData?.data?.length !== 0) {
          res.resData.data.map((item) => {
            setDropDownCity((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  useQuery({
    queryKey: ['get-kecamatan', informasiPemilikForm.city.value],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (parseInt(informasiPemilikForm.city.value) > 0) {
        const res = await apiGet({
          url: `${urls.master.prefix}/${urls.master.location.subDistrict.filterByCity}/${parseInt(
            informasiPemilikForm.city.value
          )}`,
          token: user.token,
          onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setDropDownSubDistrict([{ name: 'Pilih Kecamatan', value: -1 }])
      if (res?.resStatus === 200) {
        if (res.resData.data.length !== 0) {
          res.resData.data.map((item) => {
            setDropDownSubDistrict((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  useQuery({
    queryKey: ['get-kelurahan', informasiPemilikForm.subDistrict.value],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (parseInt(informasiPemilikForm.subDistrict.value) > 0) {
        const res = await apiGet({
          url: `${urls.master.prefix}/${urls.master.location.all.filterBySubDistrict}/${parseInt(
            informasiPemilikForm.subDistrict.value
          )}`,
          token: user.token,
          onLogout
        })

        return res
      }
    },
    onSuccess: (res) => {
      setDropDownKelurahan([{ name: 'Pilih Kelurahan', value: -1 }])
      if (res?.resStatus === 200) {
        if (res.resData.data.length !== 0) {
          res.resData.data.map((item) => {
            setDropDownKelurahan((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  useQuery({
    queryKey: ['get-model', informasiUnitForm.manufacture.value],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (parseInt(informasiUnitForm.manufacture.value) > 0) {
        const res = await apiGet({
          url: `${urls.master.prefix}/${urls.master.products.model.all}?brand_id=${parseInt(
            informasiUnitForm.manufacture.value
          )}`,
          token: user.token,
          onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setDropDownModel([{ name: 'Pilih Model', value: -1 }])
      if (res?.resStatus === 200) {
        if (res.resData.data.length !== 0) {
          res.resData.data.map((item) => {
            setDropDownModel((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  useQuery({
    queryKey: ['get-tipe', informasiUnitForm.model.value],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (parseInt(informasiUnitForm.model.value) > 0) {
        const res = await apiGet({
          url: `${urls.master.prefix}/${urls.master.products.tipe.all}?model_id=${parseInt(
            informasiUnitForm.model.value
          )}`,
          token: user.token,
          onLogout
        })

        return res
      }
    },
    onSuccess: (res) => {
      setDropDownTipe([{ name: 'Pilih Tipe', value: -1 }])
      if (res?.resStatus === 200) {
        if (res.resData.data.length !== 0) {
          res.resData.data.map((item) => {
            setDropDownTipe((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const onResetForm = () => {
    setInformasiPemilikForm(initialValuesInformasiPemilikForm)
    setInformasiUnitForm(initialValuesInformasiUnitForm)
    setDocumentStateForm(initialValuesDocumentForm)
    setPrice({ ...price, value: '' })
    setPhotoThumbnail({ file: undefined, blob: undefined })
    setListPhoto([])
    setCatatanForm({ ...catatanForm, desc: '' })
  }

  const handleSubmitForm = async () => {
    setInformasiPemilikForm({
      ...informasiPemilikForm,
      namaLengkap: {
        ...informasiPemilikForm.namaLengkap,
        isEmpty: informasiPemilikForm.namaLengkap.value === '' ? true : false
      },
      email: {
        ...informasiPemilikForm.email,
        isEmpty: informasiPemilikForm.email.value === '' ? true : false
      },
      telepon: {
        ...informasiPemilikForm.telepon,
        isEmpty: informasiPemilikForm.telepon.value === '' ? true : false
      },
      alamat: {
        ...informasiPemilikForm.alamat,
        isEmpty: informasiPemilikForm.alamat.value === '' ? true : false
      },
      province: {
        ...informasiPemilikForm.province,
        isEmpty: informasiPemilikForm.province.value < 1 ? true : false
      },
      city: {
        ...informasiPemilikForm.city,
        isEmpty: informasiPemilikForm.city.value < 1 ? true : false
      },
      subDistrict: {
        ...informasiPemilikForm.subDistrict,
        isEmpty: informasiPemilikForm.subDistrict.value < 1 ? true : false
      },
      kelurahan: {
        ...informasiPemilikForm.kelurahan,
        isEmpty: informasiPemilikForm.kelurahan.value < 1 ? true : false
      },
      postalCode: {
        ...informasiPemilikForm.postalCode,
        isEmpty: informasiPemilikForm.postalCode.value === '' ? true : false
      }
    })
    setInformasiUnitForm({
      ...informasiUnitForm,
      manufacture: {
        ...informasiUnitForm.manufacture,
        isEmpty: informasiUnitForm.manufacture.value < 1 ? true : false
      },
      model: {
        ...informasiUnitForm.model,
        isEmpty: informasiUnitForm.model.value < 1 ? true : false
      },
      tipe: {
        ...informasiUnitForm.tipe,
        isEmpty: informasiUnitForm.tipe.value < 1 ? true : false
      },
      transmission: {
        ...informasiUnitForm.transmission,
        isEmpty: informasiUnitForm.transmission.value < 1 ? true : false
      },
      fuel: {
        ...informasiUnitForm.fuel,
        isEmpty: informasiUnitForm.fuel.value < 1 ? true : false
      },
      color: {
        ...informasiUnitForm.color,
        isEmpty: informasiUnitForm.color.value < 1 ? true : false
      },
      mileage: {
        ...informasiUnitForm.mileage,
        isEmpty: informasiUnitForm.mileage.value === '' ? true : false
      },
      policeNumber: {
        ...informasiUnitForm.policeNumber,
        isEmpty: informasiUnitForm.policeNumber.value === '' ? true : false
      },
      engineNumber: {
        ...informasiUnitForm.engineNumber
      },
      frameNumber: {
        ...informasiUnitForm.frameNumber,
        isEmpty: informasiUnitForm.frameNumber.value === '' ? true : false
      },
      // bpkbNumber: {
      //   ...informasiUnitForm.bpkbNumber,
      //   isEmpty: informasiUnitForm.bpkbNumber.value === '' ? true : false
      // },
      // stnkNumber: {
      //   ...informasiUnitForm.stnkNumber,
      //   isEmpty: informasiUnitForm.stnkNumber.value === '' ? true : false
      // },
      taxActiveEndMonth: {
        ...informasiUnitForm.taxActiveEndMonth,
        isEmpty: informasiUnitForm.taxActiveEndMonth.parse === '' ? true : false
      },
      taxActiveEndYear: {
        ...informasiUnitForm.taxActiveEndYear,
        isEmpty: informasiUnitForm.taxActiveEndYear.parse === '' ? true : false
      },
      workshop: {
        ...informasiUnitForm.workshop,
        isEmpty: informasiUnitForm.workshop.value < 1 ? true : false
      }
    })
    setPrice({ ...price, isEmpty: price.value === '' ? true : false })
    setCatatanForm({ ...catatanForm, isEmpty: catatanForm.desc === '' ? true : false })
    if (
      Object.entries(informasiPemilikForm).find((item) => item[1].isEmpty === true) ||
      Object.entries(informasiUnitForm).find((item) => item[1].isEmpty === true) ||
      price.value === ''
    ) {
      return false
    } else {
      // HIT API
      setIsLoadingHelper(true)
      const formData = new FormData()
      const mount = setTimeout(async () => {
        formData.append('owner_fullname', informasiPemilikForm.namaLengkap.value)
        formData.append('owner_email', informasiPemilikForm.email.value)
        formData.append('owner_phone_number', informasiPemilikForm.telepon.value)
        formData.append('owner_address', informasiPemilikForm.alamat.value)
        formData.append('owner_province_id', informasiPemilikForm.province.value.toString())
        formData.append('owner_city_id', informasiPemilikForm.city.value.toString())
        formData.append('owner_subdistrict_id', informasiPemilikForm.subDistrict.value.toString())
        formData.append('owner_location_id', informasiPemilikForm.kelurahan.value.toString())
        formData.append('owner_postal_code', informasiPemilikForm.postalCode.value)
        formData.append('brand_id', informasiUnitForm.manufacture.value.toString())
        formData.append('model_id', informasiUnitForm.model.value.toString())
        formData.append('type_id', informasiUnitForm.tipe.value.toString())
        formData.append(
          'production_year',
          moment(informasiUnitForm.productionYear.value, 'YYYY').format('YYYY')
        )
        formData.append('transmision_id', informasiUnitForm.transmission.value.toString())
        formData.append('fuel_id', informasiUnitForm.fuel.value.toString())

        formData.append('color_id', informasiUnitForm.color.value.toString())
        formData.append('mileage', informasiUnitForm.mileage.value)

        formData.append('police_number', informasiUnitForm.policeNumber.value)
        formData.append('engine_number', informasiUnitForm.engineNumber.value)
        formData.append('frame_number', informasiUnitForm.frameNumber.value)
        formData.append('branch_id', informasiUnitForm.workshop.value.toString())

        formData.append('tax_status', informasiUnitForm.taxStatus.value ? 'true' : 'false')
        formData.append(
          'tax_active_end',
          `${moment(informasiUnitForm.taxActiveEndMonth.value, 'MM').format('MM')}/${moment(
            informasiUnitForm.taxActiveEndYear.value,
            'YYYY'
          ).format('YYYY')}`
        )
        // formData.append('document_bpkb', informasiUnitForm.bpkbNumber.value)
        // formData.append('document_stnk', informasiUnitForm.stnkNumber.value)
        formData.append('document_keur', informasiUnitForm.keurNumber.value)

        formData.append('document_stnk_status', documentStateForm.stnk ? 'true' : 'false')
        formData.append('document_bpkb_status', documentStateForm.bpkb ? 'true' : 'false')
        formData.append('document_faktur_status', documentStateForm.faktur ? 'true' : 'false')
        formData.append('document_ktp_status', documentStateForm.ktp ? 'true' : 'false')
        formData.append('document_kwitansi_status', documentStateForm.kwitansi ? 'true' : 'false')
        formData.append('document_keur_status', documentStateForm.keur ? 'true' : 'false')
        formData.append('document_form_a_status', documentStateForm.formA ? 'true' : 'false')

        formData.append('price', price.value)

        formData.append(
          'description',
          catatanForm.desc === '' ? catatanForm.placeholder : catatanForm.desc
        )

        if (photoThumbnail.file) {
          formData.append('thumbnail', photoThumbnail.file)
        }

        const res = await apiPatch({
          url: `jual-beli-service/admin/catalogs/${id}`,
          token: user.token,
          data: formData,
          isFormData: true,
          onLogout
        })
        if (res.resStatus === 200) {
          router.goBack()
          showSuccessMessage({ content: 'Successfully Update Catalog' })
          onResetForm()
        } else {
          showErrorMessage({
            content: `${
              res.resData.message ?? res.resData.errors[0].message ?? 'Failed Update Catalog'
            }`
          })
        }
        setIsLoadingHelper(false)
      })
      return () => clearTimeout(mount)
    }
  }

  const handleAddPhoto = async (file) => {
    const formDataPhoto = new FormData()
    formDataPhoto.append('photo', file)
    formDataPhoto.append('catalog_id', id)
    const res = await apiPost({
      url: `jual-beli-service/admin/catalogs/photos`,
      token: user?.token,
      isFormData: true,
      data: formDataPhoto,
      onLogout: () => dispatch(logout())
    })

    if (res?.resStatus === 200) {
      setListPhoto((prev) => [
        ...prev,
        {
          file: undefined,
          blob: `${process.env.REACT_APP_IMAGE_URL_GCS}/${res?.resData?.data?.photo_filename}`,
          id: res?.resData?.data?.id ?? undefined
        }
      ])
    }
  }

  const handleDeletePhoto = async (idPhoto) => {
    const res = await apiDelete({
      url: `jual-beli-service/admin/catalogs/photos/${idPhoto}`,
      token: user.token,
      onLogout: () => dispatch(logout())
    })

    if (res?.resStatus === 200) {
      setListPhoto((prev) => prev?.filter((f) => f?.id !== idPhoto))
    }
  }

  if (isLoading || isLoadingData || isFetching || isFetchingData || isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Katalog
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Edit Unit
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }} className="h-screen">
        <div className="flex flex-col w-full space-y-5">
          <InformasiPemilikForm
            state={informasiPemilikForm}
            dropDownProvince={dropDownProvince}
            dropDownCity={dropDownCity}
            dropDownSubDistrict={dropDownSubDistrict}
            dropDownKelurahan={dropDownKelurahan}
            onChangeInput={(field, value) => {
              setInformasiPemilikForm({
                ...informasiPemilikForm,
                [field]: {
                  ...informasiPemilikForm[field],
                  value,
                  isEmpty: value && value !== '' ? false : true
                }
              })
            }}
            onChangeDropDown={async (field, value) => {
              if (field === 'province') {
                setInformasiPemilikForm({
                  ...informasiPemilikForm,
                  [field]: {
                    ...informasiPemilikForm[field],
                    value: parseInt(value),
                    isEmpty: parseInt(value) > 0 ? false : true
                  },
                  city: {
                    ...informasiPemilikForm['city'],
                    value: -1,
                    isEmpty: true
                  },
                  subDistrict: {
                    ...informasiPemilikForm['subDistrict'],
                    value: -1,
                    isEmpty: true
                  },
                  kelurahan: {
                    ...informasiPemilikForm['kelurahan'],
                    value: -1,
                    isEmpty: true
                  }
                })
                setDropDownSubDistrict([{ name: 'Pilih Kecamatan', value: -1 }])
                setDropDownKelurahan([{ name: 'Pilih Kelurahan', value: -1 }])
              } else if (field === 'city') {
                setInformasiPemilikForm({
                  ...informasiPemilikForm,
                  [field]: {
                    ...informasiPemilikForm[field],
                    value: parseInt(value),
                    isEmpty: parseInt(value) > 0 ? false : true
                  },
                  subDistrict: {
                    ...informasiPemilikForm['subDistrict'],
                    value: -1,
                    isEmpty: true
                  },
                  kelurahan: {
                    ...informasiPemilikForm['kelurahan'],
                    value: -1,
                    isEmpty: true
                  }
                })
                setDropDownKelurahan([{ name: 'Pilih Kelurahan', value: -1 }])
              } else if (field === 'subDistrict') {
                setInformasiPemilikForm({
                  ...informasiPemilikForm,
                  [field]: {
                    ...informasiPemilikForm[field],
                    value: parseInt(value),
                    isEmpty: parseInt(value) > 0 ? false : true
                  },
                  kelurahan: {
                    ...informasiPemilikForm['kelurahan'],
                    value: -1,
                    isEmpty: true
                  }
                })
              } else {
                setInformasiPemilikForm({
                  ...informasiPemilikForm,
                  [field]: {
                    ...informasiPemilikForm[field],
                    value: parseInt(value),
                    isEmpty: parseInt(value) > 0 ? false : true
                  }
                })
              }
            }}
          />
          <InformasiUnitForm
            showBpkbNumber={false}
            showStnkNumber={false}
            state={informasiUnitForm}
            dropDownManufacture={dropDownManufacture}
            dropDownModel={dropDownModel}
            dropDownTipe={dropDownTipe}
            dropDownTransmission={dropDownTransmission}
            dropDownFuel={dropDownFuel}
            dropDownWorkshop={dropDownWorkShop}
            dropDownColor={dropDownColor}
            taxStatus={taxStatus}
            onChangeRadioButton={(field, value) => {
              setInformasiUnitForm({
                ...informasiUnitForm,
                [field]: {
                  ...informasiUnitForm[field],
                  value: value === 'true' ? true : false,
                  isEmpty: value && value !== '' ? false : true
                }
              })
            }}
            onChangeInput={(field, value) => {
              if (field === 'taxActiveEndMonth') {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    parse: value && value !== '' ? moment(value).format('MM') : '',
                    value,
                    isEmpty: value && value !== '' ? false : true
                  }
                })
              } else if (field === 'taxActiveEndYear') {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    parse: value && value !== '' ? moment(value).format('YYYY') : '',
                    value,
                    isEmpty: value && value !== '' ? false : true
                  }
                })
              }
              // else if (field === 'bpkbNumber') {
              //   setInformasiUnitForm({
              //     ...informasiUnitForm,
              //     [field]: {
              //       ...informasiUnitForm[field],
              //       value,
              //       isEmpty:
              //         field === 'engineNumber' || field === 'keurNumber'
              //           ? false
              //           : value && value !== ''
              //           ? false
              //           : true
              //     }
              //   })

              //   setDocumentStateForm((prev) => ({
              //     ...prev,
              //     bpkb: value ? true : false
              //   }))
              // } else if (field === 'stnkNumber') {
              //   setInformasiUnitForm({
              //     ...informasiUnitForm,
              //     [field]: {
              //       ...informasiUnitForm[field],
              //       value,
              //       isEmpty:
              //         field === 'engineNumber' || field === 'keurNumber'
              //           ? false
              //           : value && value !== ''
              //           ? false
              //           : true
              //     }
              //   })

              //   setDocumentStateForm((prev) => ({
              //     ...prev,
              //     stnk: value ? true : false
              //   }))
              // }
              else if (field === 'keurNumber') {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    value,
                    isEmpty:
                      field === 'engineNumber' || field === 'keurNumber'
                        ? false
                        : value && value !== ''
                        ? false
                        : true
                  }
                })

                setDocumentStateForm((prev) => ({
                  ...prev,
                  keur: value ? true : false
                }))
              } else {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    value,
                    isEmpty:
                      field === 'engineNumber' || field === 'keurNumber'
                        ? false
                        : value && value !== ''
                        ? false
                        : true
                  }
                })
              }
            }}
            onChangeDropDown={async (field, value) => {
              if (field === 'manufacture') {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    value: parseInt(value),
                    isEmpty: parseInt(value) > 0 ? false : true
                  },
                  model: {
                    ...informasiUnitForm['model'],
                    value: -1,
                    isEmpty: true
                  },
                  tipe: {
                    ...informasiUnitForm['tipe'],
                    value: -1,
                    isEmpty: true
                  }
                })
              } else if (field === 'model') {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    value: parseInt(value),
                    isEmpty: parseInt(value) > 0 ? false : true
                  },
                  tipe: {
                    ...informasiUnitForm['tipe'],
                    value: -1,
                    isEmpty: true
                  }
                })
              } else {
                setInformasiUnitForm({
                  ...informasiUnitForm,
                  [field]: {
                    ...informasiUnitForm[field],
                    value: parseInt(value),
                    isEmpty: parseInt(value) > 0 ? false : true
                  }
                })
              }
            }}
          />
          <DocumentForm
            stnk={stnk}
            bpkb={bpkb}
            faktur={faktur}
            ktp={ktp}
            kwitansi={kwitansi}
            keur={keur}
            formA={formA}
            state={documentStateForm}
            onChange={(field, value) => {
              setDocumentStateForm({
                ...documentStateForm,
                [field]: value === 'true' ? true : false
              })
            }}
          />
          <HargaJualForm
            name="price"
            state={price}
            onChangePrice={(value) =>
              setPrice({ ...price, value: value, isEmpty: value && value !== '' ? false : true })
            }
          />
          <PhotoContainerCard>
            <PhotoThumbnailCard
              isRequired={true}
              photoThumbnail={photoThumbnail.blob}
              onChangePhotoThumbnail={({ file, blob }) => setPhotoThumbnail({ file, blob })}
            />
            <PhotoMultipleCard
              isRequired={false}
              data={listPhoto}
              isDeleteById={true}
              onAddPhoto={(value) => {
                handleAddPhoto(value[0].file)
              }}
              onDeletePhoto={(index, id) => {
                handleDeletePhoto(id)
              }}
            />
          </PhotoContainerCard>
          <CatatanFormCard
            title={'Edit'}
            isEditing={catatanForm.isEditing}
            state={catatanForm}
            onEdit={() =>
              setCatatanForm({
                ...catatanForm,
                isEditing: !catatanForm.isEditing,
                isEmpty: catatanForm.desc && catatanForm.desc !== '' ? false : true
              })
            }
            onBlurEditing={(value) =>
              setCatatanForm({
                ...catatanForm,
                isEditing: false,
                desc: value,
                isEmpty: value && value !== '' ? false : true
              })
            }
          />
        </div>
        <div className="flex flex-row w-full items-center justify-end space-x-4">
          <button
            type="button"
            className="flex flex-row mt-4 mb-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
            onClick={() => router.goBack()}>
            Kembali
          </button>
          <button
            onClick={handleSubmitForm}
            type="submit"
            className="flex flex-row mt-4 mb-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
            Simpan
          </button>
        </div>
      </Content>
    </Layout>
  )
}

export default KatalogEditPage
