import React from 'react'

const IcSync = ({ className, size = 20 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 18"
    fill="none"
    stroke="white"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.1666 2.33527V7.33527M19.1666 7.33527H14.1666M19.1666 7.33527L15.2999 3.70193C14.4043 2.80586 13.2963 2.15127 12.0792 1.79925C10.8622 1.44722 9.57584 1.40923 8.34016 1.68882C7.10447 1.96841 5.95975 2.55646 5.01281 3.39812C4.06586 4.23977 3.34756 5.30759 2.92492 6.50193M0.833252 15.6686V10.6686M0.833252 10.6686H5.83325M0.833252 10.6686L4.69992 14.3019C5.59554 15.198 6.70356 15.8526 7.92059 16.2046C9.13762 16.5567 10.424 16.5946 11.6597 16.3151C12.8954 16.0355 14.0401 15.4474 14.987 14.6058C15.934 13.7641 16.6523 12.6963 17.0749 11.5019"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IcSync
