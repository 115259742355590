import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import AddInternalUnitComponent from './components/AddInternalUnitComponent'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'
import moment from 'moment'
import _ from 'lodash'
import { Loading } from 'components'

const AddInternalUnit = (props) => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  const [forms, setForms] = useState({
    brand_id: null,
    brand_name: '',
    model_id: null,
    model_name: '',
    type_id: null,
    type_name: '',
    transmission_id: null,
    fuel_id: null,
    color_id: null,
    // company_id: null,
    branch_id: null,
    branch_name: '',
    police_number: '',
    engine_capacity: 0,
    mileage: 0,
    production_year: {
      parse: '',
      value: moment(new Date(), 'YYYY')
    },
    frame_number: '',
    engine_number: '',
    description: '',
    document_stnk_status: null,
    document_bpkb_status: null,
    document_faktur_status: null,
    document_ktp_status: null,
    document_kwitansi_status: null,
    document_keur_status: null,
    document_form_status: null,
    user_id: null,
    province_id: null,
    city_id: null,
    sub_district_id: null,
    location_id: null,
    postal_code: '',
    name: '',
    email: '',
    address: '',
    phone_number: '',
    thumbnail: { file: undefined, blob: undefined },
    photos: []
  })

  const [search, setSearch] = useState({
    brand: '',
    branch: '',
    company: '',
    color: ''
  })

  const [currentImage, setCurrentImage] = useState({
    thumbnail: null,
    photos: []
  })

  const { isLoading } = useQuery({
    queryKey: ['internal-unit', router.location?.state?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = null
      if (router.location?.state?.id) {
        // const res = await apiGet({
        //     token,
        //     url: `master-service/admin/internal-units/${router.location?.state?.id}`,
        //     onLogout: () => dispatch(logout())
        // });
        // let forms = {
        //     ...res?.resData?.data,
        //     thumbnail: null,
        //     photos: []
        // }
        // delete forms.thumbnail_filename;
        // setForms(forms)
        // setCurrentImage({
        //     thumbnail: res?.resData?.data?.thumbnail_filename,
        //     photos: res?.resData?.data?.photos
        // })
      } else {
        res = await apiGet({
          token: token,
          url: `${urls?.jualUnit?.prefix}/${urls?.jualUnit?.contactBirdmobil}`,
          onLogout: () => dispatch(logout())
        })
        if (res?.resStatus === 200) {
          let phoneNumber = res?.resData?.data?.phone_number
          phoneNumber = phoneNumber.split('-').join('')
          setForms({
            ...forms,
            name: res?.resData?.data?.name,
            address: res?.resData?.data?.address,
            email: res?.resData?.data?.email,
            phone_number: phoneNumber,
            postal_code: res?.resData?.data?.postal_code,
            province_id: res?.resData?.data?.province_id,
            city_id: res?.resData?.data?.city_id,
            sub_district_id: res?.resData?.data?.subdistrict_id,
            location_id: res?.resData?.data?.location_id
          })
        } else {
          showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        }
      }
    }
  })

  const provinceList = useQuery({
    queryKey: ['province-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.location?.province?.all}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onError: (e) => {
      console.log(e)
    },
    enabled: !isLoading
  })

  const cityList = useQuery({
    queryKey: ['city-list', forms.province_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = []
      if (forms.province_id !== '' && forms.province_id !== null) {
        const response = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.cities?.filterByProvince}/${forms.province_id}`,
          onLogout: () => dispatch(logout())
        })
        res = response?.resData?.data
      }
      return res
    },
    onError: (e) => {
      console.log(e)
    },
    enabled: !provinceList?.isLoading
  })

  const subDistrictList = useQuery({
    queryKey: ['sub-district-list', forms.city_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = []
      if (forms.city_id !== '' && forms.city_id !== null) {
        const response = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.subDistrict?.filterByCity}/${forms.city_id}`,
          onLogout: () => dispatch(logout())
        })
        res = response?.resData?.data
      }
      return res
    },
    onError: (e) => {
      console.log(e)
    },
    enabled: !cityList?.isLoading && !provinceList?.isLoading
  })

  const locationList = useQuery({
    queryKey: ['location-list', forms.sub_district_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = []
      if (forms.sub_district_id !== '' && forms.sub_district_id !== null) {
        const response = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.all?.filterBySubDistrict}/${forms.sub_district_id}`,
          onLogout: () => dispatch(logout())
        })
        res = response?.resData?.data
      }
      return res
    },
    onError: (e) => {
      console.log(e)
    },
    enabled: !cityList?.isLoading && !provinceList?.isLoading && !subDistrictList?.isLoading
  })

  const brandList = useQuery({
    queryKey: ['brand-list', search.brand],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: 'master-service/admin/brands/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onError: (e) => {
      console.log(e)
    },
    enabled: !isLoading
  })

  const modelList = useQuery({
    queryKey: ['model-list', forms.brand_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        brand_id: forms.brand_id,
        offset: 0
        // limit: 10,
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/models/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading && !brandList.isLoading
  })

  const transmissionList = useQuery({
    queryKey: ['transmisi-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        offset: 0
        // limit: 10,
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/transmissions/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const fuelList = useQuery({
    queryKey: ['fuel-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        offset: 0
        // limit: 10,
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/fuels/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const typeList = useQuery({
    queryKey: ['type-list', forms.model_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        offset: 0,
        // limit: 10,
        model_id: forms.model_id
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/types/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const branchList = useQuery({
    queryKey: ['branch-list', search.branch],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: search.branch,
        offset: 0,
        limit: 10
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/workshops',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const companyList = useQuery({
    queryKey: ['company-list', search.company],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: search.company,
        offset: 0
        // limit: 10,
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'user-service/admin/companies',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const colorList = useQuery({
    queryKey: ['color-list', search.color],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: search.color,
        offset: 0,
        limit: 10
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/colors',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const handleAddPhoto = async (file) => {
    const formDataPhoto = new FormData()
    formDataPhoto.append('photo', file[0].file)
    const res = await apiPost({
      url: `master-service/v2/admin/units/upload-photo`,
      token,
      isFormData: true,
      data: formDataPhoto,
      onLogout: () => dispatch(logout())
    })
    if (res.resStatus === 200) {
      setForms((prevState) => ({
        ...prevState,
        photos: [
          ...forms.photos,
          { blob: file[0].blob, file: file[0].file, path: res.resData.data }
        ]
      }))
    } else {
      showErrorMessage({
        content:
          res.resData.message ?? res.resData.errors[0].message ?? 'Gagal untuk Menambahkan Foto.'
      })
    }
  }

  const handleDeletePhoto = async (item) => {
    const indexPhoto = forms.photos.findIndex((itemPr) => itemPr.path === item.path)
    const pathArr = Object.assign([], { path: item.path })
    const res = await apiDelete({
      url: `${urls.master.prefix}/v2/admin/internal-units/delete-photo-by-path`,
      data: pathArr,
      token,
      onLogout: () => dispatch(logout())
    })
    if (res.resStatus === 200) {
      const temp = [...forms.photos]
      temp.splice(indexPhoto, 1)
      setForms((prevState) => ({
        ...prevState,
        photos: temp
      }))
    } else {
      showErrorMessage({
        content:
          res.resData.message ?? res.resData.errors[0].message ?? 'Gagal untuk Menghapus Foto.'
      })
    }
  }

  const onCheckFieldEmpty = () =>
    Object.entries(forms)
      .filter(
        (item) =>
          item[0] !== 'photos' &&
          item[0] !== 'thumbnail' &&
          item[0] !== 'production_year' &&
          item[0] !== 'description' &&
          item[0] !== 'user_id'
      )
      .every((item) => {
        if (item[1] !== undefined && item[1] !== '' && item[1] !== null) {
          return true
        }
        showErrorMessage({ content: `Silahkan Lengkapi Field ${_.startCase(item[0])}` })
        return false
      })

  const onAdditionalCheckFieldEmpty = () => {
    if (forms.production_year.parse !== '') {
      return true
    } else {
      showErrorMessage({ content: `Silahkan Lengkapi Field Tahun Keluaran` })
      return false
    }
  }

  const onAdditionalCheckFieldThumbnail = () => {
    if (forms.thumbnail.file) {
      return true
    } else {
      showErrorMessage({ content: `Silahkan Masukkan Foto Thumbnail` })
      return false
    }
  }

  const saveData = async () => {
    if (onCheckFieldEmpty() && onAdditionalCheckFieldEmpty() && onAdditionalCheckFieldThumbnail()) {
      try {
        const formData = new FormData()
        let res
        const onReturnValue = (value) => {
          if (value === false) {
            return 'false'
          } else if (value === true) {
            return 'true'
          } else {
            return value.toString()
          }
        }
        Object.entries(forms).map((item) => {
          if (
            item[0] !== 'photos' &&
            item[0] !== 'thumbnail' &&
            item[0] !== 'production_year' &&
            item[0] !== 'description' &&
            item[0] !== 'user_id' &&
            item[1] !== null &&
            item[1] !== undefined &&
            item[1] !== ''
          ) {
            formData.append(`${item[0]}`, onReturnValue(item[1]))
          }
        })
        formData.append('production_year', forms.production_year.parse)
        formData.append(
          'description',
          forms?.description ? forms?.description : 'Tidak ada catatan'
        )
        if (forms.thumbnail.file) {
          formData.append('thumbnail', forms.thumbnail.file)
        }
        if (forms.photos.length > 0) {
          Array.from(forms.photos).forEach((image) => {
            formData.append('photos', image.path)
          })
        }
        setIsLoadingHelper(true)
        const mount = setTimeout(async () => {
          res = await apiPost({
            url: `master-service/v2/admin/internal-units`,
            token,
            isFormData: true,
            data: formData,
            onLogout: () => dispatch(logout())
          })
          if (res?.resStatus === 200 || res?.resStatus === 201) {
            router.goBack()
            showSuccessMessage({
              content: 'Successfully Create Internal Unit.'
            })
          } else {
            showErrorMessage({
              content:
                res.resData.message ??
                res.resData.errors[0].message ??
                'Failed to Create Internal Unit.'
            })
          }
          setIsLoadingHelper(false)
        })
        return () => clearTimeout(mount)
      } catch (error) {
        setIsLoadingHelper(false)
        console.log(error)
      }
    }
  }

  if (isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => props.history.go(-1)}>
          Internal Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item>Tambah Unit</Breadcrumb.Item>
      </Breadcrumb>
      <AddInternalUnitComponent
        forms={forms}
        setForms={setForms}
        setSearch={setSearch}
        provinces={provinceList?.data?.resData?.data ?? []}
        cities={cityList?.data ?? []}
        subDistricts={subDistrictList?.data ?? []}
        locations={locationList?.data ?? []}
        brandList={brandList?.data?.resData?.data ?? []}
        modelList={modelList?.data?.resData?.data ?? []}
        transmissionList={transmissionList?.data?.resData?.data ?? []}
        fuelList={fuelList?.data?.resData?.data ?? []}
        typeList={typeList?.data?.resData?.data ?? []}
        branchList={branchList?.data?.resData?.data ?? []}
        companyList={companyList?.data?.resData?.data ?? []}
        colorList={colorList?.data?.resData?.data ?? []}
        currentImage={currentImage}
        handleAddPhoto={(file) => handleAddPhoto(file)}
        handleDeletePhoto={(item) => handleDeletePhoto(item)}
        saveData={saveData}
      />
    </Layout>
  )
}

export default AddInternalUnit
