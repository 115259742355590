import * as actionTypes from 'store/actionTypes'

export const resetStateServis = () => {
  return async (dispatch) => {
    await dispatch({
      type: actionTypes.RESET_SERVIS
    })
  }
}

export const setSelectedServis = (selectedServis) => {
  return async (dispatch) => {
    await dispatch({
      type: actionTypes.SET_SELECTED_SERVIS,
      selectedServis: selectedServis
    })
  }
}

export const setServisStep1 = (unit_id, { full_name, phone, email, address }) => {
  return async (dispatch) => {
    await dispatch({
      type: actionTypes.SET_SERVIS_STEP1,
      unit_id: unit_id,
      full_name: full_name,
      phone: phone,
      email: email,
      address: address
    })
  }
}

export const setServisStep2 = (service, selectedServis, serviceForBE, simulateData) => {
  return async (dispatch) => {
    await dispatch({
      type: actionTypes.SET_SERVIS_STEP2,
      service: service,
      selectedServis: selectedServis,
      serviceForBE: serviceForBE,
      simulateData: simulateData
    })
  }
}

export const setServisStep3 = (
  service_date,
  service_start_time,
  service_end_time,
  workshop,
  stall
) => {
  return async (dispatch) => {
    await dispatch({
      type: actionTypes.SET_SERVIS_STEP3,
      service_date: service_date,
      service_start_time: service_start_time,
      service_end_time: service_end_time,
      workshop: workshop,
      stall: stall
    })
  }
}

// export const setInspeksiStep2 = (unit_id) => {
//     return async (dispatch, getState) => {
//         const profile = getState().auth.profile;
//         await dispatch({
//             type: actionTypes.SET_SERVIS_STEP1,
//             unit_id: unit_id,
//             full_name: profile.name,
//             phone: profile.phone,
//             email: profile.email,
//             address: profile.address,
//         })
//     }
// }
