import React from 'react'
import { CardContainer } from 'components'

const PhotoContainerCard = ({ title = 'Foto Unit', children }) => (
  <CardContainer className="pb-8">
    <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
      <span className="font-semibold text-2xl text-gray-900">{title}</span>
    </div>
    <div className="flex flex-col mt-4 space-y-5">{children}</div>
  </CardContainer>
)

export default PhotoContainerCard
