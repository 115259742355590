import React from 'react'
import {
  CardContainer,
  InformasiOdooCard,
  InformasiPenjualanCard,
  InformasiSpekHorizontalUnitCard,
  PaymentReceiptSingleCard
} from 'components'
import moment from 'moment'
import 'moment/locale/en-gb'
import { formatCurrency, statusLayanan } from 'utils'

const BookingHistoryContent = ({ data }) => {
  return !data ? (
    <CardContainer>
      <span className="font-medium text-lg text-gray-900">
        Data Riwayat Pembelian Tidak Ditemukan.
      </span>
    </CardContainer>
  ) : (
    <div className="flex flex-col w-full space-y-5">
      <InformasiPenjualanCard
        nama={
          data.catalog.owner_fullname && data.catalog.owner_fullname !== ''
            ? data.catalog.owner_fullname
            : '-'
        }
        pembeli={
          data.catalog.buyer_fullname && data.catalog.buyer_fullname !== ''
            ? data.catalog.buyer_fullname
            : '-'
        }
        tanggal={moment(data.catalog.created_at).format('DD/MM/YYYY')}
        harga={formatCurrency(data.catalog_invoice.total_price)}
      />
      {statusLayanan(data?.catalog?.status) === 'Terjual' &&
      (data?.odoo_repair_order?.invoice_name ||
        data?.odoo_repair_order?.repair_order_name ||
        data?.odoo_sale_order?.sale_order_name) ? (
        <InformasiOdooCard
          invoiceNumber={data?.odoo_sale_order?.invoice_name}
          soNumber={data?.odoo_sale_order?.sale_order_name}
        />
      ) : null}
      <InformasiSpekHorizontalUnitCard
        data={{
          merek: data.catalog.branch_name,
          model: data.catalog.model_name,
          tipe: data.catalog.type_name,
          transmisi: data.catalog.transmission_name,
          platNomor: data.catalog.police_number,
          tahun: data.catalog.production_year,
          warna: data.catalog.color_name === '' ? '-' : data.catalog.color_name,
          bahanBakar: data.catalog.fuel_name === '' ? '-' : data.catalog.fuel_name,
          nomorRangka: data.catalog.frame_number,
          nomorMesin: data.catalog.engine_number === '' ? '-' : data.catalog.engine_number,
          tag:
            data.catalog.tags === null
              ? '-'
              : data.catalog.tags.length !== 0
              ? data.catalog.tags.map((item) => item.name).join(' ')
              : '-'
        }}
      />
      <PaymentReceiptSingleCard
        data={{
          unitName: data.catalog.unit_name,
          unitPrice: data.catalog_invoice.subtotal_price,
          adminPrice: data.catalog_invoice.admin_price,
          taxFee: data.catalog_invoice.tax_fee ?? 0,
          totalPrice: data.catalog_invoice.total_price
        }}
      />
    </div>
  )
}

export default BookingHistoryContent
