import React from 'react'

const IcLike = ({ className, size = 20, color = 'none', stroke = '#12B76A' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill={color}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 11H2C1.73478 11 1.48043 10.8946 1.29289 10.7071C1.10536 10.5196 1 10.2652 1 10V6.5C1 6.23478 1.10536 5.98043 1.29289 5.79289C1.48043 5.60536 1.73478 5.5 2 5.5H3.5M7 4.5V2.5C7 2.10218 6.84196 1.72064 6.56066 1.43934C6.27936 1.15804 5.89782 1 5.5 1L3.5 5.5V11H9.14C9.38116 11.0027 9.61519 10.9182 9.79895 10.762C9.98272 10.6058 10.1038 10.3885 10.14 10.15L10.83 5.65C10.8518 5.50668 10.8421 5.36034 10.8017 5.22113C10.7613 5.08191 10.6911 4.95315 10.5959 4.84376C10.5008 4.73437 10.383 4.64697 10.2508 4.58761C10.1185 4.52825 9.97495 4.49836 9.83 4.5H7Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IcLike
