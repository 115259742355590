import React from 'react'
import { Modal, Typography } from 'antd';

const ModalDeleteInternalUnit = ({ isOpen, data, onSubmit, onCancel }) => {
    return (
        <Modal
            title="Hapus Internal Unit"
            open={isOpen}
            onOk={() => onSubmit(data)}
            onCancel={onCancel}
            okText="Confirm"
            okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
            cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
            maskClosable={false}
        >
        <Typography.Text className="font-medium">
            {`Apakah Anda yakin ingin menghapus Internal Unit ${data?.unit_name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
}

export default ModalDeleteInternalUnit;