import React from 'react'

const ModalForm = ({
  children,
  minHeight,
  maxHeight,
  minWidth,
  maxWidth,
  zIndex,
  height,
  overflowY
}) => {
  const maxW = maxWidth ?? ''
  const minW = minWidth ?? 'min-w-[25%]'
  const maxH = maxHeight ?? 'max-h-[95%]'
  const minH = minHeight ?? 'min-h-[33%]'
  const z = zIndex ?? 'z-10'
  const h = height ?? ''
  const y = overflowY ?? 'overflow-y-auto'

  return (
    <div
      className={`relative p-5 bg-white bg-opacity-100 ${y} ${z} ${minH} ${maxH} ${minW} ${maxW} ${h} rounded-md`}>
      {children}
    </div>
  )
}

export default ModalForm
