import { Breadcrumb, DatePicker, Input, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { IcSearch } from 'assets/icons'
import { CardContainer, Table } from 'components'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { showErrorMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  owner_name: '',
  tester_name: '',
  status: '',
  start_date: '',
  end_date: '',
  dateRangeValue: []
}
const dropdownStatus = [
  { value: 'scheduled', label: 'Terjadwal' },
  { value: 'ongoing', label: 'Sedang berlangsung' },
  { value: 'done', label: 'Selesai' },
  { value: 'not_confirmed', label: 'Belum dikonfirmasi' }
]
const TestDriveCabang = () => {
  const { id, cabang } = useParams()
  const { goBack } = useHistory()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [dropdownPemilik, setdropdownPemilik] = useState([])
  const [dropdownTester, setdropdownTester] = useState([])

  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates) {
      setTableState((prev) => ({
        ...prev,
        dateRangeValue: dates,
        start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('DD/MM/YYYY'),
        end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('DD/MM/YYYY'),
        offset: 0
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        dateRangeValue: [],
        start_date: '',
        end_date: '',
        offset: 0
      }))
    }
  }
  const columns = [
    {
      name: 'Tanggal',
      selector: (row) =>
        row?.test_drive_schedule?.created_at
          ? moment(row.test_drive_schedule?.created_at).format('DD/MM/YYYY')
          : '-',
      cell: (row) =>
        row?.test_drive_schedule?.created_at
          ? moment(row.test_drive_schedule?.created_at).format('DD/MM/YYYY')
          : '-',
      grow: 1
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col gap-2 py-1 overflow-hidden">
          <span className="text-sm-medium text-gray-700">{row?.unit_name}</span>
          <span className="text-xs-regular text-gray-500">{row?.unit_police_number}</span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.unit_owner_fullname,
      cell: (row) =>
        row?.unit_owner_fullname && row?.unit_owner_fullname !== ''
          ? row?.unit_owner_fullname
          : '-',
      grow: 2
    },
    {
      name: 'Tester',
      selector: (row) => row?.tester_fullname,
      cell: (row) =>
        row?.tester_fullname && row?.tester_fullname !== '' ? row?.tester_fullname : '-',
      grow: 2
    },
    {
      name: 'Waktu',
      ignoreRowClick: true,
      center: true,
      selector: (row) => row?.test_drive_schedule?.time,
      cell: (row) => row?.test_drive_schedule?.time,
      grow: 1
    }
  ]

  // Get Api
  // Get testdrive list
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'testdrive-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.owner_name,
      tableState.tester_name,
      tableState.status,
      tableState.start_date,
      tableState.end_date
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixJualBeli}/${urls.analytic.transactionBranch.testDriveTotalList}/${id}?keywords=${tableState.keywords}&limit=${tableState?.limit}&offset=${tableState?.offset}&owner_name=${tableState?.owner_name}&tester_name=${tableState?.tester_name}&status=${tableState?.status}&start_date=${tableState?.start_date}&end_date=${tableState?.end_date}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get list owner
  useQuery({
    queryKey: ['owner-list', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixJualBeli}/${urls.analytic.transactionBranch.testDriveOwner}/${id}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setdropdownPemilik(res?.resData?.data ?? [])
    }
  })

  // Get list testers
  useQuery({
    queryKey: ['testers-list', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixJualBeli}/${urls.analytic.transactionBranch.testDriveTester}/${id}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setdropdownTester(res?.resData?.data ?? [])
    }
  })

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }
  return (
    <Layout style={{ padding: 32, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Workshop Management
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => goBack()}>
          {cabang ?? '-'}
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Total Test Drive
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <CardContainer minHeight="65%" className={'grid grid-cols-1 gap-5'}>
          <p className="display-xs-semibold text-gray-900 m-0">Daftar Unit</p>
          <div className="flex flex-row w-full items-center justify-between space-x-1">
            <div className="flex flex-row w-full items-center space-x-3">
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 mr-3"
                style={{ minWidth: '150px' }}
                value={tableState?.owner_name}
                onChange={(e) => setTableState((prev) => ({ ...prev, owner_name: e, offset: 0 }))}>
                <Select.Option value={''}>Semua Pemilik</Select.Option>
                {dropdownPemilik?.map((pemilik, idx) => (
                  <Select.Option key={idx} value={pemilik}>
                    {pemilik}
                  </Select.Option>
                ))}
              </Select>
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 mr-3"
                style={{ minWidth: '150px' }}
                value={tableState?.tester_name}
                onChange={(e) => setTableState((prev) => ({ ...prev, tester_name: e, offset: 0 }))}>
                <Select.Option value={''}>Semua Tester</Select.Option>
                {dropdownTester?.map((tester, idx) => (
                  <Select.Option key={idx} value={tester}>
                    {tester}
                  </Select.Option>
                ))}
              </Select>
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 mr-3"
                style={{ minWidth: '150px' }}
                value={tableState?.status}
                onChange={(e) => setTableState((prev) => ({ ...prev, status: e, offset: 0 }))}>
                <Select.Option value={''}>Semua Status</Select.Option>
                {dropdownStatus?.map((status, idx) => (
                  <Select.Option key={idx} value={status?.value}>
                    {status?.label}
                  </Select.Option>
                ))}
              </Select>

              <RangePicker
                onOpenChange={(open) => setIsShowDatePicker(open)}
                onChange={handleChangeRangePicker}
                format="DD/MM/YYYY"
                open={isShowDatePicker}
                value={tableState?.dateRangeValue}
                placeholder={['Tanggal Mulai', 'Tanggal Akhir']}
                className="min-w-1/4 rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500 py-2"
              />
            </div>
            <Input
              className="w-1/4 rounded-md py-2"
              placeholder="Pencarian"
              prefix={<IcSearch className="text-gray-400" />}
              value={tableState?.keywords}
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
              }
            />
          </div>
          <div className="flex flex-col w-full space-y-5">
            <div className="flex flex-col space-y-5">
              <Table
                columns={columns}
                data={tableState?.listData}
                pending={isLoading ?? isFetching}
                totalRows={tableState?.totalRows}
                handlePerRowsChange={handlePerRowsChange}
                pageSize={tableState?.limit}
                page={tableState?.offset}
                onChangePrevPage={() => {
                  if (tableState?.offset > 0) {
                    onChangePage(tableState?.offset - tableState?.limit)
                  }
                }}
                onChangeNextPage={() => {
                  if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                    onChangePage(tableState?.offset + tableState?.limit)
                  }
                }}
              />
            </div>
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default TestDriveCabang
