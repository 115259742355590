import React from 'react'
import { CardContainer } from 'components'

const InformasiSpekUnitVerticalCard = ({ data }) => (
  <CardContainer isHeightFull width="90%">
    <div className="flex flex-col w-full items-start border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
      <span className="font-semibold text-2xl text-gray-900">Informasi Unit</span>
    </div>
    <div className="flex flex-col w-full space-y-4 pt-4">
      {Object.entries(data)
        .filter((item) => item[0] !== 'id' && item[0] !== 'owner' && item[0] !== 'riwayatService')
        .map((item, index) => (
          <div key={index} className="grid grid-cols-2 w-full items-center justify-start">
            <span className="font-medium text-gray-900 text-left text-lg">
              {`${item[0].substring(0, 1).toUpperCase()}${item[0]
                .substring(1, item[0].length)
                .replace(/([A-Z])/g, ' $1')
                .trim()}`}
            </span>
            <span className="font-normal text-gray-700 text-left text-lg -ml-20">{item[1]}</span>
          </div>
        ))}
    </div>
  </CardContainer>
)

export default InformasiSpekUnitVerticalCard
