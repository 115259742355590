import React from 'react'
import { CardContainer } from 'components'
import { formatCurrency } from 'utils'

const InformasiPenjualanSection = ({
  penjual,
  pembeli,
  alamatShowroom,
  harga
}) => {
  const fontFieldStyle = 'font-medium text-gray-900 text-lg'
  const fontValueStyle = 'font-normal text-gray-700 text-md'

  return (
    <CardContainer
      minHeight="20%"
    >
      <div
        className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300"
      >
        <span className="font-semibold text-2xl text-gray-900">
                   Informasi Penjualan
        </span>
      </div>
      <div className="grid grid-cols-4 gap-4 w-full pt-4">
        <div className="flex flex-col">
          <span className={fontFieldStyle}>
                Penjual
          </span>
          <span className={fontValueStyle}>
            {penjual}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fontFieldStyle}>
                Pembeli
          </span>
          <span className={fontValueStyle}>
            {pembeli}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fontFieldStyle}>
                Alamat Showroom
          </span>
          <span className={fontValueStyle}>
            {alamatShowroom}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fontFieldStyle}>
                Harga
          </span>
          <span className="font-medium text-[#F79009]">
            {formatCurrency(harga)}
          </span>
        </div>
      </div>
    </CardContainer>
  )
}

export default InformasiPenjualanSection
