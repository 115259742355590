import React, { useState } from 'react'
import { BaseIcon, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'

const ModalCreateUpdateGradeInspeksi = ({ state, onClose, onSubmit }) => {
  const [startGrade, setStartGrade] = useState(0)

  return (
    <ModalContainer>
      <ModalForm minHeight="min-h-[25%]">
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.type === 'create' ? 'Tambah' : 'Edit'} Grade Inspeksi`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <Formik
            initialValues={{
              grade: state.type === 'create' ? '' : state.data.grade,
              start_grade: state.type === 'create' ? 0 : state.data.start_grade,
              end_grade: state.type === 'create' ? 0 : state.data.end_grade
            }}
            onSubmit={(values) => onSubmit(values)}>
            <Form key="createUpdateGradeInspeksiForm">
              <div className="flex flex-col w-full space-y-5">
                <FormInput
                  label="Grade"
                  name="grade"
                  placeholder="Masukkan Grade"
                  isRequired
                  onValidate={(value) => {
                    if (!value) {
                      return 'Silahkan Masukkan Grade'
                    }
                  }}
                />
                <div className="flex flex-col w-full">
                  <label className="font-medium block mb-2 text-md text-gray-700">
                    Rentang
                    <span className="text-red-600">{' *'}</span>
                  </label>
                  <div className="flex flex-row items-center space-x-4">
                    <FormInput
                      name="start_grade"
                      type="number"
                      min={0}
                      isRequired
                      onValidate={(value) => {
                        if (!value) {
                          if (value < 0) {
                            return 'Silahkan Masukkan Rentang Min.'
                          }
                        } else {
                          setStartGrade(value)
                        }
                      }}
                      onKeyPress={(value) => setStartGrade(value)}
                    />
                    <label className="font-medium block mb-2 text-md text-gray-700">{`__`}</label>
                    <FormInput
                      name="end_grade"
                      type="number"
                      isRequired
                      min={startGrade ? startGrade + 1 : 1}
                      onValidate={(value) => {
                        if (!value) {
                          return 'Silahkan Masukkan Rentang Max.'
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row w-full items-center justify-end space-x-4">
                  <button
                    type="button"
                    className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                    onClick={() => onClose()}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                    {`${state.type === 'create' ? 'Tambah Grade Inspeksi' : 'Simpan'}`}
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateGradeInspeksi
