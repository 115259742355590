import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { IcEdit, IcTrash } from 'assets/icons'
import ModalUpdateAdmin from './components/ModalUpdateAdmin'
import { Button } from 'components'
import AdminPageComponent from './components/AdminPageComponent'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null,
  role_id: ''
}

const AdminPage = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    user: null
  })
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const columns = [
    {
      name: 'Nama',
      selector: (row) => (row?.fullname ? row?.fullname : row?.company_name),
      sortable: true
    },
    {
      name: 'Role',
      selector: (row) => row?.role_name,
      cell: (row) => <div className="capitalize">{row?.role_name}</div>,
      sortable: true
    },
    {
      name: 'Nomor Whatsapp',
      selector: (row) => row?.phone_number,
      sortable: true
    },
    {
      name: 'Email',
      selector: (row) => row?.email,
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => setModal({ show: true, type: 'edit', user: row })}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ show: true, type: 'delete', user: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'admin-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.role_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order,
        role_type: 'adm',
        role_id: tableState.role_id
      }
      const res = await apiGet({
        url: 'user-service/admin/users',
        token,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  useQuery({
    queryKey: ['role-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        role_type: 'adm'
      }
      const res = await apiGet({
        url: 'user-service/admin/roles/all',
        data: params,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setRoles(res?.resData?.data ?? [])
    }
  })

  const saveData = async (data) => {
    try {
      let res
      let body = {
        ...data
      }

      if (data?.id) {
        const id = data?.id
        res = await apiPut({
          url: `user-service/admin/users/${id}`,
          token,
          data: body,
          onLogout: () => dispatch(logout())
        })
      } else {
        res = await apiPost({
          url: `user-service/admin/users/adm`,
          token,
          data: body,
          onLogout: () => dispatch(logout())
        })
      }
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        showSuccessMessage({
          content: data?.id ? 'Mengubah Admin Berhasil' : 'Menambahkan Admin Berhasil!'
        })
        refetch()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    } catch (error) {
      showErrorMessage({ content: error?.message })
    }
  }

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        url: `user-service/admin/users/${data?.id}`,
        token,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        refetch()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Master Data</Breadcrumb.Item>
        <Breadcrumb.Item>Admin</Breadcrumb.Item>
      </Breadcrumb>
      <ModalUpdateAdmin
        isOpen={modal.show}
        type={modal.type}
        onSubmit={saveData}
        onDelete={deleteData}
        user={modal.user}
        onCancel={() => setModal({ ...modal, show: false })}
      />
      <AdminPageComponent
        isLoading={isLoading}
        columns={columns}
        data={tableState?.listData}
        onAdd={() => setModal({ show: true, type: 'add', data: {} })}
        tableState={tableState}
        setTableState={setTableState}
        roles={roles}
      />
    </Layout>
  )
}
export default AdminPage
