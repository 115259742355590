import React from 'react'
import { CardContainer, FormInputRupiah } from 'components'
import { Formik } from 'formik'

const HargaJualForm = ({ name, state, onChangePrice }) => (
  <CardContainer className="pb-8">
    <div className="flex flex-row items-start space-x-1 w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
      <span className="font-semibold text-2xl text-gray-900">Harga Jual</span>
      <span className="text-red-600">{' *'}</span>
    </div>
    <div className="w-full mt-4">
      <Formik>
        <FormInputRupiah
          name={name}
          value={state.value}
          mode="dark"
          isWithLabelRp
          isRequired={true}
          isEmptyField={state.isEmpty}
          message={state.message}
          onChange={(value) => onChangePrice(value)}
        />
      </Formik>
    </div>
  </CardContainer>
)

export default HargaJualForm
