import React from 'react'
import { CardContainer, FormInput } from 'components'
import { IcEdit } from 'assets/icons'
import { Formik } from 'formik'

const CatatanFormCard = ({ onEdit, onBlurEditing, isEditing, title, state }) => {
  const borderBottomHideStyle = isEditing ? 'border-b-0' : 'border-b-1'

  return (
    <CardContainer>
      <div
        className={`flex flex-row items-center justify-between border border-solid ${borderBottomHideStyle} border-x-0 border-t-0 pb-4 border-b-gray-300`}>
        <span className="font-semibold text-2xl text-gray-900">Catatan</span>
        <div
          onClick={() => onEdit()}
          className="flex flex-row items-center justify-center space-x-2 rounded-md py-2 px-3 border border-solid border-gray-300 bg-white hover:cursor-pointer">
          <IcEdit className="text-gray-700" />
          <span className="font-medium text-gray-700">{title}</span>
        </div>
      </div>
      {isEditing ? (
        <Formik
          initialValues={{
            desc: state?.desc
          }}>
          <FormInput
            name="desc"
            placeholder={state?.placeholder}
            isDisabled={isEditing === false}
            isButton={isEditing === false}
            isRequired
            isEmpty={state?.isEmpty}
            errorMessage={state?.message}
            component="textarea"
            onBlur={(value) => onBlurEditing(value)}
          />
        </Formik>
      ) : (
        <div className="flex flex-col items-start justify-start space-y-4 mt-4">
          <span className="font-medium text-gray-700 text-left text-md">
            {state?.desc === '' ? state?.placeholder : state?.desc}
          </span>
        </div>
      )}
    </CardContainer>
  )
}

export default CatatanFormCard
