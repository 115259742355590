import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { IcSearch } from 'assets/icons'
import { Table } from 'components'

const JadwalServiceComponent = ({
  columns,
  data,
  isLoading,
  provinces,
  cities,
  tableState,
  setTableState
}) => {
  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="mb-5">
          <Typography.Text className="font-semibold text-2xl">Daftar Cabang</Typography.Text>
        </div>
        <div className="mb-5 flex justify-between">
          <div className="flex">
            <Select
              className="custom-table-limit mr-3"
              style={{ minWidth: '150px' }}
              value={tableState?.province_id ?? ''}
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, province_id: e ?? '', city_id: '', offset: 0 }))
              }
              showSearch
              allowClear
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Provinsi
              </Select.Option>
              {provinces?.map((province, idx) => (
                <Select.Option key={idx} value={province?.id}>
                  {province?.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              className="custom-table-limit"
              style={{ minWidth: '150px' }}
              value={tableState?.city_id ?? ''}
              onChange={(e) => setTableState((prev) => ({ ...prev, city_id: e ?? '' }))}
              showSearch
              allowClear
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Cabang
              </Select.Option>
              {cities?.map((city, idx) => (
                <Select.Option key={idx} value={city?.id}>
                  {city?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Input
            className="w-1/4 rounded-md"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) =>
              setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
            }
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

export default JadwalServiceComponent
