import { DatePicker, Input, Select } from 'antd'
import { apiGet, apiPut, urls } from 'api'
import { IcLoadMore, IcSearch } from 'assets/icons'
import {
  CardContainer,
  ModalLoadMoreJadwalService,
  ModalServiceSelesaiJadwalService,
  Table
} from 'components'
import moment from 'moment'
import 'moment/locale/en-gb'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  onReturnStatusTelahDikonfirmasiJadwalService,
  onReturnWaktuPerluDikonfirmasi,
  showErrorMessage
} from 'utils'
import { openLinkWA } from 'utils/openLinkWA'

const ServiceJadwalDaftarUnit = ({ idCabang, stall = 0, onLogout }) => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)

  const [units, setUnits] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: [],
    stall: '',
    booking_start_date: '',
    booking_end_date: '',
    keywords: '',
    dateRangeValue: []
  })

  const [dropDownStall, setDropDownStall] = useState([])

  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates) {
      setUnits((prev) => ({
        ...prev,
        dateRangeValue: dates,
        booking_start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        booking_end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        offset: 0
      }))
    } else {
      setUnits((prev) => ({
        ...prev,
        dateRangeValue: [],
        booking_start_date: '',
        booking_end_date: '',
        offset: 0
      }))
    }
  }

  //* all modal state //
  const [isModalServisSelesai, setIsModalServisSelesai] = useState({
    show: false,
    desc: '',
    status: false,
    data: {}
  })

  //* END all modal state //

  // Set Value dropdown stall
  useEffect(() => {
    setDropDownStall([
      ...[...Array(stall)].map((arr, idx) => ({ name: `Stall ${idx + 1}`, value: idx + 1 }))
    ])
  }, [stall])

  const onReturnDescServisSelesai = (status, data) => {
    switch (status) {
      case false:
        return 'Progres servis belum dilengkapi. Mohon cek progress sebelum konfirmasi servis selesai.'
      case true:
        return `Anda Yakin konfirmasi servis selesai untuk unit ${
          data?.unit_name ?? ''
        } dengan pemilik ${data?.full_name ?? ''}.`
      default:
        break
    }
  }

  const onReturnCompleteProgress = (data) => {
    if (data?.progress_finished_total === data?.progress_total) {
      return true
    } else {
      return false
    }
  }

  const columnsUnitTelahDikonfirmasi = [
    {
      name: 'Tanggal',
      selector: (row) => row?.service_date,
      cell: (row) => moment(row?.service_date).format('DD/MM/YYYY')
    },
    {
      name: 'Unit',
      width: '25%',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <span className="font-medium text-gray-700">{`${row?.unit_name ?? ''}`}</span>
          <span className="font-normal text-gray-500">{`${row?.unit_plat_number ?? ''}`}</span>
        </div>
      )
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.full_name,
      cell: (row) => row?.full_name
    },
    {
      name: 'Stall',
      selector: (row) => row?.stall_no,
      cell: (row) => row?.stall_no ?? '-'
    },
    {
      name: 'Waktu',
      selector: (row) => row?.service_start_time,
      cell: (row) =>
        onReturnWaktuPerluDikonfirmasi(
          row?.kuota_layanan,
          `${moment(row?.service_start_time, 'HH:mm').format('HH:mm')} - ${moment(
            row?.service_end_time,
            'HH:mm'
          ).format('HH:mm')}`
        )
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
      cell: (row) => onReturnStatusTelahDikonfirmasiJadwalService(row?.status)
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '5%',
      cell: (row) => (
        <ModalLoadMoreJadwalService
          status={row?.status}
          onClickServisSelesai={() => {
            setIsModalServisSelesai({
              desc: onReturnDescServisSelesai(onReturnCompleteProgress(row), row),
              show: true,
              status: onReturnCompleteProgress(row),
              data: row
            })
          }}
          onClickDetailServis={() => router.push(`/mekanik/services/jadwal/detail/${row.id}`)}
          onClickWhatsApp={() => {
            openLinkWA(row?.phone)
          }}>
          <button
            onClick={() => {}}
            type="button"
            className="flex items-center justify-center border-0 bg-transparent self-center hover:cursor-pointer">
            <IcLoadMore className="text-gray-500" />
          </button>
        </ModalLoadMoreJadwalService>
      )
    }
  ]

  // *API
  // Get Confirmed
  const {
    isLoading: isLoadingConfirmed,
    refetch: refetchConfirmed,
    isFetching: isFetchingConfirmed
  } = useQuery({
    queryKey: [
      'confirmed',
      idCabang,
      units?.keywords,
      units?.stall,
      units?.booking_start_date,
      units?.booking_end_date,
      units?.limit,
      units?.offset
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.servicePickup.rootWorkshop}/${idCabang}/${urls?.service?.servicePickup.serviceConfirmed}?keywords=${units?.keywords}&stall_no=${units?.stall}&start_date=${units?.booking_start_date}&end_date=${units?.booking_end_date}&limit=${units?.limit}&offset=${units?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setUnits((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Konfirmasi Servis Selesai
  const handleKonfirmasiServiceSelesai = async () => {
    try {
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.servicePickup.rootService}/${isModalServisSelesai?.data?.id}/${urls.service.servicePickup.finished}`,
        token: token,
        onLogout
      })
      if (res?.resStatus === 200) {
        setIsModalServisSelesai({ ...isModalServisSelesai, show: false, status: false, data: {} })
        refetchConfirmed()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // *END API

  // *Table state
  const handlePerRowsChange = (newPerPage) => {
    setUnits((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setUnits((prev) => ({
      ...prev,
      offset: page
    }))
  }
  // END TABLE STATE

  return (
    <div className="flex flex-col w-full space-y-5">
      <ModalServiceSelesaiJadwalService
        openModal={isModalServisSelesai.show}
        desc={isModalServisSelesai?.desc}
        onClose={() =>
          setIsModalServisSelesai({ ...isModalServisSelesai, show: false, status: false, data: {} })
        }
        status={isModalServisSelesai?.status}
        onSubmit={handleKonfirmasiServiceSelesai}
      />
      <CardContainer minHeight="60%">
        <div className="flex flex-col space-y-5">
          <span className="font-semibold text-2xl text-gray-900">Daftar Unit Servis</span>
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row w-full items-center space-x-5">
              <Select
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 mr-3"
                style={{ minWidth: '150px' }}
                value={units?.stall}
                onChange={(e) => setUnits((prev) => ({ ...prev, stall: e, offset: 0 }))}>
                <Select.Option value={''}>Semua Stall</Select.Option>
                {dropDownStall?.map((stall, idx) => (
                  <Select.Option key={idx} value={stall?.value}>
                    {stall?.name}
                  </Select.Option>
                ))}
              </Select>

              <RangePicker
                onOpenChange={(open) => setIsShowDatePicker(open)}
                onChange={handleChangeRangePicker}
                format="DD/MM/YYYY"
                open={isShowDatePicker}
                value={units?.dateRangeValue}
                placeholder={['Tanggal Mulai', 'Tanggal Akhir']}
                className="min-w-1/4 rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500 py-2"
                renderExtraFooter={() => {
                  return (
                    <div className="flex flex-row justify-end items-center space-x-4 my-3">
                      <button
                        type="button"
                        className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-gray-700 bg-white"
                        onClick={() => {
                          setIsShowDatePicker(false)
                        }}>
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-white bg-primary-500"
                        onClick={() => {
                          setIsShowDatePicker(false)
                        }}>
                        Apply
                      </button>
                    </div>
                  )
                }}
              />
            </div>
            <Input
              className="w-1/4 rounded-md py-2"
              placeholder="Pencarian"
              prefix={<IcSearch className="text-gray-400" />}
              value={units?.keywords}
              onChange={(e) => setUnits((prev) => ({ ...prev, keywords: e?.target?.value }))}
            />
          </div>
          <Table
            columns={columnsUnitTelahDikonfirmasi}
            data={units?.listData}
            pending={isLoadingConfirmed ?? isFetchingConfirmed}
            totalRows={units?.totalRows}
            handlePerRowsChange={handlePerRowsChange}
            pageSize={units?.limit}
            page={units?.offset}
            onChangePrevPage={() => {
              if (units?.offset > 0) {
                onChangePage(units?.offset - units?.limit)
              }
            }}
            onChangeNextPage={() => {
              if (units?.totalRows > units?.limit + units?.offset) {
                onChangePage(units?.offset + units?.limit)
              }
            }}
          />
        </div>
      </CardContainer>
    </div>
  )
}

export default ServiceJadwalDaftarUnit
