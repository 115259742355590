import React, { useState } from 'react'
import { BaseIcon, FormDropDown, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'

const ModalCreateUpdateModel = ({ type, minHeight, onClose, onSubmit, state, dataDropDown }) => {
  const [selectBrand, setSelectBrand] = useState({
    value: type === 'edit' ? state.brandId : null,
    isEmpty: false,
    message: 'Silahkan pilih brand'
  })
  const [selectModel, setSelectModel] = useState({
    value: type === 'edit' ? state.modelName : ''
  })

  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${type === 'create' ? 'Tambah' : 'Edit'} Model`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              modelName: selectModel.value,
              brandSelector: selectBrand.value
            }}
            onSubmit={(values) => onSubmit(values)}>
            <Form key="createUpdateModelForm">
              <div className="flex flex-col w-full space-y-5">
                <FormInput
                  label="Nama Model"
                  name="modelName"
                  placeholder="Masukkan Nama Model"
                  isRequired
                  onValidate={(value) => {
                    if (!value) {
                      return 'Silahkan Masukkan Nama Model'
                    }
                    setSelectModel((prev) => ({ ...prev, value: value }))
                  }}
                />
                <FormDropDown
                  isSearch
                  allowClear
                  label="Brand"
                  name="brandSelector"
                  placeholder="Pilih Brand"
                  isRequired
                  data={dataDropDown}
                  isEmpty={selectBrand.isEmpty}
                  errorMessage={selectBrand.message}
                  onChange={(value) => {
                    if (!value || value === '') {
                      setSelectBrand((prev) => ({ ...prev, isEmpty: true }))
                    } else {
                      setSelectBrand((prev) => ({ ...prev, isEmpty: false }))
                    }
                    setSelectBrand((prev) => ({ ...prev, value: value }))
                  }}
                />
                <div className="flex flex-row w-full items-center justify-end space-x-4">
                  <button
                    type="button"
                    className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                    onClick={() => onClose()}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                    {`${type === 'create' ? 'Tambah Model' : 'Simpan'}`}
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateModel
