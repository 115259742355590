import React from 'react'
import { Spin } from 'antd'

const Loading = ({
  visible = false,
  title = 'Loading',
  display = 'relative',
  classNameWidth = 'w-full'
}) => {
  const visibility = visible ? 'visible' : 'invisible'

  return (
    <div
      className={`flex ${visibility} ${display} h-[100%] justify-center bg-[#F5F5F5] bg-opacity-10 items-center overflow-x-hidden overflow-y-hidden z-50 inset-0 outline-none focus:outline-none ${classNameWidth}`}>
      <Spin
        className="font-semibold text-primary-300"
        tip={title}
        size="large"
        spinning={visible}
      />
    </div>
  )
}

export default Loading
