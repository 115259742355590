import React from 'react'
import { Breadcrumb, Layout } from 'antd'
import DetailHasilInspeksiComponent from './components/detail-hasil-inspeksi-component'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { Loading } from 'components'

const DetailHasilInspeksi = () => {
  const { id } = useParams()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const router = useHistory()

  const { isLoading, data } = useQuery({
    queryKey: ['hasil-inspeksi-detail', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        url: `inspeksi-service/admin/inspection-unit/${id}`,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onError: (e) => {
      console.log(e)
    }
  })

  if (isLoading) {
    return <Loading visible />
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item className="cursor-pointer" onClick={() => router.goBack()}>
          Hasil Inspeksi
        </Breadcrumb.Item>
        <Breadcrumb.Item>{data?.resData?.data?.unit_name}</Breadcrumb.Item>
      </Breadcrumb>
      <DetailHasilInspeksiComponent data={data?.resData?.data} />
    </Layout>
  )
}

export default DetailHasilInspeksi
