import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'

import { IcEdit, IcTrash } from 'assets/icons'
import { Button } from 'components'
import BagianInspeksiComponent from './components/BagianInspeksiComponent'
import ModalUpdateBagianInspeksi from './components/ModalUpdateBagianInspeksi'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const BagianInspeksi = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    type: 'add',
    show: false,
    data: {}
  })
  const [tableState, setTableState] = useState(initialTableState)
  const columns = [
    {
      name: 'Kategori',
      selector: (row) => row.inspectionSubCategory?.inspectionCategory?.name,
      cell: (row) => (
        <span className="font-medium text-gray-700">
          {row.inspectionSubCategory?.inspectionCategory?.name}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Bagian Inspeksi',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>,
      grow: 3
    },
    {
      name: 'Bobot',
      selector: (row) => row.weight_point,
      cell: (row) => <span className="font-medium text-gray-700">{row.weight_point}</span>,
      grow: 1
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      grow: 1,
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
            onClick={() => setModal({ type: 'edit', show: true, data: row })}>
            <IcEdit className="text-gray-700" size={18} />
          </Button>
          <Button
            className="bg-white border-solid border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ type: 'delete', show: true, data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'part-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        url: `inspeksi-service/admin/inspection-parts`,
        data: params,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      console.log(res)
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const saveData = async (data) => {
    try {
      let res
      let body = {
        inspection_category_id: data.inspection_category_id,
        inspection_sub_category_id: data.inspection_sub_category_id,
        weight_point: data.weight_point,
        name: data.name
      }

      if (data?.id) {
        const id = data?.id
        res = await apiPut({
          url: `inspeksi-service/admin/inspection-parts/${id}`,
          token,
          data: body,
          onLogout: () => dispatch(logout())
        })
      } else {
        res = await apiPost({
          url: `inspeksi-service/admin/inspection-parts`,
          token,
          data: body,
          onLogout: () => dispatch(logout())
        })
      }
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({ content: `Successfully ${data?.id ? 'Edit' : 'Create a New'} Bagian Inspeksi.` })
        setModal({
          ...modal,
          show: false
        })
        refetch()
      } else {
        showErrorMessage({
          content: `${res.resData.message ?? res.resData.errors[0].message ?? `Failed to ${data?.id ? 'Edit' : 'Create'} Bagian Inspeksi.`
            }`
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        url: `inspeksi-service/admin/inspection-parts/${data?.id}`,
        token,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({ content: 'Successfully Delete Bagian Inspeksi.' })
        setModal({
          ...modal,
          show: false
        })
        refetch()
      } else {
        showErrorMessage({
          content: `${res.resData.message ?? res.resData.errors[0].message ?? 'Failed to Delete Bagian Inspeksi.'
            }`
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Master Data</Breadcrumb.Item>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item>Bagian Inspeksi</Breadcrumb.Item>
      </Breadcrumb>
      <ModalUpdateBagianInspeksi
        isOpen={modal.show}
        type={modal.type}
        data={modal.data}
        onSubmit={saveData}
        onDelete={deleteData}
        onCancel={() => setModal({ ...modal, show: false })}
      />
      <BagianInspeksiComponent
        isLoading={isLoading}
        columns={columns}
        data={tableState?.listData}
        onAdd={() => setModal({ show: true, type: 'add', data: null })}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}

export default BagianInspeksi
