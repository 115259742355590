import React from 'react'
import { CardContainer, FormDropDown, FormInput } from 'components'
import { Formik } from 'formik'

const InformasiPemilikForm = ({
  dropDownProvince,
  dropDownCity,
  dropDownSubDistrict,
  dropDownKelurahan,
  state,
  onChangeInput = () => {},
  onChangeDropDown = () => {}
}) => (
  <CardContainer className="pb-8">
    <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
      <span className="font-semibold text-2xl text-gray-900">Informasi Pemilik</span>
    </div>
    <div className="flex flex-col w-full space-y-5 mt-4">
      <Formik
        initialValues={{
          namaLengkap: state.namaLengkap.value
        }}>
        <FormInput
          label="Nama Lengkap"
          name="namaLengkap"
          placeholder="Masukkan Nama Lengkap"
          isRequired
          isEmpty={state.namaLengkap.isEmpty}
          errorMessage={state.namaLengkap.message}
          onValidate={(value) => onChangeInput('namaLengkap', value)}
        />
      </Formik>
      <Formik
        initialValues={{
          email: state.email.value
        }}>
        <FormInput
          label="Email"
          name="email"
          placeholder="Masukkan Email"
          isRequired
          isEmpty={state.email.isEmpty}
          errorMessage={state.email.message}
          onValidate={(value) => onChangeInput('email', value)}
        />
      </Formik>
      <Formik
        initialValues={{
          telepon: state.telepon.value
        }}>
        <FormInput
          label="Nomor Telepon"
          name="telepon"
          placeholder="Masukkan Nomor Telepon"
          isRequired
          isEmpty={state.telepon.isEmpty}
          errorMessage={state.telepon.message}
          onValidate={(value) => onChangeInput('telepon', value)}
        />
      </Formik>
      <Formik
        initialValues={{
          alamat: state.alamat.value
        }}>
        <FormInput
          label="Alamat"
          name="alamat"
          placeholder="Masukkan Alamat"
          isRequired
          isEmpty={state.alamat.isEmpty}
          errorMessage={state.alamat.message}
          onValidate={(value) => onChangeInput('alamat', value)}
        />
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          provinceSelector: state.province.value
        }}>
        <FormDropDown
          label="Provinsi"
          name="provinceSelector"
          data={dropDownProvince}
          isRequired
          isEmpty={state.province.isEmpty}
          errorMessage={state.province.message}
          isSearch
          onChange={(value) => onChangeDropDown('province', value)}
        />
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          citySelector: state.city.value
        }}>
        <FormDropDown
          label="Kota"
          name="citySelector"
          data={dropDownCity}
          isRequired
          isSearch
          isEmpty={state.city.isEmpty}
          errorMessage={state.city.message}
          onChange={(value) => onChangeDropDown('city', value)}
        />
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          subDistrictSelector: state.subDistrict.value
        }}>
        <FormDropDown
          label="Kecamatan"
          name="subDistrictSelector"
          data={dropDownSubDistrict}
          isRequired
          isSearch
          isEmpty={state.subDistrict.isEmpty}
          errorMessage={state.subDistrict.message}
          onChange={(value) => onChangeDropDown('subDistrict', value)}
        />
      </Formik>
      <Formik
        enableReinitialize
        initialValues={{
          kelurahanSelector: state.kelurahan.value
        }}>
        <FormDropDown
          label="Kelurahan"
          name="kelurahanSelector"
          data={dropDownKelurahan}
          isRequired
          isSearch
          isEmpty={state.kelurahan.isEmpty}
          errorMessage={state.kelurahan.message}
          onChange={(value) => onChangeDropDown('kelurahan', value)}
        />
      </Formik>
      <Formik
        initialValues={{
          kodePos: state.postalCode.value
        }}>
        <FormInput
          label="Kode Pos"
          name="kodePos"
          placeholder="Masukkan Kode Pos"
          isRequired
          isEmpty={state.postalCode.isEmpty}
          errorMessage={state.postalCode.message}
          onValidate={(value) => onChangeInput('postalCode', value)}
        />
      </Formik>
    </div>
  </CardContainer>
)

export default InformasiPemilikForm
