import { Breadcrumb, Layout } from 'antd'
import { dummyTransaksiCabang } from 'assets/dummies'
import { IcDownload } from 'assets/icons'
import {
  BadgeItem,
  CardContainer,
  CatatanCard,
  CustomButton,
  InformasiServisCard,
  InformasiUserCard,
  InspeksiCard,
  PaymentReceiptCard
} from 'components'
import InformasiSpekUnitHorizontalCard from 'components/card/informasi-spek-unit-horizontal-card'
import React from 'react'

const SalesOrderPage = () => {
  const dummyData = dummyTransaksiCabang[0]
  const dummyInformationInspeksi = {
    tempat: 'Birdmobil Surabaya',
    tanggal: '24/10/2022',
    waktu: '07.00 - 09.00',
    stall: '2',
    inspektor: 'Nielsen Norman',
    statusInspeksi: 'Sedang Berlangsung',
    harga: 2000000,
    summaryResultInspection: [
      { id: 0, categoryName: 'Eksterior', result: 40, total: 50 },
      { id: 1, categoryName: 'Interior', result: 90, total: 90 },
      { id: 2, categoryName: 'Tes Jalan', result: 10, total: 10 },
      { id: 3, categoryName: 'Bagian Kosong', result: 32, total: 32 }
    ]
  }
  const fieldStyle = 'font-medium text-gray-900 text-lg'
  const valueStyle = 'font-normal text-gray-700 text-md'
  return (
    <Layout className="grid grid-cols-1 gap-6 p-8" style={{ background: '#F5F5F5', width: '100%' }}>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Test Drive
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Birdmobil Surabaya
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Sales Order
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="grid grid-cols-1 gap-6">
        <CardContainer>
          <div className="flex justify-between items-center">
            <span className="display-xs-semibold text-gray-900">Sales Order</span>
            <CustomButton type="plain">
              <div className="flex justify-center items-center gap-1">
                <IcDownload className="stroke-gray-700" />
                <span className="text-sm-medium text-gray-700">Print SO</span>
              </div>
            </CustomButton>
          </div>
        </CardContainer>
        {/* Information Test Drive */}
        {/* <CardContainer className="grid grid-cols-1 ">
          <div className="flex flex-col w-full items-start border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
            <span className="font-semibold text-2xl text-gray-900">Informasi Test Drive</span>
          </div>
          <div className="grid grid-cols-4 gap-4 w-full pt-4">
            <div className="flex flex-col">
              <span className={fieldStyle}>Tempat</span>
              <span className={valueStyle}>Birdmobil Surabaya</span>
            </div>
            <div className="flex flex-col">
              <span className={fieldStyle}>Tanggal</span>
              <span className={valueStyle}>24/10/2022</span>
            </div>
            <div className="flex flex-col">
              <span className={fieldStyle}>Waktu</span>
              <span className={valueStyle}>07.00 - 09.00</span>
            </div>
            <div className="flex flex-col">
              <span className={fieldStyle}>Status</span>
              <BadgeItem type="blue">Sedang Berlangsung</BadgeItem>
            </div>
          </div>
        </CardContainer> */}
        {/* Owner Car Information */}
        {/* <InformasiUserCard
          title="Informasi Pemilik"
          data={{
            nama: 'Matty Healy',
            email: 'matty1975@gmail.com',
            telepon: '083944857222',
            alamat: 'Jln. Danau Ranau G7E/17, Sawojajar, Malang, Jawa Timur'
          }}
        /> */}
        {/* <InformasiServisCard tipe="Servis" data={dummyInformationInspeksi} /> */}
        <InformasiUserCard
          title="Informasi Unit"
          data={{
            nama: 'Matty Healy',
            email: 'matty1975@gmail.com',
            telepon: '083944857222',
            alamat: 'Jln. Danau Ranau G7E/17, Sawojajar, Malang, Jawa Timur'
          }}
        />
        <InformasiServisCard tipe="Inspeksi" data={dummyInformationInspeksi} />
        {/* Tester Information */}
        {/* <InformasiUserCard
          title="Informasi Tester"
          data={{
            nama: 'Matty Healy',
            email: 'matty1975@gmail.com',
            telepon: '083944857222',
            alamat: 'Jln. Danau Ranau G7E/17, Sawojajar, Malang, Jawa Timur'
          }}
        /> */}
        {/* <InformasiSpekUnitHorizontalCard data={dummyData.unitDetail} /> */}
        <CatatanCard desc={dummyData.catatan} />
        <InspeksiCard
          onGoToDetailInspeksi={() => {}}
          data={{
            id: 51,
            grade: 'A',
            inspectionCategoryList: [
              { id: 1, name: 'Eksterior', count: '0/3' },
              { id: 2, name: 'Interior', count: '0/1' },
              { id: 3, name: 'Test Jalan', count: '3/5' },
              { id: 4, name: 'Bagian Kosong', count: '5/5' },
              { id: 5, name: 'Egines', count: '3/3' }
            ]
          }}
        />
        <PaymentReceiptCard data={dummyData.paymentDetail} />
      </div>
    </Layout>
  )
}

export default SalesOrderPage
