import React from 'react'

const badgeType = {
  plain: 'bg-gray-100 text-bluegray-700',
  purple: 'text-purple-700 bg-purple-50',
  primary: 'text-primary-700 bg-primary-50',
  default: 'text-blue-700 bg-blue-50',
  warning: 'bg-warning-50 text-danger-700',
  danger: 'text-danger-700 bg-danger-50 ',
  bluelight: 'text-bluelight-700 bg-bluelight-50',
  success: 'text-success-700 bg-success-50',
  base: 'text-purple-700 bg-purple-50',
  gray: 'text-gray-700 bg-gray-200'
}

function BadgeItem({ className, type = 'primary', children }) {
  return (
    <div className={`flex flex-col justify-center items-center w-fit`}>
      <div
        className={`font-medium 2xl:text-sm text-xs text-left rounded-full p-2 ${className} ${badgeType[type]}`}>
        {children}
      </div>
    </div>
  )
}

export default BadgeItem
