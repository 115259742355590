import { CardContainer } from 'components'
import BadgeProgress from 'components/badge-progress'

const InformasiServisCard = ({ tipe, data }) => {
  const fieldStyle = 'font-medium text-gray-900 text-lg'
  const valueStyle = 'font-normal text-gray-700 text-md'

  return (
    <CardContainer minHeight="20%">
      <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Informasi {tipe}</span>
      </div>
      <div className="grid grid-cols-4 gap-4 w-full pt-4">
        <div className="flex flex-col">
          <span className={fieldStyle}>Tempat</span>
          <span className={valueStyle}>{data?.tempat}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Tanggal Selesai</span>
          <span className={valueStyle}>{data?.tanggal}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Waktu</span>
          <span className={valueStyle}>{data?.waktu}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Stall</span>
          <span className={valueStyle}>{data?.stall}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>{tipe === 'Inspeksi' ? 'Inspektor' : 'Mekanik'}</span>
          <span className={valueStyle}>
            {tipe === 'Inspeksi' ? data?.inspektor : data?.mekanik}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Tanggal Pembayaran</span>
          <span className={valueStyle}>{data?.tanggalTransaksi}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Status</span>
          <BadgeProgress status={data?.statusInspeksi} />
        </div>
        {/* {tipe === 'Inspeksi' && data.grade ? (
          <div className="flex flex-col">
            <span className={fieldStyle}>Grade</span>
            <span className={'text-md-medium text-blue-500'}>{data?.grade}</span>
          </div>
        ) : null} */}
      </div>
    </CardContainer>
  )
}

export default InformasiServisCard
