import React, { useState } from 'react'
import KategoriServisComponent from './components/KategoriServisComponent'
import { Button } from 'components'
import { IcEdit, IcTrash } from 'assets/icons'
import ModalKategoriServis from './components/ModalKategoriServis'
import { useMutation, useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { errorsField, validationInput } from 'helpers'
import { logout } from 'store/actions'
import { perPageInitialData } from 'constant'

const initialModal = {
  type: 'add',
  show: false,
  user: {}
}

const KategoriServis = () => {
  const user = useSelector((state) => state.auth)
  const [categoryList, setCategoryList] = useState([])
  const [modal, setModal] = useState({ ...initialModal })
  const [kategori, setKategori] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [isRefetch, setIsRefetch] = useState(false)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  // pagination & search
  const [currentPage, setCurrentPage] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [meta, setMeta] = useState({
    http_code: undefined,
    total: undefined,
    offset: undefined,
    limit: undefined
  })
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)

  const onHandleExtraUrl = () => {
    let extraUrl = ''
    if (keyword !== '') {
      extraUrl += `&keywords=${keyword}`
    }

    return extraUrl
  }

  // fetching
  const { isLoading, refetch, isFetching, isRefetching } = useQuery({
    queryKey: ['service-category-list'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.service.prefix}/${
          urls.service.serviceManagement.serviceCategories.root
        }?limit=${perPageArr.find((item) => item.selected === true).value}&offset=0`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setCategoryList(data.resData.data)
      setCurrentPage(0)
      setMeta(data.resData.meta)
    }
  })
  const postData = useMutation({
    mutationKey: 'post-data-category',
    mutationFn: async (kategori) =>
      await apiPost({
        url: `${urls.service.prefix}/${urls.service.serviceManagement.serviceCategories.root}`,
        token: user.token,
        data: {
          name: kategori
        },
        onLogout
      }),
    onSuccess: (res) => {
      if (res?.resStatus === 200) {
        setModal({ ...initialModal })
        setKategori('')
        setErrors({})
        refetch()
      } else {
        setErrors(errorsField(res?.resData?.errors))
      }
      setIsLoadingCRUD(false)
    }
  })
  const putData = useMutation({
    mutationKey: 'put-data-category',
    mutationFn: async (kategori) =>
      await apiPut({
        url: `${urls.service.prefix}/${urls.service.serviceManagement.serviceCategories.root}/${modal.user.id}`,
        token: user.token,
        data: {
          name: kategori
        },
        onLogout
      }),
    onSuccess: (res) => {
      if (res?.resStatus === 200) {
        setModal({ ...initialModal })
        setKategori('')
        setErrors({})
        refetch()
      } else {
        setErrors(errorsField(res?.resData?.errors))
      }
      setIsLoadingCRUD(false)
    }
  })
  const deleteData = useMutation({
    mutationKey: 'delete-data-category',
    mutationFn: async () =>
      await apiDelete({
        url: `${urls.service.prefix}/${urls.service.serviceManagement.serviceCategories.root}/${modal.user.id}`,
        token: user.token,
        onLogout
      }),
    onSuccess: () => {
      setIsLoadingCRUD(false)
      setModal({ ...initialModal })
      refetch()
    }
  })

  const handleSubmit = (kategori) => {
    if (
      validationInput(kategori, {
        isRequired: true
      })?.valid
    ) {
      setIsLoadingCRUD(true)
      if (modal.type === 'add') {
        postData.mutate(kategori)
      } else {
        putData.mutate(kategori)
      }
    } else {
      setErrors({
        name: validationInput(kategori, {
          isRequired: true
        })?.messageError
      })
    }
  }

  const handleDelete = () => {
    setIsLoadingCRUD(true)
    deleteData.mutate()
  }

  const onChangePrevNextPage = (offset) => {
    setIsRefetch(true)
    setMeta({ ...meta, offset })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.service.prefix}/${
          urls.service.serviceManagement.serviceCategories.root
        }?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${offset}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setCategoryList(response.resData.data)
      } else {
        refetch()
      }
      setIsRefetch(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    setIsRefetch(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.service.prefix}/${
          urls.service.serviceManagement.serviceCategories.root
        }?limit=${value}&offset=0${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setCategoryList(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        refetch()
      }
      setIsRefetch(false)
    })
    return () => clearTimeout(mount)
  }

  const handleSearchKeyword = async (value) => {
    const response = await apiGet({
      url: `${urls.service.prefix}/${
        urls.service.serviceManagement.serviceCategories.root
      }?keywords=${value}&limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=0`,
      token: user.token,
      onLogout
    })
    if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
      setCategoryList(response.resData.data)
      setMeta(response.resData.meta)
    } else {
      refetch()
    }
    setKeyword(value)
  }

  const columns = [
    {
      name: 'Nama Kategori Servis',
      selector: (row) => row.name,
      cell: (row) => row.name,
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => {
              setKategori(row.name)
              setModal({ type: 'edit', show: true, user: row })
            }}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ type: 'delete', show: true, user: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  return (
    <>
      <ModalKategoriServis
        isOpen={modal.show}
        type={modal.type}
        data={modal.user}
        kategori={kategori}
        setKategori={setKategori}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onCancel={() => {
          setModal((prev) => ({ ...prev, show: false }))
          setErrors({})
        }}
        errors={errors}
        isLoading={isLoadingCRUD}
      />
      <KategoriServisComponent
        keywords={keyword}
        onAdd={() => setModal({ type: 'add', show: true })}
        handleSearchKeyword={handleSearchKeyword}
        // table
        isLoading={isLoading || isFetching || isRefetching}
        currentPage={meta.offset ? meta.offset + 1 : 1}
        totalPerPage={
          perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) > meta.total
            ? meta.total
            : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
        }
        dropDownDataFilter={perPageArr}
        lengthAllData={meta.total}
        columns={columns}
        data={categoryList}
        onChangePrevPage={() => {
          if (currentPage > 0) {
            setCurrentPage(currentPage - 1)
            onChangePrevNextPage(
              (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
            )
          }
        }}
        onChangeNextPage={() => {
          if (
            perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
            meta.total
          ) {
            setCurrentPage(currentPage + 1)
            onChangePrevNextPage(
              (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
            )
          }
        }}
        onChangeDropDown={(value) => onChangePerPage(value)}
      />
    </>
  )
}

export default KategoriServis
