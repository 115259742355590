import React from 'react'

const SpesifikasiExTaxiItemCard = ({ title, data }) => {
  const fieldStyle = 'font-semibold text-gray-900 text-md'
  const valueStyle = 'font-normal text-gray-700 text-md'

  const onReturnField = (field) => {
    switch (field) {
      case 'shiftByWire':
        return 'Shift-By-Wire (SBW)'
      case 'motorDrivenPowerStering':
        return 'Motor Driven Power Stering (MDPS)'
      case 'tirePressureMonitoringSystem':
        return 'Tire Pressure Monitoring System (TPMS)'
      case 'antiLockBrakeSystem':
        return 'Anti-Lock Brake System (ABS)'
      case 'electronicStabillityControl':
        return 'Electronic Stabillity Control (ESC)'
      case 'hillAssistControl':
        return 'Hill Assist Control (HAC)'
      case 'laneKeepAssistSystem':
        return 'Lane Keep Assist System (LKA)'
      case 'laneFollowingAssist':
        return 'Lane Following Assist (LFA)'
      case 'blindSpotCollisionWarning':
        return 'Blind-Spot Collision Warning (BCW)'
      case 'blindSpotViewMonitor':
        return 'Blind-Spot View Monitor (BSM)'
      case 'safeExitAssist':
        return 'Safe Exit Assist (SEA)'
      case 'rearSeatOccupantAlert':
        return 'Rear Seat Occupant Alert (ROA)'
      case 'forwardCollisionAvoidanceAssist':
        return 'Forward Collision Avoidance Assist (FCA)'
      case 'drl':
        return 'DRL'
      case 'hmsl':
        return 'HMSL'
      case 'ims':
        return 'IMS'
      case 'epb':
        return 'EPB'
      case 'ac':
        return 'AC'
      case 'usb':
        return 'USB'
      case 'headUpDisplay':
        return 'Head-Up Display (HUD)'
      default:
        return `${field.substring(0, 1).toUpperCase()}${field
          .substring(1, field.length)
          .replace(/([A-Z])/g, ' $1')
          .trim()}`
    }
  }

  return (
    <div className="grid grid-cols-1 gap-3 mt-4">
      <span className="font-semibold text-gray-900 text-lg">{title}</span>
      {Object.entries(data).map((item, index) => (
        <div key={index} className="grid grid-cols-2 w-[50%]">
          <span className={fieldStyle}>{onReturnField(item[0])}</span>
          <span className={valueStyle}>{item[1]}</span>
        </div>
      ))}
    </div>
  )
}

export default SpesifikasiExTaxiItemCard
