import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory, useParams } from 'react-router-dom'
import { sparepartDataDummies } from 'assets/dummies'
import { Description, Header } from './components'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { Loading } from 'components'

const SparepartDetailPage = () => {
  const { idSparepart } = useParams()
  const [sparepart, setSparepart] = useState(
    sparepartDataDummies.find((item) => item.id === parseInt(idSparepart))
  )
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  // Get All List Inventory
  const { isLoading, isFetching } = useQuery({
    queryKey: ['inventory-list', idSparepart],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepart?.root}/${idSparepart}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setSparepart(res?.resData?.data ?? {})
    }
  })

  if (isLoading ?? isFetching) {
    return <Loading visible />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Sparepart
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Sparepart Inventory
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Sparepart
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <div className="flex flex-col w-full space-y-5">
          <Header
            sku={sparepart?.sku}
            name={sparepart?.name}
            price={sparepart?.price ?? 0}
            stock={sparepart?.stock}
          />
          <Description desc={sparepart?.description ?? 'Tidak ada deskripsi'} />
        </div>
      </Content>
    </Layout>
  )
}

export default SparepartDetailPage
