import React from 'react'
import { CardContainer } from 'components'
import { formatCurrency } from 'utils'
import 'moment/locale/en-gb'

const InformasiPenjualanCard = ({ nama, pembeli, tanggal, harga }) => {
  const fieldStyle = 'font-medium text-gray-900 text-lg'
  const valueStyle = 'font-normal text-gray-700 text-md'

  return (
    <CardContainer minHeight="20%">
      <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Informasi Penjualan</span>
      </div>
      <div className="grid grid-cols-4 gap-4 w-full pt-4">
        {nama && (
          <div className="flex flex-col">
            <span className={fieldStyle}>Nama</span>
            <span className={valueStyle}>{nama}</span>
          </div>
        )}
        <div className="flex flex-col">
          <span className={fieldStyle}>Pembeli</span>
          <span className={valueStyle}>{pembeli}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Tanggal</span>
          <span className={valueStyle}>{tanggal}</span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>Harga</span>
          <span className="font-medium text-[#F79009]">{formatCurrency(harga)}</span>
        </div>
      </div>
    </CardContainer>
  )
}

export default InformasiPenjualanCard
