import { Breadcrumb, Input, Layout, Select, Typography, Tooltip } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { IcEdit, IcEye, IcSearch, IcSync, IcTrash } from 'assets/icons'
import { CardContainer, HeaderContainer, ModalDelete, Table } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'store/actions'
import { formatCurrency, showErrorMessage, showSuccessMessage } from 'utils'
import { ModalCreateUpdateSparepart } from './components'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  listData: [],
  sparepart_category_id: '',
  keywords: ''
}

const SparepartInventoryPage = () => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [categories, setCategories] = useState([])
  const dispatch = useDispatch()
  const onLogout = () => dispatch(logout())

  const [modalCreateUpdate, setModalCreateUpdate] = useState({
    show: false,
    type: 'create',
    id: -1,
    name: '',
    sku: '',
    description: '',
    price: 0,
    stock: 0,
    sparepart_category_id: null
  })
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: -1,
    title: '',
    desc: ''
  })
  const [dropDownCategory, setDropDownCategory] = useState([])
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  const columns = [
    {
      name: 'SKU',
      selector: (row) => row?.sku,
      cell: (row) => <span className="font-medium text-gray-700">{row?.sku}</span>,
      grow: 1
    },
    {
      name: 'Nama Sparepart',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>,
      grow: 2
    },
    {
      name: 'Kategori',
      selector: (row) => row?.category?.name,
      cell: (row) => <span className="font-medium text-gray-700">{row?.category?.name}</span>,
      grow: 1
    },
    {
      name: 'Deskripsi',
      selector: (row) => row?.description,
      cell: (row) => (
        <Tooltip title={row?.description}>
          <span className="font-medium text-gray-700 line-clamp-2">{row?.description}</span>
        </Tooltip>
      ),
      grow: 2
      // cell: (row) => (
      //   <Typography.Text
      //     ellipsis={{
      //       tooltip: row?.description
      //     }}>
      //     {row?.description}
      //   </Typography.Text>
      // )
    },
    {
      name: 'Harga',
      selector: (row) => row.price,
      cell: (row) => (
        <span className="font-medium text-gray-700">{formatCurrency(row?.price ?? 0)}</span>
      ),
      grow: 1
    },
    {
      name: 'Stock',
      selector: (row) => row?.stock,
      cell: (row) => <span className="font-medium text-gray-700">{row?.stock}</span>,
      grow: 0
    },
    {
      name: 'Action',
      ignoreRowClick: true,
      sortable: false,
      left: true,
      width: '180px',
      cell: (row) => (
        <div className="flex flex-row items-center justify-center self-center space-x-2">
          <div
            onClick={() => router.push(`/sparepart/sparepart-inventory/detail/${row.id}`)}
            className="flex flex-row items-center justify-center self-center space-x-1 px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
            <IcEye className="text-gray-700" size={19} />
            <span className="font-medium text-gray-700">Lihat</span>
          </div>
          {/* <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md hover:bg-gray-100"
            onClick={() =>
              setModalCreateUpdate({
                show: true,
                type: 'update',
                id: row.id,
                name: row.name,
                sku: row.sku,
                description: row.description,
                price: row.price,
                stock: row.stock,
                sparepart_category_id: row.sparepart_category_id
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div> */}
          <div
            className="hover:cursor-pointer px-2 py-[6.5px] my-2 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setModalDelete({
                show: true,
                id: row.id,
                title: 'Hapus Sparepart',
                desc: `Apakah anda yakin ingin menghapus kategori [${row.name}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`
              })
            }>
            <IcTrash className="text-primary-500" size={19} />
          </div>
        </div>
      )
    }
  ]

  // Get All List Inventory
  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: [
      'inventory-list',
      tableState.offset,
      tableState.limit,
      tableState.sparepart_category_id,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepart?.root}?keywords=${tableState?.keywords}&sparepart_category_id=${tableState?.sparepart_category_id}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Category
  useQuery({
    queryKey: ['category-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepartCategories?.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDropDownCategory([])
      setCategories(res?.resData?.data ?? [])
      if (res.resStatus === 200) {
        if (res.resData.data.length !== '') {
          res.resData.data.map((item) => {
            setDropDownCategory((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const SyncOdooSparepartInventory = useQuery(
    {
      queryKey: 'SyncOdooInventory',
      refetchOnWindowFocus: false,
      queryFn: async () => {
        const res = await apiPost({
          url: 'servis-service/admin/odoo/sync-spareparts',
          token,
          onLogout: () => dispatch(logout())
        })
        return res
      },
      onSuccess: () => {
        refetch()
        showSuccessMessage({ content: 'Sinkronisasi Data Sparepart Inventory Berhasil' })
      },
      onError: () => {
        showErrorMessage({
          content: 'Sinkronisasi Data Sparepart Inventory Tidak Berhasil, Silahkan Coba Lagi'
        })
      },
      enabled: false
    },
    []
  )

  const syncOdooSparepartInventoryHandler = () => {
    SyncOdooSparepartInventory.refetch()
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  const onCreateUpdateSparepartInventory = (values) => {
    setModalCreateUpdate({ ...modalCreateUpdate, show: false })
    setIsLoadingHelper(true)
    const mount = setTimeout(async () => {
      let res
      if (modalCreateUpdate.type === 'create') {
        res = await apiPost({
          url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepart?.root}`,
          token,
          data: values.values,
          onLogout
        })
      }
      if (modalCreateUpdate.type === 'update') {
        res = await apiPut({
          url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepart?.root}/${modalCreateUpdate.id}`,
          token,
          data: values.values,
          onLogout
        })
      }
      if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
        if (modalCreateUpdate.type === 'create') {
          showSuccessMessage({ content: 'Berhasil membuat sparepart' })
        }
        if (modalCreateUpdate.type === 'update') {
          showSuccessMessage({ content: 'Berhasil mengupdate sparepart' })
        }
      } else {
        if (modalCreateUpdate.type === 'create') {
          showErrorMessage({
            content:
              res.resData.message ?? res.resData.errors[0].message ?? 'Gagal membuat sparepart'
          })
        }
        if (modalCreateUpdate.type === 'update') {
          showErrorMessage({
            content:
              res.resData.message ?? res.resData.errors[0].message ?? 'Gagal mengupdate sparepart'
          })
        }
      }
      refetch()
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onDeleteSparepartInventory = () => {
    setModalDelete({ ...modalDelete, show: false })
    setIsLoadingHelper(true)
    const mount = setTimeout(async () => {
      const res = await apiDelete({
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepart?.root}/${modalDelete.id}`,
        token,
        onLogout
      })
      if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
        showSuccessMessage({ content: 'Berhasil menghapus sparepart' })
      } else {
        showErrorMessage({
          content:
            res.resData.message ?? res.resData.errors[0].message ?? 'Gagal menghapus sparepart'
        })
      }
      refetch()
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Sparepart
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Sparepart Inventory
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {modalCreateUpdate.show ? (
          <ModalCreateUpdateSparepart
            state={modalCreateUpdate}
            categories={dropDownCategory}
            onClose={() => setModalCreateUpdate({ ...modalCreateUpdate, show: false })}
            onSubmit={(values) => onCreateUpdateSparepartInventory(values)}
          />
        ) : null}
        {modalDelete.show ? (
          <ModalDelete
            minHeight="min-h-[20%]"
            maxWidth="max-w-[25%]"
            title={modalDelete.title}
            desc={modalDelete.desc}
            onClose={() => setModalDelete({ ...modalDelete, show: false })}
            onSubmit={() => onDeleteSparepartInventory()}
          />
        ) : null}
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer
              title="Sparepart Inventory"
              titleAdd="Sync"
              iconRight={<IcSync className="text-white" size={17} />}
              isPressAdd
              onPressAdd={() => {
                syncOdooSparepartInventoryHandler()
              }}
              // isButtonChild
              // titleAddChild="Tambah Sparepart"
              // onPressAddChild={() =>
              //   setModalCreateUpdate({
              //     show: true,
              //     type: 'create',
              //     id: -1,
              //     name: '',
              //     sku: '',
              //     description: '',
              //     price: 0,
              //     stock: 0,
              //     sparepart_category_id: null
              //   })
              // }
              isLoadingSync={SyncOdooSparepartInventory?.isFetching}
            />
            <div className="mb-5 flex justify-between">
              <div className="flex">
                <Select
                  showSearch
                  allowClear
                  placeholder="Semua Kategori"
                  className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
                  filterOption={(input, option) => {
                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                  }}
                  style={{ minWidth: '200px' }}
                  value={
                    tableState?.sparepart_category_id === ''
                      ? null
                      : tableState?.sparepart_category_id
                  }
                  onChange={(e) =>
                    setTableState((prev) => ({
                      ...prev,
                      sparepart_category_id: e ?? '',
                      city_id: '',
                      offset: 0
                    }))
                  }>
                  {categories?.map((category, idx) => (
                    <Select.Option key={idx} value={category?.id}>
                      {category?.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Input
                className="w-1/4 rounded-md"
                placeholder="Pencarian"
                value={tableState?.keywords}
                prefix={<IcSearch className="text-gray-400" />}
                onChange={(e) =>
                  setTableState((prev) => ({
                    ...prev,
                    keywords: e?.target?.value,
                    offset: 0
                  }))
                }
              />
            </div>
            <Table
              columns={columns}
              data={tableState?.listData}
              pending={isLoading ?? isFetching ?? isLoadingHelper}
              totalRows={tableState?.totalRows}
              handlePerRowsChange={handlePerRowsChange}
              pageSize={tableState?.limit}
              page={tableState?.offset}
              onChangePrevPage={() => {
                if (tableState?.offset > 0) {
                  onChangePage(tableState?.offset - tableState?.limit)
                }
              }}
              onChangeNextPage={() => {
                if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                  onChangePage(tableState?.offset + tableState?.limit)
                }
              }}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default SparepartInventoryPage
