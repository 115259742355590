import React, { useEffect, useState } from 'react'
import { Input, Modal, Radio, Typography } from 'antd'
import { showErrorMessage } from 'utils'

const ModalSparepart = ({ isOpen, onCancel, data, onSubmit, onDelete, type }) => {
  const [forms, setForms] = useState({
    qty: data?.qty,
    uom: data?.uom ?? ''
  })

  useEffect(() => {
    setForms({ qty: data?.qty, uom: data?.uom })
  }, [data])

  if (type === 'edit') {
    return (
      <Modal
        title="Edit Sparepart"
        open={isOpen}
        onOk={() => {
          if (parseInt(forms?.qty) < 1) {
            showErrorMessage({ content: 'Data Qty tidak boleh 0' })
            return
          }
          if (forms?.uom === '') {
            showErrorMessage({ content: 'Data Satuan Wajib diisi' })
            return
          }

          onSubmit({ ...data, qty: forms?.qty, uom: forms?.uom })
        }}
        centered
        onCancel={() => {
          onCancel()
        }}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
        <div className="flex justify-between items-center">
          <div>{data?.name}</div>
          <div className="flex items-center">
            <div>Qty: </div>
            <Input
              className="rounded-md py-1 w-10 ml-1 mr-3"
              type="numeric"
              value={forms?.qty}
              onChange={(e) => setForms((prev) => ({ ...prev, qty: e.target.value }))}
            />
            <Radio.Group
              className="custom-radio-btn flex"
              name="radiogroup"
              value={forms?.uom}
              onChange={(e) => setForms((prev) => ({ ...prev, uom: e.target.value }))}>
              <Radio value={'Pcs'}>Pcs</Radio>
              <Radio value={'L'}>Liter</Radio>
            </Radio.Group>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      title="Hapus Sparepart"
      open={isOpen}
      onOk={onDelete}
      onCancel={onCancel}
      centered
      okText="Confirm"
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
      <Typography.Text className="font-medium">
        {`Apakah Anda yakin ingin menghapus sparepart ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
      </Typography.Text>
    </Modal>
  )
}

export default ModalSparepart
