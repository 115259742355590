import React from 'react'
import { BaseIcon, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'

const ModalDeleteUnit = ({ state, minHeight, maxWidth, minWidth, onClose, onSubmit }) => (
  <ModalContainer>
    <ModalForm minHeight={minHeight} maxWidth={maxWidth} minWidth={minWidth}>
      <div className="flex flex-col w-full space-y-4">
        <div className="flex flex-row items-center justify-between">
          <span className="font-semibold text-gray-900 text-xl">Konfirmasi Hapus Unit</span>
          <div
            className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
            onClick={() => onClose()}>
            <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-medium text-left text-md text-gray-500">
            Apakah Anda yakin ingin menghapus unit:
          </span>
          <div className="flex flex-col">
            <span className="font-semibold text-left text-md text-gray-700">{state.unit_name}</span>
            <span className="font-normal text-left text-sm text-gray-500">
              {state.unit_police_number}
            </span>
          </div>
        </div>
        <div className="flex flex-row w-full items-center justify-end space-x-4">
          <button
            type="button"
            className="flex flex-row mt-2 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
            onClick={() => onClose()}>
            Cancel
          </button>
          <button
            type="button"
            onClick={() => onSubmit()}
            className="flex flex-row mt-2 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
            Confirm
          </button>
        </div>
      </div>
    </ModalForm>
  </ModalContainer>
)

export default ModalDeleteUnit
