import React from 'react'
import { Modal, Typography } from 'antd'

const ModalDeleteUser = ({ user, isOpen, onCancel, title = '', onSubmit, isLoading = false }) => (
  <Modal
    centered
    title={`Hapus ${title}`}
    open={isOpen}
    onCancel={onCancel}
    onOk={onSubmit}
    okText="Confirm"
    okButtonProps={{ className: 'bg-primary-500', type: 'danger', loading: isLoading }}
    cancelButtonProps={{
      className: 'hover:text-[#000] hover:border-[#000]',
      type: 'default',
      disabled: isLoading
    }}>
    <Typography.Text className="font-medium">
      {`Apakah Anda yakin ingin menghapus ${title.toLowerCase()} ${
        user?.fullname
      } ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
    </Typography.Text>
  </Modal>
)

export default ModalDeleteUser
