import { Breadcrumb, Layout } from 'antd'
import { apiGet, urls } from 'api'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import UpdatePaketServisComponent from './components/UpdatePaketServisComponent'
import { Loading } from 'components'
import { logout } from 'store/actions'

const UpdatePaketServis = ({ type }) => {
  const router = useHistory()
  const { id } = useParams()
  const token = useSelector((state) => state.auth.token)
  const [dataDetail, setDataDetail] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const getDataDetail = useMutation({
    mutationKey: 'detail-paket-servis',
    mutationFn: async (idPaket) => {
      const res = await apiGet({
        token,
        url: `${urls.service.prefix}/${urls.service.serviceManagement.servicePackets.root}/${idPaket}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDataDetail(res?.resData?.data)
      setIsLoading(false)
    }
  })

  useEffect(() => {
    if (type === 'edit') {
      setIsLoading(true)
      getDataDetail.mutate(id)
    }
  }, [type, id])

  if (isLoading) {
    return <Loading visible />
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Servis</Breadcrumb.Item>
        <Breadcrumb.Item
          className="cursor-pointer"
          onClick={() => router.push({ pathname: '/services/management', hash: 'paket' })}>
          Managemen Servis
        </Breadcrumb.Item>
        <Breadcrumb.Item>{`${type === 'edit' ? 'Edit' : 'Tambah'} Paket Servis`}</Breadcrumb.Item>
      </Breadcrumb>
      <UpdatePaketServisComponent
        type={type}
        data={dataDetail}
        getDataDetail={getDataDetail}
        onLogout={onLogout}
      />
    </Layout>
  )
}

export default UpdatePaketServis
