import React, { useState } from 'react'
import { BaseIcon, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'
import { DatePicker, Select } from 'antd'
import moment from 'moment'
import { disabledDatePicker, disabledTimeDropdown } from 'utils'

const ModalTestDrive = ({
  minHeight,
  onClose,
  onSubmit,
  times,
  state,
  mechanics,
  selectedData,
  setSelectedData,
  tabActive
}) => {
  const [price, setPrice] = useState(state.type === 'edit' ? state.price : '')

  const handleSelectMechanic = (val) => {
    const data = {
      value: val.value,
      label: val.children
    }
    setSelectedData((prev) => ({
      ...prev,
      mechanic: data
    }))
  }

  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`Konfirmasi ${state.type === 'test-drive' ? 'Test Drive' : 'Mekanik'}`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <Formik
            initialValues={{
              unit: state?.data?.unit_name || '',
              pemilik: state?.data?.unit_owner_fullname || '',
              tester: state?.data?.tester_fullname || '',
              testerPhoneNumber: state?.data?.tester_phone_number || '',
              date: state?.data?.test_drive_date,
              time: state?.data?.test_drive_schedule?.time,
              mekanik: ''
            }}
            onSubmit={(values, action) => onSubmit(values, price)}>
            <Form key="createUpdateHargaJualForm">
              <div className="flex flex-col w-full space-y-5">
                {state.type === 'test-drive' ? (
                  <>
                    <FormInput label="Unit" name="unit" value isDisabled />
                    {tabActive === 'Mobil Bekas' ? (
                      <FormInput label="Pemilik" name="pemilik" isDisabled />
                    ) : null}
                    <FormInput label="Tester" name="tester" isDisabled />
                    <FormInput label="Nomor Telepon" name="testerPhoneNumber" isDisabled />
                    <div className="grid grid-cols-2 gap-3">
                      <div className="">
                        <label className="font-medium block mb-2 text-md text-gray-700">
                          Tanggal
                          <span className="text-red-700">*</span>
                        </label>
                        <DatePicker
                          className={`w-full font-normal text-left py-1.5 text-gray-700  placeholder-gray-500 bg-white border border-solid border-gray-300 rounded-md focus:border-primary-500 focus:ring-primary-500 focus:outline-none focus:ring focus:ring-opacity-20`}
                          format="DD/MM/YYYY"
                          onChange={(val) =>
                            setSelectedData((prev) => ({
                              ...prev,
                              date: moment(val)
                            }))
                          }
                          defaultValue={moment(selectedData.date)}
                          disabledDate={disabledDatePicker}
                        />
                      </div>
                      <div>
                        <label className="font-medium block mb-2 text-md text-gray-700">
                          Waktu
                          <span className="text-red-700">*</span>
                        </label>
                        <Select
                          className="custom-table-limit"
                          style={{ width: '100%' }}
                          value={selectedData.time_id}
                          onChange={(val, option) => {
                            setSelectedData((prev) => ({
                              ...prev,
                              time_id: val,
                              time: option.children
                            }))
                          }}>
                          <Select.Option className="custom-table-limit-option" value="">
                            Semua Waktu
                          </Select.Option>
                          {times.map((el, idx) => (
                            <Select.Option
                              key={idx}
                              value={el.value}
                              disabled={disabledTimeDropdown(
                                el.label,
                                moment(selectedData.date).format('DD/MM/YYYY')
                              )}>
                              {el.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <label className="font-medium block mb-2 text-md text-gray-700">Mekanik</label>
                    <Select
                      onChange={(_, option) => handleSelectMechanic(option)}
                      value={selectedData?.mechanic?.value ? selectedData?.mechanic?.value : null}
                      placeholder="Pilih Mekanik"
                      className="custom-table-limit w-full">
                      {mechanics.map((el, idx) => (
                        <Select.Option key={idx} value={el.value}>
                          {el.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                )}
                <div className="flex flex-row w-full items-center justify-end space-x-4">
                  <button
                    type="button"
                    className="flex flex-row hover:cursor-pointer mt-2 items-center justify-around rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                    onClick={() => onClose()}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex flex-row hover:cursor-pointer mt-2 items-center justify-around rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                    Confirm
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalTestDrive
