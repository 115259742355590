import React, { useMemo, useState } from 'react'
import { Input, Modal, Radio, Select, Typography } from 'antd'
import { IcCheck, IcSearch } from 'assets/icons'
import { useQuery } from 'react-query'
import { logout } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet } from 'api'
import { formatCurrency, showErrorMessage } from 'utils'

const ModalSparepartUnit = ({ isOpen, onCancel, onSubmit, data }) => {
  const [selectedData, setSelectedData] = useState([])
  const [category, setCategory] = useState()
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [listCategory, setListCategory] = useState([])
  const [listSparepart, setListSparepart] = useState([])

  const categoryData = useQuery({
    queryKey: ['category-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: 'servis-service/admin/sparepart-categories/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      // setSelectedData(data ?? [])
      setSearch('')
      setListCategory(res?.resData?.data ?? [])
    },
    enabled: isOpen
  })

  useQuery({
    queryKey: ['sparepart-type-list', category, search],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let params = {
        sparepart_category_id: category,
        keywords: search
      }
      const res = await apiGet({
        token,
        url: 'servis-service/admin/spareparts/all',
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      // setSelectedData(data ?? [])
      setListSparepart(res?.resData?.data ?? [])
    },
    enabled: isOpen && !categoryData.isLoading
  })

  const filterData = useMemo(() => {
    return listSparepart
      ?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
      ?.filter((fil) => data?.some((som) => som?.sparepart_id === fil?.id) === false)
  }, [search, listSparepart, data])

  const onClickitem = (item) => {
    let newArray = [...selectedData]
    let index = newArray.findIndex((unit) => unit?.sparepart_id === item.id)
    if (index >= 0) {
      newArray.splice(index, 1)
    } else {
      newArray.push({
        name: item.name,
        sparepart_id: item.id,
        qty: 0,
        uom: null
      })
    }
    setSelectedData(newArray)
  }

  const onEditSelected = (key, idx, value) => {
    let newArray = [...selectedData]
    if (idx >= 0) {
      newArray[idx] = {
        ...newArray[idx],
        [key]: value
      }
    }
    setSelectedData(newArray)
  }

  return (
    <Modal
      width={400}
      title={'Pilih Sparepart'}
      open={isOpen}
      onCancel={() => {
        onCancel()
        setSelectedData([])
        setCategory(null)
      }}
      okText={'Confirm'}
      onOk={() => {
        let checkQty = true
        let checkUom = true
        selectedData.map((data) => {
          checkQty = checkQty && data?.qty > 0
          checkUom = checkUom && !!data?.uom
          checkUom = checkUom && data?.uom !== ''
        })
        if (!checkQty) {
          showErrorMessage({ content: 'Data Qty tidak boleh 0' })
          return
        }
        if (!checkUom) {
          showErrorMessage({ content: 'Data Satuan Wajib diisi' })
          return
        }
        setSelectedData([])
        onSubmit(selectedData)
        setCategory(null)
      }}
      okButtonProps={{ className: 'bg-primary-500 rounded-md', type: 'danger' }}
      cancelButtonProps={{
        className: 'hover:text-[#000] rounded-md hover:border-[#000]',
        type: 'default'
      }}>
      <div className="bg-gray-50 p-2 rounded-lg">
        <Select
          showSearch
          className="custom-table-limit"
          placeholder="Pilih Kategori"
          style={{ width: '100%' }}
          value={category}
          // onSearch={e => setSearchCategory(e)}
          onChange={(value) => {
            setCategory(value)
          }}
          filterOption={(input, option) =>
            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {listCategory?.map((item) => (
            <Select.Option key={item?.id?.toString()} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
        <Input
          className="rounded-md py-1.5 mt-2"
          placeholder="Cari Sparepart"
          prefix={<IcSearch className="text-gray-400" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div>
          <div className="bg-gray-50 mt-2 p-2 flex items-center rounded-t-lg border-solid border-gray-200 hover:cursor-pointer">
            <div className="flex-1">
              <Typography.Text className="mx-2 font-semibold">Sparepart</Typography.Text>
            </div>
            <Typography.Text className="mx-4 font-semibold">Harga</Typography.Text>
          </div>
        </div>
        <div className="overflow-y-auto h-40 border-solid border-gray-200">
          {filterData?.map((item) => (
            <div
              key={item.id}
              className="bg-white p-2 flex items-center rounded-lg mb-1 border-solid border-white hover:cursor-pointer"
              onClick={() => onClickitem(item)}>
              {/* <span className='bg-primary-50 rounded-full flex w-fit items-center justify-center p-2'>
                            </span> */}
              {selectedData.some((e) => e.sparepart_id === item.id) ? (
                <div className="w-4 h-4 rounded-lg bg-primary-900 flex items-center justify-center">
                  <IcCheck className="text-white" size={13} />
                </div>
              ) : (
                <div className="w-4 h-4 rounded-md bg-whites border-solid border-gray-200" />
              )}
              <div className="flex-1">
                <Typography.Text className="mx-2 block text-gray-400">{item.sku}</Typography.Text>
                <Typography.Text className="mx-2 block">{item.name}</Typography.Text>
              </div>
              <Typography.Text className="mr-2 ml-4 font-semibold">
                {formatCurrency(item?.price ?? '0')}
              </Typography.Text>
            </div>
          ))}
        </div>
      </div>

      <div>
        {selectedData?.map((data, idx) => (
          <div className="flex justify-between items-center py-2" key={idx.toString()}>
            <div>{data.name}</div>
            <div className="flex items-center">
              <div>Qty: </div>
              <Input
                className="rounded-md py-1 w-10 ml-1 mr-3"
                type="numeric"
                value={data?.qty}
                onChange={(e) => onEditSelected('qty', idx, e.target.value)}
              />
              <Radio.Group
                className="custom-radio-btn"
                name="radiogroup"
                value={data?.uom}
                onChange={(e) => onEditSelected('uom', idx, e.target.value)}>
                <Radio className="block" value={'Pcs'}>
                  Pcs
                </Radio>
                <Radio value={'L'}>Liter</Radio>
              </Radio.Group>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default ModalSparepartUnit
