import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'

import { IcPlus, IcSearch } from 'assets/icons'
import { Button, Table } from 'components'

const AdminPageComponent = ({
  columns,
  data,
  tableState,
  onAdd,
  setTableState,
  isLoading,
  roles
}) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value,
      offset: 0
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">Admin List</Typography.Text>
          <Button
            className="flex flex-row items-center justify-around rounded-md space-x-1 px-2 py text-md text-white bg-primary-500 border-primary-500"
            onClick={onAdd}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Admin</span>
          </Button>
        </div>
        <div className="mb-5 flex justify-between">
          <Select
            className="custom-table-limit"
            style={{ width: '150px' }}
            showSearch
            allowClear
            filterOption={(input, option) => {
              return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }}
            value={tableState?.role_id}
            onChange={(e) => setTableState((prev) => ({ ...prev, role_id: e ?? '', offset: 0 }))}>
            <Select.Option className="custom-table-limit-option" value="">
              Semua Role
            </Select.Option>
            {roles?.map?.((role, idx) => (
              <Select.Option key={idx} value={role?.id}>
                {role?.name}
              </Select.Option>
            ))}
          </Select>
          <Input
            className="w-1/4 rounded-md py-1.5"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

AdminPageComponent.propTypes = {
  columns: PropTypes.array,
  tableState: PropTypes.object,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  setTableState: PropTypes.func,
  isLoading: PropTypes.bool
}

AdminPageComponent.defaultProps = {
  columns: PropTypes.array,
  tableState: {},
  data: [],
  onAdd: () => {},
  setTableState: () => {},
  isLoading: false
}

export default AdminPageComponent
