import imageCompression from 'browser-image-compression'

export const imageCompressor = (file) => (
    imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 2160,
        useWebWorker: true,
        alwaysKeepResolution: true,
        initialQuality: 1,
        fileType: 'image/webp'
       }).then((res) => ({status: 200, res})).catch(() => ({status: 400, res: undefined}))
)