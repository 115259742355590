import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from 'components'
import { logout } from 'store/actions'
import { showErrorMessage } from 'utils'
import {
  BookedContent,
  BookedExTaxiContent,
  BookingLunasContent,
  BookingLunasExTaxiContent,
  InKatalogContent
} from './components'

const KatalogDetailPage = () => {
  const { id, type, status } = useParams()
  const router = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [catalog, setCatalog] = useState()

  const { isLoading } = useQuery({
    queryKey: ['jual-unit-catalogs-detail'],
    refetchOnWindowFocus: true,
    queryFn: async () =>
      await apiGet({
        url:
          type === 'Mobil Bekas'
            ? `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}/${id}`
            : `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.root}/${id}`,
        token: user.token,
        onLogout: () => dispatch(logout())
      }),
    onSuccess: (data) => {
      setCatalog(data.resData.data)
    },
    onError: () => {
      router.goBack()
      setCatalog([])
      showErrorMessage({ content: 'Oopps, something went wrong.' })
    }
  })

  const { isLoading: isLoadingWorkshop } = useQuery({
    queryKey: ['workshop'],
    refetchOnWindowFocus: true,
    enabled: !!(
      catalog &&
      type === 'Mobil Bekas' &&
      (status === 'In Katalog' || status === 'Booking Lunas')
    ),
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.workshops.root}/${catalog.branch_id}`,
        token: user.token,
        onLogout: () => dispatch(logout())
      }),
    onSuccess: (data) => {
      setCatalog({
        ...catalog,
        workshop_address: `${data.resData.data.city_name}, ${data.resData.data.province_name}`
      })
    }
  })

  const onReturnContent = () => {
    switch (status.toLowerCase()) {
      case 'booked':
        if (type === 'Mobil Bekas') {
          return (
            <BookedContent
              data={catalog}
              onPressDetailInspeksi={() =>
                router.push(`/jual-unit/katalog/${catalog?.inspection_result?.id}/detail-inspeksi`)
              }
            />
          )
        } else {
          return <BookedExTaxiContent data={catalog} />
        }
      case 'booking lunas':
        if (type === 'Mobil Bekas') {
          return (
            <BookingLunasContent
              data={catalog}
              onPressDetailInspeksi={() =>
                router.push(`/jual-unit/katalog/${catalog?.inspection_result?.id}/detail-inspeksi`)
              }
            />
          )
        } else {
          return <BookingLunasExTaxiContent data={catalog} />
        }
      case 'in katalog':
        if (type === 'Mobil Bekas') {
          return (
            <InKatalogContent
              data={catalog}
              onPressButtonService={() =>
                router.push(`/internal-unit/${catalog.id}/servis-mobil`, { step: 1 })
              }
              onPressButtonInspeksi={() =>
                router.push(`/internal-unit/${catalog.id}/inspeksi`, {
                  id: catalog.id,
                  step: 1
                })
              }
              onPressDetailInspeksi={() =>
                router.push(`/jual-unit/katalog/${catalog?.inspection_result?.id}/detail-inspeksi`)
              }
            />
          )
        } else {
          return null
        }
      default:
        return null
    }
  }

  if (isLoading ?? isLoadingWorkshop) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Katalog
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          {catalog ? (type === 'Mobil Bekas' ? catalog.unit_name : catalog.taxi.name) : '-'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>{onReturnContent()}</Content>
    </Layout>
  )
}

export default KatalogDetailPage
