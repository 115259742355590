import { Select } from 'antd'
import React from 'react'

function FormSelect({ data, value, onChange }) {
  const { label, ...selectProps } = data
  return (
    <div className="grid grid-cols-1 gap-1">
      <p className="text-sm-medium text-gray-700">{label}</p>
      <Select {...selectProps} value={value} onChange={(val) => onChange(data.name, val)} />
    </div>
  )
}

export default FormSelect
