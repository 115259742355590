import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Button } from 'components'
import { IcEye, IcTrash } from 'assets/icons'
import VoucherComponent from './components/VoucherComponent'
import { useQuery } from 'react-query'
import ModalUpdateVoucher from './components/ModalUpdateVoucher'
import { formatDate } from 'helpers'
import { useHistory } from 'react-router-dom'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'
import _ from 'lodash'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: null,
  sort_order: null
}

const VoucherPage = () => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [tableState, setTableState] = useState(initialTableState)
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: null
  })

  const columnFontStyle = 'font-medium text-gray-700 py-2'

  const columns = [
    {
      name: 'Judul',
      selector: (row) => row?.name,
      cell: (row) => <span className={columnFontStyle}>{row?.name}</span>,
      grow: 1
    },
    {
      name: 'Kode',
      selector: (row) => row?.voucher_code,
      cell: (row) => <span className={columnFontStyle}>{_.toUpper(row?.voucher_code)}</span>,
      grow: 1
    },
    {
      name: 'Layanan',
      selector: (row) => row?.voucher_type,
      cell: (row) => <span className={columnFontStyle}>{row?.voucher_type}</span>,
      grow: 1
    },
    {
      name: 'Potongan',
      selector: (row) => row?.discount,
      cell: (row) => <span className={columnFontStyle}>{row?.discount}</span>,
      grow: 1
    },
    {
      name: 'Min Transaksi',
      selector: (row) => row?.min_transaction,
      cell: (row) => <span className={columnFontStyle}>{row?.min_transaction}</span>,
      grow: 1
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
      cell: (row) => <span className={columnFontStyle}>{row?.status}</span>,
      grow: 1
    },
    {
      name: 'Kuota',
      selector: (row) => row?.voucher_quota,
      cell: (row) => <span className={columnFontStyle}>{row?.voucher_quota}</span>,
      grow: 1
    },
    {
      name: 'Expired Date',
      selector: (row) => row?.expiry_date,
      cell: (row) => <span className={columnFontStyle}>{row?.expiry_date}</span>,
      grow: 1
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => router.push(`/master/voucher/${row?.id}`, { id: row?.id })}>
            <IcEye className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ show: true, type: 'delete', data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'voucher-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        token,
        url: `master-service/admin/vouchers`,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      console.log('res table : ', res.resData.meta.total)
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const saveData = async (data) => {
    try {
      setTableState((prevState) => ({
        ...prevState,
        loading: true
      }))
      let res
      let body = {
        ...data
      }

      body = {
        ...body,
        expiry_date: formatDate(data?.expiry_date),
        user_quota: parseInt(data?.user_quota),
        voucher_quota: parseInt(data?.voucher_quota),
        discount: parseInt(data?.discount),
        min_transaction: parseInt(data?.min_transaction),
        max_discount: parseInt(data?.max_discount)
      }

      if (data?.max_discount) {
        body = {
          ...body,
          max_discount: parseInt(data?.max_discount)
        }
      }

      if (data?.id) {
        const id = data?.id
        res = await apiPut({
          token,
          url: `master-service/admin/vouchers/${id}`,
          data: body,
          onLogout: () => dispatch(logout())
        })
      } else {
        res = await apiPost({
          token,
          url: `master-service/admin/vouchers`,
          data: body,
          onLogout: () => dispatch(logout())
        })
      }
      if (res?.resStatus === 200) {
        setModal({
          ...modal,
          show: false
        })
        if (data?.id) {
          showSuccessMessage({ content: 'Berhasil Mengubah Voucer' })
        } else {
          showSuccessMessage({ content: 'Berhasil Membuat Voucer' })
        }
        refetch()
      } else {
        showErrorMessage({
          content:
            res.resData.message ??
            res.resData.errors[0].message ??
            (data?.id ? 'Gagal untuk Mengubah Voucher' : 'Gagal untuk Membuat Voucher')
        })
      }
    } catch (error) {
      console.log(error)
      setModal({
        ...modal,
        show: false
      })
      showErrorMessage({
        content: 'Terjadi Kesalahan'
      })
    }
  }

  const deleteData = async (data) => {
    try {
      setTableState((prevState) => ({
        ...prevState,
        loading: true
      }))
      let res
      res = await apiDelete({
        token,
        url: `master-service/admin/vouchers/${data?.id}`,
        onLogout: () => dispatch(logout())
      })
      setModal({
        ...modal,
        show: false
      })
      if (res?.resStatus === 200) {
        showSuccessMessage({ content: 'Berhasil Menghapus Voucher' })
      } else {
        showErrorMessage({
          content:
            res.resData.message ?? res.resData.errors[0].message ?? 'Gagal untuk Menghapus Voucher'
        })
      }
      refetch()
    } catch (error) {
      console.log(error)
      setModal({
        ...modal,
        show: false
      })
      showErrorMessage({
        content: 'Terjadi Kesalahan'
      })
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Master Data</Breadcrumb.Item>
        <Breadcrumb.Item>Voucher</Breadcrumb.Item>
      </Breadcrumb>
      <ModalUpdateVoucher
        isOpen={modal.show}
        type={modal.type}
        data={modal.data}
        onSubmit={saveData}
        onDelete={deleteData}
        onCancel={() => setModal({ ...modal, show: false })}
      />
      <VoucherComponent
        isLoading={isLoading}
        columns={columns}
        data={tableState?.listData}
        onAdd={() => setModal({ show: true, type: 'add', data: null })}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}
export default VoucherPage
