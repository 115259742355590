import React, { useState } from 'react'
import {
  BaseIcon,
  FormDropDown,
  FormInput,
  FormInputRupiah,
  ModalContainer,
  ModalForm
} from 'components'
import { mdiClose } from '@mdi/js'
import { Formik } from 'formik'

const ModalCreateUpdateSparepart = ({ onClose, onSubmit, state, categories }) => {
  const [form, setForm] = useState({
    sku: {
      value: state.type === 'create' ? '' : state.sku,
      isEmpty: false,
      message: 'Silahkan Masukkkan SKU'
    },
    name: {
      value: state.type === 'create' ? '' : state.name,
      isEmpty: false,
      message: 'Silahkan Masukkkan Nama Sparepart'
    },
    sparepart_category_id: {
      value: state.type === 'create' ? null : state.sparepart_category_id,
      isEmpty: false,
      message: 'Silahkan Pilih Kategori'
    },
    description: {
      value: state.type === 'create' ? '' : state.description,
      isEmpty: false,
      message: 'Silahkan Masukkkan Deskripsi'
    },
    price: {
      value: state.type === 'create' ? 0 : state.price,
      isEmpty: false,
      message: 'Silahkan Masukkkan Harga'
    },
    stock: {
      value: state.type === 'create' ? 0 : state.stock,
      isEmpty: false,
      message: 'Silahkan Masukkkan Stock'
    }
  })

  const onChangeForm = (field, value) => {
    setForm({
      ...form,
      [field]: {
        ...form[field],
        value,
        isEmpty:
          field === 'stock' || field === 'price'
            ? value === 0
              ? false
              : !value || value < 0
              ? true
              : false
            : value && value !== ''
            ? false
            : true
      }
    })
  }

  const onCheckStockPrice = () => {
    if (
      form.stock.value !== '' &&
      form.price.value !== '' &&
      form.stock.value !== undefined &&
      form.price.value !== undefined &&
      form.stock.value > -1 &&
      form.price.value > -1
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <ModalContainer>
      <ModalForm
        minHeight="min-h-[60%]"
        maxHeight="max-h-[95%]"
        minWidth="min-w-[28%]"
        maxWidth="max-w-[30%]">
        <div className="flex-1 h-full flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.type === 'create' ? 'Tambah' : 'Edit'} Sparepart`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div className="flex flex-col w-full h-full space-y-6">
            <Formik
              initialValues={{
                sku: form.sku.value
              }}>
              <FormInput
                label="SKU"
                name="sku"
                placeholder="Masukkan SKU"
                isRequired
                isEmpty={form.sku.isEmpty}
                errorMessage={form.sku.message}
                onValidate={(value) => onChangeForm('sku', value)}
              />
            </Formik>
            <Formik
              initialValues={{
                name: form.name.value
              }}>
              <FormInput
                label="Nama Sparepart"
                name="name"
                placeholder="Masukkan Nama Sparepart"
                isRequired
                isEmpty={form.name.isEmpty}
                errorMessage={form.name.message}
                onValidate={(value) => onChangeForm('name', value)}
              />
            </Formik>
            <Formik
              enableReinitialize
              initialValues={{
                category: form.sparepart_category_id.value
              }}>
              <FormDropDown
                isSearch
                allowClear
                placeholder="Pilih Kategori"
                label="Kategori"
                name="category"
                isRequired
                isEmpty={form.sparepart_category_id.isEmpty}
                errorMessage={form.sparepart_category_id.message}
                data={categories}
                onChange={(value) =>
                  onChangeForm('sparepart_category_id', value ? parseInt(value) : null)
                }
              />
            </Formik>
            <Formik
              initialValues={{
                description: form.description.value
              }}>
              <FormInput
                component="textarea"
                height="100px"
                label="Deskripsi"
                name="description"
                value={form.description.value}
                placeholder="Masukkan Deskripsi"
                isRequired
                isEmpty={form.description.isEmpty}
                errorMessage={form.description.message}
                onValidate={(value) => onChangeForm('description', value)}
              />
            </Formik>
            <Formik
              initialValues={{
                price: form.price.value
              }}>
              <FormInputRupiah
                name="price"
                value={form.price.value}
                mode="normal"
                placeholder=""
                isWithLabelRp
                label="Harga"
                isRequired
                isEmptyField={form.price.isEmpty}
                message={form.price.message}
                onChange={(value) => onChangeForm('price', value)}
              />
            </Formik>
            <Formik
              initialValues={{
                stock: form.stock.value
              }}>
              <FormInput
                label="Stock"
                name="stock"
                type="number"
                min={0}
                isRequired
                isEmpty={form.stock.isEmpty}
                errorMessage={form.stock.message}
                onValidate={(value) => onChangeForm('stock', value)}
              />
            </Formik>
            <div className="flex flex-row w-full items-center justify-end space-x-4">
              <button
                type="button"
                className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  setForm({
                    sku: {
                      ...form.sku,
                      isEmpty: !form.sku.value || form.sku.value === '' ? true : false
                    },
                    name: {
                      ...form.name,
                      isEmpty: !form.name.value || form.name.value === '' ? true : false
                    },
                    sparepart_category_id: {
                      ...form.sparepart_category_id,
                      isEmpty: !form.sparepart_category_id.value ? true : false
                    },
                    description: {
                      ...form.description,
                      isEmpty:
                        !form.description.value || form.description.value === '' ? true : false
                    },
                    price: {
                      ...form.price,
                      isEmpty:
                        form.price.value === 0
                          ? false
                          : !form.price.value || form.price.value < 0
                          ? true
                          : false
                    },
                    stock: {
                      ...form.stock,
                      isEmpty:
                        form.stock.value === 0
                          ? false
                          : !form.stock.value || form.stock.value < 0
                          ? true
                          : false
                    }
                  })
                  if (
                    Object.entries(form)
                      .filter((itemFr) => itemFr[0] !== 'stock' && itemFr[0] !== 'price')
                      .map((itemPr) => itemPr[1].value)
                      .every((itemCh) => itemCh && itemCh !== '') &&
                    onCheckStockPrice()
                  ) {
                    onSubmit({
                      values: {
                        name: form.name.value,
                        sku: form.sku.value,
                        description: form.description.value,
                        price: form.price.value,
                        stock: form.stock.value,
                        sparepart_category_id: parseInt(form.sparepart_category_id.value)
                      }
                    })
                  }
                }}
                className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                {`${state.type === 'create' ? 'Tambah Sparepart' : 'Simpan'}`}
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateSparepart
