export const statusLayanan = (status) => {
  switch (status) {
    case 'SERVICE_IN_PROGRESS':
    case 'PICKUP_IN_PROGRESS':
    case 'Sedang Berlangsung':
    case 'ongoing':
      return 'Sedang berlangsung'

    case 'PICKUP_CONFIRMED':
    case 'SERVICE_CONFIRMED':
    case 'WAITING_APPROVAL':
    case 'scheduled':
      return 'Terjadwal'

    case 'SERVICE_FINISHED':
    case 'selesai':
    case 'Selesai':
    case 'done':
    case 'DONE':
      return 'Selesai'

    case 'UNCONFIRMED':
    case 'not_confirmed':
      return 'Belum dikonfirmasi'

    case 'REJECTED_UNCONFIRMED':
      return 'Ditolak'

    case 'CANCELED':
      return 'Dibatalkan'

    case 'sold':
      return 'Terjual'

    default:
      return status
  }
}
