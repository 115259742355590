import { Card, Typography } from 'antd'
import { Table } from 'components'
import { formatCurrency, formatThousandsINTL } from 'utils'

const InspeksiRecommendationServiceCard = ({ data }) => {
  const columns = [
    {
      name: 'Sparepart perlu perbaikan',
      width: '30%',
      selector: (row) => row?.name,
      sortable: true
    },
    {
      name: 'Perbaikan',
      selector: (row) => row?.repairment,
      sortable: true
    },
    {
      name: 'Harga Sparepart',
      selector: (row) => `${formatCurrency(row?.spare_part_price ?? '0')}`,
      sortable: true
    },
    {
      name: 'Biaya Jasa',
      selector: (row) => `${formatCurrency(row?.service_price ?? '0')}`,
      sortable: true
    },
    {
      name: 'Total',
      selector: (row) => `${formatCurrency(row?.total)}`,
      sortable: true
    }
  ]

  return (
    data?.serviceRecomendationList?.length > 0 && (
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-2xl">Rekomendasi Servis</Typography.Text>
        </div>
        <div className="text-xs-regular text-gray-600 grid grid-cols-1 gap-3 pb-4">
          <Table
            columns={columns}
            data={data?.serviceRecomendationList}
            useFooter={false}
            headStyles={{ fontSize: '14px' }}
          />
          <div className="flex justify-end content-center p-4 pt-0">
            <div className="flex gap-2 text-md-semibold">
              <span>Grand Total</span>
              <span>Rp {formatThousandsINTL(data?.total)}</span>
            </div>
          </div>
        </div>
      </Card>
    )
  )
}

export default InspeksiRecommendationServiceCard
