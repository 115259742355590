import React, { useMemo } from 'react'
import { CardContainer } from 'components'

const HeaderMapService = ({ namaBengkel, cabang, alamat, pin_google_map }) => {
  const map = useMemo(() => {
    if (pin_google_map === '' || pin_google_map === null) {
      return null
    } else {
      return `https://maps.google.com/maps?q=${pin_google_map?.split(',')?.[0]},${
        pin_google_map?.split(',')?.[1]
      }&hl=es;z=14&output=embed`
    }
  }, [pin_google_map])

  return (
    <CardContainer minHeight="10%">
      <div className="flex flex-row w-full h-44 items-center justify-between">
        <div className="flex flex-col min-w-[35%] max-w-[35%] space-y-1">
          <span className="font-semibold text-2xl text-gray-900">{namaBengkel}</span>
          <span className="font-medium text-xl text-gray-700">{cabang}</span>
          <span className="font-medium text-lg text-gray-500">{alamat}</span>
        </div>
        {map && (
          <div className="flex flex-col relative overflow-hidden h-full w-full ml-44 items-center justify-center">
            <iframe
              title="map-header-service"
              src={map}
              width="100%"
              height="210"
              frameBorder="0"
              style={{
                border: 0,
                borderRadius: 5,
                zIndex: 1,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%'
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              aria-hidden="false"
            />
          </div>
        )}
      </div>
    </CardContainer>
  )
}

export default HeaderMapService
