import { Breadcrumb, Layout } from 'antd'
import { dummyTransaksiCabang } from 'assets/dummies'
import {
  CatatanCard,
  InformasiUnitCard,
  InformasiUserCard,
  PaymentReceiptCard,
} from 'components'
import React from 'react'

const DetailTransaksiSales = () => {
  return (
    <Layout className="grid grid-cols-1 gap-6 p-8">
      {/* BREADCRUMB */}
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Transaksis
        </Breadcrumb.Item>
      </Breadcrumb>
      <InformasiUnitCard unit={'2019 Toyota Kijang Innova 2.4G'} status="Diinspeksi" harga={300000000} />
      <InformasiUserCard
        title={'Informasi Pemilik'}
        data={{
          nama: 'Jude Bellingham',
          email: 'jude.bellingham@gmail.com',
          telepon: '083944857222',
          alamat: 'Jln. Bunga Melati No. 15, Jakarta'
        }}
      />
      <InformasiUserCard
        title={'Informasi Pembeli'}
        data={{
          nama: 'Matty Healy',
          email: 'matty1975@gmail.com',
          telepon: '083944857222',
          alamat: 'Jln. Danau Ranau G7E/17, Sawojajar, Malang, Jawa Timur'
        }}
      />
      <CatatanCard
        desc={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc.'
        }
      />
      <PaymentReceiptCard data={dummyTransaksiCabang[2].paymentDetail} />
    </Layout>
  )
}

export default DetailTransaksiSales
