import { Card } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { dummyTransaksiCabang } from 'assets/dummies'
import { IcDownload } from 'assets/icons'
import {
  CatatanCard,
  CustomButton,
  InformasiServisCard,
  InspeksiCard,
  PaymentReceiptCard
} from 'components'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'

const JadwalInspeksiSOComponent = ({ data }) => {
  const { id } = useParams()
  const router = useHistory()
  const dummyData = dummyTransaksiCabang[0]

  return (
    <Content className="h-screen">
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between items-center">
          <span className="font-semibold text-xl text-gray-900">Sales Order</span>
          <CustomButton type="plain">
            <div
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => router.push(`/inspeksi/jadwal-inspeksi/${id}/sales-order-print`)}>
              <IcDownload className="stroke-gray-700" />
              <span className="text-sm-medium text-gray-700">Print SO</span>
            </div>
          </CustomButton>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
          <span className="font-semibold text-xl text-gray-900">Informasi Unit</span>
        </div>
        <div className="grid grid-cols-4 gap-4 w-full mt-4">
          <div className="flex flex-col h-full">
            <span className="font-semibold text-gray-900">Nama</span>
            <span className="font-medium text-gray-700">{data?.owner_name}</span>
          </div>
          <div className="flex flex-col h-full">
            <span className="font-semibold text-gray-900">Email</span>
            <span className="font-medium text-gray-700">{data?.owner_email}</span>
          </div>
          <div className="flex flex-col h-full">
            <span className="font-semibold text-gray-900">Nomor Telepon</span>
            <span className="font-medium text-gray-700">{data?.owner_phone_number}</span>
          </div>
          <div className="flex flex-col h-full">
            <span className="font-semibold text-gray-900">Alamat</span>
            <span className="font-medium text-gray-700">{data?.owner_address}</span>
          </div>
        </div>
      </Card>
      {/* <Card>
        <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
          <span className="font-semibold text-xl text-gray-900">Informasi nspeksi</span>
        </div>
        <div className="grid grid-cols-4 gap-4 w-full pt-4">
          <div className="flex flex-col">
            <span className='font-semibold text-gray-900'>Tempat</span>
            <span className='font-medium text-gray-700'>{data?.tempat}</span>
          </div>
          <div className="flex flex-col">
            <span className={fieldStyle}>Tanggal</span>
            <span className='font-medium text-gray-700'>{data?.tanggal}</span>
          </div>
          <div className="flex flex-col">
            <span className={fieldStyle}>Waktu</span>
            <span className={valueStyle}>{data?.waktu}</span>
          </div>
          <div className="flex flex-col">
            <span className={fieldStyle}>Stall</span>
            <span className='font-medium text-gray-700'>{data?.stall}</span>
          </div>
          <div className="flex flex-col">
            <span className={fieldStyle}>{tipe === 'Inspeksi' ? 'Inspektor' : 'Mekanik'}</span>
            <span className={valueStyle}>
              {tipe === 'Inspeksi' ? data?.inspektor : data?.mekanik}
            </span>
          </div>
          <div className="flex flex-col">
            <span className={fieldStyle}>Status</span>
            <BadgeItem type={'default'}>{data?.statusInspeksi}</BadgeItem>
          </div>
          {tipe === 'Inspeksi' && data.grade ? (
            <div className="flex flex-col">
              <span className={fieldStyle}>Grade</span>
              <span className={'text-md-medium text-blue-500'}>{data?.grade}</span>
            </div>
          ) : null}
        </div>
      </Card> */}
      <div className="grid grid-cols-1 gap-6">
        <InformasiServisCard
          tipe="Inspeksi"
          data={{
            tempat: data?.workshop_name,
            tanggal: moment(data?.inspection_date).format('DD/MM/YYYY'),
            tanggalTransaksi: moment(data?.created_at).format('DD/MM/YYYY'),
            waktu: `${data?.start_hour} - ${data?.end_hour}`,
            stall: data?.stall ? data?.stall : '-',
            inspektor: data?.inspektor_name,
            statusInspeksi: data?.status
          }}
        />
        <CatatanCard desc={data?.inspectionResult?.note} />
        <InspeksiCard
          onGoToDetailInspeksi={() => {}}
          data={{
            id: 51,
            grade: 'A',
            inspectionCategoryList: [
              { id: 1, name: 'Eksterior', count: '0/3' },
              { id: 2, name: 'Interior', count: '0/1' },
              { id: 3, name: 'Test Jalan', count: '3/5' },
              { id: 4, name: 'Bagian Kosong', count: '5/5' },
              { id: 5, name: 'Egines', count: '3/3' }
            ]
          }}
        />
        <PaymentReceiptCard data={dummyData.paymentDetail} />
      </div>
    </Content>
  )
}

export default JadwalInspeksiSOComponent
