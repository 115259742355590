export const unitDataDummies = [
  {
    id: 0,
    merek: 'Toyota',
    model: 'Kijang',
    tipe: 'Innova',
    platNomor: 'AB 2239 AAA',
    tahun: '2019',
    owner: 'Erling Haland',
    riwayatService: '22/09/2022',
    transmisi: 'Automatic',
    warna: 'Silver',
    bahanBakar: 'Bensin',
    noRangka: 'MMDJSDHFII009233312',
    noMesin: 'BBMM2231',
    tag: 'SUV, Mobil Keluarga'
  },
  {
    id: 1,
    merek: 'Toyota',
    model: 'Kijang',
    tipe: 'Innova',
    platNomor: 'AB 2239 AAA',
    tahun: '2019',
    owner: 'Erling Haland',
    riwayatService: '22/09/2022',
    transmisi: 'Automatic',
    warna: 'Silver',
    bahanBakar: 'Bensin',
    noRangka: 'MMDJSDHFII009233312',
    noMesin: 'BBMM2231',
    tag: 'SUV, Mobil Keluarga'
  },
  {
    id: 2,
    merek: 'Toyota',
    model: 'Kijang',
    tipe: 'Innova',
    platNomor: 'AB 2239 AAA',
    tahun: '2019',
    owner: 'Erling Haland',
    riwayatService: '22/09/2022',
    transmisi: 'Automatic',
    warna: 'Silver',
    bahanBakar: 'Bensin',
    noRangka: 'MMDJSDHFII009233312',
    noMesin: 'BBMM2231',
    tag: 'SUV, Mobil Keluarga'
  },
  {
    id: 3,
    merek: 'Toyota',
    model: 'Kijang',
    tipe: 'Innova',
    platNomor: 'AB 2239 AAA',
    tahun: '2019',
    owner: 'Erling Haland',
    riwayatService: '22/09/2022',
    transmisi: 'Automatic',
    warna: 'Silver',
    bahanBakar: 'Bensin',
    noRangka: 'MMDJSDHFII009233312',
    noMesin: 'BBMM2231',
    tag: 'SUV, Mobil Keluarga'
  },
  {
    id: 4,
    merek: 'Toyota',
    model: 'Kijang',
    tipe: 'Innova',
    platNomor: 'AB 2239 AAA',
    tahun: '2019',
    owner: 'Erling Haland',
    riwayatService: '22/09/2022',
    transmisi: 'Automatic',
    warna: 'Silver',
    bahanBakar: 'Bensin',
    noRangka: 'MMDJSDHFII009233312',
    noMesin: 'BBMM2231',
    tag: 'SUV, Mobil Keluarga'
  }
]
