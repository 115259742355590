import { Form, Input, Modal, Radio, Select, Typography } from 'antd'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { IcClose } from 'assets/icons'
import { Button, FormInputRupiah } from 'components'
import { Formik, useFormik } from 'formik'
import { errorsField, validationInput } from 'helpers'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import ModalSparepart from './ModalSparepart'
import { showErrorMessage } from 'utils'

const ModalServisUmum = ({ type, isOpen, onCancel, data, refetch, onLogout = () => {} }) => {
  const token = useSelector((state) => state.auth.token)
  const [selected, setSelected] = useState()
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [modal, setModal] = useState({
    show: false,
    data: {},
    type: 'default'
  })
  // const [image, setImage] = useState('')
  // const logoRef = useRef()

  const [listData, setListData] = useState({
    categories: [],
    durations: [],
    reminders: [],
    sparepartCategories: [],
    sparepart: [],
    sparepartAll: []
  })

  // Get categories
  useQuery({
    queryKey: ['categories-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceCategories?.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({ ...prev, categories: res?.resData?.data ?? [] }))
    }
  })

  // Get sparepart All
  useQuery({
    queryKey: ['sparepart-all-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepart?.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({ ...prev, sparepartAll: res?.resData?.data ?? [] }))
    }
  })

  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.name, { isRequired: true })?.valid) {
      errors.name = validationInput(values?.name, { isRequired: true })?.messageError
    }
    if (!validationInput(values?.service_category_id, { isRequired: true })?.valid) {
      errors.service_category_id = validationInput(values?.service_category_id, {
        isRequired: true
      })?.messageError
    }
    if (
      !values?.is_free &&
      !validationInput(values?.default_sparepart_id, { isRequired: true })?.valid
    ) {
      errors.default_sparepart_id = validationInput(values?.default_sparepart_id, {
        isRequired: true
      })?.messageError
    }
    if (
      !values?.is_free &&
      !validationInput(values?.default_sparepart_quantity, {
        isRequired: true,
        valueMinLength: 1,
        isNumeric: true
      })?.valid
    ) {
      errors.default_sparepart_quantity = validationInput(values?.default_sparepart_quantity, {
        isRequired: true,
        valueMinLength: 1,
        isNumeric: true
      })?.messageError
    }
    if (
      !values?.is_free &&
      !validationInput(values?.sparepart_ids, { isRequiredArray: true })?.valid
    ) {
      errors.sparepart_ids = validationInput(values?.sparepart_ids, {
        isRequiredArray: true
      })?.messageError
    }
    if (!values?.is_free && !validationInput(values?.reminder, { isRequired: true })?.valid) {
      errors.reminder = validationInput(values?.reminder, {
        isRequired: true
      })?.messageError
    }
    if (!values?.is_free && !validationInput(values?.duration, { isRequired: true })?.valid) {
      errors.duration = validationInput(values?.duration, {
        isRequired: true
      })?.messageError
    }
    if (!validationInput(values?.description, { isRequired: true })?.valid) {
      errors.description = validationInput(values?.description, {
        isRequired: true
      })?.messageError
    }
    if (!validationInput(values?.is_free, { isRequired: true })?.valid) {
      errors.is_free = validationInput(values?.is_free, {
        isRequired: true
      })?.messageError
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? '',
      service_category_id:
        listData?.categories?.find((f) => f?.id === data?.service_category_id)?.id ?? '',
      default_sparepart_id:
        data?.spareparts?.find((f) => f?.id === data?.default_sparepart?.id)?.id ?? '',
      default_sparepart_quantity: data?.default_sparepart_quantity ?? '',
      sparepart_ids: data?.spareparts?.length > 0 ? data?.spareparts?.map((item) => item?.id) : [],
      reminder: data?.reminder ?? '',
      duration: data?.duration ?? '',
      price: data?.price ?? 0,
      description: data?.description ?? '',
      is_free: data?.is_free ?? ''
      // image: ''
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      let body = {}
      if (values?.is_free) {
        body = {
          name: values?.name,
          service_category_id: parseInt(values?.service_category_id),
          description: values?.description,
          is_free: values?.is_free
        }
      } else {
        body = {
          ...values,
          service_category_id: parseInt(values?.service_category_id),
          default_sparepart_id: parseInt(values?.default_sparepart_id),
          default_sparepart_quantity: parseInt(values?.default_sparepart_quantity),
          reminder: parseInt(values?.reminder),
          duration: parseInt(values?.duration),
          price: parseInt(values?.price)
          // image: image
        }
      }
      handleSubmit(body)
    }
  })

  // Submit
  const handleSubmit = async (body) => {
    try {
      setIsLoadingCRUD(true)
      let res
      if (type === 'add') {
        res = await apiPost({
          url: `${urls.service.prefix}/${urls.service.serviceManagement.serviceGeneral.root}`,
          data: body,
          token: token,
          onLogout
        })
      } else {
        res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.serviceManagement.serviceGeneral.root}/${data?.id}`,
          data: body,
          token: token,
          onLogout
        })
      }

      if (res?.resStatus === 200) {
        onCancel()
        refetch()
        formik.handleReset()
        // setImage('')
      } else {
        formik.setErrors(errorsField(res?.resData?.errors))
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  // Delete
  const handleDeleteItem = async () => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiDelete({
        url: `${urls.service.prefix}/${urls.service.serviceManagement.serviceGeneral.root}/${data?.id}`,
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        onCancel()
        refetch()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteSparepart = (e, id) => {
    e.preventDefault()
    formik.setFieldValue(
      'sparepart_ids',
      values?.sparepart_ids?.filter((f) => f !== id)
    )

    if (values?.default_sparepart_id === id) {
      formik.setFieldValue('default_sparepart_id', '')
    }
  }

  const handleChangeIsFree = (e) => {
    formik.setFieldValue('is_free', e?.target?.value)

    // if is_free true
    if (e?.target?.value) {
      formik.setFieldValue('default_sparepart_id', '')
      formik.setFieldValue('default_sparepart_quantity', '')
      formik.setFieldValue('sparepart_ids', [])
      formik.setFieldValue('reminder', '')
      formik.setFieldValue('duration', '')
      formik.setFieldValue('price', 0)
    } else {
      if (type === 'edit') {
        formik.setFieldValue('default_sparepart_id', data?.default_sparepart?.id ?? '')
        formik.setFieldValue('default_sparepart_quantity', data?.default_sparepart_quantity ?? '')
        formik.setFieldValue(
          'sparepart_ids',
          data?.spareparts?.length > 0 ? data?.spareparts?.map((item) => item?.id) : []
        )
        formik.setFieldValue('reminder', data?.reminder ?? '')
        formik.setFieldValue('duration', data?.duration ?? '')
        formik.setFieldValue('price', data?.price ?? 0)
      }
    }
  }

  if (type === 'delete') {
    return (
      <Modal
        width={400}
        centered
        title="Hapus Servis Umum"
        open={isOpen}
        onCancel={onCancel}
        onOk={handleDeleteItem}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger', loading: isLoadingCRUD }}
        cancelButtonProps={{
          className: 'hover:text-[#000] hover:border-[#000]',
          type: 'default',
          disabled: isLoadingCRUD
        }}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus servis ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      width={400}
      centered
      title={type === 'add' ? 'Tambah Servis Umum' : 'Edit Servis Umum'}
      open={isOpen}
      onCancel={() => {
        onCancel()
        formik.handleReset()
        // setImage('')
      }}
      onOk={formik.handleSubmit}
      okText={'Confirm'}
      okButtonProps={{ className: 'bg-primary', type: 'danger', loading: isLoadingCRUD }}
      cancelButtonProps={{
        className: 'hover:text-[#000] hover:border-[#000]',
        type: 'default',
        disabled: isLoadingCRUD
      }}>
      <ModalSparepart
        isOpen={modal.show}
        data={data}
        type={modal.type}
        onCancel={() => setModal({ ...modal, show: false })}
        formik={formik}
        selected={selected}
        setSelected={setSelected}
        listData={listData}
        setListData={setListData}
        valueSparepart={values?.sparepart_ids}
        valueSparepartDefault={values?.default_sparepart_id}
      />

      <div className="mb-2">
        <Typography.Text className="font-medium">
          Jenis Servis Umum <span className="text-[red]"> *</span>
        </Typography.Text>
        <Radio.Group
          className="custom-radio-btn w-full"
          name="is_free"
          value={values?.is_free}
          onChange={handleChangeIsFree}>
          <Radio value={false}>Berbayar</Radio>
          <Radio value={true}>Gratis</Radio>
        </Radio.Group>
        {touched?.is_free && errors?.is_free && (
          <span className="text-[red]">{errors?.is_free}</span>
        )}
      </div>
      <div className="mb-2">
        <Typography.Text className="font-medium">
          Nama Servis Umum <span className="text-[red]"> *</span>
        </Typography.Text>
        <Input
          className="rounded py-3"
          placeholder="Masukan Nama Servis Umum"
          value={values?.name}
          name="name"
          onChange={formik.handleChange}
        />
        {touched?.name && errors?.name && <span className="text-[red]">{errors?.name}</span>}
      </div>
      {/* <div className="mb-2">
        <div className="flex items-center mb-2">
          <UploadImage
            customRef={logoRef}
            containerClassName={'mr-4'}
            onChangeFile={(file) => setImage(file)}
            file={image}
            oldFile={
              values?.image ? `${process.env.REACT_APP_IMAGE_URL_GCS}/${values?.image}` : null
            }
          />
          <div className="flex-1">
            <Button
              className={'bg-white py-1 mb-1 border-gray-500 text-gray-700 hover:bg-gray-50'}
              onClick={logoRef.current ? () => logoRef.current.click() : () => {}}>
              Upload Gambar Sparepart
            </Button>
            <div className="text-gray-500 text-sm">
              Harus setidaknya 400x500 px dan lebih kecil dari 1mb
            </div>
          </div>
        </div>
        {touched?.image && errors?.image && <span className="text-[red]">{errors?.image}</span>}
      </div> */}
      <div className="mb-2">
        <Typography.Text className="font-medium">
          Kategori <span className="text-[red]"> *</span>
        </Typography.Text>
        <Select
          className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector]:!h-auto"
          style={{ width: '100%' }}
          name="service_category_id"
          value={values?.service_category_id === '' ? null : values?.service_category_id}
          onChange={(e) => formik.setFieldValue('service_category_id', e)}
          placeholder="Pilih Kategori">
          {listData?.categories?.map((item, idx) => (
            <Select.Option key={idx} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
        {touched?.service_category_id && errors?.service_category_id && (
          <span className="text-[red]">{errors?.service_category_id}</span>
        )}
      </div>
      {!values?.is_free && (
        <>
          <div className="mb-2">
            <Typography.Text className="font-medium">
              Durasi Servis (menit) <span className="text-[red]"> *</span>
            </Typography.Text>
            <Input
              className="rounded py-3"
              placeholder="Masukan Durasi Servis (menit)"
              name="duration"
              value={values?.duration}
              onChange={formik.handleChange}
              type="number"
            />
            {touched?.duration && errors?.duration && (
              <span className="text-[red]">{errors?.duration}</span>
            )}
          </div>
          <div className="mb-2">
            <Typography.Text className="font-medium">
              Reminder (bulan) <span className="text-[red]"> *</span>
            </Typography.Text>
            <Input
              className="rounded py-3"
              placeholder="Masukan Reminder (bulan)"
              name="reminder"
              value={values?.reminder}
              onChange={formik.handleChange}
              type="number"
            />
            {touched?.reminder && errors?.reminder && (
              <span className="text-[red]">{errors?.reminder}</span>
            )}
          </div>
          <div className="mb-2">
            <Typography.Text className="font-medium">Harga Layanan Tambahan</Typography.Text>
            <Formik>
              <FormInputRupiah
                name="price"
                value={values?.price}
                mode="normal"
                isWithLabelRp
                message="Silahkan Masukkan Harga"
                onChange={(value) => {
                  formik.setFieldValue('price', value)
                }}
              />
            </Formik>
            {touched?.price && errors?.price && <span className="text-[red]">{errors?.price}</span>}
          </div>

          <div className="mb-2">
            <Typography.Text className="font-medium">
              Sparepart <span className="text-[red]"> *</span>
            </Typography.Text>
            <div className="mt-1">
              {values?.sparepart_ids?.length > 0 && (
                <div className="flex mb-3 flex-wrap mt-2 gap-[8px] items-center">
                  {values?.sparepart_ids?.map((item, index) => (
                    <div
                      key={`item-${index.toString()}`}
                      className="flex gap-[7px] items-center py-[2px] px-[10px] border-0 bg-[#F2F4F7] rounded-[16px] text-sm text-[#344054] selection:text-black">
                      {listData?.sparepartAll?.find((f) => f?.id === item)?.sku}
                      <IcClose
                        size={12}
                        className="text-gray-500 cursor-pointer"
                        onClick={(e) => handleDeleteSparepart(e, item)}
                      />
                    </div>
                  ))}
                </div>
              )}
              <Button
                className="border-gray-200 bg-white py-2 px-3"
                onClick={(e) => {
                  e.preventDefault()
                  setModal({ ...modal, show: true, type: 'sparepart' })
                  setSelected(values?.sparepart_ids ?? [])
                }}>
                Pilih Sparepart
              </Button>
            </div>
            {touched?.sparepart_ids && errors?.sparepart_ids && (
              <span className="text-[red]">{errors?.sparepart_ids}</span>
            )}
          </div>
          <div className="mb-2">
            <Typography.Text className="font-medium">
              Sparepart Default <span className="text-[red]"> *</span>
            </Typography.Text>
            <div className="mt-1">
              {values?.default_sparepart_id && (
                <div className="flex mb-3 flex-wrap mt-2">
                  <div className="flex gap-[7px] items-center py-[2px] px-[10px] border-0 bg-[#F2F4F7] rounded-[16px] text-sm text-[#344054] selection:text-black">
                    {
                      listData?.sparepartAll?.find((f) => f?.id === values?.default_sparepart_id)
                        ?.sku
                    }
                  </div>
                </div>
              )}
              <Button
                className="border-gray-200 bg-white py-2 px-3"
                onClick={(e) => {
                  e.preventDefault()
                  setModal({ ...modal, show: true, type: 'default' })
                  setSelected(values?.default_sparepart_id ?? '')
                }}>
                Pilih Sparepart Default
              </Button>
            </div>
            {touched?.default_sparepart_id && errors?.default_sparepart_id && (
              <span className="text-[red]">{errors?.default_sparepart_id}</span>
            )}
          </div>
          <div className="mb-2">
            <Typography.Text className="font-medium">
              Qty Sparepart Default <span className="text-[red]"> *</span>
            </Typography.Text>
            <Input
              className="rounded py-3"
              placeholder="Masukan Qty Sparepart Default"
              name="default_sparepart_quantity"
              value={values?.default_sparepart_quantity}
              onChange={formik.handleChange}
              type="number"
            />
            {touched?.default_sparepart_quantity && errors?.default_sparepart_quantity && (
              <span className="text-[red]">{errors?.default_sparepart_quantity}</span>
            )}
          </div>
        </>
      )}
      <div className="mb-2">
        <Typography.Text className="font-medium">
          Deskripsi <span className="text-[red]"> *</span>
        </Typography.Text>
        <Input.TextArea
          className="rounded mb-4 py-3"
          placeholder="Masukkan Deskripsi"
          name="description"
          value={values?.description}
          onChange={formik.handleChange}
        />
        {touched?.description && errors?.description && (
          <span className="text-[red]">{errors?.description}</span>
        )}
      </div>
    </Modal>
  )
}

export default ModalServisUmum
