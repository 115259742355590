import React from 'react'
import { Card, Image, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { CarouselMultipleLayout } from 'layouts/CarouselMultipleLayout'
import { formatCurrency } from 'utils'
import UnitListCard from './UnitListCard'

const DetailExTaxiUnitComponent = ({ data }) => {
  const isExist = (value) => (value ? 'Ada' : 'Tidak Ada')

  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Unit</Typography.Text>
        </div>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Merek</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">{data?.brand_name}</Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Model</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">{data?.model_name}</Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Transmisi</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.transmission_name}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Bahan Bakar</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">{data?.fuel_name}</Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Tahun Keluaran</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.year && data?.year !== '' ? data?.year : '-'}
          </Typography.Text>
        </Typography>
      </Card>

      {/* LIST UNIT */}
      <UnitListCard data={data} />

      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Harga Jual</Typography.Text>
        </div>
        <Typography.Text className="block w-full bg-gray-300 px-3 py-2 font-semibold rounded-md">
          {formatCurrency(data?.price)}
        </Typography.Text>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Spesifikasi Mobil</Typography.Text>
        </div>
        <Typography.Text className="text-lg block mb-3">Peformance</Typography.Text>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Engine</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.performance_engine}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Transmisi</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.performance_transmission}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Drive Mode</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.performance_drive_mode)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Drivetrain</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.performance_drive_train}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Shift-By-Wire (SBW)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.performance_sbw)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Paddle Shifter</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.performance_paddle_shifter)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Motor Driven Power Steering (MDPS)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.performance_mdps)}
          </Typography.Text>
        </Typography>
        <div className="border-0 border-b border-solid border-b-gray-300 mb-4" />
        <Typography.Text className="text-lg block mb-3">Safety</Typography.Text>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Airbag</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">{data?.safety_airbag}</Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Tire Pressure Monitoring System (TPMS)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_tpms)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Anti-Lock Brake System (ABS)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_abs)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Electronic Stabillity Control (ESC)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_esc)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Hill Assist Control (HAC)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_hac)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Lane Keep Assist System (LKA)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_lka)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Lane Following Assist (LFA)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_lfa)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Blind-Spot Collision Warning (BCW)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_bcw)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Blind-Spot View Monitor (BSM)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_bsm)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Safe Exit Assist (SEA)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_sea)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Rear Seat Occupant Alert (ROA)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_roa)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Forward Collision Avoidance Assist (FCA)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_fca)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Driver Attention Warning (DAW)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_daw)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-4">
          <Typography.Text className="font-medium text-md w-1/6">Cruise Control</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.safety_cruise_control)}
          </Typography.Text>
        </Typography>
        <div className="border-0 border-b border-solid border-b-gray-300 mb-4" />
        <Typography.Text className="text-lg block mb-3">Exterior</Typography.Text>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Headlamp</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.exterior_head_lamp}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">DRL</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_drl)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Positioning Lamp</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_positioning_lamp)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Auto Light Control
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_auto_light_control)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Grille</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.exterior_grille}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Bumper</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.exterior_bumper}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Wiper</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">{data?.exterior_wiper}</Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Wheel</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">{data?.exterior_wheel}</Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Side Mirror Color</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.exterior_side_mirror_color}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Side Mirror Folding
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_side_mirror_folding)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Side Mirror Heated
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_side_mirror_heated)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Puddle Lamp</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_puddle_lamp)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Side Mirror Repeater
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_side_mirror_repeater)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Rear Lamp</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.exterior_rear_lamp}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Side Spoiler</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_side_spoiler)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">HMSL</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_hmsl)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Muffler</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.exterior_muffler}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Trunk</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_trunk)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Sunroof</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_sunroof)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Two Tone</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_two_tone)}
          </Typography.Text>
        </Typography>
        {/* <Typography className="flex mb-1">
                    <Typography.Text className="font-medium text-md w-1/6">Roof Rack</Typography.Text>
                    <Typography.Text className=" text-gray-700 w-5/6">{isExist(data?.exterior_roof_rack)}</Typography.Text>
                </Typography> */}
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Fog Lamp</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_fog_lamp)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Parking Sensor</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_parking_sensor)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-4">
          <Typography.Text className="font-medium text-md w-1/6">Mud Guard</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.exterior_mud_guard)}
          </Typography.Text>
        </Typography>
        <div className="border-0 border-b border-solid border-b-gray-300 mb-4" />
        <Typography.Text className="text-lg block mb-3">Interior</Typography.Text>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Media</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">{data?.interior_media}</Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Cluster</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.interior_cluster}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Speaker</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.interior_speaker}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Bluetooth</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.interior_bluetooth)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Steer Control</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.interior_steer_control)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Seat Material</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.interior_seat_material}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Seat Ventilation</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.interior_seat_ventilation)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Seat Adjust</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.interior_seat_adjust}
          </Typography.Text>
        </Typography>
        {/* <Typography className="flex mb-1">
                    <Typography.Text className="font-medium text-md w-1/6">Seat Folding</Typography.Text>
                    <Typography.Text className=" text-gray-700 w-5/6">{isExist(data?.interior_seat_folding)}</Typography.Text>
                </Typography> */}
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">IMS</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.interior_ims)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Seat Capacity</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.interior_seat_capacity}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Ambient Light</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.interior_ambient_light)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Cup Holder</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.interior_luggage_net)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Luggage Net</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.interior_seat_ventilation)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-4">
          <Typography.Text className="font-medium text-md w-1/6">Cooling Glove Box</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.interior_cooling_glove_box)}
          </Typography.Text>
        </Typography>
        <div className="border-0 border-b border-solid border-b-gray-300 mb-4" />
        <Typography.Text className="text-lg block mb-3">Convenience</Typography.Text>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Key</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {data?.convenience_key}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">EPB</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.convenience_epb)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">AC</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">{data?.convenience_ac}</Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Wireless Charger</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.convenience_wireless_charger)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">USB</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.convenience_usb)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Map Lamp</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.interior_cooling_glove_box)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Rain Sensor</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.convenience_map_lamp)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">
            Head-Up Display (HUD)
          </Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.convenience_head_up_display)}
          </Typography.Text>
        </Typography>
        <Typography className="flex mb-1">
          <Typography.Text className="font-medium text-md w-1/6">Auto Defogger</Typography.Text>
          <Typography.Text className=" text-gray-700 w-5/6">
            {isExist(data?.convenience_auto_defogger)}
          </Typography.Text>
        </Typography>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Foto Mobil</Typography.Text>
        </div>
        <CarouselMultipleLayout
          slideToShow={4}
          totalItem={data?.photos?.length ?? 0}
          className={'cursor-grab'}>
          {data?.thumbnail_filename && (
            <div key={`photo-thumbnail`}>
              <Image
                className="object-center object-cover w-72 h-52"
                // className="object-center object-contain mr-2"
                // src={require('assets/images/Car1.png')}
                src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${data?.thumbnail_filename}`}
                alt={`picture-thumbnail`}
                preview
                // height={300}
                // width={300}
              />
            </div>
          )}
          {data?.photos?.map((photo, key) => (
            <div key={`photo-${key}`}>
              <Image
                className="object-center object-cover w-72 h-52"
                // className="object-center object-contain mr-2"
                // src={require('assets/images/Car1.png')}
                src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${photo?.photo_filename}`}
                alt={`picture-${key}`}
                preview
                // height={300}
                // width={300}
              />
            </div>
          ))}
        </CarouselMultipleLayout>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Catatan</Typography.Text>
        </div>
        <Typography.Paragraph>{data?.description}</Typography.Paragraph>
      </Card>
    </Content>
  )
}

export default DetailExTaxiUnitComponent
