import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { CardContainer, FormDropDown, FormInput, HeaderContainer, Loading, Table } from 'components'
import { Formik } from 'formik'
import { metaInitialData, perPageInitialData } from 'constant'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { useHistory } from 'react-router-dom'

const AnalyticsDashboardCabangTotalMekanikPage = () => {
  //* all table state
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const router = useHistory()
  const [currentPage, setCurrentPage] = useState(0)
  const [meta, setMeta] = useState(metaInitialData)
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)
  const [listTotalMekanik, setListTotalMekanik] = useState([])
  const [dropDownBengkel, setDropDownBengkel] = useState([])
  const onLogout = () => dispatch(logout())

  const { isLoading, refetch } = useQuery({
    queryKey: ['analytic-cabang-total-mechanic-list'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await Promise.all([
        apiGet({
          url: `${urls.analytic.prefixUser}/${urls.analytic.cabang.mechanicTotalList}?limit=${
            perPageArr.find((item) => item.selected === true).value
          }&offset=${meta.offset ?? 0}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.workshops.all}`,
          token: user.token,
          onLogout
        })
      ]),
    onSuccess: (data) => {
      setListTotalMekanik(data[0].resData.data)
      setMeta(data[0].resData.meta)
      setDropDownBengkel([])
      if (data[1].resStatus === 200) {
        if (data[1].resData.data.length !== 0) {
          data[1].resData.data.map((item) => {
            setDropDownBengkel((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })
  //* end all table state

  const [isLoadingHelper, setIsLoadingHelper] = useState(false)
  const [bengkelSelected, setBengkelSelected] = useState()
  const [mechanicSearch, setMechanicSearch] = useState('')

  const onHandleExtraUrl = () => {
    let extraUrl = ''

    if (bengkelSelected && parseInt(bengkelSelected) > -1) {
      extraUrl += `&workshop_id=${parseInt(bengkelSelected)}`
    }
    if (mechanicSearch && mechanicSearch !== '') {
      extraUrl += `&keywords=${mechanicSearch}`
    }

    return extraUrl
  }

  const onFilterSearchListMechanic = async (bengkel, search) => {
    let extraUrl = ''
    if (bengkel && bengkel !== -1) {
      extraUrl += `&workshop_id=${bengkel}`
    }
    if (search && search !== '') {
      extraUrl += `&keywords=${search}`
    }
    const response = await apiGet({
      url: `${urls.analytic.prefixUser}/${urls.analytic.cabang.mechanicTotalList}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${meta.offset ?? 0}${extraUrl}`,
      token: user.token,
      onLogout
    })
    if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
      setListTotalMekanik(response.resData.data)
      setMeta(response.resData.meta)
    } else {
      refetch()
    }
  }

  const onChangePrevNextPage = (offset) => {
    setIsLoadingHelper(true)
    setMeta({ ...meta, offset })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.analytic.prefixUser}/${urls.analytic.cabang.mechanicTotalList}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${offset}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListTotalMekanik(response.resData.data)
      } else {
        refetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    setIsLoadingHelper(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.analytic.prefixUser}/${
          urls.analytic.cabang.mechanicTotalList
        }?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListTotalMekanik(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        refetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  //*columns table
  const columns = [
    {
      name: 'ID',
      selector: (row) => row.profile.mechanic_no,
      cell: (row) => row?.profile?.mechanic_no
    },
    {
      name: 'Nama',
      selector: (row) => row.fullname,
      cell: (row) => row?.fullname
    },
    {
      name: 'Nomor Telepon',
      selector: (row) => row.phone_number,
      cell: (row) => row?.phone_number
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      cell: (row) => row?.email
    },
    {
      name: 'Bengkel',
      selector: (row) => row.profile.workshop_name,
      cell: (row) => row?.profile?.workshop_name
    }
  ]
  //* end columns table

  if (isLoading ?? isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Cabang
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Total Mekanik
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer title="Daftar Mekanik" />
            <div className="flex flex-row w-full items-center justify-between">
              <div className="w-1/5">
                <Formik
                  enableReinitialize
                  initialValues={{
                    bengkelSelector: bengkelSelected
                  }}>
                  <FormDropDown
                    isSearch
                    allowClear
                    placeholder="Semua Bengkel"
                    name="bengkelSelector"
                    data={dropDownBengkel}
                    onChange={(value) => {
                      setBengkelSelected(value ?? null)
                      onFilterSearchListMechanic(value ? parseInt(value) : null, mechanicSearch)
                    }}
                  />
                </Formik>
              </div>
              <div className="w-1/4">
                <Formik
                  initialValues={{
                    mechanicSearch: mechanicSearch
                  }}>
                  <FormInput
                    name="mechanicSearch"
                    placeholder="Pencarian"
                    type="text"
                    isIconLeft
                    iconName="search"
                    onValidate={(value) => {
                      setMechanicSearch(value)
                      onFilterSearchListMechanic(bengkelSelected, value)
                    }}
                  />
                </Formik>
              </div>
            </div>
            <Table
              currentPage={meta.offset ? meta.offset + 1 : 1}
              totalPerPage={
                perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
                meta.total
                  ? meta.total
                  : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
              }
              dropDownDataFilter={perPageArr}
              lengthAllData={meta.total}
              columns={columns}
              data={listTotalMekanik}
              onChangePrevPage={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onChangePrevNextPage(
                    (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeNextPage={() => {
                if (
                  perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
                  meta.total
                ) {
                  setCurrentPage(currentPage + 1)
                  onChangePrevNextPage(
                    (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeDropDown={(value) => onChangePerPage(value)}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default AnalyticsDashboardCabangTotalMekanikPage
