import * as actionTypes from '../actionTypes'

const initialState = {
  unit_id: null,
  full_name: '',
  phone: '',
  email: '',
  address: '',
  service_date: null,
  service_start_time: null,
  service_end_time: null,
  workshop: null,
  stall: null,
  selectedServis: null,
  serviceForBE: null,
  simulateData: null,
  service: {
    service_packets: {
      data: {},
      total: 0
    },
    service_generals: {
      data: {},
      total: 0
    },
    total: 0
  }
}

const servisReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_SERVIS: {
      return {
        ...state,
        selectedServis: action.selectedServis
      }
    }
    case actionTypes.SET_SERVIS_STEP1: {
      return {
        ...state,
        unit_id: action.unit_id,
        full_name: action.full_name,
        phone: action.phone,
        email: action.email,
        address: action.address
      }
    }
    case actionTypes.SET_SERVIS_STEP2: {
      return {
        ...state,
        service: action.service,
        selectedServis: action.selectedServis,
        serviceForBE: action.serviceForBE,
        simulateData: action.simulateData
      }
    }
    case actionTypes.SET_SERVIS_STEP3: {
      return {
        ...state,
        service_date: action.service_date,
        service_start_time: action.service_start_time,
        service_end_time: action.service_end_time,
        workshop: action.workshop,
        stall: action.stall
      }
    }
    case actionTypes.RESET_SERVIS: {
      return {
        ...initialState
      }
    }
    default: {
      return state
    }
  }
}

export default servisReducer
