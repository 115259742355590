export const imageDimension = (file) => {
  const img = new Image()

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width = img.naturalWidth
      const height = img.naturalHeight

      // Resolve promise with the width and height
      resolve({ width, height })
    }

    // Reject promise on error
    img.onerror = reject
  })

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = URL.createObjectURL(file)

  return promise
}
