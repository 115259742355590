import React, { useState } from 'react'
import { Breadcrumb, DatePicker, Layout, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  CardContainer,
  FormDropDown,
  FormInput,
  HeaderContainer,
  ModalDelete,
  Table
} from 'components'
import { IcEdit, IcTrash } from 'assets/icons'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { logout } from 'store/actions'
import { Formik } from 'formik'
import moment from 'moment'
import { ModalCreateUpdateHargaRekomendasi } from './components'
import { formatCurrency, showErrorMessage, showSuccessMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  brand_id: '',
  model_id: '',
  type_id: '',
  transmission_id: '',
  keywords: '',
  listData: []
}

const HargaRekomendasiPage = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [tableState, setTableState] = useState(initialTableState)

  // state filter
  const [dropDownBrandData, setDropDownBrandData] = useState([
    { name: 'Brand', value: '' },
    { name: 'Pilih Brand', value: '' }
  ])
  const [dropDownModelData, setDropDownModelData] = useState([{ name: 'Model', value: '' }])
  const [dropDownTipeData, setDropDownTipeData] = useState([{ name: 'Tipe', value: '' }])
  const [dropDownTransmisiData, setDropDownTransmisiData] = useState([
    { name: 'Transmisi', value: '' },
    { name: 'Pilih Transmisi', value: -1 }
  ])
  const [year, setYear] = useState({
    value: moment(new Date(), 'YYYY'),
    parse: ''
  })

  const onLogout = () => dispatch(logout())

  const [modalCreateUpdate, setModalCreateUpdate] = useState({
    typeCRUD: 'create',
    show: false,
    data: {}
  })

  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: 0,
    title: '',
    desc: ''
  })

  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  const { refetch, isLoading, isFetching } = useQuery({
    queryKey: [
      'harga-rekomendasi-list',
      tableState.offset,
      tableState.limit,
      tableState.brand_id,
      tableState.model_id,
      tableState.type_id,
      tableState.transmission_id,
      tableState.keywords,
      year.parse
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        offset: tableState.offset,
        limit: tableState.limit,
        brand_id: tableState.brand_id,
        model_id: tableState.model_id,
        type_id: tableState.type_id,
        transmission_id: tableState.transmission_id,
        year: year.parse,
        keywords: tableState.keywords
      }
      const res = await apiGet({
        token: user.token,
        data: params,
        url: `${urls.master.prefix}/${urls.master.products.priceRecommendation.root}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  useQuery({
    queryKey: ['harga-rekomendasi-brand'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.brand.all}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownBrandData([
        { name: 'Brand', value: '' },
        { name: 'Pilih Brand', value: -1 }
      ])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDropDownBrandData((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  useQuery({
    queryKey: ['harga-rekomendasi-model', tableState.brand_id],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.model.all}${
          tableState.brand_id !== '' ? `?brand_id=${tableState.brand_id}` : ''
        }`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownModelData([{ name: 'Model', value: '' }])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDropDownModelData((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  useQuery({
    queryKey: ['harga-rekomendasi-tipe', tableState.model_id],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.tipe.all}${
          tableState.model_id !== '' ? `?model_id=${tableState.model_id}` : ''
        }`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownTipeData([{ name: 'Tipe', value: '' }])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDropDownTipeData((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  useQuery({
    queryKey: ['harga-rekomendasi-transmisi'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.transmissions.all}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownTransmisiData([
        { name: 'Transmisi', value: '' },
        { name: 'Pilih Transmisi', value: -1 }
      ])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDropDownTransmisiData((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const columns = [
    {
      name: 'Brand',
      selector: (row) => row.brand.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.brand.name}</span>
    },
    {
      name: 'Model',
      selector: (row) => row.model.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.model.name}</span>
    },
    {
      name: 'Tipe',
      selector: (row) => row.type.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.type.name}</span>
    },
    {
      name: 'Transmisi',
      selector: (row) => row.transmission.name,
      cell: (row) => (
        <span className="font-medium text-gray-700">
          {row.transmission.name && row.transmission.name !== '' ? row.transmission.name : '-'}
        </span>
      )
    },
    {
      name: 'Tahun Keluaran',
      selector: (row) => row.year,
      cell: (row) => <span className="font-medium text-gray-700">{row.year}</span>
    },
    {
      name: 'Harga Rekomendasi',
      selector: (row) => row.price,
      width: '270px',
      cell: (row) => (
        <span className="font-medium text-gray-700">{`${
          row.start_price ? formatCurrency(row.start_price) : 'Rp. -'
        } - ${row.end_price ? formatCurrency(row.end_price) : 'Rp. -'}`}</span>
      )
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '80px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              setModalCreateUpdate({
                ...modalCreateUpdate,
                show: true,
                typeCRUD: 'update',
                data: row
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setModalDelete({
                ...modalDelete,
                show: true,
                id: row.id,
                title: 'Hapus Harga Rekomendasi',
                desc: `Apakah Anda yakin ingin menghapus kategori harga rekomendasi ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Layout
      style={{
        padding: 24,
        background: '#F5F5F5',
        width: '100%'
      }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Master Data
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Harga Rekomendasi
        </Breadcrumb.Item>
      </Breadcrumb>
      {modalDelete.show ? (
        <ModalDelete
          minHeight="min-h-[20%]"
          minWidth="min-w-[23%]"
          maxWidth="max-w-[23%]"
          title={modalDelete.title}
          desc={modalDelete.desc}
          onClose={() => setModalDelete({ ...modalDelete, show: false })}
          onSubmit={() => {
            setIsLoadingHelper(true)
            const mount = setTimeout(async () => {
              const res = await apiDelete({
                url: `${urls.master.prefix}/${urls.master.products.priceRecommendation.root}/${modalDelete.id}`,
                token: user.token,
                onLogout
              })
              if (res.resStatus === 200) {
                showSuccessMessage({ content: 'Successfully Delete Harga Rekomendasi' })
              } else {
                showErrorMessage({
                  content:
                    res.resData.message ??
                    res.resData.errors[0].message ??
                    'Failed to Delete Harga Rekomendasi'
                })
              }
              refetch()
              setIsLoadingHelper(false)
              setModalDelete({ ...modalDelete, show: false })
            })
            return () => clearTimeout(mount)
          }}
        />
      ) : null}
      {modalCreateUpdate.show ? (
        <ModalCreateUpdateHargaRekomendasi
          state={modalCreateUpdate}
          dataDropDownBrand={dropDownBrandData.filter((item) => item.name !== 'Brand')}
          dataDropDownTransmisi={dropDownTransmisiData.filter((item) => item.name !== 'Transmisi')}
          onClose={() => setModalCreateUpdate({ ...modalCreateUpdate, show: false })}
          onSubmit={(data) => {
            setIsLoadingHelper(true)
            const mount = setTimeout(async () => {
              let res
              if (modalCreateUpdate.typeCRUD === 'create') {
                res = await apiPost({
                  url: `${urls.master.prefix}/${urls.master.products.priceRecommendation.root}`,
                  token: user.token,
                  data,
                  onLogout
                })
              }
              if (modalCreateUpdate.typeCRUD === 'update') {
                res = await apiPut({
                  url: `${urls.master.prefix}/${urls.master.products.priceRecommendation.root}/${data.id}`,
                  token: user.token,
                  data,
                  onLogout
                })
              }
              if (res.resStatus === 200) {
                if (modalCreateUpdate.typeCRUD === 'create') {
                  showSuccessMessage({ content: 'Successfully Create Harga Rekomendasi' })
                }
                if (modalCreateUpdate.typeCRUD === 'update') {
                  showSuccessMessage({ content: 'Successfully Update Harga Rekomendasi' })
                }
              } else {
                showErrorMessage({
                  content:
                    res.resData.message ??
                    res.resData.errors[0].message ??
                    (modalCreateUpdate.typeCRUD === 'create'
                      ? 'Failed to Create Harga Rekomendasi'
                      : 'Failed to Update Harga Rekomendasi')
                })
              }
              refetch()
              setIsLoadingHelper(false)
              setModalCreateUpdate({ ...modalCreateUpdate, show: false })
            })
            return () => clearTimeout(mount)
          }}
        />
      ) : null}
      <CardContainer>
        <div className="flex flex-col w-full space-y-5">
          <HeaderContainer
            isPressAdd
            title="Harga Rekomendasi"
            titleAdd="Tambah Harga Rekomendasi"
            onPressAdd={() =>
              setModalCreateUpdate({ ...modalCreateUpdate, show: true, typeCRUD: 'create' })
            }
          />
          <div className="flex flex-row w-full items-start justify-between mt-2">
            <div className="flex flex-col w-full space-y-2">
              <div className="flex flex-row w-full items-center space-x-2">
                <div className="w-1/5">
                  <Select
                    showSearch
                    allowClear
                    className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                    value={tableState.brand_id}
                    onChange={(value) => {
                      setTableState((prev) => ({
                        ...prev,
                        brand_id: value ? (value === '' ? value : parseInt(value)) : ''
                      }))
                    }}
                    onSearch={(e) => {}}
                    optionFilterProp="children"
                    placeholder="Pilih/Search Brand">
                    {dropDownBrandData
                      .filter((itemPr) => itemPr.name !== 'Pilih Brand')
                      .map((itemCh, indexCh) => (
                        <Select.Option key={indexCh} value={itemCh.value}>
                          {itemCh.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="w-1/5">
                  <Select
                    showSearch
                    allowClear
                    className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                    value={tableState.model_id}
                    onChange={(value) => {
                      setTableState((prev) => ({
                        ...prev,
                        model_id: value ? (value === '' ? value : parseInt(value)) : ''
                      }))
                    }}
                    onSearch={(e) => {}}
                    optionFilterProp="children"
                    placeholder="Pilih/Search Model">
                    {dropDownModelData.map((itemCh, indexCh) => (
                      <Select.Option key={indexCh} value={itemCh.value}>
                        {itemCh.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="w-1/5">
                  <Select
                    showSearch
                    allowClear
                    className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                    value={tableState.type_id}
                    onChange={(value) => {
                      setTableState((prev) => ({
                        ...prev,
                        type_id: value ? (value === '' ? '' : parseInt(value)) : ''
                      }))
                    }}
                    onSearch={(e) => {}}
                    optionFilterProp="children"
                    placeholder="Pilih/Search Tipe">
                    {dropDownTipeData.map((itemCh, indexCh) => (
                      <Select.Option key={indexCh} value={itemCh.value}>
                        {itemCh.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex flex-row w-full items-center space-x-2">
                <div className="w-1/5">
                  <Select
                    showSearch
                    allowClear
                    className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                    value={tableState.transmission_id}
                    onChange={(value) => {
                      setTableState((prev) => ({
                        ...prev,
                        transmission_id: value ? (value === '' ? value : parseInt(value)) : ''
                      }))
                    }}
                    onSearch={(e) => {}}
                    optionFilterProp="children"
                    placeholder="Pilih/Search Transmisi">
                    {dropDownTransmisiData
                      .filter((itemPr) => itemPr.name !== 'Pilih Transmisi')
                      .map((itemCh, indexCh) => (
                        <Select.Option key={indexCh} value={itemCh.value}>
                          {itemCh.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="w-1/5">
                  <DatePicker
                    placeholder="Tahun Keluaran"
                    picker="year"
                    value={year.parse === '' ? '' : year.value}
                    onChange={(value) => {
                      setYear({
                        value: value,
                        parse: value && value !== '' ? moment(value, 'YYYY').format('YYYY') : ''
                      })
                    }}
                    className="custom-date-picker py-2.5 w-full"
                    popupClassName="custom-date-picker-dropdown"
                    format={['YYYY']}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/3">
              <Formik
                initialValues={{
                  search: tableState.keywords
                }}>
                <FormInput
                  name="search"
                  placeholder="Pencarian"
                  type="text"
                  isIconLeft
                  iconName="search"
                  onValidate={(value) => setTableState((prev) => ({ ...prev, keywords: value }))}
                />
              </Formik>
            </div>
          </div>
          <Table
            columns={columns}
            data={tableState.listData}
            pending={isLoading ?? isFetching ?? isLoadingHelper}
            totalRows={tableState?.totalRows}
            handlePerRowsChange={handlePerRowsChange}
            pageSize={tableState?.limit}
            page={tableState?.offset}
            onChangePrevPage={() => {
              if (tableState?.offset > 0) {
                onChangePage(tableState?.offset - tableState?.limit)
              }
            }}
            onChangeNextPage={() => {
              if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                onChangePage(tableState?.offset + tableState?.limit)
              }
            }}
          />
        </div>
      </CardContainer>
    </Layout>
  )
}
export default HargaRekomendasiPage
