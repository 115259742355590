import React from 'react'

const IcChevronUp = ({ className, size = 20 }) => (
  <svg width={size} className={className} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 12.5L10 7.5L5 12.5" stroke="currentColor" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default IcChevronUp
