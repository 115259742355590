import { IcImage } from 'assets/icons';
import React, { useRef } from 'react';

const UploadImage = ({
    file,
    onChangeFile,
    containerClassName,
    customRef,
    oldFile,
    customDefault,
    container,
    sizeClassName = 'w-24 h-24',
    isRawFile
}) => {
    const inputRef = useRef();

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileDrop = async (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;

        onChangeImage(files[0]);
    }

    const onChangeImage = async (file) => {
        const image = await convertBase64(file);
        if (isRawFile) {
            // tidak bisa menampilkan e.target.files[0] jadi dibikin seperti ini, contohnya ada di LandingPage
            onChangeFile(image, file);
        } else {
            onChangeFile(image);
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <div
            className={container ? container : `${sizeClassName} border border-dashed rounded-lg border-gray-700 hover:border-primary-500 hover:cursor-pointer overflow-hidden ${file || oldFile ? 'border-0' : ''} ${containerClassName}`}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={customRef ? () => customRef.current.click() : () => inputRef.current.click()}
        >
            <input
                className={`hidden`}
                accept='image/png,image/jpeg,image/jpg'
                type='file'
                id='file'
                ref={customRef ? customRef : inputRef}
                style={{ display: 'none' }}
                value={""}
                onChange={(e) => onChangeImage(e.target.files[0])}
            />
            <div className={container ? '' : `${sizeClassName} flex flex-col items-center justify-center gap-3`}>
                {file ? (
                    <img
                        alt={`upload `}
                        style={{ width: '100%', height: '100%' }}
                        src={file}
                    />

                ) : oldFile ? (
                    <img
                        alt={`upload `}
                        style={{ width: '100%', height: '100%' }}
                        src={oldFile}
                    />
                ) : customDefault ? customDefault : (
                    <>
                        <IcImage
                            className={'text-gray-400'}
                            size={25}
                        />
                        {
                            sizeClassName !== 'w-24 h-24' && <div className="text-xs-medium text-gray-400">Tambah Gambar</div>
                        }
                    </>
                )}
            </div>
        </div>
    )
}

export default UploadImage;
