import React from 'react'
import { Modal, Typography } from 'antd'

const ModalDeleteKategoriInspeksi = ({ data, isOpen, onCancel, onSubmit }) => (
  <Modal
    centered
    title="Hapus Kategori Inspeksi"
    open={isOpen}
    onCancel={onCancel}
    okText="Confirm"
    okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
    cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
    onOk={() => onSubmit(data)}>
    <Typography.Text className="font-medium">
      {`Apakah Anda yakin ingin menghapus kategori inspeksi ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
    </Typography.Text>
  </Modal>
)

export default ModalDeleteKategoriInspeksi
