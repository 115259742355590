import React from 'react'
import { CardContainer } from 'components'
import { IcAlertTriangle, IcCheck, IcClose } from 'assets/icons'

const KondisiBanSection = ({ data }) => {
  const titleSectionStyle = 'font-medium text-gray-500'
  const fieldStyle = 'font-medium text-gray-400'
  const valueStyle = 'font-medium text-gray-400'

  const onBgValue = (val) => {
    switch (val) {
      case 'lulus':
        return 'bg-[#ECFDF3]'
      case 'diperbaiki':
        return 'bg-[#FFFAEB]'
      case 'tidak lulus':
        return 'bg-[#FEF3F2]'
      default:
        break
    }
  }

  const onTextValueStyle = (val) => {
    switch (val) {
      case 'lulus':
        return 'text-[#027948]'
      case 'diperbaiki':
        return 'text-[#F79009]'
      case 'tidak lulus':
        return 'text-[#B32318]'
      default:
        break
    }
  }

  const onIcon = (val) => {
    switch (val) {
      case 'lulus':
        return (
          <IcCheck
            className="text-[#027948]"
            size={16}
          />
        )
      case 'diperbaiki':
        return (
          <IcAlertTriangle
            className="text-[#F79009]"
            size={16}
          />
        )
      case 'tidak lulus':
        return (
          <IcClose
            className="text-[#B32318]"
            size={16}
          />
        )
      default:
        break
    }
  }

  return (
    <CardContainer
      minHeight="20%"
      px="px-2"
    >
      <div
        className="flex border border-solid border-b-1 border-x-0 border-t-0 border-b-gray-300 pb-4 mx-4"
      >
        <span className="font-semibold text-2xl text-gray-900">
          Kondisi Ban
        </span>
      </div>
      <div
        className="flex p-4 w-full"
      >
        <div className="grid grid-rows-4 grid-flow-col w-full gap-4">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex flex-col space-y-1"
            >
              <div
                className="flex flex-row items-center justify-between"
              >
                <span
                  className={titleSectionStyle}
                >
                  {item.title}
                </span>
                <div
                  className={`flex flex-row items-center justify-around space-x-1 py-1 px-2 rounded-xl ${onBgValue(item.status.toLowerCase())}`}
                >
                  {onIcon(item.status.toLowerCase())}
                  <span
                    className={`font-medium text-sm ${onTextValueStyle(item.status.toLowerCase())}`}
                  >
                    {item.status}
                  </span>
                </div>
              </div>
              {
                item.category.map((itemCtg, index) => (
                  <div
                    key={index}
                    className="flex flex-row space-x-1"
                  >
                    <span
                      className={fieldStyle}
                    >
                      {itemCtg.name}
                    </span>
                    <span
                      className="text-gray-400"
                    >
                      :
                    </span>
                    <span
                      className={valueStyle}
                    >
                      {itemCtg.value}
                    </span>
                  </div>
                ))
              }
            </div>
          ))}
        </div>
      </div>
    </CardContainer>
  )
}

export default KondisiBanSection
