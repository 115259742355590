import React, { useState } from 'react'
import { Breadcrumb, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import {
  CardContainer,
  FormDropDown,
  FormInput,
  HeaderContainer,
  Loading,
  ModalDelete,
  Table
} from 'components'
import { Formik } from 'formik'
import { IcEdit, IcTrash } from 'assets/icons'
import { ModalCreateUpdateTipe } from './components'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { metaInitialData, perPageInitialData } from 'constant'
import { logout } from 'store/actions'

const ProdukTipePage = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [tipeList, setTipeList] = useState()

  const [meta, setMeta] = useState(metaInitialData)

  const [currentPage, setCurrentPage] = useState(0)

  const [dropDownBrandData, setDropDownBrandData] = useState([])

  const [dropDownModelData, setDropDownModelData] = useState([])

  const [dropDownKelasUnit, setDropDownKelasUnit] = useState([])

  const [perPageArr, setPerPageArr] = useState(perPageInitialData)

  const onLogout = () => dispatch(logout())

  const { isLoading, refetch } = useQuery({
    queryKey: ['tipe-list'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.tipe.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=0`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownModelData([{ name: 'Semua Model', value: -1 }])
      setTipeList(data.resData.data)
      setCurrentPage(0)
      setMeta(data.resData.meta)
    }
  })

  useQuery({
    queryKey: ['tipe-list-brand-dropdown'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.brand.all}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownBrandData([
        { name: 'Semua Brand', value: -1 },
        { name: 'Pilih Brand', value: 0 }
      ])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDropDownBrandData((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  useQuery({
    queryKey: ['tipe-list-class-unit-dropdown'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.classUnit.all}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownKelasUnit([
        { name: 'Semua Kelas', value: -1 },
        { name: 'Pilih Kelas', value: 0 }
      ])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDropDownKelasUnit((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const [modalDelete, setModalDelete] = useState({
    status: false,
    tipeId: 0,
    title: '',
    desc: ''
  })
  const [modalCreateUpdate, setModalCreateUpdate] = useState({
    status: false,
    type: 'create',
    tipeName: '',
    tipeId: 0,
    modelId: 0,
    brandId: 0,
    classId: 0,
    categories: []
  })

  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [searchTipe, setSearchTipe] = useState('')
  const [brandSelected, setBrandSelected] = useState(-1)
  const [modelSelected, setModelSelected] = useState(-1)
  const [kelasUnitSelected, setKelasUnitSelected] = useState(-1)

  const onHandleExtraUrl = () => {
    let extraUrl = ''

    if (searchTipe !== '') {
      extraUrl += `&keywords=${searchTipe}`
    }
    if (brandSelected > 0) {
      extraUrl += `&brand_id=${brandSelected}`
    }
    if (modelSelected > 0) {
      extraUrl += `&model_id=${modelSelected}`
    }
    if (kelasUnitSelected > 0) {
      extraUrl += `&class_id=${kelasUnitSelected}`
    }

    return extraUrl
  }

  const onChangePrevNextPage = (offset) => {
    setIsLoadingCRUD(true)
    setMeta({ ...meta, offset })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.master.prefix}/${urls.master.products.tipe.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${offset}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setTipeList(response.resData.data)
      } else {
        refetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    setIsLoadingCRUD(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.master.prefix}/${
          urls.master.products.tipe.root
        }?limit=${value}&offset=0${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setTipeList(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        refetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const columns = [
    {
      name: 'Tipe',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>
    },
    {
      name: 'Brand',
      selector: (row) => row.model.brand.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.model.brand.name}</span>
    },
    {
      name: 'Model',
      selector: (row) => row.model.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.model.name}</span>
    },
    {
      name: 'Kategori',
      selector: (row) => row.categories.map((item) => item.name).join(' '),
      cell: (row) =>
        row.categories.length === 0 ? (
          <span className="font-normal text-center text-md">No Category</span>
        ) : (
          <div className="grid grid-cols-2 gap-2 my-2">
            {row.categories.map((item, index) => (
              <div
                key={index}
                className="flex bg-blue-50 px-2 py-2 items-center justify-center rounded-xl">
                <span className="font-medium text-blue-700 text-center">{item.name}</span>
              </div>
            ))}
          </div>
        ),
      width: '270px'
    },
    {
      name: 'Kelas Unit',
      selector: (row) => row.class.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.class.name}</span>
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              setModalCreateUpdate({
                status: true,
                type: 'update',
                tipeName: row.name,
                tipeId: row.id,
                modelId: row.model.id,
                brandId: row.model.brand.id,
                classId: row.class.id,
                categories: row.categories
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setModalDelete({
                status: true,
                tipeId: row.id,
                title: 'Hapus Tipe',
                desc: `Apakah anda yakin ingin menghapus tipe [${row.name}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  if (isLoading ?? isLoadingCRUD) {
    return <Loading visible={true} />
  }

  return (
    <Layout
      style={{
        padding: 24,
        background: '#F5F5F5',
        width: '100%'
      }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Master Data
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Produk</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Tipe</Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {modalDelete.status ? (
          <ModalDelete
            minHeight="min-h-[20%]"
            minWidth="min-w-[20%]"
            maxWidth="max-w-[20%]"
            title={modalDelete.title}
            desc={modalDelete.desc}
            onClose={() => setModalDelete({ ...modalDelete, status: false })}
            onSubmit={() => {
              setIsLoadingCRUD(true)
              const mount = setTimeout(async () => {
                await apiDelete({
                  url: `${urls.master.prefix}/${urls.master.products.tipe.root}/${modalDelete.tipeId}`,
                  token: user.token,
                  onLogout
                })
                setModalDelete({ ...modalDelete, status: false })
                refetch()
                setIsLoadingCRUD(false)
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {modalCreateUpdate.status ? (
          <ModalCreateUpdateTipe
            state={modalCreateUpdate}
            dataDropDownBrand={dropDownBrandData.filter((item) => item.name !== 'Semua Brand')}
            dataDropDownKelasUnit={dropDownKelasUnit.filter((item) => item.name !== 'Semua Kelas')}
            onClose={() =>
              setModalCreateUpdate({
                status: false,
                type: 'create',
                tipeId: 0,
                tipeName: '',
                modelId: 0,
                brandId: 0,
                classId: 0,
                categories: []
              })
            }
            onSubmit={(values) => {
              setIsLoadingCRUD(true)
              const mount = setTimeout(async () => {
                if (modalCreateUpdate.type === 'create') {
                  await apiPost({
                    url: `${urls.master.prefix}/${urls.master.products.tipe.root}`,
                    data: {
                      name: values.tipeName,
                      brand_id: parseInt(values.brandSelector),
                      model_id: parseInt(values.modelSelector),
                      class_id: parseInt(values.kelasUnitSelector),
                      categories_id: values.categories.map((item) => item.id)
                    },
                    token: user.token,
                    onLogout
                  })
                }
                if (modalCreateUpdate.type === 'update') {
                  await apiPut({
                    url: `${urls.master.prefix}/${urls.master.products.tipe.root}/${modalCreateUpdate.tipeId}`,
                    data: {
                      name: values.tipeName,
                      brand_id: parseInt(values.brandSelector),
                      model_id: parseInt(values.modelSelector),
                      class_id: parseInt(values.kelasUnitSelector),
                      categories_id: values.categories.map((item) => item.id)
                    },
                    token: user.token,
                    onLogout
                  })
                }
                setModalCreateUpdate({ ...modalCreateUpdate, status: false })
                setIsLoadingCRUD(false)
                refetch()
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer
              isPressAdd
              title="Tipe List"
              titleAdd="Tambah Tipe"
              onPressAdd={() =>
                setModalCreateUpdate({ ...modalCreateUpdate, status: true, type: 'create' })
              }
            />
            <div className="flex flex-row w-full items-center justify-between mt-2">
              <div className="flex flex-row w-full items-center space-x-5">
                <div className="w-1/5">
                  <Select
                    showSearch
                    allowClear
                    className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                    value={parseInt(brandSelected)}
                    onChange={(value) => {
                      setIsLoadingCRUD(true)
                      setDropDownModelData([{ name: 'Semua Model', value: -1 }])
                      let extraUrl = ''
                      if (searchTipe !== '') {
                        extraUrl += `&keywords=${searchTipe}`
                      }
                      if (kelasUnitSelected > 0) {
                        extraUrl += `&class_id=${kelasUnitSelected}`
                      }
                      const mount = setTimeout(async () => {
                        if (value && parseInt(value) > 0) {
                          const response = await Promise.all([
                            apiGet({
                              url: `${urls.master.prefix}/${
                                urls.master.products.tipe.root
                              }?brand_id=${parseInt(value)}&limit=${
                                perPageArr.find((item) => item.selected === true).value
                              }&offset=0${extraUrl}`,
                              token: user.token,
                              onLogout
                            }),
                            apiGet({
                              url: `${urls.master.prefix}/${
                                urls.master.products.model.all
                              }?brand_id=${parseInt(value)}`,
                              token: user.token,
                              onLogout
                            })
                          ])
                          if (response[0].resStatus === 200 && response[1].resStatus === 200) {
                            setTipeList(response[0].resData.data)
                            setMeta(response[0].resData.meta)
                            if (response[1].resData.data.length !== 0) {
                              response[1].resData.data.map((item) => {
                                setDropDownModelData((prev) => [
                                  ...prev,
                                  { name: item.name, value: item.id }
                                ])
                              })
                            }
                          } else {
                            refetch()
                          }
                        } else {
                          refetch()
                        }
                        setBrandSelected(value ? parseInt(value) : -1)
                        setModelSelected(-1)
                        setKelasUnitSelected(-1)
                        setIsLoadingCRUD(false)
                      })
                      return () => clearTimeout(mount)
                    }}
                    onSearch={(e) => {}}
                    optionFilterProp="children"
                    placeholder="Pilih/Search Brand">
                    {dropDownBrandData
                      .filter((itemPr) => itemPr.name !== 'Pilih Brand')
                      .map((itemCh, indexCh) => (
                        <Select.Option key={indexCh} value={itemCh.value}>
                          {itemCh.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="w-1/5">
                  <Select
                    showSearch
                    allowClear
                    className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                    value={parseInt(modelSelected)}
                    onChange={(value) => {
                      setIsLoadingCRUD(true)
                      let extraUrl = ''
                      if (value && parseInt(value) > 0) {
                        extraUrl += `&model_id=${parseInt(value)}`
                      }
                      if (searchTipe !== '') {
                        extraUrl += `&keywords=${searchTipe}`
                      }
                      if (kelasUnitSelected > 0) {
                        extraUrl += `&class_id=${kelasUnitSelected}`
                      }
                      const mount = setTimeout(async () => {
                        const response = await apiGet({
                          url: `${urls.master.prefix}/${
                            urls.master.products.tipe.root
                          }?brand_id=${brandSelected}&limit=${
                            perPageArr.find((item) => item.selected === true).value
                          }&offset=0${extraUrl}`,
                          token: user.token,
                          onLogout
                        })
                        if (response.resStatus === 200) {
                          setTipeList(response.resData.data)
                          setMeta(response.resData.meta)
                        } else {
                          refetch()
                        }
                        setModelSelected(value ? parseInt(value) : -1)
                        setKelasUnitSelected(-1)
                        setIsLoadingCRUD(false)
                      })
                      return () => clearTimeout(mount)
                    }}
                    onSearch={(e) => {}}
                    optionFilterProp="children"
                    placeholder="Pilih/Search Model">
                    {dropDownModelData
                      .filter((itemPr) => itemPr.name !== 'Pilih Model')
                      .map((itemCh, indexCh) => (
                        <Select.Option key={indexCh} value={itemCh.value}>
                          {itemCh.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="w-1/5">
                  <Select
                    showSearch
                    allowClear
                    className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                    value={parseInt(kelasUnitSelected)}
                    onChange={(value) => {
                      setIsLoadingCRUD(true)
                      let extraUrl = ''
                      if (value && parseInt(value) > 0) {
                        extraUrl += `&class_id=${parseInt(value)}`
                      }
                      if (searchTipe !== '') {
                        extraUrl += `&keywords=${searchTipe}`
                      }
                      if (brandSelected > 0) {
                        extraUrl += `&brand_id=${brandSelected}`
                      }
                      if (modelSelected > 0) {
                        extraUrl += `&model_id=${modelSelected}`
                      }
                      const mount = setTimeout(async () => {
                        const response = await apiGet({
                          url: `${urls.master.prefix}/${urls.master.products.tipe.root}?limit=${
                            perPageArr.find((item) => item.selected === true).value
                          }&offset=0${extraUrl}`,
                          token: user.token,
                          onLogout
                        })
                        if (response.resStatus === 200) {
                          setTipeList(response.resData.data)
                          setMeta(response.resData.meta)
                        } else {
                          refetch()
                        }
                        setKelasUnitSelected(value ? parseInt(value) : -1)
                        setIsLoadingCRUD(false)
                      })
                      return () => clearTimeout(mount)
                    }}
                    onSearch={(e) => {}}
                    optionFilterProp="children"
                    placeholder="Pilih/Search Kelas">
                    {dropDownKelasUnit
                      .filter((itemPr) => itemPr.name !== 'Pilih Kelas')
                      .map((itemCh, indexCh) => (
                        <Select.Option key={indexCh} value={itemCh.value}>
                          {itemCh.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="w-1/3">
                <Formik
                  initialValues={{
                    searchTipe: searchTipe
                  }}>
                  <FormInput
                    name="searchTipe"
                    placeholder="Pencarian"
                    type="text"
                    isIconLeft
                    iconName="search"
                    onValidate={async (value) => {
                      let extraUrl = ''
                      if (brandSelected > 0) {
                        extraUrl += `&brand_id=${brandSelected}`
                      }
                      if (modelSelected > 0) {
                        extraUrl += `&model_id=${modelSelected}`
                      }
                      if (kelasUnitSelected > 0) {
                        extraUrl += `&class_id=${kelasUnitSelected}`
                      }
                      const response = await apiGet({
                        url: `${urls.master.prefix}/${
                          urls.master.products.tipe.root
                        }?keywords=${value}&limit=${
                          perPageArr.find((item) => item.selected === true).value
                        }&offset=0${extraUrl}`,
                        token: user.token,
                        onLogout
                      })
                      if (response.resStatus === 200) {
                        setTipeList(response.resData.data)
                        setMeta(response.resData.meta)
                      } else {
                        refetch()
                      }
                      setSearchTipe(value)
                    }}
                  />
                </Formik>
              </div>
            </div>
            <Table
              currentPage={meta.offset ? meta.offset + 1 : 1}
              totalPerPage={
                perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
                meta.total
                  ? meta.total
                  : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
              }
              dropDownDataFilter={perPageArr}
              lengthAllData={meta.total}
              columns={columns}
              data={tipeList}
              onChangePrevPage={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onChangePrevNextPage(
                    (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeNextPage={() => {
                if (
                  perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
                  meta.total
                ) {
                  setCurrentPage(currentPage + 1)
                  onChangePrevNextPage(
                    (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeDropDown={(value) => onChangePerPage(value)}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default ProdukTipePage
