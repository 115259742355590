import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button, Table } from 'components'
import { IcPlus, IcSearch } from 'assets/icons'

const WorkshopManagementComponent = ({
  columns,
  data,
  isLoading,
  provinces,
  cities,
  tableState,
  setTableState
}) => {
  const router = useHistory()

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">Daftar Bengkel</Typography.Text>
          <Button
            className="flex flex-row items-center justify-around rounded-md space-x-1 px-2 py text-md text-white bg-primary-500"
            onClick={() => router.push('/workshop-management/tambah-bengkel')}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Bengkel</span>
          </Button>
        </div>
        <div className="mb-5 flex justify-between">
          <div className="flex flex-row items-center space-x-4">
            <Select
              showSearch
              allowClear
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
              style={{ minWidth: '200px' }}
              value={tableState?.province_id ?? ''}
              optionFilterProp="children"
              onChange={(e) =>
                setTableState((prev) => ({ ...prev, province_id: e ?? '', city_id: '' }))
              }>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Provinsi
              </Select.Option>
              {provinces?.map((province, idx) => (
                <Select.Option key={idx} value={province?.id}>
                  {province?.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              showSearch
              allowClear
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
              style={{ minWidth: '200px' }}
              value={tableState?.city_id ?? ''}
              optionFilterProp="children"
              onChange={(e) => setTableState((prev) => ({ ...prev, city_id: e ?? '' }))}>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Cabang
              </Select.Option>
              {cities?.map((city, idx) => (
                <Select.Option key={idx} value={city?.id}>
                  {city?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Input
            className="w-1/4 rounded-md"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) =>
              setTableState((prev) => ({ ...prev, keywords: e?.target?.value, offset: 0 }))
            }
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

export default WorkshopManagementComponent
