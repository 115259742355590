import React from 'react'

const IcTaxi = ({ className, size = 20 }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1951_43439)">
      <path d="M17.5 9.16667L15.4167 4.16667H13.3333M17.5 9.16667V15.4167M17.5 9.16667H2.5M17.5 15.4167V17.5H16.6667V15.4167M17.5 15.4167H16.6667M2.5 9.16667L4.58333 4.16667H6.66667M2.5 9.16667V15.4167M2.5 15.4167V17.5H3.33333V15.4167M2.5 15.4167H3.33333M13.3333 4.16667L12.5 2.5H7.5L6.66667 4.16667M13.3333 4.16667H6.66667M16.6667 15.4167H3.33333M6.25 13.75C5.91848 13.75 5.60054 13.6183 5.36612 13.3839C5.1317 13.1495 5 12.8315 5 12.5C5 12.1685 5.1317 11.8505 5.36612 11.6161C5.60054 11.3817 5.91848 11.25 6.25 11.25C6.58152 11.25 6.89946 11.3817 7.13388 11.6161C7.3683 11.8505 7.5 12.1685 7.5 12.5C7.5 12.8315 7.3683 13.1495 7.13388 13.3839C6.89946 13.6183 6.58152 13.75 6.25 13.75ZM13.75 13.75C13.4185 13.75 13.1005 13.6183 12.8661 13.3839C12.6317 13.1495 12.5 12.8315 12.5 12.5C12.5 12.1685 12.6317 11.8505 12.8661 11.6161C13.1005 11.3817 13.4185 11.25 13.75 11.25C14.0815 11.25 14.3995 11.3817 14.6339 11.6161C14.8683 11.8505 15 12.1685 15 12.5C15 12.8315 14.8683 13.1495 14.6339 13.3839C14.3995 13.6183 14.0815 13.75 13.75 13.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1951_43439">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IcTaxi
