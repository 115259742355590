import React from 'react'
import { CardContainer } from 'components'
import { formatCurrency } from 'utils'

const PaymentReceiptSingleCard = ({ data }) => {
  const fieldFontStyle = 'font-normal text-gray-700'
  const valueFontStyle = 'font-normal text-gray-700'

  return (
    <CardContainer>
      <div className="flex flex-row w-full items-center justify-between border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Payment Receipt</span>
      </div>
      <div className="flex flex-col w-full pt-4 space-y-5">
        <div className="flex flex-col space-y-5 border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
          <div className="flex flex-col">
            <div className="flex flex-row items-start justify-between">
              <span className="font-normal text-gray-700 text-lg">{data.unitName}</span>
              <span className={valueFontStyle}>{formatCurrency(data.unitPrice)}</span>
            </div>
          </div>
          <div className="flex flex-row items-start justify-between">
            <span className={fieldFontStyle}>Biaya Admin</span>
            <span className={valueFontStyle}>{formatCurrency(data.adminPrice)}</span>
          </div>
          <div className="flex flex-row items-start justify-between">
            <span className={fieldFontStyle}>Pajak</span>
            <span className={valueFontStyle}>{formatCurrency(data.taxFee)}</span>
          </div>
        </div>
        <div className="flex flex-col space-y-5 border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
          <div className="flex flex-row items-start justify-between">
            <span className={fieldFontStyle}>Total</span>
            <span className={valueFontStyle}>{formatCurrency(data.totalPrice)}</span>
          </div>
        </div>
        <div className="flex flex-row items-start justify-between">
          <span className={fieldFontStyle}>Total Pembayaran</span>
          <span className="font-medium text-gray-900">{formatCurrency(data.totalPrice)}</span>
        </div>
      </div>
    </CardContainer>
  )
}

export default PaymentReceiptSingleCard
