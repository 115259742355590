import React from 'react'
import { Button, Select } from 'antd'
import { IcChevronLeft, IcChevronRight } from 'assets/icons'

const HistoryDetailComponent = ({
    data,
    page,
    pageSize,
    handlePerRowsChange,
    totalRows,
    onChangePrevPage,
    onChangeNextPage
}) => {
    return (
        <div>
            {data?.map((histori, idx) => (
                <div 
                    key={`histori-servis-${idx}`}
                    className='flex p-4 bg-gray-50 items-center rounded-lg mb-2'
                >
                    <div className='flex-1'>
                        <div className='flex items-center'>
                            <div className={`flex-1 font-semibold`}>{histori?.name}</div>
                            {histori?.resultLabel !== '' && <div className='flex-1'>{histori?.resultLabel}</div>}
                            <div className='flex-1'>Status</div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`flex-1 text-sm`}>{histori?.date}</div>
                            {histori?.resultValue !== '' && <div className='flex-1 text-primary-500 font-semibold'>{histori?.resultValue}</div>}
                            <div className='flex-1'>
                                {histori?.status}
                            </div>
                        </div>
                    </div>
                    <div 
                        className={'hover:cursor-pointer'}
                        onClick={histori?.onClick} 
                    >
                        <IcChevronRight
                            size={25}
                            className={'text-primary-500'}
                        />
                    </div>
                </div>
            ))}
            {data?.length > 0 && <div className="flex w-full flex-row items-center justify-end space-x-4 mt-4">
                <div className={`mr-4`}>
                    <label className="text-gray-700 mr-4">Tampilkan</label>
                    <Select
                        className="custom-table-limit"
                        style={{ width: '70px' }}
                        value={pageSize ?? 5}
                        onChange={handlePerRowsChange}
                    >
                        <Select.Option value={5}>5</Select.Option>
                        <Select.Option value={10}>10</Select.Option>
                        <Select.Option value={25}>25</Select.Option>
                        <Select.Option value={50}>50</Select.Option>
                        <Select.Option value={100}>100</Select.Option>
                    </Select>
                </div>
                <div className="flex flex-row">
                    {!isNaN(page) && (
                        <span className="font-medium text-gray-700">
                        {`${page + 1} - ${
                            page + pageSize >= totalRows ? totalRows : page + pageSize
                        } dari ${totalRows}`}
                        </span>
                    )}
                </div>
                <div className="flex flex-row space-x-3">
                    <Button
                        className="group p-0 flex border border-gray-300 bg-white justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
                        onClick={onChangePrevPage}
                    >
                        <IcChevronLeft className="text-gray-700 group-hover:text-primary-500" />
                    </Button>
                    <Button
                        className="group p-0 flex border border-gray-300 bg-white justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
                        onClick={onChangeNextPage}
                    >
                        <IcChevronRight className="text-gray-700 group-hover:text-primary-500" />
                    </Button>
                </div>
            </div>}
        </div>
    )
}

export default HistoryDetailComponent