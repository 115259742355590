import React, { useState } from 'react'
import { Breadcrumb, Card, DatePicker, Layout, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Table } from 'components'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const UiDynamicAnalyticsDashboard = ({
  columns,
  tableState,
  setTableState,
  typeTransaction,
  isLoading,
  workshop,
  type,
  units,
  sales
}) => {
  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const router = useHistory()

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates) {
      setTableState((prev) => ({
        ...prev,
        dateRangeValue: dates,
        start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('DD/MM/YYYY'),
        end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('DD/MM/YYYY'),
        offset: 0
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        dateRangeValue: [],
        start_date: '',
        end_date: '',
        offset: 0
      }))
    }
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  const onReturnBreadcrumbPage = () => {
    switch (type) {
      case 'total-penjualan':
        return 'Total Jual'
      case 'total-servis':
        return 'Total Servis'
      case 'total-inspeksi':
        return 'Total Inspeksi'
      case 'total-sales':
        return 'Total Sales'
      default:
        return ''
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Penjualan
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          {onReturnBreadcrumbPage()}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content className="h-full grid grid-cols-1 gap-6">
        {/* filter */}
        <div className="flex items-center gap-3">
          {type !== 'total-penjualan' && type !== 'total-servis' && type !== 'total-inspeksi' && (
            <Select
              showSearch
              allowClear
              placeholder="Semua Tipe Transaksi"
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
              style={{ minWidth: '200px' }}
              onSearch={(e) => {}}
              optionFilterProp="children"
              value={tableState?.type === '' ? null : tableState?.type}
              onChange={(e) => setTableState((prev) => ({ ...prev, type: e ?? '', offset: 0 }))}>
              {typeTransaction?.map((type, idx) => (
                <Select.Option key={idx} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          )}
          <Select
            showSearch
            allowClear
            placeholder="Semua Cabang"
            className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
            style={{ minWidth: '200px' }}
            onSearch={(e) => {}}
            optionFilterProp="children"
            value={tableState?.workshop_id === '' ? null : tableState?.workshop_id}
            onChange={(e) =>
              setTableState((prev) => ({ ...prev, workshop_id: e ?? '', offset: 0 }))
            }>
            {workshop?.map((bengkel, idx) => (
              <Select.Option key={idx} value={bengkel?.id}>
                {bengkel?.name}
              </Select.Option>
            ))}
          </Select>
          {type === 'total-sales' && (
            <>
              <Select
                showSearch
                allowClear
                placeholder="Semua Unit"
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                style={{ width: '200px' }}
                onSearch={(e) => {}}
                optionFilterProp="children"
                value={tableState.unit_name === '' ? null : tableState?.unit_name}
                onChange={(e) =>
                  setTableState((prev) => ({ ...prev, unit_name: e ?? '', offset: 0 }))
                }>
                {units?.map((unit, idx) => (
                  <Select.Option key={idx} value={unit}>
                    {unit}
                  </Select.Option>
                ))}
              </Select>
              <Select
                showSearch
                allowClear
                placeholder="Semua Sales"
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                style={{ width: '200px' }}
                onSearch={(e) => {}}
                optionFilterProp="children"
                value={tableState.payment_type === '' ? null : tableState?.payment_type}
                onChange={(e) =>
                  setTableState((prev) => ({ ...prev, payment_type: e ?? '', offset: 0 }))
                }>
                {sales?.map((item, idx) => (
                  <Select.Option key={idx} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </>
          )}
          <RangePicker
            onOpenChange={(open) => setIsShowDatePicker(open)}
            onChange={handleChangeRangePicker}
            format="DD/MM/YYYY"
            open={isShowDatePicker}
            value={tableState?.dateRangeValue}
            placeholder={['Tanggal Mulai', 'Tanggal Akhir']}
            className="min-w-1/4 rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500 py-3"
            renderExtraFooter={() => {
              return (
                <div className="flex flex-row justify-end items-center space-x-4 my-3">
                  <button
                    type="button"
                    className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-gray-700 bg-white"
                    onClick={() => {
                      setIsShowDatePicker(false)
                    }}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-white bg-primary-500"
                    onClick={() => {
                      setIsShowDatePicker(false)
                    }}>
                    Apply
                  </button>
                </div>
              )
            }}
          />
        </div>
        {/* content */}
        <Card className="rounded-lg">
          <div className="flex justify-between mb-5">
            <Typography.Text className="font-semibold text-xl">Daftar Transaksi</Typography.Text>
          </div>
          <Table
            columns={columns}
            data={tableState?.listData}
            pending={isLoading}
            totalRows={tableState?.totalRows}
            handlePerRowsChange={handlePerRowsChange}
            pageSize={tableState?.limit}
            page={tableState?.offset}
            onChangePrevPage={() => {
              if (tableState?.offset > 0) {
                onChangePage(tableState?.offset - tableState?.limit)
              }
            }}
            onChangeNextPage={() => {
              if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                onChangePage(tableState?.offset + tableState?.limit)
              }
            }}
          />
        </Card>
      </Content>
    </Layout>
  )
}

export default UiDynamicAnalyticsDashboard
