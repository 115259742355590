import { Breadcrumb, Layout } from 'antd'
import { apiGet, urls } from 'api'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import RilisKendaraanComponent from './components/RilisKendaraanComponent'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  workshop_id: '',
  listData: []
}

const RilisKendaraaan = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableStateReady, setTableStateReady] = useState(initialTableState)
  const [tableStateReleased, setTableStateReleased] = useState(initialTableState)
  const [workshop, setWorkshop] = useState([])
  const [tabs, setTabs] = useState([
    { name: 'Ready To Scan', active: true },
    { name: 'Released', active: false }
  ])

  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const columnsPaid = [
    {
      name: 'User',
      selector: (row) => row?.user_fullname,
      cell: (row) => row?.user_fullname
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => row?.unit_name
    },
    {
      name: 'Layanan',
      selector: (row) => row?.transaction_type,
      cell: (row) => row?.transaction_type
    },
    {
      name: 'Plat Nomor',
      selector: (row) => row?.unit_police_number,
      cell: (row) => row?.unit_police_number
    },
    {
      name: 'Cabang',
      selector: (row) => row?.workshop_name,
      cell: (row) => row?.workshop_name
    }
  ]

  const columnsReleased = [
    {
      name: 'ID User',
      selector: (row) => row?.user_id_string,
      cell: (row) => row?.user_id_string
    },
    {
      name: 'User',
      selector: (row) => row?.user_fullname,
      cell: (row) => row?.user_fullname
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => row?.unit_name
    },
    {
      name: 'Plat Nomor',
      selector: (row) => row?.unit_police_number,
      cell: (row) => row?.unit_police_number
    },
    {
      name: 'Bengkel',
      selector: (row) => row?.workshop_name,
      cell: (row) => row?.workshop_name
    },
    {
      name: 'Waktu Rilis',
      selector: (row) =>
        row?.released_at ? `${moment(row?.released_at).format('DD MMMM YYYY, HH:mm')}` : '',
      cell: (row) =>
        row?.released_at ? `${moment(row?.released_at).format('DD MMMM YYYY, HH:mm')}` : ''
    }
  ]

  // Get All List Paid
  const { isLoading: isLoadingPaid, refetch: refetchPaid } = useQuery({
    queryKey: [
      'paid-list',
      tableStateReady.offset,
      tableStateReady.limit,
      tableStateReady.keywords,
      tableStateReady.workshop_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.auth?.user?.prefix}/${urls?.auth?.user?.carRelease}?keywords=${tableStateReady.keywords}&limit=${tableStateReady?.limit}&offset=${tableStateReady?.offset}&workshop_id=${tableStateReady?.workshop_id}&status=paid`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableStateReady((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get All List released
  const { isLoading: isLoadingReleased, refetch: refetchReleased } = useQuery({
    queryKey: [
      'released-list',
      tableStateReleased.offset,
      tableStateReleased.limit,
      tableStateReleased.keywords,
      tableStateReleased.workshop_id
    ],
    refetchOnWindowFocus: false,
    enabled:
      !!tabs.find((item) => item.active === true).name === 'Released' ||
      !tableStateReleased ||
      !!tableStateReleased,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.auth?.user?.prefix}/${urls?.auth?.user?.carRelease}?keywords=${tableStateReleased.keywords}&limit=${tableStateReleased?.limit}&offset=${tableStateReleased?.offset}&workshop_id=${tableStateReleased?.workshop_id}&status=released`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableStateReleased((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Workshop
  useQuery({
    queryKey: ['workshop-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master.workshops.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setWorkshop(res?.resData?.data ?? [])
    }
  })

  const onChangeDataTable = (index) => {
    if (index === 0) {
      refetchPaid()
    }
    if (index === 1) {
      refetchReleased()
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Rilis Kendaraan
        </Breadcrumb.Item>
      </Breadcrumb>
      <RilisKendaraanComponent
        isLoading={isLoadingPaid ?? isLoadingReleased}
        columns={
          tabs.find((item) => item.active === true).name === 'Released'
            ? columnsReleased
            : columnsPaid
        }
        data={
          tabs.find((item) => item.active === true).name === 'Released'
            ? tableStateReleased.listData
            : tableStateReady?.listData
        }
        tableState={
          tabs.find((item) => item.active === true).name === 'Released'
            ? tableStateReleased
            : tableStateReady
        }
        setTableState={(prev) =>
          tabs.find((item) => item.active === true).name === 'Released'
            ? setTableStateReleased(prev)
            : setTableStateReady(prev)
        }
        workshops={workshop}
        tabs={tabs}
        onChangeTab={(index) => {
          onChangeDataTable(index)
          const tempArr = [...tabs]
          tempArr.map((itemCh, indexCh) => {
            if (index === indexCh) {
              if (itemCh.active) {
                return false
              } else {
                itemCh.active = true
              }
            } else {
              itemCh.active = false
            }
          })
          setTabs(tempArr)
        }}
      />
    </Layout>
  )
}
export default RilisKendaraaan
