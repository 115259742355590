import React from 'react'
import {
  CardContainer,
  CatatanCard,
  DocumentUnitCard,
  InformasiSpekUnitVerticalCard,
  InformasiUserCard,
  InspeksiCard,
  InspeksiCardNullify,
  PhotoCarouselAlternativeCard
} from 'components'
import _ from 'lodash'
import moment from 'moment'
import { formatThousandsINTL } from 'utils'

const BookedContent = ({ data, onPressDetailInspeksi = () => {} }) => {
  return !data ? (
    <CardContainer>
      <span className="font-medium text-lg text-gray-900">Data Detail Booked Tidak Ditemukan.</span>
    </CardContainer>
  ) : (
    <div className="flex flex-col w-full space-y-5">
      <InformasiUserCard
        title="Informasi Pemilik"
        data={{
          nama:
            data.owner_fullname && data.owner_fullname !== ''
              ? _.startCase(data.owner_fullname)
              : '-',
          email: data.owner_email && data.owner_email !== '' ? data.owner_email : '-',
          telepon:
            data.owner_phone_number && data.owner_phone_number !== ''
              ? data.owner_phone_number
              : '-',
          alamat:
            data.owner_address && data.owner_address !== '' ? _.startCase(data.owner_address) : '-'
        }}
      />
      <div className="grid grid-cols-2 w-full space-x-5 items-start justify-start">
        <InformasiSpekUnitVerticalCard
          data={{
            merek: data.brand_name,
            model: data.model_name,
            tipe: data.type_name,
            transmisi: data.transmission_name,
            platNomor: data.police_number && data.police_number !== '' ? data.police_number : '-',
            warna: data.color_name && data.color_name !== '' ? data.color_name : '-',
            bahanBakar: data.fuel_name && data.fuel_name !== '' ? data.fuel_name : '-',
            jarakTempuh:
              data.mileage && data.mileage !== '' ? `${formatThousandsINTL(data.mileage)} KM` : '-',
            nomorRangka: data.frame_number && data.frame_number !== '' ? data.frame_number : '-',
            nomorMesin: data.engine_number && data.engine_number !== '' ? data.engine_number : '-',
            tag:
              data.tags.length !== 0 || data.tags !== null
                ? data.tags.map((item) => item.name).join(' ')
                : '-'
          }}
        />
        <DocumentUnitCard
          data={[
            {
              name: 'STNK',
              value: data.document_stnk_status ? 'Ada' : 'Tidak Ada'
            },
            {
              name: 'No. STNK',
              value: data.document_stnk && data.document_stnk !== '' ? data.document_stnk : '-'
            },
            {
              name: 'BPKB',
              value: data.document_bpkb_status ? 'Ada' : 'Tidak ada'
            },
            {
              name: 'No. BPKB',
              value: data.document_bpkb && data.document_bpkb !== '' ? data.document_bpkb : '-'
            },
            {
              name: 'Faktur',
              value: data.document_faktur_status ? 'Ada' : 'Tidak ada'
            },
            {
              name: 'KTP Pemilik',
              value: data.document_ktp_status ? 'Ada' : 'Tidak ada'
            },
            {
              name: 'Kwitansi',
              value: data.document_kwitansi_status ? 'Ada' : 'Tidak ada'
            },
            {
              name: 'KEUR',
              value: data.document_keur_status ? 'Ada' : 'Tidak ada'
            },
            {
              name: 'No. KEUR',
              value: data.document_keur && data.document_keur !== '' ? data.document_keur : '-'
            },
            {
              name: 'Form A',
              value: data.document_form_a_status ? 'Ada' : 'Tidak ada'
            },
            {
              name: 'Pajak',
              value: data.tax_status ? 'Hidup' : 'Mati'
            },
            {
              name: 'Pajak Berlaku',
              value:
                data.tax_active_end && data.tax_active_end !== ''
                  ? moment(data.tax_active_end, 'YYYY-MM-DD').format('DD-MM-YYYY')
                  : '-'
            }
          ]}
        />
      </div>
      <CatatanCard
        desc={data.description && data.description !== '' ? data.description : 'Tidak ada catatan.'}
      />
      {data.is_inspected ? (
        <InspeksiCard data={data.inspection_result} onGoToDetailInspeksi={onPressDetailInspeksi} />
      ) : (
        <InspeksiCardNullify />
      )}
      <PhotoCarouselAlternativeCard data={data.photos} />
    </div>
  )
}

export default BookedContent
