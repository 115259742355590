import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { Content } from 'antd/lib/layout/layout'
import { InformasiPenjualanSection } from './components'
import { dummyTransaksiCabang, unitDataDummies } from 'assets/dummies'
import { CatatanSection, InformasiUnitSection } from 'pages/main/jual-unit/components'
import { HeaderSO, PaymentReceiptCard } from 'components'

const KatalogSOPage = () => {
  const { unit } = useParams()
  const router = useHistory()
  const [units, setUnits] = useState(unitDataDummies.find((item) => item.id === parseInt(0)))
  const [payments, setPayments] = useState(dummyTransaksiCabang[2].paymentDetail)

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Katalog</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">{unit}</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">SO</Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <div className="flex flex-col w-full space-y-5">
          <HeaderSO onPrintSO={() => router.push('/jual-unit/katalog/sales-order-print')} />
          <InformasiPenjualanSection
            penjual="Jude Bellingham"
            pembeli="Erling Haland"
            alamatShowroom="Jln. Bunga Melati No.14, Jakarta"
            harga={300000000}
          />
          <InformasiUnitSection data={units} />
          <CatatanSection desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc." />
          <PaymentReceiptCard data={payments} />
        </div>
      </Content>
    </Layout>
  )
}

export default KatalogSOPage
