import React, { useCallback, useState } from 'react'
import { Breadcrumb, Input, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { CardContainer, HeaderContainer, Table } from 'components'
import { IcEdit, IcSearch, IcTrash } from 'assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import ModalUpdateCYOCUnit from '../components/ModalUpdateCYOCUnit'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'
import _ from 'lodash'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null,
  brand_id: '',
  model_id: '',
  type_id: ''
}

const CreateYourOwnCarDaftarUnitPage = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [tableState, setTableState] = useState(initialTableState)
  const [searchSelect, setSearchSelect] = useState({
    brand: '',
    model: '',
    type: ''
  })
  const [modal, setModal] = useState({
    show: false,
    data: null,
    type: 'add'
  })

  //* columns table
  const columns = [
    {
      name: 'Brand',
      selector: (row) => row.brand_name
    },
    {
      name: 'Model',
      selector: (row) => row.model_name
    },
    {
      name: 'Tipe',
      selector: (row) => row.type_name
    },
    {
      name: 'Create Your Own Car',
      selector: (row) => (row.create_your_own_car ? 'Tersedia' : 'Tidak Tersedia')
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              setModal({
                show: true,
                type: 'edit',
                data: row
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setModal({
                show: true,
                type: 'delete',
                data: row
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  const brandList = useQuery({
    queryKey: ['cyoc-brand-unit'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `master-service/admin/brands/all`,
        onLogout: () => dispatch(logout())
      })
      return res
    }
  })

  const modelList = useQuery({
    queryKey: ['cyoc-model-unit', tableState.brand_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let params = {
        brand_id: tableState.brand_id
      }
      const res = await apiGet({
        token,
        data: params,
        url: `master-service/admin/models/all`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !brandList.isLoading
  })

  const typeList = useQuery({
    queryKey: ['cyoc-type-unit', tableState.brand_id, tableState.model_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let params = {
        brand_id: tableState.brand_id,
        model_id: tableState.model_id
      }
      const res = await apiGet({
        token,
        data: params,
        url: `master-service/admin/types/all`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !brandList.isLoading && !modelList.isLoading
  })

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'cyoc-sparepart-uni-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.brand_id,
      tableState.model_id,
      tableState.type_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order,
        brand_id: tableState.brand_id,
        model_id: tableState.model_id,
        type_id: tableState.type_id
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'jual-beli-service/admin/create-your-own-car/unit-list',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const onCheckFieldEmpty = (data) => {
    return Object.entries(data)
      .filter(
        (itemPr) =>
          itemPr[0] !== 'brand_name' && itemPr[0] !== 'model_name' && itemPr[0] !== 'type_name'
      )
      .every((item) => {
        if (item[1] !== null && item[1] !== undefined && item[1] !== '') {
          return true
        } else {
          if (item[0] === 'brand_id') {
            showErrorMessage({ content: 'Silahkan Pilih Field Brand' })
          }
          if (item[0] === 'model_id') {
            showErrorMessage({ content: 'Silahkan Pilih Field Model' })
          }
          if (item[0] === 'type_id') {
            showErrorMessage({ content: 'Silahkan Pilih Field Type' })
          }
          return false
        }
      })
  }

  const saveData = async (data) => {
    if (onCheckFieldEmpty(data)) {
      try {
        let res
        let body = {
          ...data
        }
        if (modal.type === 'add') {
          res = await apiPost({
            url: `jual-beli-service/admin/create-your-own-car/unit-list`,
            token,
            data: body,
            onLogout: () => dispatch(logout())
          })
        } else {
          res = await apiPut({
            url: `jual-beli-service/admin/create-your-own-car/unit-list/${data.id}`,
            token,
            data: body,
            onLogout: () => dispatch(logout())
          })
        }
        if (res?.resStatus === 200 || res?.resStatus === 201) {
          showSuccessMessage({
            content: `${
              res.resData.message ??
              `Successfully ${modal.type === 'add' ? 'Create' : 'Edit'} Unit.`
            }`
          })
          setModal({
            ...modal,
            show: false
          })
        } else {
          showErrorMessage({
            content: `${
              res.resData.errors[0].message ??
              `Failed to ${modal.type === 'add' ? 'Create' : 'Edit'} Unit.`
            }`
          })
        }
        refetch()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        url: `jual-beli-service/admin/create-your-own-car/unit-list/${data?.id}`,
        token
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({
          content: `${res.resData.message ?? 'Successfully Delete Unit.'}`
        })
        setModal({
          ...modal,
          show: false
        })
      } else {
        showErrorMessage({
          content: `${res.resData.errors[0].message ?? 'Failed to Delete Unit.'}`
        })
      }
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  const onChangeSelect = (key, value) => {
    setTableState((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }

  const onChangeSearchSelect = (key, value) => {
    setSearchSelect((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Create Your Own Car
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Daftar Unit
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <ModalUpdateCYOCUnit
          isOpen={modal.show}
          typeButton={modal.type}
          data={modal.data}
          onCancel={() => setModal({ ...modal, show: false })}
          onSubmit={saveData}
          onDelete={deleteData}
        />
        <CardContainer>
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer
              title="Daftar Unit"
              isPressAdd
              titleAdd="Tambah Unit"
              onPressAdd={() =>
                setModal({
                  show: true,
                  type: 'add',
                  data: null
                })
              }
            />
            <div className="mb-5 flex justify-between">
              <div className="flex">
                <Select
                  showSearch
                  allowClear
                  className="custom-table-limit mr-3"
                  style={{ width: '150px' }}
                  value={tableState?.brand_id}
                  onSearch={(e) => onChangeSearchSelect('brand_id', e)}
                  onChange={(e) => onChangeSelect('brand_id', e ?? '')}
                  filterOption={(input, option) => {
                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                  }}>
                  <Select.Option className="custom-table-limit-option" value="">
                    Semua Brand
                  </Select.Option>
                  {brandList?.data?.resData?.data?.map((item) => (
                    <Select.Option key={`b-${item?.id?.toString()}`} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  showSearch
                  allowClear
                  className="custom-table-limit mr-3"
                  style={{ width: '150px' }}
                  value={tableState?.model_id}
                  onSearch={(e) => onChangeSearchSelect('model_id', e)}
                  onChange={(e) => onChangeSelect('model_id', e ?? '')}
                  filterOption={(input, option) => {
                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                  }}>
                  <Select.Option className="custom-table-limit-option" value="">
                    Semua Model
                  </Select.Option>
                  {modelList?.data?.resData?.data?.map((item) => (
                    <Select.Option key={`b-${item?.id?.toString()}`} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  showSearch
                  allowClear
                  className="custom-table-limit mr-3"
                  style={{ width: '150px' }}
                  value={tableState?.type_id}
                  onSearch={(e) => onChangeSearchSelect('type_id', e)}
                  onChange={(e) => onChangeSelect('type_id', e ?? '')}
                  filterOption={(input, option) => {
                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                  }}>
                  <Select.Option className="custom-table-limit-option" value="">
                    Semua Type
                  </Select.Option>
                  {typeList?.data?.resData?.data?.map((item) => (
                    <Select.Option key={`b-${item?.id?.toString()}`} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Input
                className="w-1/4 rounded-md"
                placeholder="Pencarian"
                prefix={<IcSearch className="text-gray-400" />}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <Table
              columns={columns}
              data={tableState.listData}
              pending={isLoading}
              onSort={handleSort}
              totalRows={tableState?.totalRows}
              handlePerRowsChange={handlePerRowsChange}
              pageSize={tableState?.limit}
              page={tableState?.offset}
              onChangePrevPage={() => {
                if (tableState?.offset > 0) {
                  onChangePage(tableState?.offset - tableState?.limit)
                }
              }}
              onChangeNextPage={() => {
                if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                  onChangePage(tableState?.offset + tableState?.limit)
                }
              }}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default CreateYourOwnCarDaftarUnitPage
