import React, { useCallback, useState } from 'react'
import { Card, DatePicker, Input, Radio, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button, PhotoContainerCard, PhotoMultipleCard, PhotoThumbnailCard } from 'components'
import { IcEdit } from 'assets/icons'
import { useHistory } from 'react-router-dom'
import { validationInput } from 'helpers'
import moment from 'moment'

const AddInternalUnitComponent = ({
  forms,
  setForms,
  setSearch,
  provinces,
  cities,
  subDistricts,
  locations,
  brandList,
  modelList,
  transmissionList,
  fuelList,
  typeList,
  branchList,
  companyList,
  colorList,
  saveData,
  handleAddPhoto,
  handleDeletePhoto
}) => {
  const [isEdit, setEdit] = useState(false)
  const router = useHistory()

  const handleForms = useCallback((key, value) => {
    if (key === 'production_year') {
      setForms((prevState) => ({
        ...prevState,
        [key]: {
          parse: value && value !== '' ? moment(value, 'YYYY').format('YYYY') : '',
          value: value
        }
      }))
    } else {
      setForms((prevState) => ({
        ...prevState,
        [key]: value
      }))
    }
  }, [])

  const handleSearch = useCallback((key, value) => {
    setSearch((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }, [])

  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-lg">Informasi Pemilik</Typography.Text>
        </div>
        <Typography.Text className="font-medium">
          Nama Lengkap<span className="text-primary-500">*</span>
        </Typography.Text>
        <Input
          className="rounded-md mt-1 mb-3"
          placeholder="Nama Lengkap"
          value={forms?.name}
          onChange={(e) => handleForms('name', e.target.value)}
        />
        <Typography.Text className="font-medium">
          Email<span className="text-primary-500">*</span>
        </Typography.Text>
        <Input
          className="rounded-md mt-1 mb-3"
          placeholder="Email"
          value={forms?.email}
          onChange={(e) => handleForms('email', e.target.value)}
        />
        <Typography.Text className="font-medium">
          Nomor Telepon<span className="text-primary-500">*</span>
        </Typography.Text>
        <Input
          className="rounded-md mt-1 mb-3"
          placeholder="Nomor Telepon"
          value={forms?.phone_number}
          onChange={(e) => handleForms('phone_number', e.target.value)}
        />
        <Typography.Text className="font-medium">
          Alamat<span className="text-primary-500">*</span>
        </Typography.Text>
        <Input
          className="rounded-md mt-1 mb-3"
          placeholder="Alamat"
          value={forms?.address}
          onChange={(e) => handleForms('address', e.target.value)}
        />
        <div className="mb-3">
          <Typography.Text className="font-medium mb-2">
            Provinsi<span className="text-primary-500">*</span>
          </Typography.Text>
          <Select
            showSearch
            className="custom-table-limit"
            style={{ width: '100%' }}
            placeholder="Pilih Provinsi"
            onChange={(value) => {
              if (forms?.province_id !== value) {
                handleForms('city_id', null)
                handleForms('city_name', '')
                handleForms('sub_district_id', null)
                handleForms('sub_district_name', '')
                handleForms('location_id', null)
                handleForms('location_name', '')
              }
              handleForms('province_id', value)
              const find = provinces?.findIndex((item) => item.id === value)
              handleForms('province_name', provinces[find]?.name)
            }}
            // onSearch={(value) => handleSearch('brand', value)}
            value={forms?.province_id}
            options={provinces?.map((province) => {
              return {
                value: province.id,
                label: province.name
              }
            })}
            filterOption={(input, option) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </div>
        <div className="mb-3">
          <Typography.Text className="font-medium mb-2">
            Kota/Kabupaten<span className="text-primary-500">*</span>
          </Typography.Text>
          <Select
            showSearch
            disabled={!forms?.province_id}
            className="custom-table-limit"
            style={{ width: '100%' }}
            placeholder="Pilih Kota/Kabupaten"
            onChange={(value) => {
              if (forms?.city_id !== value) {
                handleForms('sub_district_id', null)
                handleForms('sub_district_name', '')
                handleForms('location_id', null)
                handleForms('location_name', '')
              }
              handleForms('city_id', value)
              const find = cities?.findIndex((item) => item.id === value)
              handleForms('city_name', cities[find]?.name)
            }}
            // onSearch={(value) => handleSearch('brand', value)}
            value={forms?.city_id}
            options={cities?.map((city) => {
              return {
                value: city.id,
                label: city.name
              }
            })}
            filterOption={(input, option) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </div>
        <div className="mb-3">
          <Typography.Text className="font-medium mb-2">
            Kecamatan<span className="text-primary-500">*</span>
          </Typography.Text>
          <Select
            showSearch
            disabled={!forms?.city_id}
            className="custom-table-limit"
            style={{ width: '100%' }}
            placeholder="Pilih Kecamatan"
            onChange={(value) => {
              if (forms?.sub_district_id !== value) {
                handleForms('location_id', null)
                handleForms('location_name', '')
              }
              handleForms('sub_district_id', value)
              const find = subDistricts?.findIndex((item) => item.id === value)
              handleForms('sub_district_name', subDistricts[find]?.name)
            }}
            // onSearch={(value) => handleSearch('brand', value)}
            value={forms?.sub_district_id}
            options={subDistricts?.map((item) => {
              return {
                value: item.id,
                label: item.name
              }
            })}
            filterOption={(input, option) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </div>
        <div className="mb-3">
          <Typography.Text className="font-medium mb-2">
            Kelurahan/Desa<span className="text-primary-500">*</span>
          </Typography.Text>
          <Select
            showSearch
            disabled={!forms?.sub_district_id}
            className="custom-table-limit"
            style={{ width: '100%' }}
            placeholder="Pilih Kelurahan/Desa"
            onChange={(value) => {
              handleForms('location_id', value)
              const find = locations?.findIndex((item) => item.id === value)
              handleForms('location_name', locations[find]?.name)
            }}
            // onSearch={(value) => handleSearch('brand', value)}
            value={forms?.location_id}
            options={locations?.map((item) => {
              return {
                value: item.id,
                label: item.name
              }
            })}
            filterOption={(input, option) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
          {/* {locations?.map((item) => (
              <Select.Option key={`location-${item?.id?.toString()}`} value={item.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select> */}
        </div>
        <Typography.Text className="font-medium">
          Kode Pos<span className="text-primary-500">*</span>
        </Typography.Text>
        <Input
          className="rounded-md mt-1 mb-3"
          placeholder="Kode Pos"
          value={forms?.postal_code}
          onChange={(e) => handleForms('postal_code', e.target.value)}
        />
      </Card>

      {/*
       //* Hide Informasi Payment
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-lg">Informasi Payment</Typography.Text>
        </div>
        <Typography.Text className="font-medium">Metode Pembayaran</Typography.Text>
        <Select
          showSearch
          className="custom-table-limit"
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Metode Pembayaran"
          onChange={(value) => {}}
          onSearch={(value) => handleSearch('brand', value)}
          value={forms?.brand_id}
          filterOption={false}
        >
          {brandList?.map((brand) => (
            <Select.Option key={`brand-${brand?.id?.toString()}`} value={brand.id}>
              {brand?.name}
            </Select.Option>
          ))}
        </Select>
        <Typography.Text className="font-medium">Bank</Typography.Text>
        <Select
          showSearch
          className="custom-table-limit"
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Bank"
          onChange={(value) => {}}
          onSearch={(value) => handleSearch('brand', value)}
          value={forms?.brand_id}
          filterOption={false}
        >
          {brandList?.map((brand) => (
            <Select.Option key={`brand-${brand?.id?.toString()}`} value={brand.id}>
              {brand?.name}
            </Select.Option>
          ))}
        </Select>
        <Typography.Text className="font-medium">Tanggal Pembayaran</Typography.Text>
        <DatePicker
          className="custom-date-picker py-[6px] w-full"
          style={{ marginBottom: 15, marginTop: 3 }}
          format={['DD/MM/YYYY']}
          placeholder={'Tanggal Pembayaran'}
          onChange={onChangeDate}
        />
        <div className="relative flex-none w-60 space-y-2 mb-5">
          <div>
            <span className="font-medium block">Upload Document</span>
          </div>
          <UploadImage
            containerClassName={'mr-4'}
            onChangeFile={(e) => handleForms('document_payment', e)}
            file={forms?.thumbnail}
            container="flex flex-col w-full h-44 bg-gray-100 rounded-lg justify-center hover:cursor-pointer"
            customDefault={
              <div className="flex flex-col space-y-2 items-center">
                <IcImage className="text-gray-400" />
                <span className="font-normal text-gray-400">Tambah Gambar</span>
              </div>
            }
            oldFile={
              currentImage?.thumbnail
                ? `${process.env.REACT_APP_IMAGE_URL_GCS}/${currentImage?.thumbnail}`
                : null
            }
          />
          <div>
            <span className="font-medium text-gray-500">
              Ukuran gambar 16:9, ukuran file maksimal 10mb, dengan format file jpg, jpeg, png.
            </span>
          </div>
        </div>
      </Card> */}

      {/* 
      //* Hide Informasi Biaya
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-lg">Rincian Biaya</Typography.Text>
        </div>
        <div className="bg-gray-50 flex flex-row py-2 mb-2">
          <Typography.Text className="font-medium text-md text-gray-500 w-[200px] mr-4">
            Nama Biaya
          </Typography.Text>
          <Typography.Text className="font-medium text-md text-gray-500 flex-1">
            Harga
          </Typography.Text>
        </div>
        <div className="flex flex-row mb-2">
          <Input
            readOnly
            className="rounded-md mt-1 w-[200px] mr-4 bg-gray-50"
            placeholder="Kode Pos"
            value={'Biaya Unit'}
          />
          <Input
            prefix="Rp. "
            className="rounded-md mt-1 flex-1"
            placeholder="Kode Pos"
            value={'100.0000.0000'}
          />
        </div>
        <div className="flex flex-row mb-2">
          <Input
            readOnly
            className="rounded-md mt-1 w-[200px] mr-4 bg-gray-50"
            placeholder="Kode Pos"
            value={'Pajak STNK'}
          />
          <Input
            prefix="Rp. "
            className="rounded-md mt-1 flex-1"
            placeholder="Kode Pos"
            value={'100.0000.0000'}
          />
        </div>
        <div className="flex flex-row mb-2">
          <Input
            readOnly
            className="rounded-md mt-1 w-[200px] mr-4 bg-gray-50"
            placeholder="Kode Pos"
            value={'Balik Nama'}
          />
          <Input
            prefix="Rp. "
            className="rounded-md mt-1 flex-1"
            placeholder="Kode Pos"
            value={'100.0000.0000'}
          />
        </div>
        {forms?.biaya.map((biaya, idx) => (
          <div key={idx.toString()} className="flex flex-row mb-2 items-center">
            <Input
              className="rounded-md mt-1 w-[200px] mr-4"
              placeholder="Nama Biaya"
              value={biaya?.label}
              onChange={(e) => {
                let newBiaya = {
                  ...biaya,
                  label: e.target.value
                }
                let newFormsBiaya = [...(forms?.biaya ?? [])]
                newFormsBiaya[idx] = newBiaya
                handleForms('biaya', newFormsBiaya)
              }}
            />
            <Input
              prefix="Rp. "
              className="rounded-md mt-1 flex-1"
              placeholder="Harga"
              value={biaya?.value}
              onChange={({ target }) => {
                let newValue = target.value?.split('.').join('')
                if (isNaN(newValue)) {
                  newValue = biaya?.value?.split('.').join('')
                }
                let { valid } = validationInput(newValue, { isNumeric: true })
                if (!valid) {
                  newValue = biaya?.value?.split('.').join('')
                }
                let newBiaya = {
                  ...biaya,
                  value: new Intl.NumberFormat('en-DE').format(newValue)
                }
                let newFormsBiaya = [...(forms?.biaya ?? [])]
                newFormsBiaya[idx] = newBiaya
                handleForms('biaya', newFormsBiaya)
              }}
            />
            <div
              className={`self-center flex items-center justify-center h-[16px] w-[16px] 
                border-gray-400 rounded-full border-solid mr-1 ml-3 overflow-hidden
                hover:border-primary-500 hover:cursor-pointer group
              `}
              onClick={() => {
                let newFormsBiaya = [...(forms?.biaya ?? [])]
                newFormsBiaya.splice(idx, 1)
                handleForms('biaya', newFormsBiaya)
              }}>
              <IcClose size={14} className={'text-gray-400 group-hover:text-primary-500'} />
            </div>
          </div>
        ))}
        <Button
          className={`w-full py-1.5 border-primary-200 bg-primary-50 hover:bg-primary-100`}
          onClick={() => {
            let newBiaya = [...(forms?.biaya ?? [])]
            newBiaya.push({
              key: '',
              value: ''
            })
            handleForms('biaya', newBiaya)
          }}>
          <div className="flex items-center justify-center">
            <IcPlus size={14} className="text-primary-500" />
            <Typography.Text className="text-primary-500 text-sm">
              Tambah Rincian Biaya
            </Typography.Text>
          </div>
        </Button>
      </Card> */}
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-lg">Informasi Unit</Typography.Text>
        </div>
        <Typography.Text className="font-medium">
          Pabrikan<span className="text-primary-500">*</span>
        </Typography.Text>
        <Select
          showSearch
          className="custom-table-limit"
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Pabrikan"
          onChange={(value) => {
            if (forms?.brand_id !== value) {
              handleForms('brand_id', value)
              const findBrand = brandList?.findIndex((brand) => brand.id === value)
              handleForms('brand_name', brandList[findBrand]?.name)
              handleForms('model_id', null)
              handleForms('model_name', '')
            }
          }}
          value={forms?.brand_id}
          options={brandList?.map((brand) => {
            return {
              value: brand.id,
              label: brand.name
            }
          })}
          filterOption={(input, option) => {
            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }}></Select>
        <Typography.Text className="font-medium">
          Model<span className="text-primary-500">*</span>
        </Typography.Text>
        <Select
          showSearch
          className="custom-table-limit"
          disabled={!forms?.brand_id}
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Model"
          onChange={(value) => {
            handleForms('model_id', value)
            const findModel = modelList?.findIndex((model) => model.id === value)
            handleForms('model_name', modelList[findModel]?.name)
          }}
          value={forms?.model_id}
          filterOption={(input, option) => {
            return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }}>
          {modelList?.map((model) => (
            <Select.Option key={`model-${model?.id?.toString()}`} value={model.id}>
              {model?.name}
            </Select.Option>
          ))}
        </Select>
        <Typography.Text className="font-medium">
          Tipe<span className="text-primary-500">*</span>
        </Typography.Text>
        <Select
          showSearch
          className="custom-table-limit"
          disabled={!forms?.model_id}
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Tipe"
          onChange={(value) => {
            handleForms('type_id', value)
            const findType = typeList?.findIndex((type) => type.id === value)
            handleForms('type_name', typeList[findType]?.name)
          }}
          value={forms?.type_id}
          filterOption={(input, option) => {
            return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }}>
          {typeList?.map((type) => (
            <Select.Option key={`type-${type?.id?.toString()}`} value={type.id}>
              {type?.name}
            </Select.Option>
          ))}
        </Select>
        <div className="mb-4">
          <Typography.Text className="mb-2">
            Tahun Keluaran
            <span className="text-primary-500">*</span>
          </Typography.Text>
          <DatePicker
            placeholder="Pilih Tahun Keluaran"
            picker="year"
            value={forms.production_year.parse === '' ? '' : forms.production_year.value}
            onChange={(value) => handleForms('production_year', value)}
            className="custom-date-picker py-1.5 w-full"
            popupClassName="custom-date-picker-dropdown"
            format={['YYYY']}
          />
        </div>
        {/* <Typography.Text className="font-medium">
          Tahun Keluaran<span className="text-primary-500">*</span>
        </Typography.Text>
        <Input
          className="rounded-md mt-1 mb-3"
          placeholder="Tahun Keluaran"
          value={forms?.production_year}
          onChange={(e) => handleForms('production_year', e.target.value)}
        /> */}
        <Typography.Text className="font-medium">
          Warna<span className="text-primary-500">*</span>
        </Typography.Text>
        <Select
          showSearch
          className="custom-table-limit"
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Warna"
          onChange={(value) => {
            handleForms('color_id', value)
            const findColor = colorList?.findIndex((color) => color.id === value)
            handleForms('color_name', fuelList[findColor]?.name)
          }}
          value={forms?.color_id}
          onSearch={(value) => handleSearch('color', value)}
          filterOption={false}>
          {colorList?.map((color) => (
            <Select.Option key={`color-${color?.id?.toString()}`} value={color.id}>
              {color?.name}
            </Select.Option>
          ))}
        </Select>
        <Typography.Text className="font-medium">
          Transmisi<span className="text-primary-500">*</span>
        </Typography.Text>
        <Select
          className="custom-table-limit"
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Transmisi"
          onChange={(value) => {
            handleForms('transmission_id', value)
            const findTrans = transmissionList?.findIndex((trans) => trans.id === value)
            handleForms('transmission_name', transmissionList[findTrans]?.name)
          }}
          value={forms?.transmission_id}
          showSearch
          filterOption={(input, option) => {
            return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }}>
          {transmissionList?.map((transmission) => (
            <Select.Option
              key={`transmission-${transmission?.id?.toString()}`}
              value={transmission.id}>
              {transmission?.name}
            </Select.Option>
          ))}
        </Select>
        <Typography.Text className="font-medium">
          Bahan Bakar<span className="text-primary-500">*</span>
        </Typography.Text>
        <Select
          className="custom-table-limit"
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Bahan Bakar"
          onChange={(value) => {
            handleForms('fuel_id', value)
            const findFuel = fuelList?.findIndex((fuel) => fuel.id === value)
            handleForms('fuel_name', fuelList[findFuel]?.name)
          }}
          value={forms?.fuel_id}
          filterOption={(input, option) => {
            return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }}
          showSearch>
          {fuelList?.map((fuel) => (
            <Select.Option key={`fuel-${fuel?.id?.toString()}`} value={fuel.id}>
              {fuel?.name}
            </Select.Option>
          ))}
        </Select>
        <Typography.Text className="font-medium">
          Cabang<span className="text-primary-500">*</span>
        </Typography.Text>
        <Select
          showSearch
          className="custom-table-limit"
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Cabang"
          onChange={(value) => {
            handleForms('branch_id', value)
            const findBranch = branchList?.findIndex((branch) => branch.id === value)
            handleForms('branch_name', branchList[findBranch]?.name)
          }}
          value={forms?.branch_id}
          onSearch={(value) => handleSearch('branch', value)}
          filterOption={false}>
          {branchList?.map((branch) => (
            <Select.Option key={`branch-${branch?.id?.toString()}`} value={branch.id}>
              {branch?.name}
            </Select.Option>
          ))}
        </Select>
        <Typography.Text className="font-medium">
          Plat Nomor<span className="text-primary-500">*</span>
        </Typography.Text>
        <Input
          className="rounded-md mt-1 mb-3"
          placeholder="Plat Nomor"
          value={forms?.police_number}
          onChange={(e) => handleForms('police_number', e.target.value)}
        />
        <Typography.Text className="font-medium">
          Nomor Mesin<span className="text-primary-500">*</span>
        </Typography.Text>
        <Input
          className="rounded-md mt-1 mb-3"
          placeholder="Nomor Mesin"
          value={forms?.engine_number}
          onChange={(e) => handleForms('engine_number', e.target.value)}
        />
        <Typography.Text className="font-medium">
          Nomor Rangka<span className="text-primary-500">*</span>
        </Typography.Text>
        <Input
          className="rounded-md mt-1 mb-3"
          placeholder="Nomor Rangka"
          value={forms?.frame_number}
          onChange={(e) => handleForms('frame_number', e.target.value)}
        />
        {/* <Typography.Text className="font-medium">
          Pilih Workshop<span className="text-primary-500">*</span>
        </Typography.Text> */}
        {/* <Select
          showSearch
          className="custom-table-limit"
          style={{ width: '100%', marginBottom: 10, marginTop: 3 }}
          placeholder="Pilih Workshop"
          onChange={(value) => {
            handleForms('company_id', value)
            const findComp = companyList?.findIndex((comp) => comp.id === value)
            handleForms('company_name', branchList[findComp]?.name)
          }}
          value={forms?.company_id}
          onSearch={(value) => handleSearch('company', value)}
          filterOption={false}>
          {companyList?.map((comp) => (
            <Select.Option key={`comp-${comp?.id?.toString()}`} value={comp.id}>
              {comp?.name}
            </Select.Option>
          ))}
        </Select> */}
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-lg">Dokumen</Typography.Text>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex-1 pr-4 border-solid border-0 border-r border-gray-300">
            <div className="flex justify-between items-center mb-2">
              <label>
                STNK<span className="text-primary-500">*</span>
              </label>
              <Radio.Group
                className="custom-radio-btn"
                name="radiogroup"
                value={forms?.document_stnk_status}
                onChange={(e) => handleForms('document_stnk_status', e.target.value)}>
                <Radio value={true}>ADA</Radio>
                <Radio value={false}>TIDAK ADA</Radio>
              </Radio.Group>
            </div>
            <div className="flex justify-between items-center mb-2">
              <label>
                BPKB<span className="text-primary-500">*</span>
              </label>
              <Radio.Group
                className="custom-radio-btn"
                name="radiogroup"
                value={forms?.document_bpkb_status}
                onChange={(e) => handleForms('document_bpkb_status', e.target.value)}>
                <Radio value={true}>ADA</Radio>
                <Radio value={false}>TIDAK ADA</Radio>
              </Radio.Group>
            </div>
            <div className="flex justify-between items-center mb-2">
              <label>
                Faktur<span className="text-primary-500">*</span>
              </label>
              <Radio.Group
                className="custom-radio-btn"
                name="radiogroup"
                value={forms?.document_faktur_status}
                onChange={(e) => handleForms('document_faktur_status', e.target.value)}>
                <Radio value={true}>ADA</Radio>
                <Radio value={false}>TIDAK ADA</Radio>
              </Radio.Group>
            </div>
            <div className="flex justify-between items-center">
              <label>
                KTP Pemilik<span className="text-primary-500">*</span>
              </label>
              <Radio.Group
                className="custom-radio-btn"
                name="radiogroup"
                value={forms?.document_ktp_status}
                onChange={(e) => handleForms('document_ktp_status', e.target.value)}>
                <Radio value={true}>ADA</Radio>
                <Radio value={false}>TIDAK ADA</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="flex-1 ml-2 flex flex-col self-stretch justify-between">
            <div className="flex-1 flex justify-between">
              <label>
                Kwitansi<span className="text-primary-500">*</span>
              </label>
              <Radio.Group
                className="custom-radio-btn"
                name="radiogroup"
                value={forms?.document_kwitansi_status}
                onChange={(e) => handleForms('document_kwitansi_status', e.target.value)}>
                <Radio value={true}>ADA</Radio>
                <Radio value={false}>TIDAK ADA</Radio>
              </Radio.Group>
            </div>
            <div className="flex-1 flex justify-between items-center">
              <label>
                KEUR<span className="text-primary-500">*</span>
              </label>
              <Radio.Group
                className="custom-radio-btn"
                name="radiogroup"
                value={forms?.document_keur_status}
                onChange={(e) => handleForms('document_keur_status', e.target.value)}>
                <Radio value={true}>ADA</Radio>
                <Radio value={false}>TIDAK ADA</Radio>
              </Radio.Group>
            </div>
            <div className="flex-1 flex justify-between items-end">
              <label>
                Form A<span className="text-primary-500">*</span>
              </label>
              <Radio.Group
                className="custom-radio-btn"
                name="radiogroup"
                value={forms?.document_form_status}
                onChange={(e) => handleForms('document_form_status', e.target.value)}>
                <Radio value={true}>ADA</Radio>
                <Radio value={false}>TIDAK ADA</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </Card>
      <PhotoContainerCard>
        <PhotoThumbnailCard
          isRequired={true}
          photoDisplay="object-contain"
          height={forms?.thumbnail.blob ? 'h-full' : undefined}
          photoThumbnail={forms?.thumbnail.blob ? forms.thumbnail.blob : null}
          onChangePhotoThumbnail={({ file, blob }) => {
            handleForms('thumbnail', { file, blob })
          }}
        />
        <PhotoMultipleCard
          isRequired={false}
          isDeleteById={true}
          data={forms.photos}
          onAddPhoto={(value) => handleAddPhoto(value)}
          onDeletePhoto={(index, id, item) => handleDeletePhoto(item)}
        />
      </PhotoContainerCard>
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300 flex justify-between items-center">
          <Typography.Text className="font-semibold text-lg">Catatan</Typography.Text>
          <Button
            className="bg-white flex items-center border-gray-300"
            onClick={() => setEdit(true)}>
            <IcEdit className="text-gray-700" size={20} />
            <span className="ml-2 font-medium text-gray-700">Edit</span>
          </Button>
        </div>
        {isEdit && (
          <Input.TextArea
            className={`rounded`}
            placeholder="Masukan Catatan"
            value={forms.description}
            onChange={(e) => handleForms('description', e.target.value)}
          />
        )}
        {!isEdit && (
          <Typography.Paragraph>
            {forms?.description ? forms?.description : 'Tidak ada catatan'}
          </Typography.Paragraph>
        )}
      </Card>
      <div className="flex justify-end items-center mb-1">
        <Button
          className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => router.go(-1)}>
          Kembali
        </Button>
        <Button
          className="p-0 px-4 py-2 bg-primary-500 border font-medium text-white hover:opacity-90"
          onClick={saveData}>
          Submit
        </Button>
      </div>
    </Content>
  )
}

export default AddInternalUnitComponent
