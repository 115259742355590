import { Input, Modal, Typography } from 'antd'
import { useFormik } from 'formik'
import { validationInput } from 'helpers'

const ModalCreateUpdateUser = ({
  type,
  isOpen,
  onCancel,
  onSubmit,
  user,
  title,
  idWorkshop,
  isLoading = false
}) => {
  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.fullname, { isRequired: true })?.valid) {
      errors.fullname = validationInput(values?.fullname, { isRequired: true })?.messageError
    }
    if (!validationInput(values?.email, { isRequired: true, isEmail: true })?.valid) {
      errors.email = validationInput(values?.email, {
        isRequired: true,
        isEmail: true
      })?.messageError
    }
    if (!validationInput(values?.phone_number, { isRequired: true, minLength: 11 })?.valid) {
      errors.phone_number = validationInput(values?.phone_number, {
        isRequired: true,
        minLength: 11
      })?.messageError
    }
    if (!validationInput(values?.address, { isRequired: true })?.valid) {
      errors.address = validationInput(values?.address, { isRequired: true })?.messageError
    }

    if (!validationInput(values?.password, { isRequired: true, isPassword: true })?.valid) {
      errors.password = validationInput(values?.password, {
        isRequired: true,
        isPassword: true
      })?.messageError
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullname: user?.fullname ?? '',
      email: user?.email ?? '',
      phone_number: user?.phone_number ?? '',
      address: user?.address ?? '',
      password: user?.password ?? '',
      workshop_id: idWorkshop ?? ''
    },
    validate: ValidationCheck,
    onSubmit: async (values, { setErrors, resetForm }) => {
      let body = {
        ...values,
        workshop_id: parseInt(values?.workshop_id)
      }

      onSubmit(body, setErrors, resetForm)
    }
  })

  return (
    <Modal
      title={type === 'add' ? `Tambah ${title}` : `Edit ${title}`}
      open={isOpen}
      onCancel={() => {
        onCancel()
        formik.handleReset()
      }}
      onOk={() => {
        formik.handleSubmit()
      }}
      okText={type === 'add' ? `Tambah ${title}` : `Edit ${title}`}
      okButtonProps={{ className: 'bg-primary-500 rounded', type: 'danger', loading: isLoading }}
      cancelButtonProps={{
        className: 'hover:text-[#000] hover:border-[#000] rounded',
        type: 'default',
        disabled: isLoading
      }}>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nama Lengkap
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className="rounded py-2"
          placeholder="Masukan Nama User"
          value={values?.fullname}
          name="fullname"
          onChange={formik.handleChange}
        />
        {touched?.fullname && errors?.fullname && (
          <span className="text-[red]">{errors?.fullname}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Email
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className="rounded py-2"
          placeholder="Email"
          value={values?.email}
          name="email"
          onChange={formik.handleChange}
        />
        {touched?.email && errors?.email && <span className="text-[red]">{errors?.email}</span>}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Password
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input.Password
          className="rounded py-2"
          placeholder="Masukan Password"
          value={values?.password}
          name="password"
          onChange={formik.handleChange}
        />
        {touched?.password && errors?.password && (
          <span className="text-[red]">{errors?.password}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nomor Telepon
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className="rounded py-2"
          placeholder="Masukan Nomor Telepon"
          value={values?.phone_number}
          name="phone_number"
          onChange={formik.handleChange}
        />
        {touched?.phone_number && errors?.phone_number && (
          <span className="text-[red]">{errors?.phone_number}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Alamat
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input.TextArea
          className="rounded py-2"
          placeholder="Masukan Alamat Lengkap"
          value={values?.address}
          name="address"
          onChange={formik.handleChange}
        />
        {touched?.address && errors?.address && (
          <span className="text-[red]">{errors?.address}</span>
        )}
      </div>
    </Modal>
  )
}

export default ModalCreateUpdateUser
