import React, { useState, useEffect } from 'react'
import { Breadcrumb, Dropdown, Layout, Typography } from 'antd'
import TestDriveDetailComponent from './components/TestDriveDetailComponent'
import { Button, ModalDelete } from 'components'
import { IcCheck, IcCheckRound, IcLoadMore, IcTrash, IcWhatsApp } from 'assets/icons'
import {
  onReturnStatusTelahDikonfirmasiJadwalService,
  onReturnWaktuPerluDikonfirmasi,
  showErrorMessage,
  showSuccessMessage
} from 'utils'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { apiDelete, apiGet, apiPatch, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { perPageInitialData } from 'constant'
import ModalTestDrive from './components/ModalTestDrive'
import { logout } from 'store/actions'
// test
const initialFilter = {
  owner_name: '',
  tester_name: '',
  status: '',
  start_date: '',
  end_date: ''
}
const initialModal = {
  show: false,
  type: 'edit',
  id: '',
  owner_name: '',
  tester_name: ''
}
const initialModalDelete = {
  show: false,
  title: '',
  desc: '',
  id: ''
}

const initialSelectedData = {
  date: '',
  time_id: '',
  test_drive_id: '',
  mechanic_id: '',
  mechanic: {}
}

const TestDriveDetail = () => {
  const { id } = useParams()
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { push, replace } = useHistory()
  const urlhash = window?.location?.hash
  const [detailWorkshop, setDetailWorkshop] = useState({})
  const [tab, setTab] = useState(urlhash?.includes('#unconfirmed') ? 0 : 1)
  const [tabExTaxi, setTabExTaxi] = useState([
    { name: 'Mobil Bekas', active: urlhash?.includes('ex-taxi') ? false : true },
    { name: 'ex-Taxi', active: urlhash?.includes('ex-taxi') ? true : false }
  ])
  const [modal, setModal] = useState({
    show: false,
    type: 'test-drive',
    data: {}
  })
  const [modalDelete, setModalDelete] = useState({ ...initialModalDelete })
  const [selectedFilter, setSelectedFilter] = useState({ ...initialFilter })
  const [selectedData, setSelectedData] = useState({ ...initialSelectedData })

  // list data
  const [listData, setListData] = useState([])
  const [calendars, setCalendars] = useState([])
  const [times, setTimes] = useState([])
  const [owners, setOwners] = useState([])
  const [testers, setTesters] = useState([])
  const [mechanics, setMechanics] = useState([])

  // pagination
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)
  const [filterCalendar, setFilterCalendar] = useState({
    start_date: moment().startOf('week').format('YYYY-MM-DD'),
    end_date: moment().endOf('week').format('YYYY-MM-DD')
  })
  const [meta, setMeta] = useState({
    http_code: undefined,
    total: undefined,
    offset: undefined,
    limit: undefined
  })

  useEffect(() => {
    setSelectedFilter({ ...initialFilter })
  }, [tab])

  const handleChange = (value, name) => {
    setSelectedFilter((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const onChangeDate = (value, dateString) => {
    setSelectedFilter((prev) => ({
      ...prev,
      start_date: dateString[0],
      end_date: dateString[1]
    }))
  }

  const onHandleExtraUrl = () => {
    let extraUrl = ''
    if (keyword !== '') {
      extraUrl += `&keywords=${keyword}`
    }

    return extraUrl
  }

  const onLogout = () => dispatch(logout())

  // get additional data
  useQuery({
    queryKey: ['getOwners', tab],
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.detail.prefix}/${
          urls.testDrive.detail.owners.root
        }/${id}?is_confirmed=${tab ? 'true' : 'false'}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      const newData = data?.resData?.data
        ?.filter((item) => item !== '')
        .map((el) => ({
          label: el,
          value: el
        }))
      setOwners(newData)
    }
  })

  useQuery({
    queryKey: ['getTesters', tab],
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.detail.prefix}/${
          urls.testDrive.detail.testers.root
        }/${id}?is_confirmed=${tab ? 'true' : 'false'}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      const newData = data?.resData?.data
        ?.filter((item) => item !== '')
        .map((el) => ({
          label: el,
          value: el
        }))
      setTesters(newData)
    }
  })
  useQuery({
    queryKey: 'getMechanics',
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.detail.mechanics.prefix}/${urls.testDrive.detail.mechanics.root}/${id}/all`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      const newRes = data.resData.data
      const newData = newRes.map((el) => ({
        label: el.fullname,
        value: el.id
      }))
      setMechanics(newData)
    }
  })

  const { refetch: refetchSchedule } = useQuery({
    queryKey: ['getCalendar', filterCalendar?.start_date, filterCalendar?.end_date, tabExTaxi],
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.detail.prefix}/${
          urls.testDrive.detail.calendar.root
        }/${id}?unit_type=${tabExTaxi[0]?.active ? 'catalog' : 'taxi'}&start_date=${
          filterCalendar?.start_date
        }&end_date=${filterCalendar?.end_date}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (res) => {
      const reWrite = res?.resData?.data?.map((item) => {
        const parseDate = moment(item?.test_drive_date).format('YYYY-MM-DD')
        return {
          ...item,
          owner_name: item.unit_owner_fullname || '-',
          title: item?.unit_name,
          start: moment(parseDate + ' ' + item?.start_hour).toDate(),
          end: moment(parseDate + ' ' + item?.end_hour).toDate()
        }
      })
      setCalendars(reWrite)
    }
  })

  useQuery({
    queryKey: 'getTimes',
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.prefix}/${urls.testDrive.workshops.root}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      const newData = data?.resData?.data?.map((el) => ({
        label: el.time,
        value: el.id
      }))
      setTimes(newData)
    }
  })

  useQuery({
    queryKey: 'getDetailWorkshop',
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.additional.prefix}/${urls.testDrive.additional.workshops.root}/${id}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      const newData = data?.resData?.data
      const newMap =
        newData.pin_google_map === '' || newData.pin_google_map === null
          ? null
          : `https://maps.google.com/maps?q=${newData?.pin_google_map?.split(',')?.[0]},${
              newData?.pin_google_map?.split(',')?.[1]
            }&hl=es;z=14&output=embed`
      setDetailWorkshop({
        ...newData,
        pin_google_map: newMap
      })
    }
  })

  const { refetch, isLoading } = useQuery({
    queryKey: ['getList', tab, selectedFilter],
    refetchOnWindowFocus: false,
    enabled: tabExTaxi.find((item) => item.active === true).name === 'Mobil Bekas',
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.detail.prefix}/${
          urls.testDrive.detail.list.root
        }/${id}?unit_type=catalog
			&limit=${perPageArr.find((item) => item.selected === true).value}&offset=0&is_confirmed=${
          tab ? 'true' : 'false'
        }
			&owner_name=${selectedFilter.owner_name}&tester_name=${selectedFilter.tester_name}
			&start_date=${selectedFilter.start_date}&end_date=${selectedFilter.end_date}
			${tab === 1 ? `&status=${selectedFilter.status}` : ''}
			`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setListData(data?.resData?.data)
      setCurrentPage(0)
      setMeta(data.resData.meta)
    }
  })

  const { refetch: refetchExTaxi, isLoadingExTaxi } = useQuery({
    queryKey: ['getList-ex-taxi', tab, selectedFilter],
    refetchOnWindowFocus: false,
    enabled: tabExTaxi.find((item) => item.active === true).name === 'ex-Taxi',
    queryFn: async () =>
      await apiGet({
        url: `${urls.testDrive.detail.prefix}/${
          urls.testDrive.detail.list.root
        }/${id}?unit_type=taxi
			&limit=${perPageArr.find((item) => item.selected === true).value}&offset=0&is_confirmed=${
          tab ? 'true' : 'false'
        }
			&owner_name=${selectedFilter.owner_name}&tester_name=${selectedFilter.tester_name}
			&start_date=${selectedFilter.start_date}&end_date=${selectedFilter.end_date}
			${tab === 1 ? `&status=${selectedFilter.status}` : ''}
			`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setListData(data?.resData?.data)
      setCurrentPage(0)
      setMeta(data.resData.meta)
    }
  })

  const deleteInspection = useMutation('deleteInspection', {
    mutationFn: () => {
      setIsLoadingCRUD(true)
      const mount = setTimeout(async () => {
        const res = await apiDelete({
          url: `${urls.testDrive.prefix}/${urls.testDrive.root}/${modalDelete.id}`,
          token: user.token,
          onLogout
        })
        if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
          showSuccessMessage({ content: 'Berhasil Menghapus Test Drive.' })
        } else {
          showErrorMessage({
            content: `${res.resData.errors[0].message ?? 'Oopps, Gagal Menghapus Test Drive.'}`
          })
        }
        setModalDelete({ ...initialModalDelete })
        onRefetch()
        setIsLoadingCRUD(false)
      })
      return () => clearTimeout(mount)
    }
  })

  const submitModal = useMutation('submitModal', {
    mutationFn: () => {
      console.log('jalan submit modal')
      // classification url & data
      let url = ''
      let dataSend = {}
      if (modal.type === 'test-drive') {
        dataSend.test_drive_date = moment(selectedData.date).format('DD/MM/YYYY')
        dataSend.test_drive_schedule_id = parseInt(selectedData.time_id)
        url = `${urls.testDrive.confirm.prefix}/${urls.testDrive.confirm.testDrive.root}/${modal?.data?.id}`
      }
      if (modal.type === 'mekanik') {
        dataSend.mechanic_id = selectedData?.mechanic?.value
        dataSend.mechanic_name = selectedData?.mechanic?.label
        url = `${urls.testDrive.confirm.prefix}/${urls.testDrive.confirm.mechanic.root}/${selectedData?.test_drive_id}`
      }
      setIsLoadingCRUD(true)
      const mount = setTimeout(async () => {
        const res = await apiPatch({
          url: url,
          data: dataSend,
          token: user.token,
          onLogout
        })
        if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
          if (modal.type === 'test-drive') {
            showSuccessMessage({ content: 'Konfirmasi Test Drive Berhasil.' })
          }
          if (modal.type === 'mekanik') {
            showSuccessMessage({ content: 'Konfirmasi Mekanik Berhasil.' })
          }
        } else {
          showErrorMessage({
            content: `${res.resData.errors[0].message ?? 'Oopps, Gagal Melakukan Konfirmasi.'}`
          })
        }
        setModal({ ...initialModal })
        onRefetch()
        setIsLoadingCRUD(false)
      })
      return () => clearTimeout(mount)
    }
  })

  const submitConfirm = useMutation('submitConfirm', {
    mutationFn: (row) => {
      // classification url & data
      let url = ''
      let dataSend = {}
      if (row.status === 'scheduled') {
        url = `${urls.testDrive.confirm.prefix}/${urls.testDrive.confirm.testDriveOngoing.root}/${row?.id}`
      }
      if (row.status === 'ongoing') {
        url = `${urls.testDrive.confirm.prefix}/${urls.testDrive.confirm.testDriveDone.root}/${row?.id}`
      }
      setIsLoadingCRUD(true)
      const mount = setTimeout(async () => {
        const res = await apiPatch({
          url: url,
          data: dataSend,
          token: user.token,
          onLogout
        })
        if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
          if (row.status === 'scheduled') {
            showSuccessMessage({ content: 'Test Drive Terjadwal Berhasil di Konfirmasi.' })
          }
          if (row.status === 'ongoing') {
            showSuccessMessage({ content: 'Test Drive Sedang Berlangsung Berhasil di Konfirmasi.' })
          }
        } else {
          showErrorMessage({
            content: `${res.resData.errors[0].message ?? 'Oopps, Gagal Melakukan Konfirmasi.'}`
          })
        }
        setModal({ ...initialModal })
        onRefetch()
        setIsLoadingCRUD(false)
      })
      return () => clearTimeout(mount)
    }
  })

  const handleConfirmModal = () => {
    // UNTUK TIPE TEST DRIVE
    if (modal?.type === 'test-drive') {
      let message = 'Jadwal Tidak Tersedia, Mohon Periksa Kembali.'
      const formatDate = 'DD/MM/YYYY'
      const service_time = selectedData?.time?.split('-')
      const currentDate = moment().format(formatDate)
      const currentHour = moment().format('HH:mm')
      const selectedDate = moment(selectedData?.date).format(formatDate)

      // CHECK IF DATE VALID / NOT EMPTY
      if (moment(selectedData?.date).isValid()) {
        // CHECK IF DATE IS LOWER THAN CURRENT
        if (moment(selectedDate, formatDate).isBefore(moment(currentDate, formatDate))) {
          return showErrorMessage({ content: message })
        }
        // IF DATE SAME AS CURRENT, CHECK IF START TIME AND END TIME LOWER THAN CURRENT HOUR
        else if (moment(selectedDate, formatDate).isSame(moment(currentDate, formatDate))) {
          if (service_time[0] < currentHour && service_time[1] < currentHour) {
            return showErrorMessage({ content: message })
          }
        }
        return submitModal.mutate()
      } else {
        return showErrorMessage({ content: 'Mohon Pilih Tanggal' })
      }
    }
    // UNTUK TIPE MEKANIK
    else {
      submitModal.mutate()
    }
  }

  const handleConfirm = (row) => {
    submitConfirm.mutate(row)
  }

  const handleDelete = () => {
    deleteInspection.mutate()
  }

  const onChangePrevNextPage = (offset) => {
    setIsLoadingCRUD(true)
    setMeta({ ...meta, offset })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.testDrive.detail.prefix}/${urls.testDrive.detail.list.root}/${id}
			?unit_type=${
        tabExTaxi.find((item) => item.active === true).name === 'ex-Taxi' ? 'taxi' : 'catalog'
      }&limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${offset}${onHandleExtraUrl()}
			&is_confirmed=${tab ? 'true' : 'false'}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListData(response.resData.data)
      } else {
        onRefetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    setIsLoadingCRUD(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.testDrive.detail.prefix}/${urls.testDrive.detail.list.root}/${id}
			?unit_type=${
        tabExTaxi.find((item) => item.active === true).name === 'ex-Taxi' ? 'taxi' : 'catalog'
      }&limit=${value}&offset=0${onHandleExtraUrl()}
			&is_confirmed=${tab ? 'true' : 'false'}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListData(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        onRefetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const handleSearch = async (value) => {
    const response = await apiGet({
      url: `${urls.testDrive.detail.prefix}/${urls.testDrive.detail.list.root}/${id}
				?unit_type=${
          tabExTaxi.find((item) => item.active === true).name === 'ex-Taxi' ? 'taxi' : 'catalog'
        }&keywords=${value}&limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=0&is_confirmed=${tab ? 'true' : 'false'}`,
      token: user.token,
      onLogout
    })
    if ((response.resStatus === 200) & (response.resData.meta.http_code === 200)) {
      setListData(response.resData.data)
      setMeta(response.resData.meta)
    } else {
      onRefetch()
    }
    setKeyword(value)
  }

  const handleChangeTab = (value) => {
    const activeType = tabExTaxi.find((el) => el.active === true)
    setTab(value)
    replace(`/test-drive/${id}#${value === 0 ? 'unconfirmed' : 'confirmed'}&${activeType.name}`)
  }

  const onRefetch = () => {
    if (tabExTaxi.find((item) => item.active === true).name === 'Mobil Bekas') {
      refetch()
    }
    if (tabExTaxi.find((item) => item.active === true).name === 'ex-Taxi') {
      refetchExTaxi()
    }
    refetchSchedule()
  }

  const columnValueStyle = 'font-medium text-gray-700'

  const columnsPerluKonfirmasi = [
    {
      name: 'Tanggal',
      selector: (row) => moment(row.test_drive_date).format('DD/MM/YYYY'),
      cell: (row) => (
        <span className={columnValueStyle}>{moment(row.test_drive_date).format('DD/MM/YYYY')}</span>
      ),
      grow: 1
    },
    {
      name: 'Unit',
      selector: (row) => row.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1 w-full my-2">
          <span className={columnValueStyle}>{row.unit_name}</span>
          <span className="font-normal text-gray-500 tracking-tight">{`${row.unit_police_number}`}</span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Pemilik',
      selector: (row) =>
        row.unit_owner_fullname && row.unit_owner_fullname !== '' ? row.unit_owner_fullname : '-',
      cell: (row) => <span className={columnValueStyle}>{row?.unit_owner_fullname}</span>,
      grow: 2
    },
    {
      name: 'Tester',
      selector: (row) => row.tester_fullname,
      cell: (row) => <span className={columnValueStyle}>{row?.tester_fullname}</span>,
      grow: 2
    },
    {
      name: 'No. Telp',
      selector: (row) =>
        row.tester_phone_number && row.tester_phone_number !== '' ? row.tester_phone_number : '-',
      cell: (row) => (
        <span className={columnValueStyle}>
          {row.tester_phone_number && row.tester_phone_number !== ''
            ? row.tester_phone_number
            : '-'}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Waktu',
      selector: (row) => [row.is_conflict, row.test_drive_schedule.time],
      cell: (row) => onReturnWaktuPerluDikonfirmasi(row.is_conflict, row.test_drive_schedule.time),
      grow: 1
    },
    {
      name: 'Action',
      minWidth: '170px',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div className="flex items-center justify-center self-center space-x-2 my-2 mr-1">
          <Button
            className="flex flex-row items-center justify-center space-x-2 px-3 border-solid border border-[#12B76A] bg-[#12B76A] rounded-md"
            onClick={() => {
              setModal({ show: true, type: 'test-drive', data: row })
              setSelectedData({
                date: row.test_drive_date,
                test_drive_id: row.id,
                time_id: row.test_drive_schedule.id,
                mechanic_id: row.id,
                time: row?.test_drive_schedule?.time
              })
            }}>
            <IcCheckRound className="text-[#FFFFFF]" size={16} />
            <span className="font-medium text-[#FFFFFF]">Konfirmasi</span>
          </Button>
          <Button
            className="p-2 border-solid border border-[#FDA19B] rounded-md bg-white"
            onClick={() => {
              setModalDelete({
                show: true,
                title: 'Hapus Test Drive',
                desc: `Apakah anda yakin ingin menghapus Test Drive di waktu [${row.test_drive_schedule.time}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`,
                id: row.id
              })
            }}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const columnsPerluKonfirmasiExTaxi = [
    {
      name: 'Tanggal',
      selector: (row) => moment(row.test_drive_date).format('DD/MM/YYYY'),
      cell: (row) => (
        <span className={columnValueStyle}>{moment(row.test_drive_date).format('DD/MM/YYYY')}</span>
      ),
      grow: 1
    },
    {
      name: 'Unit',
      selector: (row) => row.unit_name,
      cell: (row) => <span className={columnValueStyle}>{row.unit_name}</span>,
      grow: 3
    },
    {
      name: 'Tester',
      selector: (row) => row.tester_fullname,
      cell: (row) => <span className={columnValueStyle}>{row.tester_fullname}</span>,
      grow: 2
    },
    {
      name: 'No. Telp',
      selector: (row) => row.tester_phone_number,
      cell: (row) => (
        <span className={columnValueStyle}>
          {row.tester_phone_number && row.tester_phone_number !== ''
            ? row.tester_phone_number
            : '-'}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Waktu',
      selector: (row) => [row.is_conflict, row.test_drive_schedule.time],
      cell: (row) => onReturnWaktuPerluDikonfirmasi(row.is_conflict, row.test_drive_schedule.time),
      grow: 2
    },
    {
      name: 'Action',
      minWidth: '170px',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div className="flex items-center justify-center self-center space-x-2 my-2 mr-1">
          <Button
            className="flex flex-row items-center justify-center space-x-2 px-3 border-solid border border-[#12B76A] bg-[#12B76A] rounded-md"
            onClick={() => {
              setModal({ show: true, type: 'test-drive', data: row })
              setSelectedData({
                date: row.test_drive_date,
                test_drive_id: row.id,
                time_id: row.test_drive_schedule.id,
                mechanic_id: row.id,
                time: row?.test_drive_schedule?.time
              })
            }}>
            <IcCheckRound className="text-[#FFFFFF]" size={16} />
            <span className="font-medium text-[#FFFFFF]">Konfirmasi</span>
          </Button>
          <Button
            className="p-2 border-solid border border-[#FDA19B] rounded-md bg-white"
            onClick={() => {
              setModalDelete({
                show: true,
                title: 'Hapus Test Drive',
                desc: `Apakah anda yakin ingin menghapus Test Drive di waktu [${row.test_drive_schedule.time}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`,
                id: row.id
              })
            }}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const itemsOne = (row) => [
    {
      key: '1',
      className: 'cursor-pointer',
      label: (
        <div className="flex items-center" onClick={() => handleConfirm(row)}>
          <IcCheck className="text-success-500 mr-2" size={16} />
          <Typography.Text className="text-success-500 text-sm">
            {row.status === 'scheduled' ? 'Konfirmasi' : 'Test Drive Selesai'}
          </Typography.Text>
        </div>
      )
    },
    // {
    //   key: '2',
    //   label: (
    //     <div
    //       className="flex items-center"
    //       onClick={() => {
    //         setModal({ show: true, type: 'mekanik', data: row })
    //         setSelectedData({
    //           date: row.test_drive_date,
    //           test_drive_id: row.id,
    //           time_id: row.test_drive_schedule.id,
    //           mechanic_id: row.id,
    //           mechanic: {
    //             label: row.mechanic_name,
    //             value: row.mechanic_id
    //           }
    //         })
    //       }}>
    //       <IcUser className="text-gray-700 mr-2" size={16} />
    //       <Typography.Text className="text-gray-700 text-sm">Konfirmasi Mekanik</Typography.Text>
    //     </div>
    //   )
    // },
    {
      key: '3',
      label: (
        <div>
          <a
            href="https://wa.me/6287724219292"
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline">
            <div className="flex items-center">
              <IcWhatsApp className="text-gray-700 mr-2" size={16} />
              <Typography.Text className="text-gray-700 text-sm">Whatsapp</Typography.Text>
            </div>
          </a>
        </div>
      )
    },
    {
      key: '4',
      label: (
        <div
          className="flex items-center"
          onClick={() => {
            setModalDelete({
              show: true,
              title: 'Hapus Test Drive',
              desc: `Apakah anda yakin ingin menghapus Test Drive di waktu [${row.test_drive_schedule.time}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`,
              id: row.id
            })
          }}>
          <IcTrash className="text-danger-500 mr-2" size={16} />
          <Typography.Text className="text-danger-500 text-sm">Delete Test Drive</Typography.Text>
        </div>
      )
    }
  ]

  const itemsSecond = (row) => [
    // {
    //   key: '2',
    //   label: (
    //     <div
    //       className="flex items-center"
    //       onClick={() => {
    //         setModal({ show: true, type: 'mekanik', data: row })
    //         setSelectedData({
    //           date: row.test_drive_date,
    //           test_drive_id: row.id,
    //           time_id: row.test_drive_schedule.id,
    //           mechanic_id: row.mechanic_id,
    //           mechanic: {
    //             value: row.mechanic_id,
    //             label: row.mechanic_name
    //           }
    //         })
    //       }}>
    //       <IcUser className="text-gray-700 mr-2" size={16} />
    //       <Typography.Text className="text-gray-700 text-sm">Konfirmasi Mekanik</Typography.Text>
    //     </div>
    //   )
    // },
    {
      key: '3',
      label: (
        <div className="flex items-center">
          <IcWhatsApp className="text-gray-700 mr-2" size={16} />
          <Typography.Text className="text-gray-700 text-sm">Whatsapp</Typography.Text>
        </div>
      )
    },
    {
      key: '4',
      label: (
        <div
          className="flex items-center"
          onClick={() => {
            setModalDelete({
              show: true,
              title: 'Hapus Test Drive',
              desc: `Apakah anda yakin ingin menghapus Test Drive di waktu [${row.test_drive_schedule.time}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`,
              id: row.id
            })
          }}>
          <IcTrash className="text-danger-500 mr-2" size={16} />
          <Typography.Text className="text-danger-500 text-sm">Delete Test Drive</Typography.Text>
        </div>
      )
    }
  ]

  const columnsTelahKonfirmasi = [
    {
      name: 'Tanggal',
      selector: (row) => moment(row.test_drive_date).format('DD/MM/YYYY'),
      cell: (row) => (
        <span className={columnValueStyle}>{moment(row.test_drive_date).format('DD/MM/YYYY')}</span>
      ),
      grow: 1
    },
    {
      name: 'Unit',
      selector: (row) => row.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1 w-full py-2">
          <span className={columnValueStyle}>{row.unit_name}</span>
          <span className="font-normal text-gray-500 tracking-tight">{`${row.unit_police_number}`}</span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Pemilik',
      selector: (row) =>
        row.unit_owner_fullname && row.unit_owner_fullname !== '' ? row.unit_owner_fullname : '-',
      cell: (row) => (
        <span className={columnValueStyle}>
          {row.unit_owner_fullname && row.unit_owner_fullname !== ''
            ? row.unit_owner_fullname
            : '-'}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Tester',
      selector: (row) => row.tester_fullname,
      cell: (row) => <span className={columnValueStyle}>{row?.tester_fullname}</span>,
      grow: 3
    },
    {
      name: 'No. Telp',
      selector: (row) => [row.tester_phone_number && row.tester_phone_number],
      cell: (row) => (
        <span className={columnValueStyle}>
          {row.tester_phone_number && row.tester_phone_number !== ''
            ? row.tester_phone_number
            : '-'}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Waktu',
      selector: (row) => row?.test_drive_schedule?.time,
      cell: (row) => <span>{row?.test_drive_schedule?.time.replace('-', '-\n')}</span>,
      grow: 2
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
      cell: (row) => onReturnStatusTelahDikonfirmasiJadwalService(row?.status),
      grow: 1
    },
    {
      name: 'Action',
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Dropdown
            menu={{
              items:
                row.status === 'ongoing' || row.status === 'scheduled'
                  ? itemsOne(row)
                  : itemsSecond(row)
            }}
            placement="bottomRight"
            trigger={['click']}>
            <div className="cursor-pointer" onClick={(e) => e.preventDefault()}>
              <IcLoadMore className="text-gray-700" size={16} />
            </div>
          </Dropdown>
        </div>
      ),
      grow: 1
    }
  ]

  const columnsTelahKonfirmasiExTaxi = [
    {
      name: 'Tanggal',
      selector: (row) => moment(row.test_drive_date).format('DD/MM/YYYY'),
      cell: (row) => (
        <span className={columnValueStyle}>{moment(row.test_drive_date).format('DD/MM/YYYY')}</span>
      ),
      grow: 1
    },
    {
      name: 'Unit',
      selector: (row) => row.unit_name,
      cell: (row) => <span className={columnValueStyle}>{row.unit_name}</span>,
      grow: 3
    },
    {
      name: 'Tester',
      selector: (row) => row.tester_fullname,
      cell: (row) => <span className={columnValueStyle}>{row?.tester_fullname}</span>,
      grow: 3
    },
    {
      name: 'No. Telp',
      selector: (row) => row.tester_phone_number,
      cell: (row) => (
        <span>
          {row.tester_phone_number && row.tester_phone_number !== ''
            ? row.tester_phone_number
            : '-'}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Waktu',
      selector: (row) => row?.test_drive_schedule?.time,
      cell: (row) => (
        <span className={columnValueStyle}>
          {row?.test_drive_schedule?.time.replace('-', '-\n')}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Status',
      selector: (row) => onReturnStatusTelahDikonfirmasiJadwalService(row?.status),
      cell: (row) => onReturnStatusTelahDikonfirmasiJadwalService(row?.status),
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Dropdown
            menu={{
              items:
                row.status === 'ongoing' || row.status === 'scheduled'
                  ? itemsOne(row)
                  : itemsSecond(row)
            }}
            placement="bottomRight"
            trigger={['click']}>
            <div className="cursor-pointer" onClick={(e) => e.preventDefault()}>
              <IcLoadMore className="text-gray-700" size={16} />
            </div>
          </Dropdown>
        </div>
      ),
      grow: 1
    }
  ]

  const onChangeDataTable = (index) => {
    if (index === 0) {
      refetch()
    }
    if (index === 1) {
      refetchExTaxi()
    }
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5' }}>
      {modalDelete.show ? (
        <ModalDelete
          minHeight="min-h-[18%]"
          minWidth="min-w-[18%]"
          maxWidth="max-w-[18%]"
          title={modalDelete.title}
          desc={modalDelete.desc}
          onClose={() => setModalDelete({ ...initialModalDelete })}
          onSubmit={() => {
            handleDelete()
          }}
        />
      ) : null}
      {modal.show ? (
        <ModalTestDrive
          minHeight="min-h-[20%]"
          state={modal}
          mechanics={mechanics}
          times={times}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          tabActive={tabExTaxi.find((item) => item.active === true).name}
          onClose={() => setModal((prev) => ({ ...prev, show: false }))}
          onSubmit={() => {
            handleConfirmModal()
          }}
        />
      ) : null}
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Test Drive
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-semibold text-gray-500 cursor-pointer"
          onClick={() => push('/test-drive')}>
          Daftar Cabang
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          {detailWorkshop?.name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <TestDriveDetailComponent
        isLoading={isLoading ?? isLoadingExTaxi ?? isLoadingCRUD}
        owners={owners}
        testers={testers}
        detailWorkshop={detailWorkshop}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        filterCalendar={filterCalendar}
        tab={tab}
        tabExTaxi={tabExTaxi}
        calendars={calendars}
        handleChangeTab={handleChangeTab}
        setTabExTaxi={(index) => {
          onChangeDataTable(index)
          const tempArr = [...tabExTaxi]
          tempArr.map((itemCh, indexCh) => {
            if (index === indexCh) {
              if (itemCh.active) {
                return false
              } else {
                itemCh.active = true
              }
            } else {
              itemCh.active = false
            }
          })
          setTabExTaxi(tempArr)
          replace(
            `/test-drive/${id}#${tab === 0 ? 'unconfirmed' : 'confirmed'}&${
              index === 0 ? 'mobil-bekas' : 'ex-Taxi'
            }`
          )
        }}
        handleChange={handleChange}
        onChangeDate={onChangeDate}
        handleSearch={handleSearch}
        columns={
          tabExTaxi.find((item) => item.active === true).name === 'ex-Taxi'
            ? tab === 0
              ? columnsPerluKonfirmasiExTaxi
              : columnsTelahKonfirmasiExTaxi
            : tab === 0
            ? columnsPerluKonfirmasi
            : columnsTelahKonfirmasi
        }
        data={listData}
        currentPage={meta.offset ? meta.offset + 1 : 1}
        totalPerPage={
          perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) > meta.total
            ? meta.total
            : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
        }
        dropDownDataFilter={perPageArr}
        lengthAllData={meta.total}
        onChangePrevPage={() => {
          if (currentPage > 0) {
            setCurrentPage(currentPage - 1)
            onChangePrevNextPage(
              (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
            )
          }
        }}
        onChangeNextPage={() => {
          if (
            perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
            meta.total
          ) {
            setCurrentPage(currentPage + 1)
            onChangePrevNextPage(
              (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
            )
          }
        }}
        onChangeDropDown={(value) => onChangePerPage(value)}
        onChangeMonth={(date) => {
          const start = new Date(date)
          start.setDate(1)
          setFilterCalendar({
            start_date: moment(start).startOf('week').format('YYYY-MM-DD'),
            end_date: moment(start).endOf('week').format('YYYY-MM-DD')
          })
        }}
        onNextWeek={() => {
          setFilterCalendar((prev) => ({
            start_date: moment(prev.start_date).add(7, 'days').format('YYYY-MM-DD'),
            end_date: moment(prev.end_date).add(7, 'days').format('YYYY-MM-DD')
          }))
        }}
        onPrevWeek={() => {
          setFilterCalendar((prev) => ({
            start_date: moment(prev.start_date).subtract(7, 'days').format('YYYY-MM-DD'),
            end_date: moment(prev.end_date).subtract(7, 'days').format('YYYY-MM-DD')
          }))
        }}
        idWorkshop={id}
      />
    </Layout>
  )
}

export default TestDriveDetail
