import React, { useCallback } from 'react'
import { Card, DatePicker, Input, Radio, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory } from 'react-router-dom'
import {
  Button,
  FormInputRupiah,
  PhotoContainerCard,
  PhotoMultipleCard,
  PhotoThumbnailCard
} from 'components'
import { Formik } from 'formik'
import moment from 'moment'

const UpdateExTaxiUnitComponent = ({
  isEditing,
  brandList,
  modelList,
  transmissionList,
  fuelList,
  forms,
  setForms,
  setSearch,
  saveData,
  handleAddPhoto,
  handleDeletePhoto
}) => {
  const router = useHistory()

  const handleForms = useCallback((key, value) => {
    if (key === 'year') {
      setForms((prevState) => ({
        ...prevState,
        [key]: {
          parse: value && value !== '' ? moment(value, 'YYYY').format('YYYY') : '',
          value: value
        }
      }))
    } else {
      setForms((prevState) => ({
        ...prevState,
        [key]: value
      }))
    }
  }, [])

  // const handleSearch = useCallback((key, value) => {
  //   setSearch((prevState) => ({
  //     ...prevState,
  //     [key]: value
  //   }))
  // }, [])

  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Unit</Typography.Text>
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-2">Pabrikan</Typography.Text>
          <Select
            showSearch
            allowClear
            className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
            style={{ width: '100%' }}
            placeholder="Pilih Pabrikan"
            optionFilterProp="children"
            onChange={(value) => {
              handleForms('brand_id', value)
              const findBrand = brandList?.findIndex((brand) => brand.id === value)
              handleForms('brand_name', brandList[findBrand]?.name)
            }}
            options={brandList?.map((brand) => {
              return {
                value: brand.id,
                label: brand.name
              }
            })}
            filterOption={(input, option) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
            value={forms?.brand_id}></Select>
          {!forms.brand_id && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih brand
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-2">Model</Typography.Text>
          <Select
            showSearch
            allowClear
            className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
            style={{ width: '100%' }}
            placeholder="Pilih Model"
            optionFilterProp="children"
            onChange={(value) => {
              handleForms('model_id', value)
              const findModel = modelList?.findIndex((model) => model.id === value)
              handleForms('model_name', modelList[findModel]?.name)
            }}
            value={forms?.model_id}>
            {modelList?.map((model) => (
              <Select.Option key={`model-${model?.id?.toString()}`} value={model.id}>
                {model?.name}
              </Select.Option>
            ))}
          </Select>
          {!forms.model_id && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih model
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-2">Transmisi</Typography.Text>
          <Select
            showSearch
            allowClear
            className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
            style={{ width: '100%' }}
            placeholder="Pilih Transmisi"
            optionFilterProp="children"
            onChange={(value) => {
              handleForms('transmission_id', value)
              const findTrans = transmissionList?.findIndex(
                (transmission) => transmission.id === value
              )
              handleForms('transmission_name', transmissionList[findTrans]?.name)
            }}
            value={forms?.transmission_id}>
            {transmissionList?.map((transmission) => (
              <Select.Option
                key={`transmission-${transmission?.id?.toString()}`}
                value={transmission?.id}>
                {transmission?.name}
              </Select.Option>
            ))}
          </Select>
          {!forms.transmission_id && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih transmisi
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-2">Bahan Bakar</Typography.Text>
          <Select
            showSearch
            allowClear
            className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
            style={{ width: '100%' }}
            optionFilterProp="children"
            placeholder="Pilih Bahan Bakar"
            onChange={(value) => {
              handleForms('fuel_id', value)
              const findFuel = fuelList?.findIndex((fuel) => fuel.id === value)
              handleForms('fuel_name', fuelList[findFuel]?.name)
            }}
            value={forms?.fuel_id}>
            {fuelList?.map((fuel) => (
              <Select.Option key={`fuel-${fuel?.id?.toString()}`} value={fuel?.id}>
                {fuel?.name}
              </Select.Option>
            ))}
          </Select>
          {!forms.fuel_id && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih bahan bakar
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-2">Tahun Keluaran</Typography.Text>
          <DatePicker
            placeholder=""
            picker="year"
            value={forms.year.parse === '' ? '' : forms.year.value}
            onChange={(value) => handleForms('year', value)}
            className="custom-date-picker py-1.5 w-full"
            popupClassName="custom-date-picker-dropdown"
            format={['YYYY']}
          />
          {forms.year.parse === '' && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih tahun keluaran
            </Typography.Text>
          )}
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Harga Jual</Typography.Text>
        </div>
        <div className="w-full mt-4">
          <Formik>
            <FormInputRupiah
              name={'price'}
              value={forms.price}
              isWithLabelRp
              onChange={(value) => handleForms('price', value)}
            />
          </Formik>
        </div>
        {!forms.price && (
          <Typography.Text className="mt-1 block text-red-500 text-xs">
            *Silahkan masukkan harga jual
          </Typography.Text>
        )}
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Spesifikasi Mobil</Typography.Text>
        </div>
        <Typography.Text className="text-lg block mb-3">Peformance</Typography.Text>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Engine</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukkan Jenis Mesin"
            value={forms?.performance_engine}
            onChange={(e) => handleForms('performance_engine', e.target.value)}
          />
          {!forms.performance_engine && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan engine
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Transmisi</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukkan Jenis Transmisi"
            value={forms?.performance_transmission}
            onChange={(e) => handleForms('performance_transmission', e.target.value)}
          />
          {!forms.performance_transmission && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan transmission
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Drive Mode</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.performance_drive_mode}
            onChange={(e) => handleForms('performance_drive_mode', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.performance_drive_mode === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih drive mode
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Drivetrain</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukkan Drivetrain"
            value={forms?.performance_drive_train}
            onChange={(e) => handleForms('performance_drive_train', e.target.value)}
          />
          {!forms.performance_drive_train && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan drive train
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Shift-By-Wire (SBW)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.performance_sbw}
            onChange={(e) => handleForms('performance_sbw', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.performance_sbw === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih shift-by-wire
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Paddle Shifter</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.performance_paddle_shifter}
            onChange={(e) => handleForms('performance_paddle_shifter', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.performance_paddle_shifter === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih paddle shifter
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">
            Motor Driven Power Steering (MDPS)
          </Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.performance_mdps}
            onChange={(e) => handleForms('performance_mdps', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.performance_mdps === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih motor driven power steering (mdps)
            </Typography.Text>
          )}
        </div>
        <div className="border-0 border-b border-solid border-b-gray-300 mb-4" />
        <Typography.Text className="text-lg block mb-3">Safety</Typography.Text>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Air Bag</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Jumlah Airbag"
            type="number"
            min={0}
            value={forms?.safety_airbag}
            onChange={(e) => handleForms('safety_airbag', e.target.value)}
          />
          {!forms.safety_airbag && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan jumlah safety airbag
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">
            Tire Pressure Monitoring System (TPMS)
          </Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_tpms}
            onChange={(e) => handleForms('safety_tpms', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_tpms === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih tire pressure monitoring system (TPMS)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Anti-Lock Brake System (ABS)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_abs}
            onChange={(e) => handleForms('safety_abs', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_abs === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih anti-lock brake system (ABS)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">
            Electronic Stability Control (ESC)
          </Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_esc}
            onChange={(e) => handleForms('safety_esc', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_esc === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih electronic stability control (ESC)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Hill Assist Control (HAC)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_hac}
            onChange={(e) => handleForms('safety_hac', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_hac === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih hill assist control (HAC)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Lane Keep Assist System (LKA)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_lka}
            onChange={(e) => handleForms('safety_lka', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_lka === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih lane keep assist system (LKA)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Lane Following Assits (LFA)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_lfa}
            onChange={(e) => handleForms('safety_lfa', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_lfa === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih lane following assits (LFA)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">
            Blind-Spot Collision Warning (BCW)
          </Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_bcw}
            onChange={(e) => handleForms('safety_bcw', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_bcw === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih blind-spot collision warning (BCW)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Blind-Spot View Monitor (BSM)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_bsm}
            onChange={(e) => handleForms('safety_bsm', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_bsm === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih blind-spot view monitor (BSM)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Safe Exit Assist (SEA)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_sea}
            onChange={(e) => handleForms('safety_sea', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_sea === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih safe exit assist (SEA)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Rear Seat Occupant Alert (ROA)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_roa}
            onChange={(e) => handleForms('safety_roa', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_roa === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih rear seat occupant alert (ROA)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">
            Forward Collision Avoidance Assist (FCA)
          </Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_fca}
            onChange={(e) => handleForms('safety_fca', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_fca === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih forward collision avoidance assist (FCA)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Driver Attention Warning (DAW)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_daw}
            onChange={(e) => handleForms('safety_daw', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_daw === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih driver attention warning (DAW)
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Cruise Control</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.safety_cruise_control}
            onChange={(e) => handleForms('safety_cruise_control', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.safety_cruise_control === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih cruise control
            </Typography.Text>
          )}
        </div>
        <div className="border-0 border-b border-solid border-b-gray-300 mb-4" />
        <Typography.Text className="text-lg block mb-3">Exterior</Typography.Text>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Head Lamp</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Jenis Head Lamp"
            value={forms?.exterior_head_lamp}
            onChange={(e) => handleForms('exterior_head_lamp', e.target.value)}
          />
          {!forms.exterior_head_lamp && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan jenis head lamp
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">DRL</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_drl}
            onChange={(e) => handleForms('exterior_drl', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_drl === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih drl
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Positioning Lamp</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_positioning_lamp}
            onChange={(e) => handleForms('exterior_positioning_lamp', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_positioning_lamp === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih positioning lamp
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Auto Light Control</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_auto_light_control}
            onChange={(e) => handleForms('exterior_auto_light_control', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_auto_light_control === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih auto light control
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Grille</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Grille"
            value={forms?.exterior_grille}
            onChange={(e) => handleForms('exterior_grille', e.target.value)}
          />
          {!forms.exterior_grille && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan grille
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Bumper</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Bumper"
            value={forms?.exterior_bumper}
            onChange={(e) => handleForms('exterior_bumper', e.target.value)}
          />
          {!forms.exterior_bumper && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan bumper
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Wiper</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Wiper"
            value={forms?.exterior_wiper}
            onChange={(e) => handleForms('exterior_wiper', e.target.value)}
          />
          {!forms.exterior_wiper && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan wiper
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Wheel</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Wheel"
            value={forms?.exterior_wheel}
            onChange={(e) => handleForms('exterior_wheel', e.target.value)}
          />
          {!forms.exterior_wheel && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan wheel
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Side Mirror Color</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Side Mirror Color"
            value={forms?.exterior_side_mirror_color}
            onChange={(e) => handleForms('exterior_side_mirror_color', e.target.value)}
          />
          {!forms.exterior_side_mirror_color && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan side mirror color
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Side Mirror Folding</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_side_mirror_folding}
            onChange={(e) => handleForms('exterior_side_mirror_folding', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_side_mirror_folding === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih side mirror folding
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Side Mirror Heated</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_side_mirror_heated}
            onChange={(e) => handleForms('exterior_side_mirror_heated', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_side_mirror_heated === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih side mirror heated
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Puddle Lamp</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_puddle_lamp}
            onChange={(e) => handleForms('exterior_puddle_lamp', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_puddle_lamp === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih puddle lamp
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Side Mirror Repeater</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_side_mirror_repeater}
            onChange={(e) => handleForms('exterior_side_mirror_repeater', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_side_mirror_repeater === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih side mirror repeater
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Rear Lamp</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Rear Lamp"
            value={forms?.exterior_rear_lamp}
            onChange={(e) => handleForms('exterior_rear_lamp', e.target.value)}
          />
          {!forms.exterior_rear_lamp && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan rear lamp
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Side Spoiler</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_side_spoiler}
            onChange={(e) => handleForms('exterior_side_spoiler', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_side_spoiler === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih side spoiler
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">HMSL</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_hmsl}
            onChange={(e) => handleForms('exterior_hmsl', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_hmsl === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih hmsl
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Muffler</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Muffler"
            value={forms?.exterior_muffler}
            onChange={(e) => handleForms('exterior_muffler', e.target.value)}
          />
          {!forms.exterior_muffler && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan muffler
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Trunk</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_trunk}
            onChange={(e) => handleForms('exterior_trunk', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_trunk === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih trunk
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Sunroof</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_sunroof}
            onChange={(e) => handleForms('exterior_sunroof', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_sunroof === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih sunroof
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Two Tone</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_two_tone}
            onChange={(e) => handleForms('exterior_two_tone', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_two_tone === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih two tone
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Fog Lamp</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_fog_lamp}
            onChange={(e) => handleForms('exterior_fog_lamp', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_fog_lamp === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih fog lamp
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Parking Sensor</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_parking_sensor}
            onChange={(e) => handleForms('exterior_parking_sensor', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_parking_sensor === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih parking sensor
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Mud Guard</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.exterior_mud_guard}
            onChange={(e) => handleForms('exterior_mud_guard', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.exterior_mud_guard === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih mud guard
            </Typography.Text>
          )}
        </div>
        <div className="border-0 border-b border-solid border-b-gray-300 mb-4" />
        <Typography.Text className="text-lg block mb-3">Interior</Typography.Text>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Media</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Media"
            value={forms?.interior_media}
            onChange={(e) => handleForms('interior_media', e.target.value)}
          />
          {!forms.interior_media && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan media
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Cluster</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Jumlah Cluster"
            value={forms?.interior_cluster}
            type="number"
            min={0}
            onChange={(e) => handleForms('interior_cluster', e.target.value)}
          />
          {!forms.interior_cluster && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan jumlah cluster
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Speaker</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Jumlah Speaker"
            value={forms?.interior_speaker}
            type="number"
            min={0}
            onChange={(e) => handleForms('interior_speaker', e.target.value)}
          />
          {!forms.interior_speaker && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan jumlah speaker
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Bluetooth</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.interior_bluetooth}
            onChange={(e) => handleForms('interior_bluetooth', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.interior_bluetooth === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih bluetooth
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Steer Control</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.interior_steer_control}
            onChange={(e) => handleForms('interior_steer_control', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.interior_steer_control === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih steer control
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Seat Material</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Seat Material"
            value={forms?.interior_seat_material}
            onChange={(e) => handleForms('interior_seat_material', e.target.value)}
          />
          {!forms.interior_seat_material && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan seat material
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Seat Ventilation</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.interior_seat_ventilation}
            onChange={(e) => handleForms('interior_seat_ventilation', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.interior_seat_ventilation === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih seat ventilation
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Seat Adjust</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Seat Adjust"
            value={forms?.interior_seat_adjust}
            onChange={(e) => handleForms('interior_seat_adjust', e.target.value)}
          />
          {!forms.interior_seat_adjust && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan seat adjust
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Seat Folding</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.interior_seat_folding}
            onChange={(e) => handleForms('interior_seat_folding', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.interior_seat_folding === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih seat folding
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">IMS</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.interior_ims}
            onChange={(e) => handleForms('interior_ims', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.interior_ims === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih ims
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Seat Capacity</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Seat Capacity"
            value={forms?.interior_seat_capacity}
            onChange={(e) => handleForms('interior_seat_capacity', e.target.value)}
          />
          {!forms.interior_seat_capacity && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan seat capacity
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Ambient Light</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.interior_ambient_light}
            onChange={(e) => handleForms('interior_ambient_light', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.interior_ambient_light === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih ambient light
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Cup Holder</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.interior_cup_holder}
            onChange={(e) => handleForms('interior_cup_holder', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.interior_cup_holder === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih cup holder
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Luggage Net</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.interior_luggage_net}
            onChange={(e) => handleForms('interior_luggage_net', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.interior_luggage_net === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih luggage net
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Cooling Glove Box</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.interior_cooling_glove_box}
            onChange={(e) => handleForms('interior_cooling_glove_box', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.interior_cooling_glove_box === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih cooling glove box
            </Typography.Text>
          )}
        </div>
        <div className="border-0 border-b border-solid border-b-gray-300 mb-4" />
        <Typography.Text className="text-lg block mb-3">Convenience</Typography.Text>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Key</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan Key"
            value={forms?.convenience_key}
            onChange={(e) => handleForms('convenience_key', e.target.value)}
          />
          {!forms.convenience_key && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan key
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">EPB</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.convenience_epb}
            onChange={(e) => handleForms('convenience_epb', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.convenience_epb === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih epb
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">AC</Typography.Text>
          <Input
            className="rounded-md"
            placeholder="Masukan Keterangan AC"
            value={forms?.convenience_ac}
            onChange={(e) => handleForms('convenience_ac', e.target.value)}
          />
          {!forms.convenience_ac && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan masukkan keterangan ac
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Wireless Charger</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.convenience_wireless_charger}
            onChange={(e) => handleForms('convenience_wireless_charger', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.convenience_wireless_charger === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih wireless charger
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">USB</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.convenience_usb}
            onChange={(e) => handleForms('convenience_usb', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.convenience_usb === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih usb
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Map Lamp</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.convenience_map_lamp}
            onChange={(e) => handleForms('convenience_map_lamp', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.convenience_map_lamp === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih map lamp
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Rain Sensor</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.convenience_rain_sensor}
            onChange={(e) => handleForms('convenience_rain_sensor', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.convenience_rain_sensor === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih rain sensor
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Head-Up Display (HUD)</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.convenience_head_up_display}
            onChange={(e) => handleForms('convenience_head_up_display', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.convenience_head_up_display === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih head-up display
            </Typography.Text>
          )}
        </div>
        <div className="mb-4">
          <Typography.Text className="mb-1 block">Auto Defogger</Typography.Text>
          <Radio.Group
            className="custom-radio-btn"
            name="radiogroup"
            value={forms?.convenience_auto_defogger}
            onChange={(e) => handleForms('convenience_auto_defogger', e.target.value)}>
            <Radio value={true}>ADA</Radio>
            <Radio value={false}>TIDAK ADA</Radio>
          </Radio.Group>
          {forms.convenience_auto_defogger === null && (
            <Typography.Text className="mt-1 block text-red-500 text-xs">
              *Silahkan pilih auto defogger
            </Typography.Text>
          )}
        </div>
      </Card>
      <PhotoContainerCard>
        <PhotoThumbnailCard
          isRequired={true}
          photoDisplay="object-contain"
          height={forms?.thumbnail.blob ? 'h-full' : undefined}
          photoThumbnail={forms?.thumbnail.blob ? forms.thumbnail.blob : null}
          onChangePhotoThumbnail={({ file, blob }) => {
            handleForms('thumbnail', { file, blob })
          }}
        />
        <PhotoMultipleCard
          isRequired={false}
          isDeleteById={true}
          data={forms.photos}
          onAddPhoto={(value) => {
            if (isEditing) {
              handleAddPhoto(value[0].file)
            } else {
              handleForms('photos', [...forms.photos, ...value])
            }
          }}
          onDeletePhoto={(index, id) => {
            if (isEditing) {
              handleDeletePhoto(id)
            } else {
              const temp = [...forms.photos]
              temp.splice(index, 1)
              handleForms('photos', temp)
            }
          }}
        />
      </PhotoContainerCard>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Catatan</Typography.Text>
        </div>
        <Input.TextArea
          className="rounded-md bg-gray-300"
          value={forms?.description}
          onChange={(e) => handleForms('description', e.target.value)}
        />
        {!forms.description && (
          <Typography.Text className="mt-1 block text-red-500 text-xs">
            *Silahkan masukkan catatan
          </Typography.Text>
        )}
      </Card>
      <div className="flex justify-end items-center mb-1">
        <Button
          className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => router.go(-1)}>
          Kembali
        </Button>
        <Button
          className="p-0 px-4 py-2 bg-primary-500 border-primary-500 border font-medium text-white hover:opacity-90"
          onClick={saveData}>
          Submit
        </Button>
      </div>
    </Content>
  )
}

export default UpdateExTaxiUnitComponent
