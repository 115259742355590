import { Breadcrumb, Layout } from 'antd'
import {
  CatatanCard,
  InformasiServisCard,
  InformasiUnitCard,
  InformasiUserCard,
  InspeksiCard,
  PaymentReceiptCard
} from 'components'
import InformasiSpekUnitHorizontalCard from 'components/card/informasi-spek-unit-horizontal-card'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

const dummyData = {
  id: 0,
  cabang: 'Birdmobil Surabaya',
  kode: 'J-123',
  tipe: 'Servis',
  unit: '2019 Toyota Kijang Innova',
  sales: 'Rp 400.000.000',
  serviceDetail: {
    tempat: 'Birdmobil Surabaya',
    tanggal: '24/10/2022',
    waktu: '07.00 - 09.00',
    stall: '2',
    mekanik: 'Nielsen Norman',
    statusInspeksi: 'Selesai'
  },
  ownerDetail: {
    nama: 'Matty Healy',
    email: 'matty1975@gmail.com',
    telepon: '083944857222',
    alamat: 'Jln. Danau Ranau G7E/17, Sawojajar, Malang, Jawa Timur'
  },
  unitDetail: {
    id: 0,
    merek: 'Toyota',
    model: 'Kijang',
    tipe: 'Innova',
    platNomor: 'AB 2239 AAA',
    tahun: '2019',
    owner: 'Erling Haland',
    riwayatService: '22/09/2022',
    transmisi: 'Automatic',
    warna: 'Silver',
    bahanBakar: 'Bensin',
    noRangka: 'MMDJSDHFII009233312',
    noMesin: 'BBMM2231',
    tag: 'SUV, Mobil Keluarga'
  },
  catatan:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc.',
  paymentDetail: {
    listServices: [
      {
        serviceName: 'Ganti Oli',
        products: [{ itemName: 'Shell Hellix 5L', totalItems: '3x', totalPrice: 'Rp 200.000' }]
      },
      {
        serviceName: 'Tune up mesin',
        products: [{ itemName: '2.0 VTEC Turbo', totalPrice: 'Rp 500.000' }]
      }
    ],
    total: 750000,
    discountVoucher: 50000,
    totalPrice: 700000
  }
}

const DetailServisAnalyticsPage = () => {
  const { cabang } = useParams()
  const { push } = useHistory()

  return (
    <Layout className="grid grid-cols-1 gap-6 p-8">
      {/* BREADCRUMB */}
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Transaksi
        </Breadcrumb.Item>
      </Breadcrumb>
      {dummyData?.tipe === 'Jual Beli' ? (
        <InformasiUnitCard
          unit={dummyData?.unit}
          status={dummyData.serviceDetail.status}
          harga={dummyData.serviceDetail.harga}
        />
      ) : (
        <InformasiServisCard tipe={dummyData?.tipe} data={dummyData.serviceDetail} />
      )}
      <InformasiUserCard title={'Informasi Pemilik'} data={dummyData?.ownerDetail} />
      {dummyData?.buyerDetail ? (
        <InformasiUserCard title={'Informasi Pembeli'} data={dummyData?.buyerDetail} />
      ) : null}
      {dummyData?.unitDetail ? (
        <InformasiSpekUnitHorizontalCard data={dummyData?.unitDetail} />
      ) : null}
      {dummyData?.catatan ? <CatatanCard desc={dummyData?.catatan} /> : null}
      {dummyData.tipe === 'Inspeksi' ? (
        <InspeksiCard
          onGoToDetailInspeksi={() => push(`/jual-unit/katalog/detail-inspeksi/${cabang}`)}
          data={{
            id: 51,
            grade: 'A',
            inspectionCategoryList: [
              { id: 1, name: 'Eksterior', count: '0/3' },
              { id: 2, name: 'Interior', count: '0/1' },
              { id: 3, name: 'Test Jalan', count: '3/5' },
              { id: 4, name: 'Bagian Kosong', count: '5/5' },
              { id: 5, name: 'Egines', count: '3/3' }
            ]
          }}
        />
      ) : null}
      <PaymentReceiptCard data={dummyData?.paymentDetail} />
    </Layout>
  )
}

export default DetailServisAnalyticsPage
