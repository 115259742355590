import React, { useEffect } from 'react'

const ModalContainer = ({ children, zIndex }) => {
  const z = zIndex ?? 'z-10'

  useEffect(() => {
    document.body.style.overflow = 'hidden'
  }, [])

  return (
    <div
      className={`flex justify-center bg-gray-500 bg-opacity-40 items-center overflow-x-hidden overflow-y-auto ${z} fixed inset-0 outline-none focus:outline-none`}>
      {children}
    </div>
  )
}

export default ModalContainer
