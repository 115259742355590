import React, { useState } from 'react'
import { BaseIcon, FormInputRupiah, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Formik } from 'formik'
import { Form } from 'antd'

const ModalBeliStockUnit = ({ onClose, onSubmit, state }) => {
  const fontFieldStyle = 'font-semibold text-lg text-gray-900'
  const fontValueStyle = 'font-normal text-md text-gray-700'
  const [isRequiredHarga, setIsRequiredHarga] = useState(false)
  const [price, setPrice] = useState(state?.price)

  return (
    <ModalContainer>
      <ModalForm>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-xl">Konfirmasi Beli Unit</span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div className="flex flex-col w-full space-y-3">
            <div className="flex flex-col space-y-1">
              <span className={fontFieldStyle}>Unit</span>
              <span className={fontValueStyle}>{state.unit ?? '-'}</span>
            </div>
            <div className="flex flex-col space-y-1">
              <span className={fontFieldStyle}>Pemilik</span>
              <span className={fontValueStyle}>{state.owner ?? '-'}</span>
            </div>
            <div className="flex flex-col space-y-1">
              <span className={fontFieldStyle}>No Telp</span>
              <span className={fontValueStyle}>{state.phone ?? '-'}</span>
            </div>
            <div className="flex flex-col space-y-1">
              <span className={fontFieldStyle}>Harga</span>
              <Formik
                initialValues={{
                  price: price
                }}>
                <Form key="beliunit">
                  <FormInputRupiah
                    name="price"
                    value={price}
                    mode="normal"
                    isWithLabelRp
                    isEmptyField={isRequiredHarga}
                    isRequired={isRequiredHarga}
                    message="Silahkan Masukkan Harga"
                    onChange={(value) => {
                      setPrice(value)
                      if (value && value !== '') {
                        setIsRequiredHarga(false)
                      } else {
                        setIsRequiredHarga(true)
                      }
                    }}
                  />
                </Form>
              </Formik>
            </div>
            <div className="flex flex-row w-full items-center justify-end  space-x-4">
              <button
                type="button"
                className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                type="button"
                onClick={() => onSubmit(price)}
                className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalBeliStockUnit
