import React from 'react'
import {
  CardContainer,
  InformasiPenjualanCard,
  InformasiUnitExTaxiCard,
  PaymentReceiptSingleCard
} from 'components'
import moment from 'moment'
import 'moment/locale/en-gb'
import { formatCurrency } from 'utils'
import _ from 'lodash'

const BookingHistoryExTaxiContent = ({ data }) => {
  return !data ? (
    <CardContainer>
      <span className="font-medium text-lg text-gray-900">
        Data Riwayat Pembelian Ex-Taxi Tidak Ditemukan.
      </span>
    </CardContainer>
  ) : (
    <div className="flex flex-col w-full space-y-5">
      <InformasiPenjualanCard
        pembeli={
          data.booking_taxi.buyer_fullname && data.booking_taxi.buyer_fullname !== ''
            ? _.startCase(data.booking_taxi.buyer_fullname)
            : '-'
        }
        tanggal={moment(data.booking_taxi.created_at).format('DD/MM/YYYY')}
        harga={formatCurrency(data.taxi_invoice.total_price)}
      />
      <InformasiUnitExTaxiCard
        data={{
          merek: data.taxi.brand_name && data.taxi.brand_name !== '' ? data.taxi.brand_name : '-',
          model: data.taxi.model_name && data.taxi.model_name !== '' ? data.taxi.model_name : '-',
          transmisi:
            data.taxi.transmission_name && data.taxi.transmission_name !== ''
              ? data.taxi.transmission_name
              : '-',
          bahanBakar: data.taxi.fuel_name && data.taxi.fuel_name !== '' ? data.taxi.fuel_name : '-',
          warna:
            data.booking_taxi.color_name && data.booking_taxi.color_name !== ''
              ? data.booking_taxi.color_name
              : '-'
        }}
      />
      <PaymentReceiptSingleCard
        data={{
          unitName: data.taxi.name,
          unitPrice: data.taxi_invoice.subtotal_price,
          adminPrice: data.taxi_invoice.admin_price,
          taxFee: data.taxi_invoice.tax_fee ?? 0,
          totalPrice: data.taxi_invoice.total_price
        }}
      />
    </div>
  )
}

export default BookingHistoryExTaxiContent
