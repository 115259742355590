import { apiGet, urls } from 'api'
import { IcEdit, IcTrash } from 'assets/icons'
import { Button } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import LayananServisComponent from './components/LayananServisComponent'
import ModalLayananServis from './components/ModalLayananServis'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const LayananServis = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [searchGeneral, setSearchGeneral] = useState('')
  const [searchPacket, setSearchPacket] = useState('')
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: {}
  })
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [listData, setListData] = useState({
    generalService: [],
    generalServiceBySearch: [],
    packetService: [],
    packetServiceBySearch: []
  })

  // Get Service Layanan
  const { isLoading, refetch, isFetching, isRefetching } = useQuery({
    queryKey: [
      'service-packet-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.service?.root}?keywords=${tableState.keywords}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  const columns = [
    {
      name: 'Nama Layanan',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-500">{row.name}</span>,
      sortable: true,
      grow: 1
    },
    {
      name: 'Daftar Servis Umum',
      selector: (row) => row?.general_services,
      cell: (row) => (
        <div className="flex flex-wrap my-2 pr-2 max-h-[140px] overflow-y-auto overflow-x-hidden">
          {row?.general_services?.map((data, index) => (
            <span
              key={`subkategori-${index}`}
              className="px-2 mr-1.5 py-[2px] border-0 bg-[#EFF8FF] rounded-lg text-[#175CD3] mb-2 selection:text-black">
              {data?.name}
            </span>
          ))}
        </div>
      ),
      sortable: false,
      grow: 3
    },
    {
      name: 'Daftar Paket Servis',
      selector: (row) => row?.packets,
      cell: (row) => (
        <div className="flex flex-wrap my-2 pr-2 max-h-[140px] overflow-y-auto overflow-x-hidden">
          {row?.packets?.map((data, index) => (
            <span
              key={`subkategori-${index}`}
              className="px-2 mr-1.5 py-[2px] border-0 bg-[#EFF8FF] rounded-lg text-[#175CD3] mb-2 selection:text-black">
              {data?.name}
            </span>
          ))}
        </div>
      ),
      sortable: false,
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      // width: '100px',
      grow: 1,
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => setModal({ type: 'edit', show: true, data: row })}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ type: 'delete', show: true, data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  // Get All General Services By Search
  useQuery({
    queryKey: ['general-service-search-list', searchGeneral],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.all}?keywords=${searchGeneral}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({
        ...prev,
        generalServiceBySearch: res?.resData?.data
      }))
    }
  })

  // Get All General Services
  useQuery({
    queryKey: ['general-service-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({
        ...prev,
        generalService: res?.resData?.data
      }))
    }
  })

  // Get All packet service By Search
  useQuery({
    queryKey: ['service-packet-search-list', searchPacket],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.servicePackets?.root}?keywords=${searchPacket}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({
        ...prev,
        packetServiceBySearch: res?.resData?.data
      }))
    }
  })

  // Get All packet service
  useQuery({
    queryKey: ['service-packet-list', searchPacket],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.servicePackets?.root}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({
        ...prev,
        packetService: res?.resData?.data
      }))
    }
  })

  return (
    <>
      <ModalLayananServis
        isOpen={modal.show}
        type={modal.type}
        data={modal.data}
        onCancel={() => setModal({ ...modal, show: false })}
        listData={listData}
        searchGeneral={searchGeneral}
        setSearchGeneral={setSearchGeneral}
        searchPacket={searchPacket}
        setSearchPacket={setSearchPacket}
        refetch={refetch}
        onLogout={onLogout}
      />
      <LayananServisComponent
        columns={columns}
        data={tableState?.listData}
        onAdd={() => setModal({ show: true, type: 'add', data: {} })}
        isLoading={isLoading || isFetching || isRefetching}
        tableState={tableState}
        setTableState={setTableState}
      />
    </>
  )
}

export default LayananServis
