import React, { useState } from 'react'
import { BaseIcon, FormDropDown, FormInputRupiah, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Formik } from 'formik'
import { apiGet, urls } from 'api'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import moment from 'moment'
import { DatePicker, Select } from 'antd'
import { showErrorMessage } from 'utils'

const ModalCreateUpdateHargaRekomendasi = ({
  onClose,
  onSubmit,
  state,
  dataDropDownBrand,
  dataDropDownTransmisi
}) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const onLogout = () => dispatch(logout())

  const [dataDropDownModel, setDataDropDownModel] = useState([{ name: 'Pilih Model', value: -1 }])
  const [dataDropDownTipe, setDataDropDownTipe] = useState([{ name: 'Pilih Tipe', value: -1 }])
  const [brandSelectorState, setBrandSelectorState] = useState(
    state.typeCRUD === 'update' ? state.data.brand.id : -1
  )
  const [modelSelectorState, setModelSelectorState] = useState(
    state.typeCRUD === 'update' ? state.data.model.id : -1
  )
  const [tipeSelectorState, setTipeSelectorState] = useState(
    state.typeCRUD === 'update' ? state.data.type.id : -1
  )
  const [transmisiSelectorState, setTransmisiSelectorState] = useState(
    state.typeCRUD === 'update'
      ? state.data.transmission.id && state.data.transmission.id > 0
        ? state.data.transmission.id
        : -1
      : -1
  )
  const [minPrice, setMinPrice] = useState(
    state.typeCRUD === 'update' ? state.data.start_price : ''
  )
  const [maxPrice, setMaxPrice] = useState(state.typeCRUD === 'update' ? state.data.end_price : '')

  const [year, setYear] = useState({
    value: moment(state.data.year, 'YYYY'),
    parse: state.typeCRUD === 'update' ? state.data.year : ''
  })

  useQuery({
    queryKey: ['model-by-brand', brandSelectorState],
    refetchOnWindowFocus: false,
    enabled: brandSelectorState > -1,
    queryFn: async () =>
      apiGet({
        url: `${urls.master.prefix}/${urls.master.products.model.all}${
          brandSelectorState > -1 ? `?brand_id=${brandSelectorState}` : ''
        }`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDataDropDownModel([{ name: 'Pilih Model', value: -1 }])
      if (data.resData.data.length !== 0) {
        data.resData.data.map((item) => {
          setDataDropDownModel((prev) => [...prev, { name: item.name, value: item.id }])
        })
      }
    },
    onError: () => setDataDropDownModel([{ name: 'Pilih Model', value: -1 }])
  })

  useQuery({
    queryKey: ['tipe-by-model', modelSelectorState],
    refetchOnWindowFocus: false,
    enabled: modelSelectorState > -1,
    queryFn: async () =>
      apiGet({
        url: `${urls.master.prefix}/${urls.master.products.tipe.all}${
          modelSelectorState > -1 ? `?model_id=${modelSelectorState}` : ''
        }`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDataDropDownTipe([{ name: 'Pilih Tipe', value: -1 }])
      if (data.resData.data.length !== 0) {
        data.resData.data.map((item) => {
          setDataDropDownTipe((prev) => [...prev, { name: item.name, value: item.id }])
        })
      }
    },
    onError: () => setDataDropDownTipe([{ name: 'Pilih Tipe', value: -1 }])
  })

  const [isEmptyForm, setIsEmptyForm] = useState({
    brand: {
      isEmpty: false,
      message: 'Silahkan Pilih Brand'
    },
    model: {
      isEmpty: false,
      message: 'Silahkan Pilih Model'
    },
    tipe: {
      isEmpty: false,
      message: 'Silahkan Pilih Tipe'
    },
    year: {
      isEmpty: false,
      message: 'Silahkan Pilih Tanggal Keluaran'
    },
    minPrice: {
      isEmpty: false,
      message: 'Masukkan Min Harga'
    },
    maxPrice: {
      isEmpty: false,
      message: 'Masukkan Max Harga'
    }
  })

  return (
    <ModalContainer>
      <ModalForm minWidth="min-w-[27%]" maxWidth="max-w-[27%]" zIndex="z-20">
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.typeCRUD === 'create' ? 'Tambah' : 'Edit'} Harga Rekomendasi`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div className="flex flex-col w-full space-y-5">
            <div className="flex flex-col w-full space-y-2 mb-2">
              <span className="font-medium block text-md text-gray-700">
                Brand
                <span className="text-red-600">{' *'}</span>
              </span>
              <div className="relative">
                <Select
                  showSearch
                  allowClear
                  className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                  value={brandSelectorState}
                  onChange={async (value) => {
                    setBrandSelectorState(value ? parseInt(value) : -1)
                    setModelSelectorState(-1)
                    setTipeSelectorState(-1)
                    setDataDropDownModel([{ name: 'Pilih Model', value: -1 }])
                    setDataDropDownTipe([{ name: 'Pilih Tipe', value: -1 }])
                    if (!value || parseInt(value) < 1) {
                      setIsEmptyForm({
                        ...isEmptyForm,
                        brand: { ...isEmptyForm.brand, isEmpty: true },
                        model: { ...isEmptyForm.model, isEmpty: true },
                        tipe: { ...isEmptyForm.tipe, isEmpty: true }
                      })
                    } else {
                      setIsEmptyForm({
                        ...isEmptyForm,
                        brand: { ...isEmptyForm.brand, isEmpty: false }
                      })
                    }
                  }}
                  onSearch={(e) => {}}
                  optionFilterProp="children"
                  placeholder="Pilih/Search Brand">
                  {dataDropDownBrand.map((itemCh, indexCh) => (
                    <Select.Option key={indexCh} value={itemCh.value}>
                      {itemCh.name}
                    </Select.Option>
                  ))}
                </Select>
                {isEmptyForm.brand.isEmpty ? (
                  <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                    {`*${isEmptyForm.brand.message}`}
                  </div>
                ) : null}
              </div>
            </div>
            {/* <Formik
              enableReinitialize
              initialValues={{
                brandSelector: brandSelectorState
              }}>
              <FormDropDown
                label="Brand"
                name="brandSelector"
                isRequired
                isEmpty={isEmptyForm.brand.isEmpty}
                errorMessage={isEmptyForm.brand.message}
                data={dataDropDownBrand}
                onChange={async (value) => {
                  setBrandSelectorState(parseInt(value))
                  setModelSelectorState(-1)
                  setTipeSelectorState(-1)
                  setDataDropDownModel([{ name: 'Pilih Model', value: -1 }])
                  setDataDropDownTipe([{ name: 'Pilih Tipe', value: -1 }])
                  if (!value || parseInt(value) < 1) {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      brand: { ...isEmptyForm.brand, isEmpty: true },
                      model: { ...isEmptyForm.model, isEmpty: true },
                      tipe: { ...isEmptyForm.tipe, isEmpty: true }
                    })
                  } else {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      brand: { ...isEmptyForm.brand, isEmpty: false }
                    })
                  }
                }}
              />
            </Formik> */}
            <div className="flex flex-col w-full space-y-2 mb-2">
              <span className="font-medium block text-md text-gray-700">
                Model
                <span className="text-red-600">{' *'}</span>
              </span>
              <div className="relative">
                <Select
                  showSearch
                  allowClear
                  className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                  value={modelSelectorState}
                  onChange={async (value) => {
                    setModelSelectorState(value ? parseInt(value) : -1)
                    setTipeSelectorState(-1)
                    if (!parseInt(value > -1)) {
                      setDataDropDownTipe([{ name: 'Pilih Tipe', value: -1 }])
                    }
                    if (!value || parseInt(value) < 1) {
                      setIsEmptyForm({
                        ...isEmptyForm,
                        model: { ...isEmptyForm.model, isEmpty: true },
                        tipe: { ...isEmptyForm.tipe, isEmpty: true }
                      })
                    } else {
                      setIsEmptyForm({
                        ...isEmptyForm,
                        model: { ...isEmptyForm.model, isEmpty: false }
                      })
                    }
                  }}
                  onSearch={(e) => {}}
                  optionFilterProp="children"
                  placeholder="Pilih/Search Model">
                  {dataDropDownModel.map((itemCh, indexCh) => (
                    <Select.Option key={indexCh} value={itemCh.value}>
                      {itemCh.name}
                    </Select.Option>
                  ))}
                </Select>
                {isEmptyForm.model.isEmpty ? (
                  <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                    {`*${isEmptyForm.model.message}`}
                  </div>
                ) : null}
              </div>
            </div>
            {/* <Formik
              enableReinitialize
              initialValues={{
                modelSelector: modelSelectorState
              }}>
              <FormDropDown
                label="Model"
                name="modelSelector"
                isRequired
                isEmpty={isEmptyForm.model.isEmpty}
                errorMessage={isEmptyForm.model.message}
                data={dataDropDownModel}
                onChange={async (value) => {
                  setModelSelectorState(parseInt(value))
                  setTipeSelectorState(-1)
                  if (!parseInt(value > -1)) {
                    setDataDropDownTipe([{ name: 'Pilih Tipe', value: -1 }])
                  }
                  if (!value || parseInt(value) < 1) {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      model: { ...isEmptyForm.model, isEmpty: true },
                      tipe: { ...isEmptyForm.tipe, isEmpty: true }
                    })
                  } else {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      model: { ...isEmptyForm.model, isEmpty: false }
                    })
                  }
                }}
              />
            </Formik> */}
            <div className="flex flex-col w-full space-y-2 mb-2">
              <span className="font-medium block text-md text-gray-700">
                Tipe
                <span className="text-red-600">{' *'}</span>
              </span>
              <div className="relative">
                <Select
                  showSearch
                  allowClear
                  className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                  value={tipeSelectorState}
                  onChange={async (value) => {
                    setTipeSelectorState(value ? parseInt(value) : -1)
                    if (!value || parseInt(value) < 1) {
                      setIsEmptyForm({
                        ...isEmptyForm,
                        tipe: { ...isEmptyForm.tipe, isEmpty: true }
                      })
                    } else {
                      setIsEmptyForm({
                        ...isEmptyForm,
                        tipe: { ...isEmptyForm.tipe, isEmpty: false }
                      })
                    }
                  }}
                  onSearch={(e) => {}}
                  optionFilterProp="children"
                  placeholder="Pilih/Search Tipe">
                  {dataDropDownTipe.map((itemCh, indexCh) => (
                    <Select.Option key={indexCh} value={itemCh.value}>
                      {itemCh.name}
                    </Select.Option>
                  ))}
                </Select>
                {isEmptyForm.tipe.isEmpty ? (
                  <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                    {`*${isEmptyForm.tipe.message}`}
                  </div>
                ) : null}
              </div>
            </div>
            {/* <Formik
              enableReinitialize
              initialValues={{
                tipeSelector: tipeSelectorState
              }}>
              <FormDropDown
                label="Tipe"
                name="tipeSelector"
                isRequired
                isEmpty={isEmptyForm.tipe.isEmpty}
                errorMessage={isEmptyForm.tipe.message}
                data={dataDropDownTipe}
                onChange={async (value) => {
                  setTipeSelectorState(parseInt(value))
                  if (!value || parseInt(value) < 1) {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      tipe: { ...isEmptyForm.tipe, isEmpty: true }
                    })
                  } else {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      tipe: { ...isEmptyForm.tipe, isEmpty: false }
                    })
                  }
                }}
              />
            </Formik> */}
            <div className="flex flex-col w-full space-y-2 mb-2">
              <span className="font-medium block text-md text-gray-700">Transmisi</span>
              <Select
                showSearch
                allowClear
                className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
                value={transmisiSelectorState}
                onChange={async (value) => setTransmisiSelectorState(value ? parseInt(value) : -1)}
                onSearch={(e) => {}}
                optionFilterProp="children"
                placeholder="Pilih/Search Transmisi">
                {dataDropDownTransmisi.map((itemCh, indexCh) => (
                  <Select.Option key={indexCh} value={itemCh.value}>
                    {itemCh.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            {/* <Formik
              enableReinitialize
              initialValues={{
                transmisiSelector: transmisiSelectorState
              }}>
              <FormDropDown
                label="Transmisi"
                name="transmisiSelector"
                data={dataDropDownTransmisi}
                onChange={async (value) => setTransmisiSelectorState(parseInt(value))}
              />
            </Formik> */}
            <div className="flex flex-col w-full space-y-0">
              <label className="font-medium block mb-2 text-md text-gray-700">
                Tahun Keluaran
                <span className="text-red-600">{' *'}</span>
              </label>
              <DatePicker
                placeholder="Tahun Keluaran"
                picker="year"
                value={year.parse === '' ? '' : year.value}
                onChange={(value) => {
                  setYear({
                    value: value,
                    parse: value && value !== '' ? moment(value, 'YYYY').format('YYYY') : ''
                  })
                  if (value) {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      year: {
                        ...isEmptyForm.year,
                        isEmpty: false
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      ...isEmptyForm,
                      year: {
                        ...isEmptyForm.year,
                        isEmpty: true
                      }
                    })
                  }
                }}
                className="custom-date-picker py-2.5 w-full"
                popupClassName="custom-date-picker-dropdown"
                format={['YYYY']}
              />
              {isEmptyForm.year.isEmpty ? (
                <div className="flex justify-start items-start px-1 bg-transparent font-semibold text-xs text-red-500">
                  {`*${isEmptyForm.year.message}`}
                </div>
              ) : null}
            </div>
            <div className="flex flex-col space-y-0 w-full">
              <label className="font-medium block mb-2 text-md text-gray-700">
                Harga Rekomendasi
                <span className="text-red-600">{' *'}</span>
              </label>
              <div className="flex flex-row items-center justify-between w-full space-x-2">
                <Formik
                  initialValues={{
                    minPrice
                  }}>
                  <FormInputRupiah
                    name="minPrice"
                    value={minPrice}
                    mode="normal"
                    placeholder=""
                    isWithLabelRp
                    isRequired
                    isEmptyField={isEmptyForm.minPrice.isEmpty}
                    message={isEmptyForm.minPrice.message}
                    onChange={(value) => {
                      setMinPrice(value)
                      if (!value) {
                        setIsEmptyForm({
                          ...isEmptyForm,
                          minPrice: {
                            ...isEmptyForm.minPrice,
                            isEmpty: true
                          }
                        })
                      } else {
                        setIsEmptyForm({
                          ...isEmptyForm,
                          minPrice: {
                            ...isEmptyForm.minPrice,
                            isEmpty: false
                          }
                        })
                      }
                    }}
                  />
                </Formik>
                <span className="font-bold">-</span>
                <Formik
                  initialValues={{
                    maxPrice
                  }}>
                  <FormInputRupiah
                    name="maxPrice"
                    value={maxPrice}
                    mode="normal"
                    placeholder=""
                    isWithLabelRp
                    isRequired
                    isEmptyField={isEmptyForm.maxPrice.isEmpty}
                    message={isEmptyForm.maxPrice.message}
                    onChange={(value) => {
                      setMaxPrice(value)
                      if (!value) {
                        setIsEmptyForm({
                          ...isEmptyForm,
                          maxPrice: {
                            ...isEmptyForm.maxPrice,
                            isEmpty: true
                          }
                        })
                      } else {
                        setIsEmptyForm({
                          ...isEmptyForm,
                          maxPrice: {
                            ...isEmptyForm.maxPrice,
                            isEmpty: false
                          }
                        })
                      }
                    }}
                  />
                </Formik>
              </div>
            </div>
            <div className="flex flex-row w-full items-center justify-end space-x-4">
              <button
                type="button"
                className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  if (
                    brandSelectorState &&
                    brandSelectorState > 0 &&
                    modelSelectorState &&
                    modelSelectorState > 0 &&
                    tipeSelectorState &&
                    tipeSelectorState > 0 &&
                    year.parse &&
                    year.parse !== '' &&
                    minPrice &&
                    minPrice !== '' &&
                    maxPrice &&
                    maxPrice !== '' &&
                    maxPrice > minPrice
                  ) {
                    let data = {}
                    if (transmisiSelectorState && transmisiSelectorState > 0) {
                      data = {
                        id: state.data.id,
                        brand_id: brandSelectorState,
                        model_id: modelSelectorState,
                        type_id: tipeSelectorState,
                        transmission_id: transmisiSelectorState,
                        year: year.parse,
                        start_price: minPrice,
                        end_price: maxPrice
                      }
                    } else {
                      data = {
                        id: state.data.id,
                        brand_id: brandSelectorState,
                        model_id: modelSelectorState,
                        type_id: tipeSelectorState,
                        transmission_id: 0,
                        year: year.parse,
                        start_price: minPrice,
                        end_price: maxPrice
                      }
                    }
                    onSubmit(data)
                  } else if (
                    maxPrice != '' &&
                    minPrice != '' &&
                    Number(maxPrice) < Number(minPrice)
                  ) {
                    showErrorMessage({
                      content: 'Max Harga tidak boleh lebih kecil dari Min Harga!'
                    })
                  } else {
                    setIsEmptyForm({
                      brand: {
                        ...isEmptyForm.brand,
                        isEmpty: !brandSelectorState || brandSelectorState < 1 ? true : false
                      },
                      model: {
                        ...isEmptyForm.model,
                        isEmpty: !modelSelectorState || modelSelectorState < 1 ? true : false
                      },
                      tipe: {
                        ...isEmptyForm.tipe,
                        isEmpty: !tipeSelectorState || tipeSelectorState < 1 ? true : false
                      },
                      year: {
                        ...isEmptyForm.year,
                        isEmpty: year.parse && year.parse !== '' ? false : true
                      },
                      minPrice: {
                        ...isEmptyForm.minPrice,
                        isEmpty: !minPrice || minPrice === '' ? true : false
                      },
                      maxPrice: {
                        ...isEmptyForm.maxPrice,
                        isEmpty: !maxPrice || maxPrice === '' ? true : false
                      }
                    })
                  }
                }}
                className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                {`${state.typeCRUD === 'create' ? 'Tambah Harga Rekomendasi' : 'Simpan'}`}
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateHargaRekomendasi
