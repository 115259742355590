import { CardContainer, Table } from 'components'

const HistoryUnitServicesInspectionCard = ({
  title,
  data,
  columns,
  isLoading,
  tableState,
  setTableState = () => {}
}) => {
  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }
  return (
    <CardContainer minHeight="40%">
      <div className="flex flex-col w-full items-start border border-solid border-b-1 border-x-0 border-t-0 pb-4 mt4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">{title ?? 'Riwayat'}</span>
      </div>
      <div className="h-[calc(100%-57px)] flex flex-col justify-between">
        <Table
          columns={columns}
          data={data}
          isStyleHistory={true}
          noTableHead={true}
          stripped={false}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </div>
    </CardContainer>
  )
}

export default HistoryUnitServicesInspectionCard
