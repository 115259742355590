import { CustomButton, Loading } from 'components'
import ModalLayout from 'layouts/modal-layout'
import React from 'react'
const ModalKonfirmasiPickupSelesai = ({ openModal, onClose, onSubmit, desc, loading = false }) => {
  return (
    <ModalLayout
      className={'max-w-sm'}
      headerTitle={'Konfirmasi Pickup Selesai'}
      onCancel={onClose}
      openModal={openModal}
      loading={loading}>
      <div className="flex flex-col w-full gap-4">
        <span className="font-medium text-left text-gray-500">{desc}</span>
        <div className="flex flex-row w-full items-center justify-end space-x-4">
          {loading ? (
            <Loading title="" visible classNameWidth="" />
          ) : (
            <>
              <CustomButton type="plain" onClick={() => onClose()}>
                Cancel
              </CustomButton>
              <CustomButton onClick={() => onSubmit()}>Confirm</CustomButton>
            </>
          )}
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalKonfirmasiPickupSelesai
