import React from 'react'
import { CardContainer } from 'components'

const InformasiServisSection = ({
  tempat,
  tanggal,
  waktu,
  stall,
  mekanik,
  statusInspeksi
}) => {
  const fieldStyle = 'font-medium text-gray-900 text-lg'
  const valueStyle = 'font-normal text-gray-700 text-md'

  return (
    <CardContainer
      minHeight="20%"
    >
      <div
        className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300"
      >
        <span className="font-semibold text-2xl text-gray-900">
                   Informasi Servis
        </span>
      </div>
      <div className="grid grid-cols-4 gap-4 w-full pt-4">
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Tempat
          </span>
          <span className={valueStyle}>
            {tempat}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Tanggal
          </span>
          <span className={valueStyle}>
            {tanggal}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Waktu
          </span>
          <span className={valueStyle}>
            {waktu}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Stall
          </span>
          <span className={valueStyle}>
            {stall}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Mekanik
          </span>
          <span className={valueStyle}>
            {mekanik}
          </span>
        </div>
        <div className="flex flex-col">
          <span className={fieldStyle}>
                Status
          </span>
          <div
            className="flex w-[20%] w-13 px-3 py-1 bg-[#ECFDF3] rounded-xl items-center justify-center"
          >
            <span className="font-normal text-[#027948] text-md">
              {statusInspeksi}
            </span>
          </div>
        </div>
      </div>
    </CardContainer>
  )
}

export default InformasiServisSection
