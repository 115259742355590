import React from 'react'
import { CardContainer, FormRadio } from 'components'
import { Formik } from 'formik'

const DocumentForm = ({
  state,
  stnk,
  bpkb,
  faktur,
  ktp,
  kwitansi,
  keur,
  formA,
  onChange = () => {}
}) => (
  <CardContainer className="pb-8">
    <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
      <span className="font-semibold text-2xl text-gray-900">Dokumen</span>
    </div>
    <div className="flex flex-row w-full h-full items-center mt-4 space-x-20">
      <div className="flex flex-col w-full h-full space-y-2">
        <Formik>
          <FormRadio
            title="STNK"
            data={stnk}
            check={state.stnk}
            onChange={(value) => onChange('stnk', value)}
          />
        </Formik>
        <Formik>
          <FormRadio
            title="BPKB"
            data={bpkb}
            check={state.bpkb}
            onChange={(value) => onChange('bpkb', value)}
          />
        </Formik>
        <Formik>
          <FormRadio
            title="Faktur"
            data={faktur}
            check={state.faktur}
            onChange={(value) => onChange('faktur', value)}
          />
        </Formik>
        <Formik>
          <FormRadio
            title="KTP Pemilik"
            data={ktp}
            check={state.ktp}
            onChange={(value) => onChange('ktp', value)}
          />
        </Formik>
      </div>
      <div className="flex flex-col w-full h-full space-y-6">
        <Formik>
          <FormRadio
            title="Kwitansi"
            data={kwitansi}
            check={state.kwitansi}
            onChange={(value) => onChange('kwitansi', value)}
          />
        </Formik>
        <Formik>
          <FormRadio
            title="KEUR"
            isRequired={false}
            data={keur}
            check={state.keur}
            onChange={(value) => onChange('keur', value)}
          />
        </Formik>
        <Formik>
          <FormRadio
            title="Form A"
            data={formA}
            check={state.formA}
            onChange={(value) => onChange('formA', value)}
          />
        </Formik>
      </div>
    </div>
  </CardContainer>
)

export default DocumentForm
