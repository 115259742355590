import React, { useEffect, useState } from 'react'
import { Input, Modal, Typography } from 'antd'
import { validationInput } from 'helpers'

const ModalJualMobil = ({ isOpen, onCancel, data, onSubmit }) => {
  const [harga, setHarga] = useState({
    value: data?.price ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  })

  useEffect(() => {
    if (isOpen) {
      setHarga({
        ...harga,
        value: new Intl.NumberFormat('en-DE').format(data?.price ?? '0'),
        touched: !!data?.price,
        valid: validationInput(data?.price, harga.validation).valid,
        messageError: validationInput(data?.price, harga.validation).messageError
      })
    }
  }, [isOpen])

  const onOk = () => {
    if (!harga.valid) {
      alert('error')
      return
    }
    onSubmit({
      price: harga?.value?.split(".").join("") ?? "0"
    })
  }

  const onChangeInput = (value, identifier, setIdentifier) => {
    let newValue = value?.split(".").join("");
    if(isNaN(newValue)) {
      newValue = identifier?.value?.split(".").join("");
    }
    let { valid, messageError } = validationInput(newValue, identifier?.validation)
    setIdentifier({
      ...identifier,
      touched: true,
      value: new Intl.NumberFormat('en-DE').format(newValue ?? '0'),
      valid: valid,
      messageError: messageError
    })
  }

  return (
    <Modal
      title="Konfirmasi Jual Unit"
      width={'300px'}
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      okText={'Confirm'}
      okButtonProps={{
        className: 'bg-primary-500',
        type: 'danger',
        disabled: !harga.valid
      }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
      maskClosable={false}>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Unit
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded`}
          placeholder="Unit"
          value={data?.unit_name}
          readOnly
        />
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Harga
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !harga.valid && harga.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Harga"
          prefix="Rp. "
          value={harga.value}
          onChange={(e) => onChangeInput(e.target.value, harga, setHarga)}
        />
        {!harga.valid && harga.touched && <span className="text-[red]">{harga.messageError}</span>}
      </div>
    </Modal>
  )
}

export default ModalJualMobil
