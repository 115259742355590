import { apiGet, urls } from 'api'
import { IcChrome, IcEye, IcFlag, IcShoppingBag } from 'assets/icons'
import { AccumulationCard, CardContainer, Table } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { formatCurrency, showErrorMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: []
}

const TransaksiCabangContent = () => {
  const { cabang: idCabang } = useParams()
  const { push } = useHistory()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [dataCabang, setDataCabang] = useState()
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [listTotal, setListTotal] = useState({
    transaction: 0,
    unit: 0,
    testDrive: 0
  })

  const accumulationData = [
    {
      label: 'Total Pendapatan',
      icon: <IcShoppingBag className={'stroke-success-500'} />,
      desc: formatCurrency(listTotal?.transaction ?? 0),
      bgClassColor: 'bg-success-50',
      path: 'total-pendapatan'
    },
    {
      label: 'Total Unit',
      icon: <IcFlag className={'stroke-primary-500'} />,
      desc: listTotal?.unit,
      bgClassColor: 'bg-primary-50',
      path: 'total-unit'
    },
    {
      label: 'Test Drive',
      icon: <IcChrome className={'stroke-blue-500'} />,
      desc: listTotal?.testDrive,
      bgClassColor: 'bg-blue-50',
      path: 'test-drive'
    }
  ]

  const columnStyle = 'font-medium text-gray-700'

  const columns = [
    {
      name: 'Kode',
      selector: (row) => row?.transaction_code,
      cell: (row) => <span className={columnStyle}>{row?.transaction_code}</span>,
      grow: 1
    },
    {
      name: 'Tipe',
      selector: (row) => row?.transaction_type,
      cell: (row) => <span className={columnStyle}>{row?.transaction_type}</span>,
      grow: 1
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-2 py-3">
          <span className={columnStyle}>{row?.unit_name}</span>
          <span className="font-normal text-gray-500 tracking-tight">
            {row?.unit_police_number}
          </span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Sales',
      selector: (row) => formatCurrency(row?.transaction_profit ?? 0),
      cell: (row) => (
        <span className={columnStyle}>{formatCurrency(row?.transaction_profit ?? 0)}</span>
      ),
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          onClick={() =>
            row?.transaction_type?.toLowerCase() === 'beli'
              ? push(
                  `/workshop-management/${dataCabang?.name}/detail-transaksi/${row?.id}/${
                    row?.unit_id
                  }/${row?.transaction_type?.toLowerCase()}`
                )
              : push(
                  `/workshop-management/${dataCabang?.name}/detail-transaksi/${
                    row?.transaction_id
                  }/${row?.unit_id}/${row?.transaction_type?.toLowerCase()}`
                )
          }
          className="flex items-center gap-2 justify-center self-center px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
          <IcEye className="text-gray-700" />
          <span className="text-sm-medium text-gray-700">Lihat</span>
        </div>
      ),
      grow: 1
    }
  ]

  const handleNavigationReport = (path) => {
    push(`/workshop-management/${idCabang}/${dataCabang?.name}/${path}`)
  }

  // Get Api
  // Get Detail Cabang
  useQuery({
    queryKey: ['detail-cabang', idCabang],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}/${idCabang}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDataCabang(res?.resData?.data)
    }
  })

  // Get transaction list
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'transaction-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixUser}/${urls.analytic.transactionBranch.salesTotalList}/${idCabang}?keywords=${tableState.keywords}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get total testdrive
  useQuery({
    queryKey: ['testdrive-total', idCabang],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixJualBeli}/${urls.analytic.transactionBranch.testDriveTotal}/${idCabang}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, testDrive: res?.resData?.data ?? 0 }))
    }
  })

  // Get total sales
  useQuery({
    queryKey: ['sales-total', idCabang],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixUser}/${urls.analytic.transactionBranch.salesTotal}/${idCabang}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, transaction: res?.resData?.data ?? 0 }))
    }
  })

  // Get total unit
  useQuery({
    queryKey: ['mechanic-total', idCabang],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixMaster}/${urls.analytic.transactionBranch.unitTotal}/${idCabang}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, unit: res?.resData?.data ?? 0 }))
    }
  })

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <>
      {/* Total Section */}
      <div className="grid grid-cols-3 gap-6">
        {accumulationData.map((data, index) => (
          <AccumulationCard
            onClick={handleNavigationReport.bind(this, data.path)}
            key={index}
            data={data}
          />
        ))}
      </div>
      {/* Table Daftar Transaksi */}
      <CardContainer className={'grid grid-cols-1 gap-5'}>
        <p className="display-xs-semibold text-gray-900">Daftar Transaksi</p>

        <Table
          columns={columns}
          data={tableState?.listData}
          pending={isLoading ?? isFetching}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </CardContainer>
    </>
  )
}

export default TransaksiCabangContent
