import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import {
  CatatanCard,
  InformasiKelengkapanDokumen,
  InformasiUserCard,
  InformationInspeksiSecton,
  Loading,
  PhotoCarousel
} from 'components'
import InspeksiRecommendationServiceCard from 'components/card/inspeksi-recommendation-service-card'
import InspeksiResultCard from 'components/card/inspeksi-result-card'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { formatCurrency, formatThousandsINTL, pathStringify, showErrorMessage } from 'utils'

const InspeksiDetailPage = ({
  showOwner = true,
  showUnit = true,
  showInspeksi = true,
  showInspeksiHarga = false,
  showRecommendationService = false
}) => {
  const router = useHistory()
  const { id, unit } = useParams()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const [detail, setDetail] = useState({})
  const rootUrl = window.location.pathname.substring(1, window.location.length).split('/')

  const onReturnBreadcumbItem = (rootMenu) => {
    const parentBreadcrumbStyle = 'font-semibold text-gray-700'
    const childBreadcrumbStyle = 'font-medium text-gray-500'

    switch (rootMenu) {
      case 'services': {
        const { namaBengkel } = useParams()
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Servis</Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Pilih Bengkel</Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>{namaBengkel}</Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Audit Trail</Breadcrumb.Item>
          </>
        )
      }
      case 'booking-mobil': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Booking Mobil</Breadcrumb.Item>
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.push('/booking-mobil')}>
              Mobil Bekas
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.goBack()}>
              Detail Booking
            </Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Detail Inspeksi</Breadcrumb.Item>
          </>
        )
      }
      case 'analytics-dashboard': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Analytics Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.goBack()}>
              {rootUrl?.[1] === 'penjualan' ? 'Detail Transaksi' : 'Unit'}
            </Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>
              {detail?.unit_name ?? '-'}
            </Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Detail Inspeksi</Breadcrumb.Item>
          </>
        )
      }
      case 'inspeksi': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Inspeksi</Breadcrumb.Item>
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.push(`/inspeksi/jadwal-inspeksi`)}>
              Jadwal Inspeksi
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.goBack()}>
              {detail?.workshop_name ?? '-'}
            </Breadcrumb.Item>
            {/* <Breadcrumb.Item className={childBreadcrumbStyle}>
              {detail?.unit_name ?? '-'}
            </Breadcrumb.Item> */}
            <Breadcrumb.Item className={childBreadcrumbStyle}>Detail Inspeksi</Breadcrumb.Item>
          </>
        )
      }
      case 'workshop-management': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Workshop Management</Breadcrumb.Item>
            <Breadcrumb.Item className={`${childBreadcrumbStyle}`}>
              {detail?.workshop_name ?? '-'}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.goBack()}>
              Detail Transaksi
            </Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Detail Inspeksi</Breadcrumb.Item>
          </>
        )
      }
      case 'internal-unit': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Internal Unit</Breadcrumb.Item>
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.goBack()}>
              {detail?.unit_name ?? '-'}
            </Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Detail Inspeksi</Breadcrumb.Item>
          </>
        )
      }
      case 'jual-unit': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Jual Unit</Breadcrumb.Item>
            {rootUrl?.[1] === 'katalog' ? (
              <Breadcrumb.Item
                className={`${childBreadcrumbStyle} cursor-pointer`}
                onClick={() => router.push('/jual-unit/katalog')}>
                Katalog
              </Breadcrumb.Item>
            ) : null}
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.goBack()}>
              {detail?.unit_name ?? '-'}
            </Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Detail Inspeksi</Breadcrumb.Item>
          </>
        )
      }
      default:
        return window.location.pathname
          .substring(1, window.location.pathname.length)
          .split('/')
          .map((item, index) => (
            <Breadcrumb.Item
              key={index}
              className={`${index === 0 ? `${parentBreadcrumbStyle}` : `${childBreadcrumbStyle}`}`}>
              {pathStringify(item)}
            </Breadcrumb.Item>
          ))
    }
  }

  // Get Detail Unit
  const { isLoading } = useQuery({
    queryKey: ['detail-unit', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection.prefix}/${urls?.inspection.rootAdmin}/${id}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setDetail(res?.resData?.data ?? {})
    }
  })

  if (isLoading) {
    return <Loading visible />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        {onReturnBreadcumbItem(
          window.location.pathname.substring(1, window.location.length).split('/')[0]
        )}
      </Breadcrumb>
      <Content style={{ margin: 8 }} className="space-y-6">
        {showOwner && (
          <InformasiUserCard
            title="Informasi Pemilik"
            data={{
              nama: detail?.owner_name ?? '-',
              email: detail?.owner_email ?? '-',
              telepon: detail?.owner_phone_number ?? '-',
              alamat: detail?.owner_address ?? '-'
            }}
          />
        )}
        {showUnit && (
          <InformasiUserCard
            title="Informasi Unit"
            data={{
              unit: detail?.unit_name ?? '-',
              pemilik: detail?.owner_name ?? '-',
              telepon: detail?.owner_phone_number ?? '-',
              alamat: detail?.owner_address ?? '-'
            }}
          />
        )}

        {showInspeksi && (
          <InformationInspeksiSecton
            tempat={
              detail?.is_workshop
                ? detail?.workshop_name
                : `
            ${detail?.owner_address ? detail?.owner_address + ', ' : ''}
            ${detail?.location_name ? detail?.location_name + ', ' : ''}
            ${detail?.sub_district_name ? detail?.sub_district_name + ', ' : ''}
            ${detail?.city_name ? detail?.city_name + ', ' : ''}
            ${detail?.province_name ? detail?.province_name : ''}
            ${detail?.postal_code ? detail?.postal_code : ''}
            `
            }
            tanggal={
              detail?.inspection_date ? moment(detail?.inspection_date).format('DD/MM/YYYY') : ''
            }
            waktu={`${detail?.start_hour} - ${detail?.end_hour}`}
            stall={detail?.stall === '' || detail?.stall === 0 ? '-' : detail?.stall}
            inspektor={detail?.inspector_name ?? '-'}
            statusInspeksi={detail?.status ?? '-'}
            harga={showInspeksiHarga ? formatCurrency(detail?.price ?? 0) : null}
          />
        )}

        <InformasiKelengkapanDokumen
          data={{
            odometer: detail?.inspectionResult?.odometer
              ? `${formatThousandsINTL(detail?.inspectionResult?.odometer) ?? 0} KM`
              : '-',
            noRangka: detail?.inspectionResult?.frame_number ?? '-',
            imageNoRangka: detail?.inspectionResult?.frame_number_photo_filename,
            noMesin: detail?.inspectionResult?.engine_number ?? '-',
            imageNoMesin: detail?.inspectionResult?.engine_number_photo_filename,
            stnk: detail?.inspectionResult?.document_stnk_status ? 'Ada' : 'Tidak Ada',
            imageStnk: detail?.inspectionResult?.stnk_photo,
            bpkb: detail?.inspectionResult?.document_bpkb_status ? 'Ada' : 'Tidak Ada',
            imageBpkb: detail?.inspectionResult?.bpkb_photo,
            faktur: detail?.inspectionResult?.document_faktur_status ? 'Ada' : 'Tidak Ada',
            ktp: detail?.inspectionResult?.document_ktp_status ? 'Ada' : 'Tidak Ada',

            kwitansi: detail?.inspectionResult?.document_kwitansi_status ? 'Ada' : 'Tidak Ada',
            keur: detail?.inspectionResult?.document_keur_status ? 'Ada' : 'Tidak Ada',
            formA: detail?.inspectionResult?.document_form_status ? 'Ada' : 'Tidak Ada',
            remark: detail?.inspectionResult?.remark ?? '-',
            engine_capacity: detail?.inspectionResult?.engine_capacity
              ? `${formatThousandsINTL(detail?.inspectionResult?.engine_capacity)} CC`
              : '-',
            tax_expiry: detail?.inspectionResult?.tax_expiry
              ? moment(detail?.inspectionResult?.tax_expiry).format('DD-MM-YYYY')
              : '-',
            unit_ownership: detail?.inspectionResult?.unit_ownership ?? '-',
            latest_service: detail?.inspectionResult?.latest_service
              ? `${formatThousandsINTL(detail?.inspectionResult?.latest_service) ?? 0} KM`
              : '-',
            latest_service_date: detail?.inspectionResult?.latest_service_date
              ? moment(detail?.inspectionResult?.latest_service_date).format('DD-MM-YYYY')
              : '-',
            exterior_color_name: detail?.inspectionResult?.exterior_color_name ?? '-',
            interior_color_name: detail?.inspectionResult?.interior_color_name ?? '-',
            interior_material: detail?.inspectionResult?.interior_material ?? '-'
          }}
        />
        {showRecommendationService && (
          <InspeksiRecommendationServiceCard data={detail?.serviceRecomendation} />
        )}

        <InspeksiResultCard data={detail?.inspectionResult?.inspectionCategoryResults} />
        {detail?.inspectionResult?.inspectionResultPhotos && (
          <PhotoCarousel
            title={'Foto Mobil'}
            data={
              detail?.inspectionResult?.inspectionResultPhotos?.map(
                (item) => item?.photo_filename
              ) ?? []
            }
          />
        )}
        {detail?.inspectionResult?.inspectionCategoryResults &&
        detail?.inspectionResult?.inspectionCategoryResults.length !== 0 ? (
          <CatatanCard
            desc={`${
              detail?.inspectionResult?.note && detail?.inspectionResult?.note !== ''
                ? detail?.inspectionResult?.note
                : 'Tidak ada catatan'
            }`}
          />
        ) : null}
      </Content>
    </Layout>
  )
}

export default InspeksiDetailPage
