export const dummyTransaksiCabang = [
  {
    id: 0,
    cabang: 'Birdmobil Surabaya',
    kode: 'J-123',
    tipe: 'Servis',
    unit: '2019 Toyota Kijang Innova',
    sales: 'Rp 400.000.000',
    serviceDetail: {
      tempat: 'Birdmobil Surabaya',
      tanggal: '24/10/2022',
      waktu: '07.00 - 09.00',
      stall: '2',
      mekanik: 'Nielsen Norman',
      statusInspeksi: 'Selesai'
    },
    ownerDetail: {
      nama: 'Matty Healy',
      email: 'matty1975@gmail.com',
      telepon: '083944857222',
      alamat: 'Jln. Danau Ranau G7E/17, Sawojajar, Malang, Jawa Timur'
    },
    unitDetail: {
      id: 0,
      merek: 'Toyota',
      model: 'Kijang',
      tipe: 'Innova',
      platNomor: 'AB 2239 AAA',
      tahun: '2019',
      owner: 'Erling Haland',
      riwayatService: '22/09/2022',
      transmisi: 'Automatic',
      warna: 'Silver',
      bahanBakar: 'Bensin',
      noRangka: 'MMDJSDHFII009233312',
      noMesin: 'BBMM2231',
      tag: 'SUV, Mobil Keluarga'
    },
    catatan:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc.',
    paymentDetail: {
      listServices: [
        {
          serviceName: 'Ganti Oli',
          products: [{ itemName: 'Shell Hellix 5L', totalItems: '3x', totalPrice: 'Rp 200.000' }]
        },
        {
          serviceName: 'Tune up mesin',
          products: [{ itemName: '2.0 VTEC Turbo', totalPrice: 'Rp 500.000' }]
        }
      ],
      total: 750000,
      discountVoucher: 50000,
      totalPrice: 700000
    }
  },
  {
    id: 1,
    cabang: 'Birdmobil Surabaya',
    kode: 'J-123',
    tipe: 'Inspeksi',
    unit: '2019 Toyota Kijang Innova',
    sales: 'Rp 400.000.000',
    serviceDetail: {
      tempat: 'Birdmobil Surabaya',
      tanggal: '24/10/2022',
      waktu: '07.00 - 09.00',
      stall: '2',
      inspektor: 'Nielsen Norman',
      grade: 'A',
      statusInspeksi: 'Selesai',
      summaryResultInspection: [
        { id: 0, categoryName: 'Eksterior', result: 40, total: 50 },
        { id: 1, categoryName: 'Interior', result: 90, total: 90 },
        { id: 2, categoryName: 'Tes Jalan', result: 10, total: 10 },
        { id: 3, categoryName: 'Bagian Kosong', result: 32, total: 32 }
      ]
    },
    ownerDetail: {
      nama: 'Matty Healy',
      email: 'matty1975@gmail.com',
      telepon: '083944857222',
      alamat: 'Jln. Danau Ranau G7E/17, Sawojajar, Malang, Jawa Timur'
    },
    unitDetail: {
      id: 0,
      merek: 'Toyota',
      model: 'Kijang',
      tipe: 'Innova',
      platNomor: 'AB 2239 AAA',
      tahun: '2019',
      owner: 'Erling Haland',
      riwayatService: '22/09/2022',
      transmisi: 'Automatic',
      warna: 'Silver',
      bahanBakar: 'Bensin',
      noRangka: 'MMDJSDHFII009233312',
      noMesin: 'BBMM2231',
      tag: 'SUV, Mobil Keluarga'
    },
    catatan:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc.',
    paymentDetail: {
      total: 50000,
      totalPrice: 50000
    }
  },
  {
    id: 3,
    cabang: 'Birdmobil Surabaya',
    kode: 'J-123',
    tipe: 'Jual Beli',
    unit: '2019 Toyota Kijang Innova',
    sales: 'Rp 400.000.000',
    serviceDetail: {
      status: 'terjual',
      harga: 300000000
    },
    ownerDetail: {
      nama: 'Jude Belingham',
      email: 'jude.belingham@gmail.com',
      telepon: '083944814722',
      alamat: 'Jln. Bunga Melati No.14, Jakarta'
    },
    buyerDetail: {
      nama: 'Matty Healy',
      email: 'matty1975@gmail.com',
      telepon: '083944857222',
      alamat: 'Jln. Danau Ranau G7E/17, Sawojajar, Malang, Jawa Timur'
    },
    paymentDetail: {
      listServices: [
        {
          serviceName: 'Biaya Pembelian Unit',
          products: [{ itemName: '2019 Toyota Kijang Innova 2.4 G', totalPrice: 'Rp 300.000.000' }]
        }
      ],
      total: 300050000,
      totalPrice: 300050000
    }
  }
]
