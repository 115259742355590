import React, { useState } from 'react'
import { FormDropDown, FormInput, ModalContainer, ModalForm } from 'components'
import { Form, Formik } from 'formik'
import { DatePicker } from 'antd'
import moment from 'moment'
import 'moment/locale/en-gb'
import { disabledDateTimeWorkingPicker } from 'utils'

const ModalKonfirmasiStockJadwalInspeksi = ({
  minHeight,
  state,
  onClose,
  onSubmit,
  dropDownTime
}) => {
  const [date, setDate] = useState()
  const [time, setTime] = useState(dropDownTime[0].value)

  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-start">
            <span className="font-semibold text-gray-900 text-lg">Konfirmasi Jadwal Inspeksi</span>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              bengkelSelector: state.bengkel,
              timeSelector: time
            }}
            onSubmit={(values) =>
              onSubmit({
                bengkel: values.bengkelSelector,
                date: date && date !== '' ? moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY') : date,
                time: values.timeSelector
              })
            }>
            <Form key="konfirmasiJadwalInspeksiForm">
              <div className="flex flex-col w-full space-y-5">
                <FormInput label="Bengkel" name="bengkelSelector" isDisabled={true} />
                <div className="flex flex-col">
                  <label htmlFor="date" className="font-medium block mb-2 text-md text-gray-700">
                    Tanggal
                  </label>
                  <DatePicker
                    value={date}
                    onChange={(value) => setDate(value)}
                    className="custom-date-picker py-3 w-full"
                    popupClassName="custom-date-picker-dropdown"
                    placeholder="Pilih Tanggal"
                    format={['DD/MM/YYYY']}
                    disabledDate={disabledDateTimeWorkingPicker}
                  />
                </div>
                <FormDropDown
                  label="Waktu"
                  name="timeSelector"
                  data={dropDownTime}
                  date={moment(date).format('DD/MM/YYYY')}
                  onChange={(value) => setTime(value)}
                />
              </div>
              <div className="flex flex-row w-full items-center justify-end  space-x-4">
                <button
                  type="button"
                  className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                  onClick={() => onClose()}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                  Confirm
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalKonfirmasiStockJadwalInspeksi
