export const cabangDataDummies = [
  {
    cabangId: 0,
    namaBengkel: 'Birdmobil Surabaya',
    provinsi: 'Jawa Timur',
    cabang: 'Surabaya',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jln. Raya Darmo Kali No. 2-6, Keputran, Kec. Tegalsari, Kota Surabaya, Jawa Timur 60211'
  },
  {
    cabangId: 1,
    namaBengkel: 'Birdmobil Surabaya',
    provinsi: 'Jawa Timur',
    cabang: 'Surabaya',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jln. Raya Darmo Kali No. 2-6, Keputran, Kec. Tegalsari, Kota Surabaya, Jawa Timur 60211'
  },
  {
    cabangId: 2,
    namaBengkel: 'Birdmobil Jakarta',
    provinsi: 'DKI Jakarta',
    cabang: 'Jakarta Selatan',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jl. Setia Budi Selatan No.1, RT.5/RW.5, Kuningan, Karet, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920'
  },
  {
    cabangId: 3,
    namaBengkel: 'Birdmobil Jakarta',
    provinsi: 'DKI Jakarta',
    cabang: 'Jakarta Barat',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jl. Setia Budi Selatan No.1, RT.5/RW.5, Kuningan, Karet, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920'
  },
  {
    cabangId: 4,
    namaBengkel: 'Birdmobil Kalimantan',
    provinsi: 'Kalimantan Barat',
    cabang: 'Pontianak',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jl. Setia Budi Selatan No.1, RT.5/RW.5, Kuningan, Karet, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920'
  },
  {
    cabangId: 5,
    namaBengkel: 'Birdmobil Surabaya II',
    provinsi: 'Jawa Timur',
    cabang: 'Surabaya',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jln. Raya Darmo Kali No. 2-6, Keputran, Kec. Tegalsari, Kota Surabaya, Jawa Timur 60211'
  },
  {
    cabangId: 6,
    namaBengkel: 'Birdmobil Surabaya II',
    provinsi: 'Jawa Timur',
    cabang: 'Surabaya',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jln. Raya Darmo Kali No. 2-6, Keputran, Kec. Tegalsari, Kota Surabaya, Jawa Timur 60211'
  },
  {
    cabangId: 7,
    namaBengkel: 'Birdmobil Jakarta II',
    provinsi: 'DKI Jakarta',
    cabang: 'Jakarta Selatan',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jl. Setia Budi Selatan No.1, RT.5/RW.5, Kuningan, Karet, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920'
  },
  {
    cabangId: 8,
    namaBengkel: 'Birdmobil Jakarta II',
    provinsi: 'DKI Jakarta',
    cabang: 'Jakarta Barat',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jl. Setia Budi Selatan No.1, RT.5/RW.5, Kuningan, Karet, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920'
  },
  {
    cabangId: 9,
    namaBengkel: 'Birdmobil Kalimantan II',
    provinsi: 'Kalimantan Barat',
    cabang: 'Pontianak',
    request: 2,
    terkonfirmasi: 10,
    alamat:
      'Jl. Setia Budi Selatan No.1, RT.5/RW.5, Kuningan, Karet, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920'
  }
]
