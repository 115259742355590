import { CustomButton, NormalFormInput } from 'components'
import ModalLayout from 'layouts/modal-layout'
import React from 'react'
import { useState } from 'react'
import { onlyEmail, onlyNumbers } from 'utils'

const inputEmail = {
  id: 1,
  label: 'Email User',
  name: 'email',
  type: 'text',
  placeholder: 'Masukan Email'
}
const inputPhone = {
  id: 2,
  label: 'Nomor Telepon',
  name: 'phone',
  type: 'text',
  placeholder: 'Masukan Nomor Telepon',
  addonBefore: '+62'
}

const initialDataEmail = {
  value: '',
  isEmpty: false,
  message: '*Silahkan masukkan email'
}

const initialDataPhoneNumber = {
  value: '',
  isEmpty: false,
  message: '*Silahkan masukkan nomor telepon'
}

const ModalTambahUserPerusahaan = ({ onCancel, openModal, onClick }) => {
  const [isEmail, setIsEmail] = useState(true)
  const [email, setEmail] = useState(initialDataEmail)
  const [phoneNumber, setPhoneNumber] = useState(initialDataPhoneNumber)

  const onResetForm = () => {
    setEmail(initialDataEmail)
    setPhoneNumber(initialDataPhoneNumber)
  }

  return (
    <ModalLayout
      headerTitle={'Tambah User'}
      onCancel={onCancel}
      openModal={openModal}
      className="w-[400px]">
      <div className="grid grid-cols-1 gap-5">
        <p className="text-md-medium text-gray-500">
          Tambahkan email user untuk menambahkan user kedalam akun perusahaan.
        </p>
        <div>
          <p className="text-md-medium text-gray-500 mb-2">Tambahkan user berdasarkan</p>
          <div className="grid grid-cols-2 text-center text-md-medium text-gray-900 border border-gray-300 rounded-lg border-solid overflow-hidden">
            <div
              className={`py-2.5 cursor-pointer ${isEmail ? 'bg-gray-300' : 'bg-white'}`}
              onClick={() => {
                onResetForm()
                setIsEmail(true)
              }}>
              Email
            </div>
            <div
              className={`py-2.5 cursor-pointer ${!isEmail ? 'bg-gray-300' : 'bg-white'}`}
              onClick={() => {
                onResetForm()
                setIsEmail(false)
              }}>
              Nomor Telepon
            </div>
          </div>
        </div>
        <NormalFormInput
          data={isEmail ? inputEmail : inputPhone}
          state={isEmail ? email.value : phoneNumber.value}
          isEmpty={isEmail ? email.isEmpty : phoneNumber.isEmpty}
          message={isEmail ? email.message : phoneNumber.message}
          onChange={(value) => {
            if (isEmail) {
              if (value !== '') {
                if (onlyEmail(value.trim())) {
                  setEmail({ ...email, value: value, isEmpty: false })
                } else {
                  setEmail({
                    ...email,
                    value: value,
                    isEmpty: true,
                    message: '*Format email belum benar (example@mail.com)'
                  })
                }
              } else {
                setEmail({ ...email, value: value, isEmpty: true })
              }
            } else {
              if (value !== '') {
                if (onlyNumbers(value)) {
                  setPhoneNumber({ ...phoneNumber, value: value, isEmpty: false })
                }
              } else {
                setPhoneNumber({ ...phoneNumber, value: value, isEmpty: true })
              }
            }
          }}
        />
        <div className="flex justify-end items-center gap-2">
          <CustomButton type="plain" onClick={onCancel}>
            Batal
          </CustomButton>
          <CustomButton
            onClick={() => {
              if (isEmail) {
                if (email.isEmpty || email.value === '') {
                  setEmail({ ...email, isEmpty: true })
                } else {
                  onClick(email.value)
                }
              } else {
                if (phoneNumber.isEmpty || phoneNumber.value === '') {
                  setPhoneNumber({ ...phoneNumber, isEmpty: true })
                } else {
                  onClick(phoneNumber.value)
                }
              }
            }}>
            Konfirmasi
          </CustomButton>
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalTambahUserPerusahaan
