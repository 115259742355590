import React from 'react'
import { BaseIcon, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'

const ModalUpdateTax = ({ state, onClose, onSubmit }) => (
  <ModalContainer>
    <ModalForm minHeight="min-h-[15%]">
      <div className="flex flex-col w-full space-y-4">
        <div className="flex flex-row items-center justify-between">
          <span className="font-semibold text-gray-900 text-lg">Edit Tax Service Pickup</span>
          <div
            className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
            onClick={() => onClose()}>
            <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
          </div>
        </div>
        <Formik
          initialValues={{
            percentage: state.percentage
          }}
          onSubmit={(values) => onSubmit(values)}>
          <Form key="updateTaxForm">
            <div className="flex flex-col w-full space-y-5">
              <FormInput
                label="Besar Biaya"
                name="percentage"
                placeholder="Masukkan Besar Biaya"
                isPercentage
                isIconRight
                onValidate={(value) => {
                  if (!value) {
                    return 'Silahkan Masukkan Besar Biaya'
                  }
                }}
              />
              <div className="flex flex-row w-full items-center justify-end space-x-4">
                <button
                  type="button"
                  className="flex flex-row mt-2 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                  onClick={() => onClose()}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex flex-row mt-2 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                  Simpan
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </ModalForm>
  </ModalContainer>
)

export default ModalUpdateTax
