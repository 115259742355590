import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Card, Image, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button, InspeksiCard } from 'components'
import { formatCurrency } from 'utils'
import HistoryDetailComponent from './HistoryDetailComponent'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { apiGet } from 'api'
import { logout } from 'store/actions'
import { formatDate } from 'helpers'
import { CarouselMultipleLayout } from 'layouts/CarouselMultipleLayout'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  listData: []
}

const InternalUnitDetailComponent = ({ isLoading, data, onShowModalJual }) => {
  const paramsRouter = useParams()
  const router = useHistory()
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [tableServis, setTableServis] = useState(initialTableState)
  const [tableInspeksi, setTableInspeksi] = useState(initialTableState)

  useQuery({
    queryKey: ['servis-historis-list', tableServis.offset, tableServis.limit, paramsRouter?.id],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const params = {
        offset: tableServis.offset,
        limit: tableServis.limit
      }
      const res = await apiGet({
        url: `servis-service/internal/bookings/histories/unit/${paramsRouter?.id}`,
        token,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: async (res) => {
      let dataServis = res?.resData?.data?.map((servis) => {
        return {
          id: servis?.id,
          name: servis?.service_name,
          date: formatDate(servis?.updated_at),
          resultLabel: `${servis?.item_count} Item`,
          resultValue: formatCurrency(servis?.price_total),
          status: (
            <label className="px-1 py-[1px] border border-solid text-sm text-green-600 bg-green-50 rounded-md">
              Selesai
            </label>
          ),
          onClick: () =>
            router.push(`/internal-unit/detail-servis/${servis?.id}`, {
              unitOwner: data?.unitOwner
            })
        }
      })
      setTableServis({
        ...tableServis,
        listData: dataServis,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  useQuery({
    queryKey: ['inspeksi-historis-list', tableInspeksi.offset, tableInspeksi.limit, paramsRouter?.id],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const params = {
        offset: tableInspeksi.offset,
        limit: tableInspeksi.limit
      }
      const res = await apiGet({
        url: `inspeksi-service/website/inspection-unit/histories/${paramsRouter?.id}`,
        token,
        data: params,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: async (res) => {
      let dataInspeksi = res?.resData?.data?.map((inspeksi) => {
        return {
          id: inspeksi?.id,
          name: inspeksi?.workshop_name,
          date: inspeksi?.date,
          resultLabel: '',
          resultValue: '',
          status: (
            <label className="px-1 py-[1px] border border-solid text-sm text-green-600 bg-green-50 rounded-md">
              Selesai
            </label>
          ),
          onClick: () => router.push(`/internal-unit/${data?.id}/${inspeksi?.id}/detail-inspeksi`)
        }
      })
      setTableInspeksi({
        ...tableInspeksi,
        listData: dataInspeksi,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const checkingExist = (isExist) => {
    if (isExist) {
      return 'Ada'
    }
    return 'Tidak Ada'
  }
  const handlePerRowsChange = (newPerPage, setTableState) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page, setTableState) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Action</Typography.Text>
        </div>
        <div className="flex justify-between">
          <Button
            className={`border-white text-white flex-1 ${
              data?.is_inspected || data?.is_in_catalog || data?.is_service ? 'bg-gray-300' : 'bg-primary-500'
            }`}
            disabled={data?.is_inspected || data?.is_in_catalog || data?.is_service}
            onClick={() =>
              router.push(`/internal-unit/${data?.id}/servis-mobil`, { step: 1, data: data })
            }>
            Servis Mobil
          </Button>
          <Button
            className={`border-white text-white flex-1 mx-4 ${
              data?.is_inspected || data?.is_in_catalog || data?.is_service ? 'bg-gray-300' : 'bg-primary-500'
            }`}
            disabled={data?.is_inspected || data?.is_in_catalog || data?.is_service}
            onClick={() =>
              router.push(`/internal-unit/${data?.id}/inspeksi`, {
                data,
                id: data?.id,
                step: 1
              })
            }>
            Lakukan Inspeksi
          </Button>
          <Button
            className={`border-white text-white flex-1 ${
              data?.is_inspected || data?.is_in_catalog || data?.is_service ? 'bg-gray-300' : 'bg-primary-500'
            }`}
            disabled={data?.is_inspected || data?.is_in_catalog || data?.is_service}
            onClick={onShowModalJual}>
            Jual Mobil
          </Button>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Pemilik</Typography.Text>
        </div>
        <div className="flex justify-between">
          <Typography className="flex-1 flex flex-col mr-2">
            <Typography.Text className="font-medium text-md">Nama</Typography.Text>
            <Typography.Text className="text-sm text-gray-700">
              {data?.unitOwner?.name}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col mr-2">
            <Typography.Text className="font-medium text-md">Email</Typography.Text>
            <Typography.Text className="text-sm text-gray-700">
              {data?.unitOwner?.email}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col mr-2">
            <Typography.Text className="font-medium text-md">Nomor Telepon</Typography.Text>
            <Typography.Text className="text-sm text-gray-700">
              {data?.unitOwner?.phone_number ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="font-medium text-md">Alamat</Typography.Text>
            <Typography.Text className="text-sm text-gray-700">
              {data?.unitOwner?.address}
            </Typography.Text>
          </Typography>
        </div>
      </Card>
      <div className="flex mb-4">
        <Card className="flex-1 mr-1 rounded-lg">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Informasi Unit</Typography.Text>
          </div>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Merek</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.brand?.name}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Model</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.model?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Tipe</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.type?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Transmisi</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.transmission?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Tahun</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.production_name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Plat Nomor</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.police_number ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Warna</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.color?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Bahan Bakar</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.fuel?.name ?? '-'}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Nomor Rangka</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.frame_number}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Nomor Mesin</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {data?.engine_number}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Tag</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              SUV, Mobil Keluarga
            </Typography.Text>
          </Typography>
        </Card>
        <Card className="flex-1 ml-1 rounded-lg">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Dokumen</Typography.Text>
          </div>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">STNK</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_stnk_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">BPKB</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_bpkb_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Faktur</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_faktur_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">KTP Pemilik</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_ktp_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Kwitansi</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_kwitansi_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">KEUR</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_keur_status)}
            </Typography.Text>
          </Typography>
          <Typography className="flex mb-1">
            <Typography.Text className="font-medium text-md w-1/3">Form A</Typography.Text>
            <Typography.Text className="text-sm text-gray-700 w-2/3">
              {checkingExist(data?.document_form_status)}
            </Typography.Text>
          </Typography>
        </Card>
      </div>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Catatan</Typography.Text>
        </div>
        <Typography>
          <Typography.Paragraph>{data?.description}</Typography.Paragraph>
        </Typography>
      </Card>

      {data?.inspection_result && (
        <InspeksiCard
          onGoToDetailInspeksi={() =>
            router.push(
              `/internal-unit/${data?.unit_name}/${data?.inspectionResult?.id}/detail-inspeksi`,
              { id: data?.inspectionResult?.id }
            )
          }
          data={{
            ...data?.inspectionResult,
            inspectionCategoryList:
              data?.inspectionResult?.inspectionCategoryList?.map((item) => ({
                ...item,
                count: `${item?.ok_total}/${item?.part_total}`
              })) ?? []
          }}
        />
      )}

      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Foto Mobil</Typography.Text>
        </div>
        <CarouselMultipleLayout
          slideToShow={4}
          totalItem={data?.photos?.length ?? 0}
          className={'cursor-grab'}>
          <div key={`photo-thumbnail`}>
            <Image
              className="object-center object-cover w-72 h-52"
              // src={require('assets/images/Car1.png')}
              src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${data?.thumbnail_filename}`}
              alt={`picture-thumbnail`}
              preview
            />
          </div>
          {data?.photos?.map((photo, key) => (
            <div key={`photo-${key}`}>
              <Image
                className="object-center object-cover w-72 h-52"
                // src={require('assets/images/Car1.png')}
                src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${photo?.photo_filename}`}
                alt={`picture-${key}`}
                preview
              />
            </div>
          ))}
        </CarouselMultipleLayout>
      </Card>

      {/* <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Foto Mobil</Typography.Text>
        </div>
        <div className="grid grid-rows-2 grid-cols-2">
          {data?.photos?.map((photo, idx) => {
            if (data?.photos?.length - 1 === idx || idx === 3) {
              return (
                <div key={`photo-${idx}`} className="m-1 relative group hover:cursor-pointer">
                  <Image
                    className="rounded-md"
                    preview={false}
                    src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${photo?.photo_filename}`}
                  />
                  {data?.photos?.length > 4 && (
                    <div className="flex bg-black opacity-20 group-hover:opacity-50 absolute top-0 h-full w-full rounded-md" />
                  )}
                  {data?.photos?.length > 4 && (
                    <div className="absolute z-1 top-0 flex items-center justify-center rounded-md h-full w-full">
                      <span className="text-white font-semibold text-xl">+4</span>
                    </div>
                  )}
                </div>
              )
            } else if (idx < 3) {
              return (
                <div key={`photo-${idx}`} className="m-1">
                  <Image
                    className="rounded-md"
                    preview={false}
                    src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${photo?.photo_filename}`}
                  />
                </div>
              )
            }
          })}
        </div>
      </Card> */}

      <div className="flex mb-4">
        <Card className="flex-1 mr-1 rounded-lg">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Riwayat Servis</Typography.Text>
          </div>
          <HistoryDetailComponent
            data={tableServis?.listData}
            page={tableServis?.offset}
            pageSize={tableServis?.limit}
            totalRows={tableServis?.totalRows}
            handlePerRowsChange={(value) => handlePerRowsChange(value, setTableServis)}
            onChangePrevPage={() => {
              if (tableServis?.offset > 0) {
                onChangePage(tableServis?.offset - tableServis?.limit, setTableServis)
              }
            }}
            onChangeNextPage={() => {
              if (tableServis?.totalRows > tableServis?.limit + tableServis?.offset) {
                onChangePage(tableServis?.offset + tableServis?.limit, setTableServis)
              }
            }}
          />
        </Card>
        <Card className="flex-1 mr-1 rounded-lg">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Riwayat Inspeksi</Typography.Text>
          </div>
          <HistoryDetailComponent
            data={tableInspeksi?.listData}
            page={tableInspeksi?.offset}
            pageSize={tableInspeksi?.limit}
            totalRows={tableInspeksi?.totalRows}
            handlePerRowsChange={(value) => handlePerRowsChange(value, setTableInspeksi)}
            onChangePrevPage={() => {
              if (tableInspeksi?.offset > 0) {
                onChangePage(tableInspeksi?.offset - tableInspeksi?.limit, setTableInspeksi)
              }
            }}
            onChangeNextPage={() => {
              if (tableInspeksi?.totalRows > tableInspeksi?.limit + tableInspeksi?.offset) {
                onChangePage(tableInspeksi?.offset + tableInspeksi?.limit, setTableInspeksi)
              }
            }}
          />
        </Card>
      </div>
      {/* <div className="flex mb-4">
        <Card className="flex-1 mr-1 rounded-lg">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Inspeksi</Typography.Text>
          </div>
          <Card className="bg-[#F04438] rounded-md mb-3">
            <Typography className="flex flex-col">
              <Typography.Text className="text-white text-md mb-1">Grade</Typography.Text>
              <Typography.Text className="text-white text-lg">{data?.grade ?? '-'}</Typography.Text>
            </Typography>
          </Card>
          <Card className="bg-[#F79009] rounded-md mb-3">
            <Typography className="flex flex-col">
              <Typography.Text className="text-white text-md mb-1">
                Harga Jual Rekomendasi
              </Typography.Text>
              <Typography.Text className="text-white text-lg">
                {formatCurrency(data?.price ?? '0')}
              </Typography.Text>
            </Typography>
          </Card>
          <Button
            className="w-full border-gray-300 border bg-white hover:bg-gray-50"
            onClick={() =>
              router.push(`/internal-unit/${data?.id}/detail-inspeksi`, { id: data?.id })
            }>
            Lihat Detail Inspeksi
          </Button>
        </Card>
        <Card className="flex-1 ml-1 rounded-lg">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Foto Mobil</Typography.Text>
          </div>
          <div className="grid grid-rows-2 grid-cols-2">
            {data?.photos?.map((photo, idx) => {
              if (data?.photos?.length - 1 === idx || idx === 3) {
                return (
                  <div key={`photo-${idx}`} className="m-1 relative group hover:cursor-pointer">
                    <Image
                      className="rounded-md"
                      preview={false}
                      src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${photo?.photo_filename}`}
                    />
                    {data?.photos?.length > 4 && (
                      <div className="flex bg-black opacity-20 group-hover:opacity-50 absolute top-0 h-full w-full rounded-md" />
                    )}
                    {data?.photos?.length > 4 && (
                      <div className="absolute z-1 top-0 flex items-center justify-center rounded-md h-full w-full">
                        <span className="text-white font-semibold text-xl">+4</span>
                      </div>
                    )}
                  </div>
                )
              } else if (idx < 3) {
                return (
                  <div key={`photo-${idx}`} className="m-1">
                    <Image
                      className="rounded-md"
                      preview={false}
                      src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${photo?.photo_filename}`}
                    />
                  </div>
                )
              }
            })}
          </div>
        </Card>
      </div> */}
      {/* <Card className="rounded-lg mb-1">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Riwayat</Typography.Text>
        </div>
        <Typography>
          <Typography.Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa,
            scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant
            accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in
            nisl sagittis pellentesque nunc.
          </Typography.Paragraph>
        </Typography>
      </Card> */}
    </Content>
  )
}

export default InternalUnitDetailComponent
