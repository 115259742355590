import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'

import { IcPlus, IcSearch } from 'assets/icons'
import { Button, Table } from 'components'

const SparepartUnitComponent = ({
  columns,
  data,
  tableState,
  onAdd,
  setTableState,
  isLoading,
  brandList,
  modelList,
  typeList
}) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">Sparepart Unit</Typography.Text>
          <Button
            className="flex flex-row items-center justify-around rounded-md space-x-1 px-2 py text-md text-white bg-primary-500 border-primary-500"
            onClick={onAdd}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Sparepart Unit</span>
          </Button>
        </div>
        <div className="mb-5 flex justify-between">
          <div className="flex gap-x-2">
            <Select
              className="custom-table-limit"
              style={{ width: '150px' }}
              value={tableState.brand_id}
              onChange={(e) =>
                setTableState((prevState) => ({
                  ...prevState,
                  offset: 0,
                  brand_id: e ?? '',
                  model_id: prevState.brand_id !== e ? '' : prevState.model_id,
                  type_id: prevState.brand_id !== e ? '' : prevState.type_id
                }))
              }
              showSearch
              allowClear
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Brand
              </Select.Option>
              {brandList?.map((item) => (
                <Select.Option key={item?.id?.toString()} value={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
              {/* <Select.Option value="jepang">Admin</Select.Option> */}
            </Select>
            <Select
              className="custom-table-limit"
              style={{ width: '150px' }}
              value={tableState.model_id}
              onChange={(e) =>
                setTableState((prevState) => ({
                  ...prevState,
                  offset: 0,
                  model_id: e ?? '',
                  type_id: prevState.model_id !== e ? '' : prevState.type_id
                }))
              }
              showSearch
              allowClear
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Model
              </Select.Option>
              {modelList?.map((item) => (
                <Select.Option key={item?.id?.toString()} value={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
              {/* <Select.Option value="jepang">Admin</Select.Option> */}
            </Select>
            <Select
              className="custom-table-limit"
              style={{ width: '150px' }}
              value={tableState.type_id}
              onChange={(e) =>
                setTableState((prevState) => ({
                  ...prevState,
                  offset: 0,
                  type_id: e ?? ''
                }))
              }
              allowClear
              showSearch
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Tipe
              </Select.Option>
              {typeList?.map((item) => (
                <Select.Option key={item?.id?.toString()} value={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Input
            className="w-1/4 rounded-md py-1.5"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

SparepartUnitComponent.propTypes = {
  columns: PropTypes.array,
  tableState: PropTypes.object,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  setTableState: PropTypes.func,
  isLoading: PropTypes.bool
}

SparepartUnitComponent.defaultProps = {
  columns: PropTypes.array,
  tableState: {},
  data: [],
  onAdd: () => {},
  setTableState: () => {},
  isLoading: false
}

export default SparepartUnitComponent
