import React, { useCallback, useState } from 'react'
import { BaseIcon, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Formik } from 'formik'
import { ChromePicker } from 'react-color'

const ModalCreateUpdateWarna = ({ minHeight, onClose, onSubmit, state }) => {
  const [isClicked, setIsClicked] = useState(false)
  const [colorName, setColorName] = useState(state.type === 'create' ? '' : state.colorName)
  const [colorHex, setColorHex] = useState({
    hex: `${state.colorHex ?? ''}`,
    rgb: {},
    hsl: {},
    hsv: {},
    oldHue: 0
  })
  const [isEmptyForm, setIsEmptyForm] = useState({
    colorName: { isEmpty: false, message: 'Silahkan masukkan nama warna' },
    colorHex: { isEmpty: false, message: 'Silahkan pilih warna' }
  })

  const updateColor = useCallback((color) => {
    setColorHex(color)
    if (color.hex && color.hex !== '') {
      setIsEmptyForm({
        ...isEmptyForm,
        colorHex: { ...isEmptyForm.colorHex, isEmpty: false }
      })
    } else {
      setIsEmptyForm({
        ...isEmptyForm,
        colorHex: { ...isEmptyForm.colorHex, isEmpty: true }
      })
    }
  }, [])

  return (
    <ModalContainer>
      <div className={`relative min-w-[25%]`}>
        <ModalForm minHeight={minHeight}>
          <div className={`flex flex-col w-full space-y-4`}>
            <div className="flex flex-row items-center justify-between">
              <span className="font-semibold text-gray-900 text-lg">
                {`${state.type === 'create' ? 'Tambah' : 'Edit'} Warna`}
              </span>
              <div
                className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
                onClick={() => onClose()}>
                <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
              </div>
            </div>

            <div className="flex flex-col w-full space-y-5">
              <Formik
                initialValues={{
                  colorName: colorName
                }}>
                <FormInput
                  label="Warna"
                  name="colorName"
                  placeholder="Masukkan nama warna"
                  isRequired
                  isEmpty={isEmptyForm.colorName.isEmpty}
                  errorMessage={isEmptyForm.colorName.message}
                  onValidate={(value) => {
                    setColorName(value)
                    if (value && value !== '') {
                      setIsEmptyForm({
                        ...isEmptyForm,
                        colorName: { ...isEmptyForm.colorName, isEmpty: false }
                      })
                    } else {
                      setIsEmptyForm({
                        ...isEmptyForm,
                        colorName: { ...isEmptyForm.colorName, isEmpty: true }
                      })
                    }
                  }}
                />
              </Formik>
              <div className="relative">
                <div>
                  <label
                    htmlFor="colorHex"
                    className={`font-medium block mb-2 text-md text-gray-700 ${
                      isClicked ? 'hover:cursor-pointer' : ''
                    }`}
                    onClick={() => {
                      if (isClicked) {
                        setIsClicked(false)
                      }
                    }}>
                    Hex
                    <span className="text-red-600">{' *'}</span>
                  </label>
                  <input
                    className="w-full font-normal text-left py-3 px-3 text-gray-700  placeholder-gray-500 bg-white border border-solid border-gray-300 rounded-md hover:cursor-pointer focus:border-primary-500 focus:ring-primary-500 focus:outline-none focus:ring focus:ring-opacity-20"
                    name="colorHex"
                    value={colorHex.hex}
                    placeholder="Pilih warna"
                    onClick={() => {
                      setIsClicked(!isClicked)
                    }}
                    onChange={() => {}}
                  />
                </div>
                {isEmptyForm.colorHex.isEmpty ? (
                  <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                    <span>{`*${isEmptyForm.colorHex.message}`}</span>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-row w-full items-center justify-end space-x-4">
                <button
                  type="button"
                  className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                  onClick={() => onClose()}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="flex flex-row mt-4 items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500"
                  onClick={() => {
                    if (colorName && colorName !== '' && colorHex.hex && colorHex.hex !== '') {
                      onSubmit({
                        colorName: colorName,
                        colorHex: colorHex.hex
                      })
                    }
                    setIsEmptyForm({
                      colorName: {
                        ...isEmptyForm.colorName,
                        isEmpty: !colorName || colorName === '' ? true : false
                      },
                      colorHex: {
                        ...isEmptyForm.colorHex,
                        isEmpty: !colorHex.hex || colorHex.hex === '' ? true : false
                      }
                    })
                  }}>
                  {`${state.type === 'create' ? 'Tambah Warna' : 'Simpan'}`}
                </button>
              </div>
            </div>
          </div>
        </ModalForm>
        {isClicked ? (
          <div className="flex absolute z-50 items-center justify-center bg-trasnparent -bottom-[45%] right-5">
            <ChromePicker
              styles={{
                width: '100%'
              }}
              disableAlpha
              color={colorHex}
              onChange={updateColor}
            />
          </div>
        ) : null}
      </div>
    </ModalContainer>
  )
}

export default ModalCreateUpdateWarna
