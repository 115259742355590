import { IcEdit, IcTrash } from 'assets/icons'
import { Button } from 'components'
import moment from 'moment'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { Typography } from 'antd'
import { showErrorMessage, showSuccessMessage } from 'utils'
const initialModalOption = {
  typeButton: 'tambah',
  isOpen: false,
  data: null
}
const useUnitList = (data) => {
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const user = useSelector((state) => state.auth)
  const [modalOption, setModalOption] = useState({ ...initialModalOption })

  // GetUnitList
  const {
    data: dataColumns,
    refetch: refetchUnitList,
    isLoading: getUnitListLoading
  } = useQuery({
    queryKey: ['getUnitList'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token: user.token,
        url: `jual-beli-service/admin/taxis/units/${data.id}/list`,
        onLogout: () => dispatch(logout())
      })
      return res?.resData?.data
    },
    enabled: Boolean(data?.id)
  })

  const tambahUnitList = useMutation({
    mutationKey: 'TambahUnitList',
    mutationFn: async (serialNumber) =>
      await apiPost({
        url: `jual-beli-service/admin/taxis/units`,
        token: user.token,
        data: {
          taxi_id: data?.id,
          serial_number: serialNumber
        },
        onLogout
      }),
    onSuccess: () => {
      closeModal()
      showSuccessMessage({ content: 'unit berhasil ditambah' })
      refetchUnitList()
    },
    onError: (error) => {
      showErrorMessage({
        content: error?.message
          ? error.message
          : 'Terjadi kesalahan silahkan coba lagi atau hubungi admin BirdMobil.'
      })
    }
  })
  const editUnitList = useMutation({
    mutationKey: 'EditUnitList',
    mutationFn: async ({ serialNumber, unitId }) =>
      await apiPut({
        url: `jual-beli-service/admin/taxis/units/${unitId}`,
        token: user.token,
        data: {
          serial_number: serialNumber
        },
        onLogout
      }),
    onSuccess: () => {
      closeModal()
      showSuccessMessage({ content: 'unit berhasil diubah' })
      refetchUnitList()
    },
    onError: (error) => {
      showErrorMessage({
        content: error?.message
          ? error.message
          : 'Terjadi kesalahan silahkan coba lagi atau hubungi admin BirdMobil.'
      })
    }
  })
  const deleteUnitList = useMutation({
    mutationKey: 'DeleteUnitList',
    mutationFn: async (unitId) =>
      await apiDelete({
        url: `jual-beli-service/admin/taxis/units/${unitId}`,
        token: user.token,
        onLogout
      }),
    onSuccess: () => {
      closeModal()
      showSuccessMessage({ content: 'Unit berhasil dihapus' })
      refetchUnitList()
    },
    onError: (error) => {
      showErrorMessage({
        content: error?.message
          ? error.message
          : 'Terjadi kesalahan silahkan coba lagi atau hubungi admin BirdMobil.'
      })
    }
  })

  const syncUnitList = useMutation({
    mutationKey: ['getSyncUnitList'],
    mutationFn: async () =>
      await apiPost({
        url: `jual-beli-service/admin/taxis/units/${data.id}/sync`,
        token: user.token,
        onLogout
      }),
    onSuccess: () => {
      refetchUnitList()
      showSuccessMessage({ content: 'Sinkronisasi Data Ex Taxi Berhasil' })
    },
    onError: (error) => {
      showErrorMessage({
        content: error?.message
          ? error.message
          : 'Terjadi kesalahan silahkan coba lagi atau hubungi admin BirdMobil.'
      })
    }
  })

  const columns = [
    {
      name: 'Serial Product',
      sortable: true,
      sortField: 'serial_number',
      selector: (row) => row?.serial_number,
      cell: (row) => <div className="text-gray-500">{row?.serial_number}</div>
    },
    {
      name: 'Product',
      sortable: true,
      sortField: 'unit_name',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <Typography.Text
          className="font-medium text-gray-700 "
          ellipsis={{
            tooltip: row?.unit_name
          }}>
          {row?.unit_name}
        </Typography.Text>
      )
    },
    {
      name: 'Created On',
      sortable: true,
      sortField: 'created_at',
      selector: (row) => {
        return moment(row?.created_at).format('DD/MM/YYYY')
      },
      cell: (row) => {
        return moment(row?.created_at).format('DD/MM/YYYY')
      }
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      minWidth: '150px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50 flex items-center"
            onClick={() => openModal('edit', row)}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-solid border-[#FDA19B] hover:bg-red-50"
            onClick={() => openModal('delete', row)}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  function getSyncUnitList() {
    syncUnitList.mutate()
  }

  function closeModal() {
    setModalOption({ ...initialModalOption })
  }

  function openModal(typeButton, data) {
    setModalOption({ typeButton: typeButton, data: data, isOpen: true })
  }

  function deleteUnit(unitId) {
    deleteUnitList.mutate(unitId)
  }

  function submitUnit(serialNumber, unitId) {
    if (modalOption.typeButton === 'tambah') return tambahUnitList.mutate(serialNumber)
    else if (modalOption.typeButton === 'edit') return editUnitList.mutate({ serialNumber, unitId })
  }

  return {
    modalOption,
    dataColumns,
    columns,
    syncLoading: syncUnitList.isLoading,
    getUnitListLoading,
    updateLoading: deleteUnitList.isLoading || tambahUnitList.isLoading || editUnitList.isLoading,
    closeModal,
    openModal,
    deleteUnit,
    submitUnit,
    getSyncUnitList
  }
}

export default useUnitList
