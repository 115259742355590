import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import VoucherDetailComponent from './components/VoucherDetailComponent'
import ModalUpdateVoucher from './components/ModalUpdateVoucher'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPut } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from 'helpers'
import { useHistory } from 'react-router-dom'
import { logout } from 'store/actions'
import moment from 'moment'
import { showErrorMessage, showSuccessMessage } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const columns = [
  {
    name: 'Tanggal - Waktu',
    selector: (row) =>
      row?.created_at ? `${moment(row?.created_at).format('DD/MM/YYYY - HH.mm')}` : ''
  },
  {
    name: 'User',
    selector: (row) => row?.user_fullname
  },
  {
    name: 'Voucher',
    selector: (row) => row?.voucher_name
  },
  {
    name: 'Jumlah Penggunaan',
    selector: (row) => row?.voucher_use_count
  }
]

const VoucherDetailPage = () => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [tableState, setTableState] = useState(initialTableState)
  const id = router?.location?.state?.id
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: null
  })

  const voucherData = useQuery({
    queryKey: ['voucher-data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        data: {},
        url: `master-service/admin/vouchers/${id}`,
        onLogout: () => dispatch(logout())
      })
      return res
    }
  })

  const voucherUser = useQuery({
    queryKey: [
      'voucher-user-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        token,
        data: params,
        url: `user-service/admin/voucher-histories/${id}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const saveData = async (data) => {
    try {
      setTableState((prevState) => ({
        ...prevState,
        loading: true
      }))
      let res
      let body = {
        ...data
      }

      body = {
        ...body,
        expiry_date: formatDate(data?.expiry_date),
        user_quota: parseInt(data?.user_quota),
        voucher_quota: parseInt(data?.voucher_quota),
        discount: parseInt(data?.discount),
        max_discount: parseInt(data?.max_discount ? data?.max_discount : null),
        min_transaction: parseInt(data?.min_transaction)
      }

      res = await apiPut({
        token,
        data: body,
        url: `master-service/admin/vouchers/${id}`,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        showSuccessMessage({ content: 'Berhasil Mengubah Voucer' })
      } else {
        showErrorMessage({
          content:
            res.resData.message ?? res.resData.errors[0].message ?? 'Gagal untuk Mengubah Voucher'
        })
      }
      voucherData?.refetch()
      voucherUser?.refetch()
    } catch (error) {
      setModal({
        ...modal,
        show: false
      })
      showErrorMessage({
        content: 'Terjadi Kesalahan'
      })
    }
  }

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        token,
        data: {},
        url: `master-service/admin/vouchers/${data?.id}`,
        onLogout: () => dispatch(logout())
      })
      setModal({
        ...modal,
        show: false
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        router.goBack()
        showSuccessMessage({ content: 'Berhasil Menghapus Voucher' })
      } else {
        showErrorMessage({
          content:
            res.resData.message ?? res.resData.errors[0].message ?? 'Gagal untuk Menghapus Voucher'
        })
      }
    } catch (error) {
      setModal({
        ...modal,
        show: false
      })
      showErrorMessage({
        content: 'Terjadi Kesalahan'
      })
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Master Data</Breadcrumb.Item>
        <Breadcrumb.Item className="cursor-pointer" onClick={() => router.goBack()}>
          Voucher
        </Breadcrumb.Item>
        <Breadcrumb.Item>Voucher Detail</Breadcrumb.Item>
      </Breadcrumb>
      <ModalUpdateVoucher
        isOpen={modal.show}
        type={modal.type}
        data={modal.data}
        onSubmit={saveData}
        onDelete={deleteData}
        onCancel={() => setModal({ ...modal, show: false })}
      />
      <VoucherDetailComponent
        isLoading={voucherUser?.isLoading}
        columns={columns}
        voucher={voucherData?.data?.resData?.data}
        data={voucherUser?.data?.resData?.data}
        tableState={tableState}
        setTableState={setTableState}
        onEdit={() =>
          setModal({
            ...modal,
            show: true,
            type: 'edit',
            data: voucherData?.data?.resData?.data
          })
        }
        onDelete={() =>
          setModal({
            ...modal,
            show: true,
            type: 'delete',
            data: voucherData?.data?.resData?.data
          })
        }
      />
    </Layout>
  )
}

export default VoucherDetailPage
