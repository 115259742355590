import React from 'react'
import CardContainer from 'components/CardContainer'

const CyocExTaxiCard = ({ data }) => {
  const fieldStyle = 'font-semibold text-gray-900 text-md'
  const valueStyle = 'font-normal text-gray-700 text-md'

  return (
    <CardContainer>
      <div className="flex w-full border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Create Your Own Car</span>
      </div>
      {data && Object.entries(Object.assign([], data)).length !== 0 ? (
        <div className="grid grid-cols-1 gap-3 mt-4">
          {Object.entries(Object.assign([], data)).map((item, index) => (
            <div key={index} className="grid grid-cols-5">
              <div className="col-span-1">
                <span className={fieldStyle}>{item[0]}</span>
              </div>
              <div className="col-span-4">
                <span className={valueStyle}>{item[1].map((itemCh) => itemCh.name).join(' ')}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-start justify-start space-y-4 mt-4">
          <span className="text-lg font-medium text-gray-700">
            Belum Terdapat Create Own Your Car.
          </span>
        </div>
      )}
    </CardContainer>
  )
}

export default CyocExTaxiCard
