import React, { useCallback } from 'react'
import { Content } from 'antd/lib/layout/layout'
import { Card, DatePicker, Input, Select, Typography } from 'antd'
import { IcSearch } from 'assets/icons'
import { Table } from 'components'
import moment from 'moment'

const HasilInspeksiComponent = ({
  columns,
  data,
  isLoading,
  tableState,
  setTableState,
  searchDropdown,
  onSearchDropdown,
  dropdownOwner,
  dropdownWorkshop,
  dropdownStall,
  selectDropdown,
  setSelectDropdown
}) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-lg">Hasil Inspeksi</Typography.Text>
        </div>
        <div className="flex justify-between mb-5 gap-4">
          <div className="flex items-center gap-4">
            <Select
              showSearch
              className="custom-table-limit"
              style={{ width: '150px' }}
              value={selectDropdown?.owner}
              // onSearch={(text) => {
              //     onSearchDropdown({
              //         ...searchDropdown,
              //         owner: text
              //     })
              // }}
              allowClear
              onChange={(value) => {
                setSelectDropdown({
                  ...selectDropdown,
                  owner: value ?? ''
                })
                if (selectDropdown?.owner !== value) {
                  onChangePage(0)
                }
              }}
              filterOption={true}>
              <Select.Option className="custom-table-limit-option" value={''}>
                Semua Pemilik
              </Select.Option>
              {dropdownOwner?.map((owner, idx) => (
                <Select.Option key={idx?.toString()} value={owner?.owner_name}>
                  {owner?.owner_name}
                </Select.Option>
              ))}
            </Select>
            <Select
              showSearch
              className="custom-table-limit"
              style={{ width: '150px' }}
              value={selectDropdown?.workshop}
              onSearch={(text) => {
                onSearchDropdown({
                  ...searchDropdown,
                  workshop: text
                })
              }}
              onChange={(value) => {
                setSelectDropdown({
                  ...selectDropdown,
                  workshop: value ?? ''
                })
                if (selectDropdown?.workshop !== value) {
                  onChangePage(0)
                }
              }}
              allowClear
              filterOption={false}>
              <Select.Option className="custom-table-limit-option" value={''}>
                Semua Cabang
              </Select.Option>
              {dropdownWorkshop?.map((workshop, idx) => (
                <Select.Option key={idx?.toString()} value={workshop?.id}>
                  {workshop?.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              showSearch
              className="custom-table-limit"
              style={{ width: '150px' }}
              value={selectDropdown?.stall}
              // onSearch={(text) => {
              //     onSearchDropdown({
              //         ...searchDropdown,
              //         stall: text
              //     })
              // }}
              onChange={(value) => {
                setSelectDropdown({
                  ...selectDropdown,
                  stall: value ?? ''
                })
                if (selectDropdown?.stall !== value) {
                  onChangePage(0)
                }
              }}
              allowClear
              filterOption={true}>
              <Select.Option className="custom-table-limit-option" value={''}>
                Semua Stall
              </Select.Option>
              {dropdownStall?.map((stall, idx) => (
                <Select.Option key={idx?.toString()} value={stall}>
                  {stall}
                </Select.Option>
              ))}
            </Select>
            <DatePicker.RangePicker
              onOpenChange={(open) =>
                setSelectDropdown((prev) => ({ ...prev, isShowDatePicker: open }))
              }
              onChange={(dates, dateStrings) => {
                if (dates) {
                  setTableState((prev) => ({ ...prev, offset: 0 }))
                  setSelectDropdown((prev) => ({
                    ...prev,
                    dateRangeValue: dates,
                    start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('DD/MM/YYYY')
                  }))
                } else {
                  setTableState((prev) => ({ ...prev, offset: 0 }))
                  setSelectDropdown((prev) => ({
                    ...prev,
                    dateRangeValue: [],
                    start_date: '',
                    end_date: ''
                  }))
                }
              }}
              format={'DD/MM/YYYY'}
              open={selectDropdown?.isShowDatePicker}
              value={selectDropdown?.dateRangeValue}
              placeholder={['Start date', 'End date']}
              popupClassName="custom-date-picker-dropdown"
              className="custom-date-picker py-[6px] w-fit rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500"
            />
          </div>
          <Input
            className="w-1/4 rounded-md py-1.5"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

export default HasilInspeksiComponent
