import { Breadcrumb, Layout } from 'antd'
import { apiPost, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UpdateBengkelComponent from './components/UpdateBengkelComponent'
import { showErrorMessage } from 'utils'
import { useState } from 'react'
import { logout } from 'store/actions'

const TambahBengkel = () => {
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  // Submit
  const handleSubmit = async (data) => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiPost({
        url: `${urls.master.prefix}/${urls.master.workshops.root}`,
        data: data,
        token: token,
        onLogout: onLogout
      })

      if (res?.resStatus === 200) {
        router.push(`/workshop-management`)
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Workshop Management</Breadcrumb.Item>
        <Breadcrumb.Item>Tambah Bengkel</Breadcrumb.Item>
      </Breadcrumb>
      <UpdateBengkelComponent
        type="add"
        handleSubmit={handleSubmit}
        isLoadingCRUD={isLoadingCRUD}
        onLogout={onLogout}
      />
    </Layout>
  )
}

export default TambahBengkel
