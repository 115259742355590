import React from 'react'

const InvoiceNullifyCard = () => (
  <div
    className="flex min-h-screen overflow-x-hidden items-center justify-center"
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#FFFFFF'
    }}>
    <span className="font-semibold text-gray-900 text-xl">Invoice Tidak Ditemukan.</span>
  </div>
)

export default InvoiceNullifyCard
