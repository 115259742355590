import React from 'react'
import { Card, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { IcPlus } from 'assets/icons'
import { Button, Table } from 'components'

const LandingPageComponent = ({
  data,
  columns,
  handleAddBanner,
  isLoading,
  tableState,
  setTableState,
}) => {
  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }
  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between mb-5">
          <Typography.Text className="font-semibold text-2xl">
            Landing Page Banner
          </Typography.Text>
          <Button
            className="flex items-center py-0 text-white bg-primary-500 border-primary-500"
            onClick={handleAddBanner}
          >
            <IcPlus className="text-white" size={19} />
            <span>Tambah Banner</span>
          </Button>
        </div>
        <Table
          pending={isLoading}
          columns={columns}
          data={data}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

export default LandingPageComponent
