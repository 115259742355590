import React from 'react'

const IcCalendarWeek = ({ className, size = 20 }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8333 1.5H3.16667C2.24619 1.5 1.5 2.24619 1.5 3.16667V14.8333C1.5 15.7538 2.24619 16.5 3.16667 16.5H14.8333C15.7538 16.5 16.5 15.7538 16.5 14.8333V3.16667C16.5 2.24619 15.7538 1.5 14.8333 1.5Z" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.33333 4.83333H4.83333V12.3333H7.33333V4.83333Z" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.1667 4.83333H10.6667V9H13.1667V4.83333Z" stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default IcCalendarWeek
