import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, apiPut, urls } from 'api'
import {
  CardContainer,
  CatatanFormCard,
  CustomButton,
  FormInput,
  InformasiOdooCard,
  InformasiServisCard,
  InformasiSpekHorizontalUnitCard,
  InformasiUserCard,
  Loading,
  ModalDeleteRekomendasiServis,
  ModalListHargaRekomendasiServis,
  ModalServiceSelesaiJadwalService,
  ModalTambahService,
  PaymentReceiptCard,
  ServiceListCard,
  ServiceProgressCard
} from 'components'
import { Formik } from 'formik'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import {
  formatThousandsINTL,
  onlyNumbers,
  showErrorMessage,
  showSuccessMessage,
  statusLayanan,
  unformatCurrency
} from 'utils'

const onReturnDescServisSelesai = (status, data) => {
  switch (status) {
    case false:
      return 'Progres servis belum dilengkapi. Mohon cek progress sebelum konfirmasi servis selesai.'
    case true:
      return `Anda Yakin konfirmasi servis selesai untuk unit ${
        data?.unit_name ?? ''
      } dengan pemilik ${data?.full_name ?? ''}.`
    default:
      break
  }
}

const onReturnCompleteProgress = (data) => {
  if (data?.progress_finished_total === data?.progress_total) {
    return true
  } else {
    return false
  }
}

const JadwalServiceDetailServicePage = ({ isMechanicView = false }) => {
  const { idUnit } = useParams()
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [detailServis, setDetailServis] = useState({})
  const [dataRekomendasiServis, setDataRekomendasiServis] = useState({})
  const [isLoadingProgress, setIsLoadingProgress] = useState(false)
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [listData, setListData] = useState({
    generals: [],
    spareparts: [],
    selectGeneralId: null
  })

  const [catatanForm, setCatatanForm] = useState({
    isEditing: false,
    desc: ''
  })
  const [mileage, setMileage] = useState(0)
  const [formKM, setFormKM] = useState({
    isLoading: false,
    isEmpty: false,
    messageError: 'Nilai harus lebih besar dari 0'
  })

  useEffect(() => {
    setMileage(detailServis?.mileage)
  }, [detailServis?.mileage])

  const [isModalTambahServis, setIsModalTambahServis] = useState(false)
  const [isModalDeleteRekomendasi, setIsModalDeleteRekomendasi] = useState(false)
  const [selectedGeneralServiceData, setSelectedGeneralServiceData] = useState(null)
  const [isModalListHargaRekomendasi, setIsModalListHargaRekomendasi] = useState(false)
  const [isModalServisSelesai, setIsModalServisSelesai] = useState({
    show: false,
    desc: '',
    status: false,
    data: {}
  })

  const sumVoucherDiscount = (voucher) => {
    let sum = 0
    voucher?.map((voc) => {
      sum += voc?.price_total
    })

    return sum
  }

  const listServis = useMemo(() => {
    const general =
      detailServis?.general_services?.map((item) => ({
        id: item?.id,
        layanan: item?.general_service_name,
        sparepart_name: item?.sparepart_name,
        status: item?.status,
        is_finished: item?.is_finished,
        general_service_id: item?.general_service_id,
        quantity: item?.quantity,
        type: 'general_services'
      })) ?? []
    let packet = []
    detailServis?.packets?.forEach((item) => {
      item?.spareparts?.map((sparepart) => {
        packet = [
          ...packet,
          {
            id: sparepart?.id,
            packet_name: item?.packet_name,
            layanan: sparepart?.general_service_name,
            general_service_id: sparepart?.general_service_id,
            sparepart_name: sparepart?.sparepart_name,
            status: sparepart?.status,
            is_finished: sparepart?.is_finished,
            quantity: sparepart?.quantity,
            type: 'packet_services'
          }
        ]
      })
    })
    return [...general, ...packet]
  }, [detailServis?.general_services, detailServis?.packets])
  const listRekomendasiServis = useMemo(() => {
    let sparepart = []
    let totalPriceSparepart = 0
    let service = []
    let totalPriceService = 0

    dataRekomendasiServis?.general_services
      ?.filter((f) => f?.status !== 'REJECTED' && f?.status !== 'CANCELED')
      ?.forEach((item) => {
        if (item?.is_free === false) {
          sparepart.push({
            sparepart_name: item?.sparepart_name,
            price: item?.sparepart_price * item?.quantity,
            quantity: item?.quantity,
            discount: sumVoucherDiscount(item?.voucher_sparepart_detail),
            total: item?.sparepart_price_total
          })
          totalPriceSparepart += item?.sparepart_price_total
        }
        service.push({
          general_service_name: item?.general_service_name,
          price: item?.service_price + item?.class_price,
          quantity: '',
          discount: sumVoucherDiscount(item?.voucher_service_detail),
          total: item?.service_price_total,
          status: item?.status
        })
        totalPriceService += item?.service_price_total
      }) ?? []

    dataRekomendasiServis?.packets?.forEach((item) => {
      item?.spareparts
        ?.filter((f) => f?.status !== 'REJECTED' && f?.status !== 'CANCELED')
        ?.forEach((spare) => {
          if (spare?.is_free === false) {
            sparepart.push({
              sparepart_name: spare?.sparepart_name,
              price: spare?.sparepart_price * spare?.quantity,
              quantity: spare?.quantity,
              discount: sumVoucherDiscount(spare?.voucher_sparepart_detail),
              total: spare?.sparepart_price_total
            })
            totalPriceSparepart += spare?.sparepart_price_total
          }
          service.push({
            general_service_name: spare?.general_service_name,
            price: spare?.service_price + spare?.class_price,
            quantity: '',
            discount: sumVoucherDiscount(spare?.voucher_service_detail),
            total: spare?.service_price_total
          })
          totalPriceService += spare?.service_price_total
        }) ?? []
    }) ?? []
    return {
      sparepart: sparepart,
      totalPriceSparepart: totalPriceSparepart,
      service: service,
      totalPriceService: totalPriceService
    }
  }, [
    dataRekomendasiServis?.recommendation_services,
    dataRekomendasiServis?.general_services,
    dataRekomendasiServis?.packets
  ])

  const progressServis = useMemo(
    () => listServis?.filter((f) => f?.status === 'DEFAULT' || f?.status === 'APPROVED'),
    [listServis]
  )

  // API

  // Get Detail
  const { isLoading: isLoadingDetail, refetch: refetchDetail } = useQuery({
    queryKey: ['detail-data'],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.servicePickup.root}/${idUnit}?is_show_relation=true&is_show_progress=true`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      // console.log('🚀 ~ file: index.jsx:123 ~ JadwalServiceDetailServicePage ~ res:', res)
      setDetailServis(res?.resData?.data ?? {})
      setCatatanForm((prev) => ({ ...prev, desc: res?.resData?.data?.notes ?? '' }))
    }
  })

  // Get Calculation Recommendation
  const { refetch: refetchRecommendationCalculation } = useQuery({
    queryKey: ['recommendation-service-calculation'],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.booking}/${idUnit}/v2/${urls?.service?.recommendationService}/calculation`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      // console.log('🚀 ~ file: index.jsx:142 ~ JadwalServiceDetailServicePage ~ res:', res)
      setDataRekomendasiServis(res?.resData?.data ?? {})
    }
  })

  // Get All General Services
  const { refetch: refetchGSL } = useQuery({
    queryKey: ['general-service-list'],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListData((prev) => ({
        ...prev,
        generals: res?.resData?.data ?? []
      }))
    }
  })
  // Get Detail General Services
  useQuery({
    queryKey: ['general-service-list', listData?.selectGeneralId],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      if (listData?.selectGeneralId !== null) {
        const res = await apiGet({
          token,
          url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.root}/${listData?.selectGeneralId}`,
          onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      // console.log('🚀 ~ file: index.jsx:258 ~ JadwalServiceDetailServicePage ~ res:', res)
      if (listData?.selectGeneralId !== null) {
        let newSparepartList =
          res?.resData?.data?.spareparts.length > 0
            ? res?.resData?.data?.spareparts.map((sparepart) => ({
                ...sparepart,
                name: `${sparepart?.sku} - ${sparepart?.name}`
              }))
            : []
        setListData((prev) => ({
          ...prev,
          spareparts: newSparepartList
        }))
      }
    }
  })

  // Update Checked Progress Service
  const handleCheckProgressServis = async (data, body) => {
    try {
      setIsLoadingProgress(true)
      let res
      if (data?.type === 'general_services') {
        res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.servicePickup.rootProgressGeneral}/${data?.id}/${urls.service.servicePickup.progress}`,
          data: body,
          token: token,
          onLogout
        })
      } else {
        res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.servicePickup.rootProgressPacket}/${data?.id}/${urls.service.servicePickup.progress}`,
          data: body,
          token: token,
          onLogout
        })
      }
      if (res?.resStatus === 200) {
        refetchDetail()
        refetchRecommendationCalculation()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingProgress(false)
    } catch (error) {
      // console.log(error)
    }
  }

  // Add Servis
  const handleAddServis = async (body, resetForm) => {
    try {
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.servicePickup.root}/${idUnit}/${urls.service.servicePickup.addService}`,
        data: body,
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        refetchDetail()
        resetForm()
        refetchGSL()
        setIsModalTambahServis(false)
        setListData((prev) => ({ ...prev, spareparts: [], selectGeneralId: null }))
        refetchRecommendationCalculation()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    } catch (error) {
      // console.log(error)
    }
  }

  // Edit Catatan
  const handleEditCatatan = async () => {
    try {
      const body = {
        notes: catatanForm?.desc ?? ''
      }
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.servicePickup.root}/${idUnit}/${urls.service.servicePickup.updateNotes}`,
        data: body,
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        refetchDetail()
        setCatatanForm((prev) => ({ ...prev, isEditing: false }))
        // resetForm()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    } catch (error) {
      // console.log(error)
    }
  }
  // Konfirmasi Servis Selesai
  const handleKonfirmasiServiceSelesai = async () => {
    try {
      if (parseInt(mileage ?? 0) > 0) {
        setIsLoadingCRUD(true)
        const res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.servicePickup.rootService}/${idUnit}/${urls.service.servicePickup.finished}`,
          token: token,
          onLogout
        })
        if (res?.resStatus === 200) {
          setIsModalServisSelesai({ ...isModalServisSelesai, show: false, status: false, data: {} })
          showSuccessMessage({ content: `Servis Mobil ${detailServis?.unit_name} Selesai` })
          router.goBack()
        } else {
          showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        }
        setIsLoadingCRUD(false)
      } else {
        showErrorMessage({
          content: 'Kilometer saat ini tidak boleh kosong'
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // CONFIRM DELETE SELECTED RECOMMENDATION SERVICE
  const handleDeleteSelectedRecommendationService = async (selectedServiceId) => {
    try {
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.servicePickup.root}/${urls.service.recommendationService}/${selectedServiceId}/${urls.service.servicePickup.cancel}`,
        data: {},
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        refetchDetail()
        refetchRecommendationCalculation()

        // const newSelectGeneralId = listData?.selectGeneralId?.filter(
        //   (id) => id !== selectedServiceId
        // )
        // console.log('newSelectGeneralId', newSelectGeneralId)
        // setListData((prev) => ({ ...prev, spareparts: [], selectGeneralId: newSelectGeneralId }))
        showSuccessMessage({ content: 'Rekomendasi servis berhasil dibatalkan' })
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
    } catch (error) {
      // console.log(error)
    }
  }

  const listService = useMemo(() => {
    let sparepart = []
    let totalPriceSparepart = 0
    let service = []
    let totalPriceService = 0
    detailServis?.general_services
      ?.filter(
        (f) =>
          f?.status !== 'WAITING_APPROVAL' && f?.status !== 'REJECTED' && f?.status !== 'CANCELED'
      )
      ?.forEach((item) => {
        if (item?.is_free === false) {
          sparepart.push({
            sparepart_name: item?.sparepart_name,
            price: item?.sparepart_price * item?.quantity,
            quantity: item?.quantity,
            discount: sumVoucherDiscount(item?.voucher_sparepart_detail),
            total: item?.sparepart_price_total
          })
          totalPriceSparepart += item?.sparepart_price_total
        }
        service.push({
          general_service_name: item?.general_service_name,
          price: item?.service_price + item?.class_price,
          quantity: '',
          discount: sumVoucherDiscount(item?.voucher_service_detail),
          total: item?.service_price_total
        })
        totalPriceService += item?.service_price_total
      }) ?? []

    detailServis?.packets?.forEach((item) => {
      item?.spareparts
        ?.filter(
          (f) =>
            f?.status !== 'WAITING_APPROVAL' && f?.status !== 'REJECTED' && f?.status !== 'CANCELED'
        )
        ?.forEach((spare) => {
          if (spare?.is_free === false) {
            sparepart.push({
              sparepart_name: spare?.sparepart_name,
              price: spare?.sparepart_price * spare?.quantity,
              quantity: spare?.quantity,
              discount: sumVoucherDiscount(spare?.voucher_sparepart_detail),
              total: spare?.sparepart_price_total
            })
            totalPriceSparepart += spare?.sparepart_price_total
          }
          service.push({
            general_service_name: spare?.general_service_name,
            price: spare?.service_price + spare?.class_price,
            quantity: '',
            discount: sumVoucherDiscount(spare?.voucher_service_detail),
            total: spare?.service_price_total
          })
          totalPriceService += spare?.service_price_total
        }) ?? []
    }) ?? []

    return {
      totalPriceSparepart: totalPriceSparepart,
      sparepart: sparepart,
      totalPriceService: totalPriceService,
      service: service
    }
  }, [detailServis])

  let timeoutSearch = useRef(null)
  const handleChangeMileage = async (value) => {
    let val = ''
    if (value !== undefined) {
      if (onlyNumbers(unformatCurrency(value))) {
        if (value !== '') {
          val = unformatCurrency(value)
        } else {
          val = value
        }
      } else {
        val = 0
      }
      if (value === '' || val === '') {
        val = 0
      }

      clearTimeout(timeoutSearch.current)

      if (val === 0) {
        if (value === '') {
          setMileage(val)
          setFormKM((prev) => ({ ...prev, isEmpty: true }))
        }
      } else {
        setMileage(val)
        setFormKM((prev) => ({ ...prev, isEmpty: false }))
        if (value !== '') {
          timeoutSearch.current = setTimeout(async () => {
            setFormKM((prev) => ({ ...prev, isLoading: true }))

            const res = await apiPut({
              url: `${urls.service.prefix}/${urls.service.servicePickup.root}/${idUnit}/${urls.service.servicePickup.updateMileage}`,
              data: {
                mileage: val
              },
              token: token,
              onLogout
            })

            if (res?.resStatus === 200) {
              setDetailServis((prev) => ({ ...prev, mileage: res?.resData?.data?.mileage }))
            } else {
              showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
              setMileage(detailServis?.mileage)
            }

            setFormKM((prev) => ({ ...prev, isLoading: false }))
          }, 2000)
        }
      }
    }
  }

  if (isLoadingDetail) {
    return <Loading visible />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">Servis</Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() =>
            isMechanicView
              ? router.push('/mekanik/services/jadwal')
              : router.push('/services/jadwal')
          }>
          Jadwal Service
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.go(-1)}>
          {detailServis?.workshop_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          {detailServis?.unit_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Service
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <ModalServiceSelesaiJadwalService
          openModal={isModalServisSelesai.show}
          desc={isModalServisSelesai?.desc}
          onClose={() =>
            setIsModalServisSelesai({
              ...isModalServisSelesai,
              show: false,
              status: false,
              data: {}
            })
          }
          status={isModalServisSelesai?.status}
          onSubmit={handleKonfirmasiServiceSelesai}
          loading={isLoadingCRUD}
        />
        <ModalDeleteRekomendasiServis
          isOpen={isModalDeleteRekomendasi}
          generalService={selectedGeneralServiceData}
          onClose={() => {
            setSelectedGeneralServiceData(null)
            setIsModalDeleteRekomendasi(false)
          }}
          onDelete={handleDeleteSelectedRecommendationService}
        />
        <ModalTambahService
          isOpen={isModalTambahServis}
          listData={listData}
          setListData={setListData}
          onClose={() => setIsModalTambahServis(false)}
          onSubmit={handleAddServis}
          listServis={listServis}
        />
        <ModalListHargaRekomendasiServis
          isOpen={isModalListHargaRekomendasi}
          onClose={() => setIsModalListHargaRekomendasi(false)}
          data={{
            adminFee: dataRekomendasiServis?.admin_fee ?? 0,
            total:
              dataRekomendasiServis?.item_total +
              (dataRekomendasiServis?.admin_fee ?? 0) +
              (dataRekomendasiServis?.distance_price ?? 0) +
              (dataRekomendasiServis?.tax ?? 0),
            tax: dataRekomendasiServis?.tax ?? 0,
            subTotal: dataRekomendasiServis?.item_total ?? 0,
            discountVoucher: dataRekomendasiServis?.voucher_discount_total ?? 0,
            discountCompany: dataRekomendasiServis?.company_discount_total ?? 0,
            totalPrice: dataRekomendasiServis?.grand_price_total ?? 0,
            listServices: listRekomendasiServis,
            priceTotalBefore: dataRekomendasiServis?.price_total_before ?? 0,
            distanceFee: dataRekomendasiServis?.distance_price ?? 0
          }}
        />
        <div className="flex flex-col w-full space-y-5">
          <InformasiServisCard
            tipe="Service"
            data={{
              tempat: detailServis?.workshop_name,
              tanggal: moment(detailServis?.service_date).format('DD/MM/YYYY'),
              tanggalTransaksi: moment(detailServis?.created_at).format('DD/MM/YYYY'),
              waktu: `${moment(detailServis?.service_start_time, 'HH:mm').format(
                'HH:mm'
              )} - ${moment(detailServis?.service_end_time, 'HH:mm').format('HH:mm')}`,
              stall: detailServis?.stall_no,
              mekanik: detailServis?.mechanic_name,
              statusInspeksi: detailServis?.status
            }}
          />
          <InformasiUserCard
            title={'Informasi Pemilik'}
            data={{
              nama: detailServis?.full_name ?? '-',
              email: detailServis?.email ?? '-',
              telepon: detailServis?.phone ?? '-',
              alamat: detailServis?.address ?? '-'
            }}
          />
          {statusLayanan(detailServis?.status) === 'Selesai' &&
          (detailServis?.odoo_repair_order?.invoice_name ||
            detailServis?.odoo_repair_order?.repair_order_name ||
            detailServis?.odoo_sale_order?.sale_order_name) ? (
            <InformasiOdooCard
              invoiceNumber={detailServis?.odoo_repair_order?.invoice_name}
              roNumber={detailServis?.odoo_repair_order?.repair_order_name}
            />
          ) : null}
          <InformasiSpekHorizontalUnitCard
            data={{
              merek: detailServis?.unit_information?.brand?.name,
              model: detailServis?.unit_information?.model?.name,
              tipe: detailServis?.unit_information?.type?.name,
              platNomor: detailServis?.unit_information?.police_number,
              tahun: detailServis?.unit_information?.production_year,
              transmisi: detailServis?.unit_information?.transmission?.name,
              warna: detailServis?.unit_information?.color?.name,
              bahanBakar: detailServis?.unit_information?.fuel?.name,
              noRangka: detailServis?.unit_information?.frame_number,
              noMesin: detailServis?.unit_information?.engine_number,
              tag: detailServis?.unit_information?.unitTags
            }}
          />
          <CardContainer minHeight="20%">
            <div className="flex flex-row justify-between items-center">
              <div className="flex gap-1">
                <span className="font-semibold text-2xl text-gray-900">Kilometer saat ini</span>
                <span className="text-[red] text-lg"> *</span>
              </div>

              <div className="flex items-center gap-2 w-fit relative">
                {formKM?.isLoading ? (
                  <Loading title="" visible display="absolute" classNameWidth="" />
                ) : null}
                <Formik
                  initialValues={{
                    mileage: mileage
                  }}>
                  <FormInput
                    name="mileage"
                    placeholder="Kilometer"
                    isDisabled={
                      detailServis?.status === 'SERVICE_FINISHED' ||
                      detailServis?.status === 'SERVICE_CONFIRMED' ||
                      formKM?.isLoading
                    }
                    isRequired
                    isKM
                    isIconRight
                    isValue
                    value={formatThousandsINTL(mileage)}
                    isEmpty={formKM?.isEmpty}
                    errorMessage={formKM?.messageError}
                    onValidate={handleChangeMileage}
                    onBlur={() => {
                      if (formKM?.isEmpty) {
                        setMileage(detailServis?.mileage)
                        setFormKM((prev) => ({ ...prev, isEmpty: false }))
                      }
                    }}
                  />
                </Formik>
              </div>
            </div>
          </CardContainer>
          <div className="grid grid-cols-2 w-full space-x-5 items-start justify-start">
            <ServiceListCard
              onDetailRekomendasiServis={() => setIsModalListHargaRekomendasi(true)}
              dataRekomendasiServis={dataRekomendasiServis}
              data={detailServis}
              onDeleteService={(generalService) => {
                setSelectedGeneralServiceData(generalService)
                setIsModalDeleteRekomendasi(true)
              }}
              onTambahServis={() => setIsModalTambahServis(true)}
              disabled={
                detailServis?.status === 'SERVICE_FINISHED' ||
                detailServis?.status === 'SERVICE_CONFIRMED'
                  ? true
                  : false
              }
            />
            <ServiceProgressCard
              statusService={detailServis?.status}
              handleModalServiceSelesai={() => {
                setIsModalServisSelesai({
                  desc: onReturnDescServisSelesai(
                    onReturnCompleteProgress(detailServis),
                    detailServis
                  ),
                  show: true,
                  status: onReturnCompleteProgress(detailServis),
                  data: detailServis
                })
              }}
              data={progressServis}
              onSubmit={handleCheckProgressServis}
              isLoading={isLoadingProgress}
              disabled={
                detailServis?.status === 'SERVICE_FINISHED' ||
                detailServis?.status === 'SERVICE_CONFIRMED'
                  ? true
                  : false
              }
            />
          </div>
          <PaymentReceiptCard
            title="Rincian Harga"
            data={{
              adminFee: detailServis?.admin_fee ?? 0,
              total:
                detailServis?.item_total +
                (detailServis?.admin_fee ?? 0) +
                (detailServis?.distance_price ?? 0) +
                (detailServis?.tax ?? 0),
              tax: detailServis?.tax ?? 0,
              subTotal: detailServis?.item_total ?? 0,
              discountVoucher: detailServis?.voucher_discount_total ?? 0,
              discountCompany: detailServis?.company_discount_total ?? 0,
              totalPrice: detailServis?.price_total ?? 0,
              listServices: listService,
              distanceFee: detailServis?.distance_price ?? 0
            }}
            isTypeServis={true}
          />
          <CatatanFormCard
            title={'Edit Catatan'}
            isEditing={catatanForm.isEditing}
            state={catatanForm}
            desc={catatanForm.desc ?? ''}
            onEdit={() =>
              setCatatanForm((prev) => ({
                ...prev,
                isEditing: !prev.isEditing,
                desc: detailServis?.notes ?? ''
              }))
            }
            onBlurEditing={(value) => setCatatanForm((prev) => ({ ...prev, desc: value }))}
          />
          {catatanForm?.isEditing ? (
            <div className="flex flex-row w-full items-center justify-end space-x-4">
              <CustomButton
                type="plain"
                onClick={() =>
                  setCatatanForm((prev) => ({
                    ...prev,
                    isEditing: false,
                    desc: detailServis?.notes ?? ''
                  }))
                }>
                Cancel
              </CustomButton>
              <CustomButton onClick={handleEditCatatan}>Confirm</CustomButton>
            </div>
          ) : null}
        </div>
      </Content>
    </Layout>
  )
}

export default JadwalServiceDetailServicePage
