import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Breadcrumb, Layout } from 'antd'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import InternalUnitDetailComponent from './components/InternalUnitDetailComponent'
import { apiGet, apiPost } from 'api'
import { logout } from 'store/actions'
import ModalJualMobil from './components/ModalJualMobil'

const InternalUnitDetail = (props) => {
  const paramsRouter = useParams()
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [modalJual, setModalJual] = useState(false)

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['internal-unit', paramsRouter?.id],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      let res = null
      if (paramsRouter?.id) {
        res = await apiGet({
          token,
          url: `master-service/admin/internal-units/${paramsRouter?.id}`,
          onLogout: () => dispatch(logout())
        })
      }
      return res
    }
  })

  const saveJualMobil = async (formBody) => {
    try {
      let res
      let body = {
        ...formBody,
        price: parseInt(formBody?.price ?? 0)
      }
      res = await apiPost({
        url: `master-service/admin/internal-units/${data?.resData?.data?.id}/sell`,
        token,
        data: body,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModalJual(false)
        refetch()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <ModalJualMobil
        isOpen={modalJual}
        data={data?.resData?.data}
        onSubmit={saveJualMobil}
        onCancel={() => setModalJual(false)}
      />
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => props.history.goBack()}>
          Internal Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item>{data?.resData?.data?.unit_name}</Breadcrumb.Item>
      </Breadcrumb>
      <InternalUnitDetailComponent
        data={data?.resData?.data} 
        isLoading={isLoading}
        onShowModalJual={() => setModalJual(true)}
      />
    </Layout>
  )
}

export default InternalUnitDetail
