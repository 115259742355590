import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory, useParams } from 'react-router-dom'
import { apiGet, urls } from 'api'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from 'components'
import { logout } from 'store/actions'
import { BookingHistoryContent, BookingHistoryExTaxiContent } from './components'

const RiwayatPenjualanDetailPage = () => {
  const { id, type } = useParams()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const router = useHistory()
  const [bookingHistories, setBookingHistories] = useState()

  const { isLoading } = useQuery({
    queryKey: ['jual-unit-booking-histories-detail'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url:
          type === 'Mobil Bekas'
            ? `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.root}/${parseInt(id)}`
            : `${urls.jualUnit.prefix}/${urls.jualUnit.bookingHistory.exTaxi.root}/${parseInt(id)}`,
        token: user.token,
        onLogout: () => dispatch(logout())
      }),
    onSuccess: (data) => {
      if (data.resStatus === 200) {
        setBookingHistories(data.resData.data)
      }
    }
  })

  const onReturnContent = () => {
    switch (type) {
      case 'Mobil Bekas':
        return <BookingHistoryContent data={bookingHistories} />
      case 'ex-Taxi':
        return <BookingHistoryExTaxiContent data={bookingHistories} />
      default:
        return null
    }
  }

  if (isLoading) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Riwayat Penjualan
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Riwayat
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>{onReturnContent()}</Content>
    </Layout>
  )
}

export default RiwayatPenjualanDetailPage
