import React from 'react'
import { Modal, Typography } from 'antd'

const ModalKonfirmasiInpeksi = ({ isOpen, onCancel, onSubmit }) => (
  <Modal
    width={350}
    title="Konfirmasi Inspeksi"
    open={isOpen}
    onCancel={onCancel}
    onOk={onSubmit}
    centered
    okText="Confirm"
    okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
    cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
    <Typography.Text className="font-medium">
      {`Yakin dengan data inspeksi yang Anda buat?`}
    </Typography.Text>
  </Modal>
)

export default ModalKonfirmasiInpeksi
