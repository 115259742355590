import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import AuthRoute from './AuthRoute'
import MainRoute from './MainRoute'
import InvoicePage from 'pages/main/dynamic-pages/invoice/InvoicePage'
import SalesOrderPrintPage from 'pages/main/dynamic-pages/sales-order-print/SalesOrderPrintPage'

const Router = () => {
  const token = useSelector((state) => state.auth.token)
  const isFirstLoggedIn = useSelector((state) => state.auth.isFirstLoggedIn)
  const profile = useSelector((state) => state.auth.profile)

  if (!token) {
    return <AuthRoute isFirstLoggedIn={isFirstLoggedIn} pathname={window.location.pathname} />
  }

  return (
    <Switch>
      {/* invoice route */}
      <Route path="/jual-unit/katalog/invoice/:id/:type" component={InvoicePage} />
      <Route path="/services/jadwal/:id/invoice" component={InvoicePage} />
      <Route path="/inspeksi/jadwal-inspeksi/:id/invoice" component={InvoicePage} />
      <Route
        path="/workshop-management/jadwal-inspeksi/invoice/:id"
        exact
        component={InvoicePage}
      />
      <Route path="/booking-mobil/:id/invoice" component={InvoicePage} />
      {/* sales order print route */}
      <Route path="/jual-unit/katalog/sales-order-print" component={SalesOrderPrintPage} />
      <Route path="/services/jadwal/sales-order-print" component={SalesOrderPrintPage} />
      {/* inspeksi */}
      <Route
        path="/inspeksi/jadwal-inspeksi/:id/sales-order-print"
        component={SalesOrderPrintPage}
      />
      {/* main route */}
      <MainRoute profile={profile} />
      {isFirstLoggedIn ? <Redirect to="/" /> : null}
    </Switch>
  )
}

export default Router
