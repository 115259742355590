import React, { useMemo, useState } from 'react'
import { BaseIcon, FormDropDown, FormInput, ModalContainer, ModalForm } from 'components'
import { mdiClose } from '@mdi/js'
import { Form, Formik } from 'formik'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { useSelector } from 'react-redux'

const ModalCreateUpdateSubDistrict = ({
  minHeight,
  onClose,
  onSubmit,
  state,
  dropDownProvince
}) => {
  const user = useSelector((state) => state.auth)
  const [dataDropDownDistrict, setDataDropDownDistrict] = useState([])
  const [provinceSelectorState, setProvinceSelectorState] = useState(
    state.type === 'update' ? state.provinceId : null
  )
  const [districtSelectorState, setDistrictSelectorState] = useState(
    state.type === 'update' ? state.districtId : null
  )
  const [subDistrictNameState, setSubDistrictNameState] = useState(
    state.type === 'update' ? state.subDistrictName : ''
  )
  const [isEmptyForm, setIsEmptyForm] = useState({
    province: {
      isEmpty: false,
      message: 'Silahkan Pilih Provinsi'
    },
    city: {
      isEmpty: false,
      message: 'Silahkan Pilih Kota/Kabupaten'
    },
    subDistrict: {
      isEmpty: false,
      message: 'Silahkan Masukkan Nama Kecamatan'
    }
  })

  const { isLoading, data } = useQuery({
    queryKey: ['district-by-province'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.location.cities.filterByProvince}/${state.provinceId}`,
        token: user.token
      }),
    onSuccess: (data) => {
      setDataDropDownDistrict([])
      if (state.type === 'update') {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDataDropDownDistrict((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const district = useMemo(
    () => ({
      districtDropDown: dataDropDownDistrict
    }),
    [dataDropDownDistrict, setDataDropDownDistrict]
  )

  return (
    <ModalContainer>
      <ModalForm minHeight={minHeight}>
        <div className="flex flex-col w-full space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span className="font-semibold text-gray-900 text-lg">
              {`${state.type === 'create' ? 'Tambah' : 'Edit'} Lokasi`}
            </span>
            <div
              className="flex h-8 w-8 items-center justify-center hover:cursor-pointer"
              onClick={() => onClose()}>
              <BaseIcon path={mdiClose} iconSize={21} color="#98A2B3" />
            </div>
          </div>
          <div className="flex flex-col w-full space-y-5">
            <Formik
              enableReinitialize
              initialValues={{
                provinceSelector: provinceSelectorState
              }}>
              <FormDropDown
                isSearch
                allowClear
                label="Provinsi"
                placeholder="Pilih Provinsi"
                name="provinceSelector"
                isRequired
                isEmpty={isEmptyForm.province.isEmpty}
                errorMessage={isEmptyForm.province.message}
                data={dropDownProvince}
                onChange={async (value) => {
                  setDataDropDownDistrict([])
                  setProvinceSelectorState(value === '' || !value ? null : parseInt(value))
                  setDistrictSelectorState(null)
                  if (!value || parseInt(value) === 0) {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province,
                        isEmpty: true
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province,
                        isEmpty: false
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      }
                    })
                    const response = await apiGet({
                      url: `${urls.master.prefix}/${
                        urls.master.location.cities.filterByProvince
                      }/${parseInt(value)}`
                    })
                    if (response.resStatus === 200) {
                      if (response.resData.data.length !== 0) {
                        response.resData.data.map((item) => {
                          setDataDropDownDistrict((prev) => [
                            ...prev,
                            { name: item.name, value: item.id }
                          ])
                        })
                      }
                    }
                  }
                }}
              />
            </Formik>
            <Formik
              enableReinitialize
              initialValues={{
                districtSelector: districtSelectorState
              }}>
              <FormDropDown
                isSearch
                allowClear
                label="Kota/Kabupaten"
                placeholder="Pilih Kota/Kabupaten"
                name="districtSelector"
                isRequired
                isEmpty={isEmptyForm.city.isEmpty}
                errorMessage={isEmptyForm.city.message}
                data={district.districtDropDown}
                onChange={(value) => {
                  setDistrictSelectorState(value === '' || !value ? null : parseInt(value))
                  if (!value || parseInt(value) < 1) {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city,
                        isEmpty: true
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city,
                        isEmpty: false
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict
                      }
                    })
                  }
                }}
              />
            </Formik>
            <Formik
              initialValues={{
                subDistrictName: subDistrictNameState
              }}>
              <FormInput
                label="Kecamatan"
                name="subDistrictName"
                placeholder="Masukkan Nama Kecamatan"
                isRequired
                isEmpty={isEmptyForm.subDistrict.isEmpty}
                errorMessage={isEmptyForm.subDistrict.message}
                onValidate={(value) => {
                  setSubDistrictNameState(value)
                  if (!value) {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict,
                        isEmpty: true
                      }
                    })
                  } else {
                    setIsEmptyForm({
                      province: {
                        ...isEmptyForm.province
                      },
                      city: {
                        ...isEmptyForm.city
                      },
                      subDistrict: {
                        ...isEmptyForm.subDistrict,
                        isEmpty: false
                      }
                    })
                  }
                }}
              />
            </Formik>
            <div className="flex flex-row w-full items-center justify-end space-x-4">
              <button
                type="button"
                className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-gray-700 bg-white"
                onClick={() => onClose()}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  if (
                    provinceSelectorState &&
                    provinceSelectorState > 0 &&
                    districtSelectorState &&
                    districtSelectorState > 0 &&
                    subDistrictNameState &&
                    subDistrictNameState !== ''
                  ) {
                    onSubmit({
                      provinceSelector: provinceSelectorState,
                      districtSelector: districtSelectorState,
                      subDistrictName: subDistrictNameState
                    })
                  }
                  setIsEmptyForm({
                    province: {
                      ...isEmptyForm.province,
                      isEmpty: !provinceSelectorState || provinceSelectorState < 1 ? true : false
                    },
                    city: {
                      ...isEmptyForm.city,
                      isEmpty: !districtSelectorState < districtSelectorState < 1 ? true : false
                    },
                    subDistrict: {
                      ...isEmptyForm.subDistrict,
                      isEmpty: !subDistrictNameState || subDistrictNameState === '' ? true : false
                    }
                  })
                }}
                className="flex flex-row hover:cursor-pointer mt-4 items-center justify-around rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 py-2 font-medium text-md text-white bg-primary-500">
                {`${state.type === 'create' ? 'Tambah Lokasi' : 'Simpan'}`}
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </ModalContainer>
  )
}

export default ModalCreateUpdateSubDistrict
