import { Breadcrumb, DatePicker, Input, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { IcSearch } from 'assets/icons'
import { CardContainer, HeaderContainer, Table } from 'components'
import moment from 'moment'
import 'moment/locale/en-gb'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { pathStringify } from 'utils'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  username: '',
  listData: [],
  start_date: '',
  end_date: '',
  dateRangeValue: []
}

const AuditTrailPage = () => {
  const { id, namaBengkel } = useParams()
  const router = useHistory()
  const rootUrl = window.location.pathname.substring(1, window.location.length).split('/')
  const [tableState, setTableState] = useState(initialTableState)
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const columns = [
    {
      name: 'Tanggal',
      selector: (row) => row?.date_time,
      cell: (row) => moment(row?.date_time).format('DD/MM/YYYY'),
      grow: 2
    },
    {
      name: 'Waktu',
      selector: (row) => row?.date_time,
      cell: (row) => moment(row?.date_time).format('HH:mm'),
      grow: 2
    },
    {
      name: 'User',
      selector: (row) => row.username,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <span className="font-medium text-gray-700">{`${row?.username ?? '-'}`}</span>
          <span className="font-medium text-gray-500">{`${row?.user_email ?? '-'}`}</span>
        </div>
      ),
      grow: 2
    },
    {
      name: 'Aksi',
      selector: (row) => row?.action,
      cell: (row) => <span className="font-medium text-gray-700">{row?.action}</span>,
      grow: 2
    }
  ]

  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates) {
      setTableState((prev) => ({
        ...prev,
        dateRangeValue: dates,
        start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('DD/MM/YYYY'),
        end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('DD/MM/YYYY'),
        offset: 0
      }))
    } else {
      setTableState((prev) => ({
        ...prev,
        dateRangeValue: [],
        start_date: '',
        end_date: '',
        offset: 0
      }))
    }
  }

  //* Different path pola from menu
  const onReturnBreadcumbItem = (rootMenu) => {
    const parentBreadcrumbStyle = 'font-semibold text-gray-700'
    const childBreadcrumbStyle = 'font-medium text-gray-500'

    switch (rootMenu) {
      case 'mekanik':
      case 'services': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Servis</Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => {
                rootUrl[0] === 'mekanik'
                  ? rootUrl[2] === 'jadwal'
                    ? router.push('/mekanik/services/jadwal')
                    : router.push('/mekanik/services/pickup')
                  : rootUrl[1] === 'jadwal'
                  ? router.push('/services/jadwal')
                  : router.push('/services/pickup')
              }}
              className={`${childBreadcrumbStyle} cursor-pointer`}>
              Pilih Bengkel
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.goBack()}>
              {namaBengkel}
            </Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Audit Trail</Breadcrumb.Item>
          </>
        )
      }
      case 'workshop-management': {
        return (
          <>
            <Breadcrumb.Item
              className={`cursor-pointer ${parentBreadcrumbStyle}`}
              onClick={() => router.goBack()}>
              Workshop Management
            </Breadcrumb.Item>
            {rootUrl?.[1] === 'jadwal-inspeksi' || rootUrl?.[1] === 'jadwal-servis' ? (
              <Breadcrumb.Item className={`capitalize ${childBreadcrumbStyle}`}>
                {rootUrl?.[1]?.replaceAll('-', ' ')}
              </Breadcrumb.Item>
            ) : null}
            <Breadcrumb.Item className={childBreadcrumbStyle}>{namaBengkel}</Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Audit Trail</Breadcrumb.Item>
          </>
        )
      }
      case 'test-drive': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Test Drive</Breadcrumb.Item>
            {rootUrl[1] === 'mobil-bekas' ? (
              <Breadcrumb.Item
                className={`${childBreadcrumbStyle} cursor-pointer`}
                onClick={() => router.goBack()}>
                {namaBengkel}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item
                className={`${childBreadcrumbStyle} cursor-pointer`}
                onClick={() => router.goBack()}>
                Ex Taxi
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item className={childBreadcrumbStyle}>Audit Trail</Breadcrumb.Item>
          </>
        )
      }
      case 'inspeksi': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Inspeksi</Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => router.push('/inspeksi/jadwal-inspeksi')}
              className={`${childBreadcrumbStyle} cursor-pointer`}>
              Jadwal Inspeksi
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={`${childBreadcrumbStyle} cursor-pointer`}
              onClick={() => router.goBack()}>
              {namaBengkel}
            </Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Audit Trail</Breadcrumb.Item>
          </>
        )
      }
      case 'jual-unit': {
        return (
          <>
            <Breadcrumb.Item className={parentBreadcrumbStyle}>Jual Unit</Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => router.goBack()}
              className={`${childBreadcrumbStyle} cursor-pointer`}>
              {rootUrl[1] === 'stock' ? 'Stock' : 'Katalog'}
            </Breadcrumb.Item>
            <Breadcrumb.Item className={childBreadcrumbStyle}>Audit Trail</Breadcrumb.Item>
          </>
        )
      }
      default:
        return window.location.pathname
          .substring(1, window.location.pathname.length)
          .split('/')
          .map((item, index) => (
            <Breadcrumb.Item
              key={index}
              className={`${index === 0 ? `${parentBreadcrumbStyle}` : `${childBreadcrumbStyle}`}`}>
              {pathStringify(item)}
            </Breadcrumb.Item>
          ))
    }
  }

  // API
  const { isLoading } = useQuery({
    queryKey: [
      'audit-trail',
      tableState.offset,
      tableState.limit,
      tableState.username,
      tableState.start_date,
      tableState.end_date,
      rootUrl,
      id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        url: onReturnURL(),
        token: user.token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  const onReturnURL = () => {
    const url = `${urls?.auth.user.prefix}/${urls?.auth.user.auditTrail}?username=${tableState.username}&limit=${tableState?.limit}&offset=${tableState?.offset}&start_date=${tableState?.start_date}&end_date=${tableState?.end_date}`

    switch (rootUrl[0]) {
      case 'test-drive':
        if (rootUrl[1] === 'mobil-bekas') {
          return `${url}&menu=${'TEST_DRIVE/MOBIL_BEKAS'}&workshop_id=${id}`
        } else {
          return `${url}&menu=${'TEST_DRIVE/EX-TAXI'}`
        }
      case 'inspeksi':
        return `${url}&menu=${'INSPEKSI/JADWAL_INSPEKSI'}&workshop_id=${id}`
      case 'workshop-management':
        if (rootUrl[1] === 'jadwal-inspeksi') {
          return `${url}&menu=${'INSPEKSI/JADWAL_INSPEKSI'}&workshop_id=${id}`
        } else if (rootUrl[1] === 'jadwal-servis') {
          return `${url}&menu=${'SERVICE/SCHEDULE_SERVICE'}&workshop_id=${id}`
        } else {
          return `${url}&menu=${'WORKSHOP_MANAGEMENT/DATA_WORKSHOP'}&workshop_id=${id}`
        }
      case 'services':
      case 'mekanik':
        if (rootUrl[1] === 'jadwal' || rootUrl[2] === 'jadwal') {
          return `${url}&menu=${'SERVICE/SCHEDULE_SERVICE'}&workshop_id=${id}`
        } else {
          return `${url}&menu=${'SERVICE/PICKUP_SERVICE'}&workshop_id=${id}`
        }
      case 'jual-unit':
        if (rootUrl[1] === 'stock') {
          return `${url}&menu=${'JUAL_UNIT/STOCK'}`
        } else {
          if (rootUrl[2] === 'mobil-bekas') {
            return `${url}&menu=${'JUAL_UNIT/KATALOG/MOBIL_BEKAS'}`
          } else {
            return `${url}&menu=${'JUAL_UNIT/KATALOG/EX-TAXI'}`
          }
        }
      default:
        return url
    }
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>{onReturnBreadcumbItem(rootUrl[0])}</Breadcrumb>
      <Content className="h-screen" style={{ margin: 8 }}>
        <CardContainer minHeight="65%">
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer title="Audit Trail" />
            <div className="flex justify-between mt-2 gap-2">
              <div className="flex items-center space-x-2">
                {/* <Select
                  className="custom-table-limit"
                  style={{ width: '150px' }}
                  value={values?.user_id}
                  onChange={(e) => setTableState((prev) => ({ ...prev, user_id: e, offset: 0 }))
                  }>
                  <Select.Option className="custom-table-limit-option" value="">
                    Semua User
                  </Select.Option>
                  {listUser?.map((user, idx) => (
                    <Select.Option key={idx} className="custom-table-limit-option" value={user?.id}>
                      {user?.name}
                    </Select.Option>
                  ))}
                </Select> */}
                <DatePicker.RangePicker
                  onOpenChange={(open) => setIsShowDatePicker(open)}
                  onChange={handleChangeRangePicker}
                  format="DD/MM/YYYY"
                  open={isShowDatePicker}
                  value={tableState?.dateRangeValue}
                  placeholder={['Tanggal Mulai', 'Tanggal Akhir']}
                  popupClassName="custom-date-picker-dropdown"
                  className="custom-date-picker py-[6px] w-fit rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500"
                />
              </div>
              <Input
                className="w-1/4 rounded-md py-1.5"
                placeholder="Pencarian"
                prefix={<IcSearch className="text-gray-400" />}
                value={tableState?.username}
                onChange={(e) => {
                  setTableState((prev) => ({ ...prev, username: e?.target?.value, offset: 0 }))
                }}
              />
            </div>

            <Table
              columns={columns}
              data={tableState?.listData}
              pending={isLoading}
              totalRows={tableState?.totalRows}
              handlePerRowsChange={handlePerRowsChange}
              pageSize={tableState?.limit}
              page={tableState?.offset}
              onChangePrevPage={() => {
                if (tableState?.offset > 0) {
                  onChangePage(tableState?.offset - tableState?.limit)
                }
              }}
              onChangeNextPage={() => {
                if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                  onChangePage(tableState?.offset + tableState?.limit)
                }
              }}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default AuditTrailPage
