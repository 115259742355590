import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { CalendarCard, HeaderMapService, Loading } from 'components'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { dayPropGetter, eventPropGetterInspection } from 'utils'
import JadwalInspeksiViewComponent from './components/JadwalInspeksiViewComponent'

const JadwalInspeksiView = () => {
  const { id } = useParams()
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const bigCalendarRef = useRef(null)
  const [dataCabang, setDataCabang] = useState({})
  const [filterCalendar, setFilterCalendar] = useState({
    start_date: moment().startOf('week').format('YYYY-MM-DD'),
    end_date: moment().endOf('week').format('YYYY-MM-DD')
  })
  const [events, setEvents] = useState([])
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  useEffect(() => {
    const hashUrl = location?.hash
    if (hashUrl === '' || hashUrl === null || hashUrl === undefined) {
      const mount = setTimeout(
        () =>
          window.requestAnimationFrame(() =>
            bigCalendarRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          ),
        700
      )
      return () => clearTimeout(mount)
    }
  }, [events, setEvents])

  // Get Detail Cabang
  const { isLoading } = useQuery({
    queryKey: ['detail-cabang', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}/${id}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDataCabang(res?.resData?.data)
    }
  })

  // Get Jadwal Service
  const { refetch: refetchSchedule } = useQuery({
    queryKey: ['jadwal-test-drive', filterCalendar?.start_date, filterCalendar?.end_date],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection?.prefix}/${
          urls?.inspection?.calendarSchedule
        }/${id}?start_date=${moment(filterCalendar?.start_date).format(
          'DD/MM/YYYY'
        )}&end_date=${moment(filterCalendar?.end_date).format('DD/MM/YYYY')}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      const reWrite = res?.resData?.data?.map((item) => {
        const parseDate = moment(item?.inspection_date).format('YYYY-MM-DD')
        return {
          ...item,
          title: item?.unit_name,
          start: moment(parseDate + ' ' + item?.start_hour).toDate(),
          end: moment(parseDate + ' ' + item?.end_hour).toDate()
        }
      })
      setEvents(reWrite)
    }
  })

  if (isLoading) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item>Jadwal Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item
          className="cursor-pointer"
          onClick={() => router.push('/inspeksi/jadwal-inspeksi')}>
          Daftar Cabang
        </Breadcrumb.Item>
        <Breadcrumb.Item>{dataCabang?.name}</Breadcrumb.Item>
      </Breadcrumb>

      <Content style={{ margin: 8 }}>
        <div className="flex flex-col w-full space-y-5">
          <HeaderMapService
            namaBengkel={dataCabang?.name}
            cabang={dataCabang?.city_name}
            alamat={`Kota ${dataCabang?.city_name}, Provinsi ${dataCabang?.province_name}`}
            pin_google_map={dataCabang?.pin_google_map}
          />
          <div className="overflow-hidden" ref={bigCalendarRef}>
            <CalendarCard
              title="Jadwal Inspeksi"
              events={events}
              startDate={new Date(filterCalendar.start_date)}
              dayPropGetter={dayPropGetter}
              eventPropGetter={eventPropGetterInspection}
              onChangeMonth={(date) => {
                const start = new Date(date)
                start.setDate(1)
                setFilterCalendar({
                  start_date: moment(start).startOf('week').format('YYYY-MM-DD'),
                  end_date: moment(start).endOf('week').format('YYYY-MM-DD')
                })
              }}
              onNextWeek={() => {
                setFilterCalendar((prev) => ({
                  start_date: moment(prev.start_date).add(7, 'days').format('YYYY-MM-DD'),
                  end_date: moment(prev.end_date).add(7, 'days').format('YYYY-MM-DD')
                }))
              }}
              onPrevWeek={() => {
                setFilterCalendar((prev) => ({
                  start_date: moment(prev.start_date).subtract(7, 'days').format('YYYY-MM-DD'),
                  end_date: moment(prev.end_date).subtract(7, 'days').format('YYYY-MM-DD')
                }))
              }}
              onGoToAuditTrail={() =>
                router.push(`/inspeksi/jadwal-inspeksi/${dataCabang?.name}/${id}/audit-trail`)
              }
            />
          </div>
          <JadwalInspeksiViewComponent
            stall={dataCabang?.stall}
            refetchSchedule={refetchSchedule}
            onLogout={onLogout}
            token={token}
            idCabang={id}
          />
        </div>
      </Content>
    </Layout>
  )
}

export default JadwalInspeksiView
