import { Input, Select, Typography } from 'antd'
import { useFormik } from 'formik'
import ModalLayout from 'layouts/modal-layout'
import 'moment/locale/en-gb'
import { useState } from 'react'

import TextArea from 'antd/lib/input/TextArea'
import { apiGet, apiPut, urls } from 'api'
import { CustomButton, Loading } from 'components'
import { errorsField, validationInput } from 'helpers'
import { showErrorMessage } from 'utils'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { logout } from 'store/actions'

const ModalEditProfile = ({ onClose, isOpen, data, token, refetch, onLogout }) => {
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const dispatch = useDispatch()
  const ValidationCheck = (values) => {
    const errors = {}
    const { name, email, phone_number, address, postal_code } = values

    if (!validationInput(name, { isRequired: true })?.valid) {
      errors.name = validationInput(name, {
        isRequired: true
      })?.messageError
    }
    if (!validationInput(email, { isRequired: true, isEmail: true })?.valid) {
      errors.email = validationInput(email, {
        isRequired: true,
        isEmail: true
      })?.messageError
    }
    if (!validationInput(phone_number, { isRequired: true, minLength: 11 })?.valid) {
      errors.phone_number = validationInput(phone_number, {
        isRequired: true,
        minLength: 11
      })?.messageError
    }
    if (!validationInput(address, { isRequired: true })?.valid) {
      errors.address = validationInput(address, {
        isRequired: true
      })?.messageError
    }
    if (!validationInput(postal_code, { isRequired: true })?.valid) {
      errors.postal_code = validationInput(postal_code, {
        isRequired: true
      })?.messageError
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? '',
      email: data?.email ?? '',
      phone_number: data?.phone_number ?? '',
      address: data?.address ?? '',
      postal_code: data?.postal_code ?? '',
      province_name: data?.province_name ?? '',
      province_id: data?.province_id ?? null,
      city_name: data?.city_name ?? '',
      city_id: data?.city_id ?? null,
      subdistrict_name: data?.subdistrict_name ?? '',
      subdistrict_id: data?.subdistrict_id ?? null,
      location_name: data?.location_name ?? '',
      location_id: data?.location_id ?? null
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      handleSubmit(values)
    }
  })

  const provinceList = useQuery({
    queryKey: ['province-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.location?.province?.all}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onError: (e) => {
      console.log(e)
    }
  })

  const cityList = useQuery({
    queryKey: ['city-list', values.province_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = []
      if (values.province_id !== '' && values.province_id !== null) {
        const response = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.cities?.filterByProvince}/${values.province_id}`,
          onLogout: () => dispatch(logout())
        })
        res = response?.resData?.data
      }
      return res
    },
    onError: (e) => {
      console.log(e)
    },
    enabled: !provinceList?.isLoading
  })

  const subDistrictList = useQuery({
    queryKey: ['sub-district-list', values.city_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = []
      if (values.city_id !== '' && values.city_id !== null) {
        const response = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.subDistrict?.filterByCity}/${values.city_id}`,
          onLogout: () => dispatch(logout())
        })
        res = response?.resData?.data
      }
      return res
    },
    onError: (e) => {
      console.log(e)
    },
    enabled: !cityList?.isLoading && !provinceList?.isLoading
  })

  const locationList = useQuery({
    queryKey: ['location-list', values.subdistrict_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = []
      if (values.subdistrict_id !== '' && values.subdistrict_id !== null) {
        const response = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.all?.filterBySubDistrict}/${values.subdistrict_id}`,
          onLogout: () => dispatch(logout())
        })
        res = response?.resData?.data
      }
      return res
    },
    onError: (e) => {
      console.log(e)
    },
    enabled: !cityList?.isLoading && !provinceList?.isLoading && !subDistrictList?.isLoading
  })

  // Submit
  const handleSubmit = async (body) => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiPut({
        url: `${urls?.jualUnit?.prefix}/${urls?.jualUnit?.contactBirdmobil}`,
        data: body,
        token: token,
        onLogout
      })
      if (res?.resStatus === 200) {
        onClose()
        refetch()
        formik.handleReset()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        formik.setErrors(errorsField(res?.resData?.errors))
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ModalLayout
      className=" w-full md:w-[50%] rounded-md"
      headerTitle="Edit Profile Perusahaan"
      onCancel={onClose}
      openModal={isOpen}>
      <div className="flex flex-col w-full space-y-4">
        <div className="mb-1">
          <Typography.Text className="font-medium mb-2">
            Nama Perusahaan
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Input
            className="rounded py-2"
            placeholder="Masukan Nama Perusahaan"
            value={values?.name}
            name="name"
            onChange={formik.handleChange}
          />
          {touched?.name && errors?.name && <span className="text-[red]">{errors?.name}</span>}
        </div>
        <div className="mb-1">
          <Typography.Text className="font-medium mb-2">
            Email
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Input
            className="rounded py-2"
            placeholder="Masukan Email"
            value={values?.email}
            name="email"
            onChange={formik.handleChange}
          />
          {touched?.email && errors?.email && <span className="text-[red]">{errors?.email}</span>}
        </div>
        <div className="mb-1">
          <Typography.Text className="font-medium mb-2">
            Nomor Telepon
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Input
            className="rounded py-2"
            placeholder="Masukan Nomor Telepon"
            value={values?.phone_number}
            name="phone_number"
            onChange={formik.handleChange}
          />
          {touched?.phone_number && errors?.phone_number && (
            <span className="text-[red]">{errors?.phone_number}</span>
          )}
        </div>
        <div className="mb-1">
          <Typography.Text className="font-medium mb-2">
            Alamat <span className="text-[red]">*</span>
          </Typography.Text>
          <TextArea
            className="rounded py-2"
            style={{
              height: '95px'
            }}
            placeholder="Alamat"
            name="address"
            value={values?.address}
            onChange={formik.handleChange}
          />
          {touched?.address && errors?.address && (
            <span className="text-[red]">{errors?.address}</span>
          )}
        </div>
        <div className="mb-3">
          <Typography.Text className="font-medium mb-2">
            Provinsi<span className="text-primary-500">*</span>
          </Typography.Text>
          <Select
            showSearch
            className="custom-table-limit"
            style={{ width: '100%' }}
            placeholder="Pilih Provinsi"
            onChange={(value) => {
              if (values?.province_id !== value) {
                formik.setFieldValue('city_id', null)
                formik.setFieldValue('city_name', '')
                formik.setFieldValue('subdistrict_id', null)
                formik.setFieldValue('subdistrict_name', '')
                formik.setFieldValue('location_id', null)
                formik.setFieldValue('location_name', '')
              }
              formik.setFieldValue('province_id', value)
              const find = provinceList?.data?.resData?.data?.findIndex((item) => item.id === value)
              formik.setFieldValue('province_name', provinceList?.data?.resData?.data[find]?.name)
            }}
            // onSearch={(value) => handleSearch('brand', value)}
            value={values?.province_id}
            options={provinceList?.data?.resData?.data?.map((province) => {
              return {
                value: province.id,
                label: province.name
              }
            })}
            filterOption={(input, option) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </div>
        <div className="mb-3">
          <Typography.Text className="font-medium mb-2">
            Kota/Kabupaten<span className="text-primary-500">*</span>
          </Typography.Text>
          <Select
            showSearch
            disabled={!values?.province_id}
            className="custom-table-limit"
            style={{ width: '100%' }}
            placeholder="Pilih Kota/Kabupaten"
            onChange={(value) => {
              if (values?.city_id !== value) {
                formik.setFieldValue('subdistrict_id', null)
                formik.setFieldValue('subdistrict_name', '')
                formik.setFieldValue('location_id', null)
                formik.setFieldValue('location_name', '')
              }
              formik.setFieldValue('city_id', value)
              const find = cityList?.data?.findIndex((item) => item.id === value)
              formik.setFieldValue('city_name', cityList?.data[find]?.name)
            }}
            // onSearch={(value) => handleSearch('brand', value)}
            value={values?.city_id}
            options={cityList?.data?.map((city) => {
              return {
                value: city.id,
                label: city.name
              }
            })}
            filterOption={(input, option) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </div>
        <div className="mb-3">
          <Typography.Text className="font-medium mb-2">
            Kecamatan<span className="text-primary-500">*</span>
          </Typography.Text>
          <Select
            showSearch
            disabled={!values?.city_id}
            className="custom-table-limit"
            style={{ width: '100%' }}
            placeholder="Pilih Kecamatan"
            onChange={(value) => {
              if (values?.sub_district_id !== value) {
                formik.setFieldValue('location_id', null)
                formik.setFieldValue('location_name', '')
              }
              formik.setFieldValue('subdistrict_id', value)
              const find = subDistrictList?.data?.findIndex((item) => item.id === value)
              formik.setFieldValue('subdistrict_name', subDistrictList?.data[find]?.name)
            }}
            // onSearch={(value) => handleSearch('brand', value)}
            value={values?.subdistrict_id}
            options={subDistrictList?.data?.map((item) => {
              return {
                value: item.id,
                label: item.name
              }
            })}
            filterOption={(input, option) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </div>
        <div className="mb-3">
          <Typography.Text className="font-medium mb-2">
            Kelurahan/Desa<span className="text-primary-500">*</span>
          </Typography.Text>
          <Select
            showSearch
            disabled={!values?.subdistrict_id}
            className="custom-table-limit"
            style={{ width: '100%' }}
            placeholder="Pilih Kelurahan/Desa"
            onChange={(value) => {
              formik.setFieldValue('location_id', value)
              const find = locationList?.data?.findIndex((item) => item.id === value)
              formik.setFieldValue('location_name', locationList?.data[find]?.name)
            }}
            // onSearch={(value) => handleSearch('brand', value)}
            value={values?.location_id}
            options={locationList?.data?.map((item) => {
              return {
                value: item.id,
                label: item.name
              }
            })}
            filterOption={(input, option) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
          {/* {locations?.map((item) => (
              <Select.Option key={`location-${item?.id?.toString()}`} value={item.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select> */}
        </div>
        <div className="mb-1">
          <Typography.Text className="font-medium mb-2">
            Kode POS
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Input
            className="rounded py-2"
            placeholder="Masukan Nama User"
            value={values?.postal_code}
            name="postal_code"
            onChange={formik.handleChange}
          />
          {touched?.postal_code && errors?.postal_code && (
            <span className="text-[red]">{errors?.postal_code}</span>
          )}
        </div>

        <div className="flex flex-row items-center justify-end space-x-4">
          {isLoadingCRUD ? (
            <Loading title="" visible classNameWidth="" />
          ) : (
            <>
              <CustomButton
                type="plain"
                onClick={() => {
                  onClose()
                  formik.handleReset()
                }}>
                Cancel
              </CustomButton>
              <CustomButton onClick={formik.handleSubmit}>Confirm</CustomButton>
            </>
          )}
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalEditProfile
