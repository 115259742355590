export const sparepartUnitList = [
    {
        brand: 'Toyota',
        model: 'Innova',
        type: 'V'
    },
    {
        brand: 'Honda',
        model: 'Civic',
        type: 'Nouva'
    },
    {
        brand: 'Honda',
        model: 'Jazz',
        type: 'RS'
    },
]
  