import * as actionTypes from "../actionTypes";

export const setInspeksiStep1 = (unit_id, unit_name, unit_police_number, owner_name, owner_email, owner_phone_number, owner_address) => {
    return async (dispatch, getState) => {
        const userId = getState().auth.profile.id;
        await dispatch({
            type: actionTypes.SET_INSPEKSI_STEP1,
            user_id: userId,
            unit_id: unit_id,
            unit_name: unit_name,
            unit_police_number: unit_police_number,
            owner_name: owner_name,
            owner_email: owner_email,
            owner_phone_number: owner_phone_number,
            owner_address: owner_address
        })
    }
}

export const setInspeksiStep2 = (workshop_id, workshop_name, schedule_id, schedule_name, stall, inspection_date, workshop) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.SET_INSPEKSI_STEP2,
            workshop_id: workshop_id,
            workshop_name: workshop_name,
            schedule_id: schedule_id,
            schedule_name: schedule_name,
            stall: stall,
            inspection_date: inspection_date,
            workshop: workshop
        })
    }
}

export const resetInspeksi = () => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.RESET_INSPEKSI
        })
    }
}

