import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Breadcrumb, Layout } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { IcEdit, IcTrash } from 'assets/icons'
import { Button } from 'components'
import SparepartUnitComponent from './components/SparepartUnitComponent'
import ModalDeleteSparepartUnit from './components/ModalDeleteSparepartUnit'
import { apiDelete, apiGet } from 'api'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null,
  brand_id: '',
  model_id: '',
  type_id: ''
}

const SparepartUnit = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const router = useHistory()
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: null
  })
  const columns = [
    {
      name: 'Brand',
      selector: (row) => row?.brand_name,
      sortable: true
    },
    {
      name: 'Model',
      selector: (row) => row?.model_name,
      sortable: true
    },
    {
      name: 'Tipe',
      selector: (row) => row?.type_name,
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() =>
              router.push(`/sparepart/unit/edit/${row?.id}`, { type: 'edit', data: row })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ show: true, type: 'delete', data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'sparepart-unit-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.brand_id,
      tableState.model_id,
      tableState.type_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order,
        brand_id: tableState.brand_id,
        model_id: tableState.model_id,
        type_id: tableState.type_id
      }
      const res = await apiGet({
        url: 'servis-service/admin/units',
        data: params,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  const brand = useQuery({
    queryKey: ['brand-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: 'master-service/admin/brands/all',
        onLogout: () => dispatch(logout())
      })
      return res
    }
  })

  const model = useQuery({
    queryKey: ['model-list', tableState.brand_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        brand_id: tableState.brand_id,
        offset: 0,
        limit: 10
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'master-service/admin/models/all',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading
  })

  const typeList = useQuery({
    queryKey: ['type-unit', tableState.model_id, tableState.brand_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let params = {
        brand_id: tableState.brand_id,
        model_id: tableState.model_id
      }
      const res = await apiGet({
        token,
        data: params,
        url: `master-service/admin/types/all`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: !isLoading && !brand.isLoading && !model.isLoading
  })

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        url: `servis-service/admin/units/${data?.id}`,
        token,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        refetch()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Sparepart</Breadcrumb.Item>
        <Breadcrumb.Item>Unit</Breadcrumb.Item>
      </Breadcrumb>
      <ModalDeleteSparepartUnit
        isOpen={modal.show}
        type={modal.type}
        onSubmit={deleteData}
        data={modal.data}
        onCancel={() => setModal({ ...modal, show: false })}
      />
      <SparepartUnitComponent
        isLoading={isLoading}
        columns={columns}
        brandList={brand?.data?.resData?.data}
        modelList={model?.data?.resData?.data}
        typeList={typeList?.data?.resData?.data}
        // data={tableState?.listData}
        data={tableState?.listData}
        onAdd={() => router.push('/sparepart/unit/add', { type: 'add' })}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Layout>
  )
}
export default SparepartUnit
