import React, { useState } from 'react'
import { Breadcrumb, Col, DatePicker, Layout, Row, Select, Switch } from 'antd'
import CardSectionHeader from 'components/card/card-section-header'
import CustomButton from 'components/custom-button'
import { FormInput, Loading } from 'components'
import { useParams, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { useQuery } from 'react-query'
import { apiGet, apiPost, apiPut, apiResponse, urls } from 'api'
import moment from 'moment'
import 'moment/locale/en-gb'
import { showErrorMessage, showSuccessMessage } from 'utils'
import _ from 'lodash'

const initialDataUser = {
  fullname: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukka nama'
  },
  company_id: {
    value: -1
  },
  company_email: {
    value: ''
  },
  email: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan email pribadi'
  },
  phone_number: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan nomor telepon'
  },
  day: {
    value: moment(new Date(), 'DD')
  },
  month: {
    value: moment(new Date(), 'MM')
  },
  year: {
    value: moment(new Date(), 'YYYY')
  },
  npwp: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan npwp'
  },
  province: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih provinsi'
  },
  city: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih kota/kabupaten'
  },
  sub_district: {
    value: -1,
    isEmpty: false,
    message: 'Silahkan pilih kecamatan'
  },
  address: {
    value: '',
    isEmpty: false,
    message: 'Silahkan masukkan alamat'
  }
}

function AddEditUserCompany() {
  const { idUser, idCompany } = useParams()
  const history = useHistory()
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch
  const onLogout = () => dispatch(logout())
  const [userState, setUserState] = useState(initialDataUser)
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)
  const [dropDownProvince, setDropDownProvince] = useState([{ name: 'Pilih Provinsi', value: -1 }])
  const [dropDownCity, setDropDownCity] = useState([{ name: 'Pilih Kota/Kabupaten', value: -1 }])
  const [dropDownSubDistrict, setDropDownSubDistrict] = useState([
    { name: 'Pilih Kecamatan', value: -1 }
  ])

  const { isLoadingLocation } = useQuery({
    queryKey: ['perusahaan-user-detail', idUser],
    refetchOnWindowFocus: false,
    enabled: !!idUser,
    queryFn: async () =>
      await Promise.all([
        apiGet({
          url: `${urls.master.perusahaan.detail.user.prefix}/${urls.master.perusahaan.detail.user.root}/${idUser}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.location.province.all}`,
          token: user.token,
          onLogout
        })
      ]),
    onSuccess: (data) => {
      setDropDownProvince([{ name: 'Pilih Provinsi', value: -1 }])
      if (data[0].resStatus === 200) {
        setUserState({
          ...userState,
          fullname: {
            ...userState.fullname,
            value: data[0].resData.data.fullname
          },
          company_id: {
            value: data[0].resData.data.company_id
          },
          company_email: {
            value: data[0].resData.data.company_email
          },
          email: {
            ...userState.email,
            value: data[0].resData.data.email
          },
          phone_number: {
            ...userState.phone_number,
            value: data[0].resData.data.phone_number
          },
          day: {
            ...userState.day,
            value: moment(data[0].resData.data.birth_date, 'YYYY/MM/DD')
          },
          month: {
            ...userState.month,
            value: moment(data[0].resData.data.birth_date, 'YYYY/MM/DD')
          },
          year: {
            ...userState.year,
            value: moment(data[0].resData.data.birth_date, 'YYYY/MM/DD')
          },
          npwp: {
            ...userState.npwp,
            value: data[0].resData.data.npwp
          },
          address: {
            ...userState.address,
            value: data[0].resData.data.address
          },
          province: {
            ...userState.province,
            value: data[0].resData.data.province_id
          },
          city: {
            ...userState.city,
            value: data[0].resData.data.city_id
          },
          sub_district: {
            ...userState.sub_district,
            value: data[0].resData.data.sub_district_id
          }
        })
      }
      if (data[1].resStatus === 200) {
        if (data[1].resData.data.length !== 0) {
          data[1].resData.data.map((item) => {
            setDropDownProvince((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const { isLoading: isLoadingCity } = useQuery({
    queryKey: ['cities-sub-districts'],
    refetchOnWindowFocus: false,
    enabled: userState.province.value > -1,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.location.cities.filterByProvince}/${parseInt(
          userState.province.value
        )}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownCity([{ name: 'Pilih Kota/Kabupaten', value: -1 }])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDropDownCity((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const { isLoading: isLoadingSubDistrict } = useQuery({
    queryKey: ['sub-districts'],
    refetchOnWindowFocus: false,
    enabled: userState.city.value > -1,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.location.subDistrict.filterByCity}/${parseInt(
          userState.city.value
        )}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownSubDistrict([{ name: 'Pilih Kecamatan', value: -1 }])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDropDownSubDistrict((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const { isLoading } = useQuery({
    queryKey: ['province'],
    refetchOnWindowFocus: false,
    enabled: !idUser,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.prefix}/${urls.master.location.province.all}`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setDropDownProvince([{ name: 'Pilih Provinsi', value: -1 }])
      if (data.resStatus === 200) {
        if (data.resData.data.length !== 0) {
          data.resData.data.map((item) => {
            setDropDownProvince((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  })

  const onChangeInputForm = (field, value) => {
    if (field === 'company_email') {
      setUserState({
        ...userState,
        [field]: {
          ...userState[field],
          value
        }
      })
    } else {
      setUserState({
        ...userState,
        [field]: {
          ...userState[field],
          value,
          isEmpty: value && value !== '' ? false : true
        }
      })
    }
  }

  const onChangeDropDownForm = async (field, value) => {
    setUserState({
      ...userState,
      [field]: {
        ...userState[field],
        value: parseInt(value),
        isEmpty: parseInt(value) > 0 ? false : true
      }
    })
    if (field === 'province' && parseInt(value) > 0) {
      setDropDownCity([{ name: 'Pilih Kota/Kabupaten', value: -1 }])
      const response = await apiGet({
        url: `${urls.master.prefix}/${urls.master.location.cities.filterByProvince}/${parseInt(
          value
        )}`
      })
      if (response.resStatus === 200) {
        if (response.resData.data.length !== 0) {
          response.resData.data.map((item) => {
            setDropDownCity((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
    if (field === 'city' && parseInt(value) > 0) {
      setDropDownSubDistrict([{ name: 'Pilih Kecamatan', value: -1 }])
      const response = await apiGet({
        url: `${urls.master.prefix}/${urls.master.location.subDistrict.filterByCity}/${parseInt(
          value
        )}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200) {
        if (response.resData.data.length !== 0) {
          response.resData.data.map((item) => {
            setDropDownSubDistrict((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
    }
  }

  const onCheckFieldEmpty = () =>
    Object.entries(userState).every((item) => {
      if (item[0] === 'company_email' || item[0] === 'company_id') {
        return true
      } else {
        if (item[1].value !== undefined && item[1].value !== '' && item[1].value !== null) {
          return true
        }
        showErrorMessage({ content: `Silahkan Lengkapi Field ${_.startCase(item[0])}` })
        return false
      }
    })

  if (isLoading ?? isLoadingLocation ?? isLoadingCity ?? isLoadingSubDistrict ?? isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <Layout
      style={{
        padding: 24,
        background: '#F5F5F5',
        width: '100%',
        overflowY: 'hidden'
      }}
      className="overflow-y-hidden">
      <Row className="gap-6">
        {/* Header */}
        <Breadcrumb style={{ margin: 8 }}>
          <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
            Master Data
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-inter font-medium text-gray-500">
            Perusahaan
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-inter font-medium text-gray-500">
            Detail Perusahaan
          </Breadcrumb.Item>
          <Breadcrumb.Item className="font-inter font-medium text-gray-500">
            {idUser !== undefined ? 'Edit' : 'Tambah'} User
          </Breadcrumb.Item>
        </Breadcrumb>
        {/* Content Container */}
        <Col span={24} className="grid grid-cols-1 gap-6">
          <CardSectionHeader>
            {/* toggle switch di hide dulu */}
            {/* {idUser !== undefined ? (
              <div className="text-sm-medium text-gray-700 max-w-fit gap-6 pb-3 flex">
                User Aktif
                <Switch defaultChecked disabled />
              </div>
            ) : null} */}
            <div className="grid grid-cols-1 gap-5">
              <Formik
                initialValues={{
                  fullname: userState.fullname.value
                }}>
                <FormInput
                  label="Nama Lengkap"
                  name="fullname"
                  placeholder="Masukkan nama"
                  isValue={true}
                  value={userState.fullname.value}
                  isRequired
                  isEmpty={userState.fullname.isEmpty}
                  errorMessage={userState.fullname.message}
                  onValidate={(value) => onChangeInputForm('fullname', value)}
                />
              </Formik>
              <Formik
                initialValues={{
                  company_email: userState.company_email.value
                }}>
                <FormInput
                  label="Email Perusahaan"
                  name="company_email"
                  isValue={true}
                  value={userState.company_email.value}
                  placeholder="Masukkan email perusahaan"
                  onValidate={(value) => onChangeInputForm('company_email', value)}
                />
              </Formik>
              <Formik
                initialValues={{
                  email: userState.email.value
                }}>
                <FormInput
                  label="Email Pribadi"
                  name="email"
                  isValue={true}
                  value={userState.email.value}
                  placeholder="Masukkan email pribadi"
                  isRequired
                  isEmpty={userState.email.isEmpty}
                  errorMessage={userState.email.message}
                  onValidate={(value) => onChangeInputForm('email', value)}
                />
              </Formik>
              <Formik
                initialValues={{
                  phone_number: userState.phone_number.value
                }}>
                <FormInput
                  label="Nomor Telepon"
                  name="phone_number"
                  placeholder="Masukkan nomor telepon"
                  isValue={true}
                  value={userState.phone_number.value}
                  isRequired
                  isEmpty={userState.phone_number.isEmpty}
                  errorMessage={userState.phone_number.message}
                  onValidate={(value) => onChangeInputForm('phone_number', value)}
                />
              </Formik>
              <div className="flex flex-col space-y-2">
                <label htmlFor="date" className="font-medium block mb-0 text-md text-gray-700">
                  Tanggal Lahir
                  <span className="text-red-600">{' *'}</span>
                </label>
                <div className="flex flex-row items-center space-x-4">
                  <DatePicker
                    placeholder="DD"
                    picker="date"
                    value={userState.day.value}
                    onChange={(value) => onChangeInputForm('day', value)}
                    className="custom-date-picker py-3 w-full"
                    popupClassName="custom-date-picker-dropdown"
                    format={['DD']}
                  />
                  <DatePicker
                    placeholder="MM"
                    picker="month"
                    value={userState.month.value}
                    onChange={(value) => onChangeInputForm('month', value)}
                    className="custom-date-picker py-3 w-full"
                    popupClassName="custom-date-picker-dropdown"
                    format={['MM']}
                  />
                  <DatePicker
                    placeholder="YYYY"
                    picker="year"
                    value={userState.year.value}
                    onChange={(value) => onChangeInputForm('year', value)}
                    className="custom-date-picker py-3 w-full"
                    popupClassName="custom-date-picker-dropdown"
                    format={['YYYY']}
                  />
                </div>
              </div>
              <Formik
                initialValues={{
                  npwp: userState.npwp.value
                }}>
                <FormInput
                  label="NPWP"
                  name="npwp"
                  isValue={true}
                  value={userState.npwp.value}
                  placeholder="Masukkan NPWP"
                  isRequired
                  isEmpty={userState.npwp.isEmpty}
                  errorMessage={userState.npwp.message}
                  onValidate={(value) => onChangeInputForm('npwp', value)}
                />
              </Formik>
              <Formik
                initialValues={{
                  address: userState.address.value
                }}>
                <FormInput
                  component="textarea"
                  height="100px"
                  label="Alamat"
                  name="address"
                  isValue={true}
                  value={userState.address.value}
                  placeholder="Masukkan alamat"
                  isRequired
                  isEmpty={userState.address.isEmpty}
                  errorMessage={userState.address.message}
                  onValidate={(value) => onChangeInputForm('address', value)}
                />
              </Formik>
              <div className="flex flex-row items-center space-x-4">
                <div className="w-1/3">
                  <label className="font-medium block mb-2 text-md text-gray-700">
                    Provinsi <span className="text-red-600">{' *'}</span>
                  </label>
                  <Select
                    className="custom-table-limit"
                    style={{ width: '100%' }}
                    value={userState.province.value ?? ''}
                    onChange={(e) => onChangeDropDownForm('province', e)}>
                    {dropDownProvince.map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                  {userState.province.isEmpty ? (
                    <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                      {`*${userState.province.message}`}
                    </div>
                  ) : null}
                </div>
                <div className="w-1/3">
                  <label className="font-medium block mb-2 text-md text-gray-700">
                    Kota <span className="text-red-600">{' *'}</span>
                  </label>
                  <Select
                    className="custom-table-limit"
                    style={{ width: '100%' }}
                    value={userState.city.value ?? ''}
                    onChange={(e) => onChangeDropDownForm('city', e)}>
                    {dropDownCity.map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                  {userState.city.isEmpty ? (
                    <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                      {`*${userState.city.message}`}
                    </div>
                  ) : null}
                </div>
                <div className="w-1/3">
                  <label className="font-medium block mb-2 text-md text-gray-700">
                    Kecamatan <span className="text-red-600">{' *'}</span>
                  </label>
                  <Select
                    className="custom-table-limit"
                    style={{ width: '100%' }}
                    value={userState.sub_district.value ?? ''}
                    onChange={(e) => onChangeDropDownForm('sub_district', e)}>
                    {dropDownSubDistrict.map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                  {userState.sub_district.isEmpty ? (
                    <div className="flex absolute justify-start items-start px-1 py-1 bg-transparent font-semibold text-xs text-red-500">
                      {`*${userState.sub_district.message}`}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </CardSectionHeader>
          <Col span={24} className="flex justify-end items-center gap-2">
            <CustomButton
              onClick={() => {
                if (idUser) {
                  history.replace(
                    `/master/perusahaan/perusahaandetail/${userState.company_id.value}/user`
                  )
                } else {
                  history.replace(`/master/perusahaan/perusahaandetail/${idCompany}/user`)
                }
              }}
              type="plain">
              Batal
            </CustomButton>
            <CustomButton
              onClick={async () => {
                setUserState({
                  ...userState,
                  fullname: {
                    ...userState.fullname,
                    isEmpty: userState.fullname.value === '' ? true : false
                  },
                  email: {
                    ...userState.email,
                    isEmpty: userState.email.value === '' ? true : false
                  },
                  phone_number: {
                    ...userState.phone_number,
                    isEmpty: userState.phone_number.value === '' ? true : false
                  },
                  npwp: {
                    ...userState.npwp,
                    isEmpty: userState.npwp.value === '' ? true : false
                  },
                  address: {
                    ...userState.address,
                    isEmpty: userState.address.value === '' ? true : false
                  },
                  province: {
                    ...userState.province,
                    isEmpty: userState.province.value < 1 ? true : false
                  },
                  city: {
                    ...userState.city,
                    isEmpty: userState.city.value < 1 ? true : false
                  },
                  sub_district: {
                    ...userState.sub_district,
                    isEmpty: userState.sub_district.value < 1 ? true : false
                  }
                })
                if (onCheckFieldEmpty()) {
                  let res = apiResponse()
                  const data = {
                    email: userState.email.value,
                    company_email: userState.company_email.value,
                    fullname: userState.fullname.value,
                    phone_number: userState.phone_number.value,
                    npwp: userState.npwp.value,
                    address: userState.address.value,
                    birth_date: `${moment(userState.day.value, 'DD').format('DD')}/${moment(
                      userState.month.value,
                      'MM'
                    ).format('MM')}/${moment(userState.year.value, 'YYYY').format('YYYY')}`,
                    province_id: userState.province.value,
                    province_name: dropDownProvince.find(
                      (item) => item.value === userState.province.value
                    ).name,
                    city_id: userState.city.value,
                    city_name: dropDownCity.find((item) => item.value === userState.city.value)
                      .name,
                    sub_district_id: userState.sub_district.value,
                    sub_district_name: dropDownSubDistrict.find(
                      (item) => item.value === userState.sub_district.value
                    ).name
                  }
                  setIsLoadingHelper(true)
                  if (!idUser) {
                    res = await apiPost({
                      url: `${urls.master.perusahaan.detail.user.prefix}/${urls.master.perusahaan.detail.user.root}/${idCompany}`,
                      data,
                      token: user.token,
                      onLogout
                    })
                  } else {
                    res = await apiPut({
                      url: `${urls.master.perusahaan.detail.user.prefix}/${urls.master.perusahaan.detail.user.root}/${idUser}`,
                      data,
                      token: user.token,
                      onLogout
                    })
                  }
                  if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                    if (!idUser) {
                      showSuccessMessage({ content: 'Succesfully Create a New User.' })
                    } else {
                      showSuccessMessage({ content: 'Succesfully Update User.' })
                    }
                    if (idUser) {
                      history.replace(
                        `/master/perusahaan/perusahaandetail/${userState.company_id.value}/user`
                      )
                    } else {
                      history.replace(`/master/perusahaan/perusahaandetail/${idCompany}/user`)
                    }
                  } else {
                    showErrorMessage({
                      content:
                        res.resData.message ??
                        res.resData.errors[0].message ??
                        (!idUser ? 'Failed to Create a New User.' : 'Failed to Update a User.')
                    })
                  }
                  setIsLoadingHelper(false)
                }
              }}>
              Simpan
            </CustomButton>
          </Col>
        </Col>
      </Row>
    </Layout>
  )
}

export default AddEditUserCompany
