import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { useParams } from 'react-router-dom'
import { cabangDataDummies, unitDataDummies } from 'assets/dummies'
import { DynamicBreadcrumbItem } from 'utils/dynamic-breadcrumb-item/dynamic-breadcrumb-item'
import { Form, Formik } from 'formik'
import {
  CatatanFormCard,
  InformasiServisCard,
  InformasiSpekHorizontalUnitCard,
  ModalTambahService,
  ServiceListCard,
  ServiceProgressCard
} from 'components'

const DetailServisPage = () => {
  const { unit } = useParams()
  const [units, setUnits] = useState(unitDataDummies.find((item) => item.id === parseInt(0)))
  const [listPayment, setListPayment] = useState([
    {
      title: 'Ganti Oli',
      subCategory: [{ name: 'Shell Hellix 5L', price: 200000 }]
    },
    {
      title: 'Tune Up Mesin',
      subCategory: [{ name: '2.0L VTEC Turbo', price: 500000 }]
    }
  ])

  const [informationService, setInformationService] = useState({
    tempat: 'Birdmobil Surabaya',
    tanggal: '24/10/2022',
    waktu: '07.00 - 09.00',
    stall: '2',
    mekanik: 'Nielsen Norman',
    statusInspeksi: 'Sedang Berlangsung'
  })
  const [catatanForm, setCatatanForm] = useState({
    isEditing: false,
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc.'
  })

  const [services, setServices] = useState([
    {
      layanan: 'Ganti Oli',
      item: { name: 'Shell Hellix 5L', amount: 3, price: 250000 },
      selected: true
    },
    {
      layanan: 'Tune Up Mesin',
      item: { name: '2.0 L VTEC Turbo', amount: 0, price: 1500000 },
      selected: false
    }
  ])

  const [dropDownLayanan, setDropDownLayanan] = useState([
    { name: 'Pilih Layanan', value: 'Pilih Layanan' },
    { name: 'Ganti Oli', value: 'Ganti Oli' },
    { name: 'Isi Ulang Freon', value: 'Isi Ulang Freon' },
    { name: 'Ganti Filter Dryer', value: 'Ganti Filter Dryer' },
    { name: 'Tune Up Mesin', value: 'Tune Up Mesin' },
    { name: 'Ganti Kampas Rem', value: 'Ganti Kampas Rem' }
  ])
  const [dropDownItem, setDropDownItem] = useState([
    { name: 'Pilih Item', value: 'Pilih Item' },
    { name: 'Shell Helix 5L Rp 200.000', value: 'Shell Helix 5L Rp 200.000' },
    { name: 'Shell Ultra Rp 100.000', value: 'Shell Ultra Rp 100.000' },
    { name: 'Castrol F1 Rp. 250.000', value: 'Castrol F1 Rp. 250.000' },
    { name: 'H-Tec Plus Rp. 300.000', value: 'H-Tec Plus Rp. 300.000' },
    { name: 'Mobil Super Rp 150.000', value: 'Mobil Super Rp 150.000' }
  ])
  const [isModalTambahServis, setIsModalTambahServis] = useState(false)

  return (
    <Layout
      className="grid grid-cols-1 gap-6"
      style={{ padding: 32, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb>
        {DynamicBreadcrumbItem(window.location.pathname, window.location.length)}
      </Breadcrumb>
      <ModalTambahService
        isOpen={isModalTambahServis}
        dropDownLayanan={dropDownLayanan}
        dropDownItem={dropDownItem}
        onClose={() => setIsModalTambahServis(false)}
        onSubmit={() => setIsModalTambahServis(false)}
      />
      <div className="flex flex-col w-full space-y-5">
        <InformasiServisCard tipe="Service" data={informationService} />
        <InformasiSpekHorizontalUnitCard data={units} />
        <div className="grid grid-cols-2 w-full space-x-5 items-start justify-start">
          <ServiceListCard data={services} onTambahServis={() => setIsModalTambahServis(true)} />
          <ServiceProgressCard data={services} />
        </div>
        <Formik
          initialValues={{
            desc: catatanForm.desc
          }}>
          <Form key="catatanForm">
            <CatatanFormCard
              title={'Edit Catatan'}
              isEditing={catatanForm.isEditing}
              desc={catatanForm.desc}
              onEdit={() => setCatatanForm({ ...catatanForm, isEditing: !catatanForm.isEditing })}
              onBlurEditing={(value) => setCatatanForm({ isEditing: false, desc: value })}
            />
          </Form>
        </Formik>
      </div>
    </Layout>
  )
}

export default DetailServisPage
