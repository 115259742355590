import { apiDelete, apiGet, urls } from 'api'
import { IcEdit, IcTrash } from 'assets/icons'
import { Button } from 'components'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ModalDeletePaketServis from './components/ModalDeletePaketServis'
import PaketServisComponent from './components/PaketServisComponent'
import { showErrorMessage } from 'utils'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const PaketServis = () => {
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [modalDelete, setModalDelete] = useState({
    show: false,
    data: {}
  })
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  // Get packet service
  const { isLoading, refetch, isFetching, isRefetching } = useQuery({
    queryKey: [
      'service-packet-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.servicePackets?.root}?keywords=${tableState.keywords}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  const columns = [
    {
      name: 'Nama Paket',
      selector: (row) => row?.name,
      cell: (row) => row?.name,
      sortable: true,
      grow: 2
    },
    {
      name: 'Daftar Servis',
      sortable: false,
      cell: (row) => (
        <div className="flex flex-wrap pt-2 max-h-[100px] overflow-scroll no-scrollbar">
          {row?.general_services?.map((item, index) => (
            <span
              key={`subkategori-${index}`}
              className="px-2 py-[2px] border-0 bg-[#EFF8FF] rounded-lg text-[#175CD3] mb-2 selection:text-black">
              {item?.name}
            </span>
          ))}
        </div>
      ),
      grow: 4
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => router.push(`/services/management/edit-paket-servis/${row?.id}`)}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModalDelete({ show: true, data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  // Delete
  const handleDeleteService = async (id) => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiDelete({
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.servicePackets?.root}/${id}`,
        token: token,
        onLogout
      })

      if (res?.resStatus === 200) {
        refetch()
        setModalDelete({ show: false })
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ModalDeletePaketServis
        isOpen={modalDelete.show}
        data={modalDelete.data}
        onCancel={() => setModalDelete({ show: false })}
        onOk={handleDeleteService}
        isLoading={isLoadingCRUD}
      />
      <PaketServisComponent
        columns={columns}
        data={tableState?.listData}
        isLoading={isLoading || isFetching || isFetching}
        tableState={tableState}
        setTableState={setTableState}
      />
    </>
  )
}

export default PaketServis
