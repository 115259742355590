import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import LakukanInspeksiComponentStep1 from './components/LakukanInspeksiComponentStep1'
import LakukanInspeksiComponentStep2 from './components/LakukanInspeksiComponentStep2'
import LakukanInspeksiComponentStep3 from './components/LakukanInspeksiComponentStep3'
import ModalKonfirmasiInpeksi from './components/ModalKonfirmasiInpeksi'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { apiGet, apiPost } from 'api'
import { delayTime, showErrorMessage, showSuccessMessage } from 'utils'
import { Loading } from 'components'

const LakukanInspeksi = (props) => {
  const [modalKonfirmasi, setModalKonfirmasi] = useState({
    show: false,
    data: {}
  })
  const router = useHistory()
  const step = router?.location?.state?.step ?? 1
  const dataParams = props?.history?.location?.state?.data ?? {}
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()

  const { data, isLoading, isFetching, isFetched } = useQuery({
    queryKey: ['internal-unit', router.location?.state?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = null
      if (router.location?.state?.id) {
        res = await apiGet({
          token,
          // url: `inspeksi-service/admin/inspection-unit/${router?.location?.state?.id}`,
          url: `master-service/admin/internal-units/${router?.location?.state?.id}`,
          onLogout: () => dispatch(logout())
        })
      }
      return res
    }
  })

  const dataOwner = useQuery({
    queryKey: ['inspection-unit', router.location?.state?.id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = null
      if (router.location?.state?.id) {
        res = await apiGet({
          token,
          url: `inspeksi-service/admin/inspection-unit/${router?.location?.state?.id}`,
          onLogout: () => dispatch(logout())
        })
      }
      return res
    }
  })

  const onSubmit = () => {
    try {
      delete modalKonfirmasi.data.form?.stall
      const mount = setTimeout(async () => {
        const res = await apiPost({
          url: `inspeksi-service/admin/inspection-unit/register`,
          data: modalKonfirmasi.data.form,
          token
        })
        setModalKonfirmasi({ ...modalKonfirmasi, show: false })
        if (res?.resStatus === 200 || res?.resStatus === 201) {
          showSuccessMessage({ content: 'Inspeksi Mobil Sukses!' })
          delayTime(2000)
          router.push(`/internal-unit/${res.resData.data?.unit_id}`, { data })
        } else {
          showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        }
      })
      return () => clearTimeout(mount)
    } catch (error) {
      setModalKonfirmasi({ ...modalKonfirmasi, show: false })
      console.log(error)
    }
  }

  if (isLoading ?? isFetched ?? isFetching) {
    return <Loading visible={true} />
  }

  return (
    <Layout className="h-full" style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => props.history.go(-2 + (-1 * step + 1))}>
          Internal Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => props.history.go(-1 + (-1 * step + 1))}>
          {data?.resData?.data?.unit_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
      </Breadcrumb>
      <ModalKonfirmasiInpeksi
        isOpen={modalKonfirmasi.show}
        onSubmit={onSubmit}
        onCancel={() => setModalKonfirmasi({ ...modalKonfirmasi, show: false })}
      />
      {step === 1 && (
        <LakukanInspeksiComponentStep1
          data={dataParams}
          dataOwner={dataOwner?.data?.resData?.data}
        />
      )}
      {step === 2 && (
        <LakukanInspeksiComponentStep2
          data={dataParams}
          dataOwner={dataOwner?.data?.resData?.data}
        />
      )}
      {step === 3 && (
        <LakukanInspeksiComponentStep3
          onSubmit={(inspeksiData) =>
            setModalKonfirmasi({
              ...modalKonfirmasi,
              show: true,
              data: inspeksiData
            })
          }
          dataParams={dataParams}
          dataOwner={dataOwner?.data?.resData?.data}
        />
      )}
    </Layout>
  )
}

export default LakukanInspeksi
