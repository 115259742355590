import React from 'react'
import { IcCheck, IcTrash, IcTruck, IcUser, IcWhatsApp } from 'assets/icons'
import { Popover } from 'antd'

const ModalLoadMorePickupService = ({
  children,
  statusPickup,
  onPressPickupSelesai,
  onPressPickupStart,
  onPressDetailPickup,
  onPressWhatsApp,
  onPressDeleteService,
  onPressMekanik
}) => {
  const onReturnStatus = () => {
    switch (statusPickup) {
      case 'PICKUP_IN_PROGRESS':
      case 'Sedang Dijemput':
        return (
          <>
            <div
              onClick={() => onPressPickupSelesai()}
              className="flex flex-row items-center space-x-2 hover:cursor-pointer">
              <IcCheck className="text-[#12B76A]" />
              <span className="font-medium text-[#12B76A]">Pickup Selesai</span>
            </div>
            <div
              onClick={() => onPressMekanik()}
              className="flex flex-row items-center space-x-2 hover:cursor-pointer">
              <IcUser className="text-gray-500" />
              <span className="font-medium text-gray-500">Konfirmasi Mekanik</span>
            </div>
          </>
        )

      case 'PICKUP_CONFIRMED':
      case 'Pickup Terjadwal':
        return (
          <div
            onClick={() => onPressPickupStart()}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcCheck className="text-[#12B76A]" />
            <span className="font-medium text-[#12B76A]">Mulai Penjemputan</span>
          </div>
        )
      default:
        break
    }
  }

  const onReturnContent = () => (
    <div className="grid grid-flow-row gap-5">
      {onReturnStatus()}
      <div
        onClick={() => onPressDetailPickup()}
        className="flex flex-row items-center space-x-2 hover:cursor-pointer">
        <IcTruck className="text-gray-500" />
        <span className="font-medium text-gray-500">Detail Pickup</span>
      </div>
      <div
        onClick={() => onPressWhatsApp()}
        className="flex flex-row items-center space-x-2 hover:cursor-pointer">
        <IcWhatsApp className="text-gray-500" />
        <span className="font-medium text-gray-500">Whatsapp</span>
      </div>
      {statusPickup === 'PICKUP_IN_PROGRESS' || statusPickup === 'PICKUP_CONFIRMED' ? (
        <div
          onClick={() => onPressDeleteService()}
          className="flex flex-row items-center space-x-2 hover:cursor-pointer">
          <IcTrash className="text-[#F04438]" />
          <span className="font-medium text-[#F04438]">Delete Pickup Servis</span>
        </div>
      ) : null}
    </div>
  )

  return (
    <Popover
      overlayStyle={{
        backgroundColor: 'transparent'
      }}
      overlayInnerStyle={{
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        // minHeight: '8rem',
        minWidth: '15rem'
      }}
      zIndex={999}
      trigger="click"
      placement="leftTop"
      content={onReturnContent()}>
      {children}
    </Popover>
  )
}

export default ModalLoadMorePickupService
