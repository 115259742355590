import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Tag, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { formatCurrency } from 'utils'
import { formatDate } from 'helpers'

const DetailServisComponent = ({ data }) => {
  const router = useHistory()
  const unitOwner = router?.location?.state?.unitOwner

  const fieldFontStyle = 'font-normal text-gray-700'
  const valueFontStyle = 'font-normal text-gray-700'

  const listService = useMemo(() => {
    const generalService =
      data?.general_services
        ?.filter((f) => f?.status !== 'WAITING_APPROVAL' && f?.status !== 'REJECTED')
        ?.map((item) => ({
          serviceName: item?.general_service_name,
          products: [
            {
              itemName: item?.sparepart_name,
              totalItems: item?.quantity,
              totalPrice: formatCurrency(item?.price_total ?? 0)
            }
          ]
        })) ?? []
    const paketService =
      data?.packets?.map((item) => ({
        serviceName: item?.packet_name,
        products:
          item?.spareparts
            ?.filter((f) => f?.status !== 'WAITING_APPROVAL' && f?.status !== 'REJECTED')
            ?.map((sparepart) => ({
              itemName: `${sparepart?.general_service_name} ${
                sparepart?.sparepart_name ? `- ${sparepart?.sparepart_name}` : ''
              }`,
              totalItems: sparepart?.quantity,
              totalPrice: formatCurrency(sparepart?.price_total ?? 0)
            })) ?? []
      })) ?? []

    return [...generalService, ...paketService]
  }, [data])

  return (
    <Content className="h-full">
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Servis</Typography.Text>
        </div>
        <div className="flex">
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Tempat</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {data?.workshop_name}
            </Typography.Text>
            <Typography.Text className="text-gray-900 font-medium text-md mt-2">
              Mekanik
            </Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {data?.mechanic_name}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Tanggal</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {formatDate(data?.service_date)}
            </Typography.Text>
            <Typography.Text className="text-gray-900 font-medium text-md mt-2">
              Status
            </Typography.Text>
            <Tag className="w-fit rounded-lg text-[#027948] bg-[#ECFDF3]">Selesai</Tag>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Waktu</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">{`${data?.service_start_time?.substr(
              0,
              5
            )} - ${data?.service_end_time?.substr(0, 5)}`}</Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Stall</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">{data?.stall_no}</Typography.Text>
          </Typography>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Pemilik</Typography.Text>
        </div>
        <div className="flex">
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Nama</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">{unitOwner?.name}</Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Email</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">{unitOwner?.email}</Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">
              Nomor Telepon
            </Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {unitOwner?.phone_number}
            </Typography.Text>
          </Typography>
          <Typography className="flex-1 flex flex-col">
            <Typography.Text className="text-gray-900 font-medium text-md">Alamat</Typography.Text>
            <Typography.Text className="text-gray-700 text-sm">
              {`${unitOwner?.address} ${unitOwner?.subDistrict?.name} ${unitOwner?.city?.name} ${unitOwner?.province?.name} ${unitOwner?.postal_code}`}
            </Typography.Text>
          </Typography>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Informasi Unit</Typography.Text>
        </div>
        <div className="flex">
          <div className="flex-1 pr-4 ml-2">
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Merek
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.brand?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Model
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.model?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Tipe
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.type?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Transmisi
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.transmission?.name}
              </Typography.Text>
            </Typography>
          </div>
          <div className="flex-1 border-0 border-x pl-4 pr-4 border-solid border-gray-300 mr-2">
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Bahan Bakar
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.fuel?.name}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Plat Nomor
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.police_number}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Tahun
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.production_year}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Warna
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.color?.name}
              </Typography.Text>
            </Typography>
          </div>
          <div className="flex-1 ml-2">
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Nomor Rangka
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.frame_number}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Nomor Mesin
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.engine_number}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Jarak Tempuh
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">
                {data?.unit_information?.mileage}
              </Typography.Text>
            </Typography>
            <Typography className="flex items-center mb-2">
              <Typography.Text className="text-gray-900 font-medium text-sm flex-1">
                Status
              </Typography.Text>
              <Typography.Text className="text-gray-700 text-sm flex-1">Selesai</Typography.Text>
            </Typography>
          </div>
        </div>
      </Card>
      <Card className="rounded-lg mb-4">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Catatan</Typography.Text>
        </div>
        <Typography>
          <Typography.Paragraph>{data?.notes}</Typography.Paragraph>
        </Typography>
      </Card>

      <Card>
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">Payment Receipt</Typography.Text>
        </div>
        <div className="flex flex-col w-full pt-4 space-y-5">
          <div className="flex flex-col space-y-5 border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
            {/* Service & Product */}
            {listService ? (
              <div className="grid grid-cols-1 gap-5">
                {listService.map((data, index) => (
                  <div key={index} className="flex flex-col gap-1">
                    <span className="text-md-medium text-gray-900">{data.serviceName}</span>
                    {/* Mapping Products each Services */}
                    {data.products.map(({ itemName, totalItems, totalPrice }, index) => (
                      <div key={index} className="grid grid-cols-5">
                        <span className="col-span-2 text-sm-regular text-gray-700">{itemName}</span>
                        <span className="col-span-1 text-right text-sm-regular text-gray-700">
                          {totalItems ? `${totalItems}x` : ''}
                        </span>
                        <span className="col-span-2 text-right text-sm-regular text-gray-700">
                          {totalPrice}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : null}
            {data?.tax !== 0 && data?.tax !== '' && (
              <div className="flex flex-row items-start justify-between">
                <span className={fieldFontStyle}>Pajak</span>
                <span className={valueFontStyle}>{formatCurrency(data?.tax ?? 0)}</span>
              </div>
            )}
            <div className="flex flex-row items-start justify-between">
              <span className={fieldFontStyle}>Biaya Admin</span>
              <span className={valueFontStyle}>{formatCurrency(data?.admin_fee ?? 0)}</span>
            </div>
            {data?.class_price_total && (
              <div className="flex flex-row items-start justify-between">
                <span className={fieldFontStyle}>Harga Layanan</span>
                <span className={valueFontStyle}>
                  {formatCurrency(data?.class_price_total ?? 0)}
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-5 border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
            <div className="flex flex-row items-start justify-between">
              <span className={fieldFontStyle}>Total</span>
              <span className={valueFontStyle}>
                {formatCurrency(
                  data?.item_total +
                    (data?.admin_fee ?? 0) +
                    (data?.tax ?? 0) +
                    (data?.class_price_total ?? 0)
                )}
              </span>
            </div>
            {data?.voucher_discount_total ? (
              <div className="flex flex-row items-start justify-between">
                <span className={fieldFontStyle}>Diskon Voucher</span>
                <span className={valueFontStyle}>{`- ${formatCurrency(
                  data?.voucher_discount_total ?? 0
                )}`}</span>
              </div>
            ) : null}
            {data?.company_discount_total ? (
              <div className="flex flex-row items-start justify-between">
                <span className={fieldFontStyle}>Diskon Perusahaan</span>
                <span className={valueFontStyle}>{`- ${formatCurrency(
                  data?.company_discount_total ?? 0
                )}`}</span>
              </div>
            ) : null}
          </div>
          <div className="flex flex-row items-start justify-between">
            <span className={fieldFontStyle}>Total Pembayaran</span>
            <span className="font-medium text-gray-900">
              {formatCurrency(data?.price_total ?? 0)}
            </span>
          </div>
        </div>
      </Card>
    </Content>
  )
}

export default DetailServisComponent
