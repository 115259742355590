import React from 'react'
import { IcBox } from 'assets/icons'
import { Checkbox } from 'antd'

const KategoriItem = ({ isSelected, title, onSelected }) => {
  const onReturnColorSelected = () => {
    switch (isSelected) {
      case true:
        return 'border-primary-200 bg-primary-50'
      default:
        return 'bg-white border-gray-200'
    }
  }

  return (
    <div
      onClick={onSelected}
      className={`flex flex-row w-full justify-between items-center hover:cursor-pointer ${onReturnColorSelected()} rounded-lg border border-solid p-3`}>
      <div className="flex flex-row items-center space-x-2">
        <div className="flex w-14 h-14 rounded-full items-center justify-center bg-primary-100 border-8 border-solid border-primary-50">
          <IcBox className="text-primary-600" />
        </div>
        <span className="font-medium text-md text-gray-700">{title}</span>
      </div>
      <Checkbox className="custom-checkbox" checked={isSelected} />
    </div>
  )
}

export default KategoriItem
