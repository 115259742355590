import React, { useRef, useState } from 'react'
import { Input, Modal, Select, Tag, Typography } from 'antd'
import { validationInput } from 'helpers'
import UploadImage from 'components/UploadImage'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components'
import { logout } from 'store/actions'
import ModalSelectUnitCYOC from './ModalSelectUnitCYOC'
import { formatThousandsINTL, onlyNumbers, unformatCurrency } from 'utils'

const initialForms = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  category: {
    value: null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  description: '',
  units: [],
  harga: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  },
  image: null
}

const ModalUpdateCYOCSparepart = ({ type, isOpen, onCancel, data, onSubmit, onDelete }) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [oldLogo, setOldLogo] = useState()
  const [forms, setForms] = useState(initialForms)
  const [searchCategory, setSearchCategory] = useState('')
  const [modal, setModal] = useState(false)

  const logoRef = useRef()

  const onChangeInput = (value, identifier) => {
    if (forms[identifier]?.validation) {
      let { valid, messageError } = validationInput(value, forms[identifier]?.validation)
      setForms((prevState) => {
        return {
          ...prevState,
          [identifier]: {
            ...prevState[identifier],
            touched: true,
            value: value,
            valid: valid,
            messageError: messageError
          }
        }
      })
    } else {
      setForms((prevState) => {
        return {
          ...prevState,
          [identifier]: value
        }
      })
    }
  }

  const sparepart = useQuery({
    queryKey: ['cyoc-sparepart'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (type === 'edit') {
        const res = await apiGet({
          token,
          url: `jual-beli-service/admin/create-your-own-car/sparepart/${data?.id}`,
          onLogout: () => dispatch(logout())
        })
        let resData = res?.resData?.data
        onChangeInput(resData?.name, 'name')
        onChangeInput(resData?.category?.id, 'category')
        onChangeInput(data?.price, 'harga')
        onChangeInput(resData?.description, 'description')
        onChangeInput(resData?.unit_list, 'units')
        setSearchCategory(resData?.category?.name)
        setOldLogo(`${process.env.REACT_APP_IMAGE_URL_GCS}/${resData?.image_url}`)
        setModal(false)
      } else {
        setForms(initialForms)
        setOldLogo()
      }
    },
    enabled: isOpen
  })

  const categoryList = useQuery({
    queryKey: ['cyoc-category-list', searchCategory],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: searchCategory,
        offset: 0,
        limit: 10
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'jual-beli-service/admin/create-your-own-car/category',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    enabled: isOpen && !sparepart.isLoading
  })

  const onOk = () => {
    let units = forms.units.map((unit) => unit.id)
    if (type === 'add') {
      onSubmit({
        name: forms.name.value,
        category_id: forms.category.value,
        description: forms.description,
        price: forms.harga.value,
        unit_id: units,
        image: forms.image
      })
    } else {
      if (forms.image) {
        onSubmit({
          id: data?.id,
          name: forms.name.value,
          category_id: forms.category.value,
          description: forms.description,
          price: forms.harga.value,
          unit_id: units,
          image: forms.image
        })
      } else {
        onSubmit({
          id: data?.id,
          name: forms.name.value,
          category_id: forms.category.value,
          description: forms.description,
          price: forms.harga.value,
          unit_id: units
        })
      }
    }
  }

  if (type === 'delete') {
    return (
      <Modal
        centered
        title="Hapus Sparepart"
        open={isOpen}
        onOk={() => onDelete(data)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
        maskClosable={false}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus Sparepart ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      centered
      title={type === 'add' ? 'Tambah Sparepart' : 'Edit Sparepart'}
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      width={450}
      okText={type === 'add' ? 'Tambah Sparepart' : 'Edit Sparepart'}
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
      maskClosable={false}>
      <ModalSelectUnitCYOC
        isOpen={modal}
        data={forms.units}
        onSubmit={(data) => {
          onChangeInput(data, 'units')
          setModal(false)
        }}
        onCancel={() => setModal(false)}
      />
      <div className="flex items-center mb-2">
        <UploadImage
          customRef={logoRef}
          containerClassName={'mr-4'}
          onChangeFile={(file) => onChangeInput(file, 'image')}
          file={forms.image}
          oldFile={oldLogo}
        />
        <div className="flex-1 flex-col">
          <div className="flex flex-row space-x-0.5 items-start justify-start">
            <Button
              className={'bg-white py-1 mb-1 border-gray-500 text-gray-700 hover:bg-gray-50'}
              onClick={logoRef.current ? () => logoRef.current.click() : () => {}}>
              Upload Gambar Sparepart<span className='text-[red]'>*</span>
            </Button>
            {/* <span className="text-[red] text-lg">*</span> */}
          </div>
          <div className="text-gray-500 text-sm">
            Harus setidaknya 400x500 px dan lebih kecil dari 1mb
          </div>
        </div>
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nama Sparepart
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !forms.name.valid && forms.name.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Nama Sparepart"
          value={forms.name.value}
          onChange={(e) => onChangeInput(e.target.value, 'name')}
        />
        {!forms.name.valid && forms.name.touched && (
          <span className="text-[red]">{forms.name.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Kategori
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Select
          showSearch
          className="custom-table-limit mr-3"
          placeholder="Pilih Kategori"
          style={{ width: '100%' }}
          value={forms.category.value}
          onSearch={(e) => setSearchCategory(e)}
          onChange={(e) => onChangeInput(e, 'category')}
          filterOption={(input, option) => {
            return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }}>
          {categoryList?.data?.resData?.data?.map((item) => (
            <Select.Option key={item?.id?.toString()} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
        {!forms.category.valid && forms.category.touched && (
          <span className="text-[red]">{forms.category.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Deskripsi
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input.TextArea
          className={`rounded`}
          placeholder="Masukan Deskripsi Sparepart"
          value={forms.description}
          onChange={(e) => onChangeInput(e.target.value, 'description')}
        />
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Unit
          <span className="text-[red]">*</span>
        </Typography.Text>
        <div className="mt-1">
          {forms.units.length > 0 && (
            <div className="flex flex-wrap mt-2">
              {forms.units.map((item) => (
                <Tag
                  key={`unit-${item?.id.toString()}`}
                  className="py-[2px] border-0 bg-[#FEECE2] rounded-lg text-[#A21226] mb-2 selection:text-black">
                  {`${item?.brand_name} ${item?.model_name} ${item?.type_name}`}
                </Tag>
              ))}
            </div>
          )}
          <Button className="border-gray-200 bg-white py-1 px-3" onClick={() => setModal(true)}>
            Pilih Unit
          </Button>
        </div>
        {/* {!name.valid && name.touched && <span className="text-[red]">{name.messageError}</span>} */}
      </div>
      <div>
        <Typography.Text className="font-medium">
          Harga
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !forms.harga.valid && forms.harga.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Harga Sparepart"
          value={forms.harga.value === '' ? '' : formatThousandsINTL(forms.harga.value)}
          onChange={(e) => {
            if (onlyNumbers(unformatCurrency(e.target.value))) {
              if (e.target.value !== '') {
                onChangeInput(unformatCurrency(e.target.value), 'harga')
              } else {
                onChangeInput(e.target.value, 'harga')
              }
            }
            if (e.target.value === '') {
              onChangeInput(e.target.value, 'harga')
            }
          }}
          prefix="Rp. "
        />
        {!forms.harga.valid && forms.harga.touched && (
          <span className="text-[red]">{forms.harga.messageError}</span>
        )}
      </div>
    </Modal>
  )
}

export default ModalUpdateCYOCSparepart
