import React from 'react'
import { Breadcrumb, Layout, Typography } from 'antd'
import { IcEye } from 'assets/icons'
import { Button } from 'components'
import PenjualanComponent from './components/PenjualanComponent'
import { formatCurrency } from 'utils'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const Penjualan = () => {
  const { push } = useHistory()

  const columnStyle = 'font-medium text-gray-700'

  const columns = [
    {
      name: 'Kode',
      selector: (row) => row?.transaction_code,
      cell: (row) => <span className={columnStyle}>{row?.transaction_code}</span>,
      grow: 1
    },
    {
      name: 'Tipe',
      selector: (row) => row?.transaction_type,
      cell: (row) => <span className={columnStyle}>{row?.transaction_type}</span>,
      grow: 1
    },
    {
      name: 'Cabang',
      selector: (row) => row?.workshop_name,
      cell: (row) => (
        <span className={columnStyle}>
          {row?.workshop_name && row?.workshop_name !== ''
            ? row?.workshop_name
            : '-'}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-2 py-3">
          <span className={columnStyle}>{row?.unit_name}</span>
          <span className="font-normal text-gray-500 tracking-tight">
            {row?.unit_police_number && row?.unit_police_number !== ''
              ? row?.unit_police_number
              : '-'}
          </span>
        </div>
      ),
      grow: 3
    },
    {
      name: 'Tanggal',
      selector: (row) => row?.created_at,
      cell: (row) => (
        <span className={columnStyle}>{row?.done_at ? moment(row?.done_at).format('DD/MM/YYYY') : '-'}</span>
        // <span className={columnStyle}>{moment(row?.created_at).format('DD/MM/YYYY')}</span>
      ),
      grow: 2
    },
    {
      name: 'Sales',
      selector: (row) => row?.transaction_profit,
      cell: (row) => (
        <span className={columnStyle}>{formatCurrency(row?.transaction_profit ?? '0')}</span>
      ),
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 hover:bg-gray-50 flex items-center"
            onClick={() => {
              row?.transaction_type?.toLowerCase() === 'beli'
                ? push(
                    `/analytics-dashboard/penjualan/detail-transaksi/${row?.id}/${
                      row?.id
                    }/${row?.transaction_type?.toLowerCase()}`
                  )
                : push(
                    `/analytics-dashboard/penjualan/detail-transaksi/${row?.transaction_id}/${
                      row?.unit_id
                    }/${row?.transaction_type?.toLowerCase()}`
                  )
            }}>
            <IcEye className="text-gray-700 mr-1" size={18} />
            <span className="text-center">Lihat</span>
          </Button>
        </div>
      ),
      grow: 1
    }
  ]

  const columnsBrand = [
    {
      name: 'Brand',
      selector: (row) => row.brand_name,
      cell: (row) => row?.brand_name
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity,
      cell: (row) => row?.quantity
    },
    {
      name: 'Sales',
      selector: (row) => row.total_profit,
      cell: (row) => formatCurrency(row?.total_profit ?? '0')
    }
  ]

  const columnsBranch = [
    {
      name: 'Branch',
      selector: (row) => row.workshop_name,
      cell: (row) => row?.workshop_name
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity,
      cell: (row) => row?.quantity
    },
    {
      name: 'Sales',
      selector: (row) => row.total_profit,
      cell: (row) => formatCurrency(row?.total_profit ?? '0')
    }
  ]
  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Penjualan
        </Breadcrumb.Item>
      </Breadcrumb>
      <PenjualanComponent
        columns={columns}
        columnsBranch={columnsBranch}
        columnsBrand={columnsBrand}
      />
    </Layout>
  )
}

export default Penjualan
