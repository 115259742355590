import React from 'react'
import { CardContainer } from 'components'

const InformasiSpekUnitHorizontalCard = ({ data }) => {
  const fieldStyle = 'font-medium text-gray-900 text-md'
  const valueStyle = 'font-normal text-gray-700 text-md'

  return (
    <CardContainer minHeight="20%" className={'grid grid-cols-1 gap-4'}>
      <div className="flex w-full pb-4 border border-solid border-b-1 border-x-0 border-t-0 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">Informasi Unit</span>
      </div>
      <div className="grid grid-rows-4 grid-flow-col w-full gap-3">
        {Object.entries(data)
          .filter((item) => item[0] !== 'id' && item[0] !== 'owner' && item[0] !== 'riwayatService')
          .map((item, index) => (
            <div key={index} className="grid grid-cols-2 w-full items-center justify-between">
              <span className={fieldStyle}>
                {`${item[0].substring(0, 1).toUpperCase()}${item[0]
                  .substring(1, item[0].length)
                  .replace(/([A-Z])/g, ' $1')
                  .trim()}`}
              </span>
              <span className={valueStyle}>{item[1]}</span>
            </div>
          ))}
      </div>
    </CardContainer>
  )
}

export default InformasiSpekUnitHorizontalCard
