import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { useHistory } from 'react-router-dom'
import UpdateKategoriInspeksiComponent from './components/UpdateKategoriInspeksiComponent'
import { apiPost, apiPut } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { Loading } from 'components'
import { showErrorMessage, showSuccessMessage, showWarningMessage } from 'utils'
import _ from 'lodash'

const UpdateKategoriInspeksi = () => {
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const type = router?.location?.state?.type ?? 'add'
  const data = router?.location?.state?.data ?? null
  const dispatch = useDispatch()
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  const onCheckFieldEmpty = (data) => {
    return Object.entries(data).every((item) => {
      if (item[1] !== undefined && item[1] !== '' && item[1] !== null && item[1].length !== 0) {
        return true
      } else {
        if (item[0] === 'name') {
          showWarningMessage({ content: `Mohon isi field nama kategori` })
        } else if (item[0] === 'sub_categories') {
          showWarningMessage({ content: `Mohon isi field sub-kategori & klik Enter` })
        } else {
          showWarningMessage({ content: `Mohon isi field ${_.startCase(item[0])}` })
        }
        return false
      }
    })
  }

  const saveData = (data) => {
    if (onCheckFieldEmpty(data)) {
      try {
        let res
        let body = {
          name: data?.name,
          sub_categories: data?.sub_categories
        }
        const mount = setTimeout(async () => {
          if (data?.id) {
            const id = data?.id
            res = await apiPut({
              url: `inspeksi-service/admin/inspection-categories/${id}`,
              data: body,
              token,
              onLogout: () => dispatch(logout())
            })
          } else {
            let subCategories = data?.sub_categories?.map((item) => item.sub_category_names)
            body = {
              name: body.name,
              sub_categories_name: subCategories
            }
            setIsLoadingHelper(true)
            res = await apiPost({
              url: `inspeksi-service/admin/inspection-categories`,
              data: body,
              token,
              onLogout: () => dispatch(logout())
            })
          }
          setIsLoadingHelper(false)
          if (res?.resStatus === 200 || res?.resStatus === 201) {
            if (data?.id) {
              showSuccessMessage({ content: 'Berhasil mengupdate kategori inspeksi' })
            } else {
              showSuccessMessage({ content: 'Berhasil membuat kategori inspeksi' })
            }
            router.goBack()
          } else {
            showErrorMessage({
              content: `${
                res.resData.message ??
                res.resData.errors[0].message ??
                (data?.id
                  ? 'Gagal mengupdate kategori inspeksi'
                  : 'Gagal membuat kategori inspeksi')
              }`
            })
          }
        })
        return () => clearTimeout(mount)
      } catch (error) {
        setIsLoadingHelper(false)
        console.log(error)
      }
    }
  }

  if (isLoadingHelper) {
    return <Loading visible={isLoadingHelper} />
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Master Data</Breadcrumb.Item>
        <Breadcrumb.Item>Inspeksi</Breadcrumb.Item>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => router.go(-1)}>
          Kategori Inspeksi
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Kategori</Breadcrumb.Item>
      </Breadcrumb>
      <UpdateKategoriInspeksiComponent data={data} type={type} onSubmit={saveData} />
    </Layout>
  )
}

export default UpdateKategoriInspeksi
