import React from 'react'
import { BadgeItem, CardContainer } from 'components'
import { formatCurrency } from 'utils'
import { capitalize } from 'lodash'
const badgeTypeEachStatus = {
  dijual: 'bluelight',
  diinspeksi: 'success',
  terjual: 'success',
  'di garasi': 'plain',
  'sedang diservis': 'primary',
  'sedang diinspeksi': 'warning'
}
const InformasiUnitCard = ({ unit, status, harga, alamat = null, isSelling, tanggal, tanggalPelunasan }) => {
  const fieldStyle = 'font-medium text-lg text-gray-900'
  const valueStyle = 'font-normal text-gray-700'

  return (
    <CardContainer minHeight="20%">
      <div className={`grid ${isSelling ? 'grid-cols-5' : 'grid-cols-4'} gap-4 w-full items-start`}>
        <div className="flex flex-col h-full">
          <span className={fieldStyle}>Unit</span>
          <span className={valueStyle}>{unit}</span>
        </div>
        <div className="flex flex-col h-full">
          <span className={fieldStyle}>Status</span>
          <div className="flex justify-start items-center">
            <BadgeItem type={badgeTypeEachStatus[status.toLowerCase()]}>
              {capitalize(status)}
            </BadgeItem>
          </div>
        </div>
        {
          isSelling ? (
            <>
              <div className="flex flex-col h-full">
                <span className={fieldStyle}>Tanggal Booking</span>
                <span className={valueStyle}>{tanggal ?? '-'}</span>
              </div>
              <div className="flex flex-col h-full">
                <span className={fieldStyle}>Tanggal Pelunasan</span>
                <span className={valueStyle}>{tanggalPelunasan ?? '-'}</span>
              </div>
            </>
          ) : null
        }
        <div className="flex flex-col h-full">
          <span className={fieldStyle}>Harga</span>
          <span className={valueStyle}>{harga === '' ? '-' : formatCurrency(harga)}</span>
        </div>
        {alamat !== null ? (
          <div className="flex flex-col h-full">
            <span className={fieldStyle}>Alamat Workshop</span>
            <span className={valueStyle}>{alamat}</span>
          </div>
        ) : null}
      </div>
    </CardContainer>
  )
}

export default InformasiUnitCard
