import React from 'react'

const IcFlag = ({ className, size = 20 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.3335 12.5C3.3335 12.5 4.16683 11.6667 6.66683 11.6667C9.16683 11.6667 10.8335 13.3334 13.3335 13.3334C15.8335 13.3334 16.6668 12.5 16.6668 12.5V2.50002C16.6668 2.50002 15.8335 3.33335 13.3335 3.33335C10.8335 3.33335 9.16683 1.66669 6.66683 1.66669C4.16683 1.66669 3.3335 2.50002 3.3335 2.50002V12.5ZM3.3335 12.5V18.3334"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IcFlag
