import { Breadcrumb, Button, Card, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { Fragment, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import NotificationEmpty from './components/notification-empty'
import NotificationItem from './components/notification-item'
import { Loading } from 'components'
import { logout } from 'store/actions'

const NotificationPage = () => {
  const token = useSelector((state) => state.auth.token)
  const [limit, setLimit] = useState(10)
  const [notifications, setNotifications] = useState({
    data: [],
    total: 0
  })
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  // Get All Notification
  const { isLoading } = useQuery({
    queryKey: ['notification-list', limit],
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.auth.user.prefix}/${urls?.auth.user.notificationList}?limit=${limit}`,
        onLogout: onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setNotifications((prev) => ({
        ...prev,
        data: res?.resData?.data ?? prev?.data,
        total: res?.resData?.meta?.total ?? prev.total
      }))
    }
  })

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Notifikasi</Breadcrumb.Item>
      </Breadcrumb>
      <Content className="min-h-screen">
        <Card className="rounded-lg">
          <div className="space-y-3">
            {notifications?.data?.length > 0 ? (
              notifications?.data?.map((item, idx) => (
                <Fragment key={idx}>
                  <NotificationItem data={item} token={token} />
                  {idx !== notifications?.data?.length - 1 && (
                    <hr className="bg-gray-300 h-[1px]" />
                  )}
                </Fragment>
              ))
            ) : isLoading ? (
              <Loading visible />
            ) : (
              <NotificationEmpty />
            )}
          </div>
          {notifications?.data?.length >= notifications?.total ? null : (
            <Button
              loading={isLoading}
              type="link"
              danger
              className="!text-primary-600 mt-5 bg-none hover:bg-none"
              onClick={() => setLimit((prev) => prev + 10)}>
              Show More
            </Button>
          )}
        </Card>
      </Content>
    </Layout>
  )
}

export default NotificationPage
