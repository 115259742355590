import React from 'react'
import { Popover } from 'antd'
import { IcCheck, IcEye, IcInvoice, IcTrash } from 'assets/icons'

const ModalLoadMoreBills = ({
  children,
  onPressConfirmastionBills,
  onPressPaymentProof,
  onPressInvoiceBills,
  onPressDeleteBills,
  status,
  payment_status,
  proof_of_payment = null
}) => {
  const onReturnContent = () => {
    if (payment_status.toLowerCase() === 'paid') {
      if (proof_of_payment) {
        return (
          <div
            onClick={() => onPressPaymentProof()}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcEye className="text-gray-500" size={18} />
            <span className="font-medium text-gray-500">Lihat Bukti Pembayaran</span>
          </div>
        )
      } else {
        return (
          <div
            onClick={() => {}}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcEye className="text-gray-300" size={18} />
            <span className="font-medium text-gray-300">Lihat Bukti Pembayaran</span>
          </div>
        )
      }
    } else {
      if (status.toLowerCase() === 'waiting_payment') {
        return (
          <div
            onClick={() => onPressConfirmastionBills()}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcCheck className="text-success-500" size={18} />
            <span className="font-medium text-success-500">Konfirmasi Pembayaran</span>
          </div>
        )
      } else {
        return (
          <div
            onClick={() => {}}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcCheck className="text-success-200" size={18} />
            <span className="font-medium text-success-200">Konfirmasi Pembayaran</span>
          </div>
        )
      }
    }
  }

  return (
    <Popover
      overlayStyle={{
        backgroundColor: 'transparent'
      }}
      overlayInnerStyle={{
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        minHeight: '4rem',
        minWidth: '14rem'
      }}
      zIndex={999}
      trigger="click"
      placement="leftTop"
      content={
        <div className="grid grid-flow-row items-center gap-4">
          {status && payment_status ? onReturnContent() : null}
          <div
            onClick={() => onPressInvoiceBills()}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcInvoice className="text-gray-500" size={16} />
            <span className="font-medium text-gray-500">Invoice</span>
          </div>
          <div
            onClick={() => onPressDeleteBills()}
            className="flex flex-row items-center space-x-2 hover:cursor-pointer">
            <IcTrash className="text-danger-500" size={18} />
            <span className="font-medium text-danger-500">Delete Tagihan</span>
          </div>
        </div>
      }>
      {children}
    </Popover>
  )
}

export default ModalLoadMoreBills
