import React, { useRef } from 'react'
import { PhotoPicker } from 'components'
import { imageCompressor } from 'utils'

const PhotoThumbnailCard = ({
  photoThumbnail,
  onChangePhotoThumbnail,
  isRequired = false,
  title,
  notes,
  width,
  height,
  photoDisplay,
  isBorderDashed
}) => {
  const photoThumbnailRef = useRef(null)

  const handleOnClickThumbnail = (e) => {
    photoThumbnailRef.current.click()
  }

  const handleOnChangeThumbnail = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      const limitSize = 10
      const checkType = file.type.substring(file.type.lastIndexOf('/') + 1)
      const size = file.size / 1024 / 1024
      if (
        (checkType === 'jpg' || checkType === 'jpeg' || checkType === 'png') &&
        size <= limitSize
      ) {
        const compressImage = await imageCompressor(file)
        if (compressImage.status === 200) {
          return onChangePhotoThumbnail({
            file: compressImage.res,
            blob: URL.createObjectURL(compressImage.res)
          })
        }
      }
    }
  }

  return (
    <PhotoPicker
      w={width}
      h={height}
      objectPhotoDisplay={photoDisplay}
      isBorderDashed={isBorderDashed}
      isTitle
      isRequired={isRequired}
      isNotes
      ref={photoThumbnailRef}
      photo={photoThumbnail}
      name="thumbnail"
      title={`${title ?? 'Foto Thumbnail'}`}
      notes={`${
        notes ?? 'Ukuran gambar 16:9, ukuran file maksimal 10mb, dengan format file jpg, jpeg, png.'
      }`}
      handleonClick={(e) => handleOnClickThumbnail(e)}
      handleOnChange={(e) => handleOnChangeThumbnail(e)}
    />
  )
}

export default PhotoThumbnailCard
