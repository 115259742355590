import React, { useState } from 'react'
import { apiDelete, apiGet, apiPost, apiPut, apiResponse, urls } from 'api'
import { IcEdit, IcPlus, IcTrash } from 'assets/icons'
import {
  Button,
  CardContainer,
  FormInput,
  Loading,
  ModalCreateUpdateUser,
  ModalDeleteUser,
  Table
} from 'components'
import { metaInitialData, perPageInitialData } from 'constant'
import { Formik } from 'formik'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'
import { Typography } from 'antd'

const DataInspektorContent = () => {
  const { cabang: idWorkshop } = useParams()

  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [meta, setMeta] = useState(metaInitialData)
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)
  const [currentPage, setCurrentPage] = useState(0)
  const [inspectorList, setInspectorList] = useState([])

  const onLogout = () => dispatch(logout())

  const { isLoading, refetch } = useQuery({
    queryKey: ['workshop-inspector-list'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: `${urls.master.workshops.inspector.prefix}/${
          urls.master.workshops.inspector.listByWorkshop
        }/${idWorkshop}?limit=${perPageArr.find((item) => item.selected === true).value}&offset=${
          meta.offset ?? 0
        }`,
        token: user.token,
        onLogout
      }),
    onSuccess: (data) => {
      setInspectorList(data.resData.data)
      setMeta(data.resData.meta)
      setCurrentPage(0)
    }
  })

  const [modalUpdate, setModalUpdate] = useState({
    show: false,
    type: 'add',
    user: null
  })
  const [modalDelete, setModalDelete] = useState({
    show: false,
    user: null
  })
  const [inspectorSearch, setInspectorSearch] = useState('')
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)

  const onHandleExtraUrl = () => {
    let extraUrl = ''

    if (inspectorSearch !== '') {
      extraUrl += `&keywords=${inspectorSearch}`
    }

    return extraUrl
  }
  const onChangePrevNextPage = (offset) => {
    setIsLoadingHelper(true)
    setMeta({ ...meta, offset })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.master.workshops.inspector.prefix}/${
          urls.master.workshops.inspector.listByWorkshop
        }/${idWorkshop}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${offset}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setInspectorList(response.resData.data)
      } else {
        refetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    setIsLoadingHelper(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.master.workshops.inspector.prefix}/${
          urls.master.workshops.inspector.listByWorkshop
        }/${idWorkshop}?limit=${value}&offset=${meta.offset ?? 0}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setInspectorList(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        refetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const columns = [
    {
      name: 'ID',
      selector: (row) => row?.profile?.inspector_no,
      cell: (row) => (
        <Typography.Text
          ellipsis={{
            tooltip: row?.profile?.inspector_no
          }}>
          {row?.profile?.inspector_no}
        </Typography.Text>
      ),
      width: '10%'
    },
    {
      name: 'Nama',
      selector: (row) => row.fullname,
      cell: (row) => row?.fullname
    },
    {
      name: 'Nomor Telepon',
      selector: (row) => row.phone_number,
      cell: (row) => row?.phone_number
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      cell: (row) => row?.email
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => setModalUpdate({ show: true, type: 'edit', user: row })}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModalDelete({ show: true, user: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  if (isLoading ?? isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <div>
      <ModalCreateUpdateUser
        title="Inspektor"
        isOpen={modalUpdate.show}
        type={modalUpdate.type}
        user={modalUpdate.user}
        onCancel={() => setModalUpdate({ ...modalUpdate, show: false })}
        onSubmit={(values) => {
          setIsLoadingHelper(true)
          const mount = setTimeout(async () => {
            let res = apiResponse()
            if (modalUpdate.type === 'add') {
              res = await apiPost({
                url: `${urls.master.workshops.inspector.prefix}/${urls.master.workshops.inspector.root}`,
                token: user.token,
                data: {
                  email: values.email,
                  password: values.password,
                  fullname: values.fullname,
                  address: values.address,
                  phone_number: values.phone_number,
                  workshop_id: parseInt(idWorkshop)
                },
                onLogout
              })
            }
            if (modalUpdate.type === 'edit') {
              res = await apiPut({
                url: `${urls.master.workshops.inspector.prefix}/${urls.master.workshops.inspector.root}/${modalUpdate.user.id}`,
                token: user.token,
                data: {
                  email: values.email,
                  password: values.password,
                  fullname: values.fullname,
                  address: values.address,
                  phone_number: values.phone_number,
                  workshop_id: modalUpdate.user.profile.workshop_id
                },
                onLogout
              })
            }
            if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
              showSuccessMessage({
                content: `${
                  modalUpdate.type === 'add'
                    ? 'Sucessfully Create New Inspector.'
                    : 'Sucessfully Update An Inspector.'
                }`
              })
            } else {
              showErrorMessage({
                content: `${
                  res.resData.errors[0].message ??
                  `${
                    modalUpdate.type === 'add'
                      ? 'Failed to Create New Inspector.'
                      : 'Failed to Update An Inspector.'
                  }`
                }`
              })
            }
            refetch()
            setIsLoadingHelper(false)
            setModalUpdate({ ...modalUpdate, show: false })
          })
          return () => clearTimeout(mount)
        }}
      />
      <ModalDeleteUser
        title="Inspektor"
        isOpen={modalDelete.show}
        type={modalDelete.type}
        user={modalDelete.user}
        onCancel={() => setModalDelete({ ...modalDelete, show: false })}
        onSubmit={() => {
          setIsLoadingHelper(true)
          const mount = setTimeout(async () => {
            const res = await apiDelete({
              url: `${urls.auth.user.prefix}/${urls.auth.user.users}/${modalDelete.user.id}`,
              token: user.token,
              onLogout
            })
            if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
              showSuccessMessage({
                content: 'Successfully Delete Inspector.'
              })
            } else {
              showErrorMessage({
                content: `${res.resData.errors[0].message ?? 'Failed Deleting An Inspector.'}`
              })
            }
            refetch()
            setIsLoadingHelper(false)
            setModalDelete({ ...modalDelete, show: false })
          })
          return () => clearTimeout(mount)
        }}
      />
      {/* Table Inspektor List */}
      <CardContainer className={'grid grid-cols-1 gap-5 h-full'}>
        <div className="flex justify-between items-center">
          <span className="display-xs-semibold text-gray-900">Inspektor List</span>
          <button
            className="flex flex-row py-2 items-center justify-around rounded-md border-solid border-primary-500 focus:outline-none space-x-1 px-2 py text-md text-white bg-primary-500 hover:cursor-pointer"
            onClick={() => setModalUpdate({ type: 'add', show: true, user: null })}>
            <IcPlus className="text-white" size={19} />
            <span>Tambah Inspektor</span>
          </button>
        </div>
        <div>
          <div className="flex flex-row w-full items-center justify-between">
            <div className="w-1/4">
              <Formik
                initialValues={{
                  inspectorSearch: inspectorSearch
                }}>
                <FormInput
                  name="inspectorSearch"
                  placeholder="Pencarian"
                  type="text"
                  isIconLeft
                  iconName="search"
                  onKeyPress={(value) => {
                    setInspectorSearch(value)
                    let extraUrl = ''
                    if (value && value !== '') {
                      extraUrl += `&keywords=${value}`
                    }
                    setIsLoadingHelper(true)
                    const mount = setTimeout(async () => {
                      const res = await apiGet({
                        url: `${urls.master.workshops.inspector.prefix}/${
                          urls.master.workshops.inspector.listByWorkshop
                        }/${idWorkshop}?limit=${
                          perPageArr.find((item) => item.selected === true).value
                        }&offset=${meta.offset ?? 0}${extraUrl}`,
                        token: user.token,
                        onLogout
                      })
                      if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
                        setInspectorList(res.resData.data)
                        setMeta(res.resData.meta)
                      } else {
                        refetch()
                      }
                      setIsLoadingHelper(false)
                    })
                    return () => clearTimeout(mount)
                  }}
                />
              </Formik>
            </div>
          </div>
        </div>
        <Table
          currentPage={meta.offset ? meta.offset + 1 : 1}
          totalPerPage={
            perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
            meta.total
              ? meta.total
              : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
          }
          dropDownDataFilter={perPageArr}
          lengthAllData={meta.total}
          columns={columns}
          data={inspectorList}
          onChangePrevPage={() => {
            if (currentPage > 0) {
              setCurrentPage(currentPage - 1)
              onChangePrevNextPage(
                (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
              )
            }
          }}
          onChangeNextPage={() => {
            if (
              perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
              meta.total
            ) {
              setCurrentPage(currentPage + 1)
              onChangePrevNextPage(
                (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
              )
            }
          }}
          onChangeDropDown={(value) => onChangePerPage(value)}
        />
      </CardContainer>
    </div>
  )
}

export default DataInspektorContent
