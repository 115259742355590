import { Select } from 'antd'
import { apiPut, urls } from 'api'
import { CustomButton, Loading } from 'components'
import { useFormik } from 'formik'
import { errorsField, validationInput } from 'helpers'
import ModalLayout from 'layouts/modal-layout'
import 'moment/locale/en-gb'
import { useState } from 'react'
import { showErrorMessage } from 'utils'

const ModalKonfirmasiMekanik = ({
  data,
  onClose,
  token,
  refetch,
  dropDownMekanik,
  openModal,
  onLogout = () => {}
}) => {
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.mechanic_id, { isRequired: true })?.valid) {
      errors.mechanic_id = validationInput(values?.mechanic_id, {
        isRequired: true
      })?.messageError
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      mechanic_id: data?.mechanic_id ?? ''
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      let body = {
        ...values,
        mechanic_id: parseInt(values?.mechanic_id)
      }

      handleSubmit(body)
    }
  })

  // Submit
  const handleSubmit = async (body) => {
    try {
      setIsLoadingCRUD(true)
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.servicePickup.root}/${data?.id}/${urls.service.servicePickup.updateMechanic}`,
        data: body,
        token: token,
        onLogout
      })
      if (res?.resStatus === 200) {
        onClose()
        refetch()
        formik.handleReset()
      } else {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        formik.setErrors(errorsField(res?.resData?.errors))
      }
      setIsLoadingCRUD(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ModalLayout
        className={'w-full max-w-sm'}
        headerTitle={'Konfirmasi Mekanik'}
        openModal={openModal}
        onCancel={onClose}
        loading={isLoadingCRUD}>
        <div className="flex flex-col w-full space-y-5">
          <div className="flex flex-col">
            <label htmlFor="mekanik" className="font-medium block mb-2 text-md text-gray-700">
              Mekanik
            </label>
            <Select
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 w-full"
              value={values?.mechanic_id === '' ? null : values?.mechanic_id}
              onChange={(e) => formik.setFieldValue('mechanic_id', e)}
              placeholder="Pilih Mekanik">
              {dropDownMekanik?.map((mekanik, idx) => (
                <Select.Option key={idx} value={mekanik?.id}>
                  {mekanik?.fullname}
                </Select.Option>
              ))}
            </Select>
            {touched?.mechanic_id && errors?.mechanic_id && (
              <span className="text-[red]">{errors?.mechanic_id}</span>
            )}
          </div>
          <div className="flex flex-row w-full items-center justify-end  space-x-4">
            {isLoadingCRUD ? (
              <Loading title="" visible classNameWidth="" />
            ) : (
              <>
                <CustomButton
                  type="plain"
                  onClick={() => {
                    onClose()
                    formik.handleReset()
                  }}>
                  Cancel
                </CustomButton>
                <CustomButton onClick={formik.handleSubmit}>Confirm</CustomButton>
              </>
            )}
          </div>
        </div>
      </ModalLayout>
    </>
  )
}

export default ModalKonfirmasiMekanik
