import React from 'react'
import { Collapse } from 'antd'
import { CardContainer } from 'components'
import {
  IcAlertTriangle, IcCheck, IcChevronDown, IcClose
} from 'assets/icons'

const InteriorSection = ({
  kelistrikanData
}) => {
  const fieldPanelStyle = 'font-medium text-gray-500'
  const panelBorderBottom = 'flex border border-solid border-b-1 border-x-0 border-t-0 border-b-gray-300 my-3 mx-4'

  const onBgValue = (val) => {
    switch (val) {
      case 'lulus':
        return 'bg-[#ECFDF3]'
      case 'diperbaiki':
        return 'bg-[#FFFAEB]'
      case 'tidak lulus':
        return 'bg-[#FEF3F2]'
      default:
        break
    }
  }

  const onTextValueStyle = (val) => {
    switch (val) {
      case 'lulus':
        return 'text-[#027948]'
      case 'diperbaiki':
        return 'text-[#F79009]'
      case 'tidak lulus':
        return 'text-[#B32318]'
      default:
        break
    }
  }

  const onIcon = (val) => {
    switch (val) {
      case 'lulus':
        return (
          <IcCheck
            className="text-[#027948]"
            size={16}
          />
        )
      case 'diperbaiki':
        return (
          <IcAlertTriangle
            className="text-[#F79009]"
            size={16}
          />
        )
      case 'tidak lulus':
        return (
          <IcClose
            className="text-[#B32318]"
            size={16}
          />
        )
      default:
        break
    }
  }

  return (
    <CardContainer
      minHeight="20%"
      px="px-2"
    >
      <div
        className="flex border border-solid border-b-1 border-x-0 border-t-0 border-b-gray-300 pb-4 mx-4"
      >
        <span className="font-semibold text-2xl text-gray-900">
          Interior
        </span>
      </div>
      <Collapse
        className="inspeksi-detail-interior pt-2"
        defaultActiveKey={['interior-0']}
        ghost
        expandIconPosition="end"
        expandIcon={(collapse) => {
          const className = collapse.isActive ? '-rotate-180' : ''
          return (
            <div>
              <IcChevronDown className={`text-gray-900 ${className}`} size={18} />
            </div>
          )
        }}
      >
        <Collapse.Panel
          header="A. Kelistrikan"
          key="interior-0"
          className="inspeksi-collapse-header font-medium text-md"
        >
          <div
            className="flex"
          >
            <div
              className="flex-1 space-y-3 pr-4"
            >
              {
                kelistrikanData.slice(0, 4).map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-between items-center"
                  >
                    <span
                      className={fieldPanelStyle}
                    >
                      {item.name}
                    </span>
                    <div
                      className={`flex flex-row items-center justify-around space-x-1 py-1 px-2 rounded-xl ${onBgValue(item.value.toLowerCase())}`}
                    >
                      {onIcon(item.value.toLowerCase())}
                      <span
                        className={`font-medium text-sm ${onTextValueStyle(item.value.toLowerCase())}`}
                      >
                        {item.value}
                      </span>
                    </div>
                  </div>
                ))
              }
            </div>
            <div
              className="flex-1 space-y-3 pl-4"
            >
              {
                kelistrikanData.slice(4, kelistrikanData.length).map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-between items-center"
                  >
                    <span
                      className={fieldPanelStyle}
                    >
                      {item.name}
                    </span>
                    <div
                      className={`flex flex-row items-center justify-around space-x-1 py-1 px-2 rounded-xl ${onBgValue(item.value.toLowerCase())}`}
                    >
                      {onIcon(item.value.toLowerCase())}
                      <span
                        className={`font-medium text-sm ${onTextValueStyle(item.value.toLowerCase())}`}
                      >
                        {item.value}
                      </span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </Collapse.Panel>
        <div
          className={panelBorderBottom}
        />
        <Collapse.Panel
          header="B. Chasis & Drive Train Interior"
          key="interior-1"
          className="inspeksi-collapse-header font-medium text-md"
        >
          <div
            className="flex"
          >
            <span
              className={fieldPanelStyle}
            >
              Lom tau
            </span>
          </div>
        </Collapse.Panel>
        <div
          className={panelBorderBottom}
        />
        <Collapse.Panel
          header="C. Body Interior"
          key="interior-2"
          className="inspeksi-collapse-header font-medium text-md"
        >
          <div
            className="flex"
          >
            <span
              className={fieldPanelStyle}
            >
              Lom tau
            </span>
          </div>
        </Collapse.Panel>
        <div
          className={panelBorderBottom}
        />
        <Collapse.Panel
          header="D. Kelistrikan Area Seat, Trim & Panel Airbag"
          key="interior-3"
          className="inspeksi-collapse-header font-medium text-md"
        >
          <div
            className="flex"
          >
            <span
              className={fieldPanelStyle}
            >
              Lom tau
            </span>
          </div>
        </Collapse.Panel>
      </Collapse>
    </CardContainer>
  )
}

export default InteriorSection
