import React, { useEffect, useMemo, useState } from 'react'
import { Input, Modal, Typography } from 'antd'
import { validationInput } from 'helpers'
import { IcSearch } from 'assets/icons'
import { formatCurrency } from 'utils'

const ModalOpsiSparepart = ({ isOpen, onCancel, data, type, selected, onSubmit, serviceId }) => {
  const [search, setSearch] = useState({})
  const [select, setSelect] = useState(selected)

  const dataMemo = useMemo(() => {
    if (search[`general-0`] !== null) {
      if (type === 'packet') {
        return data?.general_services?.map((item, idx) => {
          let spareparts = item?.spareparts?.filter((sparepart) => {
            return sparepart?.name
              ?.toLowerCase()
              ?.includes(search?.[`general-${idx}`]?.toLowerCase())
          })
          return {
            ...item,
            spareparts: spareparts
          }
        })
      } else {
        return data?.spareparts?.filter((item) => {
          return item?.name?.toLowerCase()?.includes(search?.[`general-0`]?.toLowerCase())
        })
      }
    }
  }, [search, data, type])

  useEffect(() => {
    if (isOpen) {
      if (type === 'packet') {
        let varSearch = {}
        data?.general_services.map((_, idx) => {
          varSearch = {
            ...varSearch,
            [`general-${idx}`]: ''
          }
        })
        setSearch(varSearch)
      } else {
        setSearch({
          [`general-0`]: ''
        })
      }
      setSelect(selected);
    }
  }, [isOpen])

  const onOk = () => {
    if(type === 'packet') {
      let length = 0;
      let newData = select?.service_packets?.data
      data?.general_services?.map(general => {
        if(general?.is_free) {
          newData = {
            ...newData,
            [general?.id]: {
              general_name: general?.name,
              general_service_id: general?.id,
              name: general?.name,
              packet_id: data?.id,
              packet_name: data?.name,
              service_id: 0,
              total: 1
            }
          }
        }
      })

      let newSelect = { ...select }
      newSelect = {
        ...newSelect,
        service_packets: {
          ...newSelect.service_packets,
          data: newData
        }
      }
      
      Object.keys(newSelect?.service_packets?.data).map(key => {
        if(data?.id === newSelect?.service_packets?.data[key]?.packet_id) {
          length++;
        }
      })
      if(data?.general_services?.length !== length) {
        // alert("Sparepart harus terisi semua " + data?.general_services?.length + " === " + length)
        alert("Sparepart harus terisi semua")
        return;
      }
      onSubmit(newSelect)
    } else {
      onSubmit(select)
    }
  }

  const onSelected = (item, key) => {
    let service = {
        ...select
    }
    let items = { ...service?.[key]?.data }
    if (items[item?.general_service_id]) {
        service = {
            ...service,
            [key]: {
                ...service[key],
                total: (service?.[key]?.total ?? 0) - item?.price
            },
            total: (service?.total ?? 0) - item?.price
        }
        if (items[item?.general_service_id]?.sparepart_id === item?.id){
            delete service?.[key]?.data[item?.general_service_id];
        } else {
            service = {
                ...service,
                [key]: {
                    ...service[key],
                    data: {
                        ...items,
                        [item?.general_service_id]: {
                            packet_name: data?.name,
                            general_name: item?.general_name,
                            name: item?.name,
                            general_service_id: item?.general_service_id,
                            sparepart_id: item?.id,
                            total: item?.price,
                            packet_id: data?.id,
                            service_id: serviceId
                        }
                    },
                    total: (service?.[key]?.total ?? 0) + item?.price
                },
                total: (service?.total ?? 0) + item?.price
            }
        }
        setSelect(service)
    } else {
        service = {
            ...service,
            [key]: {
                ...service[key],
                data: {
                    ...items,
                    [item?.general_service_id]: {
                        packet_name: data?.name,
                        general_name: item?.general_name,
                        name: item?.name,
                        general_service_id: item?.general_service_id,
                        sparepart_id: item?.id,
                        total: item?.price,
                        packet_id: data?.id,
                        service_id: serviceId
                    }
                },
                total: (service?.[key]?.total ?? 0) + item?.price
            },
            total: (service?.total ?? 0) + item?.price
        }
        setSelect(service)
    }
  }

  return (
    <Modal
      title="Pilih Opsi"
      width={'600px'}
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      okText={'Confirm'}
      okButtonProps={{
        className: 'bg-primary-500',
        type: 'danger'
        // disabled: !harga.valid
      }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
      maskClosable={false}>
      {type === 'packet' && (
        <div className="overflow-y-auto max-h-[400px] overflow-hidden no-scrollbar">
          {dataMemo?.map((general, idx) => (
            <div key={`general-${idx}`}>
              <Typography.Title level={4}>{general?.name}</Typography.Title>
              <Input
                className="w-full rounded-md"
                placeholder="Cari Produk"
                prefix={<IcSearch className="text-gray-400" />}
                onChange={e => setSearch(prevState => {
                    return {
                        ...prevState,
                        [`general-${idx}`]: e.target.value
                    }
                })}
                // onChange={e => setSearchLayanan(e.target.value)}
              />
              <div className="overflow-y-auto max-h-40 mb-4 p-2">
                {general?.spareparts?.map((sparepart, idx) => (
                  <div
                    key={`sparepart-${idx}`}
                    className={`flex justify-between mt-2 p-2 border-solid border border-gray-400 rounded-md hover:cursor-pointer hover:border-primary-300 ${select?.service_packets?.data?.[general?.id]?.sparepart_id === sparepart?.id && 'bg-primary-50 border-primary-300'}`}
                    onClick={() => {
                        onSelected({
                            ...sparepart,
                            general_service_id: general?.id,
                            general_name: general?.name,
                        }, 'service_packets')
                    }}
                >
                    <div className="flex items-center">
                      <div className="h-20 w-20 mr-2">
                        {/* <img
                          alt={sparepart?.name}
                          className="h-20 w-20 mr-2" 
                          // src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${sparepart?.image_filename}`}
                          src={require('../../../../assets/images/oli.png')}
                        /> */}
                      </div>
                      <div>
                        <div className='text-md font-medium mb-1'>{sparepart?.name}</div>
                        {/* <div className='text-lg font-semibold'>{formatCurrency(sparepart?.price ?? "0")}</div> */}
                      </div>
                    </div>
                    {!sparepart?.is_recommended && <div></div>}
                    {sparepart?.is_recommended && <span className="text-primary-500 px-1 rounded-lg flex self-start border border-solid border-primary-400 bg-primary-50 text-sm font-medium">Recommendation</span>}
                    {/* <span className="text-primary-500 px-1 rounded-lg flex self-start border border-solid border-primary-400 bg-primary-50 text-sm font-medium">Recommendation</span> */}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {type === 'general' && (
        <div className="overflow-y-auto max-h-[400px] overflow-hidden no-scrollbar">
            <Typography.Title level={4}>{data?.name}</Typography.Title>
            <Input
                className="w-full rounded-md"
                placeholder="Cari Produk"
                prefix={<IcSearch className="text-gray-400" />}
                onChange={e => setSearch(prevState => {
                    return {
                        ...prevState,
                        [`general-0`]: e.target.value
                    }
                })}
                // onChange={e => setSearchLayanan(e.target.value)}
            />
            <div className="overflow-y-auto max-h-40 mb-4 p-2">
                {dataMemo?.map((sparepart, idx) => (
                  <div
                    key={`sparepart-${idx}`}
                    className={`flex justify-between mt-2 p-2 border-solid border border-gray-400 rounded-md hover:cursor-pointer hover:border-primary-300 ${select?.service_generals?.data[data?.id]?.sparepart_id === sparepart?.id && 'bg-primary-50 border-primary-300'}`}
                    onClick={() => {
                      onSelected({
                        ...sparepart,
                        sparepart_id: sparepart?.id,
                        general_service_id: data?.id,
                        general_name: data?.name,
                      }, 'service_generals')
                    }}
                >
                    <div className="flex items-center">
                        {/* <img
                          alt={sparepart?.name}
                          className="h-20 w-20 mr-2" 
                          src={`${sparepart?.image_filename}`}
                        /> */}
                      <div>
                        <div className='text-md font-medium mb-1'>{sparepart?.name}</div>
                        {/* <div className='text-lg font-semibold'>{formatCurrency(sparepart?.price ?? "0")}</div> */}
                      </div>
                    </div>
                    {!sparepart?.is_recommended && <div></div>}
                    {sparepart?.is_recommended && <span className="text-primary-500 px-1 rounded-lg flex self-start border border-solid border-primary-400 bg-primary-50 text-sm font-medium">Recommendation</span>}
                  </div>
                ))}
              </div>
        </div>
        )}
        <div className='my-2'>
            {Object.keys(select?.service_packets?.data ?? {})?.map(key => (
                <div key={`packet-select-${key}`} className='flex justify-between'>
                  <div className='flex-1 mr-2'>
                    {select?.service_packets?.data[key]?.name}
                  </div>
                  <div>x1</div>
                </div>
            ))}
            {Object.keys(select?.service_generals?.data ?? {})?.map(key => (
                <div key={`general-select-${key}`} className='flex justify-between'>
                  <div className='flex-1'>
                    {select?.service_generals?.data[key]?.name}
                  </div>
                  <div>x1</div>
                </div>
            ))}
        </div>
    </Modal>
  )
}

export default ModalOpsiSparepart
