import { useState } from 'react'
import { Tag } from 'antd'
import { apiGet, urls } from 'api'
import { IcEdit, IcTrash } from 'assets/icons'
import { Button } from 'components'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { formatCurrency } from 'utils'
import ModalServisUmum from './components/ModalServisUmum'
import ServisUmumComponent from './components/ServisUmumComponent'
import { logout } from 'store/actions'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const ServisUmum = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: {}
  })
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  // Get All General Services
  const { isLoading, refetch, isFetching, isRefetching } = useQuery({
    queryKey: [
      'general-service-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.serviceGeneral?.root}?keywords=${tableState.keywords}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  const columns = [
    {
      name: 'Nama Servis Umum',
      selector: (row) => row?.name,
      cell: (row) => row?.name
    },
    {
      name: 'Sparepart',
      selector: (row) => row?.sparepart,
      cell: (row) => (
        <div className="py-1 flex gap-y-2 flex-wrap">
          {row?.spareparts?.length > 0
            ? row?.spareparts?.map((item, index) => (
                <Tag
                  key={`item-${index}`}
                  className="py-[2px] border-0 bg-gray-200 rounded-lg text-black selection:text-black max-w-[140px] text-ellipsis truncate">
                  {item?.sku}
                </Tag>
              ))
            : '-'}
        </div>
      )
    },
    {
      name: 'Kategori',
      selector: (row) => row?.service_category?.name,
      cell: (row) => row?.service_category?.name
    },
    {
      name: 'Jenis',
      selector: (row) => (row?.is_free ? 'Gratis' : 'Berbayar'),
      cell: (row) => (row?.is_free ? 'Gratis' : 'Berbayar')
    },
    {
      name: 'Harga Layanan Tambahan',
      selector: (row) => row?.price,
      cell: (row) => formatCurrency(row?.price)
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => setModal({ type: 'edit', show: true, data: row })}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ type: 'delete', show: true, data: row })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  return (
    <>
      <ModalServisUmum
        type={modal.type}
        isOpen={modal.show}
        data={modal.data}
        onCancel={() => setModal({ ...modal, show: false })}
        refetch={refetch}
        onLogout={onLogout}
      />
      <ServisUmumComponent
        columns={columns}
        data={tableState?.listData}
        onAdd={() => setModal({ type: 'add', show: true, data: null })}
        tableState={tableState}
        setTableState={setTableState}
        isLoading={isLoading || isFetching || isRefetching}
      />
    </>
  )
}

export default ServisUmum
