import React from 'react'
import { Modal, Typography } from 'antd'

const ModalDeletePaketServis = ({ isOpen, onCancel, data, onOk, isLoading }) => {
  return (
    <Modal
      width={400}
      centered
      title="Hapus Paket Servis"
      open={isOpen}
      onCancel={onCancel}
      onOk={() => onOk(data?.id)}
      okText="Confirm"
      okButtonProps={{ className: 'bg-primary-500', type: 'danger', loading: isLoading }}
      cancelButtonProps={{
        className: 'hover:text-[#000] hover:border-[#000]',
        type: 'default',
        disabled: isLoading
      }}>
      <Typography.Text className="font-medium">
        {`Apakah Anda yakin ingin menghapus Paket servis ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
      </Typography.Text>
    </Modal>
  )
}

export default ModalDeletePaketServis
