import React, { useEffect, useMemo, useState } from 'react'
import { Card, Input, Select, Switch, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button, Loading } from 'components'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { validationInput } from 'helpers'
import { useFormik } from 'formik'
import TextArea from 'antd/lib/input/TextArea'

const UpdateBengkelComponent = ({
  type,
  data,
  handleSubmit,
  isLoading = false,
  isLoadingCRUD = false,
  onLogout = () => {}
}) => {
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()

  const [listAddress, setListAddress] = useState({
    provinces: [],
    cities: [],
    subdistricts: [],
    locations: []
  })

  const ValidationCheck = (values) => {
    const errors = {}

    if (!validationInput(values?.name, { isRequired: true })?.valid) {
      errors.name = validationInput(values?.name, { isRequired: true })?.messageError
    }
    if (!validationInput(values?.location_id, { isRequired: true })?.valid) {
      errors.location_id = validationInput(values?.location_id, { isRequired: true })?.messageError
    }
    if (
      !validationInput(values?.stall, { isRequired: true, valueMinLength: 1, isNumeric: true })
        ?.valid
    ) {
      errors.stall = validationInput(values?.stall, {
        isRequired: true,
        valueMinLength: 1,
        isNumeric: true
      })?.messageError
    }
    if (!validationInput(values?.is_pick_up_service, { isRequired: true })?.valid) {
      errors.is_pick_up_service = validationInput(values?.is_pick_up_service, {
        isRequired: true
      })?.messageError
    }
    if (!validationInput(values?.pin_google_map, { isRequired: true })?.valid) {
      errors.pin_google_map = validationInput(values?.pin_google_map, {
        isRequired: true
      })?.messageError
    }
    if (!validationInput(values?.address, { isRequired: true })?.valid) {
      errors.address = validationInput(values?.address, { isRequired: true })?.messageError
    }

    return errors
  }

  const { values, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? '',
      location_id: data?.location_id ?? '',
      subdistrict_id: data?.subdistrict_id ?? '',
      city_id: data?.city_id ?? '',
      province_id: data?.province_id ?? '',
      stall: data?.stall ?? '',
      is_pick_up_service: data?.is_pick_up_service ?? false,
      pin_google_map: data?.pin_google_map ?? '',
      address: data?.address ?? ''
    },
    validate: ValidationCheck,
    onSubmit: async (values) => {
      if (data?.id) {
        let body = {
          ...values,
          stall: parseInt(values?.stall)
        }
        handleSubmit(body)
      } else {
        let body = {
          name: values?.name,
          location_id: values?.location_id,
          stall: parseInt(values?.stall),
          is_pick_up_service: values?.is_pick_up_service,
          pin_google_map: values?.pin_google_map,
          address: values?.address
        }
        handleSubmit(body)
      }
    }
  })

  // Get Province
  useQuery({
    queryKey: ['province-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.location?.province?.all}`,
        onLogout: onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListAddress((prev) => ({ ...prev, provinces: res?.resData?.data ?? [] }))
    }
  })

  // Get City by province
  useQuery({
    queryKey: ['cities-list', values.province_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (values.province_id !== '' && values.province_id !== null) {
        const res = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.cities?.filterByProvince}/${values.province_id}`,
          onLogout: onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setListAddress((prev) => ({ ...prev, cities: res?.resData?.data ?? [] }))
    }
  })

  // Get Subdistrict by city
  useQuery({
    queryKey: ['subdistrict-list', values.city_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (values.city_id !== '' && values.city_id !== null) {
        const res = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.subDistrict?.filterByCity}/${values.city_id}`,
          onLogout: onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setListAddress((prev) => ({ ...prev, subdistricts: res?.resData?.data ?? [] }))
    }
  })

  // Get Location by subdistrict
  useQuery({
    queryKey: ['location-list', values.subdistrict_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (values.subdistrict_id !== '' && values.subdistrict_id !== null) {
        const res = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.all?.filterBySubDistrict}/${values.subdistrict_id}`,
          onLogout: onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setListAddress((prev) => ({ ...prev, locations: res?.resData?.data ?? [] }))
    }
  })

  const map = useMemo(() => {
    if (values?.pin_google_map === '' || values?.pin_google_map === null) {
      return null
    } else {
      return `https://maps.google.com/maps?q=${values?.pin_google_map?.split(',')?.[0]},${
        values?.pin_google_map?.split(',')?.[1]
      }&hl=es;z=14&output=embed`
    }
  }, [values?.pin_google_map])

  // Get current location latitude longitude where pin_google_map empty
  useEffect(() => {
    if ((values?.pin_google_map === '' || values?.pin_google_map === null) && type !== 'edit') {
      navigator.geolocation.getCurrentPosition((position) => {
        formik?.setFieldValue(
          'pin_google_map',
          `${position.coords.latitude},${position.coords.longitude}`
        )
      })
    }
  }, [])

  if (isLoading) {
    return <Loading visible />
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
          <Typography.Text className="font-semibold text-xl">
            {type === 'edit' ? 'Edit Bengkel' : 'Tambah Bengkel'}
          </Typography.Text>
        </div>
        <div className="mb-3">
          <Typography.Text className="font-medium block">
            Nama Bengkel <span className="text-[red]"> *</span>
          </Typography.Text>
          <Input
            className="rounded-md mt-1 py-3"
            placeholder="Masukkan Nama Bengkel"
            name="name"
            value={values?.name}
            onChange={formik?.handleChange}
          />
          {touched?.name && errors?.name && <span className="text-[red]">{errors?.name}</span>}
        </div>
        <div className="mb-3">
          <Typography.Text className="font-medium block">
            Jumlah Stall <span className="text-[red]"> *</span>
          </Typography.Text>
          <Input
            type="number"
            className="rounded-md mt-1 py-3"
            placeholder="Masukkan Jumlah Stall"
            name="stall"
            value={values?.stall}
            onChange={formik?.handleChange}
          />
          {touched?.stall && errors?.stall && <span className="text-[red]">{errors?.stall}</span>}
        </div>
        <div className="flex items-center mb-3">
          <Typography.Text className="font-medium mr-2">
            Melayani Pickup Servis <span className="text-[red]"> *</span>
          </Typography.Text>
          <Switch
            name="is_pick_up_service"
            checked={values?.is_pick_up_service}
            onChange={(e) => formik?.setFieldValue('is_pick_up_service', e)}
          />
        </div>
        <div className="flex items-center space-x-3">
          <div className="mb-3 flex-1">
            <Typography.Text className="font-medium block">
              Provinsi <span className="text-[red]"> *</span>
            </Typography.Text>
            <Select
              showSearch
              allowClear
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
              style={{ width: '100%' }}
              value={values?.province_id === '' ? null : values?.province_id}
              optionFilterProp="children"
              onChange={(e) => {
                formik?.setFieldValue('province_id', e ?? '')
                formik?.setFieldValue('city_id', '')
                formik?.setFieldValue('subdistrict_id', '')
                formik?.setFieldValue('location_id', '')
              }}
              placeholder="Pilih Provinsi">
              {listAddress?.provinces?.map((province, idx) => (
                <Select.Option key={idx} value={province?.id}>
                  {province?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="mb-3 flex-1">
            <Typography.Text className="font-medium block">
              Kota <span className="text-[red]"> *</span>
            </Typography.Text>
            <Select
              showSearch
              allowClear
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
              style={{ width: '100%' }}
              value={values?.city_id === '' ? null : values?.city_id}
              optionFilterProp="children"
              onChange={(e) => {
                formik?.setFieldValue('city_id', e ?? '')
                formik?.setFieldValue('subdistrict_id', '')
                formik?.setFieldValue('location_id', '')
              }}
              placeholder="Pilih Kota">
              {listAddress?.cities?.map((city, idx) => (
                <Select.Option key={idx} value={city?.id}>
                  {city?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <div
            className={['flex-1', touched?.location_id && errors?.location_id ? '' : 'mb-3'].join(
              ' '
            )}>
            <Typography.Text className="font-medium block">
              Kecamatan <span className="text-[red]"> *</span>
            </Typography.Text>
            <Select
              showSearch
              allowClear
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
              style={{ width: '100%' }}
              value={values?.subdistrict_id === '' ? null : values?.subdistrict_id}
              optionFilterProp="children"
              onChange={(e) => {
                formik?.setFieldValue('subdistrict_id', e ?? '')
                formik?.setFieldValue('location_id', '')
              }}
              placeholder="Pilih Kecamatan">
              {listAddress?.subdistricts?.map((subdistrict, idx) => (
                <Select.Option key={idx} value={subdistrict?.id}>
                  {subdistrict?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div
            className={['flex-1', touched?.location_id && errors?.location_id ? '' : 'mb-3'].join(
              ' '
            )}>
            <Typography.Text className="font-medium block">
              Kelurahan <span className="text-[red]"> *</span>
            </Typography.Text>
            <Select
              showSearch
              allowClear
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-full"
              style={{ width: '100%' }}
              value={values?.location_id === '' ? null : values?.location_id}
              optionFilterProp="children"
              onChange={(e) => {
                formik?.setFieldValue('location_id', e ?? '')
              }}
              placeholder="Pilih Kelurahan">
              {listAddress?.locations?.map((location, idx) => (
                <Select.Option key={idx} value={location?.id}>
                  {location?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        {touched?.location_id && errors?.location_id && (
          <div className="mb-3">
            <span className="text-[red]">{errors?.location_id}</span>
          </div>
        )}
        <div className="mb-3">
          <Typography.Text className="font-medium block">
            Alamat Lengkap <span className="text-[red]"> *</span>
          </Typography.Text>
          <TextArea
            className="rounded py-2"
            style={{
              height: '95px'
            }}
            placeholder="Masukkan Alamat Lengkap"
            name="address"
            value={values?.address}
            onChange={formik.handleChange}
          />
          {touched?.address && errors?.address && (
            <span className="text-[red]">{errors?.address}</span>
          )}
        </div>
        <div className="mb-5">
          <Typography.Text className="font-medium block">
            Pin Google Map <span className="text-[red]"> *</span>
          </Typography.Text>
          <Input
            className="rounded-md mt-1 py-3"
            placeholder="Masukkan Koordinat Latitude Longitude Map (-6.1753924,106.8249641)"
            name="pin_google_map"
            value={values?.pin_google_map}
            onChange={formik?.handleChange}
          />
          {touched?.pin_google_map && errors?.pin_google_map && (
            <span className="text-[red] mb-3">{errors?.pin_google_map}</span>
          )}
        </div>
        {map && (
          <div className=" overflow-hidden h-full w-full mb-4">
            <iframe
              title="map-header-service"
              src={map}
              width="100%"
              height="210"
              frameBorder="0"
              style={{
                border: 0,
                borderRadius: 5,
                zIndex: 1,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%'
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              aria-hidden="false"
            />
          </div>
        )}
        <div className="w-full border-solid border-0 border-t border-t-gray-300 flex justify-end items-center pt-2">
          {isLoadingCRUD ? (
            <Loading title="" visible classNameWidth="" />
          ) : (
            <>
              <Button
                className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
                onClick={() => {
                  router.go(-1)
                  formik?.handleReset()
                }}>
                Cancel
              </Button>
              <Button
                className="p-0 px-4 py-2 bg-primary-500 border font-medium text-white hover:opacity-90"
                onClick={() => {
                  formik?.handleSubmit()
                }}>
                Simpan
              </Button>
            </>
          )}
        </div>
      </Card>
    </Content>
  )
}

export default UpdateBengkelComponent
