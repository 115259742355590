import React, { useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import {
  CardContainer,
  FormDropDown,
  FormInput,
  HeaderContainer,
  Loading,
  ModalDelete,
  Table
} from 'components'
import { Formik } from 'formik'
import { IcEdit, IcTrash } from 'assets/icons'
import { ModalCreateUpdateDistrict } from './components'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { metaInitialData, perPageInitialData } from 'constant'
import { logout } from 'store/actions'

const LocationDistrictPage = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [citiesList, setCitiesList] = useState()
  const [meta, setMeta] = useState(metaInitialData)
  const [currentPage, setCurrentPage] = useState(0)
  const [dropDownProvince, setDropDownProvince] = useState([])
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)

  const onLogout = () => dispatch(logout())

  const { isLoading, refetch } = useQuery({
    queryKey: ['cities'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await Promise.all([
        apiGet({
          url: `${urls.master.prefix}/${urls.master.location.cities.root}?limit=${
            perPageArr.find((item) => item.selected === true).value
          }&offset=0`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.master.prefix}/${urls.master.location.province.all}`,
          token: user.token,
          onLogout
        })
      ]),
    onSuccess: (data) => {
      setDropDownProvince([])
      setCitiesList(data[0].resData.data)
      if (data[1].resStatus === 200) {
        if (data[1].resData.data.length !== 0) {
          data[1].resData.data.map((item) => {
            setDropDownProvince((prev) => [...prev, { name: item.name, value: item.id }])
          })
        }
      }
      setCurrentPage(0)
      setMeta(data[0].resData.meta)
    }
  })

  const [modalDelete, setModalDelete] = useState({
    show: false,
    cityId: 0,
    title: '',
    desc: ''
  })

  const [modalCreateUpdate, setModalCreateUpdate] = useState({
    show: false,
    type: 'create',
    cityId: '',
    cityName: '',
    provinceId: ''
  })

  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [citySearch, setCitySearch] = useState('')
  const [provinceSelected, setProvinceSelected] = useState()

  const onHandleExtraUrl = () => {
    let extraUrl = ''

    if (citySearch !== '') {
      extraUrl += `&keywords=${citySearch}`
    }
    if (provinceSelected > 0) {
      extraUrl += `&province_id=${provinceSelected}`
    }

    return extraUrl
  }

  const onChangePrevNextPage = (offset) => {
    setIsLoadingCRUD(true)
    setMeta({ ...meta, offset })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.master.prefix}/${urls.master.location.cities.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${offset}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setCitiesList(response.resData.data)
      } else {
        refetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    setIsLoadingCRUD(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.master.prefix}/${
          urls.master.location.cities.root
        }?limit=${value}&offset=0${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setCitiesList(response.resData.data)
        setMeta(response.resData.meta)
        setPerPageArr(newArr)
      } else {
        refetch()
      }
      setIsLoadingCRUD(false)
    })
    return () => clearTimeout(mount)
  }

  const columns = [
    {
      name: 'Provinsi',
      selector: (row) => row.province.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.province.name}</span>
    },
    {
      name: 'Kota/Kabupaten',
      selector: (row) => row.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              setModalCreateUpdate({
                show: true,
                type: 'update',
                cityId: row.id,
                cityName: row.name,
                provinceId: row.province.id
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setModalDelete({
                show: true,
                cityId: row.id,
                title: 'Hapus Lokasi',
                desc: `Apakah anda yakin ingin menghapus lokasi [${`${row.name}`}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  if (isLoading ?? isLoadingCRUD) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Master Data
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Lokasi</Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Kota/Kabupaten
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {modalCreateUpdate.show ? (
          <ModalCreateUpdateDistrict
            minHeight="min-h-[18%]"
            state={modalCreateUpdate}
            dropDownProvince={dropDownProvince.filter((item) => item.value !== -1)}
            onClose={() => setModalCreateUpdate({ ...modalCreateUpdate, show: false })}
            onSubmit={(values) => {
              setIsLoadingCRUD(true)
              const mount = setTimeout(async () => {
                if (modalCreateUpdate.type === 'create') {
                  await apiPost({
                    url: `${urls.master.prefix}/${urls.master.location.cities.root}`,
                    data: {
                      name: values.cityName,
                      province_id: parseInt(values.provinceSelector)
                    },
                    token: user.token,
                    onLogout
                  })
                }
                if (modalCreateUpdate.type === 'update') {
                  await apiPut({
                    url: `${urls.master.prefix}/${urls.master.location.cities.root}/${modalCreateUpdate.cityId}`,
                    data: {
                      name: values.cityName,
                      province_id: parseInt(values.provinceSelector)
                    },
                    token: user.token,
                    onLogout
                  })
                }
                setModalCreateUpdate({ ...modalCreateUpdate, show: false })
                setIsLoadingCRUD(false)
                refetch()
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {modalDelete.show ? (
          <ModalDelete
            minHeight="min-h-[18%]"
            minWidth="min-w-[22%]"
            maxWidth="max-w-[22%]"
            title={modalDelete.title}
            desc={modalDelete.desc}
            onClose={() => setModalDelete({ ...modalDelete, show: false })}
            onSubmit={() => {
              setIsLoadingCRUD(true)
              const mount = setTimeout(async () => {
                await apiDelete({
                  url: `${urls.master.prefix}/${urls.master.location.cities.root}/${modalDelete.cityId}`,
                  token: user.token,
                  onLogout
                })
                setModalDelete({ ...modalDelete, show: false })
                refetch()
                setIsLoadingCRUD(false)
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        <CardContainer>
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer
              isPressAdd
              title="Kota/Kabupaten"
              titleAdd="Tambah Lokasi"
              onPressAdd={() =>
                setModalCreateUpdate({
                  type: 'create',
                  show: true,
                  cityId: 0,
                  cityName: '',
                  provinceId: 0
                })
              }
            />
            <div className="flex flex-row w-full items-center justify-between">
              <Formik
                enableReinitialize
                initialValues={{
                  provinceSelector: provinceSelected
                }}>
                <div className="w-1/6">
                  <FormDropDown
                    isSearch
                    allowClear
                    name="provinceSelector"
                    placeholder="Semua Provinsi"
                    data={dropDownProvince}
                    onChange={(value) => {
                      setIsLoadingCRUD(true)
                      let extraUrl = ''
                      const mount = setTimeout(async () => {
                        if (value && parseInt(value) > 0) {
                          extraUrl = `&province_id=${parseInt(value)}`
                        }
                        if (citySearch !== '') {
                          extraUrl += `&keywords=${citySearch}`
                        }
                        const response = await apiGet({
                          url: `${urls.master.prefix}/${urls.master.location.cities.root}?limit=${
                            perPageArr.find((item) => item.selected === true).value
                          }&offset=0${extraUrl}`,
                          token: user.token,
                          onLogout
                        })
                        if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
                          setCitiesList(response.resData.data)
                          setMeta(response.resData.meta)
                        } else {
                          refetch()
                        }
                        setProvinceSelected(value ? parseInt(value) : null)
                        setIsLoadingCRUD(false)
                      })
                      return () => clearTimeout(mount)
                    }}
                  />
                </div>
              </Formik>
              <Formik
                initialValues={{
                  citySearch: citySearch
                }}>
                <div className="flex-row w-1/5">
                  <FormInput
                    name="citySearch"
                    placeholder="Pencarian"
                    type="text"
                    isIconLeft
                    iconName="search"
                    onValidate={async (value) => {
                      let extraUrl = ''
                      if (provinceSelected > 0) {
                        extraUrl += `&province_id=${provinceSelected}`
                      }
                      const response = await apiGet({
                        url: `${urls.master.prefix}/${
                          urls.master.location.cities.root
                        }?keywords=${value}&limit=${
                          perPageArr.find((item) => item.selected === true).value
                        }&offset=0${extraUrl}`,
                        token: user.token,
                        onLogout
                      })
                      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
                        setCitiesList(response.resData.data)
                        setMeta(response.resData.meta)
                      } else {
                        refetch()
                      }
                      setCitySearch(value)
                    }}
                  />
                </div>
              </Formik>
            </div>
            <Table
              currentPage={meta.offset ? meta.offset + 1 : 1}
              totalPerPage={
                perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
                meta.total
                  ? meta.total
                  : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
              }
              dropDownDataFilter={perPageArr}
              lengthAllData={meta.total}
              columns={columns}
              data={citiesList}
              onChangePrevPage={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onChangePrevNextPage(
                    (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeNextPage={() => {
                if (
                  perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
                  meta.total
                ) {
                  setCurrentPage(currentPage + 1)
                  onChangePrevNextPage(
                    (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeDropDown={(value) => onChangePerPage(value)}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default LocationDistrictPage
