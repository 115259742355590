import { Input, Modal, Typography } from 'antd'
import React, { useState } from 'react'

const ModalUpdateUnitList = ({
  typeButton,
  data,
  isOpen,
  onCancel,
  onSubmit,
  onDelete,
  updateLoading
}) => {
  const [serialNumber, setSerialNumber] = useState(
    typeButton === 'tambah' ? null : data?.serial_number
  )
  if (typeButton === 'delete')
    return (
      <Modal
        title="Hapus Unit"
        open={isOpen}
        centered
        onOk={() => onDelete(data?.id)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
        maskClosable={false}
        confirmLoading={updateLoading}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus Unit "${data?.unit_name}" dengan serial number "${data?.serial_number}" ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )

  return (
    <Modal
      title={typeButton === 'tambah' ? 'Tambah Unit' : 'Edit Unit'}
      open={isOpen}
      centered
      onOk={() => onSubmit(serialNumber, data?.id)}
      onCancel={onCancel}
      okText={typeButton === 'tambah' ? 'Tambah' : 'Edit'}
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
      maskClosable={false}
      confirmLoading={updateLoading}>
      <div className="mb-4">
        <Typography.Text className="mb-1 block">Serial Number</Typography.Text>
        <Input
          className="rounded-md"
          placeholder="Masukan serial number"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
        />
        {!serialNumber && (
          <Typography.Text className="mt-1 block text-red-500 text-xs">
            *Silahkan masukkan serial number unit
          </Typography.Text>
        )}
      </div>
    </Modal>
  )
}

export default ModalUpdateUnitList
