import { Breadcrumb, Layout } from 'antd'
import { apiGet, urls } from 'api'
import { IcEye } from 'assets/icons'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'store/actions'
import CabangComponent from './components/CabangComponent'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  province_id: '',
  city_id: ''
}

const Cabang = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const router = useHistory()
  const [listTotal, setListTotal] = useState({
    cabang: 0,
    testDrive: 0,
    mechanic: 0,
    inspektor: 0
  })

  //* columns table
  const columns = [
    {
      name: 'Nama Bengkel',
      selector: (row) => row?.name,
      cell: (row) => row?.name,
      grow: 1
    },
    {
      name: 'Provinsi',
      selector: (row) => row?.province_name,
      cell: (row) => row?.province_name,
      grow: 1
    },
    {
      name: 'Cabang',
      selector: (row) => row?.city_name,
      cell: (row) => row?.city_name,
      grow: 1
    },
    {
      name: 'Jumlah Stall',
      selector: (row) => row?.stall,
      cell: (row) => row?.stall,
      grow: 1
    },
    {
      name: 'Mekanik',
      selector: (row) => row?.mechanic_total,
      cell: (row) => row?.mechanic_total,
      grow: 1
    },
    {
      name: 'Inspektor',
      selector: (row) => row?.inspector_total,
      cell: (row) => row?.inspector_total,
      grow: 1
    },
    {
      name: 'Security',
      selector: (row) => row?.security_total,
      cell: (row) => row?.security_total,
      grow: 1
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          onClick={() => router.push(`/workshop-management/${row?.id}`)}
          className="flex flex-row items-center justify-center self-center space-x-1 px-2 py-2 my-2 border border-solid border-gray-300 rounded-lg hover:cursor-pointer">
          <IcEye className="text-gray-700" size={19} />
          <span className="font-medium text-gray-700">Lihat</span>
        </div>
      )
    }
  ]
  //* end columns table

  // Get Api
  // Get cabang list
  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'total-cabang-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.province_id,
      tableState.city_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.workshops?.root}?keywords=${tableState.keywords}&province_id=${tableState?.province_id}&city_id=${tableState?.city_id}&limit=${tableState?.limit}&offset=${tableState?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Provinces
  useQuery({
    queryKey: ['province-list'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.master?.prefix}/${urls?.master?.location?.province?.all}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setProvinces(res?.resData?.data ?? [])
    }
  })

  // Get City by province
  useQuery({
    queryKey: ['cities-list', tableState.province_id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (tableState.province_id !== '' && tableState.province_id !== null) {
        const res = await apiGet({
          token,
          url: `${urls?.master?.prefix}/${urls?.master?.location?.cities?.filterByProvince}/${tableState.province_id}`,
          onLogout
        })
        return res
      }
    },
    onSuccess: (res) => {
      setCities(res?.resData?.data ?? [])
    }
  })

  // Get total testdrive
  useQuery({
    queryKey: ['testdrive-total'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixJualBeli}/${urls?.analytic?.rootAdmin}/${urls?.analytic?.cabang?.testDriveTotal}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, testDrive: res?.resData?.data ?? 0 }))
    }
  })

  // Get total inspector
  useQuery({
    queryKey: ['inspector-total'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixUser}/${urls?.analytic?.cabang?.inspectorTotal}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, inspektor: res?.resData?.data?.count ?? 0 }))
    }
  })

  // Get total mechanic
  useQuery({
    queryKey: ['mechanic-total'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixUser}/${urls?.analytic?.cabang?.mechanicTotal}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, mechanic: res?.resData?.data?.count ?? 0 }))
    }
  })

  // Get total workshop
  useQuery({
    queryKey: ['workshop-total'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixMaster}/${urls?.analytic?.cabang?.workshopTotal}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, cabang: res?.resData?.data?.count ?? 0 }))
    }
  })

  // Get total security
  useQuery({
    queryKey: ['security-total'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.analytic?.prefixUser}/${urls?.analytic?.cabang?.securityTotal}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setListTotal((prev) => ({ ...prev, security: res?.resData?.data?.count ?? 0 }))
    }
  })

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prev) => ({
      ...prev,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prev) => ({
      ...prev,
      offset: page
    }))
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Cabang</Breadcrumb.Item>
      </Breadcrumb>
      <CabangComponent
        columns={columns}
        listTotal={listTotal}
        cities={cities}
        handlePerRowsChange={handlePerRowsChange}
        isLoading={isLoading ?? isFetching}
        onChangePage={onChangePage}
        provinces={provinces}
        setTableState={setTableState}
        tableState={tableState}
      />
    </Layout>
  )
}

export default Cabang
