import React from 'react'
import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Table } from 'components'
import { IcSearch } from 'assets/icons'

const TestDriveComponent = ({
  isLoading,
  provinces,
  cities,
  selectedLocation,
  handleChange,
  handleSearch,
  columns,
  data,
  currentPage,
  totalPerPage,
  dropDownDataFilter,
  lengthAllData,
  onChangePrevPage,
  onChangeNextPage,
  onChangeDropDown
}) => {
  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="mb-5">
          <Typography.Text className="font-semibold text-2xl">Daftar Cabang</Typography.Text>
        </div>
        <div className="mb-5 flex justify-between">
          <div className="flex">
            <Select
              className="custom-table-limit mr-3"
              style={{ width: '150px' }}
              allowClear
              value={selectedLocation.province_id}
              onChange={(e) => handleChange(e ?? '', 'province_id')}
              showSearch
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Provinsi
              </Select.Option>
              {provinces.map((el) => (
                <Select.Option key={el.value} value={el.value}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
            <Select
              className="custom-table-limit"
              style={{ width: '150px' }}
              allowClear
              value={selectedLocation.city_id}
              onChange={(e) => handleChange(e ?? '', 'city_id')}
              showSearch
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}>
              <Select.Option className="custom-table-limit-option" value="">
                Semua Cabang
              </Select.Option>
              {cities.map((el) => (
                <Select.Option key={el.value} value={el.value}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Input
            className="w-1/4 rounded-md"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          pending={isLoading}
          columns={columns}
          data={data}
          currentPage={currentPage}
          totalPerPage={totalPerPage}
          dropDownDataFilter={dropDownDataFilter}
          lengthAllData={lengthAllData}
          onChangePrevPage={onChangePrevPage}
          onChangeNextPage={onChangeNextPage}
          onChangeDropDown={onChangeDropDown}
        />
      </Card>
    </Content>
  )
}

export default TestDriveComponent
