import React from 'react'
import DataTable from 'react-data-table-component'
import { Select, Spin, Typography } from 'antd'
import { IcChevronLeft, IcChevronRight } from 'assets/icons'
import Button from './Button'

const Loading = () => {
  return (
    <div className={'flex flex-col items-center justify-center px-4 py-8'}>
      <Spin />
      <Typography.Text className="text-grey-400 text-lg text-primary-500 ml-2">
        Loading ...
      </Typography.Text>
    </div>
  )
}

const Table = ({
  className,
  columns,
  data,
  pending,
  onChangeRowsPerPage,
  totalRows,
  selectableRows,
  expandableRows,
  expandedComponent,
  noTableHead,
  onSelectedRowsChange,
  clearSelectedRows,
  selectableRowsHighlight,
  fixedHeader,
  style,
  onSort,
  sortServer,
  onRowClicked,
  pagination,
  selectableRowDisabled,
  currentPage,
  page,
  totalPerPage,
  lengthAllData,
  dropDownDataFilter,
  onChangeDropDown,
  onChangeNextPage,
  onChangePrevPage,
  pageSize,
  hidePagination,
  handlePerRowsChange,
  useFooter = true,
  isStyleHistory = false,
  stripped = true,
  rowsStyles,
  headStyles,
  keyField = 'id',
  cellStyles,
  tableStyles,
  noStripped = false
}) => (
  <>
    <DataTable
      keyField={keyField}
      className={className}
      style={style}
      selectableRowDisabled={selectableRowDisabled}
      fixedHeader={fixedHeader}
      progressPending={pending}
      progressComponent={<Loading />}
      persistTableHead
      clearSelectedRows={clearSelectedRows}
      selectableRowsHighlight={selectableRowsHighlight}
      columns={columns}
      onRowClicked={onRowClicked}
      customStyles={
        isStyleHistory
          ? {
              table: {
                style: {
                  padding: '2px'
                }
              },
              rows: {
                style: {
                  background: '#F9FAFB',
                  marginTop: '10px',
                  marginBottom: '10px',
                  borderRadius: '12px',
                  padding: '24px',
                  borderBottomWidth: 0,
                  borderWidth: 1,
                  '&:not(:last-of-type)': {
                    borderBottomWidth: '0px '
                  },
                  ...rowsStyles
                },
                highlightOnHoverStyle: null
              }
            }
          : {
              table: {
                style: {
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                  padding: '2px',
                  ...tableStyles
                }
              },
              headCells: {
                style: {
                  fontWeight: 600,
                  fontSize: 14,
                  color: '#101828',
                  ...headStyles
                }
              },
              rows: {
                style: {
                  borderBottomWidth: 1,
                  borderBottomColor: '#D0D5DD',
                  borderWidth: 1,
                  ...rowsStyles
                },
                highlightOnHoverStyle: null,
                stripedStyle: {
                  backgroundColor: noStripped ? 'none' : 'white'
                }
              },
              cells: {
                style: {
                  ...cellStyles
                }
              }
            }
      }
      striped={stripped}
      paginationServer
      highlightOnHover
      data={data}
      pagination={pagination}
      noTableHead={noTableHead}
      onSelectedRowsChange={onSelectedRowsChange}
      responsive
      selectableRows={selectableRows}
      paginationTotalRows={totalRows}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      paginationComponentOptions={{
        rowsPerPageText: 'Data per page',
        rangeSeparatorText: 'dari',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Semua'
      }}
      onChangeRowsPerPage={onChangeRowsPerPage}
      expandableRows={expandableRows}
      expandableRowsComponent={expandedComponent}
      onSort={onSort}
      sortServer={sortServer}
      // selectableRowsComponent={SelectedCheckbox}
      // selectableRowsComponentProps={selectableRowsComponentProps}
    />
    {!hidePagination && data && data.length !== 0 && useFooter && (
      <div className="flex w-full flex-row items-center justify-end space-x-4 mt-4">
        <div className={`${dropDownDataFilter ? 'flex flex-row items-center' : ''} mr-4`}>
          <label className="text-gray-700 mr-4">Tampilkan</label>
          {dropDownDataFilter ? (
            <Select
              className="custom-table-limit"
              style={{ width: '70px' }}
              value={dropDownDataFilter.find((item) => item.selected === true)?.value ?? 5}
              onChange={(value) => onChangeDropDown(value)}>
              {dropDownDataFilter?.map((item, idx) => (
                <Select.Option key={idx} value={item?.value}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Select
              className="custom-table-limit"
              style={{ width: '70px' }}
              value={pageSize ?? 5}
              onChange={handlePerRowsChange}>
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
          )}
        </div>
        <div className="flex flex-row">
          {isNaN(page) && (
            <span className="font-medium text-gray-700">
              {`${currentPage ?? '1'} - ${totalPerPage ?? data?.length} dari ${
                lengthAllData ?? data?.length
              }`}
            </span>
          )}
          {!isNaN(page) && (
            <span className="font-medium text-gray-700">
              {`${page + 1} - ${
                page + pageSize >= totalRows ? totalRows : page + pageSize
              } dari ${totalRows}`}
            </span>
          )}
        </div>
        <div className="flex flex-row space-x-3">
          <Button
            className="group p-0 flex border border-gray-300 bg-white justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
            onClick={onChangePrevPage ? () => onChangePrevPage() : () => {}}>
            <IcChevronLeft className="text-gray-700 group-hover:text-primary-500" />
          </Button>
          <Button
            className="group p-0 flex border border-gray-300 bg-white justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
            onClick={onChangeNextPage ? () => onChangeNextPage() : () => {}}>
            <IcChevronRight className="text-gray-700 group-hover:text-primary-500" />
          </Button>
        </div>
      </div>
    )}
  </>
)

export default Table
