import { mdiChevronRight } from '@mdi/js'
import { Breadcrumb, Layout } from 'antd'
import { apiGet, urls } from 'api'
import {
  BaseIcon,
  CatatanCard,
  DocumentUnitCard,
  InformasiUnitCard,
  InformasiUserCard,
  InspeksiCard,
  Loading,
  PhotoCarousel
} from 'components'
import BadgeProgress from 'components/badge-progress'
import HistoryUnitServicesInspectionCard from 'components/card/history-unit-services-inspection-card'
import InformasiSpekUnitVerticalCard from 'components/card/informasi-spek-unit-vertical-card'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import { formatCurrency, showErrorMessage } from 'utils'

const DetailAnalyticsUnit = () => {
  const { id, unit } = useParams()
  const { state } = useLocation()
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }

  const [detailCatalog, setDetailCatalog] = useState({})
  const [detailPriceService, setDetailPriceService] = useState({})

  const [historyInspection, setHistoryInspection] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: []
  })

  const [historyServices, setHistoryServices] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: []
  })

  const columnsHistoryInspection = [
    {
      name: 'Title',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-medium text-lg text-gray-900">{`Inspeksi - ${row?.workshop_name}`}</span>
          <span className="font-normal text-lg text-gray-900">{row?.date}</span>
        </div>
      )
    },
    {
      name: 'Status',
      width: 'auto',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-normal text-lg text-gray-900">Status</span>
          <div>
            <BadgeProgress status={row?.status} />
          </div>
        </div>
      )
    },
    {
      name: 'Action',
      width: 'auto',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => router.push(`/inspeksi/hasil-inspeksi/${row?.id}`)}>
          <BaseIcon path={mdiChevronRight} iconSize={21} color="#E12B25" />
        </div>
      )
    }
  ]

  const columnsHistoryServices = [
    {
      name: 'Title',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-medium text-lg text-gray-900">{row?.service_name}</span>
          <span className="font-normal text-lg text-gray-900">
            {moment(row?.updated_at).format('ll')}
          </span>
        </div>
      )
    },
    {
      name: 'Result',
      width: 'auto',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-normal text-left text-lg text-gray-900">{`${
            row?.item_count ?? 0
          } item`}</span>
          <span className="font-semibold text-left text-lg text-[#E12B25]">
            {formatCurrency(row?.price_total ?? 0)}
          </span>
        </div>
      )
    },
    {
      name: 'Status',
      width: 'auto',
      cell: (row) => (
        <div className="flex flex-col space-y-2">
          <span className="font-normal text-lg text-gray-900">Status</span>
          <div>
            <BadgeProgress status={row?.status} />
          </div>
        </div>
      )
    },
    {
      name: 'Action',
      width: 'auto',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      cell: (row) => (
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => router.push(`/services/jadwal/detail/${row?.id}`)}>
          {' '}
          <BaseIcon path={mdiChevronRight} iconSize={21} color="#E12B25" />
        </div>
      )
    }
  ]

  // Get List History Inspeksi
  const { isLoading: isLoadingInspeksi, isFetching: isFetchingInspeksi } = useQuery({
    queryKey: ['historyInspection-list', historyInspection.offset, historyInspection.limit, id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.inspection.prefix}/${urls?.inspection.history}/${id}?limit=${historyInspection?.limit}&offset=${historyInspection?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setHistoryInspection((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get List History Service
  const { isLoading: isLoadingServices, isFetching: isFetchingServices } = useQuery({
    queryKey: ['historyServices-list', historyServices.offset, historyServices.limit, id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service.prefix}/${urls?.service?.historiesBooking}/${id}?limit=${historyServices?.limit}&offset=${historyServices?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setHistoryServices((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Detail
  const { isLoading: isLoadingDetail, isFetching: isFetchingDetail } = useQuery({
    queryKey: ['detail-catalog', id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.analytic.prefixMaster}/${urls.analytic.unit.detailUnit}/${id}`,
        onLogout
      })
      if (res?.resStatus !== 200 && res?.resStatus !== 401) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setDetailCatalog(res?.resData?.data ?? {})
    }
  })

  // Get Detail Price Service
  useQuery({
    queryKey: ['detail-price-service', state?.idBooking],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (state?.idBooking) {
        const res = await apiGet({
          token,
          url: `${urls.service.prefix}/${urls.service.booking}/${state?.idBooking}?is_show_relation=true`,
          onLogout
        })
        if (res?.resStatus !== 200 && res?.resStatus !== 401) {
          showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        }
        return res
      }
    },
    onSuccess: (res) => {
      if (state?.idBooking) {
        setDetailPriceService(res?.resData?.data ?? {})
      }
    }
  })

  if (isLoadingDetail || isFetchingDetail) {
    return <Loading visible />
  }

  return (
    <Layout className="grid grid-cols-1 gap-6 p-8">
      {/* BREADCRUMB */}
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.goBack()}>
          Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">{unit}</Breadcrumb.Item>
      </Breadcrumb>
      <InformasiUnitCard
        unit={detailCatalog?.unit_name ?? '-'}
        status={detailCatalog?.unit_status ?? '-'}
        harga={
          state?.idBooking
            ? formatCurrency(detailPriceService?.price_total ?? 0)
            : formatCurrency(detailCatalog?.price ?? 0)
        }
      />
      <InformasiUserCard
        title={'Informasi Pemilik'}
        data={{
          nama: detailCatalog?.unitOwner?.name ?? '-',
          email: detailCatalog?.unitOwner?.email ?? '-',
          telepon: detailCatalog?.unitOwner?.phone_number ?? '-',
          alamat: detailCatalog?.unitOwner?.address ?? '-'
        }}
      />
      <div className="grid grid-cols-2 w-full space-x-5 items-start justify-start">
        <InformasiSpekUnitVerticalCard
          data={{
            merek: detailCatalog?.brand?.name ?? '',
            model: detailCatalog?.model?.name ?? '',
            tipe: detailCatalog?.type?.name ?? '',
            platNomor: detailCatalog?.police_number ?? '',
            tahun: detailCatalog?.production_year ?? '',
            transmisi: detailCatalog?.transmission?.name ?? '',
            warna: detailCatalog?.color?.name ?? '',
            bahanBakar: detailCatalog?.fuel?.name ?? '',
            noRangka: detailCatalog?.frame_number ?? '',
            noMesin: detailCatalog?.engine_number ?? '',
            tag:
              detailCatalog?.type?.categories?.map(
                (item, idx) =>
                  `${item?.name}${idx + 1 === detailCatalog?.type?.categories?.length ? '' : ', '}`
              ) ?? ''
          }}
        />
        <DocumentUnitCard
          data={[
            {
              name: 'STNK',
              value: detailCatalog?.document_stnk_status ? 'Ada' : 'Tidak Ada'
            },
            {
              name: 'BPKB',
              value: detailCatalog?.document_bpkb_status ? 'Ada' : 'Tidak Ada'
            },
            {
              name: 'Faktur',
              value: detailCatalog?.document_faktur_status ? 'Ada' : 'Tidak Ada'
            },
            {
              name: 'KTP Pemilik',
              value: detailCatalog?.document_ktp_status ? 'Ada' : 'Tidak Ada'
            },
            {
              name: 'Kwitansi',
              value: detailCatalog?.document_kwitansi_status ? 'Ada' : 'Tidak Ada'
            },
            {
              name: 'KEUR',
              value: detailCatalog?.document_keur_status ? 'Ada' : 'Tidak Ada'
            },
            {
              name: 'Form A',
              value: detailCatalog?.document_form_status ? 'Ada' : 'Tidak Ada'
            }
          ]}
        />
      </div>
      <InspeksiCard
        onGoToDetailInspeksi={() =>
          router.push(
            `/analytics-dashboard/unit/${detailCatalog?.unit_name}/${detailCatalog?.inspectionResult?.id}/detail-inspeksi`
          )
        }
        data={{
          ...detailCatalog?.inspectionResult,
          inspectionCategoryList:
            detailCatalog?.inspectionResult?.inspectionCategoryList?.map((item) => ({
              ...item,
              count: `${item?.ok_total}/${item?.part_total}`
            })) ?? []
        }}
      />
      <PhotoCarousel
        title={'Foto Mobil'}
        data={detailCatalog?.photos?.map((item) => item?.photo_filename) ?? []}
      />
      <CatatanCard desc={detailCatalog?.description} />
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
        <HistoryUnitServicesInspectionCard
          title="Riwayat Servis"
          columns={columnsHistoryServices}
          data={historyServices?.listData}
          tableState={historyServices}
          setTableState={setHistoryServices}
          isLoading={isLoadingServices ?? isFetchingServices}
        />
        <HistoryUnitServicesInspectionCard
          title="Riwayat Inspeksi"
          columns={columnsHistoryInspection}
          data={historyInspection?.listData}
          tableState={historyInspection}
          setTableState={setHistoryInspection}
          isLoading={isLoadingInspeksi ?? isFetchingInspeksi}
        />
      </div>
    </Layout>
  )
}

export default DetailAnalyticsUnit
