import React from 'react'
import { CardContainer } from 'components'
import ReactMarkdown from 'react-markdown'

const Description = ({ desc }) => {
  const fontValueStyle = 'font-medium text-gray-700'

  return (
    <CardContainer>
      <div className="flex w-full pb-4">
        <span className="font-semibold text-2xl text-gray-900">Deskripsi</span>
      </div>
      <div className="flex flex-col w-full pt-4">
        <ReactMarkdown className={fontValueStyle}>{desc}</ReactMarkdown>
      </div>
    </CardContainer>
  )
}

export default Description
