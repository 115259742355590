import React, { useCallback, useState } from 'react'
import { Breadcrumb, Input, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { CardContainer, HeaderContainer, Table } from 'components'
import { formatCurrency, showErrorMessage, showSuccessMessage } from 'utils'
import { IcEdit, IcSearch, IcTrash } from 'assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { useQuery } from 'react-query'
import ModalUpdateCYOCSparepart from '../components/ModalUpdateCYOCSparepart'
import { logout } from 'store/actions'
import _ from 'lodash'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null,
  category_id: ''
}

const CreateYourOwnCarSparepartPage = () => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const [tableState, setTableState] = useState(initialTableState)
  const [searchCategory, setSearchCategory] = useState('')
  const [modal, setModal] = useState({
    show: false,
    data: null,
    type: 'add'
  })
  //* colums table
  const columns = [
    {
      name: 'Nama',
      selector: (row) => row?.name
    },
    {
      name: 'Kategori',
      selector: (row) => row?.category?.name
    },
    {
      name: 'Harga',
      selector: (row) =>
        row?.price && row?.price !== '-' ? formatCurrency(row?.price) : row?.price
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() =>
              setModal({
                show: true,
                type: 'edit',
                data: row
              })
            }>
            <IcEdit className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setModal({
                show: true,
                type: 'delete',
                data: row
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'cyoc-sparepart-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.category_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order,
        category_id: tableState.category_id
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'jual-beli-service/admin/create-your-own-car/sparepart',
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const categoryData = useQuery({
    queryKey: ['cyoc-category-data', searchCategory],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: searchCategory,
        offset: 0,
        limit: 10
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'jual-beli-service/admin/create-your-own-car/category',
        onLogout: () => dispatch(logout())
      })
      return res
    }
  })

  const onCheckFieldEmpty = (data) => {
    return Object.entries(data).every((item) => {
      if (item[1] !== undefined && item[1] !== '' && item[1] !== null && item[1].length !== 0) {
        return true
      } else {
        if (item[0] === 'category_id') {
          showErrorMessage({ content: `Silahkan Pilih Field Kategori` })
        } else if (item[0] === 'unit_id') {
          showErrorMessage({ content: `Silahkan Pilih Field Unit` })
        } else {
          showErrorMessage({ content: `Silahkan Lengkapi Field ${_.startCase(item[0])}` })
        }
        return false
      }
    })
  }

  const saveData = async (data) => {
    if (onCheckFieldEmpty(data)) {
      try {
        let res
        let body = {
          ...data,
          price: parseInt(data.price)
        }

        if (data?.id) {
          const id = data?.id
          res = await apiPut({
            url: `jual-beli-service/admin/create-your-own-car/sparepart/${id}`,
            token,
            data: body,
            onLogout: () => dispatch(logout())
          })
        } else {
          res = await apiPost({
            url: `jual-beli-service/admin/create-your-own-car/sparepart`,
            token,
            data: body,
            onLogout: () => dispatch(logout())
          })
        }
        if (res?.resStatus === 200 || res?.resStatus === 201) {
          showSuccessMessage({
            content: `${
              res.resData.message ??
              `Successfully ${modal.type === 'add' ? 'Create' : 'Edit'} Sparepart.`
            }`
          })
          setModal({
            ...modal,
            show: false
          })
        } else {
          showErrorMessage({
            content: `${
              res.resData.errors[0].message ??
              `Failed to ${modal.type === 'add' ? 'Create' : 'Edit'} Sparepart.`
            }`
          })
        }
        refetch()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        url: `jual-beli-service/admin/create-your-own-car/sparepart/${data?.id}`,
        token,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showSuccessMessage({
          content: `${res.resData.message ?? 'Successfully Delete Sparepart.'}`
        })
        setModal({
          ...modal,
          show: false
        })
      } else {
        showErrorMessage({
          content: `${res.resData.errors[0].message ?? 'Failed to Delete Sparepart.'}`
        })
      }
      refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  const onChangeCategory = (cat) => {
    setTableState((prevState) => ({
      ...prevState,
      category_id: cat
    }))
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Create Your Own Car
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Sparepart
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <ModalUpdateCYOCSparepart
          type={modal.type}
          isOpen={modal.show}
          data={modal.data}
          onCancel={() => setModal({ ...modal, show: false })}
          onSubmit={saveData}
          onDelete={deleteData}
        />
        <CardContainer>
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer
              title="Sparepart"
              isPressAdd
              titleAdd="Tambah Sparepart"
              onPressAdd={() =>
                setModal({
                  show: true,
                  type: 'add',
                  data: null
                })
              }
            />
            <div className="mb-5 flex justify-between">
              <div className="flex">
                <Select
                  showSearch
                  className="custom-table-limit mr-3"
                  style={{ width: '150px' }}
                  value={tableState?.category_id}
                  onSearch={(e) => setSearchCategory(e ?? '')}
                  placeholder="Pilih kategori"
                  onChange={onChangeCategory}
                  filterOption={false}
                  allowClear>
                  {'semua kategori'.includes(searchCategory.toLowerCase()) && (
                    <Select.Option className="custom-table-limit-option" value="">
                      Semua Kategori
                    </Select.Option>
                  )}
                  {categoryData?.data?.resData?.data?.map((item) => (
                    <Select.Option key={item?.id?.toString()} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Input
                className="w-1/4 rounded-md"
                placeholder="Pencarian"
                prefix={<IcSearch className="text-gray-400" />}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <Table
              columns={columns}
              data={tableState.listData}
              pending={isLoading}
              onSort={handleSort}
              totalRows={tableState?.totalRows}
              handlePerRowsChange={handlePerRowsChange}
              pageSize={tableState?.limit}
              page={tableState?.offset}
              onChangePrevPage={() => {
                if (tableState?.offset > 0) {
                  onChangePage(tableState?.offset - tableState?.limit)
                }
              }}
              onChangeNextPage={() => {
                if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                  onChangePage(tableState?.offset + tableState?.limit)
                }
              }}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default CreateYourOwnCarSparepartPage
