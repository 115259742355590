export const jualUnitKatalogSalesOrderDataDummeies = {
  salesOrderNumber: '20220929',
  penjual: 'Jude Bellingham',
  pembeli: 'Matty Healy',
  alamat: 'Jl. Bunga Melati No. 14, Jakarta',
  layanan: 'Jual Beli',
  catatan:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc.',
  payments: {
    items: [
      {
        name: '2019 Toyota Kijang Innova 2.4G',
        price: 300000000,
        qty: 1
      }
    ],
    discount: 0
  }
}

export const jadwalServiceSalerOrderDataDummies = {
  salesOrderNumber: '20220929',
  account: 'Jude Bellingham',
  alamat: 'Jl. Bunga Melati No. 14, Jakarta',
  layanan: 'Servis',
  tempat: 'Birdmobil Surabaya, Stall 1',
  waktu: '24/10/2022, 07.00-09.00',
  mekanik: 'Nielsen Norman',
  catatan:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc.',
  payments: {
    items: [
      {
        name: 'Shell Helix 5L',
        price: 200000,
        qty: 3
      },
      {
        name: 'Tune Up Mesin',
        price: 500000,
        qty: 1
      }
    ],
    discount: 50000
  }
}

export const inspeksiSalesOrderDataDummies = {
  salesOrderNumber: '20220929',
  account: 'Jude Bellingham',
  alamat: 'Jl. Bunga Melati No. 14, Jakarta',
  layanan: 'Inspeksi',
  tempat: 'Birdmobi Surabaya, Stall 1',
  waktu: '24/10/2022, 07.00-09.00',
  inspektor: 'Nielsen Norman',

  catatan:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut morbi mauris, sit massa, scelerisque ultrices non. Ipsum dui, nec aliquam tortor, nisi, leo netus. Habitant accumsan eu sed cum amet, venenatis porttitor senectus. Blandit sed adipiscing proin in nisl sagittis pellentesque nunc.',
  payments: {
    items: [
      {
        name: 'Biaya Inspeksi',
        price: 500000,
        qty: 1
      }
    ],
    discount: 0
  }
}
