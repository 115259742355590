import React, { useState } from 'react'
import { Breadcrumb, DatePicker, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useHistory } from 'react-router-dom'
import { HeaderContainerJualUnit } from 'pages/main/jual-unit/components'
import { CardContainer, FormDropDown, FormInput, Loading, ModalDelete, Table } from 'components'
import { Formik } from 'formik'
import moment from 'moment'
import 'moment/locale/en-gb'
import { IcEye, IcTrash } from 'assets/icons'
import {
  ModalBeliStockUnit,
  ModalInspectionProgress,
  ModalKonfirmasiStockJadwalInspeksi,
  ModalKonfirmasiStockUnit
} from './components'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPatch, urls } from 'api'
import { metaInitialData, perPageInitialData } from 'constant'
import { disabledTimeDropdown, showErrorMessage, showSuccessMessage } from 'utils'
import { logout } from 'store/actions'
import _ from 'lodash'
import BadgeProgress from 'components/badge-progress'

const StockUnitPage = () => {
  const router = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth)
  const [listJualUnitStock, setListJualUnitStock] = useState()
  const [meta, setMeta] = useState(metaInitialData)
  const [currentPage, setCurrentPage] = useState(0)
  const [perPageArr, setPerPageArr] = useState(perPageInitialData)
  const [dropDownOwners, setDropDownOwners] = useState([{ name: 'Semua Pemilik', value: -1 }])
  const [dropDownTime, setDropDownTime] = useState([])

  const onLogout = () => {
    dispatch(logout())
  }

  const { isLoading, refetch } = useQuery({
    queryKey: ['jual-unit-stocks'],
    refetchOnWindowFocus: true,
    queryFn: async () =>
      await Promise.all([
        apiGet({
          url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}?limit=${
            perPageArr.find((item) => item.selected === true).value
          }&offset=${meta.offset ?? 0}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.owners}`,
          token: user.token,
          onLogout
        }),
        apiGet({
          url: `${urls.jualUnit.stock.schedule.prefix}/${urls.jualUnit.stock.schedule.list}`,
          token: user.token,
          onLogout
        })
      ]),
    onSuccess: (data) => {
      setListJualUnitStock(data[0].resData.data)
      setMeta((prev) => ({ ...prev, ...data[0].resData.meta }))
      setCurrentPage(0)
      setDropDownOwners([{ name: 'Semua Pemilik', value: -1 }])
      if (data[1].resStatus === 200) {
        if (data[1].resData.data.length !== 0) {
          data[1].resData.data.map((item) => {
            if (item !== '') {
              setDropDownOwners((prev) => [...prev, { name: item, value: item }])
            }
          })
        }
      }
      if (data[2].resStatus === 200) {
        if (data[2]?.resData.data.length !== 0) {
          setDropDownTime(
            data[2]?.resData.data.map((item) => ({ name: item.time, value: item.id }))
          )
        }
      }
    }
  })

  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const [tempDate, setTempDate] = useState([])
  const [dateRangeValue, setDateRangeValue] = useState([])
  const [ownerSelected, setOwnerSelected] = useState(-1)
  const [stockUnitSearch, setStockUnitSearch] = useState('')
  const [isLoadingHelper, setIsLoadingHelper] = useState(false)
  const [isDateFilterHelper, setIsDateFilterHelper] = useState(false)

  const [modalKonfirmasiUnit, setModalKonfirmasiUnit] = useState({
    show: false,
    id: 0,
    unit: '',
    price: 0,
    owner: ''
  })

  const [modalBeliUnit, setModalBeliUnit] = useState({
    show: false,
    id: 0,
    unit: '',
    price: 0,
    owner: ''
  })

  const [modalKonfirmasiJadwalInspeksi, setModalKonfirmasiJadwalInspeksi] = useState({
    id: 0,
    show: false,
    date: moment().format('DD/MM/YYYY'),
    time: '',
    bengkel: ''
  })

  const [modalInspectionProgress, setModalInspectionProgress] = useState({
    show: false,
    title: ''
  })

  const [modalDelete, setModalDelete] = useState({
    show: false,
    stockId: 0,
    title: '',
    desc: ''
  })

  const onFilterSearchListStock = async (owner, search, startDate, endDate, isDateFilter) => {
    setMeta((prev) => ({ ...prev, offset: 0 }))
    setCurrentPage(0)
    let extraUrl = ''
    if (owner && owner !== -1) {
      extraUrl += `&owner_fullname=${owner}`
    }
    if (search && search !== '') {
      extraUrl += `&keywords=${search}`
    }
    if (startDate && endDate && isDateFilter === true) {
      extraUrl += `&start_date=${startDate}&end_date=${endDate}`
    }
    const response = await apiGet({
      url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}?limit=${
        perPageArr.find((item) => item.selected === true).value
      }&offset=${0}${extraUrl}`,
      token: user.token,
      onLogout
    })
    if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
      setListJualUnitStock(response.resData.data)
      setMeta((prev) => ({ ...prev, ...response.resData.meta }))
    } else {
      refetch()
    }
  }

  const onHandleExtraUrl = () => {
    let extraUrl = ''

    if (ownerSelected && (ownerSelected !== -1 || parseInt(ownerSelected) > -1)) {
      extraUrl += `&owner_fullname=${ownerSelected}`
    }
    if (stockUnitSearch && stockUnitSearch !== '') {
      extraUrl += `&keywords=${stockUnitSearch}`
    }
    if (dateRangeValue?.[0] && dateRangeValue?.[1] && isDateFilterHelper === true) {
      extraUrl += `&start_date=${
        dateRangeValue?.[0] ? moment(dateRangeValue[0]).format('DD/MM/YYYY') : ''
      }&end_date=${dateRangeValue?.[1] ? moment(dateRangeValue[1]).format('DD/MM/YYYY') : ''}`
    }

    return extraUrl
  }

  const onChangePrevNextPage = (offset) => {
    setIsLoadingHelper(true)
    setMeta({ ...meta, offset: offset })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}?limit=${
          perPageArr.find((item) => item.selected === true).value
        }&offset=${offset}${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListJualUnitStock(response.resData.data)
      } else {
        refetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onChangePerPage = (value) => {
    setIsLoadingHelper(true)
    const newArr = [...perPageArr]
    newArr.map((item) => {
      if (item.value === value) {
        item.selected = true
      } else {
        item.selected = false
      }
    })
    const mount = setTimeout(async () => {
      const response = await apiGet({
        url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}?limit=${value}&offset=${
          meta.offset ?? 0
        }${onHandleExtraUrl()}`,
        token: user.token,
        onLogout
      })
      if (response.resStatus === 200 && response.resData.meta.http_code === 200) {
        setListJualUnitStock(response.resData.data)
        setMeta((prev) => ({ ...prev, ...response.resData.meta }))
        setPerPageArr(newArr)
      } else {
        refetch()
      }
      setIsLoadingHelper(false)
    })
    return () => clearTimeout(mount)
  }

  const onGetStockDetail = async (id) => {
    let bengkel = ''
    const res = await apiGet({
      url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}/${id}`,
      token: user.token,
      onLogout
    })
    if (res.resStatus === 200) {
      bengkel = res.resData.data.branch_name ?? '-'
    } else {
      bengkel = '-'
    }
    return bengkel
  }

  const columns = [
    {
      name: 'Tanggal',
      selector: (row) => row.created_at,
      cell: (row) => moment(row.created_at).format('DD/MM/YYYY')
    },
    {
      name: 'Unit',
      selector: (row) => row.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <span className="font-medium text-gray-700">{row.unit_name}</span>
          <span className="font-normal text-gray-500 text-xs tracking-tight">
            {row.police_number && row.police_number !== '' ? row.police_number : '-'}
          </span>
        </div>
      )
    },
    {
      name: 'Pemilik',
      selector: (row) => row.owner_fullname,
      cell: (row) => (
        <div className="flex flex-col space-y-1">
          <span className="font-medium text-gray-700">
            {row.owner_fullname && row.owner_fullname !== '' ? row.owner_fullname : '-'}
          </span>
          <span className="font-normal text-gray-500 text-xs tracking-tight">
            {row.owner_city_name && row.owner_city_name !== '' ? row.owner_city_name : '-'}
          </span>
        </div>
      )
    },
    {
      name: 'Platform',
      selector: (row) => row.platform,
      cell: (row) => _.startCase(row.platform)
    },
    {
      name: 'Status',
      selector: (row) => row?.recondition_status,
      cell: (row) => <BadgeProgress status={row?.recondition_status} />
    },
    {
      name: 'Action',
      width: '350px',
      button: false,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      cell: (row) => (
        <div className="flex flex-row items-center justify-end space-x-2 my-2 w-full">
          {row.is_inspection_booked ? (
            <div
              className={`flex flex-row w-40 items-center justify-center space-x-2 hover:cursor-pointer px-3 py-3 border-solid border ${
                row?.recondition_status === 'Belum Rekondisi' ||
                row?.recondition_status === 'Selesai Rekondisi'
                  ? 'border-success-500 bg-success-500'
                  : 'border-success-200 bg-success-200'
              } rounded-md`}
              onClick={() => {
                if (
                  row?.recondition_status === 'Belum Rekondisi' ||
                  row?.recondition_status === 'Selesai Rekondisi'
                ) {
                  router.push(`/jual-unit/stock/${row?.inspection_id}/${row?.id}/detail-inspeksi`)
                }
              }}>
              <span className="font-medium text-[#FFFFFF]">Check hasil Inspeksi</span>
            </div>
          ) : (
            <div
              onClick={async () => {
                setModalKonfirmasiJadwalInspeksi({
                  ...modalKonfirmasiJadwalInspeksi,
                  show: true,
                  id: row.id,
                  date: moment().format('DD/MM/YYYY'),
                  time: row.time,
                  bengkel: await onGetStockDetail(row.id)
                })
              }}
              className="flex items-center justify-center w-40 px-3 my-3 py-3 rounded-md bg-white border border-solid border-gray-300 hover:cursor-pointer">
              <span className="font-medium text-gray-700 text-center">Lakukan Inspeksi</span>
            </div>
          )}
          <div
            className={`hover:cursor-pointer px-4 py-3 rounded-md border-solid border ${
              row.is_inspected
                ? 'border-gray-300  hover:bg-gray-100'
                : 'border-gray-200 hover:bg-none'
            }`}
            onClick={() => {
              if (row.is_inspected) {
                setModalBeliUnit({
                  show: true,
                  id: row.id,
                  unit: row.unit_name,
                  price: row.price,
                  owner: row.owner_fullname,
                  phone: row?.owner_phone_number
                })
              } else {
                setModalInspectionProgress({
                  show: true,
                  title: 'Konfirmasi Beli Unit'
                })
              }
            }}>
            <span className={`font-medium ${row.is_inspected ? 'text-gray-700' : 'text-gray-300'}`}>
              Beli
            </span>
          </div>
          <div
            className="hover:cursor-pointer p-2 py-2.5 border-solid border border-gray-300 rounded-md hover:bg-gray-100"
            onClick={() => router.push(`/jual-unit/stock/detail/${row.id}`)}>
            <IcEye className="text-gray-700" size={17} />
          </div>
          <div
            className="hover:cursor-pointer px-2 py-2.5 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() =>
              setModalDelete({
                show: true,
                stockId: row.id,
                title: 'Hapus Stock Unit',
                desc: `Apakah anda yang ingin menghapus stock unit [${row.unit_name}] ini? Data yang Anda Hapus tidak dapat dipulihkan kembali.`
              })
            }>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  if (isLoading ?? isLoadingHelper) {
    return <Loading visible={true} />
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Jual Unit
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">Stock</Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        {modalKonfirmasiUnit.show ? (
          <ModalKonfirmasiStockUnit
            state={modalKonfirmasiUnit}
            onClose={() => setModalKonfirmasiUnit({ ...modalKonfirmasiUnit, show: false })}
            onSubmit={(values) => {
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                const res = await apiPatch({
                  url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.v2}/${modalKonfirmasiUnit.id}/confirm`,
                  token: user.token,
                  data: {
                    price: values.harga
                  },
                  onLogout
                })
                if (res.resStatus === 200) {
                  showSuccessMessage({ content: 'Konfirmasi Unit Telah Berhasil' })
                } else {
                  showErrorMessage({
                    content: `${res.resData.errors[0].message ?? 'Gagal Konfirmasi Unit'}`
                  })
                }
                refetch()
                setModalKonfirmasiUnit({ ...modalKonfirmasiUnit, show: false })
                setIsLoadingHelper(false)
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {modalBeliUnit.show ? (
          <ModalBeliStockUnit
            state={modalBeliUnit}
            onClose={() => setModalBeliUnit({ ...modalBeliUnit, show: false })}
            onSubmit={(price) => {
              if (price && price !== '') {
                setIsLoadingHelper(true)
                const mount = setTimeout(async () => {
                  const res = await apiPatch({
                    url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}/${modalBeliUnit.id}/buy`,
                    token: user.token,
                    data: { price: price },
                    onLogout
                  })
                  if (res.resStatus === 200) {
                    showSuccessMessage({
                      content: _.startCase(res.resData.message ?? 'Successfully Buy Stock.')
                    })
                  } else {
                    showErrorMessage({
                      content: `${res.resData.errors[0].message ?? 'Failed to Buy Stock.'}`
                    })
                  }
                  refetch()
                  setModalBeliUnit({ ...modalBeliUnit, show: false })
                  setIsLoadingHelper(false)
                })
                return () => clearTimeout(mount)
              }
            }}
          />
        ) : null}
        {modalKonfirmasiJadwalInspeksi.show ? (
          <ModalKonfirmasiStockJadwalInspeksi
            state={modalKonfirmasiJadwalInspeksi}
            dropDownTime={dropDownTime}
            onClose={() =>
              setModalKonfirmasiJadwalInspeksi({ ...modalKonfirmasiJadwalInspeksi, show: false })
            }
            onSubmit={(values) => {
              if (
                disabledTimeDropdown(
                  dropDownTime?.find((f) => +f?.value === +values?.time)?.name,
                  values?.date
                )
              ) {
                showErrorMessage({ content: 'Waktu tidak tersedia' })
              } else if (!values?.date || values?.date === '') {
                showErrorMessage({ content: 'Mohon pilih tanggal' })
              } else {
                setIsLoadingHelper(true)
                const mount = setTimeout(async () => {
                  const res = await apiPatch({
                    url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}/${modalKonfirmasiJadwalInspeksi.id}/inspection`,
                    token: user.token,
                    data: {
                      inspection_date: values.date,
                      inspection_schedule_id: parseInt(values.time)
                    },
                    onLogout
                  })
                  if (res.resStatus === 200) {
                    showSuccessMessage({
                      content: _.startCase(res.resData.message ?? 'Berhasil Lakukan Inspeksi.')
                    })
                  } else {
                    showErrorMessage({
                      content: `${res.resData.errors[0].message ?? 'Gagal Lakukan Inspeksi.'}`
                    })
                  }
                  refetch()
                  setIsLoadingHelper(false)
                  setModalKonfirmasiJadwalInspeksi({
                    ...modalKonfirmasiJadwalInspeksi,
                    show: false
                  })
                })
                return () => clearTimeout(mount)
              }
            }}
          />
        ) : null}
        {modalDelete.show ? (
          <ModalDelete
            minHeight="min-h-[22%]"
            maxWidth="max-w-[25%]"
            title={modalDelete.title}
            desc={modalDelete.desc}
            onClose={() => setModalDelete({ ...modalDelete, show: false })}
            onSubmit={() => {
              setIsLoadingHelper(true)
              const mount = setTimeout(async () => {
                await apiDelete({
                  url: `${urls.jualUnit.prefix}/${urls.jualUnit.stock.root}/${modalDelete.stockId}`,
                  token: user.token,
                  onLogout
                })
                refetch()
                setModalDelete({ ...modalDelete, show: false })
                setIsLoadingHelper(false)
              })
              return () => clearTimeout(mount)
            }}
          />
        ) : null}
        {modalInspectionProgress.show ? (
          <ModalInspectionProgress
            title={modalInspectionProgress.title}
            onClose={() => setModalInspectionProgress({ show: false, title: '' })}
          />
        ) : null}
        <CardContainer>
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainerJualUnit
              title="Daftar Unit Perlu Dikonfirmasi"
              titleAdd="Tambah Unit"
              isPressAdd
              onPressAdd={() => {
                router.push('/jual-unit/stock/create-unit')
              }}
              onPressAuditTrail={() => router.push('/jual-unit/stock/audit-trail')}
            />
            <div className="flex flex-row w-full items-center justify-between mt-2">
              <div className="flex flex-row w-full items-center space-x-5">
                <div className="w-1/6">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      ownerSelector: ownerSelected
                    }}>
                    <FormDropDown
                      name="ownerSelector"
                      data={dropDownOwners}
                      onChange={(value) => {
                        setOwnerSelected(value)
                        onFilterSearchListStock(
                          value,
                          stockUnitSearch,
                          dateRangeValue?.[0] ? moment(dateRangeValue[0]).format('DD/MM/YYYY') : '',
                          dateRangeValue?.[1] ? moment(dateRangeValue[1]).format('DD/MM/YYYY') : '',
                          isDateFilterHelper
                        )
                      }}
                      allowClear={true}
                      isSearch={true}
                    />
                  </Formik>
                </div>
                <div className="w-1/4 h-full">
                  <RangePicker
                    onOpenChange={(open) => setIsShowDatePicker(true)}
                    onChange={(dates) => {
                      setTempDate(dates)
                      if (!dates) {
                        setTempDate([])
                        setIsDateFilterHelper(false)
                        setDateRangeValue(dates)
                        onFilterSearchListStock(ownerSelected, stockUnitSearch, '', '', false)
                      }
                    }}
                    format="DD/MM/YYYY"
                    open={isShowDatePicker}
                    value={dateRangeValue}
                    className="py-3 rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500"
                    renderExtraFooter={() => (
                      <div className="flex flex-row justify-end items-center space-x-4 my-3">
                        <button
                          type="button"
                          className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-gray-700 bg-white"
                          onClick={() => {
                            setTempDate([])
                            setIsShowDatePicker(false)
                          }}>
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-white bg-primary-500"
                          onClick={() => {
                            setIsShowDatePicker(false)
                            if (tempDate?.length > 0) {
                              setDateRangeValue(tempDate)
                              setIsDateFilterHelper(true)
                              onFilterSearchListStock(
                                ownerSelected,
                                stockUnitSearch,
                                moment(tempDate[0]).format('DD/MM/YYYY'),
                                moment(tempDate[1]).format('DD/MM/YYYY'),
                                true
                              )
                              setTempDate([])
                            }
                          }}>
                          Apply
                        </button>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="w-1/4">
                <Formik
                  initialValues={{
                    stockUnitSearch: stockUnitSearch
                  }}>
                  <FormInput
                    name="stockUnitSearch"
                    placeholder="Pencarian"
                    type="text"
                    isIconLeft
                    iconName="search"
                    onValidate={(value) => {
                      setStockUnitSearch(value)
                      onFilterSearchListStock(
                        ownerSelected,
                        value,
                        dateRangeValue?.[0] ? moment(dateRangeValue[0]).format('DD/MM/YYYY') : '',
                        dateRangeValue?.[1] ? moment(dateRangeValue[1]).format('DD/MM/YYYY') : '',
                        isDateFilterHelper
                      )
                    }}
                  />
                </Formik>
              </div>
            </div>
            <Table
              currentPage={meta.offset ? meta.offset + 1 : 1}
              totalPerPage={
                perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) >
                meta.total
                  ? meta.total
                  : perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0)
              }
              dropDownDataFilter={perPageArr}
              lengthAllData={meta.total}
              columns={columns}
              data={listJualUnitStock}
              onChangePrevPage={() => {
                if (currentPage > 0) {
                  setCurrentPage(currentPage - 1)
                  onChangePrevNextPage(
                    (currentPage - 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeNextPage={() => {
                if (
                  perPageArr.find((item) => item.selected === true).value + (meta.offset ?? 0) <
                  meta.total
                ) {
                  setCurrentPage(currentPage + 1)
                  onChangePrevNextPage(
                    (currentPage + 1) * perPageArr.find((item) => item.selected === true).value
                  )
                }
              }}
              onChangeDropDown={(value) => onChangePerPage(value)}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default StockUnitPage
